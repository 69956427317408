import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import queryString from 'query-string'
import { Button, Form, Card } from 'semantic-ui-react'

import logo from '~/assets/logo-white.png'
import useAccounts from '~/hooks/useAccounts'

export default function Activation() {
  const { submitActivation, loadingAccounts } = useAccounts()
  const location = useLocation()

  const [values, setValues] = useState(queryString.parse(location.search))

  function onChange(key, value) {
    return setValues({
      ...values,
      [key]: value,
    })
  }

  return (
    <header className="App-header" style={{ marginTop: '-60px' }}>
      <div style={{ textAlign: 'center' }}>
        <img src={logo} className="App-logo" alt="logo" />
        <h1 style={{ color: 'white' }}>Cadastro de Admin</h1>
      </div>
      <Card centered>
        <Card.Content>
          <Form warning size="large" onSubmit={() => submitActivation(values)}>
            <Form.Input
              disabled
              value={values.email}
              type="text"
              label="Email"
              placeholder="Email"
            />
            <Form.Input
              type="text"
              label="Nome"
              placeholder="Digite seu nome"
              onChange={e => onChange('name', e.target.value)}
            />
            <Form.Input
              type="password"
              label="Senha"
              placeholder="Digite sua senha"
              onChange={e => onChange('password', e.target.value)}
            />
            <Form.Input
              type="password"
              label="Confirmar Senha"
              placeholder="Digite sua senha"
              onChange={e => onChange('confirmPassword', e.target.value)}
            />
            <Button
              color="orange"
              content="Ativar"
              loading={loadingAccounts}
              disabled={loadingAccounts}
            />
          </Form>
        </Card.Content>
      </Card>
    </header>
  )
}
