import React from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Table, Dropdown, Icon } from 'semantic-ui-react'

const Item = ({
  item,
  index,
  history,
  categoryId,
  changeStatus,
  onRemove,
  onOpenBadge,
  onOpenGroup,
}) => {
  return (
    <Table.Row key={`index-${index}`}>
      <Table.HeaderCell
        style={{
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img alt="avatar" src={item.avatar} style={{ width: 50, height: 50 }} />
      </Table.HeaderCell>
      <Table.Cell>{item.title}</Table.Cell>
      <Table.Cell>{item.time_to_appreciate}</Table.Cell>
      <Table.Cell>{item.expiring_time}</Table.Cell>
      <Table.Cell>{item.participants}</Table.Cell>
      <Table.Cell>{item.customer_value}</Table.Cell>
      <Table.Cell>
        {dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>
        {dayjs(item.updated_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell collapsing singleLine>
        <Dropdown
          button
          labeled
          floating
          text="Opções"
          icon="options"
          className="icon"
        >
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => changeStatus(item)}
              icon={item.status ? 'remove' : 'check'}
              text={item.status ? 'Inativar' : 'Ativar'}
              style={{ color: item.status ? 'red' : 'green' }}
            />
            <Dropdown.Item
              text="Editar"
              icon="pencil alternate"
              onClick={() =>
                history.push(
                  `/categories/${categoryId}/sub-categories/${item.uid}`,
                  { item }
                )
              }
            />
            {(item.has_value_percentage || item.has_value_variable) && (
              <Dropdown.Item
                icon={item.has_value_percentage ? 'percent' : 'list'}
                text={
                  item.has_value_percentage ? 'Percentual' : 'Valor Variável'
                }
                onClick={() =>
                  history.push(
                    `/categories/${categoryId}/sub-categories-values/${item.uid}`,
                    { item }
                  )
                }
              />
            )}
            {item.has_custom_status && (
              <Dropdown.Item
                icon="ordered list"
                text="Adicionar Status"
                onClick={() =>
                  history.push(
                    `/categories/${categoryId}/sub-categories/${item.uid}/custom-status`,
                    {
                      item,
                    }
                  )
                }
              />
            )}
            <Dropdown.Item
              icon="shield"
              onClick={onOpenBadge}
              text={`Badge ${item.badge_id === null ? 'Inativo' : 'Ative'}`}
            />
            <Dropdown.Item
              text="Líderes"
              icon={<Icon name="chess queen" />}
              onClick={() => history.push(`/leaders/${item.uid}`, { item })}
            />
            <Dropdown.Item
              icon="group"
              onClick={onOpenGroup}
              text="Vincular a grupo"
            />
            <Dropdown.Divider />
            <Dropdown.Item
              color="red"
              icon="trash"
              text="Remover"
              onClick={onRemove}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  item: t.objectOf(t.any),
  index: t.number,
  history: t.objectOf(t.any),
  categoryId: t.string,
  changeStatus: t.func,
  onRemove: t.func,
  onOpenBadge: t.func,
  onOpenGroup: t.func,
}

export default Item
