import React from 'react'

import { Card, Form, Icon, Button, Checkbox } from 'semantic-ui-react'

import types from '../../helpers/registersStatus'

export default function CreateItem({
  show,
  submit,
  loading,
  handleInput,

  back,
  description,
}) {
  return (
    <Card
      centered
      fluid
      style={{ marginTop: 10, display: show ? 'block' : 'none' }}
    >
      <Card.Content>
        <Form>
          <Form.Input
            type="text"
            value={description || ''}
            label="Descrição (Obrigatório)"
            placeholder="Ex: Descrição do status..."
            onChange={(e, { value }) => handleInput(value, 'description')}
          />
          <Form.Field
            toggle
            checked={back}
            control={Checkbox}
            label="Pode voltar status?"
            onChange={() => handleInput(!back, 'back')}
          />
          <Form.Select
            options={types}
            loading={loading}
            label="Status Registro"
            placeholder="Status Registro"
            onChange={(e, { value }) => handleInput(value, 'registerValue')}
          />
        </Form>
      </Card.Content>
      <Card.Content extra textAlign="right">
        <Button
          icon
          positive
          loading={loading}
          disabled={loading}
          labelPosition="left"
          onClick={() => submit()}
        >
          <Icon name="check" /> Salvar
        </Button>
      </Card.Content>
    </Card>
  )
}
