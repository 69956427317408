import React from 'react'

import { format, differenceInHours } from 'date-fns'
import t from 'prop-types'
import { Table, Label, Checkbox } from 'semantic-ui-react'

const Item = ({ item, onClick, changeCheckbox }) => {
  const isToday = new Date()

  function hasExpired(date) {
    return differenceInHours(new Date(date.expired_at), isToday) < 0
  }

  function isNew(date) {
    return (
      differenceInHours(new Date(date.started_at), isToday) >= 0 &&
      differenceInHours(new Date(date.started_at), isToday) <= 24
    )
  }

  function currentStatus(itemStatus) {
    if (!itemStatus.status) {
      return <Label color="">Inativo</Label>
    }

    if (hasExpired(itemStatus)) {
      return <Label color="red">Expirado</Label>
    }

    if (isNew(itemStatus)) {
      return <Label color="blue">Novo</Label>
    }

    return <Label color="green">Ativo</Label>
  }

  return (
    <Table.Row key={item.uid}>
      <Table.Cell>
        <Checkbox
          value={item.uid}
          onChange={(e, data) => changeCheckbox(data)}
        />
      </Table.Cell>
      <Table.Cell onClick={onClick}>{currentStatus(item)}</Table.Cell>
      <Table.Cell onClick={onClick}>{item.title}</Table.Cell>
      <Table.Cell onClick={onClick}>{item.description}</Table.Cell>
      <Table.Cell onClick={onClick}>{item.price}</Table.Cell>
      <Table.Cell onClick={onClick}>{item.installments}</Table.Cell>
      <Table.Cell onClick={onClick}>{item.quantity_discount}</Table.Cell>
      <Table.Cell onClick={onClick}>{item.value_discount}</Table.Cell>
      <Table.Cell onClick={onClick}>
        {format(new Date(item.started_at), 'dd/MM/yyyy')}
      </Table.Cell>
      <Table.Cell onClick={onClick}>
        {format(new Date(item.expired_at), 'dd/MM/yyyy')}
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  item: t.objectOf(t.any),
  onClick: t.func,
  changeCheckbox: t.func,
}

export default Item
