import React, { useState } from 'react'
import NumericFormat from 'react-number-format'

import PropTypes from 'prop-types'
import { Button, Card, Form, Grid } from 'semantic-ui-react'

export default function FormPlan({ toggleEnableOrDisableForm, savePlans }) {
  const [newPlan, setNewPlan] = useState({
    description: '',
    fees: 0,
    multipler: 0,
    installments: 0,
  })

  const changeInputValue = (key, value) => {
    setNewPlan({
      ...newPlan,
      [key]: value,
    })
  }

  const cancel = event => {
    event.preventDefault()
    setNewPlan({
      description: '',
      fees: 0,
      multipler: 0,
      installments: 0,
    })
    toggleEnableOrDisableForm()
  }

  const saveNewPlan = async event => {
    event.preventDefault()
    await savePlans(newPlan)
    setNewPlan({
      description: '',
      fees: 0,
      multipler: 0,
      installments: 0,
    })
  }

  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Field>
            <label>Descrição:</label>
            <textarea
              rows={2}
              maxLength="255"
              value={newPlan.description}
              onChange={event =>
                changeInputValue('description', event.target.value)
              }
            />
          </Form.Field>

          <Grid columns={3}>
            <Grid.Column>
              <Form.Field>
                <label>Juros</label>
                <NumericFormat
                  format="##.##"
                  type="text"
                  value={newPlan.fees}
                  onChange={event =>
                    changeInputValue('fees', event.target.value)
                  }
                  decimalScale={2}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Valor do multiplicador:</label>
                <input
                  value={newPlan.multipler}
                  onChange={event =>
                    changeInputValue('multipler', event.target.value)
                  }
                  type="number"
                  placeholder="3"
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Quantos parcelas terá?</label>
                <input
                  type="number"
                  value={newPlan.installments}
                  onChange={event =>
                    changeInputValue('installments', event.target.value)
                  }
                  placeholder="3"
                />
              </Form.Field>
            </Grid.Column>
          </Grid>
          <br />
          <Form.Field>
            <Button type="submit" primary onClick={saveNewPlan}>
              Salvar
            </Button>
            <Button type="submit" color="red" onClick={cancel}>
              Cancelar
            </Button>
          </Form.Field>
        </Form>
      </Card.Content>
    </Card>
  )
}

FormPlan.propTypes = {
  toggleEnableOrDisableForm: PropTypes.func,
  savePlans: PropTypes.func,
}
