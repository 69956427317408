import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'

import t from 'prop-types'
import { Card, Grid, Image } from 'semantic-ui-react'

import { CardStyle } from '../../styles'

function Photos({ photos }) {
  const [open, setOpen] = useState(false)
  const [items, setItems] = useState([])
  const [current, setCurrent] = useState(null)

  function openBox(photo) {
    setItems(photos.map(p => p.path))
    setCurrent(photos.indexOf(photo))

    return setTimeout(() => setOpen(true), 100)
  }

  return (
    <Grid relaxed columns={4}>
      {photos.map(photo => (
        <Card link style={CardStyle}>
          <Grid.Column verticalAlign="middle" textAlign="center">
            <Image fluid src={photo.path} onClick={() => openBox(photo)} />
          </Grid.Column>
        </Card>
      ))}

      {open && (
        <Lightbox
          onCloseRequest={() => setOpen(false)}
          mainSrc={items[current]}
          nextSrc={items[(current + 1) % items.length]}
          onMovePrevRequest={() => setCurrent(current - 1)}
          onMoveNextRequest={() => setCurrent(current + 1)}
          prevSrc={items[(current + items.length - 1) % items.length]}
        />
      )}
    </Grid>
  )
}

Photos.propTypes = {
  photos: t.arrayOf(t.any),
}

export default Photos
