import React from 'react'

import { Card, Button, Input } from 'semantic-ui-react'

import registersColors from '../../helpers/registersColors'
import registersStatus from '../../helpers/registersStatus'

export default function Item({
  show,
  index,
  status,
  showInput,
  updateItem,
  confirmItem,
  confirmUpdate,
}) {
  return (
    <Card
      fluid
      key={`i${index}`}
      color={registersColors[status.register_status_value]}
    >
      <Card.Content>
        <Card.Header>{status.order} - Order</Card.Header>
        <Card.Description>
          {showInput ? (
            <div>
              <Input
                style={{ marginRight: 10 }}
                value={status.description || null}
                onChange={(e, { value }) => updateItem(status, value)}
              />
              <Button positive icon="check" onClick={confirmUpdate} />
            </div>
          ) : (
            `${status.description} (${
              registersStatus.find(
                i => i.value === status.register_status_value
              ).text
            })`
          )}
        </Card.Description>
      </Card.Content>
      <Card.Content>
        <Button size="tiny" icon="pencil" onClick={show} content="Atualizar" />
        <Button
          size="tiny"
          color="red"
          icon="trash"
          content="Remover"
          onClick={() => confirmItem(status)}
        />
      </Card.Content>
    </Card>
  )
}
