import React from 'react'

import { Button } from 'semantic-ui-react'

import Empty from '~/components/EmptyList'
import history from '~/services/history'

export default function NotFound() {
  return (
    <>
      <Empty icon="frown" text="Página não encontrada...." />
      <div style={{ textAlign: 'center' }}>
        <Button
          as="a"
          style={{ cursor: 'pointer', fontSize: 16, fontWeight: '500' }}
          onClick={() => history.goBack()}
        >
          Voltar
        </Button>
      </div>
    </>
  )
}
