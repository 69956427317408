import React from 'react'

import t from 'prop-types'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Card } from 'semantic-ui-react'

import ContainerLoading from '~/components/Loading/ContainerLoading'

import { ContainerDiv } from '../styles'
import GraphicTooltipTotalUserByVersion from './GraphicTooltipTotalUserByVersion'

function BarGraphic({
  title,
  data,
  columnX,
  columnY,
  referenceColumn,
  rangeMinMax,
  isLoading,
}) {
  return (
    <Card fluid>
      <Card.Content>
        <h2 className="center aligned">{title}</h2>
        <ContainerDiv>
          <ContainerLoading
            isLoading={isLoading}
            customText="Carregando as informações..."
          >
            <ResponsiveContainer width="95%" height={200}>
              <BarChart
                width={800}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 50,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis angle={-45} textAnchor="end" dataKey={columnX} />
                {rangeMinMax && (
                  <YAxis dataKey={columnY} domain={rangeMinMax} />
                )}
                {!rangeMinMax && <YAxis dataKey={columnY} />}
                <Tooltip content={GraphicTooltipTotalUserByVersion} />
                <Bar dataKey={referenceColumn} fill="#4269d8" />
              </BarChart>
            </ResponsiveContainer>
          </ContainerLoading>
        </ContainerDiv>
      </Card.Content>
    </Card>
  )
}

BarGraphic.propTypes = {
  title: t.string,
  data: t.arrayOf(t.objectOf(t.any)),
  columnX: t.string,
  columnY: t.string,
  referenceColumn: t.string,
  rangeMinMax: t.arrayOf(t.number),
  isLoading: t.bool,
}

export default BarGraphic
