import React, { useState } from 'react'
import Dropzone from 'react-dropzone'

import t from 'prop-types'
import { Modal, Button, Icon } from 'semantic-ui-react'

import { DragContainer } from './styles'

const UploadContractModal = ({
  onClose,
  visible,
  onUpload,
  loading,
  creditProfileSelected,
}) => {
  const [receipt, setReceipt] = useState(null)

  return (
    <Modal size="tiny" open={visible} onClose={onClose}>
      <Modal.Header>Carrregar Contrato Assinado</Modal.Header>
      <Modal.Content style={{ backgroundColor: '#f1f2f3' }}>
        <Dropzone onDrop={files => setReceipt(files[0])}>
          {({ getRootProps, getInputProps }) => (
            <DragContainer {...getRootProps()}>
              <input {...getInputProps()} accept="application/pdf" />
              <Icon name="cloud upload" size="huge" />
              <p style={{ marginTop: '20px' }}>
                {receipt === null ? (
                  <strong>Clique aqui para fazer upload do contrato</strong>
                ) : (
                  <strong>
                    O arquivo <i>{receipt.name}</i> está selecionado
                  </strong>
                )}
              </p>
            </DragContainer>
          )}
        </Dropzone>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          primary
          loading={loading}
          disabled={receipt === null}
          onClick={async () => {
            await onUpload(receipt, creditProfileSelected)

            return onClose()
          }}
        >
          Confirmar
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

UploadContractModal.propTypes = {
  onClose: t.func,
  visible: t.bool,
  onUpload: t.func,
  loading: t.bool,
  creditProfileSelected: t.objectOf(t.any),
}

export { UploadContractModal }
