import React from 'react'
import ContentLoader from 'react-content-loader'

const initialY = {
  avatar: 26,
  description: 20,
  button: 8,
}

const space = 53

const Form = () => (
  <ContentLoader
    speed={2}
    width={600}
    height={460}
    viewBox="0 0 600 460"
    backgroundColor="#f9f6f6"
    foregroundColor="#ecebeb"
  >
    <rect
      x="59"
      y={initialY.description}
      rx="3"
      ry="3"
      width="156"
      height="11"
    />
    <circle cx="25" cy={initialY.avatar} r="20" />
    <rect x="475" y={initialY.button} rx="0" ry="0" width="94" height="34" />
    {[1, 2, 3, 4].map(i => (
      <>
        <rect
          x="59"
          y={space * i + initialY.description}
          rx="3"
          ry="3"
          width="156"
          height="11"
        />
        <circle cx="25" cy={space * i + initialY.avatar} r="20" />
        <rect
          x="475"
          y={space * i + initialY.button}
          rx="0"
          ry="0"
          width="94"
          height="34"
        />
      </>
    ))}
  </ContentLoader>
)

export default Form
