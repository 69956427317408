import React from 'react'

import { Card, Form, Input, Divider, Button, Confirm } from 'semantic-ui-react'

const FormsItemsUpdate = ({
  item,
  index,
  state,
  disable,
  showConfirm,
  submitRemove,
  handlerInput,
  handlerTitleItem,
  handlerSubmit,
  cancelConfirm,
  addMultipeItem,
  renderSortableMultipleItems,
}) => {
  const { uid, order, question } = item
  return (
    <Card fluid key={`key-${index}`}>
      <Card.Content>
        <Form onSubmit={handlerSubmit}>
          <Form.Field>
            <Form.Field
              type="text"
              control={Input}
              id={`question${uid}`}
              placeholder={question}
              onChange={handlerInput}
              label={`${order}. Ordem`}
              value={state[`question${uid}`]}
            />
          </Form.Field>
        </Form>
        <Divider />
        <Input
          fluid
          focus
          type="text"
          disabled={disable}
          id="questionMultipleItem"
          onChange={handlerTitleItem}
          placeholder="Adicionar Item (Pressione ENTER para Salvar)"
          value={state[`questionMultipleItem${uid}`]}
          onKeyDown={e => addMultipeItem({ uid }, e)}
        />
        <Divider />
        {renderSortableMultipleItems(uid)}
      </Card.Content>
      <Card.Content extra>
        <Button
          basic
          color="red"
          content="Remover"
          onClick={() => showConfirm({ showConfirm: true, removeId: uid })}
        />
        <Confirm
          header="Alerta"
          cancelButton="Cancelar"
          confirmButton="Confirmar"
          open={state.showConfirm}
          onConfirm={submitRemove}
          content="Tem certeza deseja remover este item?"
          onCancel={() => cancelConfirm({ showConfirm: false })}
        />
      </Card.Content>
    </Card>
  )
}

export default FormsItemsUpdate
