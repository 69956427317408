import styled from 'styled-components'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border: 1px solid #f1f2f2;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
`

export const ContainerCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.position ? 'flex-start' : 'flex-end')};
`

export const ContainerDataCol = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  background-color: #f1f2f3;
  border-radius: 20px;
  margin-right: 10px;
`

export const Title = styled.span`
  font-size: 16px;
  font-weight: 800;
  color: #4d4d5f;

  @media (max-width: 800px) {
    font-size: 10px !important;
  }
`

export const TitleLink = styled.a`
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
  color: #4d4d5f;

  @media (max-width: 800px) {
    font-size: 10px !important;
  }
`

export const Subtitle = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: #a4a4a4;

  @media (max-width: 800px) {
    font-size: 8px !important;
  }
`

export const TitleValue = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #23cf5f;

  @media (max-width: 800px) {
    font-size: 10px !important;
  }
`

export const StatusLabel = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background-color: ${props => props.color};
`

export const StatusDescription = styled.span`
  margin-left: 10px;
  font-size: 14px;
  color: #a4a4a4;
  display: flex;
  flex-direction: column;
  font-weight: 800;

  @media (max-width: 800px) {
    font-size: 9px !important;
  }
`

export const StatusCPF = styled.span`
  color: ${props => props.color ?? 'gray'};
  font-weight: ${props => props.bold && 'bold'};
`
