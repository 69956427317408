import React from 'react'

import t from 'prop-types'
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  Image,
  Loader,
  Modal,
  Popup,
} from 'semantic-ui-react'

import { QUESTIONS_TYPES } from '~/utils/types'

const { MULTIPLE, SELECT_LIST, RATING } = QUESTIONS_TYPES

function CreateForm({
  showModal,
  type,
  dataFormsItems,
  onClose,
  onChange,
  submit,
  addAnswers,
  addAnswersUpdate,
  answers,
  removeAnswer,
  itemsMultiples,
  submitRemoveItemsMultiples,
  onClickOptionals,
  onClickUpload,
  onClickMoveAnswers,
  myRef,
  uploadImage,
  loadingFormsItems,
  remove,
  addExample,
  operatorWarning,
  onClickUpdateScore,
  onClickRemoveImage,
  questionsTypes,
}) {
  const typeHeader = type === 'create' ? 'Criar' : 'Atualizar'

  return (
    <Modal open={showModal} onClose={onClose}>
      <Modal.Header>
        <Header size="large">{typeHeader} Items do Formulário</Header>
      </Modal.Header>

      <Modal.Content>
        <Form size="large">
          <Form.Group widths={3}>
            <Form.Input
              label="Seção"
              type="number"
              id="section"
              value={dataFormsItems?.section || ''}
              onChange={(e, { value }) => onChange('section', value)}
              placeholder="Ex: 1"
            />

            <Form.Input
              label="Limite de Caracteres"
              type="number"
              id="length"
              value={dataFormsItems?.length || ''}
              onChange={(e, { value }) => onChange('length', value)}
              placeholder="Ex: 100"
            />

            <Form.Select
              label="Tipo de Pergunta"
              type="text"
              id="type"
              value={dataFormsItems?.type || ''}
              onChange={(e, { value }) => onChange('type', value)}
              placeholder="Selectione o tipo de pergunta"
              options={questionsTypes}
            />
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Input
              label="Pergunta"
              type="text"
              id="question"
              value={dataFormsItems?.question || ''}
              onChange={(e, { value }) => onChange('question', value)}
              placeholder="Digite a pergunta"
            />
            <Form.Input
              label="Chave BI"
              type="text"
              id="question_key"
              value={dataFormsItems?.question_key || ''}
              onChange={(e, { value }) => onChange('question_key', value)}
              placeholder="Digite a chave BI"
            />
          </Form.Group>

          {loadingFormsItems ? (
            <Dimmer active inverted>
              <Loader inverted />
            </Dimmer>
          ) : (
            <>
              {[MULTIPLE, SELECT_LIST, RATING].includes(
                dataFormsItems?.type
              ) && (
                <>
                  {type === 'create' && (
                    <Form.Group widths={1}>
                      <Form.Input
                        label="Respostas"
                        type="text"
                        id="items"
                        value={dataFormsItems?.items || ''}
                        onChange={(e, { value }) => onChange('items', value)}
                        placeholder="Digite a resposta"
                      />

                      <Form.Input
                        label="Pontuação"
                        placeholder="Digite a pontuação"
                        type="number"
                        onChange={(e, { value }) => onChange('score', value)}
                      />

                      {dataFormsItems?.type === RATING && (
                        <Form.Input
                          label="Pontuação da Classificação"
                          placeholder="Digite a pontuação da classificação"
                          type="number"
                          onChange={(e, { value }) =>
                            onChange('score_rating', value)
                          }
                        />
                      )}

                      <Popup
                        content="Adicionar Resposta"
                        trigger={
                          <Button
                            icon="add"
                            color="green"
                            onClick={addAnswers}
                          />
                        }
                      />
                    </Form.Group>
                  )}
                  {answers?.map(item => (
                    <Form.Group widths={1}>
                      <Form.Input value={item.title} transparent />
                      <Form.Input value={item.score} transparent />
                      <Form.Input value={item.score_rating} transparent />
                      <Popup
                        content="Remover Resposta"
                        trigger={
                          <Button
                            icon="trash"
                            onClick={() => removeAnswer(item)}
                          />
                        }
                      />
                    </Form.Group>
                  ))}
                  {type === 'update' && (
                    <>
                      <Form.Group>
                        <Form.Input
                          label="Respostas"
                          type="text"
                          id="items"
                          onChange={(e, { value }) => onChange('items', value)}
                          placeholder="Digite a resposta"
                        />
                        <Form.Input
                          label="Pontuação"
                          placeholder="Adicione uma pontuação"
                          type="number"
                          onChange={(e, { value }) => onChange('score', value)}
                        />
                        {dataFormsItems?.type === RATING && (
                          <Form.Input
                            label="Pontuação da Classificação"
                            placeholder="Digite a pontuação da classificação"
                            type="number"
                            onChange={(e, { value }) =>
                              onChange('score_rating', value)
                            }
                          />
                        )}
                        <Popup
                          content="Adicionar Resposta e Pontuação"
                          trigger={
                            <Button
                              content="+"
                              color="green"
                              onClick={addAnswersUpdate}
                            />
                          }
                        />
                      </Form.Group>

                      {itemsMultiples?.map((item, index) => (
                        <Form.Group key={item.uid} widths={3}>
                          <Form.Input value={item.title} />
                          <Popup
                            content="Remover resposta"
                            trigger={
                              <Button
                                disabled={loadingFormsItems}
                                icon="trash"
                                onClick={() => submitRemoveItemsMultiples(item)}
                              />
                            }
                          />
                          <Popup
                            content="Opcionais"
                            trigger={
                              <Button
                                disabled={loadingFormsItems}
                                icon="ellipsis vertical"
                                onClick={() => onClickOptionals(item)}
                              />
                            }
                          />
                          <input
                            id="uploadImage"
                            ref={myRef}
                            accept="image/*"
                            type="file"
                            onChange={uploadImage}
                            style={{ display: 'none' }}
                          />
                          <Popup
                            content="Enviar imagem"
                            trigger={
                              <Button
                                disabled={loadingFormsItems}
                                icon="file outline"
                                onClick={() => onClickUpload(item)}
                              />
                            }
                          />
                          {item?.url_image && (
                            <Popup
                              pinned
                              on="click"
                              content="Ver imagem"
                              trigger={
                                <Button
                                  disabled={loadingFormsItems}
                                  icon="eye"
                                />
                              }
                            >
                              <Grid centered>
                                <Grid.Column
                                  textAlign="center"
                                  verticalAlign="middle"
                                >
                                  <Grid.Row>
                                    <Image src={item.url_image} size="small" />
                                  </Grid.Row>
                                  <Grid.Row>
                                    <Button
                                      content="Remover"
                                      icon="trash"
                                      onClick={() => onClickRemoveImage(item)}
                                    />
                                  </Grid.Row>
                                </Grid.Column>
                              </Grid>
                            </Popup>
                          )}
                          {itemsMultiples.indexOf(item) === 0 && (
                            <Popup
                              content="Mover para baixo"
                              trigger={
                                <Button
                                  disabled={loadingFormsItems}
                                  icon="arrow down"
                                  onClick={() =>
                                    onClickMoveAnswers(item, index, 'down')
                                  }
                                />
                              }
                            />
                          )}
                          {itemsMultiples.indexOf(item) ===
                            itemsMultiples.length - 1 && (
                            <Popup
                              content="Mover para cima"
                              trigger={
                                <Button
                                  disabled={loadingFormsItems}
                                  icon="arrow up"
                                  onClick={() =>
                                    onClickMoveAnswers(item, index, 'up')
                                  }
                                />
                              }
                            />
                          )}
                          {itemsMultiples.indexOf(item) > 0 &&
                            itemsMultiples.indexOf(item) <
                              itemsMultiples.length - 1 && (
                              <>
                                <Popup
                                  content="Mover para baixo"
                                  trigger={
                                    <Button
                                      disabled={loadingFormsItems}
                                      icon="arrow down"
                                      onClick={() =>
                                        onClickMoveAnswers(item, index, 'down')
                                      }
                                    />
                                  }
                                />
                                <Popup
                                  content="Mover para cima"
                                  trigger={
                                    <Button
                                      disabled={loadingFormsItems}
                                      icon="arrow up"
                                      onClick={() =>
                                        onClickMoveAnswers(item, index, 'up')
                                      }
                                    />
                                  }
                                />
                              </>
                            )}
                          <Popup
                            content="Clique para alterar a pontuação"
                            trigger={
                              <Button
                                Button
                                content={item.score || 0}
                                onClick={() =>
                                  onClickUpdateScore('score', item)
                                }
                              />
                            }
                          />
                          {dataFormsItems?.type === RATING && (
                            <Popup
                              content="Clique para alterar a classificação"
                              trigger={
                                <Button
                                  Button
                                  content={item.score_rating || 0}
                                  onClick={() =>
                                    onClickUpdateScore('score_rating', item)
                                  }
                                />
                              }
                            />
                          )}
                        </Form.Group>
                      ))}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {type === 'create' ? (
          <Button size="large" positive content="Criar" onClick={submit} />
        ) : (
          <>
            <Button
              size="large"
              color="orange"
              content="Atualizar"
              onClick={submit}
            />
            <Button
              size="large"
              color="red"
              content="Remover"
              onClick={remove}
            />
            <Button
              size="large"
              color="grey"
              content="Operador de Alerta"
              onClick={operatorWarning}
            />
            <Button
              size="large"
              color="blue"
              content="Adicionar Exemplo"
              onClick={addExample}
            />
          </>
        )}
        <Button size="large" color="black" content="Sair" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  )
}

CreateForm.propTypes = {
  type: t.string,
  showModal: t.bool,
  onClose: t.func,
  onChange: t.func,
  submit: t.func,
  dataFormsItems: t.objectOf(t.any),
  addAnswers: t.func,
  answers: t.arrayOf(t.any),
  removeAnswer: t.func,
  itemsMultiples: t.arrayOf(t.any),
  submitRemoveItemsMultiples: t.func,
  onClickOptionals: t.func,
  onClickUpload: t.func,
  onClickMoveAnswers: t.func,
  addAnswersUpdate: t.func,
  myRef: t.objectOf(t.any),
  uploadImage: t.func,
  loadingFormsItems: t.bool,
  remove: t.func,
  addExample: t.func,
  operatorWarning: t.func,
  onClickUpdateScore: t.func,
  onClickRemoveImage: t.func,
  questionsTypes: t.arrayOf(t.objectOf(t.string)),
}

export default CreateForm
