import React from 'react'

import t from 'prop-types'
import { Card, Header, Icon, Image } from 'semantic-ui-react'

import avatarImg from '~/assets/avatar.png'

function ProgressHeader({ onClick, missionsUsersData }) {
  const avatar = missionsUsersData?.avatar
    ? missionsUsersData.avatar
    : avatarImg

  return (
    <>
      <Header as="h2">
        <Icon name="chevron circle left" link color="grey" onClick={onClick} />
        {missionsUsersData?.mu_key} | {missionsUsersData?.mission}
      </Header>

      <Card fluid>
        <Card.Content>
          <Card.Header>
            <Image src={avatar} avatar /> {missionsUsersData?.name}
          </Card.Header>
          <Card.Description>{missionsUsersData?.mission_key}</Card.Description>
          <Card.Description>
            {missionsUsersData?.place || 'Missão não tem localização definida'}
          </Card.Description>
        </Card.Content>
      </Card>
    </>
  )
}

ProgressHeader.propTypes = {
  onClick: t.func,
  missionsUsersData: t.arrayOf(t.any),
}

export default ProgressHeader
