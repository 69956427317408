import React from 'react'

import t from 'prop-types'
import { Button, Form, Modal } from 'semantic-ui-react'

function ReportProblemToSendModal({
  modalProblemToSend,
  setModalProblemToSend,
  setDisclaimerProblemToSend,
  disclaimerProblemToSend,
  confirmProblemToSend,
}) {
  return (
    <Modal
      size="small"
      open={modalProblemToSend}
      onClose={() => setModalProblemToSend(false)}
    >
      <Modal.Header>Qual é o problema do envio?</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.TextArea
            value={disclaimerProblemToSend}
            onChange={event => setDisclaimerProblemToSend(event.target.value)}
            maxLength="255"
            placeholder="Digital o motivo do problema do envio aqui"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setModalProblemToSend(false)}>Fechar</Button>
        <Button primary onClick={() => confirmProblemToSend()}>
          Confirmar
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

ReportProblemToSendModal.propTypes = {
  modalProblemToSend: t.bool,
  setModalProblemToSend: t.func,
  setDisclaimerProblemToSend: t.func,
  disclaimerProblemToSend: t.string,
  confirmProblemToSend: t.func,
}

export default ReportProblemToSendModal
