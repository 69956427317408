import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import t from 'prop-types'
import {
  Label,
  Icon,
  List,
  Button,
  Segment,
  Dimmer,
  Checkbox,
  Image,
  Loader,
} from 'semantic-ui-react'

import useRegisters from '~/hooks/useRegisters'
import Message from '~/utils/messages'

import { ContainerList, ContainerColumn, ContainerItems } from './styles'

const UserItem = ({ item, onChange }) => {
  const history = useHistory()
  const [userItem, setUserItem] = useState(null)
  const { registers, loadingRegisters, getRegistersByUserId } = useRegisters()

  function onClickRegisters(userId) {
    setUserItem(userId)
    return getRegistersByUserId({ userId, limit: 3, offset: 0 })
  }

  return (
    <List.Item>
      <ContainerList>
        <List.Content floated="left">
          <Checkbox
            value={item.uid}
            id={`item-${item.uid}`}
            checked={item.checked}
            onChange={({ target }) => onChange(target.value)}
          />
        </List.Content>
        <List.Content style={{ maxWidth: 300 }}>
          <List.Header as="a" onClick={() => history.push(`/user/${item.uid}`)}>
            {item.name.toUpperCase()}
          </List.Header>
          <Label horizontal size="medium" color="blue">
            <Icon name="map marker" /> {item.distance}
            <Label.Detail>km</Label.Detail>
          </Label>
          <Label horizontal size="medium" color="teal">
            <Icon name="rocket" /> {item.total_finished}
            <Label.Detail>Finalizadas</Label.Detail>
          </Label>
          <Label horizontal size="medium" color="teal">
            <Icon name="check" /> {item.total_approved}
            <Label.Detail>Aprovadas</Label.Detail>
          </Label>
          <Label horizontal size="medium" color="yellow">
            <Icon name="money bill alternate" /> {item.balance}
            <Label.Detail>Saldo</Label.Detail>
          </Label>
          <Label horizontal size="medium" color="green">
            <Icon name="money bill alternate" /> {item.paid}
            <Label.Detail>Arrecadou</Label.Detail>
          </Label>

          {loadingRegisters && item.uid === userItem && (
            <Segment>
              <Dimmer active>
                <Loader />
              </Dimmer>
            </Segment>
          )}

          {!loadingRegisters && registers.length > 0 && item.uid === userItem && (
            <ContainerItems>
              <List animated size="tiny">
                <List.Header>3 Últimos Realizados</List.Header>
                {registers.map(i => (
                  <List.Item>
                    <Image avatar src={i.missionAvatar} />
                    <List.Content>
                      <List.Header>{i.missionName}</List.Header>
                      {i.mu_key} ({format(new Date(i.updated_at), 'dd/MM/yyyy')}
                      )
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </ContainerItems>
          )}
        </List.Content>
        <List.Content floated="right">
          <ContainerColumn>
            {item.total_finished > 0 && (
              <Button
                icon
                size="small"
                color="orange"
                labelPosition="left"
                style={{ width: 100, marginBottom: 5 }}
                onClick={() => onClickRegisters(item.uid)}
              >
                <Icon name="fire" />
                Registros
              </Button>
            )}

            {item.phone !== null && (
              <>
                <Button
                  icon
                  size="small"
                  color="green"
                  labelPosition="left"
                  style={{ width: 100, marginBottom: 5 }}
                  onClick={() =>
                    window.open(
                      `https://api.whatsapp.com/send?phone=${item.phone}`
                    )
                  }
                >
                  <Icon name="whatsapp" />
                  Chamar
                </Button>
                <CopyToClipboard
                  text={item.phone}
                  onCopy={() => Message().success('Celular Copiado.')}
                >
                  <Button
                    icon
                    size="tiny"
                    color="blue"
                    labelPosition="left"
                    style={{ width: 100 }}
                  >
                    <Icon name="copy outline" />
                    Copiar
                  </Button>
                </CopyToClipboard>
              </>
            )}
          </ContainerColumn>
        </List.Content>
      </ContainerList>
    </List.Item>
  )
}

UserItem.propTypes = {
  item: t.objectOf(t.any).isRequired,
  onChange: t.func,
}

export default UserItem
