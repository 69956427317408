import React from 'react'

import t from 'prop-types'

export default function Main({ children }) {
  return <>{children}</>
}

Main.propTypes = {
  children: t.node,
}
