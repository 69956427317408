import React, { useState, useEffect } from 'react'
import Avatar from 'react-avatar'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import { Grid, Header, Divider } from 'semantic-ui-react'

import Graphic from '~/components/Charts/Basic'
import CustomTooltip from '~/components/Charts/CustomTooltip'
import { useAuth } from '~/contexts/auth'
import useDashboard from '~/hooks/useDashboard'

import Filters from './components/Filters'
import Metrics from './components/Metrics'

import 'react-count-animation/dist/count.min.css'

const currentYear = format(new Date(), 'yyyy')

export default function Dashboard() {
  const { user } = useAuth()
  const {
    reportMetrics,
    getReportMetrics,
    metrics: resumeMetrics,
    getMetrics,
    greaterValueReportMetric,
  } = useDashboard()

  const history = useHistory()

  const [filters, setFilters] = useState({
    status: 1,
    limit: 12,
    active: 'u',
    table: 'users',
    period: 'week',
    where: 'status',
    typeData: 'count',
    label: 'Usuários',
    year: currentYear,
    columnDate: 'created_at',
  })
  const [period, setPeriod] = useState('week')
  const [loading, setLoading] = useState(true)

  function onClickSearch(path, query, status = {}) {
    return history.push({ search: query, pathname: path, state: { status } })
  }

  const getDashboardResume = async periodSelected => {
    setLoading(true)

    await Promise.all([
      getMetrics(periodSelected),
      getReportMetrics({ ...filters, period: periodSelected }),
    ])

    return setLoading(false)
  }

  const changePeriod = periodSelected => {
    setPeriod(periodSelected)
    return getDashboardResume(periodSelected)
  }

  const isSelectedPeriod = value => {
    return period === value
  }

  function onFilter(item) {
    setFilters(prevState => ({ ...prevState, ...item, period }))
    return getReportMetrics({ ...filters, ...item })
  }

  useEffect(() => {
    getDashboardResume(period)
  }, []) // eslint-disable-line

  return (
    <>
      <Header as="h2" style={{ color: '#33334f' }}>
        <Avatar name={user?.name} size="50" round /> {user?.name} (
        {user?.role_name})
      </Header>
      <Divider />

      <Filters
        changePeriod={changePeriod}
        isSelectedPeriod={isSelectedPeriod}
      />

      <br />
      <Metrics
        data={resumeMetrics}
        loading={loading}
        history={history}
        onPress={onFilter}
        active={filters.active}
        onClickSearch={onClickSearch}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column className="content">
            <Graphic
              customYAxisDomain={[0, greaterValueReportMetric]}
              customTooltip={CustomTooltip}
              data={reportMetrics}
              active={period}
              periods={[]}
              label={filters.label}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}
