import api from '~/services/api'
import Message from '~/utils/messages'

export default function useFinancial() {
  async function updateUsersBudgetValues(params) {
    try {
      const res = await api.put('user/change/budget', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      Message().success('Valor atualizado com sucesso')

      return setTimeout(() => window.location.reload(), 300)
    } catch (error) {
      return Message().error(error.message)
    }
  }

  return {
    updateUsersBudgetValues,
  }
}
