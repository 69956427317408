import { useCallback, useState } from 'react'

import { saveAs } from 'file-saver'
import * as Yup from 'yup'

import api from '~/services/api'
import Message from '~/utils/messages'

const createPaymentSchema = Yup.object().shape({
  initial: Yup.date().required(),
  final: Yup.date()
    .default(null)
    .when(
      'initial',
      (initial, yup) =>
        initial && yup.min(initial, 'Data inicial deve ser menor que final.')
    )
    .required(),
  customer_id: Yup.string().uuid().required('Cliente é obrigatório.'),
})

export default function useFinancialCustomers() {
  const [creditItems, setCreditItems] = useState([])
  const [countCreditItems, setCountCreditItems] = useState(0)
  const [loadingCreditItems, setLoadingCreditItems] = useState(false)
  const [loadingUpdateCreditItem, setLoadingUpdateCreditItem] = useState(false)

  async function createCreditRequestLicensedPayment(data) {
    try {
      setLoadingUpdateCreditItem(true)
      const res = await api.post('credit-request/licensed-payment', data, {
        params: {
          request: true,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.data)
      }

      setLoadingUpdateCreditItem(false)

      return getCreditRequests()
    } catch (error) {
      setLoadingUpdateCreditItem(false)
      return Message().error(error.message)
    }
  }

  async function onSubmitCreditRequest(data) {
    try {
      const isValid = createPaymentSchema.isValidSync(data)

      if (!isValid) {
        const validateLabel = createPaymentSchema.validateSync(data)
        throw new Error(validateLabel)
      }

      setLoadingUpdateCreditItem(true)
      const res = await api.post('credit-request/month-end-closing', data, {
        params: {
          request: true,
        },
        responseType: 'blob',
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.data)
      }

      const blob = new Blob([res.data], { type: 'application/zip' })
      saveAs(blob, `recibos_${new Date().getTime()}.zip`)

      return getCreditRequests()
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingUpdateCreditItem(false)
    }
  }

  const getCreditRequests = useCallback(async params => {
    try {
      const res = await api.get('credit-request/all', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCountCreditItems(Number(res.data.allCreditRequest[0].total_rows))
      setLoadingCreditItems(false)
      return setCreditItems(res.data.allCreditRequest)
    } catch (error) {
      setLoadingCreditItems(false)
      return Message().error(error.message)
    }
  }, [])

  async function onUpdateCreditRequest(data) {
    try {
      setLoadingUpdateCreditItem(true)
      const res = await api.put('credit-request', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingUpdateCreditItem(false)

      return getCreditRequests()
    } catch (error) {
      setLoadingUpdateCreditItem(false)
      return Message().error(error.message)
    }
  }

  async function onCreateCloseRequestPayment(params) {
    try {
      const res = await api.post('credit-request/closed-payment', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return getCreditRequests()
    } catch (error) {
      return Message().error(error.message)
    }
  }

  return {
    creditItems,
    getCreditRequests,
    loadingCreditItems,
    onUpdateCreditRequest,
    onSubmitCreditRequest,
    loadingUpdateCreditItem,
    countCreditItems,
    createCreditRequestLicensedPayment,
    onCreateCloseRequestPayment,
  }
}
