import React from 'react'

import t from 'prop-types'
import { Image } from 'semantic-ui-react'

import { InitialContainer } from './styles'

function Avatar({ fullName, src }) {
  const initial = fullName.split(' ')[0].slice(0, 2).toUpperCase()

  return (
    <div>
      {src ? (
        <Image src={src} size="mini" floated="left" circular />
      ) : (
        <InitialContainer>{initial}</InitialContainer>
      )}
    </div>
  )
}

Avatar.propTypes = {
  fullName: t.string,
  src: t.string
}

export default Avatar
