import React, { useState, useEffect } from 'react'

import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Image, Table } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'

import TableComponent from '~/components/Table'
import api from '~/services/api'
import { statusRegisterLabel, statusRegisterColor } from '~/utils/feedsActions'
import { formatPrice } from '~/utils/format'
import message from '~/utils/messages'

const columns = [
  'Status',
  'Última Atualização',
  'Missão',
  'MU',
  'Valor',
  'Pontos',
  'Local',
]

const emptyText = {
  icon: 'check circle outline',
  text: 'Nenhum registro até o momento...',
}

export function RecentRegisters({ userId }) {
  const [userRegisters, setUserRegisters] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [activePage, setActivePage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [limit] = useState(15)
  const [count, setCount] = useState(null)
  const [totalCount, setTotalCount] = useState(null)

  function handlePaginationChange(_, params) {
    const newOffset = params.activePage * limit - limit

    setActivePage(params.activePage)
    setOffset(newOffset)
  }

  const currentMissionValue = value =>
    value?.user_point_value ? '####' : formatPrice(value?.mission_value)

  useEffect(() => {
    if (userId) {
      ;(async () => {
        try {
          setIsLoading(true)

          const res = await api.get(`registers/admin/users`, {
            params: {
              userId,
              limit,
              offset,
            },
          })
          setUserRegisters(res.data.registers)

          const resCount = Number(res.data.count)
          const value = resCount / limit
          setTotalCount(resCount)
          setCount(Math.ceil(value))

          setIsLoading(false)
        } catch (e) {
          setIsLoading(false)
          message().error(e.response.data.data || 'Houve um erro no servidor')
        }
      })()
    }
  }, [userId, offset, limit])

  const renderItem = item => (
    <Table.Row key={uuidv4()}>
      <Table.Cell
        style={{
          color: statusRegisterColor(item.registerStatus),
          fontWeight: 'bold',
        }}
      >
        {statusRegisterLabel(item.registerStatus)}
      </Table.Cell>
      <Table.Cell>
        {format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Image
          src={item.missionAvatar}
          size="mini"
          style={{ marginRight: '5px' }}
        />
        {item.missionName}
      </Table.Cell>
      <Table.Cell>{item.mu_key}</Table.Cell>
      <Table.Cell>{currentMissionValue(item)}</Table.Cell>
      <Table.Cell>{item.user_point_value}</Table.Cell>
      <Table.Cell>{item.place || '-'}</Table.Cell>
    </Table.Row>
  )

  return (
    <TableComponent
      limit={limit}
      data={userRegisters}
      renderItem={renderItem}
      count={count}
      totalCount={totalCount}
      activePage={activePage}
      offset={offset}
      columns={columns}
      handlePaginationChange={handlePaginationChange}
      isLoading={isLoading}
      siblingRange={1}
      boundaryRange={0}
      emptyText={emptyText}
      showEllipsis
      showFirstAndLastNav
      showPreviousAndNextNav
    />
  )
}

RecentRegisters.propTypes = {
  userId: PropTypes.string,
}
