import React, { useState } from 'react'

import t from 'prop-types'
import Drawer from 'rc-drawer'
import {
  List,
  Icon,
  Form,
  Grid,
  Card,
  Button,
  Loader,
  TextArea,
  Dropdown,
} from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import useUsers from '~/hooks/useUsers'
import Message from '~/utils/messages'

import UserItem from './Item'
import 'rc-drawer/assets/index.css'
import { ContainerMessage } from './styles'

const filters = [
  {
    key: 1,
    text: 'Distância',
    value: 'distance',
  },
  {
    key: 2,
    text: 'Aprovações',
    value: 'total_approved',
  },
  {
    key: 3,
    text: 'Finalizações',
    value: 'total_finished',
  },
]

const UsersNearby = ({
  open,
  close,
  mission,
  loading,
  onSubmitMore,
  onFilterUsers,
  usersNearby,
}) => {
  const { identifier, name } = mission
  const { sendMessagesToUsers, loadingNotify } = useUsers()

  const [message, setMessage] = useState('')
  const [selected, setSelected] = useState([])
  const [filter, setFilter] = useState(null)
  const [openMessage, setOpen] = useState(false)

  function onSubmitNotifications() {
    if (selected.length === 0) {
      return Message().error('Selecione pelo menos um usuário!')
    }

    if (message.length === 0) {
      return Message().error('Mensagem não pode estar vazia!')
    }

    return sendMessagesToUsers({ usersId: selected, message })
  }

  function onSubmitAll() {
    if (message.length === 0) {
      return Message().error('Mensagem não pode estar vazia!')
    }

    return sendMessagesToUsers({
      usersId: usersNearby.map(i => i.uid),
      message,
    })
  }

  function onSelected(itemChecked) {
    const index = selected.findIndex(i => i === itemChecked)

    if (index > -1) {
      const filtersSelected = selected.filter(i => i !== itemChecked)

      return setSelected(filtersSelected)
    }

    return setSelected([...selected, itemChecked])
  }

  const headerTitle = `Usuários próximos de ${name} ${identifier ? `(${identifier})` : ''
    }`

  return (
    <Drawer
      open={open}
      width="500px"
      placement="right"
      onClose={close}
      handler={false}
    >
      <Card style={{ width: '100vw' }}>
        <Card.Content header={headerTitle} />
        {openMessage && (
          <ContainerMessage>
            <Form>
              <TextArea
                rows={2}
                value={message || ''}
                onChange={(e, { value }) => setMessage(value)}
                placeholder="Ex: Olá #nome#! Tudo bem? Temos uma missão próxima de você."
              />
            </Form>
            <Button
              loading={loadingNotify}
              style={{ marginTop: 10 }}
              onClick={
                selected.length > 0 ? onSubmitNotifications : onSubmitAll
              }
            >
              {selected.length > 0
                ? `${selected.length} Encaminhar`
                : `Encaminhar para Todos`}
            </Button>
          </ContainerMessage>
        )}

        <Card.Content>
          <Form style={{ flexDirection: 'row', display: 'flex' }}>
            <Form.Field>
              <Dropdown
                text="Filtro"
                icon="filter"
                floating
                labeled
                basic
                button
                defaultValue={filter}
                className="icon"
                options={filters}
                onChange={(e, { value }) => {
                  setFilter(value)
                  return onFilterUsers({ item: mission, orderBy: value })
                }}
              />
            </Form.Field>
            <Form.Field>
              <Button
                icon
                basic
                labelPosition="left"
                onClick={() => setOpen(prevState => !prevState)}
              >
                <Icon name="bullhorn" />
                Enviar Notificação
              </Button>
            </Form.Field>
          </Form>
        </Card.Content>
        <Card.Content>
          {loading && <Loader />}

          {!loading && usersNearby.length === 0 && (
            <EmptyList icon="users" text="Nenhum usuário por perto..." />
          )}

          <List divided verticalAlign="middle" relaxed="very">
            {usersNearby.length > 0 &&
              usersNearby.map(item => (
                <UserItem onChange={onSelected} key={item.uid} item={item} />
              ))}
          </List>
          <Grid centered columns={1}>
            <Grid.Column>
              <Button
                compact
                attached="bottom"
                loading={loading}
                disabled={loading}
                content="Carregar mais..."
                onClick={() => {
                  onSubmitMore({ item: mission, orderBy: filter })
                }}
              />
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>
    </Drawer>
  )
}

UsersNearby.propTypes = {
  onSubmitMore: t.func,
  loading: t.bool,
  open: t.bool,
  close: t.func,
  onFilterUsers: t.func,
  usersNearby: t.arrayOf(t.any),
  mission: t.objectOf(t.any),
}

export default UsersNearby
