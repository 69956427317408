import styled from 'styled-components'

export const CardFilter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
`

export const ContainerFields = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
`
