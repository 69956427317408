import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Button, Form, Modal, Rating, Table } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import Loading from '~/components/Loading'

export function AddRatingModal({
  open,
  onClose,
  missionsUsersRating,
  loadingMissionsUsersRating,
  user,
  onSubmitRating,
  cardDate,
}) {
  const colunms = ['Data', 'Descrição', 'Avaliação']
  const [createData, setCreateData] = useState({})

  function onChange(key, value) {
    setCreateData(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  function handleOnClickSubmitRating() {
    onSubmitRating({
      missionsUsersId: user?.uid,
      ...createData,
    })

    setCreateData({})
  }

  function hasMissionsUsersRating() {
    return missionsUsersRating && missionsUsersRating.length === 0
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="small"
      onMount={() =>
        setCreateData(prevState => ({
          ...prevState,
          date: dayjs(cardDate).$d,
        }))
      }
    >
      {loadingMissionsUsersRating ? (
        <Loading customText="Carregando Informações" />
      ) : (
        <>
          <Modal.Header>
            Avaliações - {user?.name} - {user?.mission} -{' '}
            {dayjs(cardDate).format('DD/MM/YYYY')}
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Group inline>
                <Form.Field
                  control={ReactDatePicker}
                  width={8}
                  placeholderText="Data"
                  dateFormat="dd/MM/yyyy"
                  selected={createData?.date}
                  onChange={value => onChange('date', value)}
                />
                <Form.Input
                  placeholder="Descrição da Nova Avaliação"
                  width={16}
                  onChange={(e, { value }) => onChange('description', value)}
                />

                <Form.Field width={8}>
                  <Rating
                    maxRating={5}
                    size="huge"
                    icon="star"
                    onRate={(e, { rating }) => onChange('rating', rating)}
                  />
                </Form.Field>
                <Form.Button
                  onClick={handleOnClickSubmitRating}
                  content="Adicionar"
                  color="green"
                  width={5}
                />
              </Form.Group>
            </Form>

            {!hasMissionsUsersRating() ? (
              <EmptyList icon="list" text="Nenhuma Avaliação foi Encontrada" />
            ) : (
              <Table>
                <Table.Header>
                  <Table.Row>
                    {colunms.map(colunm => (
                      <Table.HeaderCell>{colunm}</Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {missionsUsersRating.map(murData => (
                    <Table.Row>
                      <Table.Cell>
                        {dayjs(murData.created_at).format('DD/MM/YYYY')}
                      </Table.Cell>
                      <Table.Cell>{murData.description}</Table.Cell>
                      <Table.Cell>
                        <Rating
                          rating={murData.rating}
                          maxRating={5}
                          icon="star"
                          disabled
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
          </Modal.Content>
        </>
      )}
      <Modal.Actions>
        <Button content="Cancelar" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  )
}

AddRatingModal.propTypes = {
  open: t.bool,
  onClose: t.func,
  missionsUsersRating: t.arrayOf(t.objectOf(t.string)),
  loadingMissionsUsersRating: t.bool,
  user: t.objectOf(t.string),
  onSubmitRating: t.func,
  cardDate: t.string,
}
