import React, { useEffect, useState, useCallback } from 'react'

import { format } from 'date-fns'
import _ from 'lodash'
import { length } from 'ramda'
import { Card, Icon, Button, Table, Loader } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import { useAuth } from '~/contexts/auth'
import '~/assets/css/react-datepicker.css'
import api from '~/services/api'
import message from '~/utils/messages'

import CreatePromotion from './components/CreatePromotion'

export default function Promotions() {
  const [promotions, setPromotions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [updatedPromotions, setUpdatedPromotions] = useState([])
  const [itemsUidsUpdating, setItemsUidsUpdating] = useState([])

  const [promotionCreateActive, setPromotionCreateActive] = useState(false)

  const { user } = useAuth()

  const getPromotions = useCallback(async () => {
    try {
      setIsLoading(true)
      const res = await api.get('config-campaigns-member')
      setPromotions(res.data)
    } catch (error) {
      message().error(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const onCreatePromotionSuccess = useCallback(
    data => {
      const newPromotions = _.cloneDeep(promotions)
      newPromotions.unshift(data)
      setPromotions(newPromotions)
    },
    [promotions]
  )

  useEffect(() => {
    getPromotions()
  }, [getPromotions])

  useEffect(() => {
    if (
      updatedPromotions.length > 0 &&
      updatedPromotions.length === itemsUidsUpdating.length
    ) {
      const newPromotions = _.cloneDeep(promotions)
      updatedPromotions.forEach(updatedItem => {
        const idx = newPromotions.findIndex(np => np.uid === updatedItem.uid)
        newPromotions[idx] = updatedItem
      })

      setUpdatedPromotions([])
      setItemsUidsUpdating([])
      setPromotions(newPromotions)
    }
  }, [itemsUidsUpdating, promotions, updatedPromotions])

  const changeStatus = async item => {
    try {
      setItemsUidsUpdating(uids => uids.concat(item.uid))

      const res = await api.put(`config-campaigns-member/${item.uid}`, {
        ...item,
        active: !item.active,
      })

      const updatedItem = res.data
      setUpdatedPromotions(p => p.concat(updatedItem))

      message().success('Atualizado com sucesso!')
    } catch (error) {
      setItemsUidsUpdating(uids => uids.filter(uid => uid !== item.uid))
      message().error(error.message)
    }
  }

  const isItemUpdating = uid => {
    if (itemsUidsUpdating.includes(uid)) {
      return true
    }

    return false
  }

  if (user?.role_name === 'Basic' || user?.role_name === 'Leader') {
    return <EmptyList icon="lock" text="Acesso restrito" />
  }

  return (
    <>
      <div className="container">
        <Button
          onClick={() => setPromotionCreateActive(!promotionCreateActive)}
          color={promotionCreateActive ? 'red' : 'blue'}
        >
          {promotionCreateActive ? 'Fechar' : 'Nova Promoção'}
        </Button>

        {promotionCreateActive && (
          <Card fluid>
            <Card.Content header="Promoção" />
            <Card.Content>
              <CreatePromotion
                onCreatePromotionSuccess={onCreatePromotionSuccess}
              />
            </Card.Content>
          </Card>
        )}

        {isLoading ? (
          <div>
            <Loader active inline="centered" />
          </div>
        ) : (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Início</Table.HeaderCell>
                <Table.HeaderCell>Fim</Table.HeaderCell>
                <Table.HeaderCell>
                  <Icon name="user" /> Usuários
                </Table.HeaderCell>
                <Table.HeaderCell>Ações</Table.HeaderCell>
                <Table.HeaderCell>Porcentagem</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {length(promotions) > 0 &&
                promotions.map(item => (
                  <Table.Row key={item.uid}>
                    <Table.Cell>
                      {format(new Date(item.initial_ts), 'dd/MM/yyyy HH:mm')}
                    </Table.Cell>
                    <Table.Cell>
                      {format(new Date(item.final_ts), 'dd/MM/yyyy HH:mm')}
                    </Table.Cell>
                    <Table.Cell>
                      {Number(item.percentage / 100).toLocaleString(undefined, {
                        style: 'percent',
                        minimumFractionDigits: 2,
                      })}
                    </Table.Cell>
                    <Table.Cell>{item.max_users}</Table.Cell>
                    <Table.Cell>
                      <Button
                        basic
                        fluid
                        loading={isItemUpdating(item.uid)}
                        disabled={isItemUpdating(item.uid)}
                        color={item.active ? 'red' : 'green'}
                        onClick={() => changeStatus(item)}
                      >
                        {item.active ? 'Desativar' : 'Ativar'}
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        )}
      </div>
    </>
  )
}
