import { useState } from 'react'

import axios from 'axios'

import { GOOGLE_API_KEY } from '~/config/env'
import Message from '~/utils/messages'

export default function useGeo() {
  const [addresses, setAddresses] = useState([])
  const [loadingAddresses, setLoadingAddresses] = useState(false)
  const [addressByCordinates, setAddressByCordinates] = useState('')

  async function onSearchAddress(address) {
    try {
      setLoadingAddresses(true)
      const res = await axios.get(
        'https://maps.google.com/maps/api/geocode/json',
        {
          params: {
            address,
            language: 'pt-BR',
            key: GOOGLE_API_KEY,
            components: 'country:BR',
          },
          validateStatus() {
            return true
          },
        }
      )

      console.log(addresses)

      if (res.status !== 200) {
        throw new Error('Google API não está funcionando.')
      }

      return setAddresses(res.data.results)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingAddresses(false)
    }
  }

  function onGeocodeFormat(unformatted) {
    return new Promise(resolve => {
      const reducedAddress = unformatted
        .map(component => {
          if (component.types.includes('street_number')) {
            return {
              number: component.long_name,
            }
          }
          if (component.types.includes('route')) {
            return {
              street: component.long_name,
            }
          }
          if (
            component.types.includes('political') &&
            (component.types.includes('sublocality') ||
              component.types.includes('sublocality_level_1'))
          ) {
            return {
              neighborhood: component.long_name,
            }
          }
          if (
            component.types.includes('administrative_area_level_2') &&
            component.types.includes('political')
          ) {
            return {
              city: component.long_name,
            }
          }
          if (
            component.types.includes('administrative_area_level_1') &&
            component.types.includes('political')
          ) {
            return {
              state: component.short_name,
            }
          }
          if (
            component.types.includes('country') &&
            component.types.includes('political')
          ) {
            return {
              country: component.long_name,
            }
          }

          return {
            postal_code: component.long_name,
          }
        })
        .reduce((accumulator, current) => Object.assign(accumulator, current))

      resolve(reducedAddress)
    })
  }

  async function getAddressByCordinates(latitude, longitude) {
    try {
      setLoadingAddresses(true)
      const res = await axios.get(
        'https://maps.google.com/maps/api/geocode/json',
        {
          params: {
            latlng: `${latitude},${longitude}`,
            key: GOOGLE_API_KEY,
          },
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error('Google API não está funcionando.')
      }

      return setAddressByCordinates(res.data.results[0].formatted_address)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingAddresses(false)
    }
  }

  return {
    addresses,
    setAddresses,
    onSearchAddress,
    onGeocodeFormat,
    loadingAddresses,
    getAddressByCordinates,
    addressByCordinates,
  }
}
