import { Card } from 'semantic-ui-react'
import styled from 'styled-components'

export const StyledCard = styled(Card)`
  width: 100% !important;
  display: inline-block !important;

  @media (min-width: 850px) {
    margin: 8px !important;
    width: 48% !important;
  }

  @media (min-width: 1076px) {
    width: 35% !important;
  }

  @media (min-width: 1210px) {
    width: 30% !important;
  }

  @media (min-width: 1485px) {
    width: 24% !important;
  }
`
