import React from 'react'

import t from 'prop-types'
import { Table } from 'semantic-ui-react'

import { formatPrice } from '~/utils/format'

function FooterItem({ sumTotals }) {
  return (
    <Table.Footer style={{ backgroundColor: '#f1f2f3' }}>
      <Table.Row style={{ fontWeight: 'bold' }}>
        <Table.Cell colSpan="5">Total</Table.Cell>
        <Table.Cell>{sumTotals.total}</Table.Cell>
        <Table.Cell colSpan="2" />
        <Table.Cell>
          {sumTotals.total_customer_value > 0
            ? formatPrice(sumTotals.total_customer_value)
            : formatPrice(sumTotals.total_customer_value_a)}
        </Table.Cell>
        <Table.Cell>
          {sumTotals.total_user_value > 0
            ? formatPrice(sumTotals.total_user_value)
            : formatPrice(sumTotals.total_user_value_a)}
        </Table.Cell>
        <Table.Cell>
          {Number(sumTotals.total_users_point_value).toFixed(2)}
        </Table.Cell>
      </Table.Row>
    </Table.Footer>
  )
}

FooterItem.propTypes = {
  sumTotals: t.objectOf(t.number),
}

export default FooterItem
