import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Icon, Card, Feed, Popup, Button } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'

import { feedIcon, feedLabel, iconColor } from '~/utils/feedsActions'

export function MissionsFeeds({
  feeds,
  copy,
  textCopy,
  loadMoreFeeds,
  onCopied,
  goToPoint,
  isLoading,
}) {
  return (
    <Card style={{ maxHeight: 600, overflow: 'auto' }}>
      <Card.Content>
        <Card.Header>Atividades Recentes</Card.Header>
      </Card.Content>
      <Card.Content>
        <Feed>
          {feeds?.map(item => (
            <Popup
              on="click"
              size="large"
              hideOnScroll
              position="top left"
              key={uuidv4()}
              onOpen={() => copy(item.identifier)}
              trigger={
                <Feed.Event onClick={() => goToPoint(item)}>
                  <Feed.Label>
                    <Icon
                      name={feedIcon(item.action)}
                      color={iconColor(item.action)}
                    />
                  </Feed.Label>
                  <Feed.Content>
                    <Feed.Summary>
                      <Feed.User>{feedLabel(item.action)}</Feed.User>{' '}
                      {item.name}
                      <Feed.Date>
                        {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
                      </Feed.Date>
                    </Feed.Summary>
                  </Feed.Content>
                </Feed.Event>
              }
            >
              <Popup.Header>Identificador (Copiar)</Popup.Header>
              <Popup.Content>
                <CopyToClipboard text={textCopy} onCopy={onCopied}>
                  <Button>
                    {item.identifier ? item.identifier : 'Sem identificador'}
                  </Button>
                </CopyToClipboard>
              </Popup.Content>
            </Popup>
          ))}
        </Feed>
        <Button
          fluid
          compact
          loading={isLoading}
          disabled={isLoading}
          content="Carregar mais..."
          onClick={loadMoreFeeds}
        />
      </Card.Content>
    </Card>
  )
}

MissionsFeeds.propTypes = {
  feeds: PropTypes.arrayOf(PropTypes.object),
  copy: PropTypes.func,
  textCopy: PropTypes.string,
  loadMoreFeeds: PropTypes.func,
  onCopied: PropTypes.func,
  goToPoint: PropTypes.func,
  isLoading: PropTypes.bool,
}
