import React from 'react'

import t from 'prop-types'
import { Grid, Form } from 'semantic-ui-react'

import { missionsMainProps } from '../missionsMainProps'

export function Info({ getValues, openField, onChange }) {
  return (
    <Grid.Column width={5}>
      <Form.Input
        type="text"
        name="title"
        defaultValue={getValues('title')}
        onChange={onChange}
        label="Título (Obrigatório)"
        placeholder="Ex: Pesquisas"
      />

      <Form.Input
        name="time_to_appreciate"
        defaultValue={getValues('time_to_appreciate')}
        onChange={onChange}
        type="number"
        label="Tempo de Aprovação(hrs) (Obrigatório)"
        placeholder="Ex: 10"
      />

      <Form.Input
        name="expiring_time"
        defaultValue={getValues('expiring_time')}
        onChange={onChange}
        type="number"
        label="Expiração(hrs) (Obrigatório)"
        placeholder="Ex: 10"
      />

      <Form.Input
        name="fix_time"
        defaultValue={getValues('fix_time')}
        onChange={onChange}
        type="number"
        label="Tempo de Realização para Correção"
        placeholder="Ex: 10"
      />

      <Form.Input
        type="text"
        name="customer_value"
        defaultValue={getValues('customer_value')}
        onChange={onChange}
        placeholder="Ex: 30"
        disabled={false}
        label="Valor Cobrado do Cliente (Obrigatório)"
      />

      <Form.Input
        type="text"
        placeholder="Ex: 20"
        name="user_value"
        defaultValue={getValues('user_value')}
        onChange={onChange}
        disabled={false}
        label="Valor Pago ao Usuário (Obrigatório)"
      />

      <Form.Input
        type="text"
        placeholder="Ex: Ganhe R$ 50"
        name="label_value"
        defaultValue={getValues('label_value')}
        onChange={onChange}
        disabled={false}
        label="Valor em Texto"
      />

      {openField?.has_value_prime ? (
        <Form.Input
          name="user_value_prime"
          defaultValue={getValues('user_value_prime')}
          onChange={onChange}
          type="text"
          label="Valor Pago ao Usuário Prime"
          placeholder="Ex: 20"
        />
      ) : null}

      {openField?.has_value_black ? (
        <Form.Input
          name="user_value_black"
          defaultValue={getValues('user_value_black')}
          onChange={onChange}
          type="text"
          label="Valor Pago ao Usuário Black"
          placeholder="Ex: 20"
        />
      ) : null}

      {openField?.has_value_percentage ? (
        <Form.Input
          name="mission_commission_percent"
          defaultValue={getValues('mission_commission_percent')}
          onChange={onChange}
          type="text"
          label="Valor Comissão Fixado ao Mission"
          placeholder="Ex: 20"
        />
      ) : null}

      {openField?.has_bonus ? (
        <div style={styles.bonus}>
          <Form.Input
            type="text"
            placeholder="Ex: 30"
            name="customer_value_bonus"
            defaultValue={getValues('customer_value_bonus')}
            onChange={onChange}
            label="Valor Cobrado do Cliente Bônus (Obrigatório)"
          />
          <Form.Input
            type="text"
            placeholder="Ex: 20"
            name="user_value_bonus"
            defaultValue={getValues('user_value_bonus')}
            onChange={onChange}
            label="Valor Pago ao Usuário Bônus (Obrigatório)"
          />
        </div>
      ) : null}

      <Form.Input
        name="participants"
        defaultValue={getValues('participants')}
        onChange={onChange}
        type="number"
        label="N˚ de Participante(s) (Obrigatório)"
        placeholder="Ex: 20"
      />

      {openField?.has_points ? (
        <>
          <Form.Input
            type="number"
            placeholder="Ex: 10"
            name="user_point_value"
            defaultValue={getValues('user_point_value')}
            onChange={onChange}
            label="Valor em pontos (Obrigatório)"
          />
          <Form.Input
            type="text"
            name="points_label"
            defaultValue={getValues('points_label')}
            onChange={onChange}
            label="Nome da pontuação"
            placeholder="Ex: Bitcoins"
          />
        </>
      ) : null}

      {openField?.has_forms_items_overall ? (
        <Form.Input
          type="number"
          placeholder="Ex: 30"
          name="forms_items_overall_target"
          defaultValue={getValues('forms_items_overall_target')}
          onChange={onChange}
          label="Meta da Pontuação Média do Forms Items"
        />
      ) : null}
    </Grid.Column>
  )
}

const styles = {
  bonus: {
    backgroundColor: '#d8edff',
    padding: 5,
  },
}

Info.propTypes = {
  getValues: missionsMainProps.values,
  openField: missionsMainProps.values,
  onChange: t.func.isRequired,
}
