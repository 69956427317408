import React, { memo } from 'react'

import t from 'prop-types'

import { Container, Title, TitleValue, ContainerCol } from './styles'

const BudgetHeaderData = ({ paymentsNumbersInfo }) => (
  <Container>
    <ContainerCol>
      <Title>Aguardando Pagamento</Title>
      <TitleValue>{paymentsNumbersInfo?.payments_pendent_count}</TitleValue>
    </ContainerCol>
    <ContainerCol>
      <Title>Pagamentos Aprovados</Title>
      <TitleValue>{paymentsNumbersInfo?.payment_done_count}</TitleValue>
    </ContainerCol>
    <ContainerCol>
      <Title>Total Pago</Title>
      <TitleValue>R${paymentsNumbersInfo?.payment_count}</TitleValue>
    </ContainerCol>
  </Container>
)

BudgetHeaderData.propTypes = {
  paymentsNumbersInfo: t.objectOf(t.any),
}

export default memo(BudgetHeaderData)
