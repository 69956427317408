import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'
import { OPTIONS_TYPE_INPUTS } from '~/utils/types'

const TYPE_SELECT = 'SELECT'

export default function useChallenge() {
  const [isLoading, setIsLoading] = useState(false)
  const [challenges, setChallenges] = useState({})
  const [typeChallenges, setTypeChallenges] = useState([])
  const [typeChallengeParams, setTypeChallengeParams] = useState([])

  const prepareDataToPersist = data => {
    const registers = data.params.map(item => {
      if (item.type === TYPE_SELECT) {
        item.options = OPTIONS_TYPE_INPUTS[TYPE_SELECT]
      }

      return item
    })

    data.params = registers

    return data
  }

  const addTypeChallengeParams = event => {
    event.preventDefault()
    const newParams = {
      label: '',
      type: '',
      paramName: '',
    }
    setTypeChallengeParams([...typeChallengeParams, newParams])
  }

  const disableChallenge = async id => {
    try {
      const { data } = await api.put(`/challenges/${id}/disable`)

      Message().success('Desafio foi desabilitado com sucesso.')

      const registers = challenges.data.map(item => {
        if (item.id === id) {
          item = data
        }

        return item
      })

      challenges.data = registers

      return setChallenges({ ...challenges })
    } catch (error) {
      return Message().error(error.message)
    }
  }

  const enableChallenge = async id => {
    try {
      const { data } = await api.put(`/challenges/${id}/enable`)

      Message().success('Desafio foi habilitado com sucesso.')

      const registers = challenges.data.map(item => {
        if (item.id === id) {
          item = data
        }

        return item
      })

      challenges.data = registers
      setChallenges(challenges)
    } catch (error) {
      Message().error(error.message)
    }
  }

  const getChallenges = async (page = 1, filters = {}) => {
    try {
      setIsLoading(true)
      const querystring = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&')

      const { data } = await api.get(`/challenges`, {
        params: {
          page,
          querystring,
        },
      })
      setChallenges(data)
      return data
    } catch (error) {
      Message().error(
        error.response.data.message || 'Ocorreu algum erro inesperado.'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const createChallenge = async data => {
    try {
      await api.post('/challenges', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      Message().success('Desafio criado com sucesso.')
    } catch (error) {
      Message().error(
        error.response.data.message || 'Ocorreu algum erro inesperado.'
      )
    }
  }

  const getTypeChallengeById = async id => {
    try {
      const { data } = await api.get(`/type-challenges/${id}`)

      return data
    } catch (error) {
      return Message().error(error.message)
    }
  }

  const removeTypeChalllenge = async id => {
    try {
      await api.delete(`/type-challenges/${id}`)

      await getTypeChallenges()

      Message().success('Tipo de desafio removido com sucesso.')
    } catch (error) {
      Message().error(error.message)
    }
  }

  const updateTypeChallenge = async (id, data) => {
    try {
      setIsLoading(true)

      await api.put(`/type-challenges/${id}`, data)

      Message().success('Tipo de desafio atualizado com sucesso.')
    } catch (error) {
      Message().error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const createTypeChallenge = async data => {
    try {
      await api.post('type-challenges', data)

      Message().success('Tipo de desafio criado com sucesso.')
    } catch (error) {
      Message().error(
        error.response.data.message || 'Ocorreu algum erro inesperado.'
      )
    }
  }

  const getTypeChallenges = useCallback(async (fields = ['id', 'name']) => {
    const { data } = await api.get('type-challenges', {
      params: { fields: fields.join(', ') },
    })

    setTypeChallenges(data)

    return data
  }, [])

  return {
    typeChallenges,
    getTypeChallenges,
    removeTypeChalllenge,
    createTypeChallenge,
    getTypeChallengeById,
    updateTypeChallenge,
    addTypeChallengeParams,
    typeChallengeParams,
    setTypeChallengeParams,
    prepareDataToPersist,
    createChallenge,
    getChallenges,
    challenges,
    isLoading,
    enableChallenge,
    disableChallenge,
  }
}
