import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useExportationMissionImported() {
  const [exportationsRecently, setExportationRecently] = useState([])

  async function getExportationsRecently() {
    try {
      const response = await api.get(`/import-files/exportations`)
      setExportationRecently(response.data)
    } catch (error) {
      Message().error(
        error?.response?.data?.message || 'Oops! Aconteceu um erro inesperado.'
      )
    }
  }

  async function exportMissionsImported(item) {
    try {
      const response = await api.get(`/import-files/${item.uid}/exportation`)

      if (response.data.url) {
        const aElement = document.createElement('a')
        aElement.href = response.data.url
        aElement.target = '_blank'
        aElement.click()
        return
      }

      Message().success(
        `Exportação das missões da importação ${item.name} será gerada em alguns segundos.`
      )
      getExportationsRecently()
    } catch (error) {
      Message().error(
        error?.response?.data?.message || 'Oops! Aconteceu um erro inesperado.'
      )
    }
  }
  return {
    getExportationsRecently,
    exportMissionsImported,
    exportationsRecently,
  }
}
