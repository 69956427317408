import React from 'react'

import t from 'prop-types'
import {
  Button,
  Card,
  FormInput,
  FormSelect,
  Grid,
  GridColumn,
} from 'semantic-ui-react'

const optionsRequiredFields = [
  { key: 'yes', text: 'Sim', value: true },
  { key: 'no', text: 'Não', value: false },
]

export const optionsTypesFormItem = [
  {
    key: 'rc',
    icon: 'file text',
    text: 'Resposta Curta',
    value: 1,
  },
  {
    key: 'rl',
    icon: 'file text',
    text: 'Resposta Longa',
    value: 2,
  },
  {
    key: 'dt',
    icon: 'calendar',
    text: 'Data',
    value: 5,
  },
  {
    key: 'hr',
    icon: 'clock',
    text: 'Hora',
    value: 6,
  },
  {
    key: 'num',
    icon: 'sort numeric ascending',
    text: 'Número',
    value: 7,
  },
  {
    key: 'dec',
    icon: 'sort numeric ascending',
    text: 'Preço (R$)',
    value: 8,
  },
  {
    key: 'cpf',
    icon: 'sort numeric ascending',
    text: 'CPF',
    value: 9,
  },
  {
    key: 'cnpj',
    icon: 'sort numeric ascending',
    text: 'CNPJ',
    value: 10,
  },
  {
    key: 'cep',
    icon: 'sort numeric ascending',
    text: 'CEP',
    value: 11,
  },
  {
    key: 'phone',
    icon: 'sort numeric ascending',
    text: 'Telefone',
    value: 12,
  },
  {
    key: 'address',
    icon: 'address',
    text: 'Endereço',
    value: 13,
  },
  {
    key: 'text',
    icon: 'text',
    text: 'Apenas Texto',
    value: 14,
  },
  {
    key: 'email',
    icon: 'email',
    text: 'E-mail',
    value: 15,
  },
  {
    key: 'camera',
    icon: 'camera',
    text: 'Camera (Foto)',
    value: 16,
  },
]

const DeliveryPlaceCustomFields = ({
  onAddDeliveryPlaceCustomField,
  deliveryPlacesCustomFields,
  onRemoveDeliveryPlaceCustomField,
  onChangeDeliveryPlaceCustomFieldInput,
}) => (
  <Grid columns={1} relaxed>
    <Grid.Column width={16}>
      <div className="field">
        <label>Ponto de entrega - Campos customizados (Opcional)</label>
        <Card fluid>
          <Card.Content>
            <Button
              primary
              style={{ marginBottom: '10px' }}
              onClick={onAddDeliveryPlaceCustomField}
            >
              Novo campo
            </Button>

            {deliveryPlacesCustomFields.map((item, index) => (
              <Grid columns={4}>
                <GridColumn>
                  <FormInput
                    value={item.name}
                    fluid
                    label="Digite o nome do campo customizado?"
                    placeholder=""
                    onChange={event =>
                      onChangeDeliveryPlaceCustomFieldInput(
                        'name',
                        event.target.value,
                        index
                      )
                    }
                  />
                </GridColumn>
                <GridColumn>
                  <FormSelect
                    value={item.type}
                    fluid
                    label="Selecionar tipo do campo"
                    options={optionsTypesFormItem}
                    placeholder="Selecionar tipo do campo"
                    onChange={(_e, data) => {
                      onChangeDeliveryPlaceCustomFieldInput(
                        'type',
                        data.value,
                        index
                      )
                    }}
                  />
                </GridColumn>
                <GridColumn>
                  <FormSelect
                    value={deliveryPlacesCustomFields[index].required}
                    fluid
                    label="Campo é obrigatória?"
                    options={optionsRequiredFields}
                    placeholder=""
                    onChange={(event, data) => {
                      console.log(data.value)
                      onChangeDeliveryPlaceCustomFieldInput(
                        'required',
                        data.value,
                        index
                      )
                    }}
                  />
                </GridColumn>
                <GridColumn>
                  <Button
                    style={{ marginTop: '22px' }}
                    onClick={event =>
                      onRemoveDeliveryPlaceCustomField(event, index)
                    }
                    icon="trash"
                    color="red"
                  />
                </GridColumn>
              </Grid>
            ))}
          </Card.Content>
        </Card>
      </div>
    </Grid.Column>
  </Grid>
)

DeliveryPlaceCustomFields.propTypes = {
  onAddDeliveryPlaceCustomField: t.func,
  deliveryPlacesCustomFields: t.arrayOf(t.object),
  onRemoveDeliveryPlaceCustomField: t.func,
  onChangeDeliveryPlaceCustomFieldInput: t.func,
}

export default DeliveryPlaceCustomFields
