import React, { useEffect, useState } from 'react'

import { format } from 'date-fns'
import { Button, Card, Table, Icon } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useStore from '~/hooks/useStore'

import ConfirmUpdate from './components/ConfirmUpdate'
import CreateOrEdit from './components/CreateOrEdit'

function StoreCategories() {
  const columns = ['Nome', 'Criado em', 'Atualizado em', 'Ações']
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 15,
    activePage: 1,
  })

  const [modalVisible, setModalVisible] = useState(false)
  const [productCategoryData, setProductCategoryData] = useState({})
  const [showConfirm, setShowConfirm] = useState(false)

  const {
    getStoreProductsCategories,
    loading,
    storeProductsCategories,
    totalCount,
    createStoreProductsCategories,
    updateStoreProductsCategories,
    getProductsByCategoriesId,
    countProductsByCategories,
  } = useStore()

  function handleClick(params) {
    setModalVisible(true)

    setProductCategoryData({
      type: params.type,
    })

    if (params.type === 'new') {
      setProductCategoryData(prevState => ({
        ...prevState,
        header: 'Nova Categoria',
      }))
    }

    if (params.type === 'update') {
      getProductsByCategoriesId(params.item.uid)

      setProductCategoryData(prevState => ({
        ...prevState,
        ...params.item,
        header: 'Atualizar Categoria',
      }))
    }
  }

  function formatDate(date) {
    return date ? format(new Date(date), 'dd/MM/yyyy HH:mm') : ''
  }

  function renderItem(item, index) {
    return (
      <Table.Row key={String(index)}>
        <Table.Cell>{item.title}</Table.Cell>
        <Table.Cell>{formatDate(item.created_at)}</Table.Cell>
        <Table.Cell>{formatDate(item.updated_at)}</Table.Cell>
        <Table.Cell>
          <Icon
            disabled={loading}
            link={!loading}
            color="blue"
            size="large"
            name="edit"
            onClick={() => handleClick({ type: 'update', item })}
          />
        </Table.Cell>
      </Table.Row>
    )
  }

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * pagination.limit
    setPagination(prevState => ({
      ...prevState,
      activePage,
      offset,
      limit: pagination.limit,
    }))

    return getStoreProductsCategories({
      offset,
      limit: pagination.limit,
    })
  }

  function onChangeCreateOrEdit(key, value) {
    setProductCategoryData(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  function onCreate() {
    const createData = {
      title: productCategoryData.title,
      description: productCategoryData.description,
    }

    createStoreProductsCategories(createData, pagination)
    setModalVisible(false)
    return setProductCategoryData({})
  }

  function onUpdate() {
    const updateDate = {
      title: productCategoryData.title,
      description: productCategoryData.description,
      storeProductsCategoriesId: productCategoryData.uid,
    }

    updateStoreProductsCategories(updateDate, pagination)
    setModalVisible(false)
    return setProductCategoryData({})
  }

  function handleCancel() {
    setShowConfirm(false)
    setModalVisible(false)
  }

  useEffect(() => {
    getStoreProductsCategories(pagination)
  }, []) // eslint-disable-line

  useEffect(() => {
    if (countProductsByCategories > 0) {
      setShowConfirm(true)
    }
  }, [countProductsByCategories])

  return (
    <>
      <h1>Loja Mission - Categorias</h1>
      <Card fluid>
        <Card.Content>
          <Button
            primary
            size="large"
            onClick={() => handleClick({ type: 'new' })}
            loading={loading}
            disabled={loading}
          >
            Nova Categoria
          </Button>
        </Card.Content>
      </Card>

      <TableComponent
        columns={columns}
        data={storeProductsCategories}
        renderItem={renderItem}
        emptyText={{
          icon: 'eye slash outline',
          text: 'A lista de Categorias está vazia',
        }}
        count
        totalCount={totalCount}
        activePage={pagination.activePage}
        limit={pagination.limit}
        offset={pagination.offset}
        showEllipsis
        showFirstAndLastNav
        showPreviousAndNextNav
        handlePaginationChange={handlePaginationChange}
      />

      <CreateOrEdit
        open={modalVisible}
        productCategoryData={productCategoryData}
        onClose={() => setModalVisible(false)}
        onChange={onChangeCreateOrEdit}
        onCreate={onCreate}
        onUpdate={onUpdate}
      />

      <ConfirmUpdate
        handleCancel={handleCancel}
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
      />
    </>
  )
}

export default StoreCategories
