import { cond, equals, always, assoc } from 'ramda'

const initialState = {
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const dataList = assoc('data', [], initialState)
const dataUpdate = assoc('data', {}, initialState)
const dataOrder = assoc('data', false, initialState)

const FORMS_ITEMS_LIST = 'FORMS_ITEMS_LIST'
const FORMS_ITEMS_LIST_OK = 'FORMS_ITEMS_LIST_OK'
const FORMS_ITEMS_LIST_FAIL = 'FORMS_ITEMS_LIST_FAIL'

const FORMS_ITEMS_UPDATE = 'FORMS_ITEMS_UPDATE'
const FORMS_ITEMS_UPDATE_OK = 'FORMS_ITEMS_UPDATE_OK'
const FORMS_ITEMS_UPDATE_FAIL = 'FORMS_ITEMS_UPDATE_FAIL'

const FORMS_ITEMS_CREATE = 'FORMS_ITEMS_CREATE'
const FORMS_ITEMS_CREATE_OK = 'FORMS_ITEMS_CREATE_OK'
const FORMS_ITEMS_CREATE_FAIL = 'FORMS_ITEMS_CREATE_FAIL'

const FORMS_ITEMS_UPDATE_ORDER = 'FORMS_ITEMS_UPDATE_ORDER'
const FORMS_ITEMS_UPDATE_ORDER_OK = 'FORMS_ITEMS_UPDATE_ORDER_OK'
const FORMS_ITEMS_UPDATE_ORDER_FAIL = 'FORMS_ITEMS_UPDATE_ORDER_FAIL'

const FORMS_ITEMS_REMOVE = 'FORMS_ITEMS_REMOVE'
const FORMS_ITEMS_REMOVE_OK = 'FORMS_ITEMS_REMOVE_OK'
const FORMS_ITEMS_REMOVE_FAIL = 'FORMS_ITEMS_REMOVE_FAIL'

const formsItems = (state = dataList, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_ITEMS_LIST), always({ ...state, isFetching: true })],
    [
      equals(FORMS_ITEMS_LIST_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_ITEMS_LIST_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

const formsItemsUpdate = (state = dataUpdate, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_ITEMS_UPDATE), always({ ...state, isFetching: true })],
    [
      equals(FORMS_ITEMS_UPDATE_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_ITEMS_UPDATE_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

const formsItemsOrder = (state = dataUpdate, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_ITEMS_UPDATE_ORDER), always({ ...state, isFetching: true })],
    [
      equals(FORMS_ITEMS_UPDATE_ORDER_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_ITEMS_UPDATE_ORDER_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

const formsItemsCreate = (state = dataOrder, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_ITEMS_CREATE), always({ ...state, isFetching: true })],
    [
      equals(FORMS_ITEMS_CREATE_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_ITEMS_CREATE_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

const formsItemsRemove = (state = dataOrder, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_ITEMS_REMOVE), always({ ...state, isFetching: true })],
    [
      equals(FORMS_ITEMS_REMOVE_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_ITEMS_REMOVE_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

export default {
  formsItems,
  formsItemsOrder,
  formsItemsUpdate,
  formsItemsCreate,
  formsItemsRemove,
}
