import React from 'react'

import t from 'prop-types'
import { Grid } from 'semantic-ui-react'

import ContainerLoading from '~/components/Loading/ContainerLoading'

import { ContainerDiv, LargeText } from '../styles'
import ThermometerCard from './ThermometerCard'

function FunnelUsersPotential({ data: funnelUsersPotential, isLoading }) {
  const isEmpty = () => {
    return funnelUsersPotential.length === 0 && isLoading === false
  }

  return (
    <ContainerDiv>
      <ContainerLoading
        isLoading={isLoading}
        customText="Carregando as informações..."
      >
        <>
          {isEmpty() && (
            <LargeText
              size="2em"
              className="center aligned"
              style={{ marginBottom: '20px' }}
            >
              Não há nenhum registro.
            </LargeText>
          )}
          {!isEmpty() && (
            <Grid columns={4} stackable>
              {funnelUsersPotential.map((item, index) => {
                return (
                  <ThermometerCard
                    key={index}
                    percentage={item.percentage} 
                    label={item.uf} 
                  />
                )
              })}
            </Grid>
          )}
        </>
      </ContainerLoading>
    </ContainerDiv>
  )
}

FunnelUsersPotential.propTypes = {
  data: t.arrayOf(t.objectOf(t.any)),
  isLoading: t.bool,
}

export default FunnelUsersPotential
