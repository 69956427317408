import React from 'react'

import t from 'prop-types'
import { Modal, Button, Form } from 'semantic-ui-react'

function CreateCategory({
  type,
  submit,
  createCategory,
  showModal,
  onClose,
  onChange,
}) {
  const typeHeader = type === 'create' ? 'Criar' : 'Atualizar'

  return (
    <Modal open={showModal} onClose={onClose}>
      <Modal.Header>
        <h1>{typeHeader} Categoria de Produtos</h1>
      </Modal.Header>
      <Modal.Content>
        <Form size="large">
          <Form.Input
            label="Nome da Categoria"
            type="text"
            id="title"
            value={createCategory?.title || ''}
            onChange={(e, { value }) => onChange('title', value)}
            placeholder="Nome da Categoria"
          />

          <Form.Input
            label="Descrição"
            type="text"
            id="description"
            value={createCategory?.description || ''}
            onChange={(e, { value }) => onChange('description', value)}
            placeholder="Descrição"
          />

          <Form.Checkbox
            label="Assinale se for um serviço"
            id="is_service"
            checked={createCategory?.is_service}
            onChange={() => onChange('is_service', !createCategory?.is_service)}
          />

          <Form.Checkbox
            label="Assinale caso for uma categoria destaque"
            id="has_spotlight"
            checked={createCategory?.has_spotlight}
            onChange={() =>
              onChange('has_spotlight', !createCategory?.has_spotlight)
            }
          />

          {createCategory?.has_spotlight && (
            <Form.Input
              label="Descreva o Destaque"
              type="text"
              id="description_spotlight"
              value={createCategory?.description_spotlight || ''}
              onChange={(e, { value }) =>
                onChange('description_spotlight', value)
              }
              placeholder="Descreva o Destaque"
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          size="large"
          color="black"
          onClick={onClose}
          content="Cancelar"
        />
        {type === 'create' ? (
          <Button size="large" positive content="Cadastrar" onClick={submit} />
        ) : (
          <Button
            size="large"
            color="orange"
            content="Atualizar"
            onClick={submit}
          />
        )}
      </Modal.Actions>
    </Modal>
  )
}

CreateCategory.propTypes = {
  showModal: t.bool,
  onClose: t.func,
  createCategory: t.objectOf(t.any),
  onChange: t.func,
  submit: t.func,
  type: t.string,
}

export default CreateCategory
