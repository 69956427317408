import React from 'react'

import t from 'prop-types'
import { Card } from 'semantic-ui-react'

function GraphicTooltipMissionStartedFinished({ active, payload }) {
  if (!active || !payload || payload.length === 0) {
    return false
  }

  const data = payload[0].payload
  return (
    <Card style={{ width: '350px' }}>
      <Card.Content>
        <ul>
          <li>
            Período: <strong>{data.period}</strong>
          </li>
          <li>
            Total: <strong>{data.total}</strong>
          </li>
        </ul>
        <h3>Versão antiga da app</h3>
        <ul>
          <li>
            Total: <strong>{data.totalUserHasOldVersionApp}</strong>
          </li>
          <li>
            Porcetagem:{' '}
            <strong>{data.percentageUserOldCurrentVersionApp}</strong>
          </li>
        </ul>
        <h3>Versão atual da app</h3>
        <ul>
          <li>
            Total: <strong>{data.totalUserHasCurrentVersionApp}</strong>
          </li>
          <li>
            Porcetagem:
            <strong>{data.percentageUserHasCurrentVersionApp}</strong>
          </li>
        </ul>
      </Card.Content>
    </Card>
  )
}

GraphicTooltipMissionStartedFinished.propTypes = {
  active: t.bool,
  payload: t.arrayOf(t.objectOf(t.any)),
}

export default GraphicTooltipMissionStartedFinished
