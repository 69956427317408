import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import t from 'prop-types'
import { Modal, Button, Message, Icon } from 'semantic-ui-react'

import MessageBox from '~/utils/messages'

function ShowPassword({ close, previewPassword, openModal, onSubmit }) {
  return (
    <Modal size="small" open={openModal} onClose={close}>
      <Modal.Header content="Gerou uma Nova Senha" />
      <Modal.Content>
        <Message
          color="red"
          icon="warning"
          header="Atenção!"
          content="Copie e Guarde a senha em um lugar seguro"
        />
        <CopyToClipboard
          text={previewPassword}
          onCopy={() => MessageBox().success('Senha Copiada!')}
        >
          <Button size="massive" icon labelPosition="left">
            <Icon name="copy outline" />
            {previewPassword}
          </Button>
        </CopyToClipboard>
      </Modal.Content>
      <Modal.Content>
        <Button color="red" icon="close" onClick={close} content="Cancelar" />
        <Button
          color="green"
          onClick={onSubmit}
          content="Confirmar Alteração"
        />
      </Modal.Content>
    </Modal>
  )
}

ShowPassword.propTypes = {
  close: t.func,
  openModal: t.bool,
  previewPassword: t.string,
}

export default ShowPassword
