import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useForms() {
  const [loadingForms, setLoadingForms] = useState(false)
  const [forms, setForms] = useState([])

  async function getForms(clientId) {
    try {
      setLoadingForms(true)

      const res = await api.get(`forms/admin`, {
        params: {
          clientId,
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setForms(
        res.data?.map(form => ({
          ...form,
          value: form.uid,
          text: form.name,
        }))
      )

      return setForms(
        res.data?.map(form => ({
          ...form,
          value: form.uid,
          text: form.name,
        }))
      )
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingForms(false)
    }
  }

  async function createForm(params) {
    try {
      setLoadingForms(true)

      const res = await api.post('forms/admin', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getForms(params.clientId)

      return Message().success('Formulário criado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingForms(false)
    }
  }

  async function updateForms(params) {
    try {
      setLoadingForms(true)

      const { uid, name, link, customer_id } = params

      const res = await api.patch(
        'forms/admin',
        { uid, name, link },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getForms(customer_id)

      return Message().success('Formulário atualizado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingForms(false)
    }
  }

  async function removeForms(params) {
    try {
      setLoadingForms(true)

      const res = await api.delete('forms/admin', {
        params: { formsId: params.uid },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getForms(params.customer_id)

      return Message().success('Formulário removido com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingForms(false)
    }
  }

  async function duplicateForms(params) {
    try {
      setLoadingForms(true)

      const { uid, customer_id } = params

      const res = await api.post(
        'forms/admin/copy',
        { formsId: uid },
        {
          validateStatus() {
            return true
          },
        }
      )

      getForms(customer_id)

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Formulário duplicado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingForms(false)
    }
  }

  return {
    getForms,
    loadingForms,
    forms,
    createForm,
    updateForms,
    removeForms,
    duplicateForms,
  }
}
