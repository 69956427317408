import React from 'react'

import { Form, Button, Card, Select } from 'semantic-ui-react'

import { TYPE_INPUTS_OPTIONS } from '~/utils/types'

function FormDynamicParams({ params, setParams }) {
  const removeParams = index => {
    const has1Item = params.length === 1
    if (has1Item) {
      return
    }

    const registers = params.filter((_, position) => position !== index)
    setParams([...registers])
  }

  const changeInputValue = (index, key, value) => {
    params[index][key] = value
    setParams([...params])
  }

  return params.map((item, index) => {
    return (
      <Card fluid>
        <Card.Content>
          <Form.Field>
            <Button
              icon="trash"
              onClick={() => removeParams(index)}
              negative
              style={{ float: 'right' }}
            />
            <br />
            <label>Texto da questão</label>
            <input
              placeholder="Label"
              onChange={event =>
                changeInputValue(index, 'label', event.target.value)
              }
              value={params[index].label}
            />
            <label>Tipo do parâmetro</label>
            <Select
              value={params[index].type}
              onChange={(event, data) =>
                changeInputValue(index, 'type', data.value)
              }
              placeholder="Tipo de parâmetro"
              options={TYPE_INPUTS_OPTIONS}
            />
            <label>Nome do parâmetro</label>
            <input
              onChange={event =>
                changeInputValue(index, 'name', event.target.value)
              }
              value={params[index].name}
              placeholder="Nome do campo"
            />
          </Form.Field>
        </Card.Content>
      </Card>
    )
  })
}

export default FormDynamicParams
