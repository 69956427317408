const initialState = {
  data: {},
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const FORMS_HAS_MISSIONS = 'FORMS_HAS_MISSIONS'
const FORMS_HAS_MISSIONS_OK = 'FORMS_HAS_MISSIONS_OK'
const FORMS_HAS_MISSIONS_FAIL = 'FORMS_HAS_MISSIONS_FAIL'

export default function formsHasMissionsReducers(state = initialState, action) {
  const { data, message, type } = action

  switch (type) {
    case FORMS_HAS_MISSIONS:
      return { ...state, isFetching: true }

    case FORMS_HAS_MISSIONS_OK:
      return { isFetching: false, data, success: true, error: false }

    case FORMS_HAS_MISSIONS_FAIL:
      return { isFetching: false, error: true, message, success: false }

    default:
      return state
  }
}
