import React, { useEffect } from 'react'

import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useUserCashback from '~/hooks/useUserCashback'

const columns = ['Cadastrou-se em', 'Nome', 'Cashback Gerado']

const emptyText = {
  icon: 'check circle outline',
  text: 'Nenhum convidado até o momento...',
}

export function UserGuests({ userId }) {
  const { getUserCashback, usersCashbacks, loadingUserCash } = useUserCashback()

  useEffect(() => {
    getUserCashback({ userId })
  }, []) //eslint-disable-line

  return (
    <TableComponent
      data={usersCashbacks}
      renderItem={item => (
        <Table.Row key={item.user_uid}>
          <Table.Cell>
            {format(new Date(item.joined_date), 'dd/MM/yyyy HH:mm')}
          </Table.Cell>
          <Table.Cell>
            <a href={`/user/${item.user_uid}`} target="blank">
              {item.user_name}
            </a>
          </Table.Cell>
          <Table.Cell>R$ {item.value}</Table.Cell>
        </Table.Row>
      )}
      columns={columns}
      emptyText={emptyText}
      isLoading={loadingUserCash}
    />
  )
}

UserGuests.propTypes = {
  userId: PropTypes.string,
}
