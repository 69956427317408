import React, { useRef } from 'react'

import t from 'prop-types'
import { Button, Card } from 'semantic-ui-react'

const Header = ({
  selectRowKeys,
  loadingProducts,
  removeProducts,
  updateStatus,
  uploadFile,
  handleOnClick,
  selectedCategory,
}) => {
  const uploadRef = useRef('uploadRef')

  return (
    <Card fluid>
      <Card.Content>
        {selectRowKeys.length > 0 && (
          <>
            <Button
              size="large"
              color="red"
              icon="trash"
              labelPosition="left"
              content="Remover"
              loading={loadingProducts}
              onClick={() => removeProducts()}
            />
            <Button
              size="large"
              color="green"
              icon="thumbs up"
              labelPosition="left"
              content="Ativar"
              loading={loadingProducts}
              onClick={() => updateStatus(true)}
            />
            <Button
              size="large"
              content="Inativar"
              icon="thumbs down"
              labelPosition="left"
              loading={loadingProducts}
              onClick={() => updateStatus(false)}
            />
            {`${selectRowKeys.length} produtos selecionados`}{' '}
          </>
        )}
        <Button
          disabled={selectedCategory}
          loading={loadingProducts}
          size="large"
          icon="add"
          content="Novo Produto"
          labelPosition="left"
          color="green"
          onClick={() => handleOnClick('create-product')}
        />
        <input
          type="file"
          ref={uploadRef}
          id="upload"
          onChange={uploadFile}
          style={{ display: 'none' }}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <Button
          disabled={selectedCategory}
          loading={loadingProducts}
          size="large"
          icon="upload"
          content="Importar Produto"
          type="file"
          labelPosition="left"
          color="blue "
          onClick={() => uploadRef.current.click()}
        />
      </Card.Content>
    </Card>
  )
}

Header.propTypes = {
  selectRowKeys: t.arrayOf(t.any),
  loadingProducts: t.bool,
  removeProducts: t.func,
  updateStatus: t.func,
  uploadFile: t.func,
  handleOnClick: t.func,
  selectedCategory: t.bool,
}

export default Header
