import { cond, equals, always, assoc } from 'ramda'

const initialState = {
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const dataUpdate = assoc('data', {}, initialState)
const dataOrder = assoc('data', false, initialState)

const FORMS_ITEMS_MULTIPLES_CREATE = 'FORMS_ITEMS_MULTIPLES_CREATE'
const FORMS_ITEMS_MULTIPLES_CREATE_OK = 'FORMS_ITEMS_MULTIPLES_CREATE_OK'
const FORMS_ITEMS_MULTIPLES_CREATE_FAIL = 'FORMS_ITEMS_MULTIPLES_CREATE_FAIL'

const FORMS_ITEMS_MULTIPLES_UPDATE_ORDER = 'FORMS_ITEMS_MULTIPLES_UPDATE_ORDER'
const FORMS_ITEMS_MULTIPLES_UPDATE_ORDER_OK =
  'FORMS_ITEMS_MULTIPLES_UPDATE_ORDER_OK'
const FORMS_ITEMS_MULTIPLES_UPDATE_ORDER_FAIL =
  'FORMS_ITEMS_MULTIPLES_UPDATE_ORDER_FAIL'

const FORMS_ITEMS_MULTIPLES_REMOVE = 'FORMS_ITEMS_MULTIPLES_REMOVE'
const FORMS_ITEMS_MULTIPLES_REMOVE_OK = 'FORMS_ITEMS_MULTIPLES_REMOVE_OK'
const FORMS_ITEMS_MULTIPLES_REMOVE_FAIL = 'FORMS_ITEMS_MULTIPLES_REMOVE_FAIL'

const formsItemsMultiplesOrder = (state = dataUpdate, action) => {
  const { data, message, type } = action
  const fn = cond([
    [
      equals(FORMS_ITEMS_MULTIPLES_UPDATE_ORDER),
      always({ ...state, isFetching: true }),
    ],
    [
      equals(FORMS_ITEMS_MULTIPLES_UPDATE_ORDER_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_ITEMS_MULTIPLES_UPDATE_ORDER_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

const formsItemsMultiplesCreate = (state = dataOrder, action) => {
  const { data, message, type } = action
  const fn = cond([
    [
      equals(FORMS_ITEMS_MULTIPLES_CREATE),
      always({ ...state, isFetching: true }),
    ],
    [
      equals(FORMS_ITEMS_MULTIPLES_CREATE_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_ITEMS_MULTIPLES_CREATE_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

const formsItemsMultiplesRemove = (state = dataOrder, action) => {
  const { data, message, type } = action
  const fn = cond([
    [
      equals(FORMS_ITEMS_MULTIPLES_REMOVE),
      always({ ...state, isFetching: true }),
    ],
    [
      equals(FORMS_ITEMS_MULTIPLES_REMOVE_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_ITEMS_MULTIPLES_REMOVE_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

export default {
  formsItemsMultiplesOrder,
  formsItemsMultiplesCreate,
  formsItemsMultiplesRemove,
}
