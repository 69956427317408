import React, { useEffect, useState } from 'react'
import Lightbox from 'react-image-lightbox'

import { CNPJ } from 'cpf_cnpj'
import t from 'prop-types'
import {
  Confirm,
  List,
  Button,
  Image,
  Icon,
  Card,
  Popup,
} from 'semantic-ui-react'

import ShimmerLoader from '~/components/Loader/Forms'
import useFormsData from '~/hooks/useFormsData'
import api from '~/services/api'
import Message from '~/utils/messages'

import CreateFormItem from './components/CreateFormItem'
import EditFields from './components/EditFields'

import 'react-image-lightbox/style.css'

const ContainerImages = ({ item, answer, onClick, onClickRemove }) => {
  if (answer.includes('path')) {
    const images = JSON.parse(answer).map((i, index) => (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            justifyContent: 'flex-end',
            position: 'absolute',
            zIndex: 9999,
            top: -20,
            marginLeft: 15,
          }}
        >
          <Button
            negative
            circular
            icon="close"
            onClick={() => {
              onClickRemove(index, answer, item)
            }}
          />
        </div>

        <Image
          wrapped
          width={40}
          src={i.path}
          style={{ cursor: 'pointer', flexDirection: 'row' }}
          onClick={() => onClick(index, answer)}
        />
      </div>
    ))
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          gap: 10,
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        {images}
      </div>
    )
  }

  return answer
}

function FormsResponse({ id }) {
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState(null)
  const [loadingQuestion, setLoadingQuestion] = useState(false)
  const [items, setItems] = useState([])
  const [showItems, setShowItems] = useState({ item: {}, show: false })
  const [showComponent, setShowComponent] = useState(false)
  const [handleConfirm, setHandleConfirm] = useState(false)
  const [handleConfirmRemoveImage, setHandleConfirmRemoveImage] =
    useState(false)

  const [secondModal, setSecondModal] = useState(false)
  const [question, setQuestion] = useState(null)
  const [imageRemoved, setImageRemoved] = useState(null)

  const [verificationCNPJ, setVerificationCNPJ] = useState({})

  const {
    getFormsDataByMissionsUsersId,
    loadingFormsData,
    formsData,
    checkCNPJStatus,
    loadingCNPJ,
  } = useFormsData()

  function closeBox() {
    return setOpen(false)
  }

  function openBox(i, paths) {
    setItems(JSON.parse(paths).map(image => image.path))
    setCurrent(i)
    return setTimeout(() => setOpen(true), 500)
  }

  function confirmRemoveImage(i, item, answer) {
    setImageRemoved({ i, item, answer })
    setHandleConfirmRemoveImage(true)
  }

  async function onRemoveImage() {
    const itens = JSON.parse(imageRemoved.item)
    const images = itens.filter(item => item !== itens[imageRemoved.i])

    try {
      const res = await api.put(
        '/forms-data/item/update',
        {
          uid: imageRemoved.answer.forms_data_id,
          answer: JSON.stringify(images),
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }
      setLoadingQuestion(false)
      setHandleConfirmRemoveImage(false)

      onRefreshData()
      return Message().success('Resposta alterada com sucesso!')
    } catch (error) {
      setLoadingQuestion(false)
      return Message().error(error.message)
    }
  }

  function showComponentItem(item) {
    return setShowComponent(prevState => ({
      ...prevState,
      item,
      show: !prevState.show,
    }))
  }

  async function onSubmitQuestion() {
    try {
      const itemValue = formsData[0]?.items[0]
      setLoadingQuestion(true)
      const res = await api.post(`/forms-data/item/create`, {
        missions_users_id: id,
        answer: 'Sem resposta',
        user_id: itemValue.user_id,
        forms_items_id: question.uid,
        forms_missions_id: itemValue.forms_missions_id,
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingQuestion(false)
      onRefreshData()
      return Message().success('Item adicionado com sucesso!')
    } catch (error) {
      setLoadingQuestion(false)
      return Message().error(error.message)
    }
  }

  async function onRemoveItem() {
    try {
      const res = await api.delete(
        `/forms-data/item/${showItems.item.forms_data_id}`,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setHandleConfirm(false)

      onRefreshData()
      return Message().success('Item removido com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    }
  }

  function onRefreshData() {
    return getFormsDataByMissionsUsersId(id)
  }

  function questionIsCNPJ(textQuestion, textAnswer) {
    return textQuestion.match(/cnpj/i) && CNPJ.isValid(textAnswer)
  }

  async function handleOnClickCNPJ(value) {
    const cnpj = CNPJ.strip(value)

    const result = await checkCNPJStatus(cnpj)

    return setVerificationCNPJ(prevState => ({
      ...prevState,
      [value]: {
        status: result?.status,
        text: result?.text,
      },
    }))
  }

  const verifyCNPJStatus = (value, text) => {
    let result = ''

    if (value !== undefined) {
      result = value ? `✅️ ${text}` : `❌️ ${text}`
    }

    return result
  }

  const Box = () => (
    <Lightbox
      onCloseRequest={closeBox}
      mainSrc={items[current]}
      nextSrc={items[(current + 1) % items.length]}
      onMovePrevRequest={() => setCurrent(current - 1)}
      onMoveNextRequest={() => setCurrent(current + 1)}
      prevSrc={items[(current + items.length - 1) % items.length]}
    />
  )

  useEffect(() => {
    onRefreshData()
  }, []) //eslint-disable-line

  return (
    <Card fluid>
      <Card.Content>
        <List>
          {loadingFormsData && <ShimmerLoader />}

          {!loadingFormsData &&
            formsData.map((item, index) => (
              <List.Item key={String(index)}>
                <List.Header content={`Seção ${item.section}`} />
                <List>
                  {item.items.map((i, idx) => (
                    <List.Item key={`${String(idx)}-item`}>
                      <List.Content floated="right">
                        {questionIsCNPJ(i?.question, i?.answer) && (
                          <Popup
                            content="Verificar se o CNPJ está ativo"
                            trigger={
                              <Button
                                icon="search"
                                loading={loadingCNPJ}
                                disabled={!!verificationCNPJ[i.answer]}
                                onClick={() => handleOnClickCNPJ(i.answer)}
                              />
                            }
                          />
                        )}

                        <Popup
                          content="Editar resposta"
                          trigger={
                            <Button icon onClick={() => showComponentItem(i)}>
                              <Icon name="edit outline" />
                            </Button>
                          }
                        />

                        <Popup
                          content="Remover resposta"
                          trigger={
                            <Button
                              icon
                              color="red"
                              onClick={() => {
                                setShowItems({ item: i })
                                setHandleConfirm(true)
                              }}
                            >
                              <Icon name="trash alternate outline" />
                            </Button>
                          }
                        />
                      </List.Content>
                      {i.answer.trim() && (
                        <>
                          <List.Header content={`${i.order}. ${i.question}`} />
                          R:{' '}
                          {i.type === 16 ? (
                            <ContainerImages
                              item={i}
                              answer={i.answer}
                              onClick={openBox}
                              onClickRemove={confirmRemoveImage}
                            />
                          ) : (
                            `${i.answer} ${
                              questionIsCNPJ(i.question, i.answer)
                                ? verifyCNPJStatus(
                                    verificationCNPJ[i.answer]?.status,
                                    verificationCNPJ[i.answer]?.text
                                  )
                                : ''
                            }`
                          )}
                        </>
                      )}

                      {showComponent.show &&
                        showComponent.item.forms_items_id ===
                          i.forms_items_id && (
                          <List.Content>
                            <EditFields
                              missionsUsersId={id}
                              item={showComponent.item}
                              getFormsData={onRefreshData}
                            />
                          </List.Content>
                        )}
                    </List.Item>
                  ))}
                </List>
              </List.Item>
            ))}
        </List>
      </Card.Content>
      <Card.Content>
        <Button color="orange" onClick={() => setSecondModal(true)}>
          Adicionar Resposta
        </Button>
      </Card.Content>
      <Confirm
        header="Alerta"
        open={handleConfirm}
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onConfirm={onRemoveItem}
        onCancel={() => setHandleConfirm(false)}
        content="Tem certeza que deseja remover esse item?"
      />

      <Confirm
        header="Alerta"
        open={handleConfirmRemoveImage}
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onConfirm={onRemoveImage}
        onCancel={() => setHandleConfirmRemoveImage(false)}
        content="Tem certeza que deseja remover essa imagem?"
      />
      <CreateFormItem
        formsItems={formsData}
        secondModal={secondModal}
        setQuestion={setQuestion}
        setSecondModal={setSecondModal}
        loadingQuestion={loadingQuestion}
        onSubmitQuestion={onSubmitQuestion}
      />
      {open && <Box />}
    </Card>
  )
}

ContainerImages.propTypes = {
  answer: t.string,
  onClick: t.func,
  onClickRemove: t.func,
}

FormsResponse.propTypes = {
  id: t.string,
}

export default FormsResponse
