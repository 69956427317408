import React from 'react'

import t from 'prop-types'
import { Card, Form, Input, Button, Dropdown } from 'semantic-ui-react'

const options = [
  { key: '1', text: '1 ou mais', value: '1' },
  { key: '2', text: '2 ou mais', value: '2' },
  { key: '3', text: '3 ou mais', value: '3' },
  { key: '4', text: '4 ou mais', value: '4' },
  { key: '5', text: 'apenas 5', value: '5' },
]

function Filter({
  filters,
  onChangeFilters,
  clearFilters,
  hasName,
  hasEmail,
  hasPhone,
  hasRating,
  hasSituation,
}) {
  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            {hasName && (
              <Form.Field
                fluid
                control={Input}
                icon="search"
                placeholder="Nome"
                value={filters?.name || ''}
                onChange={(e, { value }) => onChangeFilters({ name: value })}
              />
            )}

            {hasEmail && (
              <Form.Field
                fluid
                control={Input}
                icon="mail"
                placeholder="Email"
                value={filters?.email || ''}
                onChange={(e, { value }) => onChangeFilters({ email: value })}
              />
            )}

            {hasPhone && (
              <Form.Field
                fluid
                control={Input}
                icon="phone"
                placeholder="Telefone"
                value={filters?.phone || ''}
                onChange={(e, { value }) => onChangeFilters({ phone: value })}
              />
            )}
            {hasSituation && (
              <Form.Field
                fluid
                control={Input}
                icon="search"
                placeholder="Situação"
                value={filters?.situation || ''}
                onChange={(e, { value }) =>
                  onChangeFilters({ situation: value })
                }
              />
            )}
            {hasRating && (
              <Form.Field
                fluid
                selection
                search
                options={options}
                control={Dropdown}
                placeholder="Avaliação"
                value={filters?.rating || ''}
                onChange={(e, { value }) => onChangeFilters({ rating: value })}
              />
            )}
          </Form.Group>
        </Form>
      </Card.Content>
      <Card.Content>
        <Button
          icon="undo"
          color="grey"
          content="Limpar Filtros"
          labelPosition="left"
          onClick={clearFilters}
        />
      </Card.Content>
    </Card>
  )
}

Filter.propTypes = {
  filters: t.objectOf(t.any),
  onChangeFilters: t.func,
  clearFilters: t.func,
  hasName: t.bool,
  hasEmail: t.bool,
  hasPhone: t.bool,
  hasRating: t.bool,
  hasSituation: t.bool,
}

export default Filter
