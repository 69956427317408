import {
  CUSTOM_STATUS_CREATE,
  CUSTOM_STATUS_UPDATE,
  CUSTOM_STATUS_REMOVE,
  CUSTOM_STATUS_REORDER,
} from '~/services/api/endpoints'
import { put, post, remove } from '~/utils/actionsRequests'

const update = (data) =>
  put({
    data,
    url: CUSTOM_STATUS_UPDATE,
    typeFetch: 'CUSTOM_STATUS_UPDATE',
    typeError: 'CUSTOM_STATUS_UPDATE_FAIL',
    typeResponse: 'CUSTOM_STATUS_UPDATE_OK',
  })

const reorder = (data) =>
  put({
    data,
    url: CUSTOM_STATUS_REORDER,
    typeFetch: 'CUSTOM_STATUS_REORDER',
    typeError: 'CUSTOM_STATUS_REORDER_FAIL',
    typeResponse: 'CUSTOM_STATUS_REORDER_OK',
  })

const create = (data) =>
  post({
    data,
    url: CUSTOM_STATUS_CREATE,
    typeFetch: 'CUSTOM_STATUS_CREATE',
    typeError: 'CUSTOM_STATUS_CREATE_FAIL',
    typeResponse: 'CUSTOM_STATUS_CREATE_OK',
  })

const removeById = (id) =>
  remove({
    url: CUSTOM_STATUS_REMOVE(id),
    typeFetch: 'CUSTOM_STATUS_REMOVE',
    typeError: 'CUSTOM_STATUS_REMOVE_FAIL',
    typeResponse: 'CUSTOM_STATUS_REMOVE_OK',
  })

export { update, create, removeById, reorder }
