import styled from 'styled-components'

export const ContainerList = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
`

export const ContainerColumn = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
`

export const ContainerItems = styled.div`
  width: 100%;
  justify-content: flex-start;
  border-top: 1px solid #f1f2f3;
  margin-top: 10px;
`

export const ContainerMessage = styled.div`
  position: sticky;
  width: 400px;
  padding: 10px;
  background-color: white;
`
