/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

import t from 'prop-types'
import {
  Icon,
  Form,
  Segment,
  Header,
  List,
  Button,
  Popup,
} from 'semantic-ui-react'

export default function Upload({
  files,
  removeFile,
  isDragActive,
  getRootProps,
  getInputProps,
}) {
  return (
    <div style={{ marginTop: 10 }}>
      <div className="field">
        <label>Gerenciar Arquivos da Plataforma</label>
      </div>
      <Form style={{ marginTop: 10 }} />
      <div
        {...getRootProps()}
        style={{
          padding: 10,
          marignTop: 10,
          borderWidth: 1,
          borderColor: '#b9b9b9',
          borderStyle: 'dashed',
        }}
      >
        <Segment placeholder textAlign="center" className="overflow">
          <Header icon>
            <Icon name="file outline" />
            {isDragActive ? (
              <p>Solte o arquivo aqui...</p>
            ) : (
              <p>
                Arraste e solte os arquivos aqui ou clique para selecioná-los
              </p>
            )}
            <input {...getInputProps()} />
          </Header>
        </Segment>
      </div>

      <List divided relaxed>
        {files.map((file, index) => (
          <List.Item style={{ display: 'flex' }} key={String(`key-${index}`)}>
            <List.Icon name="file" size="large" verticalAlign="middle" />
            <List.Content style={{ marginTop: '3px' }}>
              {file.file.name}
            </List.Content>
            <List.Content>
              <Popup
                content="Remover arquivo da lista"
                trigger={
                  <Button icon negative onClick={() => removeFile(index)}>
                    <Icon name="remove" />
                  </Button>
                }
              />
            </List.Content>
          </List.Item>
        ))}
      </List>
    </div>
  )
}

Upload.propTypes = {
  files: t.arrayOf(t.any),
  removeFile: t.func,
  isDragActive: t.bool,
  getRootProps: t.func,
  getInputProps: t.func,
}
