import React from 'react'

import t from 'prop-types'
import {
  Button,
  Modal,
  Grid,
  Card,
  Checkbox,
  List,
  Divider,
  Dimmer,
  Loader,
} from 'semantic-ui-react'

function Optional({
  open,
  onClose,
  onChange,
  allFormsItems,
  itemsParents,
  loading,
  onClickAddOrRemoveOptional,
}) {
  const disponibleFormsItems = []

  const selectedFormsItems = itemsParents.map(ip => ip.forms_items_id)

  allFormsItems.forEach(fi => {
    if (!selectedFormsItems.includes(fi.uid)) disponibleFormsItems.push(fi)
  })

  return (
    <Modal open={open} onClose={onClose} size="small">
      <Modal.Header>Opcionais</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            Selecione as perguntas que devem estar escondidas ao iniciar o
            formulário.
          </p>
          <p>
            Caso o usuário escolha esta resposta, as perguntas selecionadas
            aparecerão.
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Content>
        {loading && (
          <Card>
            <Card.Content>
              <Dimmer active>
                <Loader />
              </Dimmer>
            </Card.Content>
          </Card>
        )}
        {!loading && (
          <Grid columns={3} centered verticalAlign="top">
            <Grid.Row>
              <Grid.Column>
                <Card>
                  <Card.Content>
                    <Card.Header>
                      {disponibleFormsItems.length} Items
                    </Card.Header>
                    <Divider />
                    {disponibleFormsItems?.map(fi => {
                      return (
                        <List>
                          <Checkbox
                            label={`${fi.order} - ${fi.question}`}
                            onChange={(e, { checked }) => onChange(fi, checked)}
                          />
                        </List>
                      )
                    })}
                  </Card.Content>
                </Card>
              </Grid.Column>

              <Grid.Column verticalAlign="center" width={2}>
                <Button
                  color="green"
                  content="Registrar >"
                  onClick={() => onClickAddOrRemoveOptional('add')}
                />
                <Divider hidden />
                <Button
                  color="red"
                  content="< Remover"
                  onClick={() => onClickAddOrRemoveOptional('remove')}
                />
              </Grid.Column>

              <Grid.Column>
                <Card>
                  <Card.Content>
                    <Card.Header>
                      {itemsParents?.length} Items Selecionados
                    </Card.Header>
                    <Divider />
                    {itemsParents?.map(item => {
                      const formItem = allFormsItems.find(
                        i => i.uid === item.forms_items_id
                      )
                      return (
                        <List>
                          <Checkbox
                            label={`${formItem?.order} - ${formItem?.question}`}
                            onChange={(e, { checked }) =>
                              onChange(item, checked)
                            }
                          />
                        </List>
                      )
                    })}
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Sair" color="black" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  )
}

Optional.propTypes = {
  open: t.bool,
  onClose: t.func,
  onChange: t.func,
  allFormsItems: t.arrayOf(t.any),
  itemsParents: t.arrayOf(t.any),
  loading: t.bool,
  onClickAddOrRemoveOptional: t.func,
}

export default Optional
