import { responseData } from '~/utils/reducer'

const REGISTERS = 'REGISTERS'
const REGISTERS_ALL = 'REGISTERS_ALL'
const REGISTER_RECEIPT = 'REGISTER_RECEIPT'
const REGISTERS_FILTER = 'REGISTERS_FILTER'
const REGISTERS_FEEDS = 'REGISTERS_FEEDS'
const UPDATE_REGISTER_STATUS = 'UPDATE_REGISTER_STATUS'

const registers = responseData(REGISTERS_ALL, 'array')
const registersReceipt = responseData(REGISTER_RECEIPT, 'object')
const registerDetail = responseData(REGISTERS, 'object')
const registerFilter = responseData(REGISTERS_FILTER, 'array')
const registersFeeds = responseData(REGISTERS_FEEDS, 'array')
const registersUpdate = responseData(UPDATE_REGISTER_STATUS, 'object')

export default {
  registers,
  registerDetail,
  registerFilter,
  registersFeeds,
  registersUpdate,
  registersReceipt,
}
