import { useEffect, useState } from 'react'

import jwtDecode from 'jwt-decode'

export default function useRole() {
  const [role, setRole] = useState(null)

  const getRoleUserAuthenticated = () => {
    const token = localStorage.getItem('@MissionAdmin:token')
    setRole(jwtDecode(token).role)
  }

  const isAdminAndLeader = () => role === 'Admin' || role === 'Leader'

  useEffect(() => {
    getRoleUserAuthenticated()
  }, [])

  return {
    isAdminAndLeader,
  }
}
