import { responseData } from '~/utils/reducer'

const BADGET_CREATE = 'BADGET_CREATE'
const BADGET_FETCH = 'BADGET_FETCH'
const BADGET_GET = 'BADGET_GET'
const BADGET_UPDATE = 'BADGET_UPDATE'
const BADGET_REMOVE = 'BADGET_REMOVE'

const findBadge = responseData(BADGET_GET, 'object')
const findBadges = responseData(BADGET_FETCH, 'array')
const badgeCreate = responseData(BADGET_CREATE, 'object')
const badgeUpdate = responseData(BADGET_UPDATE, 'object')
const badgeRemove = responseData(BADGET_REMOVE, 'object')

export default {
  findBadge,
  findBadges,
  badgeCreate,
  badgeUpdate,
  badgeRemove,
}
