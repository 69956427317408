import React, { useState, useEffect, useCallback } from 'react'

import { Header } from 'semantic-ui-react'
import { useDebounce } from 'use-lodash-debounce'

import Filter from '~/components/Filter'
import TableComponent from '~/components/Table'
import useCustomers from '~/hooks/useCustomers'

import RatesModal from '../Users/components/Rates'
import Item from './components/Item'

function RatingCustomers() {
  const columns = ['Nome', 'Email', 'Situação', 'Avaliação']
  const initialFilter = { offset: 0, limit: 15, activePage: 1 }
  const [filter, setFilter] = useState(initialFilter)
  const [openRates, setOpenRates] = useState()
  const [customerName, setCustomerName] = useState()
  const {
    count,
    listCustomer,
    getListCustomer,
    loadingCustomers,
    getRateMissions,
    ratesList,
  } = useCustomers()
  const debouncedFilters = useDebounce(filter, 500)

  /* eslint-disable */
  const columnsMapForApiSort = {
    Nome: 'name',
    Email: 'email',
    Situação: 'situation',
    Avaliação: 'rate_avg',
  }
  /* eslint-enable */

  function onChangeFilters(value) {
    setFilter(prevState => ({
      ...prevState,
      ...value,
    }))
  }

  function clearFilters() {
    setFilter(initialFilter)
  }

  useEffect(() => {
    setTimeout(() => {
      if (debouncedFilters !== null) {
        getListCustomer({ ...filter })
      }
    }, 300)
  }, [debouncedFilters]) //eslint-disable-line

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * filter.limit
    setFilter(prevState => ({
      ...prevState,
      activePage,
      offset,
      limit: filter.limit,
    }))
  }

  const handleSort = useCallback(
    (order, direction) => {
      setFilter(oldFilters => ({
        ...oldFilters,
        offset: 0,
        direction,
        order: columnsMapForApiSort[order],
      }))
    },
    [setFilter, columnsMapForApiSort]
  )

  function handleRates(item) {
    setOpenRates(true)
    getRateMissions(item)
    setCustomerName(item.name)
  }

  const renderItem = item => (
    <Item key={item.uid} item={item} handleRates={handleRates} />
  )

  return (
    <>
      <Header as="h1">Avaliação de Clientes</Header>
      <Filter
        hasName
        hasEmail
        hasRating
        hasSituation
        filters={filter}
        onChangeFilters={onChangeFilters}
        clearFilters={clearFilters}
      />
      <TableComponent
        count={count}
        columns={columns}
        totalCount={count}
        data={listCustomer}
        showFirstAndLastNav
        limit={filter?.limit}
        renderItem={renderItem}
        offset={filter?.offset}
        showPreviousAndNextNav
        isLoading={loadingCustomers}
        handleColumnSort={handleSort}
        activePage={filter?.activePage}
        handlePaginationChange={handlePaginationChange}
      />
      <RatesModal
        rates={ratesList}
        openModal={openRates}
        customerName={customerName}
        isLoadingRates={loadingCustomers}
        close={() => setOpenRates(false)}
      />
    </>
  )
}

export default RatingCustomers
