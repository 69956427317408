import React from 'react'

import t from 'prop-types'
import { List, Table, Label, Button } from 'semantic-ui-react'

import { STATUS_COLOR, STATUS_LABEL } from '../../../helpers/status'
import Popup from '../Popup'

const Item = ({ item, onOpenModal, onRemoveItem, onCheckItem }) => (
  <Table.Row
    active={item.status === 'IMPORTED'}
    disabled={item.status === 'IMPORTED'}
  >
    <Table.Cell>
      <Label color={STATUS_COLOR(item.status)}>
        {STATUS_LABEL(item.status)}
      </Label>
    </Table.Cell>
    <Table.Cell>{item.original.mission_name}</Table.Cell>
    <Table.Cell>
      <List>
        {item.addressesFound.length === 0 && (
          <Button onClick={() => onOpenModal({ i: {}, k: 0, item })}>
            Adicionar Endereço
          </Button>
        )}

        {item.addressesFound.length > 0 &&
          item.addressesFound.map((i, k) => (
            <List.Item key={String(k)}>
              <List.Icon name="marker" />
              <List.Content>
                <List.Header as="a">
                  <span
                    style={{
                      color: i.statusAddress === 'INCOMPLETED' && 'red',
                    }}
                  >
                    <Popup
                      title={i.data.formattedAddress}
                      remove={() => onRemoveItem({ i, item })}
                      open={() => onOpenModal({ i, k, item })}
                      link={() =>
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${i.data.formattedAddress}`
                        )
                      }
                    />
                  </span>
                </List.Header>
                <List.Description>{i.statusAddress}</List.Description>
              </List.Content>
            </List.Item>
          ))}
      </List>
    </Table.Cell>
    <Table.Cell>
      <a
        href={`https://www.google.com/maps/search/?api=1&query=${item.original.address.join(
          '+'
        )}`}
        target="blank"
      >
        {item.original.address.join(', ')}
      </a>
    </Table.Cell>
    <Table.Cell>
      {item.status === 'SUCCESS' && (
        <Button
          compact
          content="Conferido"
          onClick={() => onCheckItem({ item })}
        />
      )}
    </Table.Cell>
  </Table.Row>
)

Item.propTypes = {
  onOpenModal: t.func,
  onCheckItem: t.func,
  onRemoveItem: t.func,
  item: t.objectOf(t.any),
}

export default Item
