import React from 'react'

import t from 'prop-types'
import { Button, Image, Popup, Table } from 'semantic-ui-react'

function Item({ item, onClickEdit, onClickRemove }) {
  return (
    <Table.Row key={item.uid}>
      <Table.Cell>
        <Image src={item.avatar} size="mini" />
      </Table.Cell>
      <Table.Cell>{item.name}</Table.Cell>
      <Table.Cell>{item.phone}</Table.Cell>
      <Table.Cell>{item.email}</Table.Cell>
      <Table.Cell>
        {item.bank_account.pix_type
          ? `PIX (${item.bank_account.pix_type.toUpperCase()}): ${
              item.bank_account.pix
            }`
          : `AG: ${item.bank_account.ag}, CC: ${item.bank_account.cc}`}
      </Table.Cell>

      <Table.Cell>
        <Popup
          content="Editar Instituição de Caridade"
          trigger={
            <Button icon="edit" onClick={() => onClickEdit('update', item)} />
          }
        />

        {item.is_deleted ? (
          <Popup
            content="Ativar Exibição da Instituição de Caridade"
            trigger={
              <Button
                icon="check"
                color="green"
                onClick={() => onClickRemove(item.uid)}
              />
            }
          />
        ) : (
          <Popup
            content="Desativar Exibição da Instituição de Caridade"
            trigger={
              <Button
                icon="times"
                color="red"
                onClick={() => onClickRemove(item.uid)}
              />
            }
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  item: t.arrayOf(t.string),
  onClickEdit: t.func,
  onClickRemove: t.func,
}

export default Item
