const initialState = {
  data: {},
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const UPDATE_MISSIONS = 'UPDATE_MISSIONS'
const UPDATE_MISSIONS_OK = 'UPDATE_MISSIONS_OK'
const UPDATE_MISSIONS_FAIL = 'UPDATE_MISSIONS_FAIL'

export default function missionsUpdateReducers(state = initialState, action) {
  const { data, message, type } = action

  switch (type) {
    case UPDATE_MISSIONS:
      return { ...state, isFetching: true }

    case UPDATE_MISSIONS_OK:
      return { isFetching: false, data, success: true, error: false }

    case UPDATE_MISSIONS_FAIL:
      return { isFetching: false, error: true, message, success: false }

    default:
      return state
  }
}
