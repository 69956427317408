import { responseData } from '~/utils/reducer'

const FORMS_USERS_ITEMS_FETCH = 'FORMS_USERS_ITEMS_FETCH'
const FORMS_USERS_ITEMS_CREATE = 'FORMS_USERS_ITEMS_CREATE'
const FORMS_USERS_ITEMS_DELETE = 'FORMS_USERS_ITEMS_DELETE'
const FORMS_USERS_ITEMS_UPDATE = 'FORMS_USERS_ITEMS_UPDATE'
const FORMS_USERS_ITEMS_REORDER = 'FORMS_USERS_ITEMS_REORDER'

const formsUsersItems = responseData(FORMS_USERS_ITEMS_FETCH, 'array')
const formsUsersCreateItems = responseData(FORMS_USERS_ITEMS_CREATE, 'object')
const formsUsersDeleteItems = responseData(FORMS_USERS_ITEMS_DELETE, 'object')
const formsUsersUpdateItems = responseData(FORMS_USERS_ITEMS_UPDATE, 'object')
const formsUsersReorderItems = responseData(FORMS_USERS_ITEMS_REORDER, 'array')

export default {
  formsUsersItems,
  formsUsersCreateItems,
  formsUsersDeleteItems,
  formsUsersUpdateItems,
  formsUsersReorderItems,
}
