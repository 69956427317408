import React from 'react'
import { AutoSizer } from 'react-virtualized'

import t from 'prop-types'
import { Area, XAxis, YAxis, Legend, Tooltip, AreaChart } from 'recharts'
import { Card, Button } from 'semantic-ui-react'

import Empty from '~/components/EmptyList'
import 'react-vis/dist/style.css'

const period = {
  day: 'Dia',
  week: 'Semana',
  month: 'Mês',
  year: 'Ano',
}

const Graphic = ({
  data,
  label,
  active,
  periods,
  onPress,
  customTooltip,
  customYAxisDomain,
}) => (
  <Card fluid style={{ position: 'relative', width: '100vm', height: 330 }}>
    <Card.Content>
      <Card.Header>
        {label} ({period[active]})
        <Button.Group basic style={{ float: 'right' }} size="tiny">
          {periods.map((i, idx) => (
            <Button
              key={String(idx)}
              active={active === i}
              onClick={() => onPress(i)}
            >
              {period[i]}
            </Button>
          ))}
        </Button.Group>
      </Card.Header>
      {data.length > 0 ? (
        <AutoSizer>
          {({ width }) => (
            <AreaChart
              width={width}
              height={300}
              data={data}
              margin={{
                top: 50,
                left: 0,
                bottom: 5,
                right: 80,
              }}
            >
              <XAxis dataKey="name" />
              {customYAxisDomain && (
                <YAxis
                  type="number"
                  dataKey="Valor"
                  domain={customYAxisDomain}
                />
              )}
              {!customYAxisDomain && <YAxis type="number" dataKey="Valor" />}
              {customTooltip && <Tooltip content={customTooltip} />}
              {!customTooltip && <Tooltip />}
              <Legend />
              <Area
                fill="#d5e9fb7d"
                dataKey="Valor"
                stroke="#0332597d"
                strokeWidth={4}
              />
            </AreaChart>
          )}
        </AutoSizer>
      ) : (
        <Empty icon="chart bar" text="Nenhuma missão foi realizada..." />
      )}
    </Card.Content>
  </Card>
)

Graphic.propTypes = {
  customYAxisDomain: t.arrayOf(t.number),
  customTooltip: t.func,
  onPress: t.func,
  label: t.string,
  active: t.string,
  data: t.arrayOf(t.any),
  periods: t.arrayOf(t.any),
}

export default Graphic
