import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  padding: 20px;
  background-color: #f6f5fa;
  flex-direction: row;
  border-radius: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: space-between;
`

export const ContainerCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const Title = styled.span`
  color: #90909b;
  font-size: 16px;
  font-weight: bold;
`

export const TitleValue = styled.span`
  color: #3f3e52;
  font-size: 16px;
  font-weight: bold;
`
