export default [
  { key: 'txt', icon: 'file text', text: 'Texto', value: 1 },
  { key: 'ni', icon: 'sort numeric down', text: 'Número Inteiro', value: 2 },
  { key: 'image', icon: 'image', text: 'Upload Imagem', value: 3 },
  { key: 'nf', icon: 'dollar sign', text: 'Número Fracionado', value: 4 },
  { key: 'a', icon: 'upload', text: 'Arquivo', value: 5 },
  { key: 'ls', icon: 'check square', text: 'Lista de Seleção', value: 6 },
  { key: 'me', icon: 'selected radio', text: 'Multipla Escolha', value: 7 },
  { key: 'cam', icon: 'camera', text: 'Câmera', value: 8 },
  { key: 'date', icon: 'calendar', text: 'Data', value: 9 },
  { key: 'addr', icon: 'marker', text: 'Endereço', value: 10 },
]
