import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useCustomers() {
  const [count, setCount] = useState(0)
  const [customers, setCustomers] = useState([])
  const [ratesList, setRatesList] = useState([])
  const [listCustomer, setListCustomer] = useState([])
  const [listNameCustomers, setListNameCustomers] = useState([])
  const [customersCount, setCustomersCount] = useState(0)
  const [loadingCustomers, setLoadingCustomers] = useState(true)

  async function onRegisterCustomer(data) {
    setLoadingCustomers(true)
    try {
      const res = await api.post('sessions/customers', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      getCustomers({
        limit: 30,
        offset: 0,
        direction: 'desc',
        order: 'c.created_at',
      })

      return Message().success(
        'O e-mail para validação de token foi encaminhado para o cliente, aguarde validação'
      )
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingCustomers(false)
    }
  }

  async function onUpdateCustomer(params) {
    setLoadingCustomers(true)
    try {
      const { settings, ...data } = params

      const res = await api.put('customers/admin', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      if (!settings) {
        getCustomers({
          limit: 30,
          offset: 0,
          direction: 'desc',
          order: 'c.created_at',
        })
      }

      getCustomers({ customerId: data.uid })

      if (!data.status && data.is_deleted) {
        return Message().success(
          'O cliente foi desativado, os dados permanecerão, o que impossibilita um novo cadastro com os mesmos dados.'
        )
      }

      return Message().success('Dados do cliente foram atualizados com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingCustomers(false)
    }
  }

  async function onResendEmail(data) {
    try {
      setLoadingCustomers(true)

      const res = await api.patch(
        'sessions/admin/customers/resend-email',
        data,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 204) {
        throw new Error(res.data.message)
      }

      return Message().success('Email reenviado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingCustomers(false)
    }
  }

  async function getCustomers(params) {
    try {
      setLoadingCustomers(true)
      const res = await api.get('customers/admin', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setListNameCustomers(
        res.data?.customers.map(({ uid, name }) => ({
          value: uid,
          text: name.toUpperCase(),
        }))
      )

      setLoadingCustomers(false)
      setCustomers(res.data.customers)
      return setCustomersCount(res.data.count)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingCustomers(false)
    }
  }

  async function getRateMissions(params) {
    try {
      setLoadingCustomers(true)

      const res = await api.get(`/rate-missions?customer_id=${params.uid}`)

      setRatesList(res.data)

      return setLoadingCustomers(false)
    } catch (error) {
      setLoadingCustomers(false)
      return Message().error(error.message)
    }
  }

  async function getListCustomer(params) {
    try {
      setLoadingCustomers(true)

      const res = await api.get('/rate-missions/list', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCount(res.data.data[0]?.total_rows || 0)
      setLoadingCustomers(false)
      return setListCustomer(res.data.data)
    } catch (error) {
      setLoadingCustomers(false)
      return Message().error(error.message)
    }
  }

  async function getAllCustomers() {
    try {
      const res = await api.get('/customers')
      setCustomers(res.data)
    } catch (error) {
      return Message().error(error.message)
    }
  }

  return {
    count,
    ratesList,
    customers,
    listCustomer,
    getCustomers,
    onResendEmail,
    customersCount,
    loadingCustomers,
    getRateMissions,
    getListCustomer,
    listNameCustomers,
    onUpdateCustomer,
    onRegisterCustomer,
    getAllCustomers,
  }
}
