import React, { memo } from 'react'

import t from 'prop-types'
import { Table, Loader, Pagination } from 'semantic-ui-react'

import EmptyList from '../EmptyList'
import { HeaderColumns } from './HeaderColumns'
import { ContainerPageTotal } from './styles'

const TableComponent = ({
  data,
  columns,
  isLoading,
  count,
  totalCount,
  activePage,
  siblingRange,
  emptyText,
  limit,
  offset,
  renderItem,
  boundaryRange,
  showEllipsis,
  showFirstAndLastNav,
  showPreviousAndNextNav,
  handlePaginationChange,
  handleColumnSort,
}) => {
  const PaginationFooter = () => {
    const startValue = Number(offset) + 1
    const endValue =
      Number(offset) + Number(data.length) <= Number(totalCount)
        ? Number(offset) + Number(data.length)
        : totalCount
    const totalItems = Number(totalCount)

    return (
      <ContainerPageTotal>
        {startValue} - {endValue} de ({totalItems}) Total
      </ContainerPageTotal>
    )
  }

  const Empty = () => (
    <Table.Row>
      <Table.Cell colSpan={columns.length}>
        <EmptyList {...emptyText} />
      </Table.Cell>
    </Table.Row>
  )

  return (
    <div style={{ marginTop: 10 }}>
      {isLoading && (
        <div>
          <Loader active inline="centered" />
        </div>
      )}

      {!isLoading && (
        <div className="container">
          <Table
            selectable
            unstackable
            className="table-resize"
            sortable={
              typeof handleColumnSort === 'function' &&
              handleColumnSort !== undefined
            }
          >
            <Table.Header>
              <Table.Row>
                {columns.map((name, index) => (
                  <HeaderColumns
                    name={name}
                    onSort={handleColumnSort}
                    key={String(`key-${index}`)}
                  />
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data?.length === 0 && <Empty />}

              {data?.length > 0 && data.map(renderItem)}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={columns.length}>
                  {count && (
                    <>
                      <Pagination
                        totalPages={Math.ceil(
                          Number(totalCount) / Number(limit)
                        )}
                        activePage={activePage}
                        siblingRange={siblingRange}
                        boundaryRange={boundaryRange}
                        onPageChange={handlePaginationChange}
                        ellipsisItem={showEllipsis ? undefined : null}
                        firstItem={showFirstAndLastNav ? undefined : null}
                        lastItem={showFirstAndLastNav ? undefined : null}
                        prevItem={showPreviousAndNextNav ? undefined : null}
                        nextItem={showPreviousAndNextNav ? undefined : null}
                      />
                      <PaginationFooter />
                    </>
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      )}
    </div>
  )
}

HeaderColumns.propTypes = {
  name: t.string,
}

TableComponent.propTypes = {
  data: t.arrayOf(t.any),
  columns: t.arrayOf(t.string),
  isLoading: t.bool,
  count: t.number,
  totalCount: t.number,
  activePage: t.number,
  limit: t.number,
  siblingRange: t.number,
  emptyText: t.objectOf(t.any),
  offset: t.number,
  renderItem: t.func,
  boundaryRange: t.number,
  showEllipsis: t.bool,
  showFirstAndLastNav: t.bool,
  showPreviousAndNextNav: t.bool,
  handlePaginationChange: t.func,
  handleColumnSort: t.func,
}

export default memo(TableComponent)
