const initialState = {
  data: {},
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const ATTACHMENTS_UPLOAD = 'ATTACHMENTS_UPLOAD'
const ATTACHMENTS_UPLOAD_OK = 'ATTACHMENTS_UPLOAD_OK'
const ATTACHMENTS_UPLOAD_FAIL = 'ATTACHMENTS_UPLOAD_FAIL'

export default function uploadAttachmentsReducers(
  state = initialState,
  action
) {
  const { data, message, type } = action

  switch (type) {
    case ATTACHMENTS_UPLOAD:
      return { ...state, isFetching: true }

    case ATTACHMENTS_UPLOAD_OK:
      return { isFetching: false, data, success: true, error: false }

    case ATTACHMENTS_UPLOAD_FAIL:
      return { isFetching: false, error: true, message, success: false }

    default:
      return state
  }
}
