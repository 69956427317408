import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'

export const StyledDropdown = styled(Dropdown)`
  position: relative;

  @media (min-width: 768px) {
    > i {
      position: absolute;
      left: 16px;
      top: calc(50% - 7px);
    }
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;

  > span {
    margin-left: 27.52px;
  }

  > i {
    margin-right: 0 !important;
  }

  @media (max-width: 767px) {
    display: none !important;
    visibility: hidden;
  }
`
