import React from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Button, Modal } from 'semantic-ui-react'

import { formatPrice } from '~/utils/types'

export function ShowDetailsModal({
  showModalDetails,
  closeShowModalDetails,
  challenge,
}) {
  const CURRENCY_TYPE = {
    POINTS: 'Pontos',
    MONEY: 'Dinheiro',
  }

  const getChallengeReward = () => {
    if (challenge.currency_type === 'POINTS') {
      return `${parseInt(challenge.value, 0)} pontos`
    }

    return formatPrice(challenge.value)
  }

  const getDateFormatted = data => {
    if (!data) {
      return 'Não foi informado'
    }
    return dayjs(data, 'America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss')
  }

  const renderChallengeParams = () => {
    return (
      <pre style={{ padding: '15px', background: 'black', color: 'white' }}>
        <code>{JSON.stringify(challenge.params, null, 2)}</code>
      </pre>
    )
  }

  return (
    <Modal size="small" open={showModalDetails} onClose={closeShowModalDetails}>
      <Modal.Header>Detalhes do desafio</Modal.Header>
      <Modal.Content>
        {challenge !== null && (
          <div>
            <p style={{ marginBottom: '0px' }}>
              <strong>Descrição:</strong> {challenge.description}
            </p>
            <span>
              <strong>Vai iniciar em: </strong>
              {getDateFormatted(challenge.start_at)}
            </span>
            <br />
            <span>
              <strong>Vai finalizar em: </strong>
              {getDateFormatted(challenge.end_at)}
            </span>
            <br />
            <span>
              <strong>Tipo da premiação: </strong>{' '}
              {CURRENCY_TYPE[challenge.currency_type]}
            </span>
            <br />
            <span>
              <strong>Premiação: </strong> {getChallengeReward()}
            </span>
            <br />
            <span>
              <strong>É recorrente? </strong>{' '}
              {challenge.recurrence ? 'Sim' : 'Não'}
            </span>
            <br />
            <p>
              <strong>Parâmetros customizados: </strong>
              <br />
              {renderChallengeParams()}
            </p>
            <br />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeShowModalDetails}>Fechar</Button>
      </Modal.Actions>
    </Modal>
  )
}

ShowDetailsModal.propTypes = {
  showModalDetails: t.bool,
  closeShowModalDetails: t.func,
  challenge: t.objectOf(t.any),
}
