import { useState } from 'react'

import * as Yup from 'yup'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useCharitiesIntitutions() {
  const [loading, setLoading] = useState(false)
  const [charitiesInstitutions, setCharitiesInstitutions] = useState([])
  const [count, setCount] = useState(0)
  const [totalDonateRequest, setTotalDonateRequest] = useState({})
  const [countDonateRequest, setCountDonateRequest] = useState(0)
  const [requestsData, setRequestsData] = useState([])

  const schemaValidation = Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),
    cnpj: Yup.string().required('O CNPJ é obrigatório'),
    email: Yup.string().required('O e-mail é obrigatório'),
    phone: Yup.string().required('O telefone é obrigatório'),
    description: Yup.string().required('A descrição é obrigatória'),
    url: Yup.string().required('A URL é obrigatória'),
    bank_account: Yup.object().required(
      'As informações financeiras são obrigatórias'
    ),
    avatar: Yup.string().required('A imagem é obrigatória'),
  })

  async function getCharitiesInstitutions(params) {
    try {
      setLoading(true)

      const res = await api.get('charities-institutions', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCount(res.data.count)
      return setCharitiesInstitutions(res.data.institutions)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function createCharitiesInstitutions(params) {
    try {
      setLoading(true)

      const isValid = schemaValidation.isValidSync(params)

      if (!isValid) {
        const validate = schemaValidation.validateSync(params)
        return Message().error({ message: validate })
      }

      const data = new FormData()

      data.set('bank_account', JSON.stringify(params?.bank_account))
      data.set('cnpj', params?.cnpj)
      data.set('description', params?.description)
      data.set('email', params?.email)
      data.set('name', params?.name)
      data.set('url', params?.url)
      data.set('phone', params?.phone)

      if (params?.avatar?.includes('data:image')) {
        data.append('file', params?.data)
      }

      const res = await api.post('charities-institutions', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCharitiesInstitutions(prevState => [...prevState, res.data[0]])

      return Message().success('Instituição de Caridade criada com Sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function changeRemove(charitiesInstitutionsId) {
    try {
      setLoading(true)

      const res = await api.delete(
        `charities-institutions/${charitiesInstitutionsId}`,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCharitiesInstitutions(prevState =>
        prevState.map(prev => {
          if (prev.uid === res.data[0].uid) {
            return res.data[0]
          }

          return prev
        })
      )

      return Message().success('Status alterado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function updateCharitiesInstitutions(params) {
    try {
      setLoading(true)

      const isValid = schemaValidation.isValidSync(params)

      if (!isValid) {
        const validate = schemaValidation.validateSync(params)
        return Message().error({ message: validate })
      }

      const data = new FormData()

      data.set('bank_account', JSON.stringify(params?.bank_account))
      data.set('cnpj', params?.cnpj)
      data.set('description', params?.description)
      data.set('email', params?.email)
      data.set('name', params?.name)
      data.set('url', params?.url)
      data.set('phone', params?.phone)
      data.set('uid', params?.uid)

      if (params?.avatar?.includes('data:image')) {
        data.append('file', params?.data)
      }

      const res = await api.put('charities-institutions', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCharitiesInstitutions(prevState =>
        prevState.map(prev => {
          if (prev.uid === res.data[0].uid) {
            return res.data[0]
          }

          return prev
        })
      )

      return Message().success('Instituição de Caridade atualizada com Sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function getTotalDonateRequests(params) {
    try {
      setLoading(true)

      const res = await api.get('charities-requests-donate', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setRequestsData(res.data.donatesData)
      setCountDonateRequest(Number(res.data.donatesData[0]?.total_rows) || '')
      return setTotalDonateRequest(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function uploadInvoice(params) {
    try {
      setLoading(true)

      const data = new FormData()

      data.set('charitiesRequestsDonateId', params.charitiesRequestsDonateId)
      data.append('file', params.file)

      const res = await api.put('charities-requests-donate/invoice', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Comprovante enviado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    getCharitiesInstitutions,
    charitiesInstitutions,
    createCharitiesInstitutions,
    changeRemove,
    count,
    updateCharitiesInstitutions,
    getTotalDonateRequests,
    totalDonateRequest,
    countDonateRequest,
    requestsData,
    uploadInvoice,
  }
}
