import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useInvoices() {
  const [invoice, setInvoice] = useState([])
  const [loadingInvoices, setLoadingInvoices] = useState(false)

  async function getInvoice(missionsUsersId) {
    try {
      setLoadingInvoices(true)
      const res = await api.get(`invoices/admin/${missionsUsersId}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setInvoice(res.data.invoices)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingInvoices(false)
    }
  }

  async function onGenerateInvoice(id) {
    try {
      setLoadingInvoices(true)
      const res = await api.get(`invoices/generate/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return window.open(res.data.invoice.invoice_pdf_path, '_blank')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingInvoices(false)
    }
  }

  return {
    invoice,
    getInvoice,
    onGenerateInvoice,
    loadingInvoices,
  }
}
