import React, { useEffect, useState } from 'react'

import { Header, Confirm } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useMultiplier from '~/hooks/useMultiplier'

import { Filter } from './components/Filter'
import { Item } from './components/Item'
import ReproveCreditProfileModal from './components/ReproveCreditProfileModal'
import ShowDocumentsModal from './components/ShowDocumentsModal'
import { UploadContractModal } from './components/UploadContractModal'

const TOTAL_ITENS_PER_PAGE = 10
const TABLE_COLUMNS = [
  'Plano',
  'Usuário',
  'Data da solicitação',
  'Status',
  'Perfil de crédito',
  'Ações',
]

export default function Requests() {
  const [isLoadingLoanRequest, setIsLoadingLoanRequest] = useState(true)
  const [openUploadContractModal, setOpenUploadContractModal] = useState(false)
  const [registerSelectedToShowDocument, setRegisterSelectedToShowDocument] =
    useState()
  const [openDocumentsModal, setOpenDocumentsModal] = useState(false)
  const [openReproveCreditProfile, setOpenReproveCreditProfile] =
    useState(false)
  const [openApproveCreditProfile, setOpenApproveCreditProfile] =
    useState(false)
  const [creditProfileSelected, setCreditProfileSelected] = useState({})
  const [openConfirmApprovationLoanModal, setOpenConfirmApprovationLoanModal] =
    useState(false)
  const [registerSelectedToApprove, setRegisterSelectedToApprove] = useState()
  const [filters, setFilters] = useState({
    status: null,
    username: null,
    planId: null,
    page: 1,
    itensPerPage: 10,
  })

  const {
    plans,
    loanRequests,
    loadingStatus,
    getLoanRequests,
    approveLoanCreditProfile,
    reproveLoanCreditProfile,
    uploadSignedContract,
    approveLoanRequest,
  } = useMultiplier()

  const getPlanOptions = () => {
    return plans.map(plan => {
      return {
        key: plan.id,
        value: plan.id,
        text: plan.description,
      }
    })
  }

  const changeInputValue = (key, value) => {
    setFilters({ ...filters, [key]: value })
  }

  const search = async event => {
    event.preventDefault()
    setIsLoadingLoanRequest(true)
    await getLoanRequests(filters)
    setIsLoadingLoanRequest(true)
  }

  const cleanFilters = event => {
    event.preventDefault()
    setFilters({
      status: '',
      username: '',
      planId: '',
    })
  }

  const approveCreditProfile = async (id, index) => {
    setCreditProfileSelected({ id, index })
    setOpenApproveCreditProfile(true)
  }

  const reproveCreditProfile = async (id, index) => {
    setCreditProfileSelected({ id, index })
    setOpenReproveCreditProfile(true)
  }

  const selectLoanRequestToShowDocuments = async (id, index) => {
    setCreditProfileSelected({ id, index })
    setRegisterSelectedToShowDocument(loanRequests.data[index])
    setOpenDocumentsModal(true)
  }

  const selectLoanRequestToApprove = (item, index) => {
    setRegisterSelectedToApprove({ ...item, index })
    setOpenConfirmApprovationLoanModal(true)
  }

  const uploadContract = async (id, index) => {
    setCreditProfileSelected({ id, index })
    setOpenUploadContractModal(true)
  }

  useEffect(() => {
    getLoanRequests(filters)
  }, []) //eslint-disable-line

  useEffect(() => {
    setIsLoadingLoanRequest(false)
  }, [loanRequests])

  return (
    <>
      <Header>Solicitações de Multiplicador</Header>

      <Filter
        search={search}
        filters={filters}
        cleanFilters={cleanFilters}
        changeInputValue={changeInputValue}
        getPlanOptions={getPlanOptions}
      />

      <TableComponent
        isLoading={isLoadingLoanRequest}
        columns={TABLE_COLUMNS}
        data={loanRequests?.data}
        offset={parseInt(loanRequests?.page, 10)}
        count={parseInt(loanRequests?.total, 10)}
        totalCount={parseInt(loanRequests?.total, 10)}
        limit={parseInt(loanRequests?.itensPerPage, 10)}
        emptyText={{ icon: 'list', text: 'Nenhuma requisição até o momento' }}
        handlePaginationChange={(_, pagination) =>
          getLoanRequests(filters, {
            page: pagination.activePage,
            itensPerPage: TOTAL_ITENS_PER_PAGE,
          })
        }
        renderItem={(item, index) => (
          <Item
            item={item}
            index={index}
            uploadContract={uploadContract}
            approveCreditProfile={approveCreditProfile}
            reproveCreditProfile={reproveCreditProfile}
            selectLoanRequestToApprove={selectLoanRequestToApprove}
            selectLoanRequestToShowDocuments={selectLoanRequestToShowDocuments}
          />
        )}
      />

      <Confirm
        open={openConfirmApprovationLoanModal}
        header="Aprovar Solicitação"
        content="Tem certeza que deseja aprovar esta solicitação?"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onCancel={() => setOpenConfirmApprovationLoanModal(false)}
        onConfirm={() => {
          setOpenConfirmApprovationLoanModal(false)
          approveLoanRequest(registerSelectedToApprove)
        }}
      />

      <Confirm
        open={openApproveCreditProfile}
        header="Perfil de Crédito"
        content="Tem certeza que deseja aprovar este usuário?"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onCancel={() => setOpenApproveCreditProfile(false)}
        onConfirm={() => {
          setOpenApproveCreditProfile(false)
          approveLoanCreditProfile(
            creditProfileSelected.id,
            creditProfileSelected.index
          )
        }}
      />

      <ReproveCreditProfileModal
        reproveLoanCreditProfile={reproveLoanCreditProfile}
        openReproveCreditProfile={openReproveCreditProfile}
        setOpenReproveCreditProfile={setOpenReproveCreditProfile}
        setCreditProfileSelected={setCreditProfileSelected}
        creditProfileSelected={creditProfileSelected}
      />

      <ShowDocumentsModal
        openDocumentsModal={openDocumentsModal}
        setOpenDocumentsModal={setOpenDocumentsModal}
        registerSelectedToShowDocument={registerSelectedToShowDocument}
      />

      <UploadContractModal
        loading={loadingStatus}
        onUpload={uploadSignedContract}
        visible={openUploadContractModal}
        onClose={() => setOpenUploadContractModal(false)}
        creditProfileSelected={creditProfileSelected}
      />
    </>
  )
}
