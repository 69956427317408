import React from 'react'

import { Container, Image } from 'semantic-ui-react'

function ContainerImages({ answer, onClick }) {
  if (answer.includes('path')) {
    return (
      <Container fluid>
        {JSON.parse(answer).map((i, index) => (
          <Image
            wrapped
            width={40}
            src={i.path}
            style={{ cursor: 'pointer', flexDirection: 'row', marginLeft: 5 }}
            onClick={() => onClick(index, answer)}
          />
        ))}
      </Container>
    )
  }

  return answer
}

export default ContainerImages
