import React, { useState, useRef } from 'react'
import ReactQuill from 'react-quill'

import t from 'prop-types'
import { Button, Card, Form, Grid, Image, Modal } from 'semantic-ui-react'

function CreateOrEdit({
  createOrEditModal,
  onClose,
  data,
  loading,
  onClickConfirm,
}) {
  const [values, setValues] = useState({})
  const [accountType, setAccountType] = useState(null)

  const uploadRef = useRef()

  function handleChange(key, value) {
    setValues(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  function handleChangeBankAccount(key, value) {
    setValues(prevState => ({
      ...prevState,
      bank_account: {
        ...prevState.bank_account,
        [key]: value,
      },
    }))
  }

  function uploadImage(file) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = event => {
      setValues(prevState => ({
        ...prevState,
        avatar: event.target.result,
        data: file,
      }))
    }
  }

  return (
    <Modal
      open={createOrEditModal.open}
      onClose={onClose}
      size="small"
      onMount={() => setValues(data)}
      onUnmount={() => setValues({})}
    >
      <Modal.Header>
        {createOrEditModal.textHeader} Instituição de Caridade
      </Modal.Header>
      <Modal.Content>
        <Form loading={loading}>
          <Grid columns={2} relaxed>
            <Grid.Column width={3}>
              <Card>
                <Image
                  size="medium"
                  verticalAlign="middle"
                  centered
                  src={
                    values?.avatar
                      ? values?.avatar
                      : 'http://placehold.it/200x200'
                  }
                />

                <input
                  type="file"
                  id="uploadImage"
                  accept="image/*"
                  style={{ display: 'none' }}
                  ref={uploadRef}
                  onChange={event => uploadImage(event.target.files[0])}
                />
                <Button
                  icon="upload"
                  content="Upload"
                  labelPosition="right"
                  onClick={() => uploadRef.current.click()}
                />
              </Card>
            </Grid.Column>
            <Grid.Column width={13} verticalAlign="middle">
              <Form.Group widths="equal">
                <Form.Input
                  id="name"
                  label="Nome"
                  value={values.name || ''}
                  onChange={(e, { value }) => handleChange('name', value)}
                />
                <Form.Input
                  type="number"
                  id="cnpj"
                  label="CNPJ"
                  value={values.cnpj || ''}
                  onChange={(e, { value }) => handleChange('cnpj', value)}
                />
                <Form.Input
                  id="email"
                  label="E-mail"
                  value={values.email || ''}
                  onChange={(e, { value }) => handleChange('email', value)}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  id="url"
                  label="Site (url)"
                  value={values.url || ''}
                  onChange={(e, { value }) => handleChange('url', value)}
                />
                <Form.Input
                  type="number"
                  id="phone"
                  label="Telefone"
                  value={values.phone || ''}
                  onChange={(e, { value }) => handleChange('phone', value)}
                />
                <Form.Select
                  id="bank_account"
                  label="Tipo da Conta"
                  options={[
                    { key: 0, value: 'pix', text: 'PIX' },
                    {
                      key: 1,
                      value: 'bank_account',
                      text: 'Conta Bancária',
                    },
                  ]}
                  onChange={(e, { value }) => setAccountType(value)}
                />
              </Form.Group>
              {accountType === 'pix' && (
                <Form.Group widths="equal">
                  <Form.Select
                    label="Tipo da Chave PIX"
                    options={[
                      { key: 0, value: 'cpf', text: 'CPF' },
                      { key: 1, value: 'phone', text: 'Telefone' },
                      { key: 2, value: 'email', text: 'E-mail' },
                      { key: 3, value: 'key', text: 'Chave Aleatória' },
                    ]}
                    onChange={(e, { value }) =>
                      handleChangeBankAccount('pix_type', value)
                    }
                  />
                  <Form.Input
                    label="Chave PIX"
                    onChange={(e, { value }) =>
                      handleChangeBankAccount('pix', value)
                    }
                  />
                </Form.Group>
              )}

              {accountType === 'bank_account' && (
                <Form.Group widths="equal">
                  <Form.Input
                    label="Agência"
                    onChange={(e, { value }) =>
                      handleChangeBankAccount('ag', value)
                    }
                  />
                  <Form.Input
                    label="Conta"
                    onChange={(e, { value }) =>
                      handleChangeBankAccount('cc', value)
                    }
                  />
                </Form.Group>
              )}
            </Grid.Column>
          </Grid>

          <Grid columns={1}>
            <Grid.Column>
              <Form.Group widths="equal">
                <div className="field">
                  <label>Descrição (Obrigatório)</label>
                  <ReactQuill
                    value={values.description || ''}
                    onChange={value => handleChange('description', value)}
                  />
                </div>
              </Form.Group>
            </Grid.Column>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancelar" onClick={onClose} />
        <Button
          onClick={() => onClickConfirm(createOrEditModal.type, values)}
          content="Confirmar"
          primary
        />
      </Modal.Actions>
    </Modal>
  )
}

CreateOrEdit.propTypes = {
  createOrEditModal: t.objectOf(t.any),
  onClose: t.func,
  data: t.objectOf(t.any),
  loading: t.bool,
  onClickConfirm: t.func,
}

export default CreateOrEdit
