import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'

import EmptyList from '~/components/EmptyList'
import Loading from '~/components/Loading'
import useMissions from '~/hooks/useMissions'
import useMissionsUsersCheckInCheckOut from '~/hooks/useMissionsUsersCheckInCheckOut'
import useSubCategories from '~/hooks/useSubCategories'
import useUfsAndCities from '~/hooks/useUfsAndCities'
import useWorkforce from '~/hooks/useWorkforce'
import CardDays from '~/pages/Workforce/components/CardDays'

import Actions from './components/Actions'
import Charts from './components/Charts'
import ChecksList from './components/ChecksList'
import Filter from './components/Filter'

function WorkforceManager() {
  const { onChangeUFs, ufsOptions, citiesOptions } = useUfsAndCities()
  const {
    getActiveMissionsByCustomerId,
    activeMissionsByCustomer,
    setActiveMissionsByCustomer,
  } = useMissions()
  const {
    getUsers,
    exportReport,
    adherence,
    users,
    setUsers,
    dateList,
    loadingWorkforce,
    adherenceGraphicBar,
    adherenceGraphicPizza,
    totalRegisters,
    ratings,
  } = useWorkforce()
  const { getSubCategories, subcategoriesOptions } = useSubCategories()

  const {
    updateOrCreateMissionsUsersCheckInCheckOut,
    getManyMissionsUsersCheckInCheckOut,
    missionsUsers,
    needCreate,
  } = useMissionsUsersCheckInCheckOut()

  const today = dayjs().format('YYYY-MM-DD')

  const [filters, setFilters] = useState({
    period: 7,
  })
  const [selectedUsers, setSelectedUsers] = useState([])
  const [timeCheck, setTimeCheck] = useState({})
  const [openModalChecksList, setOpenModalChecksList] = useState(false)
  const [mucicoIds, setMucicoIds] = useState([])
  const [muIds, setMuIds] = useState([])
  const [checkTypesId, setCheckTypesId] = useState(null)
  const [openModalActions, setOpenModalActions] = useState(false)

  function handleChange(values) {
    if (values.customerId) {
      getActiveMissionsByCustomerId({ customerId: values.customerId })
    }

    setFilters(prevState => ({
      ...prevState,
      ...values,
    }))
  }

  function getFilters() {
    const { cities, period, ...submitValues } = filters

    if ('cities' in filters) {
      submitValues.cities = cities.map(city => city.cityName)
    }

    if ('period' in filters) {
      if (period === 'custom') {
        submitValues.startDate = dayjs(submitValues.startDate).format(
          'YYYY-MM-DD'
        )
        submitValues.finalDate = dayjs(submitValues.finalDate).format(
          'YYYY-MM-DD'
        )
      } else {
        submitValues.startDate = dayjs()
          .subtract(period - 1, 'day')
          .format('YYYY-MM-DD')

        submitValues.finalDate = today
      }
    }

    return submitValues
  }

  function handleSubmit() {
    setSelectedUsers([])
    getUsers(getFilters())
  }

  function handleExportReport() {
    setSelectedUsers([])
    const submitValues = getFilters()
    submitValues.typeExportation = 'xls'
    exportReport(submitValues)
  }

  function handleReset() {
    setFilters({
      period: 7,
    })
    setActiveMissionsByCustomer([])
    setUsers({})
    setSelectedUsers([])
  }

  function handleClickSelectAllUsers(checked, usersList, date) {
    const uidsUsers = usersList
      ?.filter(user => user.uid.length > 0)
      .map(user => `${user.uid}&&${date}`)

    if (checked) {
      setSelectedUsers(prevState => [...prevState, ...uidsUsers])
    } else {
      setSelectedUsers(prevState =>
        prevState.filter(uid => !uidsUsers.includes(uid))
      )
    }
  }

  function handleClickSelectOneUser(checked, uidUser, date) {
    if (checked) {
      setSelectedUsers(prevState => [...prevState, `${uidUser}&&${date}`])
    } else {
      setSelectedUsers(prevState =>
        prevState.filter(user => user !== `${uidUser}&&${date}`)
      )
    }
  }

  function handleChangeActions(key, value) {
    setTimeCheck(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  async function handleSubmitActions() {
    const submitData = {
      ...timeCheck,
      missionsUsersCheckInCheckOutId: mucicoIds,
      missionsUsersId: muIds,
      checkTypesId,
    }

    const result = await updateOrCreateMissionsUsersCheckInCheckOut(submitData)

    if (result === 200) {
      setTimeout(() => {
        setSelectedUsers([])
        setMucicoIds([])
        setMuIds([])
        setOpenModalActions(false)
        handleSubmit()
      }, 500)
    }
  }

  const handleOpenChecksList = () => {
    setOpenModalChecksList(true)
  }

  const handleOnTabChange = checkType => {
    setCheckTypesId(Number(checkType) + 1)

    const dateConsult = []

    const missionsUsersId = selectedUsers.map(user => {
      const [uid, date] = user.split('&&')

      if (!dateConsult.includes(date)) {
        dateConsult.push(new Date(date))
      }

      return uid
    })

    getManyMissionsUsersCheckInCheckOut({
      missionsUsersId,
      checkTypesId: Number(checkType) + 1,
      dates: dateConsult,
    })
  }

  const handleOnClickConfig = values => {
    setMucicoIds(values?.mucicoIds)
    setMuIds(values?.muIds)

    setOpenModalActions(true)
  }

  useEffect(() => {
    getSubCategories()
  }, []) // eslint-disable-line

  return (
    <>
      <Filter
        filters={filters}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onReset={handleReset}
        onExportReport={handleExportReport}
        ufsOptions={ufsOptions}
        citiesOptions={citiesOptions}
        onChangeUFs={onChangeUFs}
        activeMissions={activeMissionsByCustomer}
        subcategoriesOptions={subcategoriesOptions}
      />

      {selectedUsers.length > 0 && (
        <Actions
          openModalActions={openModalActions}
          onClose={() => setOpenModalActions(false)}
          mucicoIds={mucicoIds}
          selectedUsers={selectedUsers}
          onChageActions={handleChangeActions}
          onSubmit={handleSubmitActions}
          timeCheck={timeCheck}
          handleOpenChecksList={handleOpenChecksList}
        />
      )}

      {loadingWorkforce ? (
        <Loading />
      ) : (
        <>
          {Object.keys(users).length === 0 ? (
            <EmptyList text="Lista de Usuários está Vazia" icon="box" />
          ) : (
            <>
              <CardDays
                dateList={dateList}
                usersData={users}
                adherence={adherence}
                onSelectUsers={handleClickSelectOneUser}
                onSelectAllUsers={handleClickSelectAllUsers}
                selectedUsers={selectedUsers}
                totalRegisters={totalRegisters}
                ratings={ratings}
                refreshWorkforce={() => handleSubmit()}
              />
              <Charts
                adherenceGraphicBar={adherenceGraphicBar}
                adherenceGraphicPizza={adherenceGraphicPizza}
              />
            </>
          )}
        </>
      )}

      <ChecksList
        open={openModalChecksList}
        onClose={() => setOpenModalChecksList(false)}
        checkInCheckOutData={missionsUsers}
        handleOnTabChange={handleOnTabChange}
        needCreate={needCreate}
        onClickConfig={handleOnClickConfig}
      />
    </>
  )
}

export default WorkforceManager
