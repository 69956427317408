import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useLinkFiles() {
  const [loadingArchives, setLoadingArchives] = useState(false)
  const [archives, setArchives] = useState([])
  const [count, setCount] = useState(20)

  const getArchives = useCallback(async params => {
    try {
      setLoadingArchives(true)

      const res = await api.get('links', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingArchives(false)
      setCount(res.data.count)
      return setArchives(res.data.links)
    } catch (error) {
      setLoadingArchives(false)
      return Message().error(error.message)
    }
  }, [])

  async function onRemoveArchive(id) {
    try {
      const res = await api.delete('links', {
        params: {
          linkFilesId: id,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setArchives(archives.filter(a => a.uid !== id))

      return Message().success('Item removido com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    }
  }

  async function onSubmitFiles(files) {
    try {
      setLoadingArchives(true)

      const data = new FormData()

      files.map(f => data.append('files', f.file))

      const res = await api.post('links', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getArchives()
      return Message().success('Arquivo importado com sucesso!')
    } catch (error) {
      setLoadingArchives(false)
      return Message().error(error.message)
    }
  }

  return {
    archives,
    getArchives,
    loadingArchives,
    onRemoveArchive,
    onSubmitFiles,
    count,
  }
}
