import React from 'react'

import { isNil } from 'ramda'
import {
  Card,
  Form,
  Grid,
  Input,
  Select,
  Divider,
  Button,
} from 'semantic-ui-react'

import typesOptions from '../../../helpers/types'

export default function Item({
  item,
  index,
  order,
  submit,
  loading,
  question,
  options,
  handleUpdate,
  submitUpdate,
  handleMultiple,
  removeItemForm,
  onChangeMultiple,
  createItemsUpdate,
  removeItemMultiple,
  questionMultiplesItem,
  forms_users_items_type_id,
}) {
  return (
    <Card fluid key={`key-${index}`}>
      <Card.Content>
        <Form onSubmit={submit}>
          <Form.Group widths="equal">
            <Form.Field
              type="text"
              control={Input}
              value={question}
              label={`${order}. Ordem`}
              onChange={e => handleUpdate(item, e.target.value, 'question')}
            />
            <Form.Field
              label="Tipo"
              control={Select}
              placeholder="Tipo"
              options={typesOptions}
              value={forms_users_items_type_id || null}
              onChange={(e, { value }) =>
                handleUpdate(item, Number(value), 'forms_users_items_type_id')
              }
            />
          </Form.Group>
        </Form>
        {(forms_users_items_type_id === 6 ||
          forms_users_items_type_id === 7) && (
          <div>
            <Divider />
            <Input
              fluid
              focus
              type="text"
              onChange={onChangeMultiple}
              value={questionMultiplesItem[item.uid] || ''}
              placeholder="Adicionar Item...(Pressione ENTER para Salvar)"
              onKeyDown={e => createItemsUpdate(e.key, item)}
            />
            <Divider />
            {!isNil(options) &&
              options.items.map((opt, index) => (
                <Grid columns={2} key={`opt-${index}`}>
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <Form.Field
                        width={14}
                        fluid
                        type="text"
                        control={Input}
                        key={`key-${index}`}
                        value={opt.option}
                        style={{ margin: 10 }}
                        onChange={e =>
                          handleMultiple(item, e.target.value, opt)
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Button
                        color="red"
                        content="Remover"
                        style={{ marginTop: 10 }}
                        onClick={() => removeItemMultiple(item, opt)}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ))}
          </div>
        )}
      </Card.Content>
      <Card.Content extra>
        <Button
          basic
          color="green"
          content="Atualizar"
          loading={loading}
          disabled={loading}
          onClick={() => submitUpdate(item)}
        />
        <Button
          basic
          color="red"
          content="Remover"
          loading={loading}
          disabled={loading}
          onClick={() => removeItemForm(item)}
        />
      </Card.Content>
    </Card>
  )
}
