import React from 'react'

import t from 'prop-types'
import { Button, Modal } from 'semantic-ui-react'

function DeleteModal({
  isOpenDeleteModal,
  closeModal,
  confirmDeleteItemSelected,
}) {
  return (
    <Modal size="mini" open={isOpenDeleteModal} onClose={closeModal}>
      <Modal.Header>Deletar tipo de desafio</Modal.Header>
      <Modal.Content>
        <p>Você tem certeza que você quer deletar o tipo do desafio?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button color="none" onClick={closeModal}>
          Cancelar
        </Button>
        <Button negative onClick={confirmDeleteItemSelected}>
          Confirmar
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

DeleteModal.propTypes = {
  isOpenDeleteModal: t.func,
  closeModal: t.func,
  confirmDeleteItemSelected: t.func,
}
export default DeleteModal
