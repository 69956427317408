import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import _ from 'lodash'
import { Search } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import TableComponent from '~/components/Table'
import useConsultantsMissionsMain from '~/hooks/useConsultantsMissionsMain'
import useUsersConsultants from '~/hooks/useUsersConsultants'

import { Item } from './Item'
import { HeaderSearch } from './styles'

function LeadersBySubcategory() {
  const { state } = useLocation()
  const { item } = state
  const { subcategoryId: missionsMainId } = useParams()

  const {
    consultants,
    getConsultantsMissionsMain,
    onSubmitConsultantsMissionsMain,
    onRemoveConsultantsMissionsMain,
    loadingUsersConsultants,
  } = useConsultantsMissionsMain()
  const {
    getUsersConsultantsLeaders,
    leaders,
    loadingUsersConsultants: loading,
  } = useUsersConsultants()

  async function onIncrementConsultant(consultant) {
    await onSubmitConsultantsMissionsMain({
      missionsMainId,
      leaderId: consultant.uid,
    })

    return getConsultantsMissionsMain({ missionsMainId })
  }

  async function onRemoveLeader(consultantId) {
    await onRemoveConsultantsMissionsMain(consultantId)

    return getConsultantsMissionsMain({ missionsMainId })
  }

  function onChangeSearch(e, { value }) {
    return getUsersConsultantsLeaders({
      search: value,
      missionsMainId,
    })
  }

  useEffect(() => {
    getConsultantsMissionsMain({ missionsMainId })
  }, []) //eslint-disable-line

  return (
    <>
      <Breadcrumbs
        items={[
          { hasLink: true, name: 'Subcategorias' },
          { hasLink: false, name: item.title },
          { active: true, name: 'Líderes' },
        ]}
      />
      <HeaderSearch>
        <h4>Busque e Adicione os Líderes para esta Subcategoria</h4>
        <Search
          fluid
          results={leaders.map(i => ({
            uid: i.uid,
            title: i.name,
            image: i.avatar,
            description: 'Adicionar',
          }))}
          loading={loading}
          style={{
            maxWidth: 300,
            justiContent: 'center',
            alignItems: 'flex-start',
          }}
          noResultsMessage="Nenhum líder encontrado..."
          onResultSelect={(e, { result }) => onIncrementConsultant(result)}
          onSearchChange={_.debounce(onChangeSearch, 500, { leading: true })}
        />
      </HeaderSearch>

      <TableComponent
        data={consultants}
        handlePaginationChange={null}
        renderItem={leader => (
          <Item
            key={leader.uid}
            item={leader}
            loading={loadingUsersConsultants}
            onDisableLeader={() => onRemoveLeader(leader.uid)}
          />
        )}
        columns={['', 'Nome', 'Email', 'Fone', '']}
        emptyText={{ icon: 'check circle outline', text: 'Nenhum líder...' }}
      />
    </>
  )
}

export default LeadersBySubcategory
