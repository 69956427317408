import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import t from 'prop-types'

import Breadcrumbs from '~/components/Breadcrumbs'
import useSubCategories from '~/hooks/useSubCategories'
import { FormSubcategory } from '~/pages/Categories/Subcategories/components/Form'
import Main from '~/pages/Main'

import '~/assets/css/react-draft-wysiwyg.css'

function Detail({ history, location }) {
  const { subcategoryId, categoryId } = useParams()
  const {
    loadingSubcategories,
    onUpdateSubcategory,
    getSubCategoryById,
    subcategory,
    customFields,
    onAddCustomField,
    onRemoveCustomField,
    onChangeCustomFieldInput,
    onChangeDeliveryPlaceCustomFieldInput,
    onRemoveDeliveryPlaceCustomField,
    onAddDeliveryPlaceCustomField,
    deliveryPlacesCustomFields,
  } = useSubCategories()

  async function onUpdate(data) {
    return onUpdateSubcategory(data)
  }

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      name: 'Categorias',
      link: '/categories',
    },
    {
      active: false,
      hasLink: true,
      name: 'Sub-Categorias',
      link: `/categories/${categoryId}/sub-categories`,
    },
    {
      active: true,
      hasLink: false,
      name: subcategory.title,
    },
  ]

  useEffect(() => {
    getSubCategoryById(subcategoryId)
  }, []) //eslint-disable-line

  return (
    <Main history={history} location={location}>
      <Breadcrumbs items={breadcrumbsList} history={history} />

      {!loadingSubcategories ? (
        <FormSubcategory
          show
          subcategory={subcategory}
          onSubmit={onUpdate}
          customFields={customFields}
          loading={loadingSubcategories}
          onAddCustomField={onAddCustomField}
          onRemoveCustomField={onRemoveCustomField}
          onChangeCustomFieldInput={onChangeCustomFieldInput}
          onAddDeliveryPlaceCustomField={onAddDeliveryPlaceCustomField}
          deliveryPlacesCustomFields={deliveryPlacesCustomFields}
          onRemoveDeliveryPlaceCustomField={onRemoveDeliveryPlaceCustomField}
          onChangeDeliveryPlaceCustomFieldInput={
            onChangeDeliveryPlaceCustomFieldInput
          }
        />
      ) : null}
    </Main>
  )
}

Detail.propTypes = {
  history: t.objectOf(t.any),
  location: t.objectOf(t.any),
}

export default Detail
