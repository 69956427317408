import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import t from 'prop-types'
import {
  Grid,
  Card,
  Form,
  Button,
  Dropdown,
  Checkbox,
  Icon,
} from 'semantic-ui-react'

import useCategories from '~/hooks/useCategories'
import useCustomers from '~/hooks/useCustomers'

import '~/assets/css/react-datepicker.css'
import { OptionsInfo } from '../OptionsInfo'

const groups = [
  {
    key: 1,
    value: 'customers',
    text: 'Cliente',
    param: 'cliente',
  },
  {
    key: 2,
    value: 'categories',
    text: 'Categorias',
    param: 'categoria',
  },
  {
    key: 3,
    value: 'missions_main',
    text: 'Subcategorias',
    param: 'sub_catgoria',
  },
]

const types = [
  {
    key: 1,
    value: 'created',
    text: 'Criados',
  },
  {
    key: 2,
    value: 'started',
    text: 'Iniciados',
  },
  {
    key: 3,
    value: 'finished',
    text: 'Finalizados',
  },
  {
    key: 4,
    value: 'approved',
    text: 'Aprovados',
  },
  {
    key: 5,
    value: 'reproved',
    text: 'Reprovados',
  },
  {
    key: 6,
    value: 'expiring',
    text: 'Expirando',
  },
]

const ranges = [
  {
    key: 1,
    value: 'day',
    text: 'Dia',
  },
  {
    key: 2,
    value: 'week',
    text: 'Semana',
  },
  {
    key: 3,
    value: 'month',
    text: 'Mês',
  },
]

const MetricsFilters = ({
  filters,
  loading,
  onChange,
  onSubmit,
  checkAnalytical,
  onResetFilter,
  subcategories,
  onIncrementTypes,
  onIncrementCondicional,
}) => {
  const { loadingCustomers, customers, getCustomers } = useCustomers()
  const { loadingCategories, categories, getCategories } = useCategories()
  const [openInfo, setOpenInfo] = useState(false)

  useEffect(() => {
    getCustomers({ search: '', limit: 1000, offset: 0 })
    getCategories()
  }, []) //eslint-disable-line

  return (
    <Card fluid>
      <Card.Content>
        <Grid columns={4} divided="vertically">
          <Grid.Row>
            <Grid.Column>
              <h1>Grupos</h1>
              <Form>
                {groups.map(i => (
                  <Form.Field key={i.key}>
                    <Checkbox
                      label={i.text}
                      value={i.value}
                      onClick={(e, { value }) =>
                        onIncrementTypes('typesGroups', value)
                      }
                    />
                  </Form.Field>
                ))}
              </Form>
            </Grid.Column>
            <Grid.Column>
              <div
                style={{
                  display: 'flex',
                  gap: 5,
                }}
              >
                <h1>Tipos</h1>{' '}
                <Icon
                  name="info circle"
                  link
                  onClick={() => setOpenInfo(true)}
                />
              </div>
              <OptionsInfo open={openInfo} onClose={() => setOpenInfo(false)} />
              <Form>
                {types.map(i => (
                  <Form.Field key={i.key}>
                    <Checkbox
                      label={i.text}
                      value={i.value}
                      onClick={(e, { value }) =>
                        onIncrementTypes('typesMissions', value)
                      }
                    />
                  </Form.Field>
                ))}
              </Form>
            </Grid.Column>
            <Grid.Column>
              <h1>Período</h1>
              <Button.Group basic style={{ flex: 1, marginBottom: 10 }}>
                {ranges.map(i => (
                  <Button
                    key={i.key}
                    active={filters.range === i.value}
                    onClick={() => onChange('range', i.value)}
                  >
                    {i.text}
                  </Button>
                ))}
              </Button.Group>
              <Form>
                <Form.Field
                  selectsStart
                  control={DatePicker}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Inicio"
                  selected={filters.initial}
                  startDate={filters.initial}
                  endDate={filters.final}
                  onChange={value => onChange('initial', value)}
                />
                <Form.Field
                  selectsStart
                  control={DatePicker}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Final"
                  selected={filters.final}
                  startDate={filters.initial}
                  endDate={filters.final}
                  onChange={value => onChange('final', value)}
                />
              </Form>
            </Grid.Column>
            <Grid.Column>
              <h1>Condicional</h1>
              <Dropdown
                search
                selection
                multiple
                loading={loadingCustomers}
                placeholder="Clientes"
                options={customers.map(i => ({
                  key: i.uid,
                  value: i.uid,
                  text: i.name,
                }))}
                onChange={(e, { value }) =>
                  onIncrementCondicional('customer', value)
                }
              />
              <Dropdown
                search
                selection
                multiple
                loading={loadingCategories}
                options={categories.map(i => ({
                  key: i.uid,
                  value: i.uid,
                  text: i.name,
                }))}
                placeholder="Categorias"
                style={{ marginTop: 10 }}
                onChange={(e, { value }) =>
                  onIncrementCondicional('category', value)
                }
              />
              {subcategories.length > 0 && (
                <Dropdown
                  search
                  selection
                  multiple
                  options={subcategories.map(i => ({
                    key: i.uid,
                    value: i.uid,
                    text: i.title,
                  }))}
                  style={{ marginTop: 10 }}
                  placeholder="Sub-Categoria"
                  onChange={(e, { value }) =>
                    onIncrementCondicional('mission_main', value)
                  }
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
      <Card.Content>
        <Button
          icon="check"
          content="Filtrar"
          labelPosition="left"
          color="blue"
          loading={loading}
          disabled={loading}
          onClick={() => onSubmit(false)}
        />
        <Button
          icon="file excel outline"
          color="green"
          labelPosition="left"
          content="Extração"
          loading={loading}
          disabled={loading}
          onClick={() => onSubmit(true)}
        />
        <Button
          icon="filter"
          content="Resetar Filtro"
          labelPosition="left"
          color="orange"
          onClick={onResetFilter}
        />
        <Checkbox
          label="Analitico"
          style={{ marginLeft: 10 }}
          onChange={(e, { checked }) => checkAnalytical(checked)}
        />
      </Card.Content>
    </Card>
  )
}

MetricsFilters.propTypes = {
  filters: t.objectOf(t.any),
  loading: t.bool,
  onChange: t.func,
  onSubmit: t.func,
  subcategories: t.arrayOf(t.any),
  onIncrementTypes: t.func,
  checkAnalytical: t.func,
  onResetFilter: t.func,
  onIncrementCondicional: t.func,
}
export default MetricsFilters
