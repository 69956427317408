import React from 'react'

import t from 'prop-types'
import { Button, Form, Modal } from 'semantic-ui-react'

function CreateOrEdit({
  open,
  onClose,
  productCategoryData,
  onChange,
  onCreate,
  onUpdate,
}) {
  return (
    <Modal open={open} onClose={onClose} size="tiny">
      <Modal.Header>{productCategoryData?.header}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <Form.Input
              id="title"
              label="Nome da Categoria"
              value={productCategoryData?.title || ''}
              placeholder="Digite um nome para a categoria"
              onChange={({ target }) => onChange('title', target.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              id="description"
              label="Descrição da Categoria"
              value={productCategoryData?.description || ''}
              placeholder="Digite uma descrição para a categoria"
              onChange={({ target }) => onChange('description', target.value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onClose}>
          Cancelar
        </Button>
        {productCategoryData?.type === 'new' ? (
          <Button positive type="submit" onClick={onCreate}>
            Salvar
          </Button>
        ) : (
          <Button color="orange" type="submit" onClick={onUpdate}>
            Atualizar
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  )
}

CreateOrEdit.propTypes = {
  open: t.bool,
  onClose: t.func,
  productCategoryData: t.objectOf(t.any),
  onChange: t.func,
  onCreate: t.func,
  onUpdate: t.func,
}

export default CreateOrEdit
