const initialState = {
  data: {},
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const BUDGET_REQUEST = 'BUDGET_REQUEST'
const BUDGET_REQUEST_OK = 'BUDGET_REQUEST_OK'
const BUDGET_REQUEST_FAIL = 'BUDGET_REQUEST_FAIL'

export default function budgetRequestReducers(state = initialState, action) {
  const { data, message, type } = action

  switch (type) {
    case BUDGET_REQUEST:
      return { ...state, isFetching: true }

    case BUDGET_REQUEST_OK:
      return { isFetching: false, data, success: true, error: false }

    case BUDGET_REQUEST_FAIL:
      return { isFetching: false, error: true, message, success: false }

    default:
      return state
  }
}
