import React, { useState } from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Table, Dropdown, Popup, Confirm, Icon } from 'semantic-ui-react'

import history from '~/services/history'

const IconPop = ({ name, description, color }) => (
  <Popup content={description} trigger={<Icon name={name} color={color} />} />
)

export function Item({
  item,
  roleName,
  onBanned,
  loading,
  onChangeMembers,
  onOpenMissions,
  openUserConsultant,
  onDisableLeader,
  onDisablePrivate,
}) {
  const [confirmPrivate, setConfirmPrivate] = useState(false)

  return (
    <>
      <Table.Row
        key={item.uid}
        positive={item.status && !item.banned}
        negative={!item.status && !item.banned}
        warning={(item.status || !item.status) && item.banned}
      >
        <Table.Cell>
          {item.is_leader ? (
            <IconPop
              name="sitemap"
              color="orange"
              description="Líder Consultor"
            />
          ) : null}

          {item.customer_id !== null ? (
            <IconPop
              name="privacy"
              color="blue"
              description="Cliente Privado"
            />
          ) : null}
          {item.unlimited_members ? (
            <IconPop
              name="star outline"
              color="yellow"
              description="Membros Ilimitados"
            />
          ) : null}
          {item.name}
        </Table.Cell>
        <Table.Cell>{item.email}</Table.Cell>
        <Table.Cell>{item.phone}</Table.Cell>
        <Table.Cell>{item.code}</Table.Cell>
        <Table.Cell>{item.platform}</Table.Cell>
        <Table.Cell>{item.balance}</Table.Cell>
        <Table.Cell>{item.total_points}</Table.Cell>
        <Table.Cell>{item.paid}</Table.Cell>
        <Table.Cell>{item.app_version}</Table.Cell>
        <Table.Cell>
          {item.last_access
            ? dayjs(item.last_access).format('DD/MM/YYYY HH:mm')
            : null}
        </Table.Cell>
        <Table.Cell>
          {dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}
        </Table.Cell>
        <Table.Cell textAlign="center" style={{ width: 150 }}>
          <Dropdown
            text="Opções"
            pointing="right"
            loading={loading}
            floating
            labeled
            button
            className="icon"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="edit"
                content="Dados"
                onClick={() => history.push(`user/${item.uid}`)}
              />

              <Dropdown.Item
                onClick={() => onBanned(item)}
                icon={!item.banned ? 'ban' : 'hand peace'}
                content={!item.banned ? 'Banir' : 'Desbanir'}
              />

              {['Admin', 'Leader'].includes(roleName) ? (
                <>
                  <Dropdown.Item
                    icon="shield"
                    content="Badges"
                    onClick={() => history.push(`user/${item.uid}/badges`)}
                  />
                  <Dropdown.Item
                    icon="plus"
                    content="Adicionar Missão"
                    onClick={() => onOpenMissions(item.uid)}
                  />
                  <Dropdown.Item
                    onClick={onChangeMembers}
                    icon={!item.unlimited_members ? 'star outline' : 'star'}
                    content={
                      !item.unlimited_members
                        ? 'Ativar Member Get Member Ilimitado'
                        : 'Desativar Member Get Member Ilimitado'
                    }
                  />
                  {!item.is_leader ? (
                    <Dropdown.Item
                      icon="sitemap"
                      content="Ativar Líder Consultor"
                      onClick={() => openUserConsultant(item.uid)}
                    />
                  ) : (
                    <>
                      <Dropdown.Item
                        icon="users"
                        content="Consultores"
                        onClick={() =>
                          history.push(`user/${item.uid}/consultants`)
                        }
                      />
                      <Dropdown.Item
                        icon="sitemap"
                        style={{ color: 'red' }}
                        content="Remover Líder Consultor"
                        onClick={onDisableLeader}
                      />
                    </>
                  )}
                  {item.customer_id !== null ? (
                    <Dropdown.Item
                      icon="suitcase"
                      onClick={() => setConfirmPrivate(true)}
                      content="Desabilitar Cliente Privado"
                    />
                  ) : null}
                </>
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        </Table.Cell>
      </Table.Row>

      <Confirm
        open={confirmPrivate}
        confirmButton="Confirmar"
        cancelButton="Cancelar"
        onConfirm={async () => {
          await onDisablePrivate(item, 'customer_id', null)

          return setConfirmPrivate(false)
        }}
        header="Desabilitar Cliente Privado"
        onCancel={() => setConfirmPrivate(false)}
        content="Tem certeza que deseja desabilitar o cliente privado para este usuário?"
      />
    </>
  )
}

Item.propTypes = {
  item: t.objectOf(t.any),
  onBanned: t.func,
  roleName: t.string,
  loading: t.bool,
  onChangeMembers: t.func,
  openUserConsultant: t.func,
  onDisablePrivate: t.func,
  onDisableLeader: t.func,
}

IconPop.propTypes = {
  name: t.string,
  description: t.string,
  color: t.string,
}
