import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #f1f2f3;
  cursor: pointer;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
`

export const ContentValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

export const Title = styled.span`
  font-weight: 800;
  font-size: 16px;
  margin-top: 10px;
`

export const Subtitle = styled.span`
  font-weight: 800;
  font-size: 14px;
  color: #666;
  margin-top: 10px;
`
