import { cond, equals, always, assoc } from 'ramda'

const initialState = {
  message: '',
  progress: 0,
  count: null,
  error: false,
  success: false,
  isFetching: false,
  uniqueId: '',
}

const typeData = {
  array: assoc('data', [], initialState),
  object: assoc('data', {}, initialState),
  boolean: assoc('data', false, initialState),
}

const responseData =
  (typeEvent, has) =>
  (state = typeData[has], action) => {
    const { data, message, type, progress, count, uniqueId } = action
    const fn = cond([
      [
        equals(typeEvent),
        always({
          ...state,
          progress,
          uniqueId,
          isFetching: true,
        }),
      ],
      [
        equals(`${typeEvent}_OK`),
        always({
          isFetching: false,
          data,
          success: true,
          progress: 100,
          error: false,
          count,
          uniqueId,
        }),
      ],
      [
        equals(`${typeEvent}_FAIL`),
        always({
          isFetching: false,
          error: true,
          message,
          success: false,
          uniqueId,
        }),
      ],
    ])
    return fn(type) || state
  }

export { responseData }
