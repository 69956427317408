import styled from 'styled-components'

export const DragContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 50px;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f1f2f3;
`
