import React, { useState, useEffect, useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { withRouter, useHistory, useLocation } from 'react-router-dom'

import queryString from 'query-string'
import { Confirm, Loader } from 'semantic-ui-react'

import ShimmerLoader from '~/components/Loader/Expiring'
import useCategories from '~/hooks/useCategories'
import useCustomers from '~/hooks/useCustomers'
import useMissionsMain from '~/hooks/useMissionsMain'
import useMissionsUsers from '~/hooks/useMissionsUsers'
import useRegisters from '~/hooks/useRegisters'
import useRole from '~/hooks/useRole'
import api from '~/services/api'
import {
  MISSIONS_USERS_REDIRECT,
  MISSIONS_USERS_EXPIRING_EXTEND,
  MISSIONS_USERS_EXPIRING_REMOVE,
  MISSIONS_USERS_EXPIRING_REPORT,
} from '~/services/api/endpoints'
import Message from '~/utils/messages'
import {
  objectToQueryString,
  queryStringToObject,
} from '~/utils/queryStringHelper'

import Main from '../../Main'
import CardItem from './components/CardItem'
import Filter from './components/Filter'
import { ModalMissionUsers } from './components/ModalMissionUsers'
import RemoveUsers from './components/RemoveUsers'
import { Results } from './components/Results'
import { CardGroup } from './styles'

const initialFilters = {
  limit: 10,
  final: null,
  initial: null,
  categoryId: null,
  customerId: null,
  missions_main_id: null,
  ufs: null,
  cities: null,
  progress: [1, 7],
  userName: '',
  search: '',
}

function Expiring() {
  const { removeAllUsers, getResults, loadingResults, results } =
    useMissionsUsers()

  const { getMissionsMain, missionsMain } = useMissionsMain()
  const { createRegister } = useRegisters()
  const { isAdminAndLeader } = useRole()

  const history = useHistory()
  const location = useLocation()

  const [item, setItem] = useState({})
  const [filters, setFilters] = useState(initialFilters)
  const [loading, setLoading] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [missions, setMissions] = useState([])
  const [count, setCount] = useState(0)
  const [selectedMissionID, setSelectedMissionID] = useState('')
  const [subCategories, setSubcategories] = useState([])
  const [expiration, setExpiration] = useState({
    id: null,
    new_expiration: '',
  })

  const { categories, getCategories } = useCategories()
  const { customers, getCustomers } = useCustomers()

  const [isOpenModalUsers, setIsOpenModalUsers] = useState(false)
  const [missionsUsersId, setMissionsUsersId] = useState([])
  const [loadingExp, setLoadingExp] = useState(false)
  const [openResults, setOpenResults] = useState(false)

  const getMissionsUsersExpiring = useCallback(
    async (query = filters) => {
      try {
        const { data, status } = await api.get('missions-users/expiring', {
          params: query,
        })

        if (status === 200) {
          setMissions(data.missionsUsers)
          setCount(data.count)
        }

        const missionsUsersStarted = data.missionsUsers.filter(
          mu => mu.progress === 1
        )

        const muIds = missionsUsersStarted.map(mu => mu.missions_users_id)
        setMissionsUsersId(muIds)
      } catch (error) {
        Message().error(error.response.data.data || 'Houve um erro no servidor')
      } finally {
        setLoading(false)
      }
    },
    [] //eslint-disable-line
  )

  useEffect(() => {
    setLoading(true)
    getMissionsUsersExpiring()
  }, [getMissionsUsersExpiring])

  const submitFilter = useCallback(() => {
    const search = queryStringToObject(location.search)

    setLoading(true)
    getMissionsUsersExpiring(search)
  }, [location, getMissionsUsersExpiring])

  const handleClearFilters = useCallback(() => {
    setFilters({ ...initialFilters })
    const searchString = objectToQueryString(initialFilters)
    history.push({ search: searchString })
  }, [history])

  const getSubcategories = useCallback(async categoryId => {
    try {
      const { data, status } = await api.get('missions-main', {
        params: { categoryId },
        validateStatus() {
          return true
        },
      })

      if (status !== 200) {
        throw new Error(data.message)
      }

      const newArr = data.missionsMains.map(handleSelect)
      return setSubcategories(newArr)
    } catch (error) {
      return Message().error(error.message)
    }
  }, [])

  useEffect(() => {
    getCategories({ limit: 1000, offset: 0 })
  }, []) //eslint-disable-line

  function handleSelect(i, idx) {
    return { key: idx, value: i.uid, text: i.name || i.title }
  }

  function handleMissionsExpiring(missionItem) {
    setItem(missionItem)
    setConfirm(true)
  }

  async function submit() {
    const newArr = missions.filter(
      i => i.missions_users_id !== item.missions_users_id
    )
    setConfirm(false)

    try {
      await api.delete(MISSIONS_USERS_EXPIRING_REMOVE(item.missions_users_id))

      setMissions(newArr)
      Message().success('Usuário removido com sucesso!')
    } catch (err) {
      Message().error(err?.response?.data?.data || 'Houve um erro no servidor')
    }
  }

  const handlerQuery = useCallback(
    (target, value, resetLimit = false) => {
      let handlerQueries
      const queries = queryStringToObject(location.search)

      if (target === 'cities') {
        const parsedCities = value.map(cityObj => cityObj.cityName)
        handlerQueries = { ...queries, [target]: parsedCities }
      } else {
        handlerQueries = { ...queries, [target]: value }
      }

      if (resetLimit) {
        handlerQueries = { ...handlerQueries, limit: 10 }
      }

      const search = objectToQueryString(handlerQueries)

      history.replace({ search, pathname: location.pathname })
    },
    [location.pathname, location.search, history]
  )

  const handlerChange = useCallback(
    (value, key) => {
      if (key === 'categoryId') {
        setFilters(oldFilters => ({ ...oldFilters, [key]: value }))
        handlerQuery(key, value, true)
        return getSubcategories(value)
      }

      if (key === 'initial' || key === 'final') {
        const newValue = new Date(value).getTime()
        setFilters(oldFilters => ({ ...oldFilters, [key]: newValue }))
        return handlerQuery(key, newValue, true)
      }

      if (key !== 'limit') {
        setFilters(oldFilters => ({ ...oldFilters, [key]: value, limit: 10 }))
        return handlerQuery(key, value, true)
      }

      handlerQuery(key, value)
      return setFilters(oldFilters => ({ ...oldFilters, [key]: value }))
    },
    [handlerQuery, getSubcategories]
  )

  const handleExpiration = useCallback((value, muId) => {
    setExpiration({
      id: muId,
      new_expiration: new Date(value).getTime(),
    })
  }, [])

  const handleExtendMissionsUsers = useCallback(async () => {
    const { status } = await api.put(
      MISSIONS_USERS_EXPIRING_EXTEND(expiration.id),
      {
        newExpiration: expiration.new_expiration,
      }
    )

    if (status === 200) {
      submitFilter({})
    }
  }, [expiration, submitFilter])

  function submitExpiration() {
    handleExtendMissionsUsers()
  }

  function onClickFinished(value) {
    getResults({ missionsUsersId: value })
    getMissionsMain({ missionsUsersId: value })

    setOpenResults(true)
  }

  async function handleOnSubmitFinishedMission(values) {
    const submitData = {
      lat: 0,
      lng: 0,
      userId: values.user_id,
      missions_users_id: values.missions_users_id,
    }

    await createRegister(submitData)
    await getMissionsUsersExpiring()

    setOpenResults(false)
  }

  function openModalUsers(missionID) {
    setSelectedMissionID(missionID)
    setIsOpenModalUsers(true)
  }

  async function onRedirectMissionsUsers(userId) {
    try {
      setLoadingExp(true)
      const res = await api.put(
        MISSIONS_USERS_REDIRECT,
        {
          userId,
          missionsUsersId: selectedMissionID,
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setIsOpenModalUsers(false)
      Message().success('Missão redirecionada com sucesso!')
      return getMissionsUsersExpiring()
    } catch (err) {
      return Message().error(err.message || 'Houve um erro no servidor')
    } finally {
      setLoadingExp(false)
    }
  }

  useEffect(() => {
    getCustomers({ limit: 1000, offset: 0 })
  }, []) //eslint-disable-line

  useEffect(() => {
    if (filters.limit !== 10) {
      const query = { ...queryString.parse(location.search) }
      getMissionsUsersExpiring(query)
    }
  }, [filters.limit]) //eslint-disable-line

  async function handleFetchMore() {
    const newLimit = filters.limit + 10
    handlerChange(newLimit, 'limit')
  }

  const handleExport = useCallback(() => {
    const { ...rest } = filters
    const searchString = objectToQueryString(rest)

    return window.open(`${MISSIONS_USERS_EXPIRING_REPORT}?${searchString}`)
  }, [filters])

  const [confirmRemove, setConfirmRemove] = useState(false)
  const [doubleCheckForRemoveUsers, setDoubleCheckForRemoveUsers] =
    useState(false)

  function submitRemoveUsers() {
    setConfirmRemove(true)
  }

  function onConfirmDoubleCheckForRemoveUsers() {
    setDoubleCheckForRemoveUsers(true)
    setConfirmRemove(false)
  }

  function onConfirmRemoveUsers() {
    removeAllUsers(missionsUsersId)
    setDoubleCheckForRemoveUsers(false)
    setMissions([])
  }

  return (
    <Main>
      <Results
        open={openResults}
        onClose={() => setOpenResults(false)}
        loading={loadingResults}
        results={results}
        missionsMain={missionsMain}
        onSubmitFinishedMission={handleOnSubmitFinishedMission}
      />
      <RemoveUsers
        confirmRemove={confirmRemove}
        onCancelConfirm={() => setConfirmRemove(false)}
        onConfirmDoubleCheckForRemoveUsers={onConfirmDoubleCheckForRemoveUsers}
        doubleCheckForRemoveUsers={doubleCheckForRemoveUsers}
        onCancelDoubleConfirm={() => setDoubleCheckForRemoveUsers(false)}
        onConfirmRemoveUsers={onConfirmRemoveUsers}
      />
      <Filter
        {...filters}
        isLoading={loading}
        submit={submitFilter}
        categories={categories}
        handleExport={handleExport}
        handlerChange={handlerChange}
        subCategories={subCategories}
        clearFilters={handleClearFilters}
        customers={customers.map(handleSelect)}
        submitRemoveUsers={submitRemoveUsers}
      />
      {loading && (
        <div style={{ marginTop: 15 }}>
          <Loader active inline="centered" />
        </div>
      )}
      {!loading && (
        <InfiniteScroll
          dataLength={missions.length}
          next={handleFetchMore}
          hasMore={filters.limit < count}
          loader={
            <CardGroup style={{ margin: 0.1 }}>
              {Array.from(Array(10)).map((_, index) => (
                <ShimmerLoader key={String(index)} />
              ))}
            </CardGroup>
          }
          endMessage={
            !missions.length && (
              <p style={{ textAlign: 'center' }}>
                <b>Nenhum item encontrado.</b>
              </p>
            )
          }
        >
          <CardGroup style={{ margin: 0.1 }}>
            {missions.map((mission, index) => (
              <CardItem
                key={String(`d-${mission.mission_id}-${index}`)}
                mission={mission}
                isAdminAndLeader={isAdminAndLeader}
                {...expiration}
                onClick={handleMissionsExpiring}
                onClickUpdate={submitExpiration}
                onClickFinished={onClickFinished}
                handleExpiration={handleExpiration}
                handleChangeUser={() =>
                  openModalUsers(mission.missions_users_id)
                }
              />
            ))}
          </CardGroup>
        </InfiniteScroll>
      )}
      {isOpenModalUsers && (
        <ModalMissionUsers
          isOpenModalUsers={isOpenModalUsers}
          onSubmit={onRedirectMissionsUsers}
          loading={loadingExp}
          onCloseModalUsers={() => setIsOpenModalUsers(false)}
        />
      )}
      <Confirm
        open={confirm}
        header="Alerta"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onConfirm={() => submit()}
        onCancel={() => setConfirm(false)}
        content="Tem certeza deseja retirar este usuário da missão?"
      />
    </Main>
  )
}

export default withRouter(Expiring)
