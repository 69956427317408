import { cond, equals, always, assoc } from 'ramda'

const initialState = {
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const dataList = assoc('data', [], initialState)

const FORMS_DATA = 'FORMS_DATA'
const FORMS_DATA_OK = 'FORMS_DATA_OK'
const FORMS_DATA_FAIL = 'FORMS_DATA_FAIL'

const FORMS_DATA_STATISTICS = 'FORMS_DATA_STATISTICS'
const FORMS_DATA_STATISTICS_OK = 'FORMS_DATA_STATISTICS_OK'
const FORMS_DATA_STATISTICS_FAIL = 'FORMS_DATA_STATISTICS_FAIL'

const formsData = (state = dataList, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_DATA), always({ ...state, isFetching: true })],
    [
      equals(FORMS_DATA_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_DATA_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

const formsDataStatistics = (state = dataList, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_DATA_STATISTICS), always({ ...state, isFetching: true })],
    [
      equals(FORMS_DATA_STATISTICS_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_DATA_STATISTICS_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

export default {
  formsData,
  formsDataStatistics,
}
