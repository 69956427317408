import { useState } from 'react'

import api from '~/services/api'
import {
  GET_CATEGORIES_PRODUCTS,
  GET_PRODUCTS_ITEMS,
  CREATE_CATEGORY_PRODUCTS,
  UPDATE_CATEGORY_PRODUCTS,
  REMOVE_CATEGORY_PRODUCTS,
  CREATE_PRODUCT_ITEM,
  UPDATE_PRODUCT_ITEM,
  REMOVE_PRODUCT_ITEM,
  UPDATE_ALL_STATUS,
  IMPORT_PRODUCTS_ITEMS,
} from '~/services/api/endpoints'
import Message from '~/utils/messages'

export default function useProducts() {
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [categories, setCategories] = useState([])
  const [productsItems, setProductsItems] = useState([])
  const [countProductsItems, setCountProductsItems] = useState(0)

  async function getCategoriesProducts(customerId) {
    try {
      setLoadingProducts(true)

      const res = await api.get(GET_CATEGORIES_PRODUCTS(customerId), {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCategories(
        res.data?.products.map(params => ({
          ...params,
          value: params.uid,
          text: params.title.toUpperCase(),
        }))
      )

      setCountProductsItems(0)
      setProductsItems([])

      return setLoadingProducts(false)
    } catch (error) {
      setLoadingProducts(false)
      return Message().error(error.message)
    }
  }

  async function createCategoryProducts(params) {
    try {
      setLoadingProducts(true)

      const res = await api.post(CREATE_CATEGORY_PRODUCTS, params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setProductsItems([])
      setCountProductsItems(0)

      setLoadingProducts(false)
      getCategoriesProducts(params.customer_id)
      return Message().success('Categoria Criada com Sucesso!')
    } catch (error) {
      setLoadingProducts(false)
      return Message().error(error.message)
    }
  }

  async function updateCatagoryProducts(params) {
    try {
      setLoadingProducts(true)

      const res = await api.put(UPDATE_CATEGORY_PRODUCTS, params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingProducts(false)
      getCategoriesProducts(params.customer_id)

      return Message().success('Categoria atualizada com sucesso.')
    } catch (error) {
      setLoadingProducts(false)
      return Message().error(error.message)
    }
  }

  async function removeCategoryProducts(categoryId) {
    try {
      setLoadingProducts(true)

      const res = await api.delete(REMOVE_CATEGORY_PRODUCTS(categoryId), {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const newList = categories.filter(list => list.uid !== res.data[0].uid)
      setCategories(newList)

      setProductsItems([])
      setCountProductsItems(0)

      setLoadingProducts(false)
      return Message().success('Categoria removida com sucesso.')
    } catch (error) {
      setLoadingProducts(false)
      return Message().error(error.message)
    }
  }

  async function getProductsItems(categoryId, query) {
    try {
      setLoadingProducts(true)

      const res = await api.get(GET_PRODUCTS_ITEMS(categoryId), {
        params: {
          ...query,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCountProductsItems(res.data?.count)
      setProductsItems(res.data?.data)

      return setLoadingProducts(false)
    } catch (error) {
      setLoadingProducts(false)
      return Message().error(error.message)
    }
  }

  async function createProductItem(params) {
    try {
      setLoadingProducts(true)

      const data = new FormData()

      data.set('description', params.description)
      data.set('expired_at', params.expired_at)
      data.set('installments', params.installments)
      data.set('price', params.price)
      data.set('product_id', params.product_id)
      data.set('quantity_discount', params.quantity_discount)
      data.set('special_conditions', params.special_conditions)
      data.set('title', params.title)
      data.set('uid', params.uid)
      data.set('value_discount', params.value_discount)
      data.set('image_path', params.image_path)

      const res = await api.post(CREATE_PRODUCT_ITEM, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getProductsItems(params.product_id, params.queryProductsItems)

      setLoadingProducts(false)

      return Message().success('Produto criado com sucesso.')
    } catch (error) {
      setLoadingProducts(false)
      return Message().error(error.message)
    }
  }

  async function updateProductItem(params) {
    try {
      setLoadingProducts(true)

      const data = new FormData()

      data.set('id', params.id)
      data.set('products_customers_id', params.customer_id)
      data.set('description', params.description)
      data.set('expired_at', params.expired_at)
      data.set('installments', params.installments)
      data.set('price', params.price)
      data.set('product_id', params.product_id)
      data.set('quantity_discount', params.quantity_discount)
      data.set('special_conditions', params.special_conditions)
      data.set('title', params.title)
      data.set('uid', params.uid)
      data.set('value_discount', params.value_discount)
      data.set('image_path', params.image_path)

      const res = await api.put(UPDATE_PRODUCT_ITEM, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getProductsItems(params.categoryId, params.queryProductsItems)

      setLoadingProducts(false)
      return Message().success('Produto alterado com sucesso.')
    } catch (error) {
      setLoadingProducts(false)
      return Message().error(error.message)
    }
  }

  async function removeProductItem(params, queryProductsItems) {
    try {
      setLoadingProducts(true)

      const res = await api.put(
        REMOVE_PRODUCT_ITEM,
        { products: params },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getProductsItems(res.data[0].products_customers_id, queryProductsItems)
      setLoadingProducts(false)

      return Message().success('Produto removido com sucesso.')
    } catch (error) {
      setLoadingProducts(false)
      return Message().error(error.message)
    }
  }

  async function updateAllStatus(params) {
    try {
      setLoadingProducts(true)

      const res = await api.put(UPDATE_ALL_STATUS, params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getProductsItems(
        res.data[0].products_customers_id,
        params.queryProductsItems
      )

      setLoadingProducts(false)
      return Message().success('Status do produto alterado com sucesso.')
    } catch (error) {
      setLoadingProducts(false)
      return Message().error(error.message)
    }
  }

  async function importFile(data, categoryId, query) {
    try {
      setLoadingProducts(true)

      const newData = new FormData()
      newData.set('info', `{"product_id":"${categoryId}"}`)
      newData.append('file', data)

      const res = await api.post(IMPORT_PRODUCTS_ITEMS, newData, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingProducts(false)

      getProductsItems(categoryId, query)
      return Message().success('Produto importado com sucesso.')
    } catch (error) {
      setLoadingProducts(false)

      return Message().error(error.message)
    }
  }

  return {
    getCategoriesProducts,
    getProductsItems,
    loadingProducts,
    categories,
    productsItems,
    createCategoryProducts,
    updateCatagoryProducts,
    removeCategoryProducts,
    createProductItem,
    updateProductItem,
    removeProductItem,
    updateAllStatus,
    importFile,
    countProductsItems,
  }
}
