import React from 'react'

import t from 'prop-types'
import { Form, Card, Icon, Button, Dropdown } from 'semantic-ui-react'

const AccountsCreate = ({
  show,
  admin,
  roles,
  isLoading,
  onChange,
  submitAccount,
}) => (
  <Card
    centered
    fluid
    style={{ marginTop: 10, display: show ? 'block' : 'none' }}
  >
    <Card.Content>
      <Form>
        <Form.Input
          type="text"
          id="email"
          value={admin?.email || ''}
          autoComplete="username"
          onChange={({ target }) => onChange('email', target.value)}
          placeholder="Email"
        />

        <Dropdown
          selection
          options={roles}
          placeholder="Role"
          value={admin?.role_id || null}
          onChange={(e, { value }) => onChange('role_id', value)}
        />
      </Form>
    </Card.Content>
    <Card.Content extra textAlign="left">
      <Button
        icon
        positive
        onClick={submitAccount}
        loading={isLoading}
        disabled={isLoading}
        labelPosition="left"
      >
        <Icon name="check" /> Criar
      </Button>
    </Card.Content>
  </Card>
)

AccountsCreate.propTypes = {
  show: t.bool,
  admin: t.objectOf(t.any),
  roles: t.arrayOf(t.any),
  isLoading: t.bool,
  onChange: t.func,
  submitAccount: t.func,
}

export default AccountsCreate
