import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

import t from 'prop-types'
import { Button, Card, Form, Grid } from 'semantic-ui-react'

import Message from '~/utils/messages'

const CustomFields = ({
  customFields,
  onChangeCustomFieldInput,
  onAddCustomField,
  onRemoveCustomField,
}) => {
  function getCustomFieldWithStandard(item) {
    return item.field
  }

  function notifyCopied() {
    Message().success('Nome da coluna copiado com sucesso!')
  }

  function isEnableCopyOption(item) {
    return !!item.id
  }

  return (
    <Grid columns={1} relaxed>
      <Grid.Column width={16}>
        <div className="field">
          <label>Campos customizados (Opcional)</label>
          <Card fluid>
            <Card.Content>
              <Button
                primary
                style={{ marginBottom: '10px' }}
                onClick={() => onAddCustomField()}
              >
                Novo campo
              </Button>

              {customFields.map((item, index) => {
                return (
                  <div>
                    <label htmlFor={index}>
                      Digite o nome do campo customizado?
                    </label>
                    <Form.Group>
                      <Form.Input
                        value={item.name}
                        onChange={event =>
                          onChangeCustomFieldInput(event.target.value, index)
                        }
                        type="text"
                        width={10}
                      />
                      {isEnableCopyOption(item) && (
                        <CopyToClipboard
                          text={getCustomFieldWithStandard(item)}
                          onCopy={notifyCopied}
                        >
                          <Button icon="copy" />
                        </CopyToClipboard>
                      )}
                      <Button
                        onClick={() => onRemoveCustomField(index)}
                        icon="trash"
                        color="red"
                      />
                    </Form.Group>
                  </div>
                )
              })}
            </Card.Content>
          </Card>
        </div>
      </Grid.Column>
    </Grid>
  )
}

CustomFields.propTypes = {
  customFields: t.arrayOf(t.object),
  onAddCustomField: t.func,
  onRemoveCustomField: t.func,
  onChangeCustomFieldInput: t.func,
}

export default CustomFields
