import {
  FORMS_USERS_ITEMS_CREATE,
  FORMS_USERS_ITEMS_DELETE,
  FORMS_USERS_ITEMS_FETCH,
  FORMS_USERS_ITEMS_UPDATE,
  FORMS_USERS_ITEMS_REORDER,
} from '~/services/api/endpoints'
import { get, post, put, remove } from '~/utils/actionsRequests'

const findById = id =>
  get({
    url: `${FORMS_USERS_ITEMS_FETCH}?forms_users_id=${id}`,
    typeFetch: 'FORMS_USERS_ITEMS_FETCH',
    typeError: 'FORMS_USERS_ITEMS_FETCH_FAIL',
    typeResponse: 'FORMS_USERS_ITEMS_FETCH_OK',
  })

const createItem = data =>
  post({
    data,
    url: FORMS_USERS_ITEMS_CREATE,
    typeFetch: 'FORMS_USERS_ITEMS_CREATE',
    typeError: 'FORMS_USERS_ITEMS_CREATE_FAIL',
    typeResponse: 'FORMS_USERS_ITEMS_CREATE_OK',
  })

const updateItem = data =>
  put({
    data,
    url: FORMS_USERS_ITEMS_UPDATE,
    typeFetch: 'FORMS_USERS_ITEMS_UPDATE',
    typeError: 'FORMS_USERS_ITEMS_UPDATE_FAIL',
    typeResponse: 'FORMS_USERS_ITEMS_UPDATE_OK',
  })

const removeItem = id =>
  remove({
    url: `${FORMS_USERS_ITEMS_DELETE}?formsUsersItemsId=${id}`,
    typeFetch: 'FORMS_USERS_ITEMS_DELETE',
    typeError: 'FORMS_USERS_ITEMS_DELETE_FAIL',
    typeResponse: 'FORMS_USERS_ITEMS_DELETE_OK',
  })

const reorder = data =>
  put({
    data,
    url: FORMS_USERS_ITEMS_REORDER,
    typeFetch: 'FORMS_USERS_ITEMS_REORDER',
    typeError: 'FORMS_USERS_ITEMS_REORDER_FAIL',
    typeResponse: 'FORMS_USERS_ITEMS_REORDER_OK',
  })

export { reorder, findById, createItem, updateItem, removeItem }
