import React, { useState, useEffect, useCallback } from 'react'

import randomize from 'randomatic'
import { Confirm, Loader } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useCustomers from '~/hooks/useCustomers'

import CustomerFilter from './components/Filter'
import Item from './components/Item'
import ModalInfo from './components/ModalInfo'
import ShowPasswordModal from './components/ShowPassword'

const filtersDefault = {
  limit: 30,
  offset: 0,
  order: 'c.created_at',
  direction: 'desc',
}

export default function Customers() {
  const [customerId, setCustomerId] = useState(null)
  const [isCreate, setIsCreate] = useState(true)
  const [pagination, setPagination] = useState({ activePage: 1 })
  const [filters, setFilters] = useState(filtersDefault)
  const [showPassword, setShowPassword] = useState({ open: false, value: null })
  const [customer, setCustomer] = useState({
    name: null,
    cnpj: null,
    email: null,
    phone: null,
    is_private: false,
  })
  const [showCreateCustomer, setShowCreateCustomer] = useState(false)

  const {
    getCustomers,
    customers,
    loadingCustomers,
    customersCount,
    onResendEmail,
    onUpdateCustomer,
    onRegisterCustomer,
  } = useCustomers()

  const [isConfirmRemoveModalOpen, setIsConfirmRemoveModalOpen] =
    useState(false)

  function onChange(key, value) {
    return setCustomer(prevState => ({ ...prevState, [key]: value }))
  }

  function onEdit(item) {
    setCustomer(item)
    setIsCreate(false)
    return setShowCreateCustomer(true)
  }

  function onClose() {
    setCustomer({
      name: null,
      cnpj: null,
      email: null,
      phone: null,
      is_private: false,
    })
    setIsCreate(false)
    setShowCreateCustomer(false)
  }

  const onInactivedCustomer = useCallback(item => {
    setIsConfirmRemoveModalOpen(true)
    setCustomerId(item.uid)
  }, [])

  function onClearFilters() {
    setFilters(filtersDefault)
    return getCustomers(filtersDefault)
  }

  function onChangeFilters(key, value) {
    setFilters(prevState => ({ ...prevState, [key]: value }))
    return getCustomers({ ...filters, [key]: value })
  }

  function onHandleSort(value) {
    return value
  }

  function onHandlePagination(e, { activePage }) {
    setPagination(prevState => ({ ...prevState, activePage }))

    const offset = (activePage - 1) * filters.limit

    setFilters(prevState => ({
      ...prevState,
      offset,
    }))

    getCustomers({
      ...filters,
      offset,
    })
  }

  function onConfirmRemove() {
    setIsConfirmRemoveModalOpen(false)
    return onUpdateCustomer({
      uid: customerId,
      status: false,
      is_deleted: true,
    })
  }

  async function onGeneratePassword(item) {
    const code = await randomize('Aa0', 8)

    setShowPassword({ open: true, value: code, item })
  }

  function onConfirmPreviewPassword() {
    setShowPassword({ open: false, value: null })

    return onUpdateCustomer({
      uid: showPassword.item.uid,
      generatePassword: true,
      preview_password: showPassword.value,
    })
  }

  async function onSubmit() {
    if (isCreate) {
      onRegisterCustomer(customer)
      return setShowCreateCustomer(loadingCustomers)
    }

    onUpdateCustomer(customer)
    return setShowCreateCustomer(loadingCustomers)
  }

  function onOpenCreate() {
    setIsCreate(true)

    return setShowCreateCustomer(!showCreateCustomer)
  }

  useEffect(() => {
    getCustomers(filters)
  }, []) // eslint-disable-line

  return (
    <>
      <CustomerFilter
        filters={filters}
        onCreate={onOpenCreate}
        isLoading={loadingCustomers}
        clearFilters={onClearFilters}
        onChangeFilters={onChangeFilters}
      />

      <ModalInfo
        close={onClose}
        customer={customer}
        onChange={onChange}
        onSubmit={onSubmit}
        open={showCreateCustomer}
        loading={loadingCustomers}
      />

      {loadingCustomers && (
        <div style={{ marginTop: 15 }}>
          <Loader active inline="centered" />
        </div>
      )}

      {!loadingCustomers && customers.length > 0 && (
        <TableComponent
          data={customers}
          columns={[
            'RESPONSAVEL',
            'RAZAO SOCIAL',
            'CNPJ',
            'EMAIL',
            'CRIADO EM',
            'ATUALIZADO EM',
            'STATUS E-MAIL',
            '',
          ]}
          showFirstAndLastNav
          limit={filters.limit}
          count={customersCount}
          showPreviousAndNextNav
          offset={filters.offset}
          totalCount={customersCount}
          isLoading={loadingCustomers}
          handleColumnSort={onHandleSort}
          activePage={pagination.activePage}
          renderItem={item => (
            <Item
              item={item}
              onEdit={onEdit}
              onResend={onResendEmail}
              onInactived={onInactivedCustomer}
              onGeneratePassword={onGeneratePassword}
            />
          )}
          handlePaginationChange={onHandlePagination}
        />
      )}

      <ShowPasswordModal
        openModal={showPassword.open}
        previewPassword={showPassword.value}
        close={() =>
          setShowPassword(prevState => ({ ...prevState, show: false }))
        }
        onSubmit={onConfirmPreviewPassword}
      />

      <Confirm
        open={isConfirmRemoveModalOpen}
        header="Alerta"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onConfirm={onConfirmRemove}
        onCancel={() => setIsConfirmRemoveModalOpen(false)}
        content="Tem certeza deseja desativar este cliente?"
      />
    </>
  )
}
