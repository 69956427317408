import { useEffect, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useMultiplier() {
  const DEFAULT_ERROR_MESSAGE = 'Ocorreu um erro inesperado'
  const [plans, setPlans] = useState([])
  const [loadingReceipt, setLoadingReceipt] = useState(false)
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [loanRequests, setLoanRequests] = useState([])
  const [installments, setInstallments] = useState([])

  const approveLoanRequest = async registerSelectedToApprove => {
    try {
      const { data } = await api.put(
        `/loan-requests/${registerSelectedToApprove.id}/approve`
      )
      const position = registerSelectedToApprove.index
      delete registerSelectedToApprove.index
      loanRequests.data[position] = {
        ...loanRequests.data[position],
        ...data,
      }

      setLoanRequests({ ...loanRequests })
      return Message().success(
        'Aprovação do multiplicador foi feito com sucesso'
      )
    } catch (error) {
      const message = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
      return Message().error(message)
    }
  }

  const getLoanRequests = async params => {
    try {
      const res = await api.get(`/loan-requests`, { params })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setLoanRequests(res.data)
    } catch (error) {
      return Message().error(error.message)
    }
  }

  const getInstallments = async id => {
    const { data } = await api.get(`/loan-requests/${id}/loan-approval-items`)
    setInstallments(data)
  }

  const confirmPaymentTheInstallment = async installmentSelected => {
    try {
      const { data } = await api.put(
        `/loan-requests/${installmentSelected.loanRequestId}/loan-approval-items/${installmentSelected.id}/confirm-payment`
      )
      installments[installmentSelected.index] = {
        ...installments[installmentSelected.index],
        ...data,
      }
      setInstallments([...installments])
      return Message().success('Pagamento da parcela confirmado com sucesso')
    } catch (error) {
      const message = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
      return Message().error(message)
    }
  }

  const uploadInvoiceTheInstallment = async (
    installmentSelected,
    fileUploaded
  ) => {
    try {
      setLoadingStatus(true)

      const formData = new FormData()
      formData.append('invoice', fileUploaded)

      const { data, status } = await api.put(
        `/loan-requests/${installmentSelected.loanRequestId}/loan-approval-items/${installmentSelected.id}/invoice`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )

      if (status !== 200) {
        throw new Error(data.message)
      }

      installments[installmentSelected.index] = {
        ...installments[installmentSelected.index],
        ...data,
      }

      setInstallments([...installments])

      return Message().success(
        'Upload do boleto da parcela foi feito com sucesso'
      )
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingStatus(false)
    }
  }

  async function onUploadReceiptInstallment(installmentSelected, fileUploaded) {
    try {
      setLoadingReceipt(true)
      const hasFileUploadedCorrectType = fileUploaded.type === 'application/pdf'

      if (!hasFileUploadedCorrectType) {
        return Message().error('Você precisa fazer upload do arquivo em PDF')
      }

      const formData = new FormData()
      formData.append('receipt', fileUploaded)

      const res = await api.put(
        `/loan-requests/${installmentSelected.loanRequestId}/loan-approval-items/${installmentSelected.id}/receipt`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      installments[installmentSelected.index] = {
        ...installments[installmentSelected.index],
        ...res.data,
      }

      return Message().success(
        'Upload do boleto da parcela foi feito com sucesso'
      )
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingReceipt(false)
    }
  }

  const getLoanPlans = async () => {
    const { data } = await api.get(`/loan-plans`)
    setPlans(data)
  }

  const enablePlan = async (id, planPositionOnArray) => {
    try {
      const { data } = await api.put(`/loan-plans/${id}/status`, {
        status: true,
      })
      plans[planPositionOnArray] = data
      Message().success('Plano habilitado com sucesso.')
      setPlans([...plans])
    } catch (error) {
      const message = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
      Message().error(message)
    }
  }

  const disablePlan = async (id, planPositionOnArray) => {
    try {
      const { data } = await api.put(`/loan-plans/${id}/status`, {
        status: false,
      })
      plans[planPositionOnArray] = data
      Message().success('Plano desabilitado com sucesso.')
      setPlans([...plans])
    } catch (error) {
      const message = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
      Message().error(message)
    }
  }

  const savePlan = async newPlan => {
    try {
      const { data } = await api.post(`/loan-plans`, newPlan)
      Message().success('Plano cadastrado com sucesso')
      setPlans([...plans, data])
    } catch (error) {
      const message = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
      Message().error(message)
    }
  }

  async function approveLoanCreditProfile(id, index) {
    try {
      setLoadingStatus(true)
      const { data, status } = await api.put(
        `/loans-credit-profile-approvel/${id}/approve`
      )

      if (status !== 200) {
        throw new Error(data.message)
      }

      loanRequests.data[index].credit_profile_status = data.status
      Message().success('Perfil de crédito aprovado com sucesso')

      return setLoanRequests({ ...loanRequests })
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingStatus(true)
    }
  }

  const reproveLoanCreditProfile = async (id, index, motivation) => {
    try {
      const { data } = await api.put(
        `/loans-credit-profile-approvel/${id}/reprove`,
        { motivationReproved: motivation }
      )
      loanRequests.data[index].credit_profile_status = data.status
      Message().success('Perfil de crédito reprovado com sucesso')
      setLoanRequests({ ...loanRequests })
    } catch (error) {
      const message = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
      Message().error(message)
    }
  }

  const getSignedUrlToDocument = async (id, source, sourceField) => {
    const { data } = await api.get(
      `/loan-documents?id=${id}&source=${source}&source_field=${sourceField}`
    )
    return data.url
  }

  const uploadSignedContract = async (fileUploaded, creditProfileSelected) => {
    try {
      const hasFileUploadedCorrectType = fileUploaded.type === 'application/pdf'
      if (!hasFileUploadedCorrectType) {
        return Message().error('Você precisa fazer upload do arquivo em PDF')
      }

      const formData = new FormData()
      formData.append('document', fileUploaded)
      await api.put(
        `/loans-credit-profile-approvel/${creditProfileSelected.id}/signed-contract`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )

      loanRequests.data[
        creditProfileSelected.index
      ].has_signed_contract_url = true
      setLoanRequests({ ...loanRequests })
      return Message().success(
        'Upload do contrato assinado foi feito com sucesso.'
      )
    } catch (error) {
      const message = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE
      return Message().error(message)
    }
  }

  useEffect(() => {
    getLoanPlans()
  }, [])

  return {
    plans,
    getLoanPlans,
    savePlan,
    enablePlan,
    disablePlan,
    getLoanRequests,
    loadingStatus,
    loanRequests,
    approveLoanCreditProfile,
    reproveLoanCreditProfile,
    getSignedUrlToDocument,
    uploadSignedContract,
    getInstallments,
    installments,
    loadingReceipt,
    onUploadReceiptInstallment,
    uploadInvoiceTheInstallment,
    confirmPaymentTheInstallment,
    approveLoanRequest,
  }
}
