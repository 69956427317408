import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'

import t from 'prop-types'
import { Button, Modal } from 'semantic-ui-react'

import { Container, Pagination } from './styles'

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
}

export default function Preview({ isOpen, close, file }) {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages: num_pages }) {
    setNumPages(num_pages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  return (
    <Container open={isOpen} onClose={close}>
      <Modal.Content>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <Pagination>
          <p>
            Pagina {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
          </p>
          <div>
            <Button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              Previous
            </Button>
            <Button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Next
            </Button>
          </div>
        </Pagination>
      </Modal.Content>
    </Container>
  )
}

Preview.propTypes = {
  isOpen: t.bool,
  close: t.func,
  file: t.string,
}
