import React from 'react'

import t from 'prop-types'
import { Card } from 'semantic-ui-react'

function GraphicTooltip({ active, payload }) {
  if (!active || !payload || payload.length === 0) {
    return false
  }

  const data = payload[0].payload

  return (
    <Card style={{ width: '200px' }}>
      <Card.Content>
        <ul>
          <li>
            Período: <strong>{data.period}</strong>
          </li>
          <li>
            Total: <strong>{data.total}</strong>
          </li>
          <li>
            Porcetagem: &nbsp;
            <strong>{data.percentage}</strong>
          </li>
        </ul>
      </Card.Content>
    </Card>
  )
}

GraphicTooltip.propTypes = {
  active: t.bool,
  payload: t.arrayOf(t.objectOf(t.any)),
}

export default GraphicTooltip
