import { useState, useCallback } from 'react'

import dayjs from 'dayjs'

import api from '~/services/api'

export default function useDashboard() {
  const [metrics, setMetricts] = useState([])
  const [reportMetrics, setReportMetrics] = useState([])
  const [greaterValueReportMetric, setGreaterValueReportMetric] = useState(0)

  const getMetrics = useCallback(async period => {
    const response = await api.get('dashboard', {
      params: { period },
      validateStatus() {
        return true
      },
    })

    setMetricts(response.data)
  }, [])

  const getReportMetrics = useCallback(async params => {
    const response = await api.get('dashboard/report', {
      params,
      validateStatus() {
        return true
      },
    })

    let greaterValue = 0

    setReportMetrics(
      response.data.map(item => {
        if (greaterValue < parseInt(item.total, 10)) {
          greaterValue = parseInt(item.total, 10)
        }
        return {
          name: `${dayjs(item.period).format('DD/MM/YYYY')}`,
          Valor: item.total,
          Porcentagem: item.percentage,
          situation: item.situation,
        }
      })
    )
    setGreaterValueReportMetric(greaterValue)
  }, [])

  return {
    getMetrics,
    getReportMetrics,
    metrics,
    reportMetrics,
    greaterValueReportMetric,
  }
}
