import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useFormsData() {
  const [formsData, setFormsData] = useState([])
  const [loadingFormsData, setLoadingFormsData] = useState(false)
  const [loadingCNPJ, setLoadingCNPJ] = useState(false)
  const [loadingQuestion, setLoadingQuestion] = useState(false)

  const getFormsDataByMissionsUsersId = useCallback(async id => {
    try {
      setLoadingFormsData(true)
      const res = await api.get(`forms-data/customers/forms-data-by-mission/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setFormsData(res.data.data)
      return setLoadingFormsData(false)
    } catch (error) {
      setLoadingFormsData(false)
      return Message().error(error.message)
    }
  }, [])

  async function onRemoveImageFormsData(data, missionsUsersId) {
    try {
      const res = await api.put('/forms-data/item/update', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }
      setLoadingQuestion(false)

      getFormsDataByMissionsUsersId(missionsUsersId)
      return Message().success('Resposta alterada com sucesso!')
    } catch (error) {
      setLoadingQuestion(false)
      return Message().error(error.message)
    }
  }

  async function onSubmitFormsDataItem(data, missionsUsersId) {
    try {
      setLoadingQuestion(true)
      const res = await api.post(`/forms-data/item/create`, data)

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingQuestion(false)
      getFormsDataByMissionsUsersId(missionsUsersId)
      return Message().success('Item adicionado com sucesso!')
    } catch (error) {
      setLoadingQuestion(false)
      return Message().error(error.message)
    }
  }

  async function onRemoveFormsDataItem(id, missionsUsersId) {
    try {
      const res = await api.delete(`/forms-data/item/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getFormsDataByMissionsUsersId(missionsUsersId)
      return Message().success('Item removido com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    }
  }

  async function checkCNPJStatus(cnpj) {
    try {
      setLoadingCNPJ(true)

      const result = await api.get(`https://publica.cnpj.ws/cnpj/${cnpj}`)
      const { situacao_cadastral } = result.data.estabelecimento

      const searchText = /.*ativa.*/i

      return {
        status: searchText.test(situacao_cadastral),
        text: situacao_cadastral,
      }
    } catch (error) {
      return Message().error(error.response.data.detalhes)
    } finally {
      setLoadingCNPJ(false)
    }
  }

  return {
    formsData,
    loadingQuestion,
    loadingFormsData,
    onRemoveFormsDataItem,
    onSubmitFormsDataItem,
    onRemoveImageFormsData,
    getFormsDataByMissionsUsersId,
    checkCNPJStatus,
    loadingCNPJ,
  }
}
