import React, { useEffect, useState } from 'react'

import { format } from 'date-fns'
import t from 'prop-types'
import { Card, Image, Button, Divider } from 'semantic-ui-react'

import ImageAvatar from '~/assets/avatar.png'
import useMemberGetMember from '~/hooks/useMemberGetMember'
import { formatPrice } from '~/utils/format'

import { EditValues } from './EditValues'

export function UserInfo({ userDetail, openModal, userId }) {
  const { getHost, host, loadingMember } = useMemberGetMember()
  const [editValues, setEditValues] = useState({
    open: false,
    type: '',
  })

  function handleOnClick({ open, type }) {
    setEditValues(prevState => ({
      ...prevState,
      open,
      type,
    }))
  }

  useEffect(() => {
    if (userDetail.uid) {
      getHost({ guestId: userDetail.uid })
    }
  }, [userDetail.uid]) //eslint-disable-line

  return (
    <>
      <Card fluid>
        <Image src={userDetail.avatar || ImageAvatar} wrapped ui={false} />
        <Card.Content>
          <Card.Header>{userDetail.name}</Card.Header>
          <Card.Meta>
            <span className="date">
              {userDetail.last_access
                ? `Último acesso ${format(
                    new Date(userDetail?.last_access),
                    'dd/MM/yyyy HH:mm'
                  )}`
                : null}
            </span>
          </Card.Meta>
          <Card.Description>{userDetail?.email}</Card.Description>
          <Card.Description>{userDetail?.phone}</Card.Description>
          {!loadingMember && host && (
            <>
              <Divider />
              <Card.Description>
                Convidado por:{' '}
                <a href={`/user/${host?.host_id}`} target="blank">
                  {host?.name}
                </a>
              </Card.Description>
              <Card.Description>
                Comissão gerada: {formatPrice(host?.total)}
              </Card.Description>
              <Divider />
            </>
          )}
          <Button
            fluid
            compact
            color="blue"
            content="Editar Dados"
            onClick={() => openModal()}
            style={{ marginTop: '10px' }}
          />
        </Card.Content>
      </Card>

      <EditValues
        type={editValues.type}
        open={editValues.open}
        setOpen={handleOnClick}
        balance={userDetail.balance}
        paid={userDetail.paid}
        userId={userId}
      />
    </>
  )
}

UserInfo.propTypes = {
  userDetail: t.objectOf(t.any),
  openModal: t.func,
  userId: t.string,
}
