import React from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Card, Checkbox, Container, Grid, Icon, Popup } from 'semantic-ui-react'

import Avatar from '~/pages/Workforce/components/Avatar'

import {
  StatusLabel,
  UserContainer,
  ChecksContainer,
  FlexContainer,
  SelectsContainer,
  UserLinkProfile,
  AddRatingContainer,
} from '../CardDays/styles'
import { Counter } from '../Counter'

const CardItem = props => {
  const { date } = props
  const { ratings } = props
  const { user } = props

  function formatDate(date) {
    return dayjs(date).format('YYYY-MM-DD')
  }

  function hasRating(ratingsData, date, user) {
    return ratingsData?.ratingsMissionsOfUsersPerDay?.[date]?.[user?.uid]
  }

  return (
    <Card.Group key={user.uid}>
      <Card
        centered
        style={{
          ...props.style,
        }}
      >
        <Card.Content>
          <Container fluid text>
            <Grid columns="equal">
              <Grid.Column>
                <SelectsContainer>
                  {user?.uid && (
                    <Popup
                      content="Selecionar usuário"
                      trigger={
                        <Checkbox
                          checked={props.selectedUsers.includes(
                            `${user?.uid}&&${formatDate(date)}`
                          )}
                          onClick={(e, { checked }) =>
                            props.onSelectUsers(
                              checked,
                              user?.uid,
                              formatDate(date)
                            )
                          }
                        />
                      }
                    />
                  )}

                  <Avatar fullName={user?.name} src={user?.avatar} />
                </SelectsContainer>
              </Grid.Column>
              <Grid.Column width={8}>
                <UserContainer>
                  <Card.Header>
                    <FlexContainer>
                      <UserLinkProfile
                        target="_blank"
                        to={`/user/${user?.userId}`}
                      >
                        {user?.name}
                      </UserLinkProfile>

                      <AddRatingContainer
                        onClick={() => props.handleOnClickAddRating(user, date)}
                      >
                        {hasRating(ratings, date, user) ? (
                          <Counter
                            iconName="avgRating"
                            iconColor="yellow"
                            description="Média das Avaliações"
                            text={
                              ratings?.ratingsMissionsOfUsersPerDay?.[date]?.[
                                user?.uid
                              ]
                            }
                          />
                        ) : (
                          <Popup
                            content="Adicionar Primeira Avaliação"
                            trigger={<Icon name="plus" color="green" link />}
                          />
                        )}
                      </AddRatingContainer>
                    </FlexContainer>
                  </Card.Header>
                  <Card.Meta
                    style={{
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {user?.mission}
                  </Card.Meta>
                  {user.has_night_time && (
                    <Card.Meta
                      style={{
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Turno noturno
                    </Card.Meta>
                  )}
                </UserContainer>
              </Grid.Column>
              <Grid.Column textAlign="left">
                <StatusLabel isChecked={user?.checkIn || user?.checkOut} />
              </Grid.Column>
            </Grid>
          </Container>
        </Card.Content>
        <Card.Content extra>
          <ChecksContainer>
            <div>
              <Icon name="sign-in" />
              Check-In{' '}
              {user?.checkIn &&
                dayjs(user?.checkIn).format('DD/MM/YYYY HH:mm:ss')}
            </div>
          </ChecksContainer>
          <ChecksContainer>
            <div>
              <Icon name="sign-out" />
              Check-Out{' '}
              {user?.checkOut &&
                dayjs(user?.checkOut).format('DD/MM/YYYY HH:mm:ss')}
            </div>
          </ChecksContainer>
        </Card.Content>
      </Card>
    </Card.Group>
  )
}

CardItem.propTypes = {
  date: t.string,
  ratings: t.arrayOf(t.objectOf(t.any)),
  user: t.objectOf(t.any),
  style: t.objectOf(t.any),
  onSelectUsers: t.func,
  handleOnClickAddRating: t.func,
  selectedUsers: t.arrayOf(t.string),
}

export default CardItem
