import { responseData } from '~/utils/reducer'

const BADGET_FORM_GET = 'BADGET_FORM_GET'
const BADGET_FORM_FETCH = 'BADGET_FORM_FETCH'
const BADGET_FORM_CREATE = 'BADGET_FORM_CREATE'
const BADGET_FORM_UPDATE = 'BADGET_FORM_UPDATE'
const BADGET_FORM_UPDATE_ANSWER = 'BADGET_FORM_UPDATE_ANSWER'
const BADGET_FORM_CREATE_ANSWER = 'BADGET_FORM_CREATE_ANSWER'
const BADGET_FORM_REMOVE_ANSWER = 'BADGET_FORM_REMOVE_ANSWER'
const BADGET_FORM_REMOVE_QUESTION = 'BADGET_FORM_REMOVE_QUESTION'

const BADGET_FORM_CHANGE_ORDER_ANSWER = 'BADGET_FORM_CHANGE_ORDER_ANSWER'
const BADGET_FORM_CHANGE_ORDER_QUESTIONS = 'BADGET_FORM_CHANGE_ORDER_QUESTIONS'

const findFormBadge = responseData(BADGET_FORM_GET, 'object')
const findFormBadges = responseData(BADGET_FORM_FETCH, 'array')
const badgeFormCreate = responseData(BADGET_FORM_CREATE, 'object')
const badgeFormRemove = responseData(BADGET_FORM_REMOVE_QUESTION, 'object')
const badgeFormUpdateQuestion = responseData(BADGET_FORM_UPDATE, 'object')
const badgeFormUpdateAnswer = responseData(BADGET_FORM_UPDATE_ANSWER, 'object')
const badgeFormCreateAnswer = responseData(BADGET_FORM_CREATE_ANSWER, 'object')
const badgeFormRemoveAnswer = responseData(BADGET_FORM_REMOVE_ANSWER, 'object')

const changeOrderAnswers = responseData(
  BADGET_FORM_CHANGE_ORDER_ANSWER,
  'array'
)
const changeOrderQuestions = responseData(
  BADGET_FORM_CHANGE_ORDER_QUESTIONS,
  'array'
)

export default {
  findFormBadge,
  findFormBadges,
  badgeFormCreate,
  badgeFormRemove,
  badgeFormCreateAnswer,
  badgeFormUpdateQuestion,
  badgeFormUpdateAnswer,
  badgeFormRemoveAnswer,
  changeOrderAnswers,
  changeOrderQuestions,
}
