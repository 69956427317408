const initialState = {
  data: [],
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const MISSIONS_PARTICIPANTS = 'MISSIONS_PARTICIPANTS'
const MISSIONS_PARTICIPANTS_OK = 'MISSIONS_PARTICIPANTS_OK'
const MISSIONS_PARTICIPANTS_FAIL = 'MISSIONS_PARTICIPANTS_FAIL'

export default function missionsParticipantsReducers(
  state = initialState,
  action
) {
  const { data, message, type } = action

  switch (type) {
    case MISSIONS_PARTICIPANTS:
      return { ...state, isFetching: true }

    case MISSIONS_PARTICIPANTS_OK:
      return { isFetching: false, data, success: true, error: false }

    case MISSIONS_PARTICIPANTS_FAIL:
      return { isFetching: false, error: true, message, success: false }

    default:
      return state
  }
}
