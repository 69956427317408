import React from 'react'

import t from 'prop-types'
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Card, Grid } from 'semantic-ui-react'

function Charts({ adherenceGraphicBar, adherenceGraphicPizza }) {
  const chartPizzaColors = ['#3ad089', '#fe5565']

  return (
    <Card fluid>
      <Card.Content>
        <Grid columns={1} doubling stackable>
          <Grid.Row>
            <Grid.Column>
              <Card fluid style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                <ResponsiveContainer>
                  <Card.Content>
                    <BarChart
                      width={500}
                      height={500}
                      margin={{ top: 60 }}
                      data={adherenceGraphicBar}
                    >
                      <XAxis dataKey="name" />
                      <YAxis domain={[0, 100]} />
                      <Legend align="center" />
                      <Bar
                        dataKey="Aderência"
                        fill="#3ad089"
                        label={{
                          position: 'top',
                          formatter: value => Math.round(value),
                        }}
                      />
                    </BarChart>
                  </Card.Content>
                </ResponsiveContainer>
              </Card>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Card fluid>
                <ResponsiveContainer>
                  <Card.Content>
                    <PieChart width={400} height={400}>
                      <Pie
                        data={adherenceGraphicPizza}
                        cx="50%"
                        cy="50%"
                        labelLine
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                        nameKey="name"
                      >
                        {adherenceGraphicPizza.length > 0 &&
                          adherenceGraphicPizza.map((entry, index) => (
                            <Cell
                              key={`cell-${entry.name}`}
                              fill={chartPizzaColors[index]}
                            />
                          ))}
                      </Pie>
                      <Tooltip />

                      <Legend
                        width="100%"
                        formatter={(value, entry) =>
                          `${value} (${entry.payload.percentage})`
                        }
                      />
                    </PieChart>
                  </Card.Content>
                </ResponsiveContainer>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  )
}

Charts.propTypes = {
  adherenceGraphicBar: t.arrayOf(t.any),
  adherenceGraphicPizza: t.arrayOf(t.any),
}

export default Charts
