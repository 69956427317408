import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Confirm, Table } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import useMultiplier from '~/hooks/useMultiplier'

import { Item } from './components/Item'
import { UploadInvoiceModal } from './components/UploadInvoiceModal'
import { UploadReceiptModal } from './components/UploadReceiptModal'

export default function Installments() {
  const params = useParams()
  const {
    installments,
    getInstallments,
    loadingReceipt,
    loadingStatus,
    onUploadReceiptInstallment,
    uploadInvoiceTheInstallment,
    getSignedUrlToDocument,
    confirmPaymentTheInstallment,
  } = useMultiplier()

  const [openReceiptModal, setOpenReceiptModal] = useState(false)
  const [openUploadInvoiceModal, setOpenUploadInvoiceModel] = useState(false)
  const [openConfirmInstallmentPaidModal, setOpenConfimInstallmentPaidModal] =
    useState(false)
  const [installmentSelected, setInstallmentSelected] = useState({})

  const uploadInvoice = (item, index) => {
    setOpenUploadInvoiceModel(true)
    setInstallmentSelected({
      loanRequestId: item.loan_request_id,
      id: item.id,
      index,
    })
  }

  const onOpenReceiptUpload = (item, index) => {
    setOpenReceiptModal(true)
    setInstallmentSelected({
      loanRequestId: item.loan_request_id,
      id: item.id,
      index,
    })
  }

  const confirmInstallmentPaid = (item, index) => {
    setOpenConfimInstallmentPaidModal(true)
    setInstallmentSelected({
      loanRequestId: item.loan_request_id,
      id: item.id,
      index,
    })
  }

  useEffect(() => {
    if (params.id) {
      getInstallments(params.id)
    }
  }, []) //eslint-disable-line

  const breadcrumbs = [
    {
      active: false,
      hasLink: true,
      link: '/financial/multipliers/requests',
      name: 'Solicitações de Multiplicador',
    },
    {
      active: true,
      hasLink: false,
      name: 'Parcelas da Solicitação',
    },
  ]

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Usuário</Table.HeaderCell>
            <Table.HeaderCell>Vencimento</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Boleto Gerado</Table.HeaderCell>
            <Table.HeaderCell>Valor da parcela</Table.HeaderCell>
            <Table.HeaderCell>Ações</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {installments.map((installment, index) => (
            <Item
              index={index}
              installment={installment}
              uploadInvoice={uploadInvoice}
              onOpenReceiptUpload={onOpenReceiptUpload}
              getSignedUrlToDocument={getSignedUrlToDocument}
              confirmInstallmentPaid={confirmInstallmentPaid}
            />
          ))}
        </Table.Body>
      </Table>
      <UploadInvoiceModal
        loading={loadingStatus}
        visible={openUploadInvoiceModal}
        onUpload={uploadInvoiceTheInstallment}
        installmentSelected={installmentSelected}
        onClose={() => {
          setOpenUploadInvoiceModel(false)
          setInstallmentSelected({})
        }}
      />
      <UploadReceiptModal
        loading={loadingReceipt}
        visible={openReceiptModal}
        onUpload={onUploadReceiptInstallment}
        installmentSelected={installmentSelected}
        onClose={() => {
          setOpenReceiptModal(false)
          setInstallmentSelected({})
        }}
      />
      <Confirm
        open={openConfirmInstallmentPaidModal}
        content="Tem certeza que deseja aprovar este pagamento?"
        onCancel={() => setOpenConfimInstallmentPaidModal(false)}
        onConfirm={() => {
          setOpenConfimInstallmentPaidModal(false)
          confirmPaymentTheInstallment(installmentSelected)
        }}
      />
    </>
  )
}
