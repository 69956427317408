import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px dashed #f1f2f3;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fbfbfb;
`
