import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Grid,
  Table,
  Dropdown,
  Button,
  Card,
  Form,
  Select,
} from 'semantic-ui-react'

import TableComponent from '~/components/Table/index'
import useChallenge from '~/hooks/useChallenge'

import { ShowDetailsModal } from './components/ShowDetailModal'

function List() {
  const COLUMNS_TABLE = ['Id', 'Título', 'Está ativo?', 'Ação']

  const [showModalDetails, setShowModalDetails] = useState(false)
  const [challenge, setChallenge] = useState(null)
  const [optionsTypeChallenge, setOptionsTypeChallenge] = useState([])
  const [filters, setFilters] = useState({})
  const {
    isLoading,
    getChallenges,
    challenges,
    enableChallenge,
    disableChallenge,
    getTypeChallenges,
    typeChallenges,
  } = useChallenge()

  const OPTIONS_STATUS = [
    {
      key: 'true',
      value: true,
      text: 'Ativo',
    },
    {
      key: 'false',
      value: false,
      text: 'Inativo',
    },
  ]
  const OPTIONS_RECURRENCE = [
    {
      key: 'true',
      value: true,
      text: 'Sim',
    },
    {
      key: 'false',
      value: false,
      text: 'Não',
    },
  ]

  const openShowModalDetails = item => {
    setShowModalDetails(true)
    setChallenge(item)
  }

  const closeShowModalDetails = () => {
    setShowModalDetails(false)
    setChallenge(null)
  }

  const isDisable = item => {
    return !item.status
  }

  const renderItem = item => {
    return (
      <Table.Row>
        <Table.Cell>{item.id}</Table.Cell>
        <Table.Cell>{item.title}</Table.Cell>
        <Table.Cell>{item.status ? 'Sim' : 'Não'}</Table.Cell>
        <Table.Cell>
          <Dropdown icon="ellipsis vertical">
            <Dropdown.Menu>
              <Dropdown.Menu scrolling>
                <Dropdown.Item
                  onClick={() => openShowModalDetails(item)}
                  icon="search"
                  key="details"
                  text="Ver detalhes"
                  value="details"
                />
                {isDisable(item) && (
                  <Dropdown.Item
                    onClick={() => enableChallenge(item.id)}
                    icon="check"
                    key="habilitar"
                    text="Habilitar desafio"
                    value="habilitar desafio"
                  />
                )}
                {!isDisable(item) && (
                  <Dropdown.Item
                    onClick={() => disableChallenge(item.id)}
                    icon="check"
                    key="desabilitar"
                    text="Desabilitar desafio"
                    value="desabilitar desafio"
                  />
                )}
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown>
        </Table.Cell>
      </Table.Row>
    )
  }

  function handlerInputValue(key, value) {
    return setFilters({ ...filters, [key]: value })
  }

  function resetFilters(event) {
    event.preventDefault()
    setFilters({ status: '', typeChallenge: '', recurrence: '' })

    return getChallenges(challenges.page, {})
  }

  function getChallengesByFilters(event) {
    event.preventDefault()

    return getChallenges(challenges.page, filters)
  }

  useEffect(() => {
    const registers = typeChallenges.map(item => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }))

    setOptionsTypeChallenge([...registers])
  }, [typeChallenges])

  useEffect(() => {
    Promise.all([getChallenges(), getTypeChallenges(['name'])])
  }, []) //eslint-disable-line

  return (
    <>
      <ShowDetailsModal
        showModalDetails={showModalDetails}
        closeShowModalDetails={closeShowModalDetails}
        challenge={challenge}
      />

      <Link to="/challenges/new">
        <Button primary>Novo</Button>
      </Link>

      <Card fluid>
        <Card.Content>
          <Form>
            <Grid columns={3}>
              <Grid.Column>
                <Form.Field>
                  <label>Status:</label>
                  <Select
                    value={filters.status}
                    onChange={(event, data) =>
                      handlerInputValue('status', data.value)
                    }
                    placeholder="Selecionar um opção"
                    options={OPTIONS_STATUS}
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column>
                <Form.Field>
                  <label>Tipo do desafio:</label>
                  <Select
                    value={filters.typeChallenge}
                    onChange={(event, data) =>
                      handlerInputValue('typeChallenge', data.value)
                    }
                    placeholder="Selecionar um opção"
                    options={optionsTypeChallenge}
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column>
                <Form.Field>
                  <label>Desafio é recorrente?</label>
                  <Select
                    value={filters.recurrence}
                    onChange={(event, data) =>
                      handlerInputValue('recurrence', data.value)
                    }
                    placeholder="Selecionar um opção"
                    options={OPTIONS_RECURRENCE}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
            <br />
            <Form.Field>
              <Button
                onClick={getChallengesByFilters}
                content="Filtrar"
                color="green"
                labelPosition="left"
                icon="check"
                type="submit"
              />
              <Button
                onClick={resetFilters}
                content="Resetar filtro"
                color="orange"
                labelPosition="left"
                icon="undo"
                type="submit"
              />
            </Form.Field>
          </Form>
        </Card.Content>
      </Card>

      <TableComponent
        emptyText="Não há desafios cadastrados"
        activePage={challenges.page}
        isLoading={isLoading}
        renderItem={renderItem}
        data={challenges.data}
        columns={COLUMNS_TABLE}
        count={challenges.total}
        totalCount={challenges.total}
        offset={challenges.page}
        limit={challenges.itemsPerPage}
        handlePaginationChange={(event, data) => {
          getChallenges(data.activePage)
        }}
      />
    </>
  )
}

export default List
