import React, { useRef } from 'react'

import t from 'prop-types'
import { Modal, Button, Form, Grid, Image } from 'semantic-ui-react'

function CreateProducts({
  type,
  submit,
  dataProducts,
  showModal,
  onClose,
  onChange,
  uploadImgProduct,
}) {
  const typeHeader =
    type === 'create-product' ? 'Novo Produto' : 'Atualizar Produto'
  const uploadRef = useRef('uploadRef')

  return (
    <Modal open={showModal} onClose={onClose}>
      <Modal.Header>
        <h1>{typeHeader}</h1>
      </Modal.Header>
      <Modal.Content>
        <Form size="big">
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <input
                  ref={uploadRef}
                  style={{ display: 'none' }}
                  type="file"
                  id="image_path"
                  onChange={uploadImgProduct}
                />
                <Image
                  src={dataProducts?.image_path || ''}
                  size="small"
                  onClick={() => uploadRef.current.click()}
                  style={{ cursor: 'pointer' }}
                  label="Foto do Produto"
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  fluid
                  label="Nome do Produto"
                  type="text"
                  id="title"
                  value={dataProducts?.title || ''}
                  onChange={(e, { value }) => onChange('title', value)}
                />

                <Form.Input
                  fluid
                  label="Descrição"
                  type="text"
                  id="description"
                  value={dataProducts?.description || ''}
                  onChange={(e, { value }) => onChange('description', value)}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3}>
              <Grid.Column>
                <Form.Input
                  label="Preço"
                  type="number"
                  id="price"
                  value={dataProducts?.price || ''}
                  onChange={(e, { value }) => onChange('price', value)}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  label="Parcelas (x)"
                  type="number"
                  id="installments"
                  value={dataProducts?.installments || ''}
                  onChange={(e, { value }) => onChange('installments', value)}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  label="Expira Em"
                  type="date"
                  id="expired_at"
                  value={dataProducts?.expired_at || ''}
                  onChange={(e, { value }) => onChange('expired_at', value)}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Input
                  label="Desconto a partir de"
                  type="number"
                  id="quantity_discount"
                  value={dataProducts?.quantity_discount || ''}
                  onChange={(e, { value }) =>
                    onChange('quantity_discount', value)
                  }
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  label="Valor do desconto"
                  type="number"
                  id="value_discount"
                  value={dataProducts?.value_discount || ''}
                  onChange={(e, { value }) => onChange('value_discount', value)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Input
                  label="Condições Especiais"
                  type="text"
                  id="special_conditions"
                  value={dataProducts?.special_conditions || ''}
                  onChange={(e, { value }) =>
                    onChange('special_conditions', value)
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          size="large"
          color="black"
          onClick={onClose}
          content="Cancelar"
        />
        {type === 'create-product' ? (
          <Button size="large" positive content="Cadastrar" onClick={submit} />
        ) : (
          <Button
            size="large"
            color="orange"
            content="Atualizar"
            onClick={submit}
          />
        )}
      </Modal.Actions>
    </Modal>
  )
}

CreateProducts.propTypes = {
  showModal: t.bool,
  onClose: t.func,
  dataProducts: t.objectOf(t.any),
  onChange: t.func,
  submit: t.func,
  type: t.string,
  uploadImgProduct: t.func,
}

export default CreateProducts
