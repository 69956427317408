import React from 'react'

import t from 'prop-types'
import {
  Button,
  Form,
  FormField,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from 'semantic-ui-react'

export function GenerateSharedLinks({
  isGeneratingSharedLink,
  openModalSharedLinkGenerator,
  setOpenModelSharedLinkGenerator,
  dataToGenerateLinks,
  generateSharedLinks,
  onChangeDataToGenerateLinks,
}) {
  const close = () => {
    onChangeDataToGenerateLinks('linkCampaign', '')
    setOpenModelSharedLinkGenerator(false)
  }

  return (
    <Modal
      size="tiny"
      open={openModalSharedLinkGenerator}
      onClose={() => close()}
    >
      <ModalHeader>Gerar links de compartilhamento</ModalHeader>
      <ModalContent>
        <Form>
          <FormField>
            <Form.Input
              type="url"
              value={dataToGenerateLinks.linkCampaign}
              onChange={event =>
                onChangeDataToGenerateLinks('linkCampaign', event.target.value)
              }
              placeholder="Link da campanha aqui"
            />
          </FormField>
        </Form>
      </ModalContent>
      <ModalActions>
        <Button onClick={() => close()}>Sair</Button>
        <Button primary onClick={() => generateSharedLinks()}>
          {isGeneratingSharedLink ? 'Gerando...' : 'Gerar'}
        </Button>
      </ModalActions>
    </Modal>
  )
}

GenerateSharedLinks.propTypes = {
  isGeneratingSharedLink: t.bool,
  openModalSharedLinkGenerator: t.bool,
  setOpenModelSharedLinkGenerator: t.func,
  dataToGenerateLinks: t.objectOf(t.any),
  generateSharedLinks: t.func,
  onChangeDataToGenerateLinks: t.func,
}
