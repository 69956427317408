import React from 'react'

import t from 'prop-types'
import { Dropdown } from 'semantic-ui-react'

const Customers = ({ loading, values, onChange }) => (
  <Dropdown
    fluid
    search
    selection
    loading={loading}
    onChange={onChange}
    options={values}
    placeholder="Escolha um cliente..."
  />
)

Customers.propTypes = {
  loading: t.bool,
  onChange: t.func,
  values: t.arrayOf(t.any),
}

export default Customers
