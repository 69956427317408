import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { Editor } from 'react-draft-wysiwyg'

import moment from 'moment'
import t from 'prop-types'
import {
  Modal,
  Form,
  List,
  Input,
  Select,
  Button,
  Message,
  Checkbox,
} from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'
import { useAuth } from '~/contexts/auth'
import api from '~/services/api'
import { CUSTOMERS_RESTRICTED_SUBCATEGORIES } from '~/services/api/endpoints'

export function Detail({
  selectedMission,
  isOpenModalDetail,
  close,
  errors,
  handlerInput,
  address,
  loadingAddresses,
  changeAddress,
  handlerDate,
  editorState,
  onEditorStateChange,
  handlerCategories,
  submitMissionDetail,
  customFields,
  customFieldsAnswers,
  onChangeInputValueCustomField,
  getSubCategoryById,
}) {
  const { user } = useAuth()
  const [openAddress, setOpenAddress] = useState(false)
  const [
    alreadyCallPrepareCustomFieldsAndAnswers,
    alreadysetCallPrepareCustomFieldsAndAnswers,
  ] = useState(false)
  const [customFieldsToRender, setCustomFieldsToRender] = useState([])
  const [hasDistance, setHasDistance] = useState(true)
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([])

  const getOptionsArray = arr =>
    arr.map((item, index) => ({
      key: index,
      value: item.uid || item.uf || item,
      text: item.name || item.title || item,
    }))

  async function loadSubCategory(id) {
    await getSubCategoryById(id)
  }

  function prepareCustomFieldsAndAnswers() {
    const registers = {}
    for (let index = 0; index < customFieldsAnswers.length; index += 1) {
      const item = customFieldsAnswers[index]
      registers[item.id] = item.value
    }

    const registersToRender = []
    for (let index = 0; index < customFields.length; index += 1) {
      const item = customFields[index]
      item.value = registers[item.id] || ''
      registersToRender.push(item)
    }

    setCustomFieldsToRender(registersToRender)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const res = await api.get(CUSTOMERS_RESTRICTED_SUBCATEGORIES, {
          params: { customerId: selectedMission.customer_id },
          validateStatus() {
            return true
          },
        })
        setSubCategoriesOptions(getOptionsArray(res.data))
      } catch (error) {
        return alert(error.message) //eslint-disable-line
      }
    })()
    loadSubCategory(selectedMission.missions_main_id)
  }, [selectedMission.customer_id]) //eslint-disable-line

  useEffect(() => {
    const isNeedToPrepareCustomFieldsAndAnswers =
      alreadyCallPrepareCustomFieldsAndAnswers === false &&
      customFields &&
      customFields.length > 0

    if (isNeedToPrepareCustomFieldsAndAnswers) {
      prepareCustomFieldsAndAnswers()
      alreadysetCallPrepareCustomFieldsAndAnswers(true)
    }
  }, [customFields]) //eslint-disable-line

  useEffect(
    () => () => {
      alreadysetCallPrepareCustomFieldsAndAnswers(false)
      setCustomFieldsToRender([])
    },
    []
  )

  return (
    <Modal size="small" open={isOpenModalDetail} onClose={close}>
      <Modal.Header content="Atualizar Missão" />
      <Modal.Content scrolling>
        {errors.length > 0 && (
          <Message
            key={errors}
            warning
            fluid
            header="Alerta!"
            style={{ padding: 10 }}
            list={errors}
          />
        )}
        <Form>
          <Form.Input
            id="name"
            type="text"
            onChange={handlerInput}
            value={selectedMission.name || ''}
            label="Nome da Missão (Obrigatório)"
            placeholder="Ex: Cópia de Processo"
          />
          <Form.Input
            type="text"
            id="identifier"
            onChange={handlerInput}
            value={selectedMission.identifier || ''}
            label="Identificador (Opcional)"
            placeholder="Ex: 123 João"
          />
          {user?.role_name === 'Admin' || user?.role_name === 'Leader' ? (
            <Form.Field
              label="Categoria"
              control={Select}
              id="missionsMain"
              placeholder="Tipo"
              options={subCategoriesOptions}
              value={selectedMission.missions_main_id}
              onChange={handlerCategories}
            />
          ) : null}

          {!selectedMission.no_location && (
            <>
              <Form.Input
                id="place"
                type="text"
                disabled
                placeholder="Endereço da Missão"
                value={selectedMission.place || null}
              />
              <Button
                content="Atualizar Endereço"
                style={{ marginBottom: 10 }}
                onClick={() => setOpenAddress(true)}
              />
            </>
          )}

          <Modal
            size="small"
            open={openAddress}
            closeOnDimmerClick={false}
            onClose={() => setOpenAddress(false)}
          >
            <Modal.Header content="Adicionar Endereço" />
            <Modal.Content scrolling>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Input
                  id="searchAddress"
                  type="text"
                  onChange={handlerInput}
                  loading={loadingAddresses}
                  placeholder="Buscar Endereço..."
                />
                {!loadingAddresses && address.length > 0 && (
                  <List selection verticalAlign="middle">
                    {address.map((i, index) => (
                      <List.Item
                        key={String(index)}
                        onClick={() => {
                          changeAddress(i)
                          setOpenAddress(false)
                        }}
                      >
                        <List.Icon style={{ color: 'red' }} name="marker" />
                        <List.Content>
                          <List.Header as="a">
                            {i.formatted_address}
                          </List.Header>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                )}
              </div>
            </Modal.Content>
          </Modal>

          <Form.Field inline>
            <Checkbox onChange={() => setHasDistance(!hasDistance)} />
            <Input
              type="number"
              id="custom_distance"
              style={{ width: 300 }}
              onChange={handlerInput}
              value={selectedMission.custom_distance || ''}
              placeholder="Restringir Distância (km). Ex: 1"
              disabled={hasDistance}
            />
          </Form.Field>
          <div className="field">
            <label>Data de Expiração</label>
            <div>
              <DatePicker
                id="expired_at"
                minDate={moment()}
                dateFormat="dd/MM/yyyy"
                onChange={data => handlerDate('expired_at', data)}
                value={moment(selectedMission.expired_at).format('DD/MM/YYYY')}
              />
            </div>
          </div>
          <div className="field">
            <label>Data de Ativação</label>
            <div>
              <DatePicker
                id="activated_at"
                minDate={moment()}
                dateFormat="dd/MM/yyyy"
                onChange={data => handlerDate('activated_at', data)}
                value={moment(selectedMission.activated_at).format(
                  'DD/MM/YYYY'
                )}
              />
            </div>
          </div>
          <div className="field">
            {customFieldsToRender.map((item, index) => (
              <div key={item.id} className="field">
                <label htmlFor={item.field}>{item.name} (Opcional):</label>
                <Input
                  type="text"
                  id={item.field}
                  value={item.value || ''}
                  onChange={event =>
                    onChangeInputValueCustomField(index, event.target.value)
                  }
                />
              </div>
            ))}
          </div>
          <div className="field">
            <label>Descrição</label>
            <div className="editor">
              <Editor
                style={{ borderWidth: 1, borderColor: 'gray' }}
                editorState={editorState}
                editorClassName="editorClassName"
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                onEditorStateChange={onEditorStateChange}
              />
            </div>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={() => {
            close()
          }}
        >
          Cancelar
        </Button>
        <Button
          positive
          icon="checkmark"
          content="Atualizar"
          labelPosition="right"
          onClick={() => {
            submitMissionDetail()
          }}
        />
      </Modal.Actions>
    </Modal>
  )
}

Detail.propTypes = {
  selectedMission: t.objectOf(t.any),
  isOpenModalDetail: t.bool,
  close: t.func,
  errors: t.arrayOf(t.any),
  handlerInput: t.func,
  address: t.arrayOf(t.any),
  loadingAddresses: t.bool,
  changeAddress: t.func,
  handlerDate: t.func,
  editorState: t.func,
  onEditorStateChange: t.func,
  handlerCategories: t.func,
  submitMissionDetail: t.func,
  customFields: t.arrayOf(t.any),
  customFieldsAnswers: t.arrayOf(t.any),
  onChangeInputValueCustomField: t.func,
  getSubCategoryById: t.func,
}
