import React from 'react'

import t from 'prop-types'
import { Modal } from 'semantic-ui-react'

function ModalStatusCheckCPF({ loadingCheckCPF }) {
  return (
    <Modal centered open={loadingCheckCPF}>
      <Modal.Header>Consulta Situação CPF</Modal.Header>
      <Modal.Content>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <span>
            Aguarde um momento, a sua consulta está sendo realizada e pode
            demorar alguns segundos...
          </span>
        </div>
      </Modal.Content>
    </Modal>
  )
}

ModalStatusCheckCPF.propTypes = {
  loadingCheckCPF: t.bool,
}

export { ModalStatusCheckCPF }
