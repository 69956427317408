import React, { useState } from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Button, Checkbox, Modal, Tab, Table } from 'semantic-ui-react'

function ChecksList({
  open,
  onClose,
  checkInCheckOutData,
  handleOnTabChange,
  onClickConfig,
  needCreate,
}) {
  const [mucicoIds, setMucicoIds] = useState([])
  const [muIds, setMuIds] = useState([])

  function handleOnClickCheckBox(checked, type, data) {
    if (checked) {
      if (type === 'update') {
        setMucicoIds(prevState => [...prevState, data.uid])
      }
      if (type === 'create') {
        setMuIds(prevState => [...prevState, data.uid])
      }
    } else {
      setMucicoIds(prevState => prevState.filter(prev => prev !== data.uid))
      setMuIds(prevState => prevState.filter(prev => prev !== data.uid))
    }
  }

  const handleOnClick = () => {
    onClickConfig({ mucicoIds, muIds })

    setMucicoIds([])
    setMuIds([])

    setTimeout(() => {
      onClose()
    }, 500)
  }

  const renderItem = () => (
    <Tab.Pane>
      <Table compact celled definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell content="Nome" />
            <Table.HeaderCell content="Missão" />
            <Table.HeaderCell content="Data" />
            <Table.HeaderCell content="Hora" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {needCreate.map(createData => (
            <Table.Row>
              <Table.Cell>
                <Checkbox
                  onClick={(e, { checked }) =>
                    handleOnClickCheckBox(checked, 'create', createData)
                  }
                />
              </Table.Cell>
              <Table.Cell>{createData.user_name}</Table.Cell>
              <Table.Cell>{createData.mission_name}</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell textAlign="center">-</Table.Cell>
            </Table.Row>
          ))}

          {checkInCheckOutData.map(data => (
            <Table.Row key={data.uid}>
              <Table.Cell>
                <Checkbox
                  onClick={(e, { checked }) =>
                    handleOnClickCheckBox(checked, 'update', data)
                  }
                />
              </Table.Cell>
              <Table.Cell>{data.user_name}</Table.Cell>
              <Table.Cell>{data.mission_name}</Table.Cell>

              <Table.Cell>
                {dayjs(data.created_at).format('DD/MM/YYYY')}
              </Table.Cell>
              <Table.Cell>{dayjs(data.created_at).format('HH:mm')}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell colSpan="5">
              <Button
                color="blue"
                disabled={!mucicoIds.length > 0 && !muIds.length > 0}
                content="Configurar Data e Horário"
                onClick={handleOnClick}
              />
              <Button content="Sair" onClick={onClose} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Tab.Pane>
  )

  const panes = [
    {
      menuItem: { key: 'in', content: 'Check IN', icon: 'sign-in' },
      render: () => renderItem(),
    },
    {
      menuItem: { key: 'out', content: 'Check OUT', icon: 'sign-out' },
      render: () => renderItem(),
    },
  ]

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="tiny"
      onMount={() => handleOnTabChange(0)}
    >
      <Modal.Header>Lista de Check</Modal.Header>
      <Modal.Content>
        <Tab
          defaultActiveIndex={0}
          panes={panes}
          menu={{ secondary: true, pointing: true }}
          onTabChange={(e, { activeIndex }) => handleOnTabChange(activeIndex)}
        />
      </Modal.Content>
    </Modal>
  )
}

ChecksList.propTypes = {
  open: t.bool,
  onClose: t.func,
  checkInCheckOutData: t.arrayOf(t.objectOf(t.any)),
  handleOnTabChange: t.func,
  onClickConfig: t.func,
  needCreate: t.arrayOf(t.objectOf(t.any)),
}

export default ChecksList
