import { useState, useEffect, useCallback } from 'react'

import _ from 'lodash'

import api from '~/services/api'
import message from '~/utils/messages'

export default function useUfsAndCitiesFilters(filters, onChangeFilters) {
  const [ufsOptions, setUfsOptions] = useState([])
  const [citiesOptions, setCitiesOptions] = useState([])

  const getCitiesByUfs = async selectedUfsQuery => {
    try {
      const { data: res } = await api.get('geocode/admin/search', {
        params: { ufs: selectedUfsQuery },
        validateStatus() {
          return true
        },
      })

      const newArray = res.data.map(item => {
        const [city, uf] = item.split(' - ')

        return {
          key: item,
          text: city,
          value: {
            cityName: city,
            uf,
          },
        }
      })

      setCitiesOptions(newArray)
    } catch (error) {
      message().error(error.message)
    }
  }

  const onChangeUFs = selectedUfs => {
    if (!selectedUfs.length) {
      return onChangeFilters({ ufs: [], cities: [] })
    }

    if (filters.cities && selectedUfs.length < filters.ufs?.length) {
      const cities = filters.cities.filter(cityObj =>
        selectedUfs.includes(cityObj.uf)
      )

      return onChangeFilters({ ufs: selectedUfs, cities })
    }

    return onChangeFilters({ ufs: selectedUfs })
  }

  const onChangeCities = selectedCitiesObjs => {
    if (!selectedCitiesObjs.length) {
      return onChangeFilters({ cities: [] })
    }

    return onChangeFilters({ cities: selectedCitiesObjs })
  }

  const getUfs = useCallback(async () => {
    try {
      const { data: res } = await api.get('geocode/admin/search')

      const newArray = res.data.map(state => ({
        key: state.name,
        text: `${state.name} (${state.uf})`,
        value: state.uf,
      }))

      return setUfsOptions(newArray)
    } catch (error) {
      return message().error(
        error.response.data.data || 'Houve um erro no servidor'
      )
    }
  }, [])

  useEffect(() => {
    getUfs()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (ufsOptions.length && filters.ufs) {
      const selectedUfsIds = ufsOptions
        .filter(uf => filters.ufs.includes(uf.value))
        .map(uf => uf.value)

      if (selectedUfsIds.length > 0) {
        const selectedUfsQuery = selectedUfsIds.join('|')

        getCitiesByUfs(selectedUfsQuery)
      }
    }
  }, [filters.ufs, ufsOptions, onChangeFilters])

  useEffect(() => {
    if (
      citiesOptions.length &&
      filters.cities &&
      _.isString(filters.cities[0])
    ) {
      const citiesObjs = filters.cities.map(city => {
        const cityObj = citiesOptions.find(cObj => cObj.value.cityName === city)
        return cityObj.value
      })
      onChangeFilters({ cities: citiesObjs })
    }
  }, [filters.cities, citiesOptions, onChangeFilters])

  return {
    ufsOptions,
    citiesOptions,
    onChangeUFs,
    onChangeCities,
  }
}
