import React, { useRef, useState } from 'react'

import { Button, Form, Icon, Modal } from 'semantic-ui-react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function ImportStore() {
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const inputRef = useRef(null)

  const onConfirm = () => setOpen(false)

  async function handleChangeFile(e) {
    const file = e.target.files?.[0]
    const formData = new FormData()
    formData.append('file', file)
    setData(formData)
    try {
      setLoading(true)
    } catch (error) {
      Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function onSubmit() {
    try {
      setLoading(true)

      const res = await api.post(`workforce-management-store/import`, data)

      if (!res?.ok) {
        throw new Error('Houve um erro no envio do arquivo')
      }

      const response = await res.json()
      console.log(response)

      onConfirm()
      Message().success('Arquivo importado com sucesso!')
      setData(null)
      if (inputRef?.current?.value) {
        inputRef.current.value = null
      }
    } catch (error) {
      Message().error(error.message || error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Form.Button
        color="blue"
        icon
        labelPosition="right"
        onClick={() => setOpen(true)}
      >
        <Icon name="upload" />
        Importação
      </Form.Button>
      <Modal size="small" open={open} onClose={() => setOpen(true)}>
        <Modal.Header>Vincular subcategoria</Modal.Header>
        <Modal.Content>
          <div>
            <p>Faça upload do arquivo de importação antes de continuar.</p>
            <div
              style={{
                border: 'solid 1px #ddd',
                borderRadius: 10,
                padding: 20,
              }}
            >
              <input
                hidden
                type="file"
                ref={inputRef}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleChangeFile}
              />

              <span>Arquivo .XLSX</span>
              <div>
                <Button
                  variant="outline"
                  style={{ marginRight: 10 }}
                  disabled={isLoading}
                  onClick={() => inputRef?.current?.click()}
                >
                  {data ? (
                    <>
                      <Icon name="check" size={16} style={{ marginRight: 5 }} />
                      <span>Arquivo selecionado</span>
                    </>
                  ) : (
                    <>
                      <Icon
                        name="upload"
                        size={20}
                        style={{ marginRight: 5 }}
                      />
                      <span>Selecione um arquivo</span>
                    </>
                  )}
                </Button>
                {data ? (
                  <Button
                    size="icon"
                    variant={null}
                    disabled={isLoading}
                    onClick={() => {
                      setData(null)
                      if (inputRef?.current?.value) {
                        inputRef.current.value = null
                      }
                    }}
                  >
                    <Icon name="close" size={20} />
                  </Button>
                ) : null}
              </div>
            </div>
            <div
              style={{ display: 'flex', paddingTop: 20, justifyContent: 'end' }}
            >
              <Button
                onClick={onConfirm}
                variant="outline"
                style={{ marginRight: 10 }}
              >
                Fechar
              </Button>
              <Button
                disabled={!data || isLoading}
                type="primary"
                onClick={onSubmit}
              >
                {isLoading ? (
                  <>
                    <Icon name="spinner" className="spin" />
                    <span>Carregando</span>
                  </>
                ) : (
                  <span>Importar</span>
                )}
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  )
}
