import React from 'react'

import { Grid, Button, Input, Icon } from 'semantic-ui-react'

export default function FormsItemsDetail({
  value,
  state,
  index,
  removeItem,
  handlerInput,
  handleChangeFormMultiple,
}) {
  return (
    <Grid columns={3} key={`key-${index}`}>
      <Grid.Column width={12}>
        <Input
          fluid
          type="text"
          key={`idx-${index}`}
          onChange={handlerInput}
          placeholder={value.answer}
          id={`answer${value.answer_id}_${value.question_id}`}
          value={
            state[`answer${value.answer_id}_${value.question_id}`] ||
            value.answer
          }
        />
      </Grid.Column>
      <Grid.Column width={3}>
        <Button
          positive={value.is_correct}
          negative={!value.is_correct}
          onClick={() => handleChangeFormMultiple(value)}
        >
          Correto
        </Button>
      </Grid.Column>
      <Grid.Column width={1}>
        <Button negative icon onClick={() => removeItem(value)}>
          <Icon name="trash" />
        </Button>
      </Grid.Column>
    </Grid>
  )
}
