import React, { useRef } from 'react'

import t from 'prop-types'
import { Card, Button } from 'semantic-ui-react'

function SubMenu({
  selectedForm,
  loadingFormsItems,
  handleClickButton,
  onChangeImport,
  handleClickExportFormsItemsTemplate,
}) {
  const importFormsItemsRef = useRef()

  return (
    <Card fluid>
      <Card.Content>
        <Button
          disabled={!selectedForm}
          loading={loadingFormsItems}
          size="large"
          icon="add"
          content="Novo Item"
          labelPosition="left"
          color="green"
          onClick={() => handleClickButton('create-item')}
        />

        <input
          type="file"
          ref={importFormsItemsRef}
          id="importFormsItemsRef"
          onChange={onChangeImport}
          style={{ display: 'none' }}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <Button
          disabled={!selectedForm}
          loading={loadingFormsItems}
          size="large"
          icon="upload"
          content="Importar Items"
          labelPosition="left"
          color="blue"
          onClick={() => importFormsItemsRef.current.click()}
        />

        <Button
          content="Exportar Template"
          size="large"
          color="orange"
          disabled={!selectedForm}
          loading={loadingFormsItems}
          icon="download"
          labelPosition="left"
          onClick={handleClickExportFormsItemsTemplate}
        />
      </Card.Content>
    </Card>
  )
}

SubMenu.propTypes = {
  selectedForm: t.string,
  loadingFormsItems: t.bool,
  handleClickButton: t.func,
  onChangeImport: t.func,
  handleClickExportFormsItemsTemplate: t.func,
}

export default SubMenu
