import React from 'react'

import t from 'prop-types'
import { Modal, Button } from 'semantic-ui-react'

import SearchFormsItems from '../SearchFormsItems'

const CreateFormItem = ({
  secondModal,
  formsItems,
  setQuestion,
  setSecondModal,
  loadingQuestion,
  onSubmitQuestion,
}) => (
  <Modal onClose={() => setSecondModal(false)} open={secondModal} size="small">
    <Modal.Header>Adicione uma pergunta</Modal.Header>
    <Modal.Content>
      <SearchFormsItems
        onChange={setQuestion}
        formId={formsItems[0]?.items[0].forms_id}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button
        icon="check"
        color="green"
        content="Confirmar"
        loading={loadingQuestion}
        onClick={() => {
          setSecondModal(false)
          return onSubmitQuestion()
        }}
      />
    </Modal.Actions>
  </Modal>
)

CreateFormItem.propTypes = {
  secondModal: t.bool,
  onSubmitQuestion: t.func,
  formsItems: t.objectOf(t.any),
  loadingQuestion: t.bool,
  setSecondModal: t.func,
  setQuestion: t.func,
}

export default CreateFormItem
