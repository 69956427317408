import React, { useEffect, useState } from 'react'

import { Checkbox, Header, Confirm as ConfirmUI } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useBadges from '~/hooks/useBadges'
import useCustomers from '~/hooks/useCustomers'

import Answers from './components/Answers'
import Confirm from './components/Confirm'
import Filter from './components/Filter'
import Item from './components/Item'
import Options from './components/Options'

function BadgeRequests() {
  const {
    getExclusives,
    exclusiveBadges,
    countExclusiveBadges,
    changeStatus,
    getAllBadges,
    badgesOptions,
    updateRequestsInBatch,
    exportReport,
  } = useBadges()

  const { getCustomers, listNameCustomers } = useCustomers()

  const initialPagination = {
    page: 1,
    offset: 0,
    activePage: 1,
  }

  const [pagination, setPagination] = useState(initialPagination)

  const initalFilter = {
    status: 'PENDENT',
    username: '',
    badgeId: null,
    customerId: null,
    itemsPerPage: 15,
  }

  const [filters, setFilters] = useState(initalFilter)

  const [confirmModal, setConfirmModal] = useState({
    open: false,
    item: {},
  })

  const [anserwsModal, setAnswersModal] = useState({
    open: false,
    item: {},
  })

  const [selectedMission, setSelectedMission] = useState([])
  const [checkedAll, setCheckedAll] = useState(false)
  const [confirmUpdateInBatch, setConfirmUpdateInBatch] = useState({
    open: false,
    type: '',
  })

  const labels = {
    approve: 'Aprovar',
    reprove: 'Reprovar',
    export: 'Exportar',
  }

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * filters.itemsPerPage

    setPagination(prevState => ({
      ...prevState,
      activePage,
      page: activePage,
      offset,
      limit: filters.itemsPerPage,
    }))

    setCheckedAll(false)
    setSelectedMission([])

    return getExclusives({
      page: activePage,
      status: filters.status,
      itemsPerPage: filters.itemsPerPage,
    })
  }

  function handleOnClickActions(action, item) {
    if (action === 'answers') {
      return setAnswersModal(prevState => ({
        ...prevState,
        open: true,
        item,
      }))
    }

    return setConfirmModal(prevState => ({
      ...prevState,
      open: true,
      action,
      item,
    }))
  }

  function handleOnClickConfirm(userId, action) {
    if (action === 'approve') {
      changeStatus(userId, 'APPROVED')
    }

    if (action === 'reprove') {
      changeStatus(userId, 'REPROVED')
    }

    if (action !== 'answers') {
      getExclusives({
        ...filters,
        ...pagination,
      })
    }

    setTimeout(() => {
      setConfirmModal(prevState => ({
        ...prevState,
        open: false,
      }))
    }, 300)
  }

  function handleOnChangeFilter(key, value) {
    setFilters(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  function handleOnClickSubmit() {
    getExclusives({
      ...filters,
      page: 1,
    })

    setPagination(initialPagination)
  }

  function handleOnClickClear() {
    setFilters(initalFilter)
    setPagination(initialPagination)
    setSelectedMission([])

    getExclusives({
      page: 1,
      itemsPerPage: filters.itemsPerPage,
      status: 'PENDENT',
    })
  }

  function handleOnChangeSelectAllMissions() {
    setCheckedAll(prevState => !prevState)

    if (!checkedAll) {
      return setSelectedMission(exclusiveBadges)
    }

    return setSelectedMission([])
  }

  function handleOnClickOptions(typeOption) {
    setConfirmUpdateInBatch(prevState => ({
      ...prevState,
      open: true,
      type: typeOption,
    }))
  }

  function handleOnConfirmUpdateInBatch() {
    const badgeRequestsIds = selectedMission.map(request => request.id)

    if (confirmUpdateInBatch.type === 'approve') {
      updateRequestsInBatch({
        status: 'APPROVED',
        badgeRequestsIds,
      })
    }

    if (confirmUpdateInBatch.type === 'reprove') {
      updateRequestsInBatch({
        status: 'REPROVED',
        badgeRequestsIds,
      })
    }

    if (confirmUpdateInBatch.type === 'export') {
      setConfirmUpdateInBatch({ open: false })

      return exportReport({ badgeRequestsIds })
    }

    return setTimeout(() => {
      setCheckedAll(false)
      setSelectedMission([])
      setConfirmUpdateInBatch({ open: false })
      setFilters(initalFilter)

      getExclusives({
        page: pagination.activePage,
        itemsPerPage: filters.itemsPerPage,
        status: 'PENDENT',
      })
    }, 500)
  }

  function handleOnClickCheckBox(checked, item) {
    if (checked) {
      if (exclusiveBadges.length === selectedMission.length + 1) {
        setCheckedAll(true)
      }

      return setSelectedMission(prevState => [...prevState, item])
    }

    setCheckedAll(false)
    return setSelectedMission(prevState =>
      prevState.filter(prev => prev !== item)
    )
  }

  useEffect(() => {
    getExclusives({
      page: filters,
      itemsPerPage: filters.itemsPerPage,
      status: 'PENDENT',
    })
  }, []) // eslint-disable-line

  useEffect(() => {
    getCustomers({ limit: 10000 })
  }, []) // eslint-disable-line

  useEffect(() => {
    getAllBadges()
  }, []) // eslint-disable-line

  return (
    <>
      <Header>Solicitações de Certificado</Header>
      <Filter
        filters={filters}
        onChangeFilter={handleOnChangeFilter}
        onClickSubmit={handleOnClickSubmit}
        onClickClear={handleOnClickClear}
        listNameCustomers={listNameCustomers}
        badgesOptions={badgesOptions}
      />

      <Options
        disabled={selectedMission.length === 0}
        onClick={handleOnClickOptions}
      />

      <TableComponent
        columns={[
          <Checkbox
            onChange={handleOnChangeSelectAllMissions}
            checked={checkedAll}
          />,
          'Nome',
          'Telefone',
          'Email',
          'Certificado',
          'Criado Em',
          'Atualizado Em',
          'Ações',
        ]}
        data={exclusiveBadges}
        renderItem={item => (
          <Item
            item={item}
            onClickActions={handleOnClickActions}
            isChecked={selectedMission.includes(item)}
            onClickCheckBox={handleOnClickCheckBox}
          />
        )}
        activePage={pagination.activePage}
        limit={filters.itemsPerPage}
        offset={pagination.offset}
        count={countExclusiveBadges}
        totalCount={countExclusiveBadges}
        showFirstAndLastNav
        showPreviousAndNextNav
        handlePaginationChange={handlePaginationChange}
        emptyText={{
          icon: 'shield',
          text: 'Nenhuma Solicitação Encontrada ',
        }}
      />

      <Confirm
        data={confirmModal}
        action="reprove"
        onClose={() =>
          setConfirmModal(prevState => ({ ...prevState, open: false }))
        }
        onClickConfirm={handleOnClickConfirm}
      />

      <Answers
        data={anserwsModal}
        onClickCancel={() =>
          setAnswersModal(prevState => ({ ...prevState, open: false }))
        }
      />

      <ConfirmUI
        open={confirmUpdateInBatch.open}
        size="mini"
        header={`${labels[confirmUpdateInBatch.type]} em Lote`}
        content={`Você está prestes a ${labels[confirmUpdateInBatch.type]} ${
          selectedMission.length
        } solicitações. Tem certeza?`}
        onConfirm={handleOnConfirmUpdateInBatch}
        onCancel={() => setConfirmUpdateInBatch({ open: false })}
        cancelButton="Cancelar"
        confirmButton="Confirmar"
      />
    </>
  )
}

export default BadgeRequests
