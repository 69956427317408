import React from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { isNil } from 'ramda'
import { Table, Button, Label, Icon } from 'semantic-ui-react'

export function Item({
  item,
  openModalDetail,
  removeItem,
  updateStatus,
  openModalForms,
  openModalAttachments,
  openModalParticipants,
  openModalSharedLinkGenerator,
  onChangeDataToGenerateLinks,
}) {
  return (
    <Table.Row key={item.uid} negative={!item.status}>
      <Table.Cell>
        {item.participants === parseInt(item.has_finished, 2) && (
          <Label color="teal" size="small" ribbon>
            Finalizado
          </Label>
        )}
        {parseInt(item.has_started, 0) > 0 && (
          <Label color="blue" ribbon size="small">
            Iniciada
          </Label>
        )}
        {item.mission_key}
      </Table.Cell>
      <Table.Cell>{item.identifier}</Table.Cell>
      <Table.Cell>{item.customers_name}</Table.Cell>
      <Table.Cell>{item.title}</Table.Cell>
      <Table.Cell>
        {dayjs(item.created_at).format('YYYY-MM-DD') ===
          dayjs().format('YYYY-MM-DD') && (
          <Label color="red" size="small">
            Novo
          </Label>
        )}{' '}
        {item.name}
      </Table.Cell>
      <Table.Cell>{isNil(item.username) ? 'MASTER' : item.username}</Table.Cell>
      <Table.Cell>
        {dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>
        {item.activated_at &&
          dayjs(item.activated_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>{item.times_reactivated}</Table.Cell>
      <Table.Cell>
        {dayjs(item.expired_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>
        {item.status ? (
          <Button negative onClick={updateStatus}>
            Inativar
          </Button>
        ) : (
          <Button positive onClick={updateStatus}>
            Ativar
          </Button>
        )}
      </Table.Cell>
      <Table.Cell>
        <Button.Group basic size="small">
          <Button icon onClick={openModalDetail}>
            <Icon name="edit" />
          </Button>
          {item.participants > 0 && (
            <Button icon onClick={openModalParticipants}>
              <Icon name="users" />
            </Button>
          )}
          {item.has_attachment && (
            <Button icon onClick={openModalAttachments}>
              <Icon name="file pdf outline" />
            </Button>
          )}
          {item.has_forms && (
            <Button icon onClick={openModalForms}>
              <Icon name="file text" />
            </Button>
          )}
          {parseInt(item.has_started, 0) === 0 &&
            parseInt(item.has_finished, 0) === 0 && (
              <Button icon onClick={removeItem}>
                <Icon name="trash" />
              </Button>
            )}

          {item.has_shared_link && (
            <Button
              icon
              onClick={() => {
                onChangeDataToGenerateLinks(
                  'missionMainId',
                  item.missions_main_id
                )
                openModalSharedLinkGenerator()
              }}
            >
              <Icon name="linkify" />
            </Button>
          )}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  item: t.objectOf(t.any),
  openModalDetail: t.func,
  removeItem: t.func,
  updateStatus: t.func,
  openModalForms: t.func,
  openModalAttachments: t.func,
  openModalParticipants: t.func,
  openModalSharedLinkGenerator: t.func,
  onChangeDataToGenerateLinks: t.func,
}
