import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useMissionsMain() {
  const [missionsMain, setMissionsMain] = useState(null)

  async function getMissionsMain(params) {
    try {
      const res = await api.get('missions-main/admin', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setMissionsMain(res.data)
    } catch (error) {
      return Message().error(error.message)
    }
  }

  return {
    getMissionsMain,
    missionsMain,
  }
}
