import React, { useState } from 'react'

import t from 'prop-types'
import {
  Button,
  Checkbox,
  Divider,
  Input,
  List,
  Modal,
} from 'semantic-ui-react'

import { DynamicHeaderOptions } from '../DynamicHeaderOptions'

export function CreateWebhook({
  open,
  onClose,
  webhooksEvents,
  customerId,
  createWebhook,
  loadingWebhook,
}) {
  const [values, setValues] = useState({})
  const [events, setEvents] = useState([])
  const [headers, setHeaders] = useState([])

  const addHeader = () =>
    setHeaders(prevState => [...prevState, { header: 'value' }])

  function handleOnChangeHeaders(key, value, index) {
    if (key === 'header') {
      setHeaders(prevState =>
        prevState.map((prev, idx) => {
          const [, valueHeader] = Object.keys(prev)

          return idx === index ? { [value]: valueHeader } : prev
        })
      )
    } else {
      setHeaders(prevState =>
        prevState.map((prev, idx) => {
          const [header] = Object.keys(prev)
          return idx === index ? { [header]: value } : prev
        })
      )
    }
  }

  function handleOnChange(key, value, checked) {
    if (key === 'events') {
      if (checked) {
        return setEvents(prevState => [...prevState, value])
      }
      return setEvents(prevState => prevState.filter(prev => prev !== value))
    }

    return setValues(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  function handleOnClickCreate() {
    const headersObject = {}

    headers.forEach(item => {
      const shouldContinue = !Object.keys(item).includes('header')

      if (shouldContinue) {
        Object.assign(headersObject, item)
      }
    })

    const createObject = {
      authorization: headersObject,
      customerId,
      callback_url: values.callbackURL,
      specific_topic: values.specificTopic,
      events,
    }

    createWebhook(createObject)

    onClose()
    setValues({})
    setEvents([])
    setHeaders([])
  }

  return (
    <Modal open={open} onClose={onClose} size="small">
      <Modal.Header>Novo Webhook</Modal.Header>
      <Modal.Content scrolling>
        <Input
          label="Endpoint URL"
          placeholder="https://"
          fluid
          onChange={(_, { value }) => handleOnChange('callbackURL', value)}
          disabled={loadingWebhook}
        />

        <Input
          placeholder="Tópico Específico"
          fluid
          onChange={(_, { value }) => handleOnChange('specificTopic', value)}
          disabled={loadingWebhook}
          style={{ marginTop: 10 }}
        />
        <Divider />
        <DynamicHeaderOptions
          loadingWebhook={loadingWebhook}
          headers={headers}
          addHeader={addHeader}
          handleOnChange={handleOnChangeHeaders}
        />

        <Divider />
        {webhooksEvents?.length > 0 &&
          webhooksEvents.map(event => (
            <List.Item>
              <List.Content>
                <Checkbox
                  toggle
                  id={event.id}
                  value={event.id}
                  label={event.event_name}
                  onChange={(_, { value, checked }) =>
                    handleOnChange('events', value, checked)
                  }
                  disabled={loadingWebhook}
                />
              </List.Content>
            </List.Item>
          ))}
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cancelar"
          onClick={onClose}
          disabled={loadingWebhook}
        />
        <Button
          content="Criar"
          primary
          onClick={handleOnClickCreate}
          disabled={loadingWebhook}
          loading={loadingWebhook}
        />
      </Modal.Actions>
    </Modal>
  )
}

CreateWebhook.propTypes = {
  createWebhook: t.func,
  loadingWebhook: t.bool,
  open: t.bool,
  onClose: t.func,
  webhooksEvents: t.arrayOf(t.any),
  customerId: t.string,
}
