import React, { useState, useEffect, useCallback, useRef } from 'react'
import { ExcelRenderer } from 'react-excel-renderer'

import { Card, Button, Grid, Dropdown, Header } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import TableComponent from '~/components/Table'
import useCustomers from '~/hooks/useCustomers'
import Main from '~/pages/Main'
import api from '~/services/api'
import { IMPORT_PRODUCTS_PDV, CUSTOMERS_GROUPS } from '~/services/api/endpoints'
import messages from '~/utils/messages'

import Customers from './components/Customers'
import Item from './components/Item'

function Imports(props) {
  const uploadRef = useRef('uploadRef')
  const uploadImageRef = useRef('uploadImageRef')

  const [product, setProduct] = useState({})
  const [products, setProducts] = useState([])
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingItem, setLoadingItem] = useState('')
  const [customerId, setCustomerId] = useState(null)
  const [customersGroupsId, setCustomersGroupsId] = useState(null)
  const { getCustomers, customers } = useCustomers()

  const findByCustomerId = useCallback(async (id, groupId) => {
    setLoading(true)
    const res = await api.get(IMPORT_PRODUCTS_PDV, {
      params: {
        customerId: id,
        customersGroupsId: groupId,
      },
      verifyStatus() {
        return true
      },
    })

    if (res.status === 200) {
      setLoading(false)
      setProducts(res.data)
    }
  }, [])

  const findGroupByCustomerId = useCallback(async id => {
    setLoading(true)
    const res = await api.get(CUSTOMERS_GROUPS, {
      params: {
        customerId: id,
      },
      verifyStatus() {
        return true
      },
    })

    if (res.status === 500) {
      setLoading(false)
    }

    setLoading(false)
    return setGroups(
      res.data.map(({ uid, description }, key) => ({
        key,
        value: uid,
        text: description.toUpperCase(),
      }))
    )
  }, [])

  const handleMap = item => ({
    key: item.uid,
    value: item.uid,
    text: item.name.toUpperCase(),
  })

  function onChange(e, { value }) {
    findGroupByCustomerId(value)

    return setCustomerId(value)
  }

  function handleChangeGroup(e, { value }) {
    setCustomersGroupsId(value)

    return findByCustomerId(customerId, value)
  }

  function uploadFile(e) {
    return submit(e.target.files[0])
  }

  async function submit(fileInput) {
    if (!customerId) {
      return messages().error('Cliente esta vazio!')
    }

    if (!customersGroupsId) {
      return messages().error('Vincule os produtos a um grupo!')
    }

    const spreadsheet = await ExcelRenderer(fileInput, (err, resp) => {
      if (err) {
        return messages().error(err)
      }

      return resp
    })

    if (spreadsheet.rows.length > 4001) {
      return messages().error('Arquivo não pode ter mais de 4000 linhas')
    }

    setLoading(true)

    const newData = new FormData()
    newData.set('customerId', customerId)
    newData.set('customersGroupsId', customersGroupsId)
    newData.set('totalLines', spreadsheet.rows.length)
    newData.append('file', fileInput)

    const res = await api.post(IMPORT_PRODUCTS_PDV, newData, {
      validateStatus() {
        return true
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    if (res.status === 500) {
      setLoading(false)
      return messages().error(res.data.message)
    }

    setLoading(false)
    return messages().success(
      'Dentro de instantes todos os seus produtos serão importados.'
    )
  }

  function uploadImage(e) {
    handleUpdate({ item: product, file: e.target.files[0] })

    e.target.value = null
  }

  function handleProductSelected(item) {
    setProduct(oldValue => ({ ...oldValue, ...item }))
    return uploadImageRef.current.click()
  }

  async function handleUpdate({ item, file }) {
    setLoadingItem(item.uid)

    const newData = new FormData()
    newData.set('customerId', customerId)
    newData.set('description', item.description)
    newData.set('productId', item.uid)
    newData.set('status', item.status)
    newData.append('file', file)

    const res = await api.put(IMPORT_PRODUCTS_PDV, newData, {
      validateStatus() {
        return true
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    if (res.status !== 200) {
      setLoadingItem('')
      return messages().error(res.data.message)
    }

    const index = products.findIndex(i => i.uid === item.uid)

    products[index] = res.data

    setProducts(products)
    setLoadingItem('')
    return messages().success('Item atualizado com sucesso!')
  }

  useEffect(() => {
    getCustomers({ limit: 1000, offset: 0 })
  }, []) //eslint-disable-line

  useEffect(() => {
    setProducts(products)
  }, [products])

  return (
    <Main {...props}>
      <Header>Importar Produtos (PDV)</Header>
      <Card fluid>
        <Card.Content>
          <Grid columns={2}>
            <Grid.Column>
              <Customers
                loading={loading}
                onChange={onChange}
                values={customers.map(handleMap)}
              />
            </Grid.Column>
            {customerId && (
              <Grid.Column>
                <Dropdown
                  fluid
                  search
                  selection
                  options={groups}
                  loading={loading}
                  onChange={handleChangeGroup}
                  placeholder="Escolha um grupo..."
                />
              </Grid.Column>
            )}
            <Grid.Column>
              <input
                type="file"
                ref={uploadRef}
                id="upload"
                onChange={uploadFile}
                style={{ display: 'none' }}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <Button
                icon="upload"
                content="Upload"
                labelPosition="right"
                onClick={() => uploadRef.current.click()}
              />
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>

      {customerId && (
        <TableComponent
          data={products}
          isLoading={loading}
          renderItem={(item, index) => (
            <Item
              item={item}
              key={String(index)}
              myRef={uploadImageRef}
              uploadImage={uploadImage}
              loading={loadingItem === item.uid}
              onClick={() => handleProductSelected(item)}
            />
          )}
          columns={[
            'IMAGEM',
            'ID CATEGORIA PAI',
            'ID CATEGORIA FILHO',
            'DESCRIÇÃO',
            'AÇÕES',
          ]}
          emptyText={{ icon: 'box', text: 'Nenhum produto foi importado...' }}
        />
      )}

      {customerId === null && (
        <EmptyList text="Escolha um cliente" icon="users" />
      )}
    </Main>
  )
}

export default Imports
