import React from 'react'

import t from 'prop-types'
import { Modal, Table, Image, Button, Header } from 'semantic-ui-react'

import { formatPrice } from '~/utils/types'

import { DELIVERY_STATUS } from '../helpers/types'

function OrderDetailModal({
  setShowOrderDetails,
  showOrderDetails,
  setOrderSelected,
  orderSelected,
}) {
  const CURRENCY_TYPES = {
    money: 'Dinheiro',
    points: 'Pontos',
  }

  function closeModal() {
    setOrderSelected(null)
    setShowOrderDetails(false)
  }

  function renderOrderAddress(order) {
    if (!order || (order && !order.deliveryAddress)) {
      return false
    }

    return (
      <>
        <Header>Dados de endereço:</Header>

        <ul>
          <li>
            Cep: <strong>{order.deliveryAddress.zipcode}</strong>
          </li>
          <li>
            Endereços: <strong>{order.deliveryAddress.address}</strong>
          </li>
          <li>
            Número: <strong>{order.deliveryAddress.number}</strong>
          </li>
          <li>
            Complemento:{' '}
            <strong>
              {order.deliveryAddress.complement || 'Não foi encontrado'}
            </strong>
          </li>
          <li>
            Bairro: <strong>{order.deliveryAddress.neighborhood}</strong>
          </li>
          <li>
            Estado: <strong>{order.deliveryAddress.state}</strong>
          </li>
          <li>
            Cidade: <strong>{order.deliveryAddress.city}</strong>
          </li>
        </ul>
      </>
    )
  }

  function renderOrderProducts(order) {
    if (!order) {
      return false
    }

    return orderSelected.products.map((item, index) => {
      return (
        <Table.Row key={String(index)} className="center aligned">
          <Table.Cell>
            <Image width="50px" height="50px" src={item.image_url} />
          </Table.Cell>
          <Table.Cell>{item.name}</Table.Cell>
          <Table.Cell>{item.quantity}</Table.Cell>
          <Table.Cell>
            {item.currency_type === 'money'
              ? formatPrice(item.value)
              : parseInt(item.value, 10)}
          </Table.Cell>
          <Table.Cell>{CURRENCY_TYPES[item.currency_type]}</Table.Cell>
          <Table.Cell>{item.has_delivery ? 'Físico' : 'Digital'}</Table.Cell>
        </Table.Row>
      )
    })
  }

  function hasReportedProblemToSend() {
    return (
      orderSelected != null &&
      orderSelected.deliveryStatus === DELIVERY_STATUS.PROBLEM_TO_SEND &&
      orderSelected.disclaimer &&
      orderSelected.disclaimer.length > 0
    )
  }

  return (
    <Modal
      onClose={() => setShowOrderDetails(false)}
      onOpen={() => setShowOrderDetails(true)}
      open={showOrderDetails}
    >
      <Modal.Header>Detalhes do pedido</Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          {hasReportedProblemToSend() && (
            <>
              <Header>Problema de envio reportado:</Header>
              <p>{orderSelected.disclaimer}</p>
            </>
          )}


          {renderOrderAddress(orderSelected)}

          <Header>Produtos</Header>
          <div>
            <Table>
              <Table.Header className="center aligned">
                <Table.Cell />
                <Table.Cell>Nome</Table.Cell>
                <Table.Cell>Quantidade</Table.Cell>
                <Table.Cell>Valor por unidade</Table.Cell>
                <Table.Cell>Tipo de moeda</Table.Cell>
                <Table.Cell>Físico ou Digital</Table.Cell>
              </Table.Header>
              {renderOrderProducts(orderSelected)}
            </Table>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => closeModal(false)}>Fechar</Button>
      </Modal.Actions>
    </Modal>
  )
}

OrderDetailModal.propTypes = {
  setShowOrderDetails: t.func,
  showOrderDetails: t.bool,
  setOrderSelected: t.func,
  orderSelected: t.objectOf(t.any),
}

export default OrderDetailModal
