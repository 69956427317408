import React from 'react'

import t from 'prop-types'
import { Button, Grid, Input } from 'semantic-ui-react'

export function DynamicHeaderOptions({
  headers,
  addHeader,
  handleOnChange,
  loadingWebhook,
}) {
  return (
    <Grid columns={2}>
      {headers.map((item, idx) => {
        const headerKeys = Object.keys(item)

        return headerKeys.map(itemHeader => (
          <Grid.Row stretched>
            <Grid.Column>
              <Input
                placeholder="header"
                value={itemHeader}
                onChange={(_, { value }) =>
                  handleOnChange('header', value, idx)
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                placeholder="value"
                value={item[itemHeader]}
                onChange={(_, { value }) => handleOnChange('value', value, idx)}
              />
            </Grid.Column>
          </Grid.Row>
        ))
      })}

      <Grid.Row>
        <Grid.Column>
          <Button
            primary
            content="Adicionar Novo Header"
            onClick={addHeader}
            disabled={loadingWebhook}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

DynamicHeaderOptions.propTypes = {
  headers: t.arrayOf(t.any),
  addHeader: t.func,
  handleOnChange: t.func,
  loadingWebhook: t.bool,
}
