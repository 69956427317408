import styled from 'styled-components'

export const InitialContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
  background-color: #bfbfbf;
  color: #ffffff;
  width: 35px;
  height: 35px;
  margin-right: 7.8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`
