import React, { memo, useEffect } from 'react'

import t from 'prop-types'
import { Card, Icon, Form, Button, Select } from 'semantic-ui-react'

import useCustomersPaymentConfig from '~/hooks/useCustomersPaymentConfig'

const configOptions = [
  { key: 1, text: 'Pós-pago Normal', value: 1 },
  { key: 2, text: 'Pós-pago Desonerado', value: 2 },
  { key: 3, text: 'Pré-pago Normal', value: 3 },
  { key: 4, text: 'Pré-pago Desonerado', value: 4 },
  { key: 6, text: 'Licenciamento', value: 6 },
]

function Finance({ customerId }) {
  const {
    onChange,
    hasPaymentConfig,
    getCustomersPaymentConfig,
    customerPaymentConfig,
    loadingCustomersPayment,
    onUpdatePaymentConfig,
    onSubmitPaymentConfig,
  } = useCustomersPaymentConfig()

  function onSubmit() {
    return hasPaymentConfig
      ? onUpdatePaymentConfig({
          ...customerPaymentConfig,
          customer_id: customerId,
        })
      : onSubmitPaymentConfig({
          ...customerPaymentConfig,
          customer_id: customerId,
        })
  }

  useEffect(() => {
    getCustomersPaymentConfig({ customerId })
  }, []) //eslint-disable-line

  return (
    <Card centered fluid>
      <Card.Content>
        <Card.Header>Configuração de Pagamento</Card.Header>
      </Card.Content>
      <Card.Content>
        <Form>
          <Form.Field
            id="customerPaymentType"
            control={Select}
            options={configOptions}
            label="Tipo de Pagamento"
            placeholder="Tipo de Pagamento"
            value={customerPaymentConfig?.customer_payment_type || ''}
            onChange={(_, { value }) =>
              onChange('customer_payment_type', value)
            }
          />
          {customerPaymentConfig?.customer_payment_type < 3 && (
            <Form.Input
              id="limit"
              type="number"
              placeholder="Ex: 100"
              value={customerPaymentConfig?.limit || ''}
              label="Limite (Obrigatório)"
              onChange={({ target }) => onChange('limit', target.value)}
            />
          )}

          {customerPaymentConfig?.customer_payment_type === 6 && (
            <Form.Input
              id="schedule_transaction_days"
              type="number"
              placeholder="Ex: 100"
              value={customerPaymentConfig?.schedule_transaction_days}
              label="Total de dias de prorrogação da liberação de pagamento dos usuários(Cliente privado)"
              onChange={({ target }) =>
                onChange('schedule_transaction_days', target.value)
              }
            />
          )}
        </Form>
      </Card.Content>
      <Card.Content extra textAlign="right">
        <Button
          icon
          color="green"
          onClick={onSubmit}
          labelPosition="left"
          loading={loadingCustomersPayment}
          disabled={loadingCustomersPayment}
        >
          <Icon name="check" /> Salvar
        </Button>
      </Card.Content>
    </Card>
  )
}

Finance.propTypes = {
  customerId: t.string,
}

export default memo(Finance)
