import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
`

export const ContainerWrapper = styled.div`
  padding: 10px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`
