import React from 'react'
import GaugeChart from 'react-gauge-chart'

import t from 'prop-types'
import { Card, Grid } from 'semantic-ui-react'

function ThermometerCard({ percentage, label }) {
  return (
    <Grid.Column>
      <Card
        className="center aligned"
        style={{ width: '250px', height: '95px' }}
      >
        <Card.Content>
          <GaugeChart
            colors={['#ff0000', '#ffff00', '#00ff00']}
            textColor="black"
            percent={percentage / 100}
            style={{ width: '150px', heigth: '50px', fontWeight: 'bolder' }}
          />
          <h4 style={{ marginTop: '-10px' }} className="center aligned">
            {label}
          </h4>
        </Card.Content>
      </Card>
    </Grid.Column>
  )
}

ThermometerCard.propTypes = {
  percentage: t.number,
  label: t.string,
}

export default ThermometerCard
