import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import TableComponent from '~/components/Table'
import useTransferRequests from '~/hooks/useTransferRequests'
import Main from '~/pages/Main'

import Item from './components/Item'
import ReproveModal from './components/ReproveModal'

function TransferRequests() {
  const history = useHistory()

  const {
    transferCount,
    loadingTransfer,
    transferRequests,
    getTransferRequests,
    onUpdateTransferRequest,
  } = useTransferRequests()
  const [motivation, setMotivation] = useState(null)
  const [openModal, setOpenModal] = useState({ show: false, item: null })

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 50,
    activePage: 1,
  })

  function onItem(item) {
    return setOpenModal({ show: true, item })
  }

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * pagination.limit

    setPagination(prevState => ({
      ...prevState,
      activePage,
      offset,
      limit: pagination.limit,
    }))

    return getTransferRequests({
      offset,
      limit: pagination.limit,
      direction: 'asc',
    })
  }

  useEffect(() => {
    getTransferRequests({
      offset: pagination.offset,
      limit: pagination.limit,
      direction: 'asc',
    })
  }, []) //eslint-disable-line

  return (
    <Main>
      <h1>Transferências de Usuários</h1>
      <TableComponent
        showFirstAndLastNav
        showPreviousAndNextNav
        showEllipsis
        activePage={pagination.activePage}
        limit={pagination.limit}
        offset={pagination.offset}
        count={transferCount}
        totalCount={transferCount}
        handlePaginationChange={handlePaginationChange}
        columns={['Nome', 'Criado Em', 'Atualizado Em', 'Valor', '']}
        data={transferRequests}
        isLoading={loadingTransfer}
        renderItem={(item, index) => (
          <Item
            item={item}
            onItem={onItem}
            key={`item-${index}`}
            loading={loadingTransfer}
            onUpdate={onUpdateTransferRequest}
            onClickUser={() => history.push(`/user/${item?.user_id}`)}
          />
        )}
      />
      <ReproveModal
        open={openModal.show}
        onChange={value => setMotivation(value)}
        onClose={() => setOpenModal({ show: false, item: null })}
        onClick={() =>
          onUpdateTransferRequest({
            motivation,
            id: openModal.item.id,
            transfer_request_status_id: 3,
          })
        }
      />
    </Main>
  )
}

export default TransferRequests
