import React, { useState } from 'react'

import t from 'prop-types'
import { Form, List, Image, Modal, Button } from 'semantic-ui-react'

import useGeo from '~/hooks/useGeo'
import usePhotos from '~/hooks/usePhotos'
import Message from '~/utils/messages'

const SendImages = ({ id, open, images, onClose, onRemove, onRefresh }) => {
  let delay
  const [place, setPlace] = useState('')
  const [placeValue, setPlaceValue] = useState({
    place: 'Florianopolis',
    lat: 0,
    lng: 0,
  })
  // const [placeValue, setPlaceValue] = useState(null)

  const { onSubmitPhotos, loadingUpload } = usePhotos()
  const { onSearchAddress, loadingAddresses, addresses, setAddresses } =
    useGeo()

  function onChangeAddress(e, { value }) {
    e.preventDefault()
    setPlace(value)
    clearTimeout(delay)
    delay = setTimeout(() => {
      return value.length > 2 && onSearchAddress(value)
    }, 1000)
  }

  async function onSubmitImages() {
    if (images.length === 0) {
      return Message().error('Envie pelo menos uma imagem')
    }

    if (!placeValue) {
      return Message().error('Adicione um endereço')
    }

    await Promise.all(
      images.map(i =>
        onSubmitPhotos({
          id,
          data: {
            src: i.src,
            info: JSON.stringify({ ...placeValue, ...i }),
          },
        })
      )
    )

    await onRefresh()

    return onClose()
  }

  function onClick(location) {
    setPlaceValue(prev => ({ ...prev, ...location }))

    return setAddresses([])
  }

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header content="Enviar Imagens" />
      <Modal.Content scrolling>
        <Form>
          <Form.Input
            type="text"
            icon="search"
            value={place}
            loading={loadingAddresses}
            onChange={onChangeAddress}
            placeholder="Ex: Florianópolis"
            label="Digite o endereço (Obrigatório)"
          />

          {place.length > 2 && (
            <List selection verticalAlign="middle">
              {console.log(addresses)}
              {!loadingAddresses &&
                addresses?.map(i => {
                  const findAddress = i.formatted_address
                  const { lat, lng } = i.geometry.location

                  return (
                    <List.Item
                      key={`key-geo-${place}`}
                      onClick={() => onClick({ place: findAddress, lat, lng })}
                    >
                      <List.Icon style={{ color: 'red' }} name="marker" />
                      <List.Content>
                        <List.Header as="a">{findAddress}</List.Header>
                      </List.Content>
                    </List.Item>
                  )
                })}
            </List>
          )}

          {images.length > 0 && (
            <List divided verticalAlign="middle">
              {images.map(i => {
                return (
                  <List.Item
                    key={`key-${i.uid}`}
                    style={{ alignItems: 'center' }}
                    onClick={() => onRemove(i.uniqueId)}
                  >
                    <Image
                      size="tiny"
                      src={i.preview}
                      verticalAlign="middle"
                      style={{ borderRadius: 4, maxHeight: 40 }}
                    />
                    <List.Content>
                      <List.Header>{i.order}. Ordem</List.Header>
                      <span>{i.size}</span>
                    </List.Content>
                  </List.Item>
                )
              })}
            </List>
          )}
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button
          negative
          onClick={onClose}
          content="Cancelar"
          loading={loadingUpload}
          disabled={loadingUpload}
        />
        <Button
          positive
          icon="checkmark"
          content="Enviar"
          labelPosition="right"
          loading={loadingUpload}
          disabled={loadingUpload}
          onClick={onSubmitImages}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default SendImages

SendImages.propTypes = {
  id: t.string,
  open: t.bool,
  onClose: t.func,
  onRemove: t.func,
  onRefresh: t.func,
  images: t.arrayOf(t.any),
}
