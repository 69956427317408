import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useUsersBadges() {
  const [loadingUsersBadges, setLoading] = useState(true)
  const [usersBadges, setUsersBadges] = useState([])

  const getUsersBadges = useCallback(async params => {
    try {
      setLoading(true)
      const res = await api.get('users-badges/admin', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setUsersBadges(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  async function onUpdateUsersBadges(data) {
    try {
      setLoading(true)
      const res = await api.put('users-badges/admin', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return Message().success('Badge vinculado com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function onCreateUsersBadges(data) {
    try {
      setLoading(true)
      const res = await api.post('users-badges/admin', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return Message().success('Badge vinculado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      getUsersBadges({ userId: data.userId })
      setLoading(false)
    }
  }

  async function onRemoveUsersBadges({ id, userId }) {
    try {
      setLoading(true)
      const res = await api.delete(`users-badges/admin/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Badge removido com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      getUsersBadges({ userId })
      setLoading(false)
    }
  }

  return {
    usersBadges,
    getUsersBadges,
    loadingUsersBadges,
    onRemoveUsersBadges,
    onCreateUsersBadges,
    onUpdateUsersBadges,
  }
}
