import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useFormsUsers() {
  const [formsUsersData, setFormsUsersData] = useState(null)
  const [loadingFud, setLoading] = useState(false)

  const getFormsUsersData = useCallback(async ({ userId, badgeId }) => {
    try {
      setLoading(true)
      const res = await api.get('forms-users-data/admin', {
        params: { userId, badgeId },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setFormsUsersData(res.data.formsUsersData)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  async function onRemoveFormsUsers(formsUsersId) {
    try {
      const res = await api.delete('forms-users', {
        params: { formsUsersId },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      Message().success('Formulário removido com sucesso')
      return res.data
    } catch (error) {
      return Message().error(error.message)
    }
  }

  return {
    loadingFud,
    formsUsersData,
    getFormsUsersData,
    onRemoveFormsUsers,
  }
}
