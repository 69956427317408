import React from 'react'
import ContentLoader from 'react-content-loader'

export default () => (
  <ContentLoader viewBox="0 0 120 100" width={150} height={120} speed={1}>
    <rect x="0" y="44" rx="0" ry="0" width="0" height="0" />
    <rect x="0" y="29" rx="0" ry="0" width="258" height="32" />
    <rect x="29" y="116" rx="0" ry="0" width="749" height="32" />
  </ContentLoader>
)
