import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

function useReports() {
  const [filters, setFilters] = useState({})
  const [errorsValidation, setErrorsValidation] = useState({})
  const [enableLoader, setEnableLoader] = useState(false)
  const [reports, setReports] = useState([])
  const [paginate, setPagination] = useState({})
  const [offset, setOffset] = useState(0)
  const [limit] = useState(10)
  const [reportSelected, setReportSelected] = useState({})

  const generateXlsUrl = async data => {
    const response = await api.get(
      `forms-data/zip-images-signed-url?type=EXCEL&uid=${data.uid}`
    )
    const aElement = document.createElement('a')
    aElement.href = response.data.signedUrl
    aElement.target = '_blank'
    aElement.click()
  }

  const generateZipSignedUrls = async data => {
    data.urls = data.url.split(',')

    const promiseSignedUrls = []
    for (let index = 0; index < data.urls.length; index += 1) {
      const link = data.urls[index]
      const linkSplited = link.split('/')
      const filename = linkSplited[linkSplited.length - 1]
      promiseSignedUrls.push(
        api.get(
          `reports/zips-signed-url?type=ZIP&uid=${data.uid}&filename=${filename}`
        )
      )
    }

    const signedUrls = await Promise.all(promiseSignedUrls)
    for (let index = 0; index < signedUrls.length; index += 1) {
      data.urls[index] = signedUrls[index].data.signedUrl
    }

    setReportSelected(data)
  }

  const exportPhotos = async () => {
    const errors = {}

    if (!filters.initial) {
      errors.initial = 'Campo é obrigatório'
    }

    if (!filters.final) {
      errors.final = 'Campo é obrigatório'
    }

    if (!filters.customerId) {
      errors.customerId = 'Campo é obrigatório'
    }

    const hasErrors = Object.keys(errors).length > 0
    if (hasErrors) {
      setErrorsValidation(errors)
      return
    }

    setErrorsValidation({})
    try {
      setEnableLoader(true)
      const { data } = await api.post('reports/photos', filters)
      Message().success(
        `As fotos estão sendo exportadas é assim que finalizar a exportação os links serão enviados para o email ${data.emailUsedToSendZipLinks}.`
      )
      setFilters({
        initial: '',
        final: '',
        customerId: null,
        formId: null,
        type: null,
      })
    } catch (error) {
      Message().error(
        error?.response?.data?.message || 'Ocorreu um erro inesperado'
      )
    } finally {
      setEnableLoader(false)
    }
  }

  const exportImages = async () => {
    const errors = {}

    if (!filters.initial) {
      errors.initial = 'Campo é obrigatório'
    }

    if (!filters.final) {
      errors.final = 'Campo é obrigatório'
    }

    if (!filters.customerId) {
      errors.customerId = 'Campo é obrigatório'
    }

    if (!filters.formId) {
      errors.formId = 'Campo é obrigatório'
    }

    const hasErrors = Object.keys(errors).length > 0
    if (hasErrors) {
      setErrorsValidation(errors)
      return
    }

    setErrorsValidation({})
    try {
      setEnableLoader(true)
      const { data } = await api.post('reports/images', filters)
      Message().success(
        `As imagens estão sendo exportadas é assim que finalizar a exportação os links serão enviados para o email ${data.emailUsedToSendZipLinks}.`
      )
      setFilters({
        initial: '',
        final: '',
        customerId: null,
        formId: null,
        type: null,
      })
    } catch (error) {
      Message().error(
        error?.response?.data?.message || 'Ocorreu um erro inesperado'
      )
    } finally {
      setEnableLoader(false)
    }
  }

  const getReports = async (offset = 0, customerId) => {
    const res = await api.get('customers/reports', {
      params: {
        offset,
        limit,
        all: true,
        customerId,
      },
    })

    setReports([...res.data])

    const pageLimit = offset * limit
    let count = 0

    if (res?.data?.length > 0) {
      count = res.data[0].total_rows
    }

    setPagination(page => ({
      ...page,
      total: Number(count),
      pageSize: res.data.length,
      current: page.current > 0 ? pageLimit - limit : 1,
    }))
  }

  return {
    exportImages,
    enableLoader,
    errorsValidation,
    filters,
    setFilters,
    getReports,
    setPagination,
    paginate,
    setOffset,
    reports,
    limit,
    offset,
    generateXlsUrl,
    generateZipSignedUrls,
    reportSelected,
    exportPhotos,
  }
}

export default useReports
