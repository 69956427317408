import { responseData } from '~/utils/reducer'

const FORMS_USERS_FETCH = 'FORMS_USERS_FETCH'
const FORMS_USERS_CREATE = 'FORMS_USERS_CREATE'
const FORMS_USERS_DELETE = 'FORMS_USERS_DELETE'
const FORMS_USERS_UPDATE = 'FORMS_USERS_UPDATE'
const FORMS_USERS_FETCH_TYPE = 'FORMS_USERS_FETCH_TYPE'
const FORMS_USERS_CREATE_TYPE = 'FORMS_USERS_CREATE_TYPE'

const formsUsers = responseData(FORMS_USERS_FETCH, 'array')
const formsUsersCreate = responseData(FORMS_USERS_CREATE, 'object')
const formsUsersDelete = responseData(FORMS_USERS_DELETE, 'object')
const formsUsersUpdate = responseData(FORMS_USERS_UPDATE, 'object')
const formsUsersType = responseData(FORMS_USERS_FETCH_TYPE, 'array')
const formsUsersCreateType = responseData(FORMS_USERS_CREATE_TYPE, 'object')

export default {
  formsUsers,
  formsUsersType,
  formsUsersCreate,
  formsUsersDelete,
  formsUsersUpdate,
  formsUsersCreateType,
}
