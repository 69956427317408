import { responseData } from '~/utils/reducer'

const IMPORT_FILE = 'IMPORT_FILE'
const IMPORT_CREATE = 'IMPORT_CREATE'
const IMPORT_FILE_ID = 'IMPORT_FILE_ID'
const IMPORT_FILE_UPDATE = 'IMPORT_FILE_UPDATE'
const IMPORT_FILE_CREATE = 'IMPORT_FILE_CREATE'
const IMPORT_FILE_REMOVE = 'IMPORT_FILE_REMOVE'
const IMPORT_FILE_MISSIONS_OPEN = 'IMPORT_FILE_MISSIONS_OPEN'

const importsFile = responseData(IMPORT_FILE, 'array')
const importCreated = responseData(IMPORT_CREATE, 'object')
const importsFilesMissions = responseData(IMPORT_FILE_ID, 'array')
const importsFileUpdate = responseData(IMPORT_FILE_UPDATE, 'object')
const importsFileCreated = responseData(IMPORT_FILE_CREATE, 'object')
const importsFileRemoved = responseData(IMPORT_FILE_REMOVE, 'object')
const exportedMissionsOpen = responseData(IMPORT_FILE_MISSIONS_OPEN, 'object')

export default {
  importsFile,
  importCreated,
  importsFileUpdate,
  importsFileCreated,
  importsFileRemoved,
  importsFilesMissions,
  exportedMissionsOpen,
}
