import React, { useEffect, useState } from 'react'

import t from 'prop-types'
import { Modal, Button, Form, Dropdown } from 'semantic-ui-react'

import useUsers from '~/hooks/useUsers'
import message from '~/utils/messages'

export function ModalExport({ filters, initialFilters, open, close }) {
  const [columnsOptions, setColumnsOptions] = useState([])
  const [columnsSelected, setColumnsSelected] = useState([])
  const { onExport, loadingExport } = useUsers()

  function onSubmitExport() {
    if (columnsSelected.length === 0) {
      return message().error('Selecione ao menos uma coluna!')
    }

    const hasPublic = columnsSelected.includes('public_users')
    const hasPrivate = columnsSelected.includes('private_users')

    if (hasPublic && hasPrivate) {
      return message().error(
        'Não é permitido selecionar usuários privados e públicos na mesma consulta'
      )
    }

    return onExport({ ...filters, export_columns: columnsSelected })
  }

  const clear = () => {
    setColumnsSelected([])
  }

  useEffect(() => {
    const columns = []
    const ignore = ['limit', 'offset', 'order', 'direction', 'badges']

    Object.keys(initialFilters).forEach(key => {
      if (!ignore.includes(key)) {
        columns.push({ key, value: key, text: key })
      }
    })

    setColumnsOptions(columns)
  }, [initialFilters])

  return (
    <Modal size="small" open={open} centered={false}>
      <Modal.Content>
        <h2>Selecione as colunas que serão exportadas:</h2>
        <Form>
          <Form.Field>
            <Dropdown
              search
              selection
              multiple
              options={columnsOptions}
              placeholder="Colunas"
              value={columnsSelected || []}
              onChange={(e, { value }) => setColumnsSelected(value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Content>
        <Button color="red" icon="close" onClick={close} content="Cancelar" />
        <Button
          color="green"
          floated="right"
          icon="download"
          loading={loadingExport}
          disabled={loadingExport}
          onClick={onSubmitExport}
          content="Exportar"
        />
        <Button
          color="grey"
          floated="right"
          icon="undo"
          onClick={clear}
          content="Limpar"
        />
      </Modal.Content>
    </Modal>
  )
}

ModalExport.propTypes = {
  filters: t.objectOf(t.any),
  initialFilters: t.objectOf(t.any),
  open: t.bool,
  close: t.func,
}
