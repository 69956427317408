import React from 'react'
import NumberFormat from 'react-number-format'

import t from 'prop-types'
import { Card, Form, Grid, Button } from 'semantic-ui-react'

const CreateSubCategorieValue = ({
  item,
  show,
  create,
  loading,
  onChange,
  valueType,
}) => (
  <Card
    centered
    fluid
    style={{
      marginTop: 10,
      display: show ? 'block' : 'none',
    }}
  >
    <Card.Content>
      <Grid relaxed>
        <Grid.Column>
          <Form>
            <Form.Input
              type="text"
              value={item.description || ''}
              label="Descrição (Obrigatório)"
              placeholder={valueType ? 'Volume' : 'Ex: Orçamento'}
              onChange={(e, { value }) => onChange('description', value)}
            />

            <NumberFormat
              prefix={valueType ? 'R$' : '%'}
              customInput={Form.Input}
              decimalSeparator="."
              label="Valor Cliente"
              thousandSeparator
              value={item.customer_value || ''}
              placeholder={valueType ? 'Ex: 10,50 (R$)' : 'Ex: 1 (%)'}
              onValueChange={({ value }) => onChange('customer_value', value)}
            />

            <NumberFormat
              prefix={valueType ? 'R$' : '%'}
              thousandSeparator
              decimalSeparator="."
              label="Valor Usuário"
              customInput={Form.Input}
              value={item.user_value || ''}
              placeholder={valueType ? 'Ex: 10,50 (R$)' : 'Ex: 1 (%)'}
              onValueChange={({ value }) => onChange('user_value', value)}
            />
          </Form>
        </Grid.Column>
      </Grid>
    </Card.Content>
    <Card.Content extra textAlign="right">
      <Button
        positive
        icon="check"
        content="Salvar"
        loading={loading}
        disabled={loading}
        labelPosition="left"
        onClick={() => create()}
      />
    </Card.Content>
  </Card>
)

CreateSubCategorieValue.propTypes = {
  show: t.bool,
  create: t.func,
  loading: t.bool,
  onChange: t.func,
  valueType: t.bool,
  item: t.objectOf(t.any),
}

export default CreateSubCategorieValue
