import React from 'react'
import DatePicker from 'react-datepicker'

import t from 'prop-types'
import { Button, Card, Form, Grid, Image, Modal } from 'semantic-ui-react'

function CreateOrEdit({
  productData,
  open,
  onClose,
  onChange,
  onCreate,
  uploadImage,
  myRef,
  onClickUpload,
  storeProductsCategories,
  isLoading,
  onUpdate,
}) {
  const options = [
    { key: 'p', text: 'Pontos', value: 'points' },
    { key: 'd', text: 'Dinheiro', value: 'money' },
  ]

  const optionsStoreProductsCategories = storeProductsCategories?.map(
    category => {
      return {
        key: category.uid,
        text: category.description,
        value: category.uid,
      }
    }
  )

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>{productData?.header}</Modal.Header>
      <Modal.Content>
        <Grid columns={2} relaxed>
          <Grid.Column width={4}>
            <Card>
              <Image
                size="medium"
                verticalAlign="middle"
                src={productData?.image_url}
                disabled={isLoading}
              />
              <Card.Content>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  style={{ display: 'none' }}
                  ref={myRef}
                  onChange={uploadImage}
                />

                <Button
                  size="medium"
                  icon="upload"
                  content="Upload de Imagem"
                  labelPosition="left"
                  onClick={onClickUpload}
                  disabled={isLoading}
                />
              </Card.Content>
            </Card>
          </Grid.Column>

          <Grid.Column width={12}>
            <Form loading={isLoading}>
              <Form.Input
                fluid
                id="name"
                type="text"
                label="Nome do Produto"
                placeholder="Digite um nome para o produto"
                value={productData?.name || ''}
                onChange={(e, { value }) => onChange('name', value)}
              />

              <Form.TextArea
                id="description"
                type="text"
                label="Descrição do Produto"
                placeholder="Digite uma descrição para o produto"
                value={productData?.description || ''}
                onChange={(e, { value }) => onChange('description', value)}
              />

              <Form.Group widths="equal">
                <Form.Input
                  id="value"
                  type="number"
                  label="Valor do Produto"
                  placeholder="Digite um valor para o produto"
                  value={productData?.value || ''}
                  onChange={(e, { value }) => onChange('value', value)}
                />

                <Form.Select
                  search
                  id="currencyType"
                  label="Tipo de Moeda"
                  placeholder="Escolha a moeda"
                  options={options}
                  value={productData?.currencyType || ''}
                  onChange={(e, { value }) => onChange('currencyType', value)}
                />

                <Form.Input
                  id="stockLimit"
                  type="number"
                  label="Limite de Vendas"
                  placeholder="Número de possíveis vendas"
                  value={productData?.stockLimit || ''}
                  onChange={(e, { value }) => onChange('stockLimit', value)}
                />
              </Form.Group>

              <Form.Select
                search
                id="storeProductsCategoriesId"
                label="Categoria"
                placeholder="Selecione uma categoria para o produto"
                options={optionsStoreProductsCategories}
                value={productData?.storeProductsCategoriesId || ''}
                onChange={(e, { value }) =>
                  onChange('storeProductsCategoriesId', value)
                }
              />

              <Form.Group>
                <Form.Field>
                  <label>Data para início das Vendas</label>
                  <DatePicker
                    id="start"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Data Inicio"
                    selected={productData?.startedAt || ''}
                    onChange={value => onChange('startedAt', value)}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Data para término das Vendas</label>
                  <DatePicker
                    id="expired"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Data Inicio"
                    selected={productData?.expiredAt || ''}
                    onChange={value => onChange('expiredAt', value)}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Checkbox
                id="status"
                label="Produto disponível para venda na loja"
                checked={productData?.status || false}
                onChange={(e, { checked }) => onChange('status', checked)}
              />
              <Form.Checkbox
                id="hasDelivery"
                label="Produto precisa ser entregue?"
                checked={productData?.hasDelivery || false}
                onChange={(e, { checked }) => onChange('hasDelivery', checked)}
              />
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          content="Cancelar"
          onClick={onClose}
          disabled={isLoading}
        />

        {productData?.type === 'new' ? (
          <Button
            positive
            content="Salvar"
            onClick={onCreate}
            disabled={isLoading}
          />
        ) : (
          <Button
            color="orange"
            content="Atualizar"
            onClick={onUpdate}
            disabled={isLoading}
          />
        )}
      </Modal.Actions>
    </Modal>
  )
}

CreateOrEdit.propTypes = {
  productData: t.objectOf(t.any),
  open: t.bool,
  onClose: t.func,
  onChange: t.func,
  onCreate: t.func,
  uploadImage: t.func,
  myRef: t.objectOf(t.any),
  onClickUpload: t.func,
  storeProductsCategories: t.arrayOf(t.any),
  isLoading: t.bool,
  onUpdate: t.func,
}

export default CreateOrEdit
