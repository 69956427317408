import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import {
  Button,
  Card,
  CardContent,
  Dropdown,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Label,
  Table,
} from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useCustomers from '~/hooks/useCustomers'
import useWebhook from '~/hooks/useWebhook'

import { CreateWebhook } from './components/CreateWebhook'

export default function Webhook() {
  const { getCustomers, listNameCustomers, loadingCustomers } = useCustomers()
  const {
    createWebhook,
    getWebhooksEvents,
    webhooksEvents,
    loadingWebhook,
    getWebhooks,
    webhooks,
    removeWebhooks,
  } = useWebhook()

  const [selectedCustomer, setSelectedCustomer] = useState('')
  const [openEvents, setOpenEvents] = useState(false)

  const columns = ['Endpoint', 'Status', 'Criado em', 'Ações']

  function renderItem(item, index) {
    return (
      <Table.Row key={`key-${index}`}>
        <Table.Cell>{item.callback_url}</Table.Cell>
        <Table.Cell>
          {item.status ? (
            <Label color="green">Ativo</Label>
          ) : (
            <Label color="gray">Inativo</Label>
          )}
        </Table.Cell>
        <Table.Cell>
          {dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}
        </Table.Cell>
        <Table.Cell>
          <Dropdown icon="ellipsis vertical">
            <Dropdown.Menu direction="left">
              <Dropdown.Item
                text="Remover"
                icon="trash"
                onClick={() =>
                  removeWebhooks({ id: item.id, customerId: selectedCustomer })
                }
              />
            </Dropdown.Menu>
          </Dropdown>
        </Table.Cell>
      </Table.Row>
    )
  }

  useEffect(() => {
    getCustomers({ limit: 10000 })
    getWebhooksEvents()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (selectedCustomer) {
      getWebhooks(selectedCustomer)
    }
  }, [selectedCustomer]) // eslint-disable-line

  return (
    <>
      <CreateWebhook
        createWebhook={createWebhook}
        loadingWebhook={loadingWebhook}
        open={openEvents}
        onClose={() => setOpenEvents(false)}
        webhooksEvents={webhooksEvents}
        customerId={selectedCustomer}
      />
      <Grid columns={2}>
        <GridRow>
          <GridColumn>
            <Header>Webhooks</Header>
          </GridColumn>
          <GridColumn>
            <Button
              floated="right"
              content="Novo Webhook"
              primary
              icon="plus"
              labelPosition="left"
              onClick={() => setOpenEvents(true)}
              disabled={!selectedCustomer}
            />
          </GridColumn>
        </GridRow>
      </Grid>

      <Card fluid>
        <CardContent>
          Clientes:
          <Dropdown
            fluid
            search
            selection
            placeholder="Escolha um cliente..."
            loading={loadingCustomers}
            disabled={loadingCustomers}
            options={listNameCustomers}
            value={selectedCustomer}
            onChange={(e, { value }) => setSelectedCustomer(value)}
          />
        </CardContent>
      </Card>

      <TableComponent
        emptyText={{ icon: 'linkify', text: 'Nenhum Webhook Cadastrado' }}
        data={webhooks}
        columns={columns}
        renderItem={renderItem}
      />
    </>
  )
}
