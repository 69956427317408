import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useWorkforce() {
  const [loadingWebhook, setLoadingWebhook] = useState(false)
  const [webhooksEvents, setWebhooksEvents] = useState([])
  const [webhooks, setWebhooks] = useState([])

  async function createWebhook(params) {
    try {
      setLoadingWebhook(true)

      const res = await api.post('webhook/admin', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return Message().success('Webhook criado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingWebhook(false)
    }
  }

  async function getWebhooksEvents(customerId) {
    try {
      setLoadingWebhook(true)

      const res = await api.get('webhook/events/admin', {
        params: { customerId },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setWebhooksEvents(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingWebhook(false)
    }
  }

  async function getWebhooks(customerId) {
    try {
      setLoadingWebhook(true)

      const res = await api.get('webhook/admin', {
        params: { customerId },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setWebhooks(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingWebhook(false)
    }
  }

  async function removeWebhooks(params) {
    try {
      setLoadingWebhook(true)

      const res = await api.delete(`webhook/admin/${params.id}`, {
        params: { customerId: params.customerId },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getWebhooks(params.customerId)

      return Message().success('Webhook removido com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingWebhook(false)
    }
  }

  return {
    createWebhook,
    getWebhooksEvents,
    webhooksEvents,
    loadingWebhook,
    getWebhooks,
    webhooks,
    removeWebhooks,
  }
}
