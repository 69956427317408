import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useFiles() {
  const [files, setFiles] = useState([])
  const [progress, setProgress] = useState(0)
  const [loadingFiles, setLoadingFiles] = useState(true)

  const getFiles = useCallback(async id => {
    try {
      const res = await api.get(`files/admin/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.mesage)
      }

      return setFiles(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFiles(false)
    }
  }, [])

  async function onRemoveFile({ id, fileId }) {
    try {
      const res = await api.delete(`files/${fileId}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.data)
      }

      return getFiles(id)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFiles(false)
    }
  }

  async function onSubmitFile({ id, data }) {
    try {
      const newData = new FormData()
      data.forEach(item => newData.append('files', item))
      newData.append('missionsUsersId', id)

      const res = await api.post('files/admin', newData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          setProgress(percentCompleted)
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }
      return Message().success('Arquivos enviado(s) com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setProgress(0)
      getFiles(id)
      setLoadingFiles(false)
    }
  }

  return {
    onSubmitFile,
    onRemoveFile,
    getFiles,
    files,
    progress,
    loadingFiles,
  }
}
