import { Card } from 'semantic-ui-react'
import styled from 'styled-components'

export const CardGroup = styled(Card.Group)`
  .ui.card,
  .ui.cards > .card {
    width: 31.6rem;
  }

  @media (max-width: 768px) {
    justify-content: center;

    .ui.card,
    .ui.cards > .card {
      width: 100%;
    }
  }
`
