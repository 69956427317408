import React from 'react'

import t from 'prop-types'
import { Button, Modal } from 'semantic-ui-react'

function Confirm({ data, onClose, onClickConfirm }) {
  const actionText = {
    approve: 'Aprovação',
    reprove: 'Reprovação',
  }

  return (
    <Modal open={data.open} onClose={onClose} size="small">
      <Modal.Header>Confirmação de {actionText[data.action]}</Modal.Header>
      <Modal.Content>
        <p>
          Tem Certeza que Deseja Realizar a{' '}
          <span
            style={{
              fontWeight: 'bold',
              color: data.action === 'approve' ? 'green' : 'red',
            }}
          >
            {actionText[data.action]}
          </span>{' '}
          do Item Abaixo?
        </p>
        <p>
          <strong>Usuário:</strong> {data.item.username}
        </p>
        <p>
          <strong>Certificado:</strong> {data.item.badge}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancelar" onClick={onClose} />
        <Button
          primary
          content="Confirmar"
          onClick={() => onClickConfirm(data.item.id, data.action)}
        />
      </Modal.Actions>
    </Modal>
  )
}

Confirm.propTypes = {
  data: t.objectOf(t.any),
  onClose: t.func,
  onClickConfirm: t.func,
}

export default Confirm
