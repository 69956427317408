import React from 'react'
import ReactQuill from 'react-quill'

import t from 'prop-types'
import { isEmpty } from 'ramda'
import {
  Card,
  Form,
  Grid,
  Icon,
  Image,
  Button,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react'

const CreateBadges = ({
  show,
  title,
  myRef,
  onClick,
  avatar,
  create,
  isLoading,
  isConsultant,
  isExclusive,
  isPrivate,
  uploadImage,
  onChange,
  urlVideo,
  description,
  requireApproval,
  listNameCustomers,
  selectedCustomer,
  avgTime,
  avgValue,
  minScore,
}) => (
  <Card
    centered
    fluid
    style={{ marginTop: 10, display: show ? 'block' : 'none' }}
  >
    <Card.Content>
      <Grid columns={2} relaxed>
        <Grid.Column width={3}>
          <Card>
            <Image
              size="medium"
              verticalAlign="middle"
              src={
                isEmpty(avatar)
                  ? 'https://react.semantic-ui.com/images/avatar/large/matthew.png'
                  : avatar?.src
              }
            />
            <Card.Content>
              <input
                type="file"
                id="uploadImage"
                accept="image/*"
                style={{ display: 'none' }}
                ref={myRef}
                onChange={uploadImage}
              />
              <Button
                icon="upload"
                content="Upload"
                labelPosition="right"
                onClick={onClick}
              />
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column width={13}>
          <Form>
            <Form.Input
              id="title"
              type="text"
              value={title || ''}
              onChange={(e, target) => onChange('title', target.value)}
              label="Título (Obrigatório)"
              placeholder="Ex: Advogado"
            />
            <Form.Field inline>
              <Checkbox
                id="isExclusive"
                label="Exclusivo"
                checked={isExclusive}
                onChange={() => onChange('isExclusive', !isExclusive)}
              />
            </Form.Field>
            <Form.Field inline>
              <Checkbox
                id="isConsultant"
                checked={isConsultant}
                label="Consultor"
                onChange={() => onChange('isConsultant', !isConsultant)}
              />
            </Form.Field>
            {isConsultant && (
              <Form.Field inline>
                <Checkbox
                  id="requireApproval"
                  checked={requireApproval}
                  label="Exige aprovação de um líder?"
                  onChange={() => onChange('requireApproval', !requireApproval)}
                />
              </Form.Field>
            )}
            <Form.Field inline>
              <Checkbox
                id="isPrivate"
                checked={isPrivate}
                label="Privado"
                onChange={() => onChange('isPrivate', !isPrivate)}
              />
            </Form.Field>
            {isPrivate && (
              <Form.Field inline>
                <Dropdown
                  fluid
                  search
                  selection
                  placeholder="Escolhe um cliente"
                  options={listNameCustomers}
                  onChange={(e, { value }) => onChange('customerId', value)}
                  defaultValue={selectedCustomer}
                />
              </Form.Field>
            )}
            <Form.Input
              id="urlVideo"
              type="text"
              value={urlVideo || ''}
              onChange={(e, target) => onChange('urlVideo', target.value)}
              label="URL Video (Obrigatório)"
              placeholder="Ex: https://www.youtube.com/embed/{HASH}"
            />
            <div className="field">
              <label>Descrição (Obrigatório)</label>
              <ReactQuill
                value={description}
                onChange={value => onChange('description', value)}
              />
            </div>
            <Grid columns={3}>
              <Grid.Column>
                <Form.Input
                  id="avgTime"
                  type="number"
                  value={avgTime || ''}
                  label="Tempo Médio"
                  onChange={(e, { value }) => onChange('avgTime', value)}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  id="avgValue"
                  type="number"
                  value={avgValue || ''}
                  label="Valor Médio"
                  onChange={(e, { value }) => onChange('avgValue', value)}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  id="minScore"
                  icon="percent"
                  type="number"
                  value={minScore || ''}
                  label="Pontuação Mínima"
                  onChange={(e, { value }) => onChange('minScore', value)}
                />
              </Grid.Column>
            </Grid>
          </Form>
        </Grid.Column>
      </Grid>
    </Card.Content>
    <Card.Content extra textAlign="right">
      <Button
        icon
        positive
        onClick={create}
        loading={isLoading}
        disabled={isLoading}
        labelPosition="left"
      >
        <Icon name="check" /> Salvar
      </Button>
    </Card.Content>
  </Card>
)

CreateBadges.propTypes = {
  show: t.bool,
  title: t.string,
  myRef: t.func,
  onClick: t.func,
  avatar: t.objectOf(t.any),
  create: t.func,
  isLoading: t.bool,
  isConsultant: t.bool,
  isExclusive: t.bool,
  uploadImage: t.func,
  onChange: t.func,
  urlVideo: t.string,
  requireApproval: t.bool,
  description: t.string,
  listNameCustomers: t.arrayOf(t.any),
  isPrivate: t.bool,
  selectedCustomer: t.string,
  avgTime: t.string,
  avgValue: t.string,
  minScore: t.string,
}

export default CreateBadges
