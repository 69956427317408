import React from 'react'

import t from 'prop-types'
import { Icon } from 'semantic-ui-react'

import { Container, Label, Value, Percentage } from './styles'

function CustomTooltip({ payload, active }) {
  if (active) {
    const data = payload[0].payload

    const isUp = data.situation === 'Crescimento'

    return (
      <Container>
        <Label>{data.name}</Label>
        <Value>{data.Valor}</Value>
        <Percentage isUp={isUp}>
          <Icon name={isUp ? 'caret up' : 'caret down'} />
          {data.Porcentagem}
        </Percentage>
      </Container>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: t.bool,
  payload: t.shape({
    name: t.string,
    Valor: t.string,
    situation: t.string,
    Porcentagem: t.string,
  }),
}

export default CustomTooltip
