import React from 'react'

import t from 'prop-types'
import { Button, Card, Form, Grid, Icon, Select } from 'semantic-ui-react'

const STATUS_OPTIONS = [
  {
    key: 1,
    value: 'PENDING',
    text: 'Pendente',
  },
  {
    key: 2,
    value: 'APPROVED',
    text: 'Aprovado',
  },
  {
    key: 3,
    value: 'REPROVED',
    text: 'Reprovado',
  },
]

const Filter = ({
  filters,
  search,
  cleanFilters,
  changeInputValue,
  getPlanOptions,
}) => (
  <Card fluid>
    <Card.Content>
      <Form>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label>Status:</label>
                <Select
                  value={filters.status}
                  onChange={(event, { value }) =>
                    changeInputValue('status', value)
                  }
                  placeholder="Selecionar um status"
                  options={STATUS_OPTIONS}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Nome do usuário:</label>
                <input
                  value={filters.username}
                  onChange={event =>
                    changeInputValue('username', event.target.value)
                  }
                  type="text"
                  placeholder="Digite o nome completo ou parte do nome. Ex: maria, ma, mari ou ria"
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Planos:</label>
                <Select
                  value={filters.planId}
                  onChange={(event, { value }) =>
                    changeInputValue('planId', value)
                  }
                  placeholder="Selecionar um plano"
                  options={getPlanOptions()}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <Form.Field>
          <Button
            icon
            labelPosition="left"
            type="submit"
            color="green"
            onClick={search}
          >
            <Icon name="check" />
            Filtrar
          </Button>
          <Button
            icon
            labelPosition="left"
            type="submit"
            color="orange"
            onClick={cleanFilters}
          >
            <Icon name="undo" />
            Resetar filtros
          </Button>
        </Form.Field>
      </Form>
    </Card.Content>
  </Card>
)

Filter.propTypes = {
  filters: t.arrayOf(t.any),
  search: t.func,
  cleanFilters: t.func,
  changeInputValue: t.func,
  getPlanOptions: t.func,
}

export { Filter }
