import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function usePhotos() {
  const [photos, setPhotos] = useState([])
  const [progress, setProgress] = useState(0)
  const [loadingPhotos, setLoadingPhotos] = useState(true)
  const [loadingUpload, setLoadingUpload] = useState(false)

  const getPhotos = useCallback(async ({ id, limit }) => {
    try {
      const res = await api.get(`photos/admin/${id}`, {
        params: {
          limit,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.data)
      }

      return setPhotos(
        res.data.data.map(i => ({
          ...i,
          path: `${i.path}?id=${new Date().getTime()}`,
        }))
      )
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingPhotos(false)
    }
  }, [])

  async function onRotatePhoto({ id, fileId, rotate }) {
    try {
      setLoadingPhotos(true)
      const res = await api.put(
        'photos/rotate',
        { uid: fileId, rotate },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.data)
      }

      return Message().success('Image alterada com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      getPhotos({ id })
      setLoadingPhotos(false)
    }
  }

  async function onReorderPhotos({ id, data }) {
    try {
      setLoadingPhotos(true)

      const res = await api.put('photos/reorder', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.data)
      }

      return Message().success('Image alterada com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      getPhotos({ id })
      setLoadingPhotos(false)
    }
  }

  async function onRemovePhoto(fileId) {
    try {
      const res = await api.delete(`photos/${fileId}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.data)
      }

      return Message().success('Item removido com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingPhotos(false)
    }
  }

  /**
   * @param src
   * @param info {   *
   *  @param lng
   *  @param src
   *  @param order
   *  @param table
   *  @param user_id
   *  @param mission_id
   *  @param missions_users_id
   * }
   * @returns
   */
  async function onSubmitPhotos({ data }) {
    try {
      setLoadingUpload(true)
      const newData = new FormData()
      newData.append('info', data.info)
      newData.append('src', data.src)

      const res = await api.post(`photos`, newData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          setProgress(percentCompleted)
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.data)
      }

      return Message().success('Arquivo(s) enviado(s) com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingUpload(false)
    }
  }

  return {
    onSubmitPhotos,
    onRemovePhoto,
    getPhotos,
    setPhotos,
    photos,
    progress,
    loadingUpload,
    loadingPhotos,
    onRotatePhoto,
    onReorderPhotos,
  }
}
