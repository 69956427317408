import React from 'react'

import t from 'prop-types'
import { Button, Form, Modal } from 'semantic-ui-react'

function SetTrackingCodeModal({
  modelSetTrackingCode,
  setModelSetTrackingCode,
  setTrackingCode,
  addTrackingCodeInOrder,
  trackingCode,
}) {
  return (
    <Modal
      size="mini"
      open={modelSetTrackingCode}
      onClose={() => setModelSetTrackingCode(false)}
    >
      <Modal.Header>Adicionar código de rastreio</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <Form.Input
              id="trackingCode"
              value={trackingCode}
              onChange={event => setTrackingCode(event.target.value)}
              type="text"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setModelSetTrackingCode(false)}>Fechar</Button>
        <Button primary onClick={() => addTrackingCodeInOrder()}>
          Confirmar
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

SetTrackingCodeModal.propTypes = {
  modelSetTrackingCode: t.bool,
  setModelSetTrackingCode: t.func,
  setTrackingCode: t.func,
  addTrackingCodeInOrder: t.func,
  trackingCode: t.string,
}

export default SetTrackingCodeModal
