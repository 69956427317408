import React, { useEffect } from 'react'

import t from 'prop-types'
import { Form, Radio, Modal, Button, Loader } from 'semantic-ui-react'

import useBadges from '~/hooks/useBadges'

function BadgesList({
  close,
  badgeId,
  openModal,
  onSaveBadge,
  onRemoveBadge,
  onChangeBadge,
}) {
  const { badges, getBadges, loadingBadges } = useBadges()

  useEffect(() => {
    getBadges({ limit: 1000, active: true })
  }, []) //eslint-disable-line

  return (
    <Modal size="small" onClose={close} open={openModal}>
      <Modal.Header>Escolha o Badge</Modal.Header>
      <Modal.Content>
        <Form>
          {loadingBadges && <Loader />}

          {!loadingBadges &&
            badges.map(item => (
              <Form.Field key={item.uid}>
                <Radio
                  name="radio"
                  value={item.uid}
                  label={item.title}
                  checked={badgeId === item.uid}
                  onChange={() => onChangeBadge(item.uid)}
                />
              </Form.Field>
            ))}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative content="Remover Badge" onClick={onRemoveBadge} />
        <Button content="Cancelar" onClick={close} />
        <Button positive content="Salvar" onClick={onSaveBadge} />
      </Modal.Actions>
    </Modal>
  )
}

BadgesList.propTypes = {
  badgeId: t.string,
  openModal: t.bool,
  onChangeBadge: t.func,
  close: t.func,
  onSaveBadge: t.func,
  onRemoveBadge: t.func,
}

export default BadgesList
