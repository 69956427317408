class Spreadsheet {
  static mapRowsToObject(rows) {
    const header = rows[0]
    for (let index = 1; index < rows.length; index += 1) {
      const line = rows[index]
      rows[index] = {
        [header[0]]: line[0],
        [header[1]]: line[1],
        [header[2]]: line[2],
        [header[3]]: line[3],
        [header[4]]: line[4],
        [header[5]]: line[5],
        [header[6]]: line[6],
        [header[7]]: line[7],
        [header[8]]: line[8],
        [header[9]]: line[9],
        [header[10]]: line[10],
        [header[11]]: line[11],
        [header[12]]: line[12],
        [header[13]]: line[13],
      }
    }
    rows.shift()
    return rows
  }
}

export default Spreadsheet
