import { toast } from 'react-semantic-toasts'

function Message() {
  function error(message) {
    return toast(
      {
        time: 5000,
        icon: 'remove',
        title: 'Alerta',
        type: 'warning',
        description: message,
      },
      () => true
    )
  }

  function errors(messages) {
    return toast(
      {
        icon: 'remove',
        title: 'Alerta',
        type: 'warning',
        list: messages,
        time: 7000
      },
      () => true
    )
  }

  function success(message) {
    return toast(
      {
        icon: 'check',
        type: 'success',
        title: 'Alerta',
        description: message,
      },
      () => true
    )
  }

  function info(message) {
    return toast(
      {
        icon: 'info',
        type: 'info',
        title: 'Alerta',
        description: message,
      },
      () => true
    )
  }

  return {
    info,
    error,
    success,
    errors
  }
}

export default Message
