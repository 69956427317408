/* eslint-disable no-shadow */
import React, { useState, useEffect, useCallback } from 'react'

import { Header, Button, Confirm } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import Loader from '~/components/Loading'
import WithdrawalsReport from '~/components/Modal/WithdrawalsReport'
import { useAuth } from '~/contexts/auth'
import useDashboard from '~/hooks/useDashboard'
import usePaymentRequest from '~/hooks/usePaymentRequest'
import useQueryFilters from '~/hooks/useQueryFilters'
import useUsers from '~/hooks/useUsers'
import BudgetFilter from '~/pages/Financial/PaymentsUsers/Filters'
import message from '~/utils/messages'

import BudgetHeaderData from './Header'
import Item from './Item'
import ModalRevertPaymentRequest from './ModalRevertPaymentRequest'
import { ModalStatusCheckCPF } from './ModalStatusCheckCPF'
import { Container } from './styles'

const initialFilters = {
  initial: null,
  final: null,
  status: 0,
  limit: 150,
  offset: 0,
}

export default function Payments() {
  const { user } = useAuth()

  const { checkCPFStatus, loadingCheckCPF } = useUsers()

  const {
    payments,
    paymentCount,
    transactions,
    onSubmitXls,
    onRevertPayment,
    onSubmitApprove,
    loadingPayments,
    onSubmitReceipt,
    getTransactions,
    loadingTransactions,
    getPaymentRequests,
  } = usePaymentRequest()
  const { getMetrics, metrics } = useDashboard()

  const [open, setOpen] = React.useState(false)
  const [errorsModalAttach, setErrorsModalAttach] = useState([])
  const [isOpenModalReport, setIsOpenModalReport] = useState(false)
  const [revertPaymentRequest, setRevertPaymentRequest] = useState({
    uid: '',
    motivation: '',
  })
  const [openConfirmCheckStatusCPF, setOpenConfirmCheckStatusCPF] =
    useState(false)
  const [selectedUserId, setSelectedUserId] = useState('')

  const parseQueryParamsBeforeSetFilters = useCallback(queryObj => {
    const parsedParams = {}

    if (queryObj.limit) {
      parsedParams.limit = parseInt(queryObj.limit, 0)
    }
    if (queryObj.offset) {
      parsedParams.offset = parseInt(queryObj.offset, 0)
    }
    if (queryObj.initial) {
      parsedParams.initial = new Date(parseInt(queryObj.initial, 0))
    }
    if (queryObj.final) {
      parsedParams.final = new Date(parseInt(queryObj.final, 0))
    }
    if (queryObj.status) {
      parsedParams.status = parseInt(queryObj.status, 0)
    }

    return parsedParams
  }, [])

  const {
    filters,
    debouncedFilters,
    setFilters,
    onChangeFilter,
    clearFilters,
  } = useQueryFilters(initialFilters, parseQueryParamsBeforeSetFilters)

  function onLoadPage() {
    const { limit } = filters

    return setFilters(prev => ({ ...prev, limit: limit + 100 }))
  }

  function onRefresh() {
    const validParams = { ...debouncedFilters }

    if (validParams.initial) {
      validParams.initial = new Date(validParams.initial).getTime()
    }

    if (validParams.final) {
      validParams.final = new Date(validParams.final).getTime()
    }

    return getPaymentRequests(validParams)
  }

  function onSubmitExport() {
    const validParams = { ...debouncedFilters }

    if (validParams.initial) {
      validParams.initial = new Date(validParams.initial).getTime()
    }

    if (validParams.final) {
      validParams.final = new Date(validParams.final).getTime()
    }

    return onSubmitXls({ ...validParams, data: 'xls' })
  }

  function onUploadReceipt(e, id) {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onloadend = event => {
      message().info('Aguarde, o arquivo está sendo enviado...')

      if (file.size >= 3000000) {
        return setErrorsModalAttach(
          errorsModalAttach.concat('Arquivo deve ser menor ou igual a 3MB!')
        )
      }

      return onSubmitReceipt({
        uid: id,
        file: {
          size: file.size,
          name: file.name,
          type: file.type,
          base64: event.target.result,
        },
      })
    }
  }

  async function onConfirmPayment(id) {
    await message().info('Aguarde, o pagamento está sendo enviado...')

    return onSubmitApprove(id)
  }

  function onClickUpload(uid) {
    const input = document.getElementById(`uploadAttachment-${uid}`)
    input.value = null

    return input.click()
  }

  function handleOpenModalWidrawalsReport(params) {
    setIsOpenModalReport(true)

    return getTransactions({
      userId: params.user_id,
      createdAt: params.created_at,
    })
  }

  function openRevertPaymentRequestModal(item) {
    setRevertPaymentRequest(prev => ({ ...prev, uid: item.uid }))

    return setOpen(true)
  }

  function onRevertChange(motivation) {
    return setRevertPaymentRequest(prev => ({ ...prev, motivation }))
  }

  async function onRevertRequestPayment(revertPaymentRequest) {
    await onRevertPayment(revertPaymentRequest)
    setOpen(prev => !prev)
  }

  function handleCheckCPFStatus(params) {
    setSelectedUserId(params.user_id)
    setOpenConfirmCheckStatusCPF(true)
  }

  async function handleOnConfirmCheckStatusCPF(type) {
    setOpenConfirmCheckStatusCPF(false)

    if (type === 'confirm') {
      const result = await checkCPFStatus(selectedUserId)

      if (result) {
        onRefresh()
      }
    }

    if (type === 'cancel') {
      setOpenConfirmCheckStatusCPF(false)
    }
  }

  useEffect(() => {
    if (debouncedFilters !== null) {
      onRefresh()
    }
  }, [debouncedFilters]) //eslint-disable-line

  useEffect(() => {
    getMetrics('week')
  }, []) //eslint-disable-line

  if (user?.role_name === 'Basic' || user?.role_name === 'Leader') {
    return <EmptyList icon="lock" text="Acesso restrito" />
  }

  return (
    <Container>
      <ModalRevertPaymentRequest
        open={open}
        loading={loadingPayments}
        setMotivation={onRevertChange}
        onClose={() => setOpen(prev => !prev)}
        makeRevertPaymentRequest={() =>
          onRevertRequestPayment(revertPaymentRequest)
        }
      />

      <Header as="h1">Pagamentos Usuários</Header>

      {!!metrics && <BudgetHeaderData paymentsNumbersInfo={metrics} />}

      {filters && (
        <BudgetFilter
          filters={filters}
          isLoading={loadingPayments}
          clearFilters={clearFilters}
          onChangeFilter={onChangeFilter}
          onSubmitExport={onSubmitExport}
        />
      )}

      {loadingPayments && <Loader />}

      <ModalStatusCheckCPF loadingCheckCPF={loadingCheckCPF} />

      {!loadingPayments &&
        payments.length > 0 &&
        payments?.map((item, index) => (
          <Item
            item={item}
            onClickUpload={onClickUpload}
            confirmPayment={onConfirmPayment}
            uploadAttachments={onUploadReceipt}
            key={String(`${item.userName}${index}`)}
            openRevertPaymentRequestModal={openRevertPaymentRequestModal}
            handleOpenModalWidrawalsReport={handleOpenModalWidrawalsReport}
            handleCheckCPFStatus={handleCheckCPFStatus}
          />
        ))}

      <Confirm
        header="Alerta"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        open={openConfirmCheckStatusCPF}
        onConfirm={() => handleOnConfirmCheckStatusCPF('confirm')}
        content="Realizar Consulta de CPF na Receita Federal. Tem certeza que deseja continuar?"
        onCancel={() => handleOnConfirmCheckStatusCPF('cancel')}
      />

      {payments?.length === 0 && (
        <EmptyList icon="building" text="Nenhuma requisição foi realizada" />
      )}

      {Number(payments.length) < Number(paymentCount) && (
        <Button basic onClick={onLoadPage} loading={loadingPayments}>
          Carregar mais
        </Button>
      )}

      <WithdrawalsReport
        isOpen={isOpenModalReport}
        itemsTransactions={transactions}
        isLoading={loadingTransactions}
        close={() => setIsOpenModalReport(false)}
      />
    </Container>
  )
}
