import React, { useEffect, useState } from 'react'

import { format } from 'date-fns'
import { Button, Card, Grid, Header, Dropdown } from 'semantic-ui-react'

import CustomHeader from '~/components/Header'
import TableComponent from '~/components/Table'
import useCustomers from '~/hooks/useCustomers'
import useProducts from '~/hooks/useProducts'

import CreateCategory from './components/CreateCategory'
import CreateProduct from './components/CreateProduct'
import Item from './components/Item'
import RemoveCategory from './components/RemoveCategory'

function ImportsProductsCustomers() {
  const { getCustomers, listNameCustomers, loadingCustomers } = useCustomers()
  const {
    categories,
    getCategoriesProducts,
    loadingProducts,
    getProductsItems,
    productsItems,
    createCategoryProducts,
    updateCatagoryProducts,
    removeCategoryProducts,
    createProductItem,
    updateProductItem,
    removeProductItem,
    updateAllStatus,
    importFile,
    countProductsItems,
  } = useProducts()

  const [selectedCustomer, setSelectedCustomer] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedProducts, setSelectedProducts] = useState([])

  const columns = [
    'Check',
    'Status',
    'Nome',
    'Descrição',
    'Preço (R$)',
    'Parcelas (x)',
    'Desconto (qtd)',
    'Desconto (%)',
    'Criado Em',
    'Expirado Em',
  ]

  const initialCategory = {
    is_service: false,
    has_spotlight: false,
    description_spotlight: null,
  }

  const [createCategory, setCreateCategory] = useState({ ...initialCategory })
  const [createProduct, setCreateProduct] = useState({})
  const [categoryData, setCategoryData] = useState({})
  const [modalRemoveCategory, setModalRemoveCategory] = useState(false)

  const [modalCreateCategory, setModalCreateCategory] = useState({
    open: false,
    type: '',
  })

  const [modalProducts, setModalProducts] = useState({
    open: false,
    type: '',
  })

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 50,
    activePage: 1,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,
  })

  const queryProductsItems = {
    offset: pagination.offset,
    limit: pagination.limit,
  }

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * pagination.limit
    setPagination(prevState => ({
      ...prevState,
      activePage,
      offset,
      limit: pagination.limit,
    }))
    return getProductsItems(selectedCategory, {
      offset,
      limit: pagination.limit,
    })
  }

  useEffect(() => {
    getCustomers({ limit: 2000 })
    if (selectedCustomer) {
      getCategoriesProducts(selectedCustomer)
      setSelectedCategory('')
    }
  }, [selectedCustomer]) // eslint-disable-line

  useEffect(() => {
    if (selectedCategory)
      getProductsItems(selectedCategory, {
        offset: pagination.offset,
        limit: pagination.limit,
      })
  }, [selectedCategory]) // eslint-disable-line

  function onChangeCreateCategory(key, value) {
    setCreateCategory(prevState => ({
      ...prevState,
      [key]: value,
      customer_id: selectedCustomer,
    }))
  }

  function onChangeCreateProduct(key, value) {
    setCreateProduct(prevState => ({
      ...prevState,
      [key]: value,
      queryProductsItems,
    }))
  }

  function submitCreateCategory() {
    if (modalCreateCategory.type === 'create') {
      createCategoryProducts(createCategory)
      setSelectedCategory('')
    }

    if (modalCreateCategory.type === 'update') {
      updateCatagoryProducts(createCategory)
    }

    setTimeout(() => {
      setModalCreateCategory({ open: false })
      setCreateCategory({
        is_service: false,
        has_spotlight: false,
        description_spotlight: null,
      })
    }, 500)
  }

  function submitRemove() {
    removeCategoryProducts(selectedCategory)
    setSelectedCategory('')
    setTimeout(() => {
      setModalRemoveCategory(false)
    }, 500)
  }

  function submitCreateProduct() {
    if (modalProducts.type === 'create-product') {
      createProductItem(createProduct)
      setModalProducts({ open: false })
    }

    if (modalProducts.type === 'update-product') {
      updateProductItem(createProduct)
      setModalProducts({ open: false })
    }
  }

  function uploadImgProduct(e) {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = event => {
      setCreateProduct(prevState => ({
        ...prevState,
        image_path: event.target.result,
        data: file,
        queryProductsItems,
      }))
    }
  }

  function handleOnClick(typeClick, data) {
    if (typeClick === 'create') {
      setModalCreateCategory({ open: true, type: typeClick })

      setCreateCategory(prevState => ({
        ...prevState,
        ...initialCategory,
        customer_id: selectedCustomer,
      }))
    }
    if (typeClick === 'update') {
      setModalCreateCategory({ open: true, type: typeClick })
      const dataSelectCategory = categories.find(
        uid => uid.uid === selectedCategory
      )
      const { total_rows, value, text, ...dataCategory } = dataSelectCategory // eslint-disable-line
      setCreateCategory(dataCategory)
    }
    if (typeClick === 'remove') {
      setModalRemoveCategory(true)
      setCategoryData(categories.find(cat => cat.uid === selectedCategory))
    }

    if (typeClick === 'create-product') {
      setModalProducts({ open: true, type: typeClick })
      setCreateProduct({
        product_id: selectedCategory,
      })
    }

    if (typeClick === 'update-product') {
      setModalProducts({ open: true, type: typeClick })
      setCreateProduct({
        categoryId: selectedCategory,
        product_id: data.uid,
        uid: data.uid,
        title: data.title,
        description: data.description,
        expired_at: format(new Date(data.expired_at), 'yyyy-MM-dd'),
        installments: data.installments,
        price: data.price,
        quantity_discount: data.quantity_discount,
        value_discount: data.value_discount,
        id: data.product_id,
        special_conditions: data.special_conditions,
        image_path: data.image_path,
      })
    }

    if (typeClick === 'import-product') {
      importFile(data, selectedCategory, queryProductsItems)
    }
  }

  function removeProducts() {
    removeProductItem(selectedProducts, queryProductsItems)
    setSelectedProducts([])
  }

  function changeCheckbox(params) {
    if (params.checked) {
      setSelectedProducts([...selectedProducts, params.value])
    }

    if (!params.checked) {
      const newProducts = selectedProducts.filter(
        product => product !== params.value
      )
      setSelectedProducts(newProducts)
    }
  }

  function updateStatus(status) {
    const params = {
      status,
      uids: selectedProducts,
      queryProductsItems,
    }
    updateAllStatus(params)
  }

  function uploadFile(e) {
    return handleOnClick('import-product', e.target.files[0])
  }

  const renderItem = item => (
    <Item
      item={item}
      onClick={() => handleOnClick('update-product', item)}
      changeCheckbox={changeCheckbox}
    />
  )

  return (
    <>
      <CreateCategory
        showModal={modalCreateCategory.open}
        type={modalCreateCategory.type}
        onClose={() => setModalCreateCategory({ open: false })}
        onChange={onChangeCreateCategory}
        createCategory={createCategory}
        submit={submitCreateCategory}
      />

      <RemoveCategory
        showModal={modalRemoveCategory}
        onClose={() => setModalRemoveCategory(false)}
        categoryData={categoryData}
        submitRemove={submitRemove}
      />

      <CreateProduct
        showModal={modalProducts.open}
        type={modalProducts.type}
        onClose={() => setModalProducts({ open: false })}
        onChange={onChangeCreateProduct}
        dataProducts={createProduct}
        submit={submitCreateProduct}
        uploadImgProduct={uploadImgProduct}
      />

      <Header>Produtos da Plataforma do Cliente</Header>
      <Card fluid itemsPerRow={2}>
        <Card.Content>
          <Grid columns={2}>
            <Grid.Column>
              Cliente:
              <Dropdown
                fluid
                search
                selection
                placeholder="Escolha um cliente..."
                loading={loadingCustomers}
                disabled={loadingProducts}
                options={listNameCustomers}
                value={selectedCustomer}
                onChange={(e, { value }) => setSelectedCustomer(value)}
              />
            </Grid.Column>

            <Grid.Column>
              Categoria:
              <Dropdown
                fluid
                search
                selection
                placeholder="Escolha uma categoria..."
                loading={loadingProducts}
                options={categories}
                onChange={(e, { value }) => setSelectedCategory(value)}
                value={selectedCategory}
                disabled={!selectedCustomer}
              />
              <br />
              {selectedCustomer && (
                <Button
                  disabled={loadingProducts}
                  size="large"
                  icon="add"
                  content="Criar Categoria"
                  labelPosition="left"
                  color="green"
                  onClick={() => handleOnClick('create')}
                />
              )}
              {selectedCategory && (
                <>
                  <Button
                    disabled={loadingProducts}
                    size="large"
                    icon="refresh"
                    content="Atualizar Categoria"
                    labelPosition="left"
                    color="orange"
                    onClick={() => handleOnClick('update')}
                  />

                  <Button
                    disabled={loadingProducts}
                    size="large"
                    icon="remove"
                    content="Remover Categoria"
                    labelPosition="left"
                    color="red"
                    onClick={() => handleOnClick('remove')}
                  />
                </>
              )}
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>
      <CustomHeader
        selectRowKeys={selectedProducts}
        loadingProducts={loadingProducts}
        removeProducts={removeProducts}
        updateStatus={status => updateStatus(status)}
        uploadFile={uploadFile}
        handleOnClick={handleOnClick}
        selectedCategory={!selectedCategory}
      />
      <TableComponent
        emptyText={{ icon: 'box', text: 'Nenhum produto para exibir' }}
        data={productsItems}
        columns={columns}
        renderItem={renderItem}
        activePage={pagination.activePage}
        limit={pagination.limit}
        offset={pagination.offset}
        totalCount={countProductsItems}
        count={countProductsItems}
        showFirstAndLastNav={pagination.showFirstAndLastNav}
        showPreviousAndNextNav={pagination.showPreviousAndNextNav}
        handlePaginationChange={handlePaginationChange}
      />
    </>
  )
}

export default ImportsProductsCustomers
