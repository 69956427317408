import React from 'react'

import t from 'prop-types'
import { Modal, Grid, Button, TextArea } from 'semantic-ui-react'

const ModalRevertPaymentRequest = ({
  open,
  onClose,
  loading,
  makeRevertPaymentRequest,
  setMotivation,
}) => (
  <Modal onClose={onClose} open={open}>
    <Modal.Header>Reverter pedido de pagamento</Modal.Header>
    <Modal.Content image>
      <Modal.Description>
        <Grid>
          <Grid.Column textAlign="center">
            <p className="text-center">
              Você realmente deseja fazer essa ação? Caso sim, será necessário
              informar o motivo abaixo.
            </p>
            <TextArea
              onChange={event => {
                setMotivation(event.target.value)
              }}
              rows="5"
              cols="70"
            />
          </Grid.Column>
        </Grid>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button loading={loading} disabled={loading} onClick={onClose}>
        Cancelar
      </Button>
      <Button
        loading={loading}
        disabled={loading}
        color="green"
        onClick={makeRevertPaymentRequest}
      >
        Sim
      </Button>
    </Modal.Actions>
  </Modal>
)

ModalRevertPaymentRequest.propTypes = {
  open: t.bool,
  loading: t.bool,
  onClose: t.func,
  setMotivation: t.func,
  makeRevertPaymentRequest: t.func,
}

export default ModalRevertPaymentRequest
