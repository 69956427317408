export default [
  {
    key: 0,
    value: 0,
    text: 'Aguardando Avaliação',
  },
  {
    key: 1,
    value: 1,
    text: 'Aprovado',
  },
  {
    key: 2,
    value: 2,
    text: 'Reprovado',
  },
  {
    key: 3,
    value: 3,
    text: 'Validando Dados',
  },
]
