import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { head } from 'ramda'

import Breadcrumbs from '~/components/Breadcrumbs'
import useBadges from '~/hooks/useBadges'
import useCustomers from '~/hooks/useCustomers'

import CreateBadge from './components/Create'

import 'react-quill/dist/quill.snow.css'

function BadgeDetail() {
  const uploadRef = useRef()
  const { getCustomers, listNameCustomers } = useCustomers()

  const { id: badgeId } = useParams()

  const { onSubmitUpdate, getBadgeById, badge, loadingBadges } = useBadges()
  const [badgeInfo, setBadgeInfo] = useState({})

  function onChange(key, value) {
    return setBadgeInfo(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      link: '/badges',
      name: 'Badges',
    },
    {
      active: true,
      hasLink: false,
      name: badge.title,
    },
  ]

  const uploadImage = e => {
    const file = head(e.target.files)
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = event => {
      setBadgeInfo(prevState => ({
        ...prevState,
        avatar: {
          src: event.target.result,
          data: file,
        },
      }))
    }
  }

  useEffect(() => {
    getBadgeById(badgeId)
  }, []) // eslint-disable-line

  useEffect(() => {
    getCustomers({ limit: 2000 })
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!loadingBadges && !!Object.keys(badge).length) {
      setBadgeInfo(prevState => ({
        ...prevState,
        badgeId,
        title: badge.title,
        isPrivate: !!badge.customer_id,
        isConsultant: badge.is_consultant,
        isExclusive: badge.exclusive,
        customerId: badge.customer_id,
        urlVideo: badge.url_video,
        avatar: { src: badge.avatar },
        requireApproval: badge.require_approval,
        description: badge.description,
        avgTime: badge.avg_time,
        avgValue: badge.avg_value,
        minScore: badge.min_score,
      }))
    }
  }, [loadingBadges, badge]) // eslint-disable-line

  return (
    <>
      <Breadcrumbs items={breadcrumbsList} />
      <CreateBadge
        show
        myRef={uploadRef}
        onChange={onChange}
        title={badgeInfo.title}
        uploadImage={uploadImage}
        avatar={badgeInfo.avatar}
        avgTime={badgeInfo.avgTime}
        urlVideo={badgeInfo.urlVideo}
        avgValue={badgeInfo.avgValue}
        isPrivate={badgeInfo.isPrivate}
        isExclusive={badgeInfo.isExclusive}
        isConsultant={badgeInfo.isConsultant}
        listNameCustomers={listNameCustomers}
        selectedCustomer={badgeInfo.customerId}
        create={() => onSubmitUpdate(badgeInfo)}
        onClick={() => uploadRef.current.click()}
        requireApproval={badgeInfo.requireApproval}
        description={badgeInfo.description || badge.description || ''}
        minScore={badgeInfo.minScore}
      />
    </>
  )
}

export default BadgeDetail
