import React from 'react'
import { Popup, Marker } from 'react-map-gl'

import moment from 'moment'
import t from 'prop-types'
import { Icon } from 'semantic-ui-react'

import { missionActionLabel, missionActionIcon } from '~/utils/feedsActions'

export default function Markers({ index, lat, lng, action, created_at }) {
  return (
    <div key={`key-${index}`}>
      <Popup
        anchor="top"
        tipSize={10}
        offsetTop={30}
        offsetLeft={15}
        closeButton={false}
        latitude={lat}
        longitude={lng}
      >
        <div style={{ fontSize: 13, textAlign: 'center' }}>
          <h5>{missionActionLabel[action]}</h5>
          <p>{moment(created_at).format('DD/MM/YY HH:mm')}</p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps/?q=${lat}, ${lng}`}
            >
              {`(${lat}, ${lng})`}
            </a>
          </p>
        </div>
      </Popup>
      <Marker
        key={`key-${index}`}
        offsetTop={0}
        offsetLeft={0}
        latitude={lat}
        longitude={lng}
      >
        <Icon
          circular
          inverted
          style={{ marginRight: 10 }}
          color="grey"
          name={missionActionIcon[action]}
        />
      </Marker>
    </div>
  )
}

Markers.propTypes = {
  index: t.number,
  lat: t.number,
  lng: t.number,
  action: t.string,
  created_at: t.oneOfType([t.number, t.instanceOf(Date)]),
}
