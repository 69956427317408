import { useState, useCallback } from 'react'

const emptyText = {
  icon: 'check circle outline',
  text: 'Não há nada a ser exibido...',
}

export default function usePaginationTable(
  filters,
  setFilters,
  columnsMapForApiSort
) {
  const [count, setCount] = useState(null)
  const [totalCount, setTotalCount] = useState(0)
  const [activePage, setActivePage] = useState(1)

  const onPageChange = useCallback(
    (_, params) => {
      const { limit } = filters
      const offset = params.activePage * limit - limit

      setFilters({ ...filters, offset })
      setActivePage(params.activePage)
    },
    [filters, setFilters]
  )

  const handleSort = useCallback(
    (order, direction) => {
      setFilters(oldFilters => ({
        ...oldFilters,
        offset: 0,
        direction,
        order: columnsMapForApiSort[order],
      }))
    },
    [setFilters, columnsMapForApiSort]
  )

  const calculatePagination = useCallback(
    dataCount => {
      const newCount = parseInt(dataCount, 0)
      const value = newCount / parseInt(filters.limit, 0)

      setCount(Math.ceil(value))
      setTotalCount(newCount)
      setActivePage(filters.offset / filters.limit + 1)
    },
    [filters]
  )

  return {
    count,
    totalCount,
    activePage,
    calculatePagination,
    handleSort,
    onPageChange,
    emptyText,
  }
}
