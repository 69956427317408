import React from 'react'

import { startOfDay, endOfDay } from 'date-fns'
import t from 'prop-types'
import queryString from 'query-string'
import { Grid } from 'semantic-ui-react'

import filters from '../../helpers/filters'
import CardStatistic from '../CardStatistic'

const Metrics = ({ data, active, onPress, onClickSearch, loading }) => {
  return (
    <Grid columns={4} relaxed padded="vertically" stackable>
      <Grid.Row>
        <Grid.Column>
          <CardStatistic
            loading={loading}
            borderColor="red"
            label="Missões Expirando (Hoje)"
            value={data?.missions_expired_count_today}
            onClick={() =>
              onClickSearch(
                'missions/launched',
                queryString.stringify({
                  offset: 0,
                  limit: 15,
                  iniExpired: Number(startOfDay(new Date())),
                  finExpired: Number(endOfDay(new Date())),
                  direction: 'desc',
                  order: 'm.expired_at',
                })
              )
            }
          />
        </Grid.Column>
        <Grid.Column>
          <CardStatistic
            loading={loading}
            borderColor="red"
            label="Missões Abertas"
            value={data?.missions_opened}
            onClick={() =>
              onClickSearch(
                'missions/opened',
                queryString.stringify({
                  offset: 0,
                  limit: 15,
                  kind: 'opened',
                  direction: 'asc',
                })
              )
            }
          />
        </Grid.Column>
        <Grid.Column>
          <CardStatistic
            loading={loading}
            label="Usuários"
            sublabel={data?.users?.percentage}
            value={`${data?.users?.totalInDb}`}
            onClick={() => onPress(filters[0])}
            borderColor={active === 'u' ? 'blue' : 'grey'}
          />
        </Grid.Column>
        <Grid.Column>
          <CardStatistic
            loading={loading}
            label="Clientes"
            sublabel={data?.customer?.percentage}
            value={data?.customer?.totalInDb}
            onClick={() => onPress(filters[1])}
            borderColor={active === 'c' ? 'blue' : 'grey'}
          />
        </Grid.Column>

        <Grid.Column>
          <CardStatistic
            loading={loading}
            label="Missões Lançadas"
            sublabel={data?.missionLaunched?.percentage}
            value={data?.missionLaunched?.totalInDb}
            onClick={() => onPress(filters[5])}
            borderColor={active === 'ml' ? 'blue' : 'grey'}
          />
        </Grid.Column>
        <Grid.Column>
          <CardStatistic
            loading={loading}
            label="Missões Concluídas"
            sublabel={data?.missionFinished?.percentage}
            value={data?.missionFinished?.totalInDb}
            onClick={() => onPress(filters[4])}
            borderColor={active === 'mc' ? 'blue' : 'grey'}
          />
        </Grid.Column>
        <Grid.Column>
          <CardStatistic
            loading={loading}
            label="Missões Aprovadas"
            sublabel={data?.missionApproved?.percentage}
            value={data?.missionApproved?.totalInDb}
            onClick={() => onPress(filters[7])}
            borderColor={active === 'ma' ? 'blue' : 'grey'}
          />
        </Grid.Column>
        <Grid.Column>
          <CardStatistic
            loading={loading}
            label="Missões Reprovadas"
            sublabel={data?.missionReproved?.percentage}
            value={data?.missionReproved?.totalInDb}
            onClick={() => onPress(filters[6])}
            borderColor={active === 'mr' ? 'blue' : 'grey'}
          />
        </Grid.Column>

        <Grid.Column>
          <CardStatistic
            loading={loading}
            label="Missões Lançadas (Hoje)"
            value={data?.missions_created_today}
            borderColor="blue"
            onClick={() =>
              onClickSearch(
                'missions/launched',
                queryString.stringify({
                  offset: 0,
                  limit: 15,
                  kind: 'started',
                  iniCreate: Number(startOfDay(new Date())),
                  finCreate: Number(endOfDay(new Date())),
                  direction: 'desc',
                  order: 'm.created_at',
                })
              )
            }
          />
        </Grid.Column>
        <Grid.Column>
          <CardStatistic
            loading={loading}
            label="Missões Iniciadas (Hoje)"
            value={data?.missions_started_count_today}
            borderColor="blue"
            onClick={() =>
              onClickSearch(
                'missions/opened',
                queryString.stringify({
                  offset: 0,
                  limit: 15,
                  kind: 'started',
                  initial: Number(startOfDay(new Date())),
                  final: Number(endOfDay(new Date())),
                })
              )
            }
          />
        </Grid.Column>
        <Grid.Column>
          <CardStatistic
            loading={loading}
            label="Missões Concluídas (Hoje)"
            value={data?.missions_finalized_count_today}
            borderColor="yellow"
            onClick={() =>
              onClickSearch(
                'missions/opened',
                queryString.stringify({
                  offset: 0,
                  limit: 15,
                  kind: 'finished',
                  direction: 'asc',
                  initial: Number(startOfDay(new Date())),
                  final: Number(endOfDay(new Date())),
                })
              )
            }
          />
        </Grid.Column>
        <Grid.Column>
          <CardStatistic
            loading={loading}
            label="Missões Aprovadas (Hoje)"
            value={data?.registers_approved_count_today}
            borderColor="green"
            onClick={() =>
              onClickSearch(
                'registers',
                queryString.stringify({
                  offset: 0,
                  limit: 15,
                  initial: Number(startOfDay(new Date())),
                  final: Number(endOfDay(new Date())),
                  status: 1,
                  order: 'r.updated_at',
                  direction: 'desc',
                })
              )
            }
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

Metrics.propTypes = {
  onPress: t.func,
  loading: t.bool,
  active: t.string,
  onClickSearch: t.func,
  data: t.oneOfType([t.array, t.object]),
}

export default Metrics
