/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import t from 'prop-types'
import { Button } from 'semantic-ui-react'

export const Rating = ({ items, loading, onSubmitRating }) => {
  const [ratingOrderValues, setRatingOrderValues] = useState([])
  const [itemsRating, setItemsRating] = useState([])

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    const questions = result.map((i, index) => ({ ...i, order: index + 1 }))
    setItemsRating(questions)
    return questions
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    const newItems = reorder(
      itemsRating,
      result.source.index,
      result.destination.index
    )

    const mapped = newItems.map(itemRating => itemRating.uid)

    setItemsRating(newItems)
    setRatingOrderValues(mapped)
  }

  const grid = 2

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'lightgreen' : '#f1f2f3',
    ...draggableStyle,
  })

  const getListStyle = isDraggingOver => ({
    width: '100%',
    padding: grid,
    background: isDraggingOver ? 'lightblue' : '#f1f2f3',
  })

  useEffect(() => {
    setItemsRating(items)
  }, []) // eslint-disable-line

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(providedDroppable, snapshotDroppable) => (
            <div
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
              style={getListStyle(snapshotDroppable.isDraggingOver)}
            >
              {itemsRating.map((itemParam, index) => (
                <Draggable
                  index={index}
                  key={itemParam.uid}
                  draggableId={itemParam.uid}
                >
                  {(providedDraggable, snapshotDraggable) => (
                    <div
                      ref={providedDraggable.innerRef}
                      {...providedDraggable.draggableProps}
                      {...providedDraggable.dragHandleProps}
                      style={getItemStyle(
                        snapshotDraggable.isDragging,
                        providedDraggable.draggableProps.style
                      )}
                    >
                      {itemParam.title}
                    </div>
                  )}
                </Draggable>
              ))}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        color="green"
        loading={loading}
        onClick={() => onSubmitRating(ratingOrderValues)}
        style={{ marginTop: 10 }}
      >
        Salvar
      </Button>
    </>
  )
}

Rating.propTypes = {
  items: t.arrayOf(t.objectOf(t.any)),
  loading: t.bool,
  onSubmitRating: t.func,
}
