import React from 'react'

import t from 'prop-types'
import { Button, Input, Modal } from 'semantic-ui-react'

function CloseRequest({ open, onClose, onOpen, onClick, onChange }) {
  return (
    <Modal open={open} onOpen={onOpen} onClose={onClose} size="mini">
      <Modal.Header>Requsição de Fechamento</Modal.Header>
      <Modal.Content>
        <Modal.Description>Valor do Imposto</Modal.Description>
        <Input placeholder="Ex.: 5" onChange={onChange} /> %
      </Modal.Content>
      <Modal.Actions>
        <Button content="Gerar Fechamento" onClick={onClick} color="orange" />
        <Button content="Sair" onClick={onClose} color="black" />
      </Modal.Actions>
    </Modal>
  )
}

CloseRequest.propTypes = {
  open: t.bool,
  onClose: t.func,
  onOpen: t.func,
  onClick: t.func,
  onChange: t.func,
}

export default CloseRequest
