import React from 'react'

import t from 'prop-types'
import { Button, Card } from 'semantic-ui-react'

export function Info({ place, onClick }) {
  return (
    <Card fluid>
      <Card.Content>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Card.Header>Local</Card.Header>
            <Card.Description>{place || 'Não Localizada'}</Card.Description>
          </div>
          <Button basic onClick={onClick}>
            Ir até o Local
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

Info.propTypes = {
  place: t.string,
  onClick: t.func,
}
