import React from 'react'
import { useHistory } from 'react-router-dom'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import t from 'prop-types'
import { Table, Icon, Label, Dropdown } from 'semantic-ui-react'

dayjs.extend(relativeTime)

const Item = ({ item, onEdit, onInactived, onResend, onGeneratePassword }) => {
  const history = useHistory()

  return (
    <Table.Row key={item.uid}>
      <Table.Cell>{item.responsible}</Table.Cell>
      <Table.Cell>{item.name}</Table.Cell>
      <Table.Cell>{item.cnpj}</Table.Cell>
      <Table.Cell>{item.email}</Table.Cell>
      <Table.Cell>
        {dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>
        {dayjs(item.updated_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Label color={item.email_received ? 'green' : 'yellow'}>
          <Icon name="mail" />
          {item.email_received ? 'Recebido' : 'Aguardando'}
        </Label>
      </Table.Cell>
      <Table.Cell>
        <Dropdown icon="ellipsis vertical">
          <Dropdown.Menu direction="left">
            {!item.status && !item.is_secure && (
              <Dropdown.Item
                icon="refresh"
                text="Reenviar Convite"
                onClick={() =>
                  onResend({ id: item.uid, email: item.email, role: 0 })
                }
              />
            )}
            <Dropdown.Item
              text="Editar Dados"
              icon="edit"
              onClick={() =>
                onEdit({
                  uid: item.uid,
                  cnpj: item.cnpj,
                  name: item.name,
                  phone: item.phone,
                  email: item.email,
                  responsible: item.responsible,
                  is_private: item.is_private,
                })
              }
            />
            <Dropdown.Item
              text="Editar Configurações"
              icon="edit"
              onClick={() => history.push(`customers/${item.uid}`)}
            />
            <Dropdown.Item
              icon="remove"
              text="Desativar Cliente"
              style={{ color: 'red' }}
              onClick={() => onInactived(item)}
            />
            {item.status && item.email_received && (
              <Dropdown.Item
                icon="lock"
                text="Gerar Senha Interna"
                onClick={() => onGeneratePassword(item)}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  item: t.objectOf(t.any),
  onEdit: t.func,
  onResend: t.func,
  onInactived: t.func,
  onGeneratePassword: t.func,
}

export default Item
