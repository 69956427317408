/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useRef } from 'react'

import t from 'prop-types'
import { isEmpty, isNil } from 'ramda'
import { Checkbox, Input, Select, Button } from 'semantic-ui-react'

import api from '~/services/api'
import Message from '~/utils/messages'
import { QUESTIONS_TYPES } from '~/utils/types'

import { Rating } from './components/Rating'
import { Container } from './styles'

const { MULTIPLE, SELECT_LIST, CAMERA, RATING } = QUESTIONS_TYPES

const EditFields = ({ item, getFormsData, missionsUsersId }) => {
  const myRef = useRef()

  const [items, setItems] = useState([])
  const [updateValue, setUpdateValue] = useState({ value: '' })
  const [checkValues, setCheckValues] = useState([])
  const [loading, setLoading] = useState(false)

  function onChange(e, { value }) {
    return setUpdateValue({ ...updateValue, value })
  }

  function onChangeMultipleValues(e, { value }) {
    return setUpdateValue(prevState => ({
      ...prevState,
      value: value.title,
      id: value.uid,
    }))
  }

  function handleClickCheckbox(e, { value }) {
    const index = checkValues.findIndex(i => i === value)

    if (index >= 0) {
      return setCheckValues(checkValues.filter(v => v !== value))
    }

    return setCheckValues(prevState => [...prevState, value])
  }

  async function onSubmit() {
    try {
      if (isEmpty(updateValue.value) || isNil(updateValue.value)) {
        throw new Error('Resposta não pode estar vazia!')
      }

      setLoading(true)

      const res = await api.put(
        '/forms-data/item/update',
        {
          uid: item.forms_data_id,
          answer: updateValue.value,
          forms_items_questions_multiples_id: updateValue.id || null,
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }
      setLoading(false)
      getFormsData()
      return Message().success('Resposta alterada com sucesso!')
    } catch (error) {
      setLoading(false)
      return Message().error(error.message)
    }
  }

  async function onSubmitSelect() {
    try {
      if (checkValues.length === 0) {
        throw new Error('Envie pelo menos uma opção!')
      }

      setLoading(true)

      const res = await api.post(
        '/forms-data/items/update',
        {
          missionsUsersId,
          answers: checkValues,
          userId: item.user_id,
          id: item.forms_data_id,
          formsItemsId: item.forms_items_id,
          formsMissionsId: item.forms_missions_id,
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }
      setLoading(false)
      getFormsData()
      return Message().success('Resposta alterada com sucesso!')
    } catch (error) {
      setLoading(false)
      return Message().error(error.message)
    }
  }

  async function onSubmitRating(ratingOrderValues) {
    try {
      if (ratingOrderValues.length === 0) {
        throw new Error('Não houveram alterações para serem enviadas')
      }
      setLoading(true)

      const res = await api.put(
        '/forms-data/item/update',
        { ratingOrderValues, item },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }
      setLoading(false)
      getFormsData()
      return Message().success('Resposta alterada com sucesso!')
    } catch (error) {
      setLoading(false)
      return Message().error(error.message)
    }
  }

  async function getItems() {
    try {
      const res = await api.get(
        `/forms-items/multiple/${item.forms_items_id}`,
        {
          validateStatus() {
            return true
          },
        }
      )

      const answers = await api.get('/forms-data/admin/answers', {
        params: {
          missionsUsersId,
          formsItemsId: item.forms_items_id,
        },
        validateStatus() {
          return true
        },
      })

      const listAnswers = answers.data.map(a => a.title)

      res.data.forEach(data => {
        listAnswers.forEach(textAnswer => {
          if (data.title === textAnswer) {
            setCheckValues(prevState => [...prevState, data])
          }
        })
      })

      return setItems(res.data)
    } catch (error) {
      return Message().error(error.message)
    }
  }

  async function handleUpload(e) {
    const { files } = e.target

    const filesUploaded = await Promise.all(
      Object.keys(files).map((i, index) => ({
        file: files[i],
        type: files[i].type,
        path: `forms-items/${missionsUsersId}/${item.forms_data_id}/${
          item.forms_data_id
        }_${Date.now()}_${index}.png`,
      }))
    )

    return handleSubmitImage(filesUploaded)
  }

  async function uploadFiles({ file, path, type }) {
    return new Promise(resolve => {
      const formDataFile = new FormData()

      formDataFile.append('file', file)
      formDataFile.append('type', type)
      formDataFile.append('path', path)

      setTimeout(async () => {
        try {
          const res = await api.post('/upload/generate-url', formDataFile, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            timeout: 300000,
            validateStatus() {
              return true
            },
          })

          if (res.status !== 200) {
            return Message().error(res.data.message)
          }

          resolve({ path: res.data.url })
        } catch (error) {
          return Message().error(error.message)
        }
      }, 1000)
    })
  }

  async function handleSubmitImage(filesUploaded) {
    try {
      let files = []
      setLoading(true)

      const paths = filesUploaded.map(
        f =>
          new Promise(resolve => {
            ;(async () => {
              const res = await uploadFiles(f)

              files = [...files, { path: res.path }]

              resolve(true)
            })()
          })
      )

      await Promise.all(paths)

      const submitResponse = await api.put(
        '/forms-data/item/update',
        {
          uid: item.forms_data_id,
          answer: JSON.stringify(files),
          forms_items_questions_multiples_id: null,
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (submitResponse.status !== 200) {
        throw new Error(submitResponse.data.message)
      }

      setLoading(false)
      getFormsData()
      return Message().success('Resposta alterada com sucesso!')
    } catch (error) {
      setLoading(false)
      return Message().error(error.message)
    }
  }

  const SelectItem = () => (
    <>
      {items.map(v => (
        <Checkbox
          key={v.title}
          label={v.title}
          value={v}
          style={{ marginBottom: 10 }}
          onChange={handleClickCheckbox}
          checked={checkValues.includes(v)}
        />
      ))}
      <Button
        color="green"
        loading={loading}
        onClick={onSubmitSelect}
        style={{ marginTop: 10 }}
      >
        Salvar
      </Button>
    </>
  )

  const Multiple = () => (
    <>
      <h4 style={{ marginBottom: 10 }}>{updateValue.value || ''}</h4>
      <Select
        placeholder="Escolha Opção"
        value={updateValue.value || ''}
        onChange={onChangeMultipleValues}
        options={items.map(v => ({
          key: v.title,
          value: v,
          text: v.title,
        }))}
      />
      <Button
        color="green"
        loading={loading}
        onClick={onSubmit}
        style={{ marginTop: 10 }}
      >
        Salvar
      </Button>
    </>
  )

  const InputValue = () => (
    <>
      <Input
        name="answer"
        onChange={onChange}
        autoFocus
        placeholder="Resposta"
        value={updateValue.value || ''}
      />
      <Button
        color="green"
        loading={loading}
        onClick={onSubmit}
        style={{ marginTop: 10 }}
      >
        Salvar
      </Button>
    </>
  )

  const UploadButton = () => (
    <>
      <input
        multiple
        ref={myRef}
        type="file"
        id="uploadImage"
        accept="image/*"
        onChange={handleUpload}
        style={{ display: 'none' }}
      />
      <Button
        icon="upload"
        content="Upload"
        loading={loading}
        labelPosition="right"
        onClick={() => myRef.current.click()}
      />
    </>
  )

  const FieldsValues = () => {
    if (item.type === MULTIPLE) {
      return <Multiple />
    }

    if (item.type === SELECT_LIST) {
      return <SelectItem />
    }

    if (item.type === CAMERA) {
      return <UploadButton />
    }

    if (item.type === RATING) {
      return (
        <Rating
          loading={loading}
          items={items}
          setItems={setItems}
          onSubmitRating={onSubmitRating}
        />
      )
    }

    return <InputValue />
  }

  useEffect(() => {
    if (
      item.type === MULTIPLE ||
      item.type === SELECT_LIST ||
      item.type === RATING
    ) {
      getItems()
    }
  }, []) //eslint-disable-line

  return (
    <Container>
      <FieldsValues />
    </Container>
  )
}

EditFields.propTypes = {
  item: t.objectOf(t.any),
  getFormsData: t.func,
  missionsUsersId: t.string,
}

export default EditFields
