import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { format } from 'date-fns'
import queryString from 'query-string'
import { isNil, cond, equals, always, length, isEmpty } from 'ramda'

import RegisterFilter from '~/components/RegisterFilter'
import RegisterHeaderData from '~/components/RegisterHeaderData'
import TableComponent from '~/components/Table'
import api, { baseURL } from '~/services/api'
import {
  findUfsMission,
  findCitiesMission,
} from '~/store/modules/campaign/actions'
import { dashboard } from '~/store/modules/dashboard/actions'
import { getAll } from '~/store/modules/registers/actions'
import Message from '~/utils/messages'

import Main from '../../Main'
import { Item } from './Item'

const columns = [
  'COD',
  'Cliente',
  'ID',
  'Status',
  'Nome',
  'Sub Categoria',
  'Missão',
  'Valor Moeda (R$)',
  'Valor Pontos',
  'Criado Em',
  'Atualizado Em',
  'Ações',
]

const status = cond([
  [equals(0), always('Aguardando Avaliação')],
  [equals(1), always('Aprovado')],
  [equals(2), always('Reprovado')],
  [equals(3), always('Validando Dados')],
  [equals(4), always('Em Correção')],
])

const direc = {
  0: 'asc',
  1: 'desc',
  2: 'desc',
  3: 'asc',
  4: 'asc',
}

const startQuery = {
  limit: 15,
  offset: 0,
  status: 3,
  final: null,
  initial: null,
  search: '',
  uf: [],
  city: [],
  ufsList: [],
  activePage: 1,
  citiesList: [],
  categories: [],
  subCategories: [],
  customerId: '',
  missionsMainId: '',
}

class Registers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...startQuery,

      status: 3,
      totalCount: 0,
      siblingRange: 1,
      boundaryRange: 0,
      showEllipsis: true,
      showFirstAndLastNav: true,
      showPreviousAndNextNav: true,

      isLoading: false,
      missionLoading: '',

      errors: [],
      isFilterLoading: false,

      search: 'status=3&offset=0&limit=15',
    }
  }

  componentDidMount() {
    this.onRefresh()
    this.startQuery()

    const location = queryString.parse(this.props.location.search)

    if (!this.state.categoryId && location.categoryId) {
      this.getSubcategories(location.categoryId)
      this.setState({
        categoryId: location.categoryId,
        missionsMainId: location.missionsMainId,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.ufs !== this.props.ufs) {
      this.handlerList(nextProps.ufs, 'ufsList')
    }

    if (nextProps.cities !== this.props.cities) {
      this.handlerList(nextProps.cities, 'citiesList')
    }

    if (nextProps.subCategoriesAll !== this.props.subCategoriesAll) {
      this.handlerList(nextProps.subCategoriesAll, 'subCategories')
    }

    if (nextProps.registers !== this.props.registers) {
      this.calculatePagination(nextProps.registers)
    }
  }

  async getCustomers() {
    try {
      const res = await api.get('customers/admin', {
        params: { limit: 1000, offset: 0 },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return this.setState(prevState => ({
        ...prevState,
        customers: res.data.customers.map(c => ({
          text: c.name,
          value: c.uid,
          key: c.uid,
        })),
      }))
    } catch (error) {
      return Message().error(error.message)
    }
  }

  resetFilter = () => {
    this.props.history.push({ search: '?limit=15&offset=0&status=3' })

    const search = queryString.stringify(startQuery)

    this.setState({
      ...startQuery,
      categoryId: '',
    })
    this.props.getAll(search)
    this.onRefresh()
  }

  handlerChange = (value, key) => {
    if (key === 'categoryId') {
      this.handlerQuery(key, value)
      this.setState(prevState => ({ ...prevState, [key]: value }))
      return this.getSubcategories(value)
    }

    if (key === 'uf') {
      const ufs = value.join(', ')
      this.props.findCitiesMission(`ufs=${ufs}`)
      this.handlerQuery(key, ufs)
      return this.setState(prevState => ({ ...prevState, [key]: ufs }))
    }

    if (key === 'initial' || key === 'final') {
      this.handlerQuery(key, new Date(value).getTime())
      return this.setState(prevState => ({ ...prevState, [key]: value }))
    }

    this.handlerQuery(key, value)
    return this.setState(prevState => ({ ...prevState, [key]: value }))
  }

  handlerQuery = (target, value) => {
    const queries = queryString.parse(this.props.location.search)
    const handlerQueries = { ...queries, [target]: value }
    const search = queryString.stringify(handlerQueries)
    this.props.history.replace({
      search,
      pahtname: this.props.location.pathname,
    })
  }

  handlerRegisterDetail = id => {
    const queries = queryString.parse(this.props.location.search)
    const search = queryString.stringify(queries)
    this.props.history.push(`register/${id}`, { search })
  }

  submitExport = async () => {
    const search = queryString.stringify({
      status: Number(this.state.status),
      xlsx: true,
    })

    try {
      const res = await api.get(`registers/admin?${search}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Solicitação enviada com sucesso')
    } catch (error) {
      return Message().error(error.message)
    }
  }

  submitFilterRegister = () => {
    const { initial, final } = queryString.parse(this.props.location.search)
    const parsedQuery = {
      ...queryString.parse(this.props.location.search),
      limit: Number(queryString.parse(this.props.location.search).limit),
    }
    const queries = {
      ...parsedQuery,
      status: Number(this.state.status),
      offset: 0,
      order: 'r.created_at',
      direction: direc[Number(this.state.status)],
      limit: parsedQuery.limit > 15 ? parsedQuery.limit : 15,
    }

    if (queries.status === 1 || queries.status === 0) {
      Object.assign(queries, { order: 'r.updated_at' })
    }

    if (!isNil(initial) && !isNil(final)) {
      Object.assign(queries, {
        initial: Number(initial),
        final: Number(final),
      })
    }

    const search = queryString.stringify(queries)
    this.setState({ activePage: 1, ...queries })
    this.props.history.replace({ search })
    this.props.getAll(search)
  }

  handlePaginationChange = (e, { activePage }) => {
    const newOffset = (activePage - 1) * this.state.limit
    this.handlerQuery('offset', Number(newOffset))
    const queries = queryString.parse(this.props.location.search)
    const search = queryString.stringify({
      ...queries,
      offset: newOffset,
      limit: this.state.limit,
    })
    this.setState({ activePage, offset: Number(newOffset) })
    this.props.getAll(search)
  }

  calculatePagination = props => {
    if (props.success) {
      const newCount = parseInt(props.count, 0)
      const value = newCount / this.state.limit
      const currentCount =
        this.state.limit < newCount
          ? props.data.length + this.state.limit * (this.state.activePage - 1)
          : newCount

      this.setState({
        totalCount: newCount,
        registers: props.data,
        count: Math.ceil(value),
        actualCount: currentCount,
      })
    }
  }

  getCategories = async () => {
    try {
      const res = await api.get('categories', {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return this.setState({ categories: res.data.categories })
    } catch (error) {
      return Message().error(error.message)
    }
  }

  getSubcategories = async categoryId => {
    try {
      const res = await api.get('missions-main', {
        params: {
          categoryId,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return this.setState({ subCategories: res.data.missionsMains })
    } catch (error) {
      return Message().error(error.message)
    }
  }

  handleReceipt = async id => {
    try {
      this.setState({ isLoading: true })

      const res = await api.put(`reports/registers-user-receipt/${id}`, null, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      this.setState({ isLoading: false })

      window.open(res.data.register.url_user_mission_receipt, '_self')

      return Message().success('Exportação realizada com sucesso!')
    } catch (error) {
      this.setState({ isLoading: false })
      return Message().error(error.message)
    }
  }

  handlerList = (props, key) => {
    if (props.success) {
      const newArray = props.data.map((item, index) => ({
        key: index,
        value: item.uid || item.uf || item,
        text: item.name || item.title || item,
      }))
      this.setState({ [key]: newArray })
    }
  }

  handlerRegisters = props => {
    if (props.success) {
      this.setState({ registers: props.data })
      props.success = false
    } else if (props.error) {
      alert(props.message)
      props.error = false
    }
  }

  onRefresh = () => {
    this.getCategories()
    this.getCustomers()
    this.props.dashboard()
    this.props.findUfsMission()
  }

  startQuery = () => {
    const defaultQuery = 'limit=15&offset=0&status=3'
    const propSearch = queryString.parse(this.props.location.search)
    const query =
      this.props.location.search === ''
        ? defaultQuery
        : queryString.stringify(propSearch)
    const parsedQuery = queryString.parse(query)
    const search = {
      ...parsedQuery,
      limit: Number(parsedQuery.limit),
      status: Number(parsedQuery.status),
      initial: Number(parsedQuery.initial),
      final: Number(parsedQuery.final),
    }

    this.setState({
      ...search,
      search: query,
    })

    this.props.history.replace({ search: query })
    this.props.getAll(query)
  }

  dateParse = dateItem => {
    const date = new Date(dateItem)
    const isoDate = date.toISOString()
    const dateFormat = format(new Date(dateItem), 'dd/MM/yyyy')
    const time = isoDate.substr(11, 8)

    return `${dateFormat} ${time}`
  }

  submitFilter = () => {
    this.setState({ isFilterLoading: true })

    const { startDate, endDate } = this.state

    if (isEmpty(startDate)) {
      this.setState({
        isFilterLoading: false,
        errors: this.state.errors.concat('Data Inicial não pode estar vazia!'),
      })
      return
    }

    if (isEmpty(endDate)) {
      this.setState({
        isFilterLoading: false,
        errors: this.state.errors.concat('Data Final não pode estar vazia!'),
      })
      return
    }

    const params = `?initial=${new Date(startDate).getTime()}&final=${new Date(
      endDate
    ).getTime()}`

    window.open(
      `${baseURL}${this.props.user.profile.url_integration_export}${params}`,
      '_self'
    )

    setTimeout(() => {
      this.setState({ errors: [], isFilterLoading: false })
    }, 2000)
  }

  sortedRequest = (order, direction) => {
    const { initial, final } = this.state
    const search =
      !!initial && !!final
        ? {
            ...queryString.parse(this.state.search),
            order,
            direction,
            status: Number(this.state.status),
            initial: Number(initial),
            final: Number(final),
          }
        : {
            ...queryString.parse(this.state.search),
            order,
            direction,
            status: Number(this.state.status),
          }

    if (this.state.categoryId) {
      search.categoryId = this.state.categoryId
    }

    if (this.state.uf) {
      search.uf = this.state.uf
    }

    if (this.state.missionsMainId) {
      search.missionsMainId = this.state.missionsMainId
    }

    const query = queryString.stringify(search)
    this.setState(search)
    this.props.history.replace({ search: query })
    this.props.getAll(query)
  }

  handleSort = (order, direction) => {
    let column = order
    switch (column) {
      case 'Cliente':
        column = 'c.name'
        this.sortedRequest(column, direction)
        break
      case 'Status':
        column = 'r.status'
        this.sortedRequest(column, direction)
        break
      case 'Nome':
        column = 'u.name'
        this.sortedRequest(column, direction)
        break
      case 'Sub Categoria':
        column = 'mm.title'
        this.sortedRequest(column, direction)
        break
      case 'Missão':
        column = 'm.name'
        this.sortedRequest(column, direction)
        break
      case 'Criado Em':
        column = 'r.created_at'
        this.sortedRequest(column, direction)
        break
      case 'Atualizado Em':
        column = 'r.updated_at'
        this.sortedRequest(column, direction)
        break
      default:
    }
  }

  render() {
    return (
      <Main {...this.props}>
        <RegisterFilter
          {...this.state}
          {...this}
          resetFilter={this.resetFilter}
        />
        <RegisterHeaderData
          handleChange={this.handlerChange}
          status={this.state.status}
          data={this.props.dataCustomer.data}
        />
        {length(this.state.errors) > 0 && (
          <Message negative header="Alerta!" list={this.state.errors} />
        )}
        <TableComponent
          {...this.state}
          columns={columns}
          data={this.state.registers}
          renderItem={register => (
            <Item
              register={register}
              loading={this.state.isLoading}
              onReceipt={this.handleReceipt}
              onRegisterDetail={this.handlerRegisterDetail}
            />
          )}
          handleColumnSort={this.handleSort}
          isLoading={this.props.registers.isFetching}
          handlePaginationChange={this.handlePaginationChange}
          emptyText={{
            icon: 'check circle outline',
            text: `Nenhum Registro ${status(this.state.status)}...`,
          }}
        />
      </Main>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  ufs: state.ufsMissions,
  cities: state.citiesMissions,
  registers: state.registers,
  dataCustomer: state.dashboard,
})

export default connect(mapStateToProps, {
  getAll,
  dashboard,
  findUfsMission,
  findCitiesMission,
})(withRouter(Registers))
