import React from 'react'

import t from 'prop-types'
import { Icon, Segment, Message, Header } from 'semantic-ui-react'

export default function Files({
  rows,
  isDragActive,
  getRootProps,
  getInputProps,
}) {
  return (
    <div style={{ marginTop: 10 }}>
      {rows.length === 0 && (
        <div className="field">
          <label>Arquivo .XLSX (Obrigatório)</label>
        </div>
      )}

      {rows.length > 0 && (
        <Message positive>
          <Message.Header>Arquivo Pronto!</Message.Header>
          <p>
            Está tudo certo com o arquivo, clique em &quot;Importar&quot; para
            prosseguir com a importação
          </p>
        </Message>
      )}

      {rows.length === 0 && (
        <div
          {...getRootProps()}
          style={{
            padding: 10,
            marignTop: 10,
            borderWidth: 1,
            borderColor: '#b9b9b9',
            borderStyle: 'dashed',
          }}
        >
          <Segment placeholder textAlign="center" className="overflow">
            <Header icon>
              <Icon name="file excel outline" />
              {isDragActive ? (
                <p>Solte o arquivo aqui...</p>
              ) : (
                <p>
                  Arraste e solte os arquivos aqui ou clique para selecioná-los
                </p>
              )}
              <input {...getInputProps()} />
            </Header>
          </Segment>
        </div>
      )}
    </div>
  )
}

Files.propTypes = {
  rows: t.arrayOf(t.any),
  isDragActive: t.bool,
  getRootProps: t.func,
  getInputProps: t.func,
}
