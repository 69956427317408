import React, { memo } from 'react'
import DatePicker from 'react-datepicker'

import t from 'prop-types'
import { Select, Button, Card, Form, Input } from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'

function CustomerFilter({
  filters,
  isLoading,
  clearFilters,
  onChangeFilters,
  onCreate,
}) {
  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Nome"
              value={filters.name || ''}
              onChange={(e, { value }) => onChangeFilters('name', value)}
            />

            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Telefone"
              value={filters.phone || ''}
              onChange={(e, { value }) => onChangeFilters('phone', value)}
            />

            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Limite"
              value={filters.customer_limit || ''}
              onChange={(e, { value }) =>
                onChangeFilters('customer_limit', value)
              }
            />

            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Saldo Atual"
              value={filters.amount || ''}
              onChange={(e, { value }) => onChangeFilters('amount', value)}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Potencial de Faturamento"
              value={filters.billing_estimated || ''}
              onChange={(e, { value }) =>
                onChangeFilters('billing_estimated', value)
              }
            />
            <Form.Field>
              <div className="ui input">
                <DatePicker
                  style={{ width: '100%' }}
                  id="contract_start"
                  selectsStart
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Contrato"
                  selected={filters.contract_start || ''}
                  onChange={value => onChangeFilters('contract_start', value)}
                />
              </div>
            </Form.Field>

            <Form.Field
              control={Select}
              placeholder="Situação"
              selection
              value={filters.situation || ''}
              options={[
                { key: 0, text: 'Todos', value: undefined },
                { key: 1, text: 'Ativo', value: 'Ativo' },
                { key: 2, text: 'On Hold', value: 'On Hold' },
                { key: 3, text: 'Inativo', value: 'Inativo' },
              ]}
              onChange={(e, { value }) => onChangeFilters('situation', value)}
            />

            <Form.Field
              control={Select}
              placeholder="Tipo de Pagamento"
              selection
              value={filters.customer_payment_type || ''}
              options={[
                { key: 0, text: 'Todos', value: undefined },
                { key: 1, text: 'Pós-pago Normal', value: 1 },
                { key: 2, text: 'Pós-pago Desonerado', value: 2 },
                { key: 3, text: 'Pré-pago Normal', value: 3 },
                { key: 4, text: 'Pré-pago Desonerado', value: 4 },
                { key: 6, text: 'Licenciamento', value: 6 },
              ]}
              onChange={(e, { value }) =>
                onChangeFilters('customer_payment_type', value)
              }
            />
          </Form.Group>
        </Form>
      </Card.Content>

      <Card.Content>
        <Button
          color="orange"
          icon="user plus icon"
          content="Criar Conta"
          labelPosition="left"
          onClick={onCreate}
          loading={isLoading}
        />
        <Button
          icon="undo"
          color="grey"
          content="Limpar Filtros"
          labelPosition="left"
          onClick={clearFilters}
          loading={isLoading}
        />
      </Card.Content>
    </Card>
  )
}

CustomerFilter.propTypes = {
  filters: t.objectOf(t.any),
  isLoading: t.bool,
  clearFilters: t.func,
  onCreate: t.func,
  onChangeFilters: t.func,
}

export default memo(CustomerFilter)
