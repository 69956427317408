import { useCallback, useState } from 'react'

import dayjs from 'dayjs'
import * as Yup from 'yup'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useWorkforce() {
  const [loadingWorkforce, setLoadingWorkforce] = useState(false)
  const [users, setUsers] = useState({})
  const [adherence, setAdherence] = useState({})
  const [dateList, setDateList] = useState([])
  const [adherenceGraphicBar, setAdherenceGraphicBar] = useState([])
  const [adherenceGraphicPizza, setAdherenceGraphicPizza] = useState([])
  const [totalRegisters, setTotalRegisters] = useState({})
  const [ratings, setRatings] = useState({})

  const validateIfInformedParamsRequired = params => {
    const schema = Yup.object().shape({
      customerId: Yup.string().required('O Cliente precisa ser selecionado'),
      startDate: Yup.string().required(
        'A Data Inicial precisa ser selecionada'
      ),
      finalDate: Yup.string().required('A Data Final precisa ser selecionada'),
    })

    const isValid = schema.isValidSync(params)

    if (!isValid) {
      const validate = schema.validateSync(params)
      throw new Error(validate)
    }
  }

  const exportReport = async params => {
    try {
      setLoadingWorkforce(true)
      validateIfInformedParamsRequired(params)

      const res = await api.get('missions-users/workforce-manager/admin', {
        params,
        responseType: 'blob',
      })

      const href = URL.createObjectURL(res.data)
      const link = document.createElement('a')
      link.href = href
      link.click()
    } catch (error) {
      if (error.response && error.response.status === 409) {
        Message().error('Não há registros para serem exportados.')
      } else {
        Message().error(error.message || 'Oops algo inesperado aconteceu!')
      }
    } finally {
      setLoadingWorkforce(false)
    }
  }

  const getUsers = useCallback(async params => {
    try {
      setLoadingWorkforce(true)
      validateIfInformedParamsRequired(params)

      const res = await api.get('missions-users/workforce-manager/admin', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const arrayAdherenceGraphicBar = []
      res.data.adherenceGraphicBar.labels.forEach((date, idxDate) =>
        arrayAdherenceGraphicBar.push({
          name: dayjs(date).format('DD/MM/YYYY'),
          Aderência: res.data.adherenceGraphicBar.data[idxDate],
        })
      )

      const arrayAdherenceGraphicPizza = []
      res.data.adherenceGraphicPizza.labels.forEach((date, idxDate) =>
        arrayAdherenceGraphicPizza.push({
          name: date,
          value: Number(res.data.adherenceGraphicPizza.data[idxDate]),
          percentage: `${res.data.adherenceGraphicPizza.data[idxDate]}%`,
        })
      )

      setDateList(Object.keys(res.data.missionsUsers))
      setAdherence(res.data.adherence)
      setAdherenceGraphicBar(arrayAdherenceGraphicBar)
      setAdherenceGraphicPizza(arrayAdherenceGraphicPizza)
      setTotalRegisters({
        totalRegistersPerDay: res.data.totalRegistersPerDay,
        totalRegistersInProgressPerDay: res.data.totalRegistersInProgressPerDay,
        totalRegistersMissedPerDay: res.data.totalRegistersMissedPerDay,
      })
      setRatings({
        ratingsPerDay: res.data.ratingsPerDay,
        ratingsMissionsOfUsersPerDay: res.data.ratingsMissionsOfUsersPerDay,
      })

      return setUsers(res.data.missionsUsers)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingWorkforce(false)
    }
  }, [])

  return {
    getUsers,
    exportReport,
    setUsers,
    users,
    loadingWorkforce,
    dateList,
    adherence,
    adherenceGraphicBar,
    adherenceGraphicPizza,
    totalRegisters,
    ratings,
  }
}
