import React from 'react'

import t from 'prop-types'
import { Button, Form, Header, Modal } from 'semantic-ui-react'

function CreateForm({
  showModal,
  dataMultiple,
  onClose,
  onChange,
  submit,
  scoreType,
}) {
  const textScoreTypes = {
    score: 'Pontuação',
    score_rating: 'Classificação',
  }

  function labelScoreType() {
    return scoreType === 'score' ? 'score' : 'score_rating'
  }

  return (
    <Modal open={showModal} onClose={onClose} size="tiny">
      <Modal.Header>
        <Header size="large">Alterar {textScoreTypes[labelScoreType()]}</Header>
      </Modal.Header>

      <Modal.Content>
        <Form>
          <Form.Input
            label={textScoreTypes[labelScoreType()]}
            type="number"
            id={labelScoreType()}
            value={dataMultiple[scoreType]}
            onChange={(e, { value }) => onChange(scoreType, value)}
            placeholder={`Digite a ${
              textScoreTypes[labelScoreType()]
            } desejada para a resposta`}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          size="large"
          color="black"
          content="Cancelar"
          onClick={onClose}
        />
        <Button
          size="large"
          color="orange"
          content="Atualizar"
          onClick={submit}
        />
      </Modal.Actions>
    </Modal>
  )
}

CreateForm.propTypes = {
  showModal: t.bool,
  onClose: t.func,
  onChange: t.func,
  submit: t.func,
  dataMultiple: t.objectOf(t.any),
  scoreType: t.string,
}

export default CreateForm
