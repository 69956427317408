import React, { useEffect } from 'react'

import t from 'prop-types'
import { Grid, Card, Image } from 'semantic-ui-react'

import useMissionsUsersSignature from '~/hooks/useMissionsUsersSignature'

function Signature({ id }) {
  const { feedSignature, getMissionsUsersSignature } =
    useMissionsUsersSignature()

  useEffect(() => {
    getMissionsUsersSignature(id)
  }, []) // eslint-disable-line

  return (
    <Grid columns={4} doubling>
      {feedSignature?.map(item => (
        <Grid.Column>
          <Card key={item.id}>
            <Card.Content>
              <Image src={item.url_signature} size="large" />
            </Card.Content>
          </Card>
        </Grid.Column>
      ))}
    </Grid>
  )
}

Signature.propTypes = {
  id: t.string.isRequired,
}

export default Signature
