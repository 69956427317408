import React from 'react'

import t from 'prop-types'
import { Form, Modal, Button, Input, Checkbox } from 'semantic-ui-react'

const ModalInfo = ({ close, open, customer, onSubmit, onChange, loading }) => (
  <Modal size="small" open={open} onClose={() => open}>
    <Modal.Header>Dados do Cliente</Modal.Header>
    <Modal.Content>
      <Form>
        <Form.Field
          control={Input}
          id="responsible"
          type="text"
          placeholder="Ex: João Silva"
          label="Responsável (Obrigatório)"
          value={customer?.responsible || ''}
          onChange={(e, { value }) => onChange('responsible', value)}
        />
        <Form.Field
          control={Input}
          id="name"
          type="text"
          value={customer?.name || ''}
          onChange={(e, { value }) => onChange('name', value)}
          label="Razão Social (Obrigatório)"
          placeholder="Ex: Mission Brasil LTDA"
        />
        <Form.Field
          control={Input}
          id="email"
          type="email"
          value={customer?.email || ''}
          onChange={(e, { value }) => onChange('email', value)}
          label="Email (Obrigatório)"
          placeholder="Ex: cliente@cliente.com"
        />
        <Form.Field
          control={Input}
          id="cnpj"
          type="number"
          value={customer?.cnpj || ''}
          onChange={(e, { value }) => onChange('cnpj', value)}
          label="CNPJ (Obrigatório)"
          placeholder="Ex: CNPJ Válido"
        />

        <Form.Field
          control={Input}
          id="phone"
          type="number"
          value={customer?.phone || ''}
          label="Telefone (Obrigatório)"
          placeholder="Ex: 48987654321"
          onChange={(e, { value }) => onChange('phone', value)}
        />

        <Form.Field
          control={Checkbox}
          id="is_private"
          checked={customer?.is_private || ''}
          label="Cliente privado"
          onChange={(e, { checked }) => onChange('is_private', checked)}
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button content="Cancelar" onClick={close} />
      <Button
        positive
        icon="check"
        content="Salvar"
        labelPosition="left"
        loading={loading}
        disabled={loading}
        onClick={onSubmit}
      />
    </Modal.Actions>
  </Modal>
)

ModalInfo.propTypes = {
  close: t.func,
  open: t.bool,
  loading: t.bool,
  onSubmit: t.func,
  onChange: t.func,
  customer: t.objectOf(t.any),
}

export default ModalInfo
