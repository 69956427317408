import React from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Table, Dropdown, Label, Image } from 'semantic-ui-react'

import AvatarDefault from '~/assets/avatar.png'
import history from '~/services/history'

import { planColor, planLabel, statusColor, statusLabel } from './labels'

export function TableRow({ item, onOpenMissions }) {
  const hasPhone = item.phone !== null
  const hasAvatar = item.avatar !== null
  const phone = hasPhone ? item.phone.replace('+', '') : 'Número Não Cadastrado'

  return (
    <Table.Row key={item.id}>
      <Table.Cell>
        <Image
          avatar
          size="tiny"
          src={hasAvatar ? item.avatar : AvatarDefault}
        />
      </Table.Cell>
      <Table.Cell>{item.name}</Table.Cell>
      <Table.Cell>{item.email}</Table.Cell>
      <Table.Cell>
        {hasPhone ? (
          <a href={`https://api.whatsapp.com/send/?phone=${phone}`}>{phone}</a>
        ) : (
          phone
        )}
      </Table.Cell>
      <Table.Cell>
        <Label size="large" color={statusColor[item.status]}>
          {statusLabel[item.status]}
        </Label>
      </Table.Cell>
      <Table.Cell>
        <Label size="large" color={planColor[item.unit_label]}>
          {planLabel[item.unit_label]}
        </Label>
      </Table.Cell>
      <Table.Cell>{item.balance}</Table.Cell>
      <Table.Cell>{item.current_amount}</Table.Cell>
      <Table.Cell>
        {dayjs(new Date(item.created_at)).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell negative={item.canceled_at !== null}>
        {dayjs(new Date(item.canceled_at)).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell textAlign="center" style={{ width: 150 }}>
        <Dropdown text="Opções" pointing="right" className="link item">
          <Dropdown.Menu>
            <Dropdown.Item
              icon="eye"
              content="Visualizar Perfil"
              onClick={() => history.push(`user/${item.user_id}`)}
            />
            <Dropdown.Item
              icon="plus"
              content="Adicionar Missão"
              onClick={() => onOpenMissions(item.user_id)}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
    </Table.Row>
  )
}

TableRow.propTypes = {
  item: t.objectOf(t.any),
  onOpenMissions: t.func,
}
