import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactDatePicker from 'react-datepicker'
import { useHistory } from 'react-router-dom'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Modal, List, Image, Button, Popup, Form } from 'semantic-ui-react'

import useMissionsUsers from '~/hooks/useMissionsUsers'
import Message from '~/utils/messages'

function CurrentParticipants({ open, onClose, data }) {
  const history = useHistory()
  const { loadingProgress, revertExpired } = useMissionsUsers()
  const [selectedInfo, setSelectedInfo] = useState(null)
  const [showConfirmRevertExpired, setShowConfirmRevertExpired] =
    useState(false)

  function phoneNotFound() {
    return Message().error('Não há celular cadastrado para este usuário')
  }

  function openWhatsapp(phone) {
    return phone
      ? window.open(
          `https://api.whatsapp.com/send?phone=${phone.replace('+', '')}`
        )
      : phoneNotFound()
  }

  function copyPhone(phone) {
    return phone ? Message().success('Celular copiado!') : phoneNotFound()
  }

  function handleOnClickRevertExpired(info) {
    setSelectedInfo(prevState => ({
      ...prevState,
      uid: info.missionsUsersId,
      expired_at: new Date(info.expired_at),
    }))

    setShowConfirmRevertExpired(true)
  }

  function handleOnChangeDate(key, value) {
    setSelectedInfo(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  function handleOnClickConfirm() {
    revertExpired({
      uid: selectedInfo?.uid,
      expired_at: dayjs(selectedInfo?.expired_at).toISOString(),
    })

    setTimeout(() => {
      setShowConfirmRevertExpired(false)
    }, 500)
  }

  return (
    <>
      <Modal open={open} onClose={onClose} size="tiny">
        <Modal.Header>Participantes na Missão</Modal.Header>
        <Modal.Content>
          <List divided relaxed verticalAlign="middle" size="massive">
            {!data.length ? (
              <List.Item>
                <List.Content>
                  <List.Description>
                    Não há dados para exibição
                  </List.Description>
                </List.Content>
              </List.Item>
            ) : (
              data?.map(info => (
                <List.Item>
                  <Image avatar src={info?.avatar} />
                  <List.Content>
                    <List.Header
                      as="a"
                      onClick={() => history.push(`/user/${info.userId}`)}
                    >
                      {info?.name}
                    </List.Header>
                    <List.Description>
                      Progresso: {info?.missionsProgress}
                    </List.Description>
                    <List.Description>
                      Telefone: {info?.phone}{' '}
                      <Popup
                        content="Copiar Telefone"
                        trigger={
                          <CopyToClipboard
                            text={info?.phone}
                            onCopy={copyPhone}
                          >
                            <Button icon="copy" color="blue" />
                          </CopyToClipboard>
                        }
                      />
                      <Popup
                        content="Abrir Whatsapp"
                        trigger={
                          <Button
                            icon="whatsapp"
                            color="green"
                            onClick={() => openWhatsapp(info?.phone)}
                          />
                        }
                      />
                      <Popup
                        content="Reverter Expiração"
                        trigger={
                          <Button
                            icon="redo"
                            color="orange"
                            onClick={() => handleOnClickRevertExpired(info)}
                          />
                        }
                      />
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))
            )}
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Sair" onClick={onClose} />
        </Modal.Actions>
      </Modal>
      <Modal open={showConfirmRevertExpired} size="mini">
        <Modal.Header>Reverter Expiração de Missão</Modal.Header>
        <Modal.Content>
          <Form loading={loadingProgress}>
            <Form.Field>
              <div
                className="ui input"
                style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
              >
                <strong>Data Expiração</strong>
                <ReactDatePicker
                  style={{ width: '100%' }}
                  id="date"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Selecione Nova Data"
                  selected={selectedInfo?.expired_at || ''}
                  onChange={value => handleOnChangeDate('expired_at', value)}
                />
              </div>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={loadingProgress}
            content="Cancelar"
            onClick={() => setShowConfirmRevertExpired(false)}
          />
          <Button
            disabled={loadingProgress}
            content="Confirmar"
            primary
            onClick={() => handleOnClickConfirm()}
          />
        </Modal.Actions>
      </Modal>
    </>
  )
}

CurrentParticipants.propTypes = {
  open: t.bool,
  onClose: t.func,
  data: t.arrayOf(t.any),
}

export default CurrentParticipants
