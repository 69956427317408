import { useState } from 'react'

import dayjs from 'dayjs'

import api from '~/services/api'
import Message from '~/utils/messages'

import {
  DELIVERY_STATUS,
  DELIVERY_STATUS_TO_DISPLAY,
  INITIAL_PAGE,
} from '../pages/Store/Orders/helpers/types'

export default function useOrder() {
  const [filter, setFilter] = useState({
    deliveryStatus: '',
    initDate: dayjs().set('date', 1).format('YYYY-MM-DD'),
    finalDate: dayjs().format('YYYY-MM-DD'),
  })
  const [trackingCode, setTrackingCode] = useState('')
  const [modelSetTrackingCode, setModelSetTrackingCode] = useState(false)
  const [orders, setOrders] = useState([])
  const [orderSelected, setOrderSelected] = useState(null)
  const [showOrderDetails, setShowOrderDetails] = useState(false)
  const [pagination, setPagination] = useState({
    activePage: INITIAL_PAGE,
    limit: 10,
    total: 10,
    offset: INITIAL_PAGE,
  })
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const [deliveryStatusToUpdate, setDeliveryStatusToUpdate] = useState(null)
  const [modalProblemToSend, setModalProblemToSend] = useState(false)
  const [disclaimerProblemToSend, setDisclaimerProblemToSend] = useState('')

  function openModalProblemToSend(order) {
    setOrderSelected(order)
    setModalProblemToSend(true)
  }

  async function confirmProblemToSend() {
    try {
      if (disclaimerProblemToSend.length === 0) {
        Message().info('É necessário informar o motivo do problema do envio')
        return
      }

      await api.put(`store-products/orders/${orderSelected.id}`, {
        deliveryStatus: DELIVERY_STATUS.PROBLEM_TO_SEND,
        disclaimer: disclaimerProblemToSend,
      })
      Message().success('Pedido atualizado para problema de envio com sucesso')
      setOrderSelected(null)
      setModalProblemToSend(false)
      await getOrders(INITIAL_PAGE, pagination.limit)
    } catch (error) {
      Message().error(error?.response?.data?.message)
    }
  }

  async function confirmUpdateDeliveryStatus() {
    try {
      await api.put(`store-products/orders/${orderSelected.id}`, {
        deliveryStatus: deliveryStatusToUpdate,
      })
      Message().success(
        `Pedido teve a situação atualizada para ${DELIVERY_STATUS_TO_DISPLAY[
          deliveryStatusToUpdate
        ].toLowerCase()} com sucesso`
      )
      setModalConfirmation(false)
      setDeliveryStatusToUpdate(null)
      getOrders(INITIAL_PAGE, pagination.limit)
    } catch (error) {
      Message().error(error?.response?.data.message)
    }
  }

  function openModelSetTrackingCode(item) {
    setOrderSelected(item)
    setModelSetTrackingCode(true)
  }

  async function addTrackingCodeInOrder() {
    try {
      if (trackingCode.length === 0) {
        Message().info('Você precisa informar código de rastreio.')
        return
      }
      await api.put(`store-products/orders/${orderSelected.id}`, {
        trackingCode,
      })
      Message().success('Adicionado código de rastreio com sucesso')
      setModelSetTrackingCode(false)
      setTrackingCode('')
      await getOrders(INITIAL_PAGE, pagination.limit)
    } catch (error) {
      Message().error(error?.response?.data.message)
    }
  }

  async function getOrders({ offset, limit }) {
    const response = await api.get(
      `store-products/admin/orders?page=${offset}&itemsPerPage=${limit}`,
      {
        params: {
          ...filter,
          page: offset,
          itemsPerPage: limit,
          initDate: filter.initDate.length > 0 ? filter.initDate : null,
          finalDate: filter.finalDate.length > 0 ? filter.finalDate : null,
          deliveryStatus:
            filter.deliveryStatus.length > 0 ? filter.deliveryStatus : null,
        },
      }
    )
    setOrders(response.data.data)
    setPagination({
      activePage: response.data.page,
      offset: response.data.page - 1,
      limit: response.data.itemsPerPage,
      total: response.data.total,
    })
  }

  async function changePagination(activePage) {
    setPagination({
      ...pagination,
      activePage,
      offset: activePage,
    })
    await getOrders(activePage, pagination.limit)
  }

  function selectOrder(order) {
    setOrderSelected(order)
    setShowOrderDetails(true)
  }

  function changeInputValue(key, value) {
    setFilter({ ...filter, [key]: value })
  }

  function resetFilter() {
    setFilter({
      deliveryStatus: '',
      initDate: '',
      finalDate: '',
    })
  }

  function openModelConfirmation(order, deliveryStatus) {
    setOrderSelected(order)
    setModalConfirmation(true)
    setDeliveryStatusToUpdate(deliveryStatus)
  }

  return {
    getOrders,
    orders,
    pagination,
    changePagination,
    selectOrder,
    showOrderDetails,
    setShowOrderDetails,
    orderSelected,
    setOrderSelected,
    filter,
    changeInputValue,
    resetFilter,
    addTrackingCodeInOrder,
    trackingCode,
    setTrackingCode,
    modelSetTrackingCode,
    setModelSetTrackingCode,
    openModelSetTrackingCode,
    confirmUpdateDeliveryStatus,
    setModalConfirmation,
    modalConfirmation,
    setDeliveryStatusToUpdate,
    openModelConfirmation,
    openModalProblemToSend,
    confirmProblemToSend,
    modalProblemToSend,
    setModalProblemToSend,
    setDisclaimerProblemToSend,
    disclaimerProblemToSend,
  }
}
