import React, { useEffect, useState } from 'react'

import t from 'prop-types'
import { Button, Confirm, Input, Modal } from 'semantic-ui-react'

import useFinancial from '~/hooks/useFinancial'

export function EditValues({ type, open, setOpen, balance, paid, userId }) {
  const { updateUsersBudgetValues } = useFinancial()

  const [openConfirm, setOpenConfirm] = useState(false)
  const [updateValue, setUpdateValue] = useState(0)

  const headers = {
    paid: 'Pagamentos Aprovados',
    balance: 'Saldo do Usuário',
  }

  function handleOnActionClick(typeAction) {
    if (typeAction === 'cancel') {
      setOpen({ open: false, type })
    }

    if (typeAction === 'update') {
      setOpenConfirm(true)
    }
  }

  useEffect(() => {
    setUpdateValue(type === 'balance' ? balance : paid)
  }, [type]) // eslint-disable-line

  return (
    <Modal open={open} size="mini">
      <Modal.Content>
        <Modal.Header>Editar {headers[type]}</Modal.Header>
        <Input
          type="number"
          value={updateValue}
          size="mini"
          onChange={(_, { value }) => setUpdateValue(Number(value))}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => handleOnActionClick('cancel')}>Cancelar</Button>
        <Button primary onClick={() => handleOnActionClick('update')}>
          Alterar
        </Button>
      </Modal.Actions>

      <Confirm
        open={openConfirm}
        size="mini"
        header={`Alteração de ${headers[type]}`}
        content={`Você tem certeza que deseja alterar ${headers[type]}?`}
        cancelButton="Cancelar"
        confirmButton="Confirmar Alteração"
        onCancel={() => setOpenConfirm(false)}
        onConfirm={() =>
          updateUsersBudgetValues({ updateValue, userId, typeUpdate: type })
        }
      />
    </Modal>
  )
}

EditValues.propTypes = {
  type: t.string,
  open: t.bool,
  setOpen: t.func,
  balance: t.string,
  paid: t.string,
  userId: t.string,
}
