import React from 'react'

import { cond, equals, always } from 'ramda'
import { Card, Form, Button, Input, Select } from 'semantic-ui-react'

import typesOptions from '../../../helpers/types'

export default function CreateItem({
  reset,
  submit,
  loading,
  question,
  multiples,
  createItems,
  handleInput,
  handleMultiple,
  questionMultiples,
  forms_users_items_type_id,
}) {
  const typeInput = cond([
    [
      equals(6),
      always(
        <Form.Field
          control={Input}
          type="text"
          onKeyDown={createItems}
          value={questionMultiples}
          onChange={({ target }) => handleMultiple(target)}
          placeholder="Adicionar Item (Pressione ENTER para Salvar)..."
        />
      ),
    ],
    [
      equals(7),
      always(
        <Form.Field
          control={Input}
          type="text"
          onKeyDown={createItems}
          value={questionMultiples}
          onChange={({ target }) => handleMultiple(target)}
          placeholder="Adicionar Item (Pressione ENTER para Salvar)..."
        />
      ),
    ],
  ])
  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              type="text"
              control={Input}
              label="Pergunta"
              value={question || ''}
              placeholder="Sua pergunta"
              onChange={({ target }) => handleInput(target.value, 'question')}
            />
            <Form.Field
              label="Tipo"
              control={Select}
              placeholder="Tipo"
              options={typesOptions}
              value={forms_users_items_type_id || null}
              onChange={(e, { value }) =>
                handleInput(value, 'forms_users_items_type_id')
              }
            />
          </Form.Group>

          {typeInput(forms_users_items_type_id)}

          {multiples.map((item, index) => (
            <Form.Field
              type="text"
              control={Input}
              key={`key-${index}`}
              value={item.option}
            />
          ))}
        </Form>
      </Card.Content>
      <Card.Content extra>
        <Button
          basic
          color="blue"
          onClick={submit}
          loading={loading}
          disabled={loading}
          content="Adicionar Item"
        />
        <Button
          basic
          color="orange"
          onClick={reset}
          loading={loading}
          disabled={loading}
          content="Resetar"
        />
      </Card.Content>
    </Card>
  )
}
