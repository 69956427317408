import React, { useState } from 'react'
import { FixedSizeList as List } from 'react-window'

import dayjs from 'dayjs'
import t from 'prop-types'
import {
  Card,
  Checkbox,
  Grid,
  Header,
  Popup,
  Progress,
} from 'semantic-ui-react'

import useMissionsUsersRating from '~/hooks/useMissionsUsersRating'
import CardItem from '~/pages/Workforce/components/CardItem'

import { AddRatingModal } from '../AddRatingModal'
import { Counter } from '../Counter'
import {
  HeaderContainer,
  MainContainer,
  DaysContainer,
  FlexContainer,
  CardContainer,
} from './styles'

function CardDays({
  usersData,
  dateList,
  adherence,
  onSelectUsers,
  onSelectAllUsers,
  selectedUsers,
  totalRegisters,
  ratings,
  refreshWorkforce,
}) {
  const [addRatingModal, setAddRatingModal] = useState({})

  const {
    getMissionsUsersRatingByMissionsUsersId,
    loadingMissionsUsersRating,
    missionsUsersRating,
    createMissionsUsersRating,
  } = useMissionsUsersRating()

  function formatDate(date) {
    return dayjs(date).format('YYYY-MM-DD')
  }

  function handleOnClickAddRating(user, date) {
    setAddRatingModal(prevState => ({
      ...prevState,
      open: true,
      user,
      cardDate: date,
    }))

    getMissionsUsersRatingByMissionsUsersId({
      missionsUsersId: user?.uid,
      date,
    })
  }

  function handleOnSubmitRating(values) {
    createMissionsUsersRating(values)
    refreshWorkforce()
  }

  return (
    <>
      <CardContainer id="main">
        <Card.Content>
          <MainContainer>
            {dateList.map(date => (
              <DaysContainer key={date}>
                <HeaderContainer>
                  <FlexContainer>
                    <FlexContainer>
                      <Popup
                        content="Selecionar todos os usuários do dia"
                        trigger={
                          <Checkbox
                            onClick={(e, { checked }) =>
                              onSelectAllUsers(
                                checked,
                                usersData[date],
                                formatDate(date)
                              )
                            }
                          />
                        }
                      />
                    </FlexContainer>
                    <FlexContainer className="date">
                      <Header>{dayjs(date).format('DD/MM/YYYY')}</Header>
                    </FlexContainer>
                  </FlexContainer>
                  <Progress
                    percent={adherence[date]}
                    progress="percent"
                    color="blue"
                    autoSuccess
                    active
                  />

                  <Grid columns={4}>
                    <Grid.Row>
                      <Grid.Column textAlign="center">
                        <Counter
                          description="Missões Disponíveis"
                          iconName="totalDisponible"
                          iconColor="grey"
                          text={totalRegisters?.totalRegistersPerDay[date]}
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <Counter
                          description="Missões em Progresso"
                          iconName="totalInProgress"
                          iconColor="blue"
                          text={
                            totalRegisters?.totalRegistersInProgressPerDay[date]
                          }
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <Counter
                          description="Missões com Faltas"
                          iconName="totalMissed"
                          iconColor="red"
                          text={
                            totalRegisters?.totalRegistersMissedPerDay[date]
                          }
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <Counter
                          description="Média das Avaliações"
                          iconName="avgRating"
                          iconColor="yellow"
                          text={ratings?.ratingsPerDay[date]}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </HeaderContainer>

                <List
                  height={700}
                  itemCount={usersData[date]?.length}
                  itemSize={150}
                  width={300}
                >
                  {props =>
                    CardItem({
                      ...props,
                      onSelectUsers,
                      selectedUsers,
                      refreshWorkforce,
                      date,
                      ratings,
                      user: usersData[date][props.index],
                      handleOnClickAddRating,
                    })
                  }
                </List>
              </DaysContainer>
            ))}
          </MainContainer>
        </Card.Content>
      </CardContainer>

      <AddRatingModal
        open={addRatingModal.open}
        user={addRatingModal.user}
        cardDate={addRatingModal.cardDate}
        onClose={() =>
          setAddRatingModal(prevState => ({
            ...prevState,
            open: false,
          }))
        }
        missionsUsersRating={missionsUsersRating}
        loadingMissionsUsersRating={loadingMissionsUsersRating}
        onSubmitRating={values => handleOnSubmitRating(values)}
      />
    </>
  )
}

CardDays.propTypes = {
  usersData: t.objectOf(t.any),
  dateList: t.arrayOf(t.string),
  adherence: t.objectOf(t.string),
  onSelectUsers: t.func,
  onSelectAllUsers: t.func,
  selectedUsers: t.arrayOf(t.string),
  totalRegisters: t.objectOf(t.string),
  ratings: t.objectOf(t.string),
  refreshWorkforce: t.func,
}

export default CardDays
