import React, { useEffect, useState } from 'react'
import { FlyToInterpolator } from 'react-map-gl'

import * as d3 from 'd3'
import t from 'prop-types'
import { Grid } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import Loading from '~/components/Loading'
import useMissionsUsersCheckInCheckOut from '~/hooks/useMissionsUsersCheckInCheckOut'

import Info from './Info'
import Map from './Map'

function CheckInCheckOut({ missionsUsersId, place, lng, lat }) {
  const {
    getMissionsUsersCheckInCheckOut,
    checkInData,
    checkOutData,
    loadingCheckInOut,
  } = useMissionsUsersCheckInCheckOut()

  const viewportDefault = {
    zoom: 16,
    latitude: lat || 0,
    longitude: lng || 0,
    transitionDuration: 5000,
    transitionEasing: d3.easeCubic,
    transitionInterpolator: new FlyToInterpolator(),
  }

  const [viewportCheckIn, setViewportCheckIn] = useState({
    ...viewportDefault,
  })

  const [viewportCheckOut, setViewportCheckOut] = useState({
    ...viewportDefault,
  })

  function onChangeCheckIn(viewportData) {
    return setViewportCheckIn(prevState => ({ ...prevState, ...viewportData }))
  }

  function onChangeCheckOut(viewportData) {
    return setViewportCheckOut(prevState => ({ ...prevState, ...viewportData }))
  }

  useEffect(() => {
    getMissionsUsersCheckInCheckOut(missionsUsersId)
  }, []) // eslint-disable-line

  return (
    <>
      {loadingCheckInOut ? (
        <Loading customText="Carregando..." />
      ) : (
        <>
          {checkInData?.length === 0 && checkOutData?.length === 0 ? (
            <EmptyList
              icon="map marker alternate"
              text="Ainda Não Foram Realizados os Registros de Entrada e Saída"
            />
          ) : (
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Map
                    items={checkInData}
                    viewport={viewportCheckIn}
                    onChange={onChangeCheckIn}
                  />
                </Grid.Column>

                <Grid.Column>
                  <Map
                    items={checkOutData}
                    viewport={viewportCheckOut}
                    onChange={onChangeCheckOut}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Info place={place} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </>
      )}
    </>
  )
}

CheckInCheckOut.propTypes = {
  missionsUsersId: t.string,
  place: t.string,
  lat: t.number,
  lng: t.number,
}

export default CheckInCheckOut
