import React from 'react'

import t from 'prop-types'
import { Grid, Icon, Popup } from 'semantic-ui-react'

export function Counter({ iconName, iconColor, text, description }) {
  const icons = {
    totalDisponible: 'target',
    totalInProgress: 'rocket',
    totalMissed: 'close',
    avgRating: 'star',
  }

  return (
    <Popup
      content={description}
      trigger={
        <Grid.Row>
          <Icon name={icons[iconName]} color={iconColor} />
          {text || 0}
        </Grid.Row>
      }
    />
  )
}

Counter.propTypes = {
  iconName: t.string,
  iconColor: t.string,
  text: t.string,
  description: t.string,
}
