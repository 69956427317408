import { useCallback, useState } from 'react'

import FileSaver from 'file-saver'

import api from '~/services/api'
import Message from '~/utils/messages'

function usePaymentRequest() {
  const filters = { limit: 100, offset: 0, status: 0 }
  const [loadingTransactions, setLoading] = useState(false)
  const [transactions, setTransactions] = useState([])

  const [payments, setPayments] = useState([])
  const [paymentCount, setPaymentCount] = useState(0)
  const [loadingPayments, setLoadingPayments] = useState(true)

  const [usersPayments, setUsersPayments] = useState([])

  const getTransactions = useCallback(async params => {
    try {
      setLoading(true)
      const res = await api.get('payment-request/extract', {
        params,
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setTransactions(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  const getPaymentRequests = useCallback(async params => {
    try {
      setLoadingPayments(true)
      const res = await api.get('payment-request/users', {
        params,
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setPaymentCount(res.data.count)

      return setPayments(res.data.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingPayments(false)
    }
  }, [])

  const getPaymentRequestsByUserId = useCallback(
    async ({ userId, page, itemsPerPage }) => {
      try {
        setLoadingPayments(true)
        const res = await api.get(`payment-request/users/${userId}`, {
          params: {
            page,
            itemsPerPage,
          },
        })

        if (res.status !== 200) {
          throw new Error(res.data.message)
        }

        setPaymentCount(res.data.count)

        return setUsersPayments(res.data.data)
      } catch (error) {
        return Message().error(error.message)
      } finally {
        setLoadingPayments(false)
      }
    },
    []
  )

  async function onSubmitReceipt(data) {
    try {
      setLoadingPayments(true)
      const res = await api.post('payment-request/receipt', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Comprovante enviado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      getPaymentRequests(filters)
      setLoadingPayments(false)
    }
  }

  async function onSubmitApprove(id) {
    try {
      setLoadingPayments(true)
      const res = await api.put(
        'payment-request/approve',
        { id },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Comprovante Aprovado')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      getPaymentRequests(filters)
      setLoadingPayments(false)
    }
  }

  async function onRevertPayment(data) {
    try {
      setLoadingPayments(true)
      const res = await api.put(`/payment-request/revert/${data.uid}`, {
        motivation: data.motivation,
      })

      if (res.status !== 200 && res.status !== 204) {
        throw new Error(res.data.message)
      }

      return Message().success('Pedido de pagamento revertido com sucesso')
    } catch (error) {
      return Message().error(
        error?.response?.data?.message || 'Ocorreu um erro inesperado.'
      )
    } finally {
      getPaymentRequests(filters)
      setLoadingPayments(false)
    }
  }

  async function onSubmitXls(params) {
    console.log(params)
    try {
      setLoadingPayments(true)
      const res = await api.get('payment-request/report-xls', {
        params,
        responseType: 'arraybuffer',
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      })

      return FileSaver.saveAs(blob, `pagamentos_${new Date().getTime()}`)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      getPaymentRequests(filters)
      setLoadingPayments(false)
    }
  }

  return {
    payments,
    loadingPayments,
    paymentCount,
    onSubmitApprove,
    getPaymentRequests,
    onSubmitReceipt,
    onSubmitXls,
    onRevertPayment,

    usersPayments,
    getPaymentRequestsByUserId,

    transactions,
    getTransactions,
    loadingTransactions,
  }
}

export default usePaymentRequest
