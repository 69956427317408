import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import t from 'prop-types'
import { Icon, Button, Confirm, Divider } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import TableComponent from '~/components/Table'
import useGeo from '~/hooks/useGeo'
import { baseURL } from '~/services/api'
import {
  update,
  createMissions,
  findImportsFiles,
} from '~/store/modules/imports/actions'
import Message from '~/utils/messages'

import Item from './components/Item'
import ModalAddress from './components/Modal'

const BREADCRUMBS_LIST = [
  {
    active: false,
    hasLink: true,
    link: '/imports',
    name: 'Importação',
  },
  {
    active: true,
    hasLink: false,
    name: 'Missões',
  },
]

function Imports(props) {
  const {
    match,
    update,
    history,
    createMissions,
    addressResponse,
    findImportsFiles,
    importsFileUpdate,
    importsFileCreated,
    importsFilesMissions,
  } = props
  const id = match.params.importFileId

  const [open, setOpen] = useState(false)
  const [address, setAddress] = useState('')
  const [itemData, setItemData] = useState({})
  const [missions, setMissions] = useState([])
  const [confirm, setConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const [filters, setFilters] = useState({ limit: 100, offset: 0 })

  const { onSearchAddress, loadingAddresses, addresses, setAddresses } =
    useGeo()

  useEffect(() => {
    findImportsFiles({ ...filters, id })
    setLoading(true)
  }, [id, filters, findImportsFiles])

  useEffect(() => {
    if (importsFilesMissions.success) {
      setMissions(
        importsFilesMissions.data.map(i => ({
          ...i,
          checked: false,
        }))
      )
      setLoading(false)
      setLoadingButton(false)
      importsFilesMissions.success = false
    }

    if (importsFileUpdate.success) {
      Message().success('Item atualizado com sucesso')
      importsFileUpdate.success = false
    }

    if (importsFileCreated.success) {
      Message().success('Iniciando a criação de missões...')
      importsFileCreated.success = false
    } else if (importsFileCreated.error) {
      Message().error(importsFileCreated.message)
      setLoading(false)
      importsFileCreated.error = false
    }
  }, [
    id,
    filters,
    addressResponse,
    importsFileUpdate,
    importsFileCreated,
    importsFilesMissions,
  ])

  function onRemoveItem({ i, item }) {
    const newData = missions.find(m => m === item)
    const items = newData.addressesFound.filter(r => r !== i)

    newData.addressesFound = items

    if (items.length === 0) {
      newData.status = 'NOT_FOUND'
    }

    if (items.length === 1) {
      newData.status = 'SUCCESS'
    }

    newData.status = 'MULTIPLES'

    return update({ fileId: id, id: newData.uid, data: newData })
  }

  function onOpenModal({ i, k, item }) {
    setOpen(true)
    setItemData({
      item,
      addressItem: i,
      addressIndex: k,
    })

    return Object.keys(i).length
      ? setAddress(i.data.formattedAddress)
      : setAddress('')
  }

  function handleLimit() {
    setLoadingButton(true)
    setFilters({ limit: filters.limit + 100, offset: 0 })

    return findImportsFiles({ ...filters, id, limit: filters.limit + 100 })
  }

  function handleAddress(value) {
    setTimeout(() => value.length > 2 && onSearchAddress(value), 1000)

    return setAddress(value)
  }

  async function changeAddress({ data }) {
    const newItem = missions.find(i => itemData.item === i)

    if (newItem.addressesFound.length > 0) {
      const updateAddress = newItem.addressesFound.find(
        r => r === itemData.addressItem
      )
      updateAddress.data = data
      updateAddress.statusAddress = 'COMPLETED'
    } else {
      newItem.addressesFound = [
        ...newItem.addressesFound,
        {
          data,
          statusAddress: 'COMPLETED',
        },
      ]
    }

    newItem.status = 'CHECKED'

    setAddresses([])

    await update({ fileId: id, id: newItem.uid, data: newItem })

    return setOpen(false)
  }

  function onCheckItem({ item }) {
    const newData = missions.find(m => m === item)
    newData.status = 'CHECKED'

    return update({ fileId: id, id: newData.uid, data: newData })
  }

  function onConfirmItems() {
    const statusSuccess = missions.find(
      i =>
        i.status === 'MULTIPLES' ||
        i.status === 'NOT_FOUND' ||
        i.status === 'ERROR'
    )

    if (statusSuccess) {
      setConfirm(true)
      return false
    }

    return setConfirm(true)
  }

  function submit() {
    setLoading(true)
    setConfirm(false)

    return createMissions(id)
  }

  function onExportFiles() {
    return window.open(
      `${baseURL}integration/importFile/${id}/not-found`,
      'self'
    )
  }

  return (
    <>
      <Breadcrumbs history={history} items={BREADCRUMBS_LIST} />

      <Button
        icon
        positive
        loading={loading}
        disabled={loading}
        labelPosition="left"
        onClick={onConfirmItems}
      >
        <Icon name="check" />
        Criar Missões
        {/* {items.length > 0 && `(${items.length})`} */}
      </Button>

      <Button icon labelPosition="left" onClick={onExportFiles}>
        <Icon name="download" />
        Não Encontradas
      </Button>

      <TableComponent
        data={missions}
        isLoading={loading}
        renderItem={item => (
          <Item
            item={item}
            onOpenModal={onOpenModal}
            onCheckItem={onCheckItem}
            onRemoveItem={onRemoveItem}
          />
        )}
        columns={[
          // <Checkbox checked={checkedAll} onClick={() => selectedAll()} />,
          'STATUS',
          'NOME DA MISSAO',
          'ENDEREÇO - CONVERTIDO',
          'ENDEREÇO - ORIGINAL',
          'AÇÕES',
        ]}
      />

      <Divider />

      {!loading && (
        <Button
          primary
          onClick={handleLimit}
          loading={loadingButton}
          disabled={loadingButton}
        >
          Carregar mais...
        </Button>
      )}

      <ModalAddress
        open={open}
        item={itemData}
        address={address}
        addresses={addresses}
        close={() => setOpen(false)}
        changeAddress={changeAddress}
        handleAddress={handleAddress}
        loadingAddresses={loadingAddresses}
      />

      <Confirm
        open={confirm}
        header="Alerta"
        confirmButton="Criar"
        cancelButton="Cancelar"
        onConfirm={() => submit()}
        onCancel={() => setConfirm(false)}
        content="Tem certeza que deseja criar as missões? Lembrando que as missões que serão criadas devem estar com status igual a SUCESSO."
      />
    </>
  )
}

Imports.propTypes = {
  update: t.func,
  match: t.objectOf(t.any),
  history: t.objectOf(t.any),
  createMissions: t.objectOf(t.any),
  findImportsFiles: t.objectOf(t.any),
  importsFileUpdate: t.objectOf(t.any),
  importsFileCreated: t.objectOf(t.any),
  importsFilesMissions: t.objectOf(t.any),
}

const mapStateToProps = state => ({
  user: state.user,
  importsFileUpdate: state.importsFileUpdate,
  importsFileCreated: state.importsFileCreated,
  importsFilesMissions: state.importsFilesMissions,
})

export default connect(mapStateToProps, {
  update,
  createMissions,
  findImportsFiles,
})(Imports)
