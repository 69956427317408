import { useCallback, useState } from 'react'

import * as FilerSaver from 'file-saver'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useFormsItems() {
  const [formsItems, setFormsItems] = useState([])
  const [loadingFormsItems, setLoadingFormsItems] = useState(false)
  const [items, setItems] = useState([])
  const [itemsMultiples, setItemsMultiples] = useState([])
  const [questionsTypes, setQuestionsTypes] = useState([])

  const getFormsItems = useCallback(async id => {
    try {
      setLoadingFormsItems(true)
      const res = await api.get(
        `forms-data/customers/forms-data-by-mission/${id}`,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setFormsItems(res.data.data)
      return setLoadingFormsItems(false)
    } catch (error) {
      setLoadingFormsItems(false)
      return Message().error(error.message)
    }
  }, [])

  async function getItems(formId) {
    try {
      setLoadingFormsItems(true)

      const res = await api.get('forms-items/admin', {
        params: { formId },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setItems(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function createItems(params) {
    try {
      setLoadingFormsItems(true)

      const { answers, dataFormsItems } = params
      const { formId, ...data } = dataFormsItems

      delete data.items
      delete data.score
      delete data.score_rating

      const res = await api.post(
        'forms-items/admin',
        {
          forms_id: formId,
          ...data,
          ...answers,
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getItems(formId)

      return Message().success('Item do formulário criado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function updateFormsItems(params) {
    try {
      setLoadingFormsItems(true)

      const { answers, dataFormsItems, multi, selectedForm } = params

      const list = [...answers.items, ...multi]
      const multiples = list.map(item => ({
        ...item,
        fiqmId: item.uid,
      }))

      const res = await api.put(
        'forms-items/admin',
        {
          ...dataFormsItems,
          formItemId: dataFormsItems.id,
          multiples,
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getItems(selectedForm)

      return Message().success('Item atualizado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function getItemsMultiples(itemsId) {
    try {
      setLoadingFormsItems(true)
      const res = await api.get('forms-items-multiples/admin', {
        params: { itemsId },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setItemsMultiples(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function createMultiple(params) {
    try {
      setLoadingFormsItems(true)

      const res = await api.post('forms-items-multiples/admin', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return getItemsMultiples(params.uid)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function removeMultiple(params) {
    try {
      const res = await api.delete('forms-items-multiples/admin', {
        params: { fiqmId: params.uid },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getItemsMultiples(params.forms_items_id)

      return Message().success('Item removido com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function reorderMultiple(params) {
    try {
      setLoadingFormsItems(true)
      const res = await api.put('forms-items-multiples/admin', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return getItemsMultiples(params.formsItemsId)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function submitUploadImage(params) {
    try {
      setLoadingFormsItems(true)
      const res = await api.put('forms-items-multiples/admin/image', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setItemsMultiples(prevState =>
        prevState.map(prev => {
          if (prev.uid === res.data[0].uid) {
            return res.data[0]
          }

          return prev
        })
      )

      return Message().success('Envio de imagem realizado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function updateQuestionsMultiples(params) {
    try {
      setLoadingFormsItems(true)

      const res = await api.patch('forms-items-multiples/admin', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getItemsMultiples(params.forms_items_id)

      return Message().success('Pontuação atualizada com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function importFormsItems(params) {
    try {
      setLoadingFormsItems(true)

      const newData = new FormData()
      newData.set('formsId', params.selectedForm)
      newData.append('file', params.item)

      const res = await api.post('forms-items/import', newData, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      getItems(params.selectedForm)

      return Message().success('Items importados com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function removeFormsItems(params) {
    try {
      setLoadingFormsItems(true)

      const { formItemUid, formUid } = params

      const res = await api.delete('forms-items/admin', {
        data: { formItemUid },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getItems(formUid)

      return Message().success('Item de formulário removido com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function orderFormsItems(params) {
    try {
      setLoadingFormsItems(true)
      const { formItems, formUid } = params
      const res = await api.put(
        'forms-items/admin/order',
        { formItems },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return getItems(formUid)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function submitExample(params) {
    try {
      setLoadingFormsItems(true)

      const { dataFormsItems, selectedForm } = params

      if (!dataFormsItems.example.image) throw new Error('Adicione uma imagem')

      const res = await api.put(
        'forms-items/admin',
        { ...dataFormsItems, multiples: [] },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getItems(selectedForm)

      return Message().success('Exemplo de item salvo com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function submitOperatorWarning(params) {
    try {
      setLoadingFormsItems(true)

      const { dataFormsItems, selectedForm } = params

      const data = {
        ...dataFormsItems,
        id: dataFormsItems.id,
        uid: dataFormsItems.uid,
      }

      const res = await api.put(
        'forms-items/admin',
        { ...data, multiples: [] },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getItems(selectedForm)

      return Message().success('Operador registrado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function exportFormsItemsTemplate(formsId) {
    try {
      setLoadingFormsItems(true)

      const res = await api.get('forms-items/admin/export', {
        params: { formsId },
        responseType: 'arraybuffer',
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      })

      FilerSaver.saveAs(blob, `forms-items-template-${Date.now()}`)

      return Message().success('Exportação realizada com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function changeOrderFormsItems(params) {
    try {
      setLoadingFormsItems(true)

      const newOrder = params.map((item, index) => ({
        ...item,
        order: index + 1,
      }))

      const res = await api.put(
        'forms-items/admin/change-order',
        { items: newOrder },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setItems(newOrder)

      return Message().success('Nova ordenação registrada com sucesso')
    } catch (error) {
      return Message().error(
        error.message || 'Houve um problema para ordenar a lista'
      )
    } finally {
      setLoadingFormsItems(false)
    }
  }

  async function getQuestionsTypes() {
    try {
      const res = await api.get('forms-items/admin/questions-types', {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setQuestionsTypes(
        res.data?.map(item => ({
          key: item.id,
          value: item.id,
          text: `${item.id} - ${item.name}`,
        }))
      )
    } catch (error) {
      return Message().error(error.message)
    }
  }

  return {
    formsItems,
    getFormsItems,
    loadingFormsItems,
    getItems,
    items,
    createItems,
    getItemsMultiples,
    setItemsMultiples,
    itemsMultiples,
    updateFormsItems,
    createMultiple,
    removeMultiple,
    reorderMultiple,
    submitUploadImage,
    importFormsItems,
    removeFormsItems,
    orderFormsItems,
    submitExample,
    submitOperatorWarning,
    updateQuestionsMultiples,
    exportFormsItemsTemplate,
    changeOrderFormsItems,
    getQuestionsTypes,
    questionsTypes,
  }
}
