import { useState, useEffect, useCallback } from 'react'

import _ from 'lodash'

import api from '~/services/api'
import message from '~/utils/messages'

export default function useUfsAndCities() {
  const [ufs, setUfs] = useState([])
  const [cities, setCities] = useState([])
  const [ufsOptions, setUfsOptions] = useState([])
  const [citiesOptions, setCitiesOptions] = useState([])

  const getCitiesByUfs = async selectedUfsQuery => {
    try {
      const { data: res } = await api.get('geocode/admin/search', {
        params: { ufs: selectedUfsQuery.ufs.join(',') },
      })

      const newArray = res.data.map(city => {
        const arrayCity = city.split('-').map(item => item.trim())
        return {
          key: `${arrayCity[0]}-${arrayCity[1]}`,
          text: arrayCity[0],
          value: {
            cityName: arrayCity[0],
            uf: arrayCity[1],
          },
        }
      })

      setCitiesOptions(newArray)
    } catch (error) {
      message().error(error.response?.data?.data || 'Houve um erro no servidor')
    }
  }

  const onChangeUFs = selectedUfs => {
    if (!selectedUfs.length) {
      setUfs([])
      return setCities([])
    }

    if (cities && selectedUfs.length < ufs?.length) {
      const citiesList = cities.filter(cityObj =>
        selectedUfs.includes(cityObj.uf)
      )
      setUfs(selectedUfs)
      return setCities(citiesList)
    }

    return setUfs(selectedUfs)
  }

  const onChangeCities = selectedCitiesObjs => {
    if (!selectedCitiesObjs.length) {
      return setCities([])
    }

    return setCities(selectedCitiesObjs)
  }

  const getUfs = useCallback(async () => {
    try {
      const { data: res } = await api.get('geocode/admin/search')

      const newArray = res.data.map(({ uf, name }) => {
        return {
          key: uf,
          text: `${name} (${uf})`,
          value: uf,
        }
      })

      setUfsOptions(newArray)
    } catch (error) {
      message().error(error.response.data.data || 'Houve um erro no servidor')
    }
  }, [])

  useEffect(() => {
    getUfs()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (ufsOptions.length && ufs) {
      const selectedUfs = ufsOptions
        .filter(uf => ufs.includes(uf.value))
        .map(uf => uf.key)

      const ufsObj = { ufs: selectedUfs }

      if (selectedUfs.length > 0) {
        getCitiesByUfs(ufsObj)
      }
    }
  }, [ufs, ufsOptions])

  useEffect(() => {
    if (citiesOptions.length && cities && _.isString(cities[0])) {
      const citiesObjs = cities.map(city => {
        const cityObj = citiesOptions.find(cObj => cObj.value.cityName === city)
        return cityObj.value
      })
      setCities(citiesObjs)
    }
  }, [citiesOptions, cities]) //eslint-disable-line

  return {
    ufs,
    cities,
    ufsOptions,
    citiesOptions,
    onChangeUFs,
    onChangeCities,
  }
}
