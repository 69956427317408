import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Card, Dropdown } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import EmptyList from '~/components/EmptyList'
import useBadges from '~/hooks/useBadges'
import useFormsUsers from '~/hooks/useFormsUsers'
import useUsers from '~/hooks/useUsers'
import useUsersConsultants from '~/hooks/useUsersConsultants'
import messages from '~/utils/messages'

import { Item } from './Item'
import { ModalFormsUsersData } from './ModalFormsUsersData'

function Consultants() {
  const history = useHistory()
  const userId = useParams().id

  const { getBadges, badges } = useBadges()
  const {
    getUsersConsultants,
    onUpdateUsersConsultants,
    onSubmitUsersConsultants,
    loadingUsersConsultants,
    onDisableUserConsultor,
    consultants,
  } = useUsersConsultants()
  const { getUserData, userDetail } = useUsers(userId)
  const { getFormsUsersData, formsUsersData, loadingFud } = useFormsUsers()

  const [open, setOpen] = useState(false)
  const [badgeId, setBadgeId] = useState(null)
  const [percentualValue, setPercentual] = useState({})

  useEffect(() => {
    getUserData()
  }, []) //eslint-disable-line

  useEffect(() => {
    getBadges({ limit: 1000 })
  }, []) //eslint-disable-line

  useEffect(() => {
    getUsersConsultants({ leader_id: userId })
  }, []) //eslint-disable-line

  function handleInput(id) {
    setBadgeId(id)

    return getUsersConsultants({ leader_id: userId, badge_id: id })
  }

  function onChangeItem(item, key, value) {
    const index = consultants.findIndex(i => i === item)

    consultants[index][key] = value
  }

  function onSubmitUserConsultant(item) {
    onChangeItem(item, 'status', true)

    const params = {
      status: true,
      is_leader: false,
      badge_id: badgeId,
      leader_id: userId,
      user_id: item.user_id,
      percentual: percentualValue[item.uid],
    }

    if (item.status) {
      params.uid = item.uid

      return onUpdateUsersConsultants(params)
    }

    return onSubmitUsersConsultants(params)
  }

  function onDisableUsersConsultants(item) {
    if (!badgeId) {
      return messages().error('Escolha o badge do consultor!')
    }

    onChangeItem(item, 'status', false)

    return onDisableUserConsultor(item.uid)
  }

  function handleChange(key, value) {
    return setPercentual(prev => ({ ...prev, [key]: value }))
  }

  async function getConsultantFormsUsers(user) {
    await getFormsUsersData({ userId: user.user_id, badgeId })

    return setOpen(!loadingFud)
  }

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      name: 'Usuários',
      link: '/users',
    },
    {
      active: false,
      hasLink: false,
      name: userDetail?.name || '...',
    },
    {
      active: true,
      hasLink: false,
      name: 'Consultores',
    },
  ]
  return (
    <>
      <Breadcrumbs history={history} items={breadcrumbsList} hasButton>
        <Dropdown
          style={{ width: 200 }}
          search
          selection
          options={badges.map(badge => ({
            key: badge.uid,
            text: badge.title,
            value: badge.uid,
          }))}
          placeholder="Buscar Certificados"
          onChange={(e, { value }) => handleInput(value)}
        />
      </Breadcrumbs>

      {badgeId === null ? (
        <EmptyList text="Escolha um badge" icon="trophy" />
      ) : null}

      {consultants.length === 0 ? (
        <EmptyList text="Nenhum consultor até o momento" icon="users" />
      ) : null}

      {badgeId !== null && consultants.length > 0 && (
        <Card.Group>
          {consultants.map(item => (
            <Item
              item={item}
              key={item.uid}
              handleChange={handleChange}
              loading={loadingUsersConsultants}
              percentualValue={percentualValue}
              loadingFormsUsers={loadingFud}
              onSubmitUserConsultant={() => onSubmitUserConsultant(item)}
              getConsultantFormsUsers={() => getConsultantFormsUsers(item)}
              onDisableUsersConsultants={() => onDisableUsersConsultants(item)}
            />
          ))}
        </Card.Group>
      )}

      {open && formsUsersData.length > 0 ? (
        <ModalFormsUsersData
          open={open}
          close={() => setOpen(false)}
          items={formsUsersData[0]?.answers?.items}
        />
      ) : null}
    </>
  )
}

export default Consultants
