import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useFormsMissions() {
  const [loadingFormsMissions, setLoading] = useState(false)
  const [formsMissions, setFormsMissions] = useState(null)

  async function onUpdateFormsMissions(params) {
    try {
      setLoading(true)

      const res = await api.put('form-missions/admin', params)

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Missões vinculada com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function getFormsMissions(missionId) {
    try {
      setLoading(true)

      const res = await api.get('form-missions/admin', {
        params: { missionId },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setFormsMissions(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    loadingFormsMissions,
    onUpdateFormsMissions,
    getFormsMissions,
    formsMissions,
  }
}
