import React, { memo } from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Modal, Loader, Tab, Button } from 'semantic-ui-react'

import history from '~/services/history'

import { Item } from './Item'
import { Container } from './styles'

function WithdrawalsReport({ isOpen, close, isLoading, itemsTransactions }) {
  const { transactions, points } = itemsTransactions

  function onClickTransaction(key) {
    return history.push(`/registers?limit=15&offset=0&search=${key}&status=1`)
  }

  const panes = [
    {
      menuItem: 'Dinheiro',
      render: () => (
        <Tab.Pane attached={false}>
          <Container>
            {transactions.map(item => (
              <Item
                onClick={
                  item.mission_key
                    ? () => onClickTransaction(item.mission_key)
                    : null
                }
                key={item.mission_name}
                title={`${item.mission_key ? `[${item.mission_key}] ` : ''}${
                  item.mission_name
                }`}
                value={item.mission_value}
                subtitle={dayjs(item.created_at).format('DD/MM/YYYY HH:MM')}
              />
            ))}
          </Container>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Pontos',
      render: () => (
        <Tab.Pane>
          <Container>
            {points.map(item => (
              <Item
                key={item.description}
                value={item.value}
                title={item.description}
                subtitle={dayjs(item.created_at).format('DD/MM/YYYY HH:MM')}
              />
            ))}
          </Container>
        </Tab.Pane>
      ),
    },
  ]

  return (
    <Modal size="tiny" open={isOpen} onClose={close}>
      <Modal.Header content="Extrato" />
      <Modal.Content scrolling>
        {isLoading && (
          <div>
            <Loader active inline="centered" />
          </div>
        )}

        {!isLoading && (
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close}>Ok</Button>
      </Modal.Actions>
    </Modal>
  )
}

WithdrawalsReport.propTypes = {
  isOpen: t.bool,
  close: t.func,
  isLoading: t.bool,
  itemsTransactions: t.objectOf({
    transactions: t.arrayOf(t.any),
    points: t.arrayOf(t.any),
  }),
}

export default memo(WithdrawalsReport)
