/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'

import dayjs from 'dayjs'
import t from 'prop-types'
import Drawer from 'rc-drawer'
import { Button, Grid, Icon, Table } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useQueueUpdateMissionBatch from '~/hooks/useQueueUpdateMissionBatch'

import {
  COLUMNS,
  EMPTY_TEXT,
  DATE_FORMAT,
  QUEUE_UPDATE_MISSION_BATCH_STATUS,
  QUEUE_UPDATE_MISSION_STATUS_TO_USER,
} from '../../helpers/type'

export default function ListEditionMissionBatch({ open, close }) {
  const {
    getListEditionMissionsBatch,
    isLoading,
    pagination,
    setPagination,
    items,
  } = useQueueUpdateMissionBatch()

  const isFailed = item =>
    item.status === QUEUE_UPDATE_MISSION_BATCH_STATUS.FAILED

  const getDateFinishedProcess = item => {
    const isCompletedProcess =
      item.status === QUEUE_UPDATE_MISSION_BATCH_STATUS.DONE ||
      item.status === QUEUE_UPDATE_MISSION_BATCH_STATUS.FAILED
    if (isCompletedProcess) {
      return dayjs(item.updated_at).format('DD/MM/YYYY HH:mm:ss')
    }

    return ''
  }

  const changePage = async page => {
    setPagination({
      ...pagination,
      page,
    })
  }

  const renderItem = item => (
    <Table.Row key={item.id}>
      <Table.Cell>
        {isFailed(item) && (
          <>
            <a data-tip data-for={`${item.id}_error`}>
              <Icon size="large" color="red" name="warning sign" />
            </a>

            <ReactTooltip place="right" id={`${item.id}_error`} type="error">
              <span>{item.error_tried_process}.</span>
            </ReactTooltip>
          </>
        )}
        {item.client}
      </Table.Cell>
      <Table.Cell>
        {QUEUE_UPDATE_MISSION_STATUS_TO_USER[item.status]}
      </Table.Cell>
      <Table.Cell>{item.total_missions}</Table.Cell>
      <Table.Cell>{item.total_missions_updated}</Table.Cell>
      <Table.Cell>
        Inicio: {dayjs(item.filters.initialDate).format(DATE_FORMAT)} <br />
        Final: {dayjs(item.filters.finalDate).format(DATE_FORMAT)} <br />
        Filtro selecionado: {item.filters.periodFieldSelected}
      </Table.Cell>
      <Table.Cell>
        {item.started_at ? dayjs(item.started_at).format(DATE_FORMAT) : ''}
      </Table.Cell>
      <Table.Cell>{getDateFinishedProcess(item)}</Table.Cell>
      <Table.Cell>{dayjs(item.created_at).format(DATE_FORMAT)}</Table.Cell>
    </Table.Row>
  )

  useEffect(() => {
    if (open) {
      getListEditionMissionsBatch()
    }
  }, [open, pagination.page]) //eslint-disable-line

  return (
    <Drawer
      width="75%"
      placement="right"
      open={open}
      onClose={close}
      handler={false}
    >
      <Grid style={{ margin: '5px' }}>
        <Grid.Column>
          <Grid.Row>
            <h2>Edições de missões em massa</h2>
          </Grid.Row>
          <Grid.Row>
            <Button
              onClick={() => getListEditionMissionsBatch()}
              floated="right"
              icon="refresh"
              primary
            />
            <br />
            <TableComponent
              showEllipsis
              count={pagination.itemsPerPage}
              data={items}
              columns={COLUMNS}
              showFirstAndLastNav
              emptyText={EMPTY_TEXT}
              showPreviousAndNextNav
              limit={pagination.itemsPerPage}
              totalCount={pagination.total}
              activePage={pagination.page}
              offset={pagination.page - 1}
              isLoading={isLoading}
              handlePaginationChange={(_event, data) =>
                changePage(data.activePage)
              }
              renderItem={renderItem}
            />
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </Drawer>
  )
}

ListEditionMissionBatch.propTypes = {
  open: t.func,
  close: t.func,
}
