import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

const MISSIONS_MAIN_VALUE = 'missions-main-values'

export default function useMissionsMainValues() {
  const [loading, setLoading] = useState(false)
  const [loadingChangeValue, setLoadingChangeValue] = useState(false)
  const [missionsMainValues, setMissionsMainValues] = useState([])

  const getMissionsMainValues = useCallback(async missionsMainId => {
    try {
      setLoading(true)
      const res = await api.get(MISSIONS_MAIN_VALUE, {
        params: {
          missionsMainId,
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoading(false)
      return setMissionsMainValues(res.data)
    } catch (error) {
      setLoading(false)
      return Message().error(error.message)
    }
  }, [])

  async function onChangeMissionsValuesOnRegisters({
    id,
    costValue,
    onRefresh,
    missionsUsersId,
  }) {
    setLoadingChangeValue(true)
    try {
      if (!id) {
        throw new Error('Escolha o Valor Variavel ou Percentual')
      }

      const res = await api.post(
        `/missions-users/admin/missions-main-values`,
        {
          costValue,
          missionsUsersId,
          missionMainValueId: id,
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Valor alterado com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      onRefresh()
      setLoadingChangeValue(false)
    }
  }

  async function onCreateMissionsValues(data) {
    try {
      setLoading(true)
      const res = await api.post(`${MISSIONS_MAIN_VALUE}/create`, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoading(false)
      getMissionsMainValues(res.data.missions_main_id)
      return Message().success('Valor Variavel criado com sucesso!')
    } catch (error) {
      setLoading(false)
      return Message().error(error.message)
    }
  }

  async function onUpdateMissionsValues(data) {
    try {
      setLoading(true)
      const res = await api.put(`${MISSIONS_MAIN_VALUE}/update`, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoading(false)
      getMissionsMainValues(res.data.missions_main_id)
      return Message().success('Valor Variavel criado com sucesso!')
    } catch (error) {
      setLoading(false)
      return Message().error(error.message)
    }
  }

  async function onDeleteMissionsValues(id) {
    try {
      setLoading(true)
      const res = await api.delete(`${MISSIONS_MAIN_VALUE}/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoading(false)
      getMissionsMainValues(res.data.missions_main_id)
      return Message().success('Valor Variavel criado com sucesso!')
    } catch (error) {
      setLoading(false)
      return Message().error(error.message)
    }
  }

  return {
    loading,
    missionsMainValues,
    loadingChangeValue,
    getMissionsMainValues,
    onCreateMissionsValues,
    onUpdateMissionsValues,
    onDeleteMissionsValues,
    onChangeMissionsValuesOnRegisters,
  }
}
