import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useMissionsManagement() {
  const [loadingMissions, setLoading] = useState(false)
  const [missions, setMissions] = useState([])

  const getMissions = useCallback(async params => {
    setLoading(true)
    try {
      const res = await api.get('missions/admin/users', {
        params,
      })

      return setMissions(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }, []) //eslint-disable-line

  async function onCreateMissionsUsers({ userId, missionsIds }) {
    try {
      setLoading(true)
      const res = await api.post(
        'missions-users/admin',
        {
          userId,
          missionsIds,
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return Message().success(`Suas missões foram adicionadas com sucesso`)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function onImportMissions(data) {
    setLoading(true)
    try {
      const formData = new FormData()

      formData.append('file', data.file)
      formData.append('userId', data.userId)

      const res = await api.post('missions-users/import-missions', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Importação encaminhada com sucesso.')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    missions,
    getMissions,
    loadingMissions,
    onImportMissions,
    onCreateMissionsUsers,
  }
}
