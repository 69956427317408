import React, { useEffect } from 'react'

import t from 'prop-types'
import { Card, Grid, Statistic } from 'semantic-ui-react'

import useUsersTransactions from '~/hooks/useUsersTransactions'

export function Cards({ userId }) {
  const { getUsersTransactionsReport, usersTransactionsReport, inconsistency } =
    useUsersTransactions()

  useEffect(() => {
    getUsersTransactionsReport(userId)
  }, []) // eslint-disable-line

  function StatisticCard({ label, value }) {
    return (
      <Grid.Column>
        <Card>
          <Card.Content textAlign="center">
            <Statistic size="tiny">
              <Statistic.Label>{label}</Statistic.Label>
              <Statistic.Value>{value}</Statistic.Value>
            </Statistic>
          </Card.Content>
        </Card>
      </Grid.Column>
    )
  }

  StatisticCard.propTypes = {
    label: t.string,
    value: t.number,
  }

  return (
    <>
      {inconsistency && (
        <Card fluid style={{ backgroundColor: 'yellow' }}>
          <Card.Content>
            <strong>ATENÇÃO!</strong> Há inconsistência nos valores acumulados e
            com requisições de retirada do usuário.
          </Card.Content>
        </Card>
      )}
      <Grid columns={5} relaxed>
        <Grid.Row>
          {usersTransactionsReport?.map(data => (
            <StatisticCard label={data.label} value={data.value} />
          ))}
        </Grid.Row>
      </Grid>
    </>
  )
}

Cards.propTypes = {
  userId: t.string,
}
