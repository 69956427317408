import React, { useEffect } from 'react'

import { Button, Card, Form, Grid } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useOrder from '~/hooks/useOrder'

import ConfirmationModal from './components/ConfirmationModal'
import OrderDetailModal from './components/OrderDetailModal'
import OrderItem from './components/OrderItem'
import ReportProblemToSendModal from './components/ReportProblemToSendModal'
import SetTrackingCodeModal from './components/SetTrackingCodeModal'
import {
  COLUMNS,
  DELIVERY_STATUS_TO_DISPLAY,
  INITIAL_PAGE,
} from './helpers/types'

function Orders() {
  const {
    orders,
    getOrders,
    pagination,
    changePagination,
    selectOrder,
    showOrderDetails,
    setShowOrderDetails,
    orderSelected,
    setOrderSelected,
    filter,
    changeInputValue,
    resetFilter,
    addTrackingCodeInOrder,
    trackingCode,
    setTrackingCode,
    modelSetTrackingCode,
    setModelSetTrackingCode,
    openModelSetTrackingCode,
    confirmUpdateDeliveryStatus,
    setModalConfirmation,
    modalConfirmation,
    openModelConfirmation,
    openModalProblemToSend,
    modalProblemToSend,
    setModalProblemToSend,
    setDisclaimerProblemToSend,
    disclaimerProblemToSend,
    confirmProblemToSend,
  } = useOrder()

  function renderItem(item, index) {
    return (
      <OrderItem
        index={index}
        item={item}
        openOrderDetailsModal={selectOrder}
        openModelConfirmation={openModelConfirmation}
        openModelSetTrackingCode={openModelSetTrackingCode}
        openModalProblemToSend={openModalProblemToSend}
      />
    )
  }

  function renderOptions() {
    return Object.keys(DELIVERY_STATUS_TO_DISPLAY).map(key => {
      return {
        key,
        value: key,
        text: DELIVERY_STATUS_TO_DISPLAY[key],
      }
    })
  }

  function onSubmit() {
    return getOrders({ offset: INITIAL_PAGE, limit: pagination.limit })
  }

  useEffect(() => {
    getOrders(pagination)
  }, []) //eslint-disable-line

  return (
    <>
      <h1>Pedidos dos usuários</h1>
      <Card fluid>
        <Card.Content>
          <Form>
            <Grid>
              <Form.Field>
                <label>Situação da entrega:</label>
                <Form.Select
                  id="deliveryStatus"
                  value={filter.deliveryStatus}
                  onChange={(_, data) =>
                    changeInputValue('deliveryStatus', data.value)
                  }
                  placeholder="Selecionar uma situação"
                  options={renderOptions()}
                />
              </Form.Field>
              <Form.Field>
                <label>Data inicial:</label>
                <Form.Input
                  id="initDate"
                  value={filter.initDate}
                  onChange={event =>
                    changeInputValue('initDate', event.target.value)
                  }
                  type="date"
                />
              </Form.Field>
              <Form.Field>
                <label>Data Final:</label>
                <Form.Input
                  id="finalDate"
                  value={filter.finalDate}
                  onChange={event =>
                    changeInputValue('finalDate', event.target.value)
                  }
                  type="date"
                />
              </Form.Field>
            </Grid>
            <Grid>
              <Grid.Column>
                <Button color="green" type="submit" onClick={onSubmit}>
                  Filtrar
                </Button>
                <Button
                  color="orange"
                  type="submit"
                  onClick={() => resetFilter()}
                >
                  Limpar filtros
                </Button>
              </Grid.Column>
            </Grid>
          </Form>
        </Card.Content>
      </Card>

      <ReportProblemToSendModal
        modalProblemToSend={modalProblemToSend}
        setModalProblemToSend={setModalProblemToSend}
        setDisclaimerProblemToSend={setDisclaimerProblemToSend}
        disclaimerProblemToSend={disclaimerProblemToSend}
        confirmProblemToSend={confirmProblemToSend}
      />

      <ConfirmationModal
        modalConfirmation={modalConfirmation}
        setModalConfirmation={setModalConfirmation}
        confirmUpdateDeliveryStatus={confirmUpdateDeliveryStatus}
      />

      <SetTrackingCodeModal
        modelSetTrackingCode={modelSetTrackingCode}
        setModelSetTrackingCode={setModelSetTrackingCode}
        setTrackingCode={setTrackingCode}
        addTrackingCodeInOrder={addTrackingCodeInOrder}
        trackingCode={trackingCode}
      />

      <OrderDetailModal
        setShowOrderDetails={setShowOrderDetails}
        showOrderDetails={showOrderDetails}
        orderSelected={orderSelected}
        setOrderSelected={setOrderSelected}
      />

      <TableComponent
        isLoading={false}
        columns={COLUMNS}
        data={orders}
        renderItem={item => renderItem(item)}
        emptyText={{
          icon: 'eye slash outline',
          text: 'Não foi encontrado nenhum pedido',
        }}
        count={pagination.total}
        totalCount={pagination.total}
        activePage={pagination.activePage}
        limit={pagination.limit}
        offset={pagination.offset === 0 ? 0 : pagination.offset - 1}
        showEllipsis
        showFirstAndLastNav
        showPreviousAndNextNav
        handlePaginationChange={(_, page) => changePagination(page.activePage)}
      />
    </>
  )
}

export default Orders
