import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'

import t from 'prop-types'
import { Button, Image, List, Modal } from 'semantic-ui-react'

import 'react-image-lightbox/style.css'

function Answers({ onClickCancel, data }) {
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState(null)
  const [items, setItems] = useState([])

  const Box = () => (
    <Lightbox
      onCloseRequest={closeBox}
      mainSrc={items[current]}
      nextSrc={items[(current + 1) % items.length]}
      onMovePrevRequest={() => setCurrent(current - 1)}
      onMoveNextRequest={() => setCurrent(current + 1)}
      prevSrc={items[(current + items.length - 1) % items.length]}
    />
  )

  function closeBox() {
    return setOpen(false)
  }

  function openBox(i, paths) {
    setItems(paths.map(image => image.path))
    setCurrent(i)
    return setTimeout(() => setOpen(true), 500)
  }

  return (
    <Modal open={data.open} size="small">
      <Modal.Header>Lista de Respostas</Modal.Header>
      <Modal.Content>
        <List>
          {data.item?.answers?.items?.map(itemQuestion => (
            <List.Item key={itemQuestion.id} style={{ marginBottom: 4 }}>
              <List.Header content={itemQuestion.question} />
              R:{' '}
              {Array.isArray(itemQuestion.answer)
                ? itemQuestion.answer.map((itemAnswer, indexAnswer) => (
                    <Image
                      width={40}
                      src={itemAnswer.path}
                      style={{ cursor: 'pointer', marginRight: 2 }}
                      onClick={() => openBox(indexAnswer, itemQuestion.answer)}
                    />
                  ))
                : itemQuestion.answer}
            </List.Item>
          ))}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancelar" onClick={onClickCancel} />
      </Modal.Actions>
      {open && <Box />}
    </Modal>
  )
}

Answers.propTypes = {
  onClickCancel: t.func,
  data: t.objectOf(t.any),
}

export default Answers
