import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function usePointOfSale() {
  const [pos, setPos] = useState(null)
  const [loadingPos, setLoadingPos] = useState(true)

  const getPos = useCallback(async id => {
    try {
      setLoadingPos(true)
      const res = await api.get(`point-of-sale/sent/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setPos(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingPos(false)
    }
  }, [])

  return {
    pos,
    getPos,
    loadingPos,
  }
}
