import React from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Dropdown, Table, Label, Menu } from 'semantic-ui-react'

import { formatPrice } from '~/utils/format'
import {
  loanApprovalItemStatus,
  mappedStatusInstallment,
  mappedStatusInstallmentColor,
} from '~/utils/types'

const Item = ({
  index,
  uploadInvoice,
  installment,
  onOpenReceiptUpload,
  confirmInstallmentPaid,
  getSignedUrlToDocument,
}) => {
  const SOURCE = 'loan_approval_items'
  const isEnableUploadInvoiceOption = installment.invoice_url === null
  const isEnableConfirmInstallmentPaidOption =
    installment.receipt_url !== null &&
    installment.status === loanApprovalItemStatus.PENDING
  const isEnableUploadReceipt =
    installment.invoice_url !== null &&
    installment.status === loanApprovalItemStatus.PENDING

  const onDownloadReceipt = async sourceField => {
    const url = await getSignedUrlToDocument(
      installment.id,
      SOURCE,
      sourceField
    )
    const elementA = document.createElement('a')
    elementA.target = '_blank'
    elementA.href = url
    elementA.click()
  }

  return (
    <Table.Row>
      <Table.Cell>{installment.username}</Table.Cell>
      <Table.Cell singleLine>
        {dayjs(installment.payment_term_date).format('DD/MM/YYYY')}
      </Table.Cell>
      <Table.Cell>
        <Label
          size="big"
          color={mappedStatusInstallmentColor[installment.status]}
        >
          {mappedStatusInstallment[installment.status]}
        </Label>
      </Table.Cell>
      <Table.Cell>
        <Label size="big" color={isEnableUploadReceipt ? 'green' : 'orange'}>
          {isEnableUploadReceipt ? 'Sim' : 'Aguardando Upload'}
        </Label>
      </Table.Cell>
      <Table.Cell>{formatPrice(installment.installment_value)}</Table.Cell>
      <Table.Cell>
        {['PENDING', 'CANCELED'].includes(installment.status) ? (
          <Menu compact>
            <Dropdown simple item text="Opções">
              <Dropdown.Menu className="left">
                {isEnableUploadReceipt ? (
                  <>
                    <Dropdown.Item
                      icon="eye"
                      text="Ver Boleto de Pagamento"
                      onClick={() => onDownloadReceipt('invoice_url')}
                    />
                    <Dropdown.Item
                      icon="upload"
                      text="Carregar Comprovante de Pagamento"
                      onClick={() => onOpenReceiptUpload(installment, index)}
                    />
                  </>
                ) : null}
                {isEnableConfirmInstallmentPaidOption ? (
                  <>
                    <Dropdown.Item
                      icon="eye"
                      text="Ver Comprovante de Pagamento"
                      onClick={() => onDownloadReceipt('receipt_url')}
                    />
                    <Dropdown.Item
                      icon="check circle"
                      text="Confirmar Pagamento"
                      onClick={() => confirmInstallmentPaid(installment, index)}
                    />
                  </>
                ) : null}
                {isEnableUploadInvoiceOption ? (
                  <Dropdown.Item
                    icon="upload"
                    text="Carregar Boleto"
                    onClick={() => uploadInvoice(installment, index)}
                  />
                ) : null}
              </Dropdown.Menu>
            </Dropdown>
          </Menu>
        ) : null}
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  index: t.string,
  uploadInvoice: t.func,
  installment: t.objectOf(t.any),
  onOpenReceiptUpload: t.func,
  getSignedUrlToDocument: t.func,
  confirmInstallmentPaid: t.func,
}

export { Item }
