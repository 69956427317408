import React from 'react'

import t from 'prop-types'
import { Button, Form, TextArea, Modal } from 'semantic-ui-react'

const ReproveModal = ({ open, onClose, onOpen, onClick, onChange }) => (
  <Modal open={open} onOpen={onOpen} onClose={onClose} size="small">
    <Modal.Header>Reprovar Transferência</Modal.Header>
    <Modal.Content>
      <Modal.Description>Motivo do Cancelamento</Modal.Description>
      <Form>
        <TextArea
          rows={3}
          placeholder="Ex.: Banco não corresponde"
          onChange={event => onChange(event.target.value)}
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button content="Cancelar" onClick={onClose} />
      <Button content="Confirmar" onClick={onClick} primary />
    </Modal.Actions>
  </Modal>
)

ReproveModal.propTypes = {
  open: t.bool,
  onClose: t.func,
  onOpen: t.func,
  onClick: t.func,
  onChange: t.func,
}

export default ReproveModal
