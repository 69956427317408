import React from 'react'
import DatePicker from 'react-datepicker'

import t from 'prop-types'
import { Button, Card, Form, Modal } from 'semantic-ui-react'

function Actions({
  selectedUsers,
  onChageActions,
  onSubmit,
  timeCheck,
  handleOpenChecksList,
  openModalActions,
  onClose,
}) {
  return (
    <Card fluid>
      <Card.Content>
        <Button
          onClick={handleOpenChecksList}
          content="Alterar Check-In / Check-Out"
          color="facebook"
        />{' '}
        <strong>{selectedUsers.length} </strong>
        {selectedUsers.length === 1
          ? 'usuário selecionado'
          : 'usuários selecionados'}
      </Card.Content>

      <Modal open={openModalActions} onClose={onClose} size="mini">
        <Modal.Header content="Alterar Check-In / Check-Out" />
        <Modal.Content>
          <Form>
            <Form.Group widths="12">
              <Form.Field>
                <Form.Field
                  defaultValue=""
                  label="Data do Registro"
                  control={DatePicker}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data do Registro"
                  selected={timeCheck?.date}
                  onChange={value => onChageActions('date', value)}
                />

                <Form.Field>
                  <Form.Input
                    label="Horário do Registro"
                    placeholder="Exemplo: 14:50"
                    onChange={(e, { value }) => onChageActions('time', value)}
                  />
                </Form.Field>
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancelar" color="black" onClick={onClose} />
          <Button content="Salvar" color="green" onClick={() => onSubmit()} />
        </Modal.Actions>
      </Modal>
    </Card>
  )
}

Actions.propTypes = {
  selectedUsers: t.arrayOf(t.string),
  onChageActions: t.func,
  onSubmit: t.func,
  timeCheck: t.objectOf(t.any),
  handleOpenChecksList: t.func,
  openModalActions: t.bool,
  onClose: t.func,
}

export default Actions
