import styled from 'styled-components'

export const Container = styled.div`
  max-width: 120px;
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #f1f2f3;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const Label = styled.p`
  font-size: 14px;
  font-weight: 900;
`

export const Value = styled.p`
  font-size: 14px;
  font-weight: 900;
`

export const Percentage = styled.p`
  font-size: 13px;
  font-weight: bold;
  color: ${props => (props.isUp ? 'green' : 'red')};
`
