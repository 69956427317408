import React from 'react'

import { format } from 'date-fns'
import t from 'prop-types'
import { isNil } from 'ramda'
import { Icon, Table, Button } from 'semantic-ui-react'

const Item = ({ item, onOpenModalUsersNearby, onClickCurrentParticipants }) => (
  <Table.Row negative={!item.status}>
    <Table.Cell style={{ maxWidth: 150 }}>{item.identifier}</Table.Cell>
    <Table.Cell>{item.name}</Table.Cell>
    <Table.Cell>{item.city}</Table.Cell>
    <Table.Cell>{item.uf}</Table.Cell>
    <Table.Cell>{item.category_name}</Table.Cell>
    <Table.Cell>{item.sub_category_name}</Table.Cell>
    <Table.Cell>{item.participants}</Table.Cell>
    <Table.Cell
      style={{ cursor: 'pointer' }}
      onClick={() => onClickCurrentParticipants(item)}
    >
      {item.current_participant}
    </Table.Cell>
    <Table.Cell>{item.customername}</Table.Cell>
    <Table.Cell>
      {format(new Date(item.expired_at), 'dd/MM/yyyy HH:mm')}
    </Table.Cell>
    <Table.Cell>
      <Button.Group basic size="small">
        {!isNil(item.lat) && !isNil(item.lng) && (
          <Button onClick={() => onOpenModalUsersNearby(item)}>
            <Icon name="search" />
            Buscar Usuários
          </Button>
        )}
      </Button.Group>
    </Table.Cell>
  </Table.Row>
)

Item.propTypes = {
  item: t.objectOf(t.any),
  onOpenModalUsersNearby: t.func,
  onClickCurrentParticipants: t.func,
}

export default Item
