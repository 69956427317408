import { Form, List } from 'semantic-ui-react'
import styled from 'styled-components'

export const SearchForm = styled(Form)`
  display: flex;
  align-items: center;
  margin: 1rem 1.5rem;

  .field.icon {
    flex: 1;
    margin: 0 0.8rem 0 0;

    input {
      padding: 0.85rem;
      font-size: 1.4rem;
    }
  }
`

export const ListItem = styled(List.Item)`
  display: flex !important;
  justify-content: space-between;
  div {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .alignRight {
    align-self: flex-end;
  }
`
