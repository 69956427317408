import React, { useRef } from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Button, Popup, Table } from 'semantic-ui-react'

const [PENDENT_STATUS, APPROVED_STATUS] = [1, 2]

function Item({ item, onChangeUpload }) {
  const uploadRef = useRef('uploadRef')

  function formatMoney(value) {
    return value?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  return (
    <Table.Row key={item.uid}>
      <Table.Cell>{item.charitie_institution_name}</Table.Cell>
      <Table.Cell>{formatMoney(Number(item.value))}</Table.Cell>
      <Table.Cell>{item.user_name}</Table.Cell>
      <Table.Cell>{dayjs(item.created_at).format('DD/MM/YYYY')}</Table.Cell>
      <Table.Cell>
        {item.charities_requests_donate_status_id === PENDENT_STATUS
          ? 'Aguardando Comprovante'
          : 'Aprovado'}
      </Table.Cell>
      <Table.Cell>
        <input
          type="file"
          ref={uploadRef}
          hidden
          onChange={({ target }) => onChangeUpload(item, target.files[0])}
        />
        <Popup
          content="Enviar Comprovante"
          trigger={
            <Button
              primary
              icon="upload"
              disabled={
                item.charities_requests_donate_status_id === APPROVED_STATUS
              }
              onClick={() => uploadRef.current.click()}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  item: t.arrayOf(t.string),
  onChangeUpload: t.func,
}

export default Item
