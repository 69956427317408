import { useState } from 'react'

import * as Yup from 'yup'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useMissionsUsersCheckInCheckOut() {
  const [checkInData, setCheckInData] = useState([])
  const [checkOutData, setCheckOutData] = useState([])
  const [missionsUsers, setMissionsUsers] = useState([])
  const [needCreate, setNeedCreate] = useState([])
  const [loadingCheckInOut, setLoadingCheckInOut] = useState(false)

  async function getMissionsUsersCheckInCheckOut(missionsUsersId) {
    try {
      setLoadingCheckInOut(true)
      const res = await api.get(
        `missions-users-check-in-check-out/${missionsUsersId}`,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const dataLength = res.data.length
      const missionsUsersCheckIn = []
      const missionsUsersCheckOut = []

      for (let i = 0; i < dataLength; i += 1) {
        const item = res.data[i]
        if (item.check_types_id === 1) {
          missionsUsersCheckIn.push({ action: 'check_in', ...item })
        }

        if (item.check_types_id === 2) {
          missionsUsersCheckOut.push({ action: 'check_out', ...item })
        }
      }

      setCheckInData(missionsUsersCheckIn)
      return setCheckOutData(missionsUsersCheckOut)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingCheckInOut(false)
    }
  }

  async function updateOrCreateMissionsUsersCheckInCheckOut(params) {
    try {
      const schema = Yup.object().shape({
        time: Yup.string().matches(
          /^([01]\d|2[0-3]):([0-5]\d)$/,
          'O horário deve estar no formato HH:MM'
        ),
      })

      const isValid = schema.isValidSync(params)

      if (!isValid) {
        const validate = schema.validateSync(params)
        throw new Error(validate)
      }

      const res = await api.put(
        'missions-users-check-in-check-out/admin',
        params,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      Message().success('Operação realizada com sucesso')

      return res.status
    } catch (error) {
      return Message().error(error.message)
    }
  }

  async function getManyMissionsUsersCheckInCheckOut(params) {
    try {
      const res = await api.get(
        'missions-users-check-in-check-out/admin/many-missions-users',
        {
          params,
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setNeedCreate(res.data?.needCreateCheckInCheckOut)
      return setMissionsUsers(res.data?.updatedMissionsUsersCheckInCheckOut)
    } catch (error) {
      return Message().error(error.message)
    }
  }

  return {
    getMissionsUsersCheckInCheckOut,
    checkInData,
    checkOutData,
    updateOrCreateMissionsUsersCheckInCheckOut,
    getManyMissionsUsersCheckInCheckOut,
    missionsUsers,
    needCreate,
    loadingCheckInOut,
  }
}
