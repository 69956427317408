import React from 'react'

import { format } from 'date-fns'
import t from 'prop-types'
import { Table, Popup, Button } from 'semantic-ui-react'

const ButtonPop = ({ icon, onUpdate, loading, description }) => (
  <Popup
    inverted
    content={description}
    trigger={
      <Button
        icon={icon}
        onClick={onUpdate}
        loading={loading}
        disabled={loading}
        positive={icon === 'check'}
      />
    }
  />
)

ButtonPop.propTypes = {
  icon: t.string,
  loading: t.bool,
  onUpdate: t.func,
  description: t.string,
}

const Item = ({ item, loading, onUpdate }) => (
  <Table.Row
    warning={item.status === 0}
    positive={item.status === 1}
    negative={item.status === 2}
  >
    <Table.Cell>{item.customerName}</Table.Cell>
    <Table.Cell>{item.customerEmail}</Table.Cell>
    <Table.Cell>{item.payment_type}</Table.Cell>
    <Table.Cell>
      {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
    </Table.Cell>
    <Table.Cell>
      {item.updated_at && format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
    </Table.Cell>
    <Table.Cell>R$ {item.value}</Table.Cell>
    <Table.Cell textAlign="center" style={{ width: 150 }}>
      {item.status === 0 && (
        <div>
          <ButtonPop
            loading={loading}
            description="Confirmar Pagamento"
            onUpdate={() =>
              onUpdate({
                status: 1,
                uid: item.uid,
                email: item.customerEmail,
                customer_id: item.customer_id,
              })
            }
            icon="check"
          />
          <ButtonPop
            icon="remove"
            loading={loading}
            description="Cancelar"
            onUpdate={() =>
              onUpdate({
                status: 2,
                uid: item.uid,
                email: item.customerEmail,
                customer_id: item.customer_id,
              })
            }
          />
        </div>
      )}
    </Table.Cell>
  </Table.Row>
)

Item.propTypes = {
  loading: t.bool,
  onUpdate: t.func,
  item: t.objectOf(t.any),
}

export default Item
