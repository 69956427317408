import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

import createStore from './createStore'
import rootReducer from './modules/rootReducer'
import persistReducers from './persistReducers'

const middlewares = [thunk]

const store = createStore(persistReducers(rootReducer), middlewares)
const persistor = persistStore(store)

export { store, persistor }
