import React from 'react'

import PropTypes from 'prop-types'

import Loading from '~/components/Loading/index'

export default function ContainerLoading({ children, isLoading, customText }) {
  if (isLoading) {
    return (
      <>
        <Loading customText={customText} />
        <br />
      </>
    )
  }

  return <>{children}</>
}

ContainerLoading.propTypes = {
  children: PropTypes.element,
  isLoading: PropTypes.bool,
  customText: PropTypes.string,
}

ContainerLoading.defaultProps = {
  isLoading: false,
  customText: undefined,
}
