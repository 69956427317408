import { createGlobalStyle } from 'styled-components'

import 'semantic-ui-css/semantic.min.css'

export default createGlobalStyle`
  body {
    -webkit-font-smoothing: antialiased !important;
    margin: 0;
    padding: 0;

    background-color: #f7f7fc !important;
  }

  div, span, p, h1, h2, h3, h4, h5, h6 {
    // font-family: inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol" !important;
  }

  html {
    font-size: 62.5%; /* 1rem = 10px */

    @media (min-width: 1981px) {
      font-size: 80%;
    };

    @media (max-width: 968px) {
      font-size: 60%;
    };

    @media (max-width: 768px) {
      font-size: 58%;
    };

    @media (max-width: 600px) {
      font-size: 55%;
    };
  }

  .ui.vertical.menu .item > i.icon {
    width: 1.18em;
    float: left !important;
    margin: 0 11px 0 0 !important;
  }

  .ui.vertical.menu {
    display: flex;
    width: fit-content;
    flex-shrink: 0;

    @media (max-width: 767px) {
      align-items: center;

      .item {
        > i.icon {
          margin: 0.5rem 0 !important;
          font-size: 2rem;
        }
      }
    }
  }

  .react-datepicker-popper {
    z-index: 99999 !important;
  }

  .flex {
    width: '100%' !important;
    overflow: hidden;
  }

  .flex-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
  }

  #root {
    height: 100% !important;
  }

  .App-header {
    margin: 0 auto;
    width: 100%;
  }

  .ui.search > .results .result > .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 150px;
    margin-top: 120px;
  }

  .text-field {
    width: 200px;
    color: white;
  }

  .login-form {
    margin: 0 auto;
    width: 300px;
    text-align: center;
  }

  .App-title {
    font-size: 1.6rem;
    color: white;
  }

  .App-intro {
    font-size: large;
  }

  .card-custom {
    height: 347px;
    overflow-y: auto;
  }

  @keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  .flex {
    overflow-x:auto;
    white-space:nowrap;
    max-width: 100%;
  }

  .editor {
    border: 1px solid #22242626
  }

  .fixed-order {
    max-height: 500px;
    overflow: auto;
  }

  .custom-small-font {
    font-size: 9px !important;
  }

  .header-side-bar {
    font-size: 1.3rem;
    margin-left: 5px;
    max-width: 135px;
    white-space: normal;
    vertical-align: middle;
    display: -webkit-inline-box;
  }

  .left-border {
    border-top: 1px solid #f1f2f3;
    padding-top: 10px;
  }

  .ui.small.modal {
    > .header:not(.ui) {
      font-size: 1.5rem;
    }
  }

  .ui.modal {
    top: 10% !important;

    .content {
      font-size: 1.4rem;
    }
  }

  .ui.breadcrumb {
    font-size: 1.6rem;
  }

  .ui.dropdown .menu .text {
    font-size: 1.25rem;
  }

  .ui.menu:not(.vertical) .item>.button {
    font-size: 1.3rem;

    @media (max-width: 768px) {
      margin: 0.5rem 0;
    }
  }

  .ui.menu {
    font-size: 1.4rem;

    @media (max-width: 767px) {
      font-size: 1rem;
    }
  }

  .side-bar {
    /* background-color: #033259 !important; */
    /* overflow-y: auto; */
    background-color: #fcfcff !important;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1;

    @media (max-width: 767px) {
      .item span, .item div.divider {
        display: none;
        visibility: hidden;
      }
    }
  }

  .ui.vertical.menu div.dropdown.item:hover {
    color: #647384 !important;
    background: #f7f7fc !important;
  }

  .ui.vertical.menu div.dropdown.item:active {
    background: rgba(255,255,255,.08);
  }

  .ui.vertical.menu .dropdown.item .menu {
    min-width: 14rem;

    @media (max-width: 767px) {
      .item {
        font-size: 1.6rem !important;
        padding: 1.2rem !important;

        span {
          display: unset;
          visibility: visible;
        }
      }
    }
  }

  .ui.checkbox label {
    font-size: 1.4rem;
  }

  .overflow {
    overflow-x: auto !important;
  }

  .container {
    /* padding-right: 8%; */
  }

  #row:hover {
    cursor: pointer;
    background-color: #f7f7fc;
  }

  .grid-size {
    margin-left: 220px !important;
    /*min-width: 550px !important;*/
    width: 100%;
    z-index: 0;

    @media (max-width: 767px) {
      margin-left: 7rem !important;
    }
  }

  .content-main {
    padding-top: 30px;
    margin-right: 1.8rem !important
  }

  .ui.card [class*="center aligned"] {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ui.inverted.menu .item, .ui.inverted.menu .item>a:not(.ui),
  .side-bar > a:hover,
  .ui.inverted.menu .active.item:hover,
  .side-bar > a {
    color: #637282 !important;
  }

  .ui.secondary.vertical.pointing.menu .active.item {
    border-color: white !important;
  }

  .ui.inverted.menu .active.item {
    background: #f7f7fc;
    color: #0332597d !important;
  }

  .ui.inverted.menu a.item:hover {
    color: #647384 !important;
    background: #f7f7fc !important;
  }

  /* .ui.inverted.menu .item, .ui.inverted.menu .item > a:not(.ui) {
    color: white
  } */

  .ui.inverted.pointing.menu .active.item:after {
    background: #f7f7fc !important;
  }

  .ui.search.dropdown {
    min-width: 0;
  }

  .search {
    width: 100%
  }

  .border {
    border: 1px solid black;
  }

  .board {
    background-color: 'white';
    position: relative;
    border: 1px solid gray;
    padding: 10px;
  }

  .recharts-wrapper {
    width: 100% !important;
    text-align: center !important;
  }

  .registers-activities .play {
    height: 32px !important;
    background: #FFBF00 !important;
  }

  .registers-activities .photo {
    height: 32px !important;
    background: #5449D2 !important;
  }

  .registers-activities .sign {
    height: 32px !important;
    background: #F64744 !important;
  }

  .registers-activities .check {
    height: 32px !important;
    background: #66B34E !important;
  }

  .registers-activities .linkify {
    height: 32px !important;
    background: #FE825A !important;
  }

  .registers-activities .file {
    height: 32px !important;
    background: #3880F4 !important;
  }

  .registers-activities > i {
    color: white !important;
    border-radius: 100% !important;
    vertical-align: text-top !important;
  }

  .column .react-datepicker-manager,
  .column .react-datepicker-wrapper,
  .column .react-datepicker__input-container {
    width: 100%;

    #contract_start {
      width: 100%;
    }
  }

  .tab-segment {
    background: #033259 !important;
  }

  .ui.segment {
    position: relative;
    box-shadow: none !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
    border: 0 !important;
  }

  .pdf-file {
    width: 100%;
  }

  .ui.statistic > .value, .ui.statistics .statistic > .value {
    font-weight: 800 !important;
    color: #0332597d;
    font-size: 4.6rem !important;
  }

  .ui.segment.active.tab {
    background-color: transparent;
  }

  .table-resize {
    font-size: 12px !important;
  }

  .ui.celled.table th.th-has-consultant:nth-child(4) {
    max-width: 125px;
  }

  .enable-scroll-horizontally {
    overflow-y: scroll;
  }

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }

  .spin {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
`
