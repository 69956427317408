import React from 'react'

import t from 'prop-types'

const Filters = ({ changePeriod, isSelectedPeriod }) => (
  <div className="ui tiny basic buttons">
    <button
      type="button"
      onClick={() => changePeriod('yesterday')}
      className={`ui button ${isSelectedPeriod('yesterday') ? 'active' : ''}`}
    >
      1 dia
    </button>
    <button
      type="button"
      onClick={() => changePeriod('day')}
      className={`ui button ${isSelectedPeriod('day') ? 'active' : ''}`}
    >
      30 dias
    </button>
    <button
      type="button"
      onClick={() => changePeriod('week')}
      className={`ui button ${isSelectedPeriod('week') ? 'active' : ''}`}
    >
      90 dias
    </button>
    <button
      type="button"
      onClick={() => changePeriod('month')}
      className={`ui button ${isSelectedPeriod('month') ? 'active' : ''}`}
    >
      24 meses
    </button>
    <button
      type="button"
      onClick={() => changePeriod('year')}
      className={`ui button ${isSelectedPeriod('year') ? 'active' : ''}`}
    >
      Tudo
    </button>
  </div>
)

Filters.propTypes = {
  changePeriod: t.func,
  isSelectedPeriod: t.func,
}

export default Filters
