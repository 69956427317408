import React from 'react'

import { Segment, Dimmer, Loader } from 'semantic-ui-react'

const Loading = ({ customText }) => (
  <Segment>
    <Dimmer active inverted>
      <Loader inverted>{ (customText ? customText : 'Carregando...') }</Loader>
    </Dimmer>
  </Segment>
)

export default Loading
