import { cond, equals, always, assoc } from 'ramda'

const initialState = {
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const dataUpdate = assoc('data', {}, initialState)
const dataOrder = assoc('data', false, initialState)

const FORMS_MISSIONS_CREATE = 'FORMS_MISSIONS_CREATE'
const FORMS_MISSIONS_CREATE_OK = 'FORMS_MISSIONS_CREATE_OK'
const FORMS_MISSIONS_CREATE_FAIL = 'FORMS_MISSIONS_CREATE_FAIL'

const FORMS_MISSIONS_UPDATE = 'FORMS_MISSIONS_UPDATE'
const FORMS_MISSIONS_UPDATE_OK = 'FORMS_MISSIONS_UPDATE_OK'
const FORMS_MISSIONS_UPDATE_FAIL = 'FORMS_MISSIONS_UPDATE_FAIL'

const formsMissionsUpdate = (state = dataUpdate, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_MISSIONS_UPDATE), always({ ...state, isFetching: true })],
    [
      equals(FORMS_MISSIONS_UPDATE_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_MISSIONS_UPDATE_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

const formsMissionsCreate = (state = dataOrder, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_MISSIONS_CREATE), always({ ...state, isFetching: true })],
    [
      equals(FORMS_MISSIONS_CREATE_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_MISSIONS_CREATE_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

export default {
  formsMissionsUpdate,
  formsMissionsCreate,
}
