import React from 'react'

import logo from '~/assets/logo-dark.png'

export default function LoadingPage() {
  return (
    <div style={{ textAlign: 'center' }}>
      <img
        style={{ width: '80px', height: '80px' }}
        src={logo}
        className="App-logo"
        alt="logo"
      />
      <p style={{ color: 'black' }}>Carregando...</p>
    </div>
  )
}
