import { useState } from 'react'

import dayjs from 'dayjs'
import * as Yup from 'yup'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useMissionsUsersCheckInCheckOut() {
  const [loadingMissionsUsersRating, setLoadingMissionsUsersRating] =
    useState(false)
  const [missionsUsersRating, setMissionsUsersRating] = useState([])

  async function getMissionsUsersRatingByMissionsUsersId(params) {
    try {
      setLoadingMissionsUsersRating(true)

      const res = await api.get('missions-users-rating/admin', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setMissionsUsersRating(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingMissionsUsersRating(false)
    }
  }

  async function createMissionsUsersRating(params) {
    try {
      setLoadingMissionsUsersRating(true)

      const schema = Yup.object().shape({
        date: Yup.string().required('A data é obrigatória'),
        rating: Yup.number().required('A avaliação é obrigatória'),
        description: Yup.string().required('A descrição é obrigatória'),
        missionsUsersId: Yup.string()
          .uuid('O missionsUsersId está em um formato inválido')
          .required('O missionsUsersId é obrigatório'),
      })

      const isValid = schema.isValidSync(params)

      if (!isValid) {
        const validate = schema.validateSync(params)
        throw new Error({ message: validate })
      }

      const res = await api.post('missions-users-rating/admin', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      getMissionsUsersRatingByMissionsUsersId({
        date: dayjs(params.date).format('YYYY-MM-DD'),
        missionsUsersId: params.missionsUsersId,
      })

      return Message().success('Avaliação criada com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingMissionsUsersRating(false)
    }
  }

  return {
    getMissionsUsersRatingByMissionsUsersId,
    loadingMissionsUsersRating,
    missionsUsersRating,
    createMissionsUsersRating,
  }
}
