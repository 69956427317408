import React from 'react'
import DatePicker from 'react-datepicker'

import t from 'prop-types'
import { Menu, Icon, Select, Button, Input } from 'semantic-ui-react'

import CloseRequest from '../CloseRequest'

import '~/assets/css/react-datepicker.css'

const ID_LICENSED_PAYMENT_MODEL = 6

const Filter = ({
  filters,
  loading,
  customers,
  onSubmit,
  onChange,
  loadingCustomers,
  onCreateCreditRequestLicensedPayment,
  onCreateCloseRequestPayment,
  modalCloseRequest,
  setModalCloseRequest,
}) => {
  function isDisabledUntilFiltersFill() {
    return !(
      filters.customerId !== null &&
      filters.final !== null &&
      filters.initial !== null
    )
  }

  function isDisabledUntilFillFieldsRequiredToCreditLicensedPayment() {
    return !(filters.valuePerUser != null && filters.totalLicenses != null)
  }

  function isLicensedPaymentModel() {
    const customer = customers.find(item => item.value === filters.customerId)
    if (!customer) {
      return false
    }

    return customer.customer_payment_type === ID_LICENSED_PAYMENT_MODEL
  }

  return (
    <>
      <Menu>
        <Menu.Item>
          <Icon name="filter" />
        </Menu.Item>
        <Menu.Item>
          <div className="ui input">
            <DatePicker
              id="initial"
              selectsStart
              dateFormat="dd/MM/yyyy 00:00:00"
              placeholderText="Data Inicio"
              selected={filters.initial}
              startDate={filters.initial}
              endDate={filters.final}
              onChange={value => onChange('initial', value)}
            />
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="ui input">
            <DatePicker
              id="final"
              selectsEnd
              dateFormat="dd/MM/yyyy 23:59:59"
              placeholderText="Data Fim"
              selected={filters.final}
              startDate={filters.initial}
              endDate={filters.final}
              onChange={value => onChange('final', value)}
            />
          </div>
        </Menu.Item>
        <Menu.Item>
          <Select
            search
            options={customers}
            placeholder="Clientes"
            style={{ minWidth: 200 }}
            loading={loadingCustomers}
            onChange={(e, { value }) => onChange('customerId', value)}
          />
        </Menu.Item>
        {isLicensedPaymentModel() && (
          <Menu.Item position="right">
            <Button
              content="Gerar Requisição de Fechamento"
              color="green"
              icon="check circle outline"
              labelPosition="left"
              disabled={isDisabledUntilFiltersFill() || loading}
              onClick={() => setModalCloseRequest(true)}
            />
            <CloseRequest
              open={modalCloseRequest}
              onClose={() => setModalCloseRequest(false)}
              onChange={(e, { value }) => onChange('taxValue', value)}
              onClick={onCreateCloseRequestPayment}
            />
          </Menu.Item>
        )}
        {!isLicensedPaymentModel() && (
          <Menu.Item position="right">
            <Button
              icon
              color="orange"
              loading={loading}
              onClick={onSubmit}
              disabled={loading}
              labelPosition="left"
            >
              <Icon name="check circle outline" />
              Gerar Requisição Para Pós-Pago
            </Button>
          </Menu.Item>
        )}
      </Menu>

      {isLicensedPaymentModel() && (
        <Menu>
          <Menu.Item>
            <Input
              type="number"
              placeholder="Número de Licenciamentos"
              style={{ minWidth: 150 }}
              onChange={(e, { value }) => onChange('totalLicenses', value)}
            />
          </Menu.Item>
          <Menu.Item>
            <Input
              placeholder="Valor por Licença(Usuários)"
              style={{ minWidth: 150 }}
              onChange={(e, { value }) => onChange('valuePerUser', value)}
            />
          </Menu.Item>

          <Menu.Item position="right">
            <Button
              icon
              color="orange"
              loading={loading}
              onClick={onCreateCreditRequestLicensedPayment}
              disabled={
                isDisabledUntilFillFieldsRequiredToCreditLicensedPayment() ||
                loading
              }
              labelPosition="left"
            >
              <Icon name="check circle outline" />
              Gerar Requisição Para Pós-Pago
            </Button>
          </Menu.Item>
        </Menu>
      )}
    </>
  )
}

Filter.propTypes = {
  filters: t.objectOf(t.any),
  loading: t.bool,
  onCreateCreditRequestLicensedPayment: t.func,
  onSubmit: t.func,
  onChange: t.func,
  customers: t.arrayOf(t.any),
  loadingCustomers: t.bool,
  onCreateCloseRequestPayment: t.func,
  modalCloseRequest: t.bool,
  setModalCloseRequest: t.func,
}

export default Filter
