import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useFinancialCustomers() {
  const [hasPaymentConfig, setHasPaymentConfig] = useState(false)
  const [customerPaymentConfig, setCustomerPaymentConfig] = useState(null)
  const [loadingCustomersPayment, setLoadingCustomersPayment] = useState(true)

  function onChange(key, value) {
    return setCustomerPaymentConfig(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  const getCustomersPaymentConfig = useCallback(async params => {
    setLoadingCustomersPayment(true)
    try {
      const res = await api.get('customers-payment-config/admin', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setHasPaymentConfig(res.data !== null)

      return setCustomerPaymentConfig(res.data || {})
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingCustomersPayment(false)
    }
  }, [])

  async function onSubmitPaymentConfig(data) {
    setLoadingCustomersPayment(true)
    try {
      const res = await api.post('customers-payment-config', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.data)
      }

      return Message().success('Dados Financeiros criados com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingCustomersPayment(false)
      getCustomersPaymentConfig({ customerId: data.customer_id })
    }
  }

  async function onUpdatePaymentConfig(data) {
    setLoadingCustomersPayment(true)
    try {
      const res = await api.put('customers-payment-config', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.data)
      }

      return Message().success('Dados Financeiros atualizados com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingCustomersPayment(false)
      getCustomersPaymentConfig({ customerId: data.customer_id })
    }
  }

  return {
    onChange,
    hasPaymentConfig,
    customerPaymentConfig,
    onUpdatePaymentConfig,
    onSubmitPaymentConfig,
    loadingCustomersPayment,
    getCustomersPaymentConfig,
  }
}
