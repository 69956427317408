import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useSharedLink() {
  const [extraData, setExtraData] = useState([])
  const [isGeneratingSharedLink, setIsGeneratingSharedLink] = useState(false)
  const [openModalSharedLinkGenerator, setOpenModelSharedLinkGenerator] =
    useState(false)
  const [dataToGenerateLinks, setDataToGenerateLinks] = useState({
    linkCampaign: '',
    missionMainId: '',
  })
  async function getSharedLinkExtraDataByMissionUserId(missionUserId) {
    try {
      const response = await api.get(
        `/shared-links-missions-users/${missionUserId}`
      )
      setExtraData(response.data)
      return null
    } catch (error) {
      return Message().error(error.message)
    }
  }

  async function generate() {
    try {
      setIsGeneratingSharedLink(true)
      await api.post('/shared-links/generate', dataToGenerateLinks)
      setDataToGenerateLinks({ ...dataToGenerateLinks, linkCampaign: '' })
      Message().success('Links de compartilhamento foram gerados com sucesso.')
      setOpenModelSharedLinkGenerator(false)
      return
    } catch (error) {
      if (error.response) {
        Message().error(error.response.data.message)
      } else {
        Message().error('Oops! Ocorreu um erro interno.')
      }
    } finally {
      setIsGeneratingSharedLink(false)
    }
  }

  return {
    getSharedLinkExtraDataByMissionUserId,
    extraData,
    generate,
    openModalSharedLinkGenerator,
    setOpenModelSharedLinkGenerator,
    setDataToGenerateLinks,
    dataToGenerateLinks,
    isGeneratingSharedLink,
  }
}
