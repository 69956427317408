import React from 'react'
import DatePicker from 'react-datepicker'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import t from 'prop-types'
import { always, cond, equals } from 'ramda'
import { Card, Button, Popup, Form } from 'semantic-ui-react'

import avatarImg from '~/assets/avatar.png'

import { Container, Row, Avatar } from './styles'

const color = cond([
  [equals('Iniciado'), always('blue')],
  [equals('Concluído'), always('green')],
  [equals('Rejeitado'), always('red')],
  [equals('Expirado'), always('red')],
  [equals('Excluído'), always('red')],
  [equals('Desistência'), always('red')],
  [equals('Missão Incompleta'), always('orange')],
])

function CardItem({
  mission,
  onClick,
  onClickUpdate,
  onClickFinished,
  new_expiration,
  handleExpiration,
  handleChangeUser,
  isAdminAndLeader,
}) {
  const history = useHistory()

  const avatar = mission.avatar === null ? avatarImg : mission.avatar

  return (
    <Container>
      <Card.Content>
        <Avatar avatar floated="left" size="massive" src={avatar} />
        <Card.Header>{mission.user_name}</Card.Header>
        <Card.Meta>{mission.email}</Card.Meta>
        <Card.Meta
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={() =>
            window.open(`https://wa.me/${mission.phone.replace('+', '')}`)
          }
        >
          {mission.phone}
        </Card.Meta>
        <Card.Description>
          <Row>
            <strong>MISSAO: </strong>
            <span>{mission.mission_name} </span>
          </Row>
          <Row>
            <strong>STATUS: </strong>
            <span style={{ color: color(mission.mu_progress) }}>
              {mission.mu_progress}
            </span>
          </Row>
          <Row>
            <strong>MB: </strong>
            <span>{mission.mission_key} </span>
          </Row>
          <Row>
            <strong>MU: </strong>
            <span>{mission.mu_key} </span>
          </Row>
          <Row>
            <strong>Cidade: </strong>
            <span>{mission.city} </span>
          </Row>
          <Row>
            <strong>Estado: </strong>
            <span>{mission.uf} </span>
          </Row>
          <Row>
            <strong>EXP. MISSAO: </strong>
            <span>
              {format(new Date(mission.expired_at), 'dd/MM/yyyy HH:mm')}
            </span>
          </Row>
          <Row>
            <strong>EXP. USUARIO: </strong>
            <span style={{ cursor: 'pointer' }}>
              <Popup
                flowing
                on="click"
                trigger={
                  <span style={{ color: 'red' }}>{mission.time_to_expire}</span>
                }
              >
                <Form>
                  <Form.Field
                    minDate={new Date()}
                    control={DatePicker}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Alterar Expiração"
                    selected={new_expiration}
                    onChange={value =>
                      handleExpiration(value, mission.missions_users_id)
                    }
                  />
                  <Button content="Alterar" onClick={() => onClickUpdate()} />
                </Form>
              </Popup>
            </span>
          </Row>
        </Card.Description>
      </Card.Content>
      {(mission.mu_progress === 'Iniciado' ||
        mission.mu_progress === 'Missão Incompleta') &&
        isAdminAndLeader && (
          <Card.Content extra>
            <div className="ui four buttons">
              <Button
                icon="exchange"
                color="green"
                content="Redirecionar"
                onClick={handleChangeUser}
                className="flex-center"
              />
              <Button
                primary
                icon="info circle"
                content="Ver Progresso"
                onClick={() =>
                  history.push(
                    `/missions/progress/${mission.missions_users_id}`
                  )
                }
                className="flex-center"
              />
              <Button
                negative
                icon="trash"
                color="red"
                content="Excluir da Missão"
                onClick={() => onClick(mission)}
                className="flex-center"
              />

              <Button
                icon="flag checkered"
                color="orange"
                content="Finalizar Missão"
                onClick={() => onClickFinished(mission.missions_users_id)}
                className="flex-center"
              />
            </div>
          </Card.Content>
        )}
    </Container>
  )
}

CardItem.propTypes = {
  mission: t.arrayOf(t.any),
  onClick: t.func,
  onClickUpdate: t.func,
  onClickFinished: t.func,
  new_expiration: t.string,
  handleExpiration: t.func,
  handleChangeUser: t.func,
  isAdminAndLeader: t.bool,
}

export default CardItem
