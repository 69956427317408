import React from 'react'

import t from 'prop-types'
import {
  Message as MessageComponent,
  MessageHeader,
  MessageList,
  MessageItem,
} from 'semantic-ui-react'

const EXPORTATION_IMPORT_FILE = {
  PENDENT: 'Pendente',
  IN_PROGRESS: 'Em progresso',
  DONE: 'Finalizado',
}

export default function QueueExportationMissionsImported({ exportations }) {
  const hasShow = exportations.length > 0

  if (!hasShow) {
    return <></>
  }
  return (
    <MessageComponent>
      <MessageHeader>Fila de exportações de missões</MessageHeader>
      <MessageList>
        {exportations.map(item => (
          <MessageItem key={item.import_file_id}>
            A exportação das missões da <strong>{item.import_name}</strong> está{' '}
            <strong>{EXPORTATION_IMPORT_FILE[item.status]}</strong>. &nbsp;
            {item.url && (
              <a rel="noopener noreferrer" target="_blank" href={item.url}>
                Clique aqui para baixar a exportação
              </a>
            )}
          </MessageItem>
        ))}
      </MessageList>
    </MessageComponent>
  )
}

QueueExportationMissionsImported.propTypes = {
  exportations: t.arrayOf(t.any),
}
