import React, { useEffect, useState } from 'react'

import { Grid, Header } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useCharitiesIntitutions from '~/hooks/useCharitiesIntitutions'

import Filter from './components/Filter'
import Item from './components/Item'
import TotalCards from './components/TotalCards'

function CharitiesIntitutionsRequests() {
  const {
    getTotalDonateRequests,
    totalDonateRequest,
    countDonateRequest,
    requestsData,
    loading,
    uploadInvoice,
  } = useCharitiesIntitutions()

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    activePage: 1,
  })

  const [filters, setFilters] = useState({
    charitiesRequestsDonateStatusId: 1,
  })

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * pagination.limit
    setPagination(prevState => ({
      ...prevState,
      activePage,
      offset,
      limit: pagination.limit,
    }))
    return getTotalDonateRequests({
      ...filters,
      offset,
      limit: pagination.limit,
    })
  }

  function handleOnChangeFilter(key, value) {
    setFilters(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  function handleOnClickSubmit() {
    getTotalDonateRequests(filters)
  }

  function handleOnClickClear() {
    setFilters({
      search: '',
      date: '',
      charitiesRequestsDonateStatusId: 1,
    })

    getTotalDonateRequests({
      charitiesRequestsDonateStatusId: 1,
    })
  }

  async function handleOnChangeUpload(itemData, file) {
    const { uid: charitiesRequestsDonateId } = itemData

    await uploadInvoice({ charitiesRequestsDonateId, file })
    getTotalDonateRequests({ ...pagination, ...filters })
  }

  useEffect(() => {
    getTotalDonateRequests({ ...pagination, ...filters })
  }, []) // eslint-disable-line

  return (
    <>
      <Header>Solicitações de Doações</Header>
      <Grid columns={4} stackable>
        <Grid.Row>
          <TotalCards
            label="Doações Pendentes"
            value={totalDonateRequest.totalDonatePendent}
          />
          <TotalCards
            label="Doações Aprovadas"
            value={totalDonateRequest.totalDonateApproved}
          />
          <TotalCards
            isMoney
            label="Valor Pendente"
            value={totalDonateRequest.totalDonateValuePendent}
          />
          <TotalCards
            isMoney
            label="Valor Aprovado"
            value={totalDonateRequest.totalDonateValueApproved}
          />
        </Grid.Row>
      </Grid>

      <Filter
        filters={filters}
        onChangeFilter={handleOnChangeFilter}
        onClickSubmit={handleOnClickSubmit}
        onClickClear={handleOnClickClear}
      />

      <TableComponent
        isLoading={loading}
        columns={['Instituição', 'Valor', 'Doador', 'Data', 'Status', 'Ações']}
        data={requestsData}
        renderItem={item => (
          <Item item={item} onChangeUpload={handleOnChangeUpload} />
        )}
        emptyText={{
          icon: 'box',
          text: 'Nenhuma Doação foi Encontrada',
        }}
        count={countDonateRequest}
        totalCount={countDonateRequest}
        showFirstAndLastNav
        showPreviousAndNextNav
        activePage={pagination.activePage}
        limit={pagination.limit}
        offset={pagination.offset}
        handlePaginationChange={handlePaginationChange}
      />
    </>
  )
}

export default CharitiesIntitutionsRequests
