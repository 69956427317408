import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  flex-direction: row;
`

export const Title = styled.span`
  font-size: 20px;
  font-weight: '900';
`
