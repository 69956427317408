import React from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Checkbox, Dropdown, Table } from 'semantic-ui-react'

import history from '~/services/history'

function Item({ item, onClickActions, isChecked, onClickCheckBox }) {
  return (
    <Table.Row>
      <Table.Cell>
        <Checkbox
          checked={isChecked}
          onClick={(e, { checked }) => onClickCheckBox(checked, item)}
        />
      </Table.Cell>
      <Table.Cell
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(`/user/${item?.user_id}`)}
      >
        {item?.username?.split(' ')[0]}
      </Table.Cell>
      <Table.Cell>{item?.phone}</Table.Cell>
      <Table.Cell>{item?.email}</Table.Cell>
      <Table.Cell>{item?.badge}</Table.Cell>
      <Table.Cell>
        {dayjs(item?.created_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>
        {dayjs(item?.updated_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Dropdown icon="ellipsis vertical" floating>
          <Dropdown.Menu direction="left">
            {item.status !== 'PENDENT' && !item.answers && (
              <Dropdown.Item icon="close" text="Não há ação disponível" />
            )}
            {item.status === 'PENDENT' && (
              <>
                <Dropdown.Item
                  icon="check"
                  text="Aprovar"
                  onClick={() => onClickActions('approve', item)}
                />
                <Dropdown.Item
                  icon="cancel"
                  text="Reprovar"
                  onClick={() => onClickActions('reprove', item)}
                />
              </>
            )}
            {item.answers && (
              <Dropdown.Item
                icon="list"
                text="Ver Respostas"
                onClick={() => onClickActions('answers', item)}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  item: t.arrayOf(t.any),
  onClickActions: t.func,
  isChecked: t.bool,
  onClickCheckBox: t.func,
}

export default Item
