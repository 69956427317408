import React, { useCallback, useEffect, useState } from 'react'

import t from 'prop-types'
import { Dropdown } from 'semantic-ui-react'

import api from '~/services/api'
import messages from '~/utils/messages'

const SeachFormsItems = ({ formId, onChange }) => {
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)

  const getItems = useCallback(async () => {
    try {
      setLoading(true)
      const res = await api.get(`/forms-items/${formId}`)

      setLoading(false)
      return setResults(
        res.data.map(item => ({
          key: item.uid,
          value: item,
          text: `${item.order}. ${item.question}`,
        }))
      )
    } catch (error) {
      return messages().error(error.message)
    }
  }, [results]) //eslint-disable-line

  useEffect(() => {
    getItems()
  }, []) //eslint-disable-line

  return (
    <Dropdown
      placeholder="Selecione a Pergunta"
      fluid
      search
      selection
      loading={loading}
      onChange={(e, { value }) => onChange(value)}
      options={results}
    />
  )
}

SeachFormsItems.propTypes = {
  formId: t.string.isRequired,
  onChange: t.func.isRequired,
}

export default SeachFormsItems
