import React from 'react'

import t from 'prop-types'
import { Button, Message, Modal } from 'semantic-ui-react'

export function OptionsInfo({ open, onClose }) {
  const infos = [
    {
      typeTitle: 'Expirando',
      description: [
        'Missões que não foram deletadas',
        'Missions Users apenas em progresso: Iniciado',
        'O intervaldo de datas considera a data de expiração da missão',
      ],
    },
    {
      typeTitle: 'Reprovadas',
      description: [
        'Missões que não foram deletadas',
        'Missions Users apenas em progresso: Concluído',
        'O intervaldo de datas considera a data de atualização do registro',
        'Registro apenas com status: Reprovado',
      ],
    },
    {
      typeTitle: 'Aprovadas',
      description: [
        'Missões que não foram deletadas',
        'Missions Users apenas em progresso: Concluído',
        'O intervaldo de datas considera a data de atualização do registro',
        'Registro apenas com status: Aprovado',
      ],
    },
    {
      typeTitle: 'Finalizado',
      description: [
        'Missões que não foram deletadas',
        'O intervaldo de datas considera a data de criação do registro',
        'Missions Users em progresso: Concluído, Rejeitado, Excluído, Missão Incompleta',
      ],
    },
    {
      typeTitle: 'Iniciado',
      description: ['Missões que não foram deletadas'],
    },
    {
      typeTitle: 'Criado',
      description: [
        'Missões que não foram deletadas',
        'O intervaldo de datas considera a data de criação da missão',
      ],
    },
  ]

  return (
    <Modal open={open} onClose={onClose} size="tiny">
      <Modal.Header>Informações sobre Seleção de Tipo</Modal.Header>
      <Modal.Content>
        {infos.map(info => (
          <Message>
            <Message.Header>{info.typeTitle}</Message.Header>
            <Message.List>
              {info.description.map(desc => (
                <Message.Item>{desc}</Message.Item>
              ))}
            </Message.List>
          </Message>
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Sair" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  )
}

OptionsInfo.propTypes = {
  open: t.bool,
  onClose: t.func,
}
