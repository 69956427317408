import React from 'react'

import { format } from 'date-fns'
import t from 'prop-types'
import { isNil, has } from 'ramda'
import { Table, Label, Popup, Button } from 'semantic-ui-react'

import translate from '~/assets/i18n/ptBr'

const Item = ({ item, onActive, onRemove, onOpen }) => (
  <Table.Row positive={item.active} negative={!item.active}>
    <Table.Cell>
      <Label color="blue" ribbon>
        {translate[item.recurrence.type]}
      </Label>
      {item.id}
    </Table.Cell>
    <Table.Cell>{item.name}</Table.Cell>
    <Table.Cell>{item.message}</Table.Cell>
    <Table.Cell>
      {isNil(item.started_at)
        ? 'Tempo indeterminado'
        : format(new Date(item.started_at), 'dd/MM/yyyy HH:mm')}
    </Table.Cell>
    <Table.Cell>
      {isNil(item.finished_at)
        ? 'Tempo indeterminado'
        : format(new Date(item.finished_at), 'dd/MM/yyyy HH:mm')}
    </Table.Cell>
    <Table.Cell>
      {has('period')(item.recurrence) && item.recurrence.hours.join(', ')}
    </Table.Cell>
    <Table.Cell>
      {has('period')(item.recurrence) &&
        Array.isArray(item.recurrence.period) &&
        item.recurrence.period
          .map((i) =>
            item.recurrence.type === 'weekly' ? translate.weekValue[i] : i
          )
          .join(', ')}
    </Table.Cell>
    <Table.Cell>
      {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
    </Table.Cell>
    <Table.Cell>
      {!isNil(item.updated_at) &&
        format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
    </Table.Cell>
    <Table.Cell>
      <Popup
        inverted
        content="Editar"
        trigger={<Button icon="edit" onClick={() => onOpen(item)} />}
      />
      <Popup
        inverted
        trigger={
          <Button
            negative={item.active}
            positive={!item.active}
            onClick={() => onActive(item)}
            icon={item.active ? 'remove' : 'check'}
          />
        }
        content={item.active ? 'Inativar' : 'Ativar'}
      />
      <Button icon="trash" onClick={() => onRemove(item)} />
    </Table.Cell>
  </Table.Row>
)

Item.propTypes = {
  item: t.objectOf(t.any),
  onActive: t.func,
  onRemove: t.func,
  onOpen: t.func,
}

export default Item
