import axios from 'axios'
import { has } from 'ramda'

import api from '~/services/api'

const postFormData = params => async dispatch => {
  const { typeFetch, typeError, typeResponse, data, url } = params
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      dispatch({ type: typeFetch, progress: percentCompleted })
    },
    timeout: 100000,
  }
  dispatch({ type: typeFetch })
  try {
    const res = await api.post(url, data, config)
    if (res.status === 200 || res.status === 201) {
      dispatch({ type: typeResponse, data: res.data.data || res.data })
    }
  } catch (e) {
    const message = e.response.data.data || 'Houve um erro no servidor'
    dispatch({ type: typeError, message })
  }
}

const post = params => async dispatch => {
  const { typeFetch, typeError, typeResponse, data, url } = params
  dispatch({ type: typeFetch })
  try {
    const res = await api.post(url, data, { timeout: 100000 })
    if (res.status === 200 || res.status === 201) {
      dispatch({ type: typeResponse, data: res.data.data || res.data })
    }
  } catch (e) {
    console.warn(`post => ${e}`)
    const message = e.response.data.data || 'Houve um erro no servidor'
    dispatch({ type: typeError, message })
  }
}

const remove = params => async dispatch => {
  const { typeFetch, typeError, typeResponse, url } = params
  dispatch({ type: typeFetch })
  try {
    const res = await api.delete(url)
    if (res.status === 200) {
      dispatch({ type: typeResponse, data: res.data.data || res.data })
    }
  } catch (e) {
    const message = e.response.data.data || 'Houve um erro no servidor'
    dispatch({ type: typeError, message })
  }
}

const get = params => async dispatch => {
  const { url, typeError, typeFetch, typeResponse } = params
  dispatch({ type: typeFetch })

  const hasParams = has('data')
  let object = {}

  if (hasParams(params)) {
    object = Object.assign(object, { params: params.data })
  }

  try {
    const res = await api.get(url, object)
    const hasCount = has('count')

    if (res.status === 200) {
      dispatch({
        type: typeResponse,
        data: res.data.data || res.data,
        count: hasCount(res.data) ? res.data.count : null,
      })
    }
  } catch (e) {
    dispatch({
      type: typeError,
      message: e.response.data.data || 'Houve um erro no servidor',
    })
  }
}

const getAdress = params => async dispatch => {
  const { typeFetch, typeError, typeResponse, url } = params
  dispatch({ type: typeFetch })
  try {
    const res = await axios.get(url)

    if (res.status === 200) {
      dispatch({ type: typeResponse, data: res.data.results || res.data })
    }
  } catch (e) {
    const message = e.response.data.data || 'Houve um erro no servidor'
    dispatch({ type: typeError, message })
  }
}

const put = params => async dispatch => {
  const { typeFetch, typeError, typeResponse, url, data } = params
  dispatch({ type: typeFetch })
  try {
    const res = await api.put(url, data)
    if (res.status === 200 || res.status === 201) {
      dispatch({ type: typeResponse, data: res.data.data || res.data })
    }
  } catch (e) {
    const message = e.response.data.data || 'Houve um erro no servidor'
    dispatch({ type: typeError, message })
  }
}

const putFormData = params => async dispatch => {
  const { typeFetch, typeError, typeResponse, data, url } = params
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      dispatch({ type: typeFetch, progress: percentCompleted })
    },
    timeout: 100000,
  }
  dispatch({ type: typeFetch })
  try {
    const res = await api.put(url, data, config)
    if (res.status === 200 || res.status === 201) {
      dispatch({ type: typeResponse, data: res.data.data || res.data })
    }
  } catch (e) {
    console.warn(`putFormData => ${e}`)
    const message = e.response.data.data || 'Houve um erro no servidor'
    dispatch({ type: typeError, message })
  }
}

export { get, put, post, remove, getAdress, postFormData, putFormData }
