import React from 'react'

import t from 'prop-types'
import { Card, Statistic, Icon, Segment } from 'semantic-ui-react'

import Loader from '~/components/Loader/Statistic'

const CardStatistic = ({
  sublabel,
  label,
  value,
  onClick,
  borderColor,
  loading,
}) => {
  if (loading) {
    return <Loader />
  }

  return (
    <Card
      fluid
      color={borderColor}
      style={{ height: 120, marginBottom: 20 }}
      onClick={onClick || null}
    >
      <Card.Content textAlign="center">
        <Segment raised>
          <Statistic>
            <Statistic.Value>{value}</Statistic.Value>
            <Statistic.Label>{label}</Statistic.Label>
            {sublabel && (
              <Statistic.Label
                style={{ color: sublabel >= 0 ? 'green' : 'red', fontSize: `2em` }}
              >
                <Icon name={sublabel > 0 ? 'caret up' : 'caret down'} />{' '}
                {sublabel}%
              </Statistic.Label>
            )}
          </Statistic>
        </Segment>
      </Card.Content>
    </Card>
  )
}

CardStatistic.propTypes = {
  sublabel: t.string,
  label: t.string,
  value: t.string,
  onClick: t.func,
  loading: t.bool,
  borderColor: t.string,
}

export default CardStatistic
