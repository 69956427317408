import React from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Dropdown, Table } from 'semantic-ui-react'

import { formatPrice } from '~/utils/types'

import { DELIVERY_STATUS_TO_DISPLAY, DELIVERY_STATUS } from '../helpers/types'

function OrderItem({
  index,
  item,
  openOrderDetailsModal,
  openModelConfirmation,
  openModelSetTrackingCode,
  openModalProblemToSend,
}) {
  function isEnableOptionSended(order) {
    return (
      order.deliveryStatus === DELIVERY_STATUS.PENDENT &&
      order.trackingCode.length > 0
    )
  }

  function isEnableOptionProblemToSend(order) {
    return order.deliveryStatus === DELIVERY_STATUS.SENDED
  }

  function isEnableOptionDelivery(order) {
    return order.deliveryStatus === DELIVERY_STATUS.SENDED
  }

  function isEnableOptionAddTrackingCode(order) {
    return order.deliveryStatus === DELIVERY_STATUS.PENDENT
  }

  function getQuantityOrderProducts(order) {
    return order.products.reduce((current, product) => {
      return current + product.quantity
    }, 0)
  }

  return (
    <Table.Row key={String(index)}>
      <Table.Cell>{item.username}</Table.Cell>
      <Table.Cell>{DELIVERY_STATUS_TO_DISPLAY[item.deliveryStatus]}</Table.Cell>
      <Table.Cell>{formatPrice(item.totalMoney)}</Table.Cell>
      <Table.Cell>{item.totalPoints}</Table.Cell>
      <Table.Cell>{getQuantityOrderProducts(item)}</Table.Cell>
      <Table.Cell>
        {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Dropdown icon="ellipsis vertical" direction="left">
          <Dropdown.Menu>
            <Dropdown.Item
              icon="search"
              text="Ver detalhes"
              onClick={() => openOrderDetailsModal(item)}
            />
            {isEnableOptionAddTrackingCode(item) && (
              <Dropdown.Item
                icon="truck"
                onClick={() => openModelSetTrackingCode(item)}
                text="Adicionar código de rastreio"
              />
            )}

            {isEnableOptionSended(item) && (
              <Dropdown.Item
                icon="box"
                onClick={() =>
                  openModelConfirmation(item, DELIVERY_STATUS.SENDED)
                }
                text="Definir como enviado"
              />
            )}
            {isEnableOptionProblemToSend(item) && (
              <Dropdown.Item
                icon="warning sign"
                onClick={
                  () => openModalProblemToSend(item)
                  // openModelConfirmation(item, DELIVERY_STATUS.PROBLEM_TO_SEND)
                }
                text="Definir como problema no envio"
              />
            )}
            {isEnableOptionDelivery(item) && (
              <Dropdown.Item
                icon="check circle"
                onClick={() =>
                  openModelConfirmation(item, DELIVERY_STATUS.DELIVERED)
                }
                text="Definir como entregue"
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
    </Table.Row>
  )
}

OrderItem.propTypes = {
  index: t.number,
  item: t.objectOf(t.any),
  openOrderDetailsModal: t.objectOf(t.any),
  openModelConfirmation: t.func,
  openModelSetTrackingCode: t.func,
  openModalProblemToSend: t.func,
}

export default OrderItem
