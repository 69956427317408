import React from 'react'
import DatePicker from 'react-datepicker'
import { Editor } from 'react-draft-wysiwyg'

import moment from 'moment'
import t from 'prop-types'
import {
  Checkbox,
  Modal,
  Form,
  List,
  Button,
  Container,
  Select,
} from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'

const ModalEditAllMissions = ({
  close,
  value,
  address,
  isActive,
  handlerDate,
  editorState,
  handlerInput,
  changeAddress,
  onChangeActive,
  loadingMissions,
  isOpenEditModal,
  loadingAddresses,
  submitMissionEdit,
  onEditorStateChange,
  handlerChangeStatus,
  customerId,
  forms,
  handlerForms,
}) => (
  <Modal size="small" open={isOpenEditModal} onClose={close}>
    <Modal.Header content="Atualizar Todas Missões" />
    <Modal.Content scrolling>
      <Form>
        <Form.Input
          id="place"
          type="text"
          value={value?.place}
          onChange={handlerInput}
          loading={loadingAddresses}
          placeholder="Ex: Florianópolis"
          label="Digite o endereço (Obrigatório)"
        />
        {!loadingAddresses && (
          <List selection verticalxAlign="middle">
            {address.map((i, index) => {
              const place = i.formatted_address

              return (
                <List.Item key={String(index)} onClick={() => changeAddress(i)}>
                  <List.Icon style={{ color: 'red' }} name="marker" />
                  <List.Content>
                    <List.Header as="a">{place}</List.Header>
                  </List.Content>
                </List.Item>
              )
            })}
          </List>
        )}

        <Form.Field
          control={Checkbox}
          label="Deseja ativar ou inativar as missões?"
          value={false}
          onClick={onChangeActive}
        />

        {isActive && (
          <Form.Field
            onChange={handlerChangeStatus}
            checked={value?.status}
            control={Checkbox}
            label={
              value?.status
                ? 'Ativar Todas as missões'
                : 'Inativar todas as missões'
            }
            toggle
          />
        )}

        <div className="field">
          <label>Data de Expiração</label>
          <div>
            <DatePicker
              id="expired_at"
              minDate={moment()}
              dateFormat="dd/MM/yyyy"
              onChange={data => handlerDate('expired_at', data)}
              selected={value?.expired_at || ''}
            />
          </div>
        </div>

        <div className="field">
          <label>Data de Ativação</label>
          <div>
            <DatePicker
              id="activated_at"
              minDate={moment()}
              dateFormat="dd/MM/yyyy"
              onChange={data => handlerDate('activated_at', data)}
              selected={value?.activated_at || ''}
            />
          </div>
        </div>

        {customerId && (
          <Container>
            <strong>Selecione um formulário</strong>
            <Select search options={forms} onChange={handlerForms} />
          </Container>
        )}

        <div className="field">
          <label>Descrição</label>
          <div className="editor">
            <Editor
              style={{ borderWidth: 1, borderColor: 'gray' }}
              editorState={editorState}
              editorClassName="editorClassName"
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              onEditorStateChange={onEditorStateChange}
            />
          </div>
        </div>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={close}>
        Cancelar
      </Button>
      <Button
        positive
        icon="checkmark"
        content="Atualizar"
        labelPosition="right"
        onClick={submitMissionEdit}
        loading={loadingMissions}
      />
    </Modal.Actions>
  </Modal>
)

ModalEditAllMissions.propTypes = {
  close: t.func,
  address: t.oneOfType(t.array, t.func),
  isActive: t.bool,
  handlerDate: t.func,
  editorState: t.func,
  handlerInput: t.func,
  changeAddress: t.func,
  onChangeActive: t.func,
  isOpenEditModal: t.bool,
  loadingMissions: t.bool,
  value: t.objectOf(t.any),
  loadingAddresses: t.bool,
  submitMissionEdit: t.func,
  onEditorStateChange: t.func,
  handlerChangeStatus: t.func,
  customerId: t.string,
  forms: t.arrayOf(t.any),
  handlerForms: t.func,
}

export default ModalEditAllMissions
