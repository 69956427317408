import { cond, equals, always, assoc } from 'ramda'

const initialState = {
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const dataObject = assoc('data', {}, initialState)
const dataBool = assoc('data', false, initialState)

const BUDGET = 'BUDGET'
const BUDGET_OK = 'BUDGET_OK'
const BUDGET_FAIL = 'BUDGET_FAIL'

const REPORT_TRANSACTIONS = 'REPORT_TRANSACTIONS'
const REPORT_TRANSACTIONS_OK = 'REPORT_TRANSACTIONS_OK'
const REPORT_TRANSACTIONS_FAIL = 'REPORT_TRANSACTIONS_FAIL'

const budget = (state = dataBool, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(REPORT_TRANSACTIONS), always({ ...state, isFetching: true })],
    [
      equals(REPORT_TRANSACTIONS_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(REPORT_TRANSACTIONS_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

const reportTransactions = (state = dataObject, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(BUDGET), always({ ...state, isFetching: true })],
    [
      equals(BUDGET_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(BUDGET_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

export default {
  budget,
  reportTransactions,
}
