import React from 'react'

import 'react-semantic-toasts/styles/react-semantic-alert.css'

import PropTypes from 'prop-types'

import * as S from './styles'

export default function Auth({ children }) {
  return <S.Container>{children}</S.Container>
}

Auth.propTypes = {
  children: PropTypes.element.isRequired,
}
