/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import t from 'prop-types'
import { Card, Image, List, Label, Grid, Rating, Icon } from 'semantic-ui-react'

import MissionDescription from '~/components/Modal/MissionDescription'
import useUsers from '~/hooks/useUsers'
import { statusRegisterColor, statusRegisterLabel } from '~/utils/feedsActions'
import { planColor, registerStatus } from '~/utils/types'

import AvaliationButton from './AvaliationButton'
import Bonus from './Bonus'
import MissionMainValue from './MissionMainValue'

const InfoValues = ({
  register,
  onRefresh,
  onChangeBonus,
  onSelectStatus,
  loading,
  hasBonus,
}) => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const isPercent = register.has_value_percentage
  const isVariable = register.has_value_variable
  const isCustomValue = isPercent || isVariable
  const hasInvoiceValue = register.invoice_value !== null

  const { getUserData, loadingUser, userDetail } = useUsers(register.user_id)

  const onUserProfile = () => history.push(`/user/${register.user_id}`)

  const onSubcategory = () =>
    history.push(
      `/categories/${register.category_id}/sub-categories/${register.missions_main_id}`
    )
  const onSubcategoryValues = () =>
    history.push(
      `/categories/${register.category_id}/sub-categories-values/${register.missions_main_id}`
    )

  useEffect(() => {
    getUserData()
  }, []) //eslint-disable-line

  const isSubscribed = userDetail.subscription !== null
  const subscriptionLabel =
    isSubscribed && userDetail?.subscription?.includes('black')
      ? 'Plano Black'
      : 'Plano Prime'

  return (
    <Grid columns={2}>
      <Grid.Column>
        <Card fluid>
          <Card.Content>
            <Image src={register.userAvatar} floated="left" size="tiny" />
            <Card.Header as="a" onClick={onUserProfile}>
              {register.userName.toUpperCase()}{' '}
              {!loadingUser && isSubscribed ? (
                <Label color={planColor[userDetail?.subscription].bg}>
                  <Icon name={planColor[userDetail?.subscription].icon} />{' '}
                  {subscriptionLabel}
                </Label>
              ) : null}
            </Card.Header>
            <Card.Header>
              <Label
                style={{
                  backgroundColor: statusRegisterColor(register.status),
                }}
              >
                {statusRegisterLabel(register.status)}{' '}
                {register.custom_status_description !== null
                  ? ` - ${register.custom_status_description}`
                  : ''}
              </Label>
            </Card.Header>
            {register.rate !== null && (
              <Card.Description>
                {register.rate}{' '}
                <Rating defaultRating={0} maxRating={register.rate} disabled />
              </Card.Description>
            )}
            {register.status === registerStatus.REPROVED && (
              <Card.Description>
                Motivo: {register.has_reproved}
              </Card.Description>
            )}
            {register.status === registerStatus.IN_CORRECTION && (
              <Card.Description>
                Motivo: {register.has_reproved}
              </Card.Description>
            )}
            <Card.Description>
              {register.mission_key} - {register.missionName}
            </Card.Description>
            <Card.Description as="a" onClick={onSubcategory}>
              {register.category_name} - {register.subcategory}
            </Card.Description>
            <Card.Meta>
              Criado em {format(new Date(register.created_at), 'dd/MM/yyy')}
            </Card.Meta>
          </Card.Content>
          <Card.Content>
            <Card.Header as="a" onClick={() => setOpen(true)}>
              O que vamos analisar? (Clique para ver instruções)
            </Card.Header>
            <List>
              {register.has_forms && (
                <List.Item>
                  <List.Icon name="check" />
                  <List.Content>Formulário</List.Content>
                </List.Item>
              )}
              {register.has_photo && (
                <List.Item>
                  <List.Icon name="check" />
                  <List.Content>Fotos</List.Content>
                </List.Item>
              )}
              {register.has_products && (
                <List.Item>
                  <List.Icon name="check" />
                  <List.Content>Produtos</List.Content>
                </List.Item>
              )}
              {register.has_import_document && (
                <List.Item>
                  <List.Icon name="check" />
                  <List.Content>Documento PDF</List.Content>
                </List.Item>
              )}
              {register.has_pos && (
                <List.Item>
                  <List.Icon name="check" />
                  <List.Content>PDV</List.Content>
                </List.Item>
              )}
              {register.has_bonus && (
                <List.Item>
                  <List.Icon name="check" />
                  <List.Content>Missão Bônus</List.Content>
                </List.Item>
              )}
            </List>
          </Card.Content>

          <MissionDescription
            open={open}
            onClose={() => setOpen(false)}
            content={register.description}
          />
        </Card>
      </Grid.Column>
      <Grid.Column>
        <Card fluid>
          {register.has_bonus && (
            <Card.Content>
              <Card.Header>
                Clique em "Confirmar", caso o usuário tenha cumprido com as
                exigências necessárias para ganhar o bônus.
              </Card.Header>
              <Card.Description>
                {register.user_value_bonus === null
                  ? 'Valor Bônus Não foi inserido!'
                  : `Valor R$ ${register.user_value_bonus}`}
              </Card.Description>
              <Card.Description>
                <Bonus onChange={onChangeBonus} hasBonus={hasBonus} />
              </Card.Description>
            </Card.Content>
          )}
          {isCustomValue && (
            <Card.Content>
              <Card.Header as="a" onClick={onSubcategoryValues}>
                Alterar Valor {isPercent ? 'Percentual' : 'Valor Variavel'}{' '}
                {register.invoice_value || '(Valor Não Inputado)'}
              </Card.Header>
              <MissionMainValue
                onRefresh={onRefresh}
                isPercent={isPercent}
                subcategoryId={register.missions_main_id}
                missionsUsersId={register.missions_users_id}
              />
            </Card.Content>
          )}
          <Card.Content>
            <Card.Header>
              Valores {register.description_variable || ''}
            </Card.Header>
            <List>
              {isPercent && (
                <>
                  <List.Item>
                    <List.Icon name="money" />
                    <List.Content>
                      {register.customer_value_variable}% - Cliente
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="money" />
                    <List.Content>
                      {register.user_value_variable}% - Usuário
                    </List.Content>
                  </List.Item>

                  <List.Item>
                    <List.Icon name="money" />
                    <List.Content>
                      R$ {hasInvoiceValue ? register.invoice_value : 0} - Valor
                      Inputado
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="money" />
                    <List.Content>
                      {hasInvoiceValue
                        ? `R$ ${register.r_user_value} - Total Usuário`
                        : 'Aguardando Valor do Orçamento'}{' '}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="money" />
                    <List.Content>
                      {hasInvoiceValue
                        ? `R$ ${register.r_customer_value} - Total Cliente`
                        : 'Aguardando Valor do Orçamento'}{' '}
                    </List.Content>
                  </List.Item>
                </>
              )}
              {isVariable && (
                <>
                  <List.Item>
                    <List.Icon name="money" />
                    <List.Content>
                      R$ {register.customer_value_variable} - Cliente
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="money" />
                    <List.Content>
                      R$ {register.user_value_variable} - Usuário
                    </List.Content>
                  </List.Item>
                </>
              )}
              {!isPercent && !isVariable && (
                <>
                  <List.Item>
                    <List.Icon name="money" />
                    <List.Content>
                      R$ {register.r_user_value} - Usuário
                    </List.Content>
                  </List.Item>
                  {hasBonus && (
                    <List.Item>
                      <List.Icon name="money" />
                      <List.Content>
                        R$ {register.user_value_bonus} - Bonus
                      </List.Content>
                    </List.Item>
                  )}
                </>
              )}
            </List>
          </Card.Content>
          <Card.Content>
            <Card.Header>Avaliação Final</Card.Header>
            <Card.Content>
              <AvaliationButton
                loading={loading}
                onClick={onSelectStatus}
                status={register.status}
              />
            </Card.Content>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid>
  )
}

InfoValues.propTypes = {
  onChangeBonus: t.func,
  hasBonus: t.bool,
  loading: t.bool,
  onRefresh: t.func,
  onSelectStatus: t.func,
  register: t.objectOf(t.any),
}
export default InfoValues
