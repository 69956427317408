import styled from 'styled-components'

export const Container = styled.div`
  margin: 20px auto;
`

export const Title = styled.span`
  font-size: 18px;
  margin-left: 5px;
  font-weight: 500;
`

export const Label = styled.span`
  font-size: 16px;
  margin-left: 5px;
  font-weight: 500;
`
export const Total = styled.span`
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`
