import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useMissionsFeeds() {
  const [loadingSignature, setLoadingSignature] = useState(false)
  const [feedSignature, setFeedSignature] = useState([])

  async function getMissionsUsersSignature(missionsUsersId) {
    try {
      setLoadingSignature(true)

      const res = await api.get('missions-users-signature', {
        params: { missionsUsersId },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setFeedSignature(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingSignature(false)
    }
  }

  return {
    loadingSignature,
    getMissionsUsersSignature,
    feedSignature,
  }
}
