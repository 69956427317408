import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'

import t from 'prop-types'
import { Card, Form, Icon } from 'semantic-ui-react'

import useCustomers from '~/hooks/useCustomers'
import ImportStore from '../ImportStore'

function Filter({
  filters,
  onChange,
  onSubmit,
  onReset,
  ufsOptions,
  citiesOptions,
  onChangeUFs,
  loading,
  activeMissions,
  subcategoriesOptions,
  onExportReport,
}) {
  const [showCustom, setShowCustom] = useState(false)

  const { getCustomers, listNameCustomers } = useCustomers()

  const periodOptions = [
    { text: '1 dia', value: 1 },
    { text: '7 dias', value: 7 },
    { text: '15 dias', value: 15 },
    { text: '30 dias', value: 30 },
    { text: 'Custom', value: 'custom' },
  ]

  function handleChangePeriod(value) {
    onChange({ period: value })

    if (value === 'custom') {
      return setShowCustom(true)
    }

    return setShowCustom(false)
  }

  function handleReset() {
    onReset()
    setShowCustom(false)
  }

  useEffect(() => {
    getCustomers({ limit: 10000 })
  }, []) // eslint-disable-line

  return (
    <Card fluid>
      <Card.Content>
        <Form loading={loading} onSubmit={(e, { value }) => console.log(value)}>
          <Form.Group widths="equal">
            <Form.Select
              fluid
              id="customerId"
              search
              selected
              placeholder="Cliente"
              options={listNameCustomers}
              name="customerId"
              value={filters?.customerId || ''}
              onChange={(e, { value }) => {
                onChange({ customerId: value })
              }}
            />

            <Form.Select
              fluid
              id="missionId"
              disabled={!activeMissions?.length}
              search
              placeholder="Missão"
              options={activeMissions || []}
              value={filters?.missionId || ''}
              onChange={(e, { value }) => {
                onChange({ missionId: value })
              }}
            />

            <Form.Input
              fluid
              id="search"
              icon="search"
              placeholder="Identificador ou MB Key"
              value={filters?.search || ''}
              onChange={(e, { value }) => onChange({ search: value })}
            />

            <Form.Select
              fluid
              id="missionsMainId"
              search
              placeholder="Sub-Categoria"
              options={subcategoriesOptions}
              value={filters?.missionsMainId || ''}
              onChange={(e, { value }) => {
                onChange({ missionsMainId: value })
              }}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Select
              fluid
              id="uf"
              search
              multiple
              placeholder="Estado"
              options={ufsOptions}
              value={filters?.uf || []}
              onChange={(e, { value }) => {
                onChange({ uf: value })
                onChangeUFs(value)
              }}
            />

            <Form.Select
              fluid
              id="cities"
              search
              multiple
              placeholder="Cidades"
              disabled={!citiesOptions.length}
              options={citiesOptions}
              value={filters?.cities || []}
              onChange={(e, { value }) => {
                onChange({ cities: value })
              }}
            />
            <Form.Select
              id="period"
              name="period"
              placeholder="Periodo"
              options={periodOptions}
              value={filters?.period || ''}
              onChange={(e, { value }) => handleChangePeriod(value)}
            />

            {showCustom && (
              <>
                <Form.Field
                  fluid
                  id="startDate"
                  control={ReactDatePicker}
                  placeholderText="Data Início"
                  dateFormat="dd/MM/yyyy"
                  selected={filters?.startDate || ''}
                  onChange={value => onChange({ startDate: value })}
                />

                <Form.Field
                  fluid
                  id="finalDate"
                  control={ReactDatePicker}
                  placeholderText="Data Fim"
                  dateFormat="dd/MM/yyyy"
                  selected={filters?.finalDate || ''}
                  onChange={value => onChange({ finalDate: value })}
                />
              </>
            )}
          </Form.Group>

          <Form.Group>
            <Form.Button
              color="green"
              icon
              labelPosition="right"
              onClick={onSubmit}
            >
              <Icon name="check" />
              Filtrar
            </Form.Button>

            <Form.Button
              color="gray"
              icon
              labelPosition="right"
              onClick={handleReset}
            >
              <Icon name="undo" />
              Resetar Filtros
            </Form.Button>

            <ImportStore />

            <Form.Button
              color="orange"
              icon
              labelPosition="right"
              onClick={onExportReport}
            >
              <Icon name="download" />
              Gerar relatório
            </Form.Button>
          </Form.Group>
        </Form>
      </Card.Content>
    </Card>
  )
}

Filter.propTypes = {
  filters: t.objectOf(t.any),
  onChange: t.func,
  onSubmit: t.func,
  onReset: t.func,
  ufsOptions: t.arrayOf(t.object),
  citiesOptions: t.arrayOf(t.object),
  onChangeUFs: t.func,
  loading: t.bool,
  activeMissions: t.arrayOf(t.object),
  subcategoriesOptions: t.arrayOf(t.object),
  onExportReport: t.func,
}

export default Filter
