import React from 'react'

import t from 'prop-types'
import { Button, Modal } from 'semantic-ui-react'

function ConfirmationModal({
  modalConfirmation,
  setModalConfirmation,
  confirmUpdateDeliveryStatus,
}) {
  return (
    <Modal
      size="mini"
      open={modalConfirmation}
      onClose={() => setModalConfirmation(false)}
    >
      <Modal.Header>Alterar situação do pedido</Modal.Header>
      <Modal.Content className="center align">
        <p>Você tem certeza que executar essa ação?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setModalConfirmation(false)}>Não</Button>
        <Button primary onClick={() => confirmUpdateDeliveryStatus()}>
          Sim
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  modalConfirmation: t.bool,
  setModalConfirmation: t.func,
  confirmUpdateDeliveryStatus: t.func,
}

export default ConfirmationModal
