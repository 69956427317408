import React, { useEffect, useState } from 'react'

import t from 'prop-types'
import {
  Form,
  Radio,
  Modal,
  Button,
  Loader,
  Dimmer,
  Segment,
} from 'semantic-ui-react'

import useForms from '~/hooks/useForms'
import useFormsMissions from '~/hooks/useFormsMissions'

export function Forms({
  isOpenModalForm,
  clientId,
  mission,
  onCloseModalForm,
}) {
  const { forms, getForms, loadingForms } = useForms()

  const {
    loadingFormsMissions,
    onUpdateFormsMissions,
    formsMissions,
    getFormsMissions,
  } = useFormsMissions()

  const [selected, setSelected] = useState({
    mission_id: mission.uid,
    forms_id: null,
  })

  function onChange(key, item) {
    return setSelected(prev => ({ ...prev, [key]: item.value }))
  }

  function onClickSubmitUpdate() {
    onUpdateFormsMissions(selected)

    return setTimeout(() => onCloseModalForm(), 500)
  }

  function onMountModal() {
    return setSelected(prev => ({ ...prev, forms_id: formsMissions?.forms_id }))
  }

  useEffect(() => {
    const onform = Promise.all([
      getForms(clientId),
      getFormsMissions(mission.uid),
    ])

    return () => onform
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!loadingFormsMissions) {
      setSelected(prev => ({ ...prev, forms_id: formsMissions?.forms_id }))
    }
  }, [loadingFormsMissions, formsMissions?.forms_id]) //eslint-disable-line

  return (
    <Modal
      size="small"
      open={isOpenModalForm}
      onClose={onCloseModalForm}
      onMount={onMountModal}
    >
      <Modal.Header>Formulário(s)</Modal.Header>
      <Modal.Content>
        {loadingForms ? (
          <Segment>
            <Dimmer>
              <Loader />
            </Dimmer>
          </Segment>
        ) : null}
        <Form>
          {!loadingForms && selected
            ? forms.map((item, index) => (
                <Form.Field key={String(`key-${index}`)}>
                  <Radio
                    name="radio"
                    value={item}
                    label={item.name}
                    checked={item.uid === selected.forms_id}
                    onChange={(e, { value }) => onChange('forms_id', value)}
                  />
                </Form.Field>
              ))
            : null}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCloseModalForm}>Cancelar</Button>
        <Button
          primary
          content="Atualizar"
          loading={loadingFormsMissions}
          onClick={onClickSubmitUpdate}
          disabled={
            loadingFormsMissions ||
            selected.forms_id === formsMissions?.forms_id
          }
        />
      </Modal.Actions>
    </Modal>
  )
}

Forms.propTypes = {
  clientId: t.string,
  isOpenModalForm: t.bool,
  mission: t.objectOf(t.any),
  onCloseModalForm: t.func,
}
