import React from 'react'

import { Button, Popup, Grid } from 'semantic-ui-react'

export default function PopupAddress({ open, link, title, remove }) {
  return (
    <Popup trigger={<span>{title}</span>} flowing hoverable>
      <Grid centered divided columns={3}>
        <Grid.Column textAlign="center">
          <Button onClick={open}>Alterar</Button>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Button negative onClick={remove}>
            Remover
          </Button>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Button primary onClick={link}>
            Google
          </Button>
        </Grid.Column>
      </Grid>
    </Popup>
  )
}
