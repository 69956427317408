import React from 'react'

import t from 'prop-types'
import { Icon, Button } from 'semantic-ui-react'

const Bonus = ({ onChange, hasBonus }) => {
  const [confirm, setConfirm] = React.useState(hasBonus)

  return (
    <>
      {!confirm ? (
        <Button
          icon
          positive
          labelPosition="left"
          onClick={() => {
            setConfirm(true)
            onChange(true)
          }}
        >
          <Icon name="check" />
          Confirmar
        </Button>
      ) : (
        <Button
          icon
          negative
          labelPosition="left"
          onClick={() => {
            setConfirm(false)
            onChange(false)
          }}
        >
          <Icon name="remove" />
          Desfazer
        </Button>
      )}
    </>
  )
}

Bonus.propTypes = {
  onChange: t.func,
  hasBonus: t.bool,
}

export default Bonus
