export default [
  {
    label: 'Usuários',
    table: 'users',
    where: 'status',
    status: 1,
    typeData: 'count',
    active: 'u',
    columnDate: 'created_at',
  },
  {
    label: 'Clientes',
    table: 'customers',
    where: 'status',
    status: 1,
    typeData: 'count',
    active: 'c',
    columnDate: 'created_at',
  },
  {
    label: 'Pagamentos (Entrada)',
    table: 'master_credit_requests',
    where: 'status',
    status: 1,
    typeData: 'sum',
    active: 'pe',
    columnDate: 'created_at',
  },
  {
    label: 'Pagamentos (Saida)',
    table: 'payment_request',
    where: 'payment_status',
    status: 1,
    typeData: 'sum',
    active: 'ps',
    columnDate: 'created_at',
  },
  {
    label: 'Missões Concluida(s)',
    table: 'registers',
    where: '',
    status: '',
    typeData: 'count',
    active: 'mc',
    columnDate: 'created_at',
  },
  {
    label: 'Missões Lançada(s)',
    table: 'missions',
    where: '',
    status: '',
    typeData: 'count',
    active: 'ml',
    columnDate: 'created_at',
  },
  {
    label: 'Missões Reprovada(s)',
    table: 'registers',
    where: 'status',
    status: 2,
    typeData: 'count',
    active: 'mr',
    columnDate: 'updated_at',
  },
  {
    label: 'Missões Aprovada(s)',
    table: 'registers',
    where: 'status',
    status: 1,
    typeData: 'count',
    active: 'ma',
    columnDate: 'updated_at',
  },
]
