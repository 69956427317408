import React, { useEffect } from 'react'

import t from 'prop-types'
import { Card, List } from 'semantic-ui-react'

import useSkillsTags from '~/hooks/useSkillsTags'

export function SkillsTags({ userId }) {
  const { getUsersSkills, userSkills } = useSkillsTags()

  useEffect(() => {
    getUsersSkills({ userId })
  }, []) // eslint-disable-line

  return (
    <Card fluid>
      <Card.Content>
        <List>
          {userSkills?.map(item => (
            <List.Item>
              <List.Icon name="check" />
              <List.Content>{item}</List.Content>
            </List.Item>
          ))}
        </List>
      </Card.Content>
    </Card>
  )
}

SkillsTags.propTypes = {
  userId: t.string,
}
