import { useState } from 'react'

import api from '~/services/api'

export default function useQueueUpdateMissionBatch() {
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    itemsPerPage: 10,
    total: 0,
  })
  const [isLoading, setIsLoading] = useState(false)

  const getListEditionMissionsBatch = async () => {
    setIsLoading(true)
    const response = await api.get(
      'missions/admin/queue-update-missions-batch',
      {
        params: {
          page: pagination.page,
          itemsPerPage: pagination.itemsPerPage,
        },
      }
    )

    const { data } = response
    setItems(data.data)
    setPagination({
      ...pagination,
      total: data.total,
    })
    setIsLoading(false)
  }

  return {
    getListEditionMissionsBatch,
    isLoading,
    pagination,
    setPagination,
    items,
  }
}
