import React from 'react'

import t from 'prop-types'
import { Confirm } from 'semantic-ui-react'

function RemoveUsers({
  confirmRemove,
  onCancelConfirm,
  onConfirmDoubleCheckForRemoveUsers,
  doubleCheckForRemoveUsers,
  onCancelDoubleConfirm,
  onConfirmRemoveUsers,
}) {
  return (
    <>
      <Confirm
        size="small"
        open={confirmRemove}
        onCancel={onCancelConfirm}
        content="Você está prestes a remover todos os usuários das missões, que estão em andamento, que foram listadas na tela anterior. Deseja continuar?"
        header="Confirmação para Remoção de Usuários"
        confirmButton="Sim, remover usuários"
        onConfirm={onConfirmDoubleCheckForRemoveUsers}
      />
      <Confirm
        open={doubleCheckForRemoveUsers}
        onCancel={onCancelDoubleConfirm}
        header="Dupla checagem para remoção de usuário"
        content="Essa é uma dupla confirmação para garantir que a ação está sendo realizada propositalmente. Todos os usuários, do filtro anterior, serão removidos!"
        confirmButton="Estou ciente! Pode remover"
        onConfirm={onConfirmRemoveUsers}
      />
    </>
  )
}

RemoveUsers.propTypes = {
  confirmRemove: t.bool,
  onCancelConfirm: t.func,
  onConfirmDoubleCheckForRemoveUsers: t.func,
  doubleCheckForRemoveUsers: t.func,
  onCancelDoubleConfirm: t.func,
  onConfirmRemoveUsers: t.func,
}

export default RemoveUsers
