import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import { Table, Popup, Button, Confirm } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import TableComponent from '~/components/Table'
import { useAuth } from '~/contexts/auth'
import useBadges from '~/hooks/useBadges'
import useCustomers from '~/hooks/useCustomers'
import Main from '~/pages/Main'

import CreateBadge from './Detail/components/Create'

const columns = ['Avatar', 'ID', 'Title', 'Criado em', 'Atualizado em', 'Ações']

function Badges() {
  const { getCustomers, listNameCustomers } = useCustomers()

  const history = useHistory()
  const { user } = useAuth()
  const {
    getBadges,
    badges,
    loadingBadges,
    badgesCount,
    onChangeStatus,
    onSubmitCreate,
  } = useBadges()

  const uploadRef = useRef()
  const [showForm, setShowForm] = useState(false)
  const [detailsBadge, setDetailsBadge] = useState(null)

  const initialBadge = {
    avatar: { src: null },
    title: '',
    urlVideo: '',
    description: '',
    active: false,
    isExclusive: false,
    isConsultant: false,
    requireApproval: false,
    isPrivate: false,
    avgTime: 0,
    avgValue: 0,
    minScore: 0,
  }
  const [badge, setBadge] = useState({
    ...initialBadge,
  })

  const [modalConfirm, setModalConfirm] = useState(false)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    activePage: 1,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,
  })

  useEffect(() => {
    getBadges({ ...pagination })
  }, []) // eslint-disable-line

  useEffect(() => {
    getCustomers({ limit: 2000 })
  }, []) // eslint-disable-line

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * pagination.limit
    setPagination(prevState => ({
      ...prevState,
      activePage,
      offset,
      limit: pagination.limit,
    }))
    return getBadges({
      offset,
      limit: pagination.limit,
    })
  }

  function confirmUpdate(item) {
    setDetailsBadge(item)
    return setModalConfirm(true)
  }

  function submitUpdate() {
    onChangeStatus(detailsBadge)

    return setModalConfirm(false)
  }

  function onChange(id, value) {
    return setBadge(prevState => ({
      ...prevState,
      [id]: value,
    }))
  }

  function submitCreate() {
    onSubmitCreate(badge)
    setShowForm(false)

    return setBadge({ ...initialBadge })
  }

  function uploadImage(e) {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = event => {
      setBadge(prevState => ({
        ...prevState,
        avatar: {
          src: event.target.result,
          data: file,
        },
      }))
    }
  }

  function renderCells(item, index) {
    return (
      <Table.Row
        key={`index-${index}`}
        negative={!item.active}
        positive={item.active}
      >
        <Table.HeaderCell
          style={{
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            alt="avatar"
            src={item.avatar}
            style={{ width: 50, height: 30 }}
          />
        </Table.HeaderCell>
        <Table.Cell>{item.id}</Table.Cell>
        <Table.Cell>{item.title}</Table.Cell>
        <Table.Cell>
          {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
        </Table.Cell>
        <Table.Cell>
          {format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
        </Table.Cell>
        <Table.Cell>
          <Popup
            inverted
            trigger={
              <Button
                icon="search"
                onClick={() => history.push(`badges/detail/${item.uid}`)}
              />
            }
            content="Editar"
          />
          {!item.exclusive && (
            <Popup
              inverted
              trigger={
                <Button
                  color="purple"
                  icon="file"
                  onClick={() => history.push(`badges/detail/${item.uid}/ead`)}
                />
              }
              content="Exame"
            />
          )}
          <Button
            icon={item.active ? 'check' : 'remove'}
            color={item.active ? 'green' : 'red'}
            onClick={() => confirmUpdate(item)}
          />
        </Table.Cell>
      </Table.Row>
    )
  }

  function renderComponent() {
    return (
      <>
        <Confirm
          header="Alerta"
          cancelButton="Cancelar"
          confirmButton="Confirmar"
          open={modalConfirm}
          onConfirm={submitUpdate}
          onCancel={() => setModalConfirm(false)}
          content="Tem certeza deseja alterar o status desse item?"
        />
        <Button
          size="small"
          color={showForm ? 'red' : 'blue'}
          onClick={() => setShowForm(!showForm)}
        >
          {showForm ? 'Fechar' : 'Criar'}
        </Button>
        <CreateBadge
          description={badge.description}
          title={badge.title}
          avatar={badge.avatar}
          isConsultant={badge.isConsultant}
          isExclusive={badge.isExclusive}
          urlVideo={badge.urlVideo}
          requireApproval={badge.requireApproval}
          avgTime={badge.avgTime}
          avgValue={badge.avgValue}
          show={showForm}
          create={submitCreate}
          myRef={uploadRef}
          uploadImage={uploadImage}
          onClick={() => uploadRef.current.click()}
          onChange={onChange}
          listNameCustomers={listNameCustomers}
          isPrivate={badge.isPrivate}
          onChangeCustomer={badge.customerId}
          minScore={badge.minScore}
        />
        <TableComponent
          showFirstAndLastNav={pagination.showFirstAndLastNav}
          showPreviousAndNextNav={pagination.showPreviousAndNextNav}
          count={badgesCount}
          activePage={pagination.activePage}
          limit={pagination.limit}
          offset={pagination.offset}
          totalCount={badgesCount}
          columns={columns}
          data={badges}
          renderItem={renderCells}
          isLoading={loadingBadges}
          handlePaginationChange={handlePaginationChange}
          emptyText={{
            icon: 'shield',
            text: 'Nenhum Badge...',
          }}
        />
      </>
    )
  }

  return (
    <Main>
      {user?.role_name === 'Basic' || user?.role_name === 'Leader' ? (
        <EmptyList icon="lock" text="Acesso restrito" />
      ) : (
        renderComponent()
      )}
    </Main>
  )
}

export default Badges
