import React from 'react'

import { Dropdown } from 'semantic-ui-react'

export default function Customers({ loading, values, onChange }) {
  return (
    <div>
      <div className="field">
        <label>Clientes (Obrigatório)</label>
      </div>
      <Dropdown
        fluid
        search
        selection
        loading={loading}
        onChange={onChange}
        options={values}
        placeholder="Escolha um cliente..."
      />
    </div>
  )
}
