import React, { useEffect } from 'react'

import t from 'prop-types'
import { Card, List, Image } from 'semantic-ui-react'

import usePointOfSale from '~/hooks/usePointOfSale'

const Pdv = ({ id }) => {
  const { loadingPos, getPos, pos } = usePointOfSale()

  useEffect(() => {
    getPos(id)
  }, []) //eslint-disable-line

  return (
    <Card fluid>
      {!loadingPos && (
        <Card.Content>
          <Card.Header>Informações</Card.Header>
          <List divided verticalAlign="middle">
            {pos !== null &&
              pos.length > 0 &&
              pos.map(p => (
                <List.Item>
                  <List.Header>{p.description}</List.Header>
                  <List.Content floated="right">
                    <Image.Group size="tiny">
                      {p.data.files.map(i => (
                        <Image src={i.path} />
                      ))}
                    </Image.Group>
                  </List.Content>
                  <List.Content>
                    <p>Posição: {p.data.position}</p>
                    <p>Quantidade: {p.data.quantity_product}</p>
                    <p>Existe? {p.data.exist ? 'Sim' : 'Não'}</p>
                    <p>
                      Existe Ponto Extra?{' '}
                      {p.data.exist_sales_point ? 'Sim' : 'Não'}
                    </p>
                    <p>
                      Tipos de Ponto Extra:{' '}
                      {p.data.type_sales_point.map(i => i).join(', ')}
                    </p>
                  </List.Content>
                </List.Item>
              ))}
          </List>
        </Card.Content>
      )}
    </Card>
  )
}

Pdv.propTypes = {
  id: t.string,
}

export default Pdv
