import { responseData } from '~/utils/reducer'

const UFS = 'UFS'
const CITIES = 'CITIES'
const UFS_MIS = 'UFS_MIS'
const CITIES_MIS = 'CITIES_MIS'
const CAMPAIGN = 'CAMPAIGN'
const CAMPAIGN_CREATE = 'CAMPAIGN_CREATE'
const CAMPAIGN_REMOVE = 'CAMPAIGN_REMOVE'
const CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE'

const ufs = responseData(UFS, 'array')
const cities = responseData(CITIES, 'array')
const ufsMissions = responseData(UFS_MIS, 'array')
const citiesMissions = responseData(CITIES_MIS, 'array')
const campaigns = responseData(CAMPAIGN, 'array')
const campaignCreate = responseData(CAMPAIGN_CREATE, 'object')
const campaignRemove = responseData(CAMPAIGN_REMOVE, 'object')
const campaignUpdate = responseData(CAMPAIGN_UPDATE, 'object')

export default {
  ufs,
  cities,
  ufsMissions,
  citiesMissions,
  campaigns,
  campaignCreate,
  campaignRemove,
  campaignUpdate,
}
