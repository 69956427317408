import { useState } from 'react'

import * as FileSaver from 'file-saver'
import { isEmpty } from 'ramda'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useExportFiles() {
  const [loadingExport, setLoadingExport] = useState(false)

  async function onExportMissionsOpen(query) {
    try {
      setLoadingExport(true)

      Object.keys(query).map(
        value =>
          (query[value] === null || isEmpty(query[value])) &&
          delete query[value]
      )

      query.requestType = 'export'

      const res = await api.get('master/missions-open/excel', {
        params: query,
        responseType: 'arraybuffer',
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      Message().success('Exportação realizada com sucesso.')
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      })

      setLoadingExport(false)
      return FileSaver.saveAs(blob, `missions_${Date.now()}`)
    } catch (error) {
      setLoadingExport(false)
      return Message().error(error.message)
    }
  }

  return {
    loadingExport,
    onExportMissionsOpen,
  }
}
