import React from 'react'
import { Route as RouteRRD, Redirect } from 'react-router-dom'

import PropTypes from 'prop-types'

import LoadingPage from '~/components/LoadingPage'
import { useAuth } from '~/contexts/auth'
import AuthLayout from '~/pages/_layouts/auth'
import DefaultLayout from '~/pages/_layouts/default'

export default function Route({ component: Component, isPrivate, ...params }) {
  const { loadingAdmin } = useAuth()

  if (loadingAdmin) {
    return <LoadingPage />
  }

  const token = localStorage.getItem('@MissionAdmin:token')

  if (token === null && isPrivate) {
    return <Redirect to={{ pathname: '/', state: { from: params.path } }} />
  }

  if (token !== null && !isPrivate) {
    return <Redirect to="/dashboard" />
  }

  const Layout = token ? DefaultLayout : AuthLayout

  if (params.path === '/404') {
    return <RouteRRD {...params} render={props => <Component {...props} />} />
  }

  return (
    <RouteRRD
      {...params}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

Route.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
}

Route.defaultProps = {
  isPrivate: false,
}
