import React from 'react'
import { pdfjs } from 'react-pdf'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { SemanticToastContainer } from 'react-semantic-toasts'

import dayjs from 'dayjs'
import { PersistGate } from 'redux-persist/integration/react'

import './config/ReactotronConfig'

import Routes from '~/routes'
import history from '~/services/history'
import GlobalStyles from '~/styles/global'

import AppProvider from './contexts'
import { store, persistor } from './store'

require('dayjs/locale/pt-br')

dayjs.locale('pt-br')

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router history={history}>
        <AppProvider>
          <Routes />
          <GlobalStyles />
          <SemanticToastContainer position="top-right" />
        </AppProvider>
      </Router>
    </PersistGate>
  </Provider>
)

export default App
