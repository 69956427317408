import { responseData } from '~/utils/reducer'

const USERS_LEADER_FETCH = 'USERS_LEADER_FETCH'
const USERS_LEADER_UPDATE = 'USERS_LEADER_UPDATE'
const USERS_LEADER_CREATE = 'USERS_LEADER_CREATE'
const USERS_LEADER_REMOVE = 'USERS_LEADER_REMOVE'

const usersLeaderCreated = responseData(USERS_LEADER_CREATE, 'object')
const usersLeaderUpdated = responseData(USERS_LEADER_UPDATE, 'object')
const usersLeaderDeleted = responseData(USERS_LEADER_REMOVE, 'object')
const usersLeaderFetch = responseData(USERS_LEADER_FETCH, 'array')

export default {
  usersLeaderFetch,
  usersLeaderCreated,
  usersLeaderUpdated,
  usersLeaderDeleted,
}
