import { Card, Image } from 'semantic-ui-react'
import styled from 'styled-components'

export const Container = styled(Card)``

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Avatar = styled(Image)`
  width: 5rem !important;
  height: 5rem !important;
  padding: 2px;
  border: 2px solid rgba(0, 0, 0, 0.5);
`
