import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import t from 'prop-types'
import { isNil } from 'ramda'
import { Breadcrumb, Menu } from 'semantic-ui-react'

const Breadcrumbs = ({ items, hasButton, children }) => {
  const history = useHistory()
  return (
    <Menu>
      <Menu.Item>
        <Breadcrumb>
          {items?.map((value, index) => (
            <Fragment key={String(index)}>
              <Breadcrumb.Section
                active={value.active}
                link={value.hasLink}
                content={value.name}
                onClick={() =>
                  isNil(value.link)
                    ? history.goBack()
                    : history.push(value.link)
                }
              />
              {!value.active && <Breadcrumb.Divider />}
            </Fragment>
          ))}
        </Breadcrumb>
      </Menu.Item>
      {hasButton && (
        <Menu.Menu position="right">
          <Menu.Item>{children}</Menu.Item>
        </Menu.Menu>
      )}
    </Menu>
  )
}

Breadcrumbs.propTypes = {
  hasButton: t.bool,
  items: t.arrayOf(t.any),
  children: t.element,
}

export default Breadcrumbs
