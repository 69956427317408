import React from 'react'

import t from 'prop-types'
import { Card } from 'semantic-ui-react'

const Info = ({ place }) => (
  <Card fluid>
    <Card.Content>
      <Card.Header>Local</Card.Header>
      <Card.Description>{place || 'Não Localizada'}</Card.Description>
    </Card.Content>
  </Card>
)

Info.propTypes = {
  place: t.string,
}

export default Info
