import React from 'react'
import ReactMapGl, { Marker, Popup } from 'react-map-gl'

import t from 'prop-types'

import LogoDark from '~/assets/logo-dark.png'
import MarkerIcon from '~/components/MapsItems/MarkerIcon'
import { MAPBOX_TOKEN } from '~/config/env'

export default function Map({ viewport, onChange, items, local }) {
  return (
    <ReactMapGl
      {...viewport}
      width="100%"
      height={507}
      onViewportChange={onChange}
      mapboxApiAccessToken={MAPBOX_TOKEN}
      mapStyle="mapbox://styles/missionbrasil/cjkmphdta3c5r2rpezblnlfth"
    >
      <div key="key-local">
        <Popup
          anchor="top"
          tipSize={10}
          offsetTop={30}
          offsetLeft={15}
          closeButton={false}
          longitude={local?.lng}
          latitude={local?.lat}
        >
          <div style={{ fontSize: 13, textAlign: 'center' }}>
            <h5>Local da Missão</h5>
            <p>{local?.place}</p>
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.google.com/maps/?q=${local?.lat}, ${local?.lng}`}
              >
                {`(${local?.lat}, ${local?.lng})`}
              </a>
            </p>
          </div>
        </Popup>
        <Marker
          key="local"
          longitude={local?.lng}
          latitude={local?.lat}
          anchor="bottom"
        >
          <img src={LogoDark} width={50} height={50} alt="local" />
        </Marker>
      </div>

      {items.map((item, index) => (
        <MarkerIcon {...item} index={index} />
      ))}
    </ReactMapGl>
  )
}
Map.propTypes = {
  onChange: t.func,
  items: t.arrayOf(t.any),
  viewport: t.objectOf(t.any),
  local: t.objectOf({ lat: t.number, lng: t.number }),
}
