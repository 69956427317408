import React from 'react'

import t from 'prop-types'
import { Confirm } from 'semantic-ui-react'

function ConfirmUpdate({
  showConfirm,
  countBuyer,
  handleCancel,
  setShowConfirm,
}) {
  const content = `${countBuyer} ${
    countBuyer > 1
      ? 'pessoas compraram este produto.'
      : 'pessoa comprou este produto'
  } Tem certeza que deseja alterá-lo?`

  return (
    <Confirm
      open={showConfirm}
      header="Confirmação para Alteração"
      content={content}
      cancelButton="Cancelar"
      onCancel={handleCancel}
      confirmButton="Sim, desejo alterar"
      onConfirm={() => setShowConfirm(false)}
    />
  )
}

ConfirmUpdate.propTypes = {
  showConfirm: t.bool,
  countBuyer: t.number,
  handleCancel: t.func,
  setShowConfirm: t.func,
}

export default ConfirmUpdate
