import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Grid, Tab } from 'semantic-ui-react'
import * as Yup from 'yup'

import Breadcrumbs from '~/components/Breadcrumbs'
import useUsers from '~/hooks/useUsers'
import api from '~/services/api'
import { USER_UPDATE_BY_ADMIN } from '~/services/api/endpoints'
import history from '~/services/history'
import message from '~/utils/messages'

import { Cards } from './components/Cards'
import {
  RecentActivities,
  RecentPayments,
  RecentRegisters,
  UserGuests,
  PointsTransactions,
  Statement,
  SkillsTags,
} from './components/Tabs'
import { UserInfo } from './components/UserInfo'
import UserInfoEditModal from './components/UserInfo/UserInfoEditModal'

export default function UserDetail() {
  const { id: userId } = useParams()

  const { userDetail, getUserData } = useUsers(userId)

  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  function openModal() {
    setModalVisible(true)
  }

  // eslint-disable-next-line consistent-return
  async function updateProfile(profile) {
    try {
      setIsLoadingUpdate(true)

      const schema = Yup.object().shape({
        name: Yup.string().min(2).required('Nome não pode estar vazio!'),
        phone: Yup.string().min(14).required(),
        email: Yup.string().email().required(),
        password: Yup.string()
          .oneOf([Yup.ref('passwordConfirm'), null], 'Senhas devem ser iguais!')
          .notRequired(),
      })

      const isValid = schema.isValidSync(profile)

      if (!isValid) {
        const validate = schema.validateSync(profile)
        throw new Error(validate)
      }

      Object.keys(profile).forEach(i => {
        if (profile[i] === null || profile[i].trim().length === 0) {
          delete profile[i]
        }
      })

      delete profile.passwordConfirm

      const res = await api.patch(USER_UPDATE_BY_ADMIN(userId), profile, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getUserData(res.data.uid)
      setIsLoadingUpdate(false)
      setModalVisible(false)
      return message().success('Usuário atualizado com sucesso!')
    } catch (error) {
      setIsLoadingUpdate(false)
      message().error(error.message || 'Houve um erro no servidor')
    }
  }

  useEffect(() => {
    getUserData(userId)
  }, [userId]) //eslint-disable-line

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      name: 'Usuários',
      link: '/users',
    },
    {
      active: true,
      hasLink: false,
      name: userDetail?.name,
    },
  ]

  const panes = [
    {
      menuItem: 'Pagamentos Recentes',
      render: () => (
        <Tab.Pane attached={false}>
          <RecentPayments userId={userId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Registros Recentes',
      render: () => (
        <Tab.Pane attached={false}>
          <RecentRegisters userId={userId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Atividades Recentes',
      render: () => (
        <Tab.Pane attached={false}>
          <RecentActivities userDetailData={userDetail} userId={userId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Usuários Convidados',
      render: () => (
        <Tab.Pane attached={false}>
          <UserGuests userId={userId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Transações de Pontuação',
      render: () => (
        <Tab.Pane attached={false}>
          <PointsTransactions userId={userId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Extrato do Usuário',
      render: () => (
        <Tab.Pane attached={false}>
          <Statement userId={userId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Skills Tags',
      render: () => (
        <Tab.Pane attached={false}>
          <SkillsTags userId={userId} />
        </Tab.Pane>
      ),
    },
  ]

  return (
    <>
      <Breadcrumbs items={breadcrumbsList} history={history} />

      {userId !== null ? (
        <>
          <Grid>
            <Grid.Column width={4}>
              <UserInfo
                userDetail={userDetail}
                openModal={openModal}
                userId={userId}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <Cards userId={userId} />
              <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
            </Grid.Column>
          </Grid>
          <UserInfoEditModal
            open={modalVisible}
            userProfile={userDetail}
            submit={updateProfile}
            isLoading={isLoadingUpdate}
            close={() => setModalVisible(false)}
          />
        </>
      ) : (
        <div>ID do Usuário não foi informado</div>
      )}
    </>
  )
}
