import React, { memo } from 'react'
import DatePicker from 'react-datepicker'

import t from 'prop-types'
import { Icon, Select, Button, Form, Input, Popup } from 'semantic-ui-react'

import { CardFilter, ContainerFields } from './styles'

import '~/assets/css/react-datepicker.css'

const BudgetFilter = ({
  filters,
  isLoading,
  clearFilters,
  onChangeFilter,
  onSubmitExport,
}) => (
  <CardFilter>
    <Form style={{ width: '100%' }}>
      <ContainerFields>
        <Form.Group widths="equal" style={{ margin: 0 }}>
          <Form.Field
            control={Input}
            placeholder="Buscar por nome..."
            value={filters.user_name}
            onChange={(e, { value }) => onChangeFilter('user_name', value)}
          />
          <Form.Field
            control={Select}
            placeholder="Status"
            value={filters.status}
            options={[
              { key: 0, value: 0, text: 'Pendências' },
              { key: 1, value: 1, text: 'Aprovados' },
            ]}
            onChange={(e, { value }) => onChangeFilter('status', value)}
          />
          <Form.Field
            control={Select}
            placeholder="Tipo de Conta"
            value={filters.account_type}
            options={[
              { key: 0, value: 'pix', text: 'PIX' },
              { key: 1, value: 'bank_account', text: 'Conta Bancária' },
            ]}
            onChange={(e, { value }) => onChangeFilter('account_type', value)}
          />
          <Form.Field>
            <div className="ui input">
              <DatePicker
                id="initial"
                selectsStart
                dateFormat="dd/MM/yyyy"
                placeholderText="Data Inicio"
                selected={filters.initial}
                startDate={filters.initial}
                endDate={filters.final}
                onChange={value => onChangeFilter('initial', value)}
              />
            </div>
          </Form.Field>

          <Form.Field>
            <div className="ui input">
              <DatePicker
                id="final"
                selectsEnd
                dateFormat="dd/MM/yyyy"
                placeholderText="Data Fim"
                selected={filters.final}
                startDate={filters.initial}
                endDate={filters.final}
                onChange={value => onChangeFilter('final', value)}
              />
            </div>
          </Form.Field>
        </Form.Group>
        <Form.Group style={{ justifyContent: 'flex-end' }}>
          <Form.Field>
            <Popup
              inverted
              content="Limpar Filtro"
              position="top center"
              trigger={
                <Button icon basic color="red" onClick={clearFilters}>
                  <Icon name="undo" />
                </Button>
              }
            />
          </Form.Field>
          <Form.Field>
            <Popup
              inverted
              position="top center"
              content="Exportar .XLS"
              trigger={
                <Button
                  icon
                  basic
                  color="blue"
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={onSubmitExport}
                >
                  <Icon name="download" />
                </Button>
              }
            />
          </Form.Field>
        </Form.Group>
      </ContainerFields>
    </Form>
  </CardFilter>
)

BudgetFilter.propTypes = {
  filters: t.objectOf(t.any),
  isLoading: t.bool,
  clearFilters: t.func,
  onChangeFilter: t.func,
  onSubmitExport: t.func,
}

export default memo(BudgetFilter)
