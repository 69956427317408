export const statusLabel = {
  active: 'Ativo',
  canceled: 'Cancelado',
}

export const statusColor = {
  active: 'green',
  canceled: 'red',
}

export const planColor = {
  black_anual: 'black',
  black_month: 'black',
  prime_month: 'brown',
  prime_anual: 'brown',
}

export const planLabel = {
  black_anual: 'Plano Black Anual',
  black_month: 'Plano Black Mensal',
  prime_month: 'Plano Prime Mensal',
  prime_anual: 'Plano Prime Anual',
}
