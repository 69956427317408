import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

function useMemberGetMember() {
  const [host, setHost] = useState(null)
  const [loadingMember, setLoading] = useState(false)

  const getHost = useCallback(async params => {
    try {
      setLoading(true)
      const res = await api.get('member-get-member/host/admin', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setHost(res.data ? res.data : null)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    host,
    getHost,
    loadingMember,
  }
}

export default useMemberGetMember
