import React from 'react'
import ContentLoader from 'react-content-loader'

import { Card } from 'semantic-ui-react'

export default () => (
  <Card>
    <ContentLoader viewBox="0 0 315 300" width={315} height={300} speed={1}>
      <rect x="15" y="15" rx="30" ry="30" width="60" height="60" />
      <rect x="80" y="15" rx="4" ry="4" width="150" height="15" />
      <rect x="80" y="35" rx="4" ry="4" width="150" height="15" />
      <rect x="80" y="55" rx="4" ry="4" width="85" height="15" />
      <rect x="15" y="90" rx="3" ry="3" width="285" height="15" />
      <rect x="15" y="115" rx="3" ry="3" width="285" height="15" />
      <rect x="15" y="140" rx="3" ry="3" width="285" height="15" />
      <rect x="15" y="165" rx="3" ry="3" width="285" height="15" />
      <rect x="15" y="190" rx="3" ry="3" width="285" height="15" />
      <rect x="15" y="215" rx="3" ry="3" width="285" height="15" />
      <rect x="15" y="250" rx="3" ry="3" width="285" height="35" />
    </ContentLoader>
  </Card>
)
