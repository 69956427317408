import React from 'react'

import t from 'prop-types'
import { Button, Modal, Message } from 'semantic-ui-react'

const ZipImagesModal = ({
  isOpenZipLinkModal,
  setIsOpenZipLinkModal,
  reportSelected,
}) => {
  const renderZipLinks = () => {
    if (reportSelected.urls == null) {
      return false
    }

    return reportSelected.urls.map((url, index) => {
      const position = index + 1
      return (
        <>
          <a href={url} target="_blank" rel="noopener noreferrer">
            Clique aqui para baixar o {position}º zip
          </a>
          <br />
        </>
      )
    })
  }

  return (
    <Modal
      size="tiny"
      open={isOpenZipLinkModal}
      onClose={() => setIsOpenZipLinkModal(false)}
    >
      <Modal.Header>Arquivos da exportação de imagens</Modal.Header>
      <Modal.Content>
        <Message
          icon="info circle"
          header="Aviso"
          content="Cada arquivo zip tem até 1.000 arquivos dentro."
        />
        <div style={{ marginLeft: '10px' }}>{renderZipLinks()}</div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setIsOpenZipLinkModal(false)}>Fechar</Button>
      </Modal.Actions>
    </Modal>
  )
}

ZipImagesModal.propTypes = {
  isOpenZipLinkModal: t.bool,
  setIsOpenZipLinkModal: t.func,
  reportSelected: t.objectOf(t.any),
}

export default ZipImagesModal
