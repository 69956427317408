import React, { useState } from 'react'

import t from 'prop-types'
import { Table } from 'semantic-ui-react'

const Item = ({ item, handleRates }) => {
  const [style, setStyle] = useState({
    cursor: 'pointer',
  })

  const onMouseEnter = () => {
    setStyle(prevState => ({
      ...prevState,
      backgroundColor: '#EFEFEF',
    }))
  }

  const onMouseLeave = () => {
    setStyle(prevState => ({
      ...prevState,
      backgroundColor: '#fff',
    }))
  }

  return (
    <Table.Row
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => handleRates(item)}
      key={item.uid}
      positive={item.status && !item.is_deleted}
      negative={!item.status && !item.is_deleted}
      warning={(item.status || !item.status) && item.is_deleted}
    >
      <Table.Cell style={style}>{item.name}</Table.Cell>
      <Table.Cell style={style}>{item.email}</Table.Cell>
      <Table.Cell style={style}>{item.situation}</Table.Cell>
      <Table.Cell style={style}>
        {parseFloat(item.rate_avg).toFixed(1)}
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  item: t.objectOf(t.any),
  handleRates: t.func,
}

export default Item
