import React from 'react'

import t from 'prop-types'
import {
  Button,
  Container,
  Divider,
  Form,
  Modal,
  Select,
} from 'semantic-ui-react'

function Box({ children }) {
  return (
    <Container style={{ display: 'flex', flexDirection: 'column' }}>
      {children}
    </Container>
  )
}

function OperatorWarning({
  showOperatorWarning,
  onClose,
  onChange,
  submitOperatorWarning,
  operatorWarningData,
  loadingFormsItems,
}) {
  const operators = [
    {
      value: '>',
      text: 'Maior que',
    },
    {
      value: '>=',
      text: 'Maior ou Igual que',
    },
    {
      value: '=',
      text: 'Igual que',
    },
    {
      value: '<',
      text: 'Menor que',
    },
    {
      value: '<=',
      text: 'Menor ou Igual que',
    },
  ]

  const type = [
    {
      value: 'dot_comma',
      text: ';',
    },
    {
      value: 'comma',
      text: ',',
    },
    {
      value: 'number',
      text: 'Número',
    },
    {
      value: 'text',
      text: 'Texto',
    },
  ]

  return (
    <Modal open={showOperatorWarning} onClose={onClose} size="tiny">
      <Modal.Header>Operador de Alerta</Modal.Header>
      <Modal.Content>
        <Box>
          Operador:
          <Select
            value={operatorWarningData.operator_warning?.operator}
            options={operators}
            onChange={(e, { value }) => onChange('operator', value)}
          />
        </Box>
        <Divider hidden />
        <Box>
          Valor:
          <Form.Input
            value={operatorWarningData.operator_warning?.value}
            onChange={(e, { value }) => onChange('value', value)}
          />
        </Box>
        <Divider hidden />

        <Box>
          Tipo:
          <Select
            value={operatorWarningData.operator_warning?.type}
            options={type}
            onChange={(e, { value }) => onChange('type', value)}
          />
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Salvar"
          color="green"
          onClick={submitOperatorWarning}
          loading={loadingFormsItems}
        />
        <Button
          content="Sair"
          color="black"
          onClick={onClose}
          loading={loadingFormsItems}
        />
      </Modal.Actions>
    </Modal>
  )
}

Box.propTypes = {
  children: t.objectOf(t.any),
}

OperatorWarning.propTypes = {
  showOperatorWarning: t.bool,
  onClose: t.func,
  submitOperatorWarning: t.func,
  operatorWarningData: t.objectOf(t.any),
  onChange: t.func,
  loadingFormsItems: t.bool,
}

export default OperatorWarning
