import React, { useEffect, useState, useCallback } from 'react'

import { Divider, Header } from 'semantic-ui-react'
import { useDebounce } from 'use-lodash-debounce'

import Filter from '~/components/Filter'
import TableComponent from '~/components/Table'
import useUsers from '~/hooks/useUsers'

import Item from './components/Item'

export default function RatingUsers() {
  const columns = ['Nome', 'Email', 'Telefone', 'Avaliação']

  /* eslint-disable */
  const columnsMapForApiSort = {
    Nome: 'name',
    Email: 'email',
    Telefone: 'phone',
    Avaliação: 'rating',
  }
  /* eslint-enable */

  const { getUsers, users, loadingUser, count } = useUsers()
  const initialFilter = {
    offset: 0,
    limit: 15,
    order: 'rating',
    direction: 'desc',
    activePage: 1,
  }
  const [filter, setFilter] = useState(initialFilter)
  const debouncedFilters = useDebounce(filter, 500)

  function onChangeFilters(value) {
    setFilter(prevState => ({
      ...prevState,
      ...value,
    }))
  }

  function clearFilters() {
    setFilter(initialFilter)
  }

  useEffect(() => {
    setTimeout(() => {
      if (debouncedFilters !== null) {
        getUsers({ ...filter })
      }
    }, 300)
  }, [debouncedFilters]) //eslint-disable-line

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * filter.limit
    setFilter(prevState => ({
      ...prevState,
      activePage,
      offset,
      limit: filter.limit,
    }))
  }

  const handleSort = useCallback(
    (order, direction) => {
      setFilter(oldFilters => ({
        ...oldFilters,
        offset: 0,
        direction,
        order: columnsMapForApiSort[order],
      }))
    },
    [setFilter, columnsMapForApiSort]
  )

  const renderItem = item => <Item key={item.uid} item={item} />

  return (
    <>
      <Header as="h1">Avaliações de Usuários</Header>
      <Filter
        filters={filter}
        onChangeFilters={onChangeFilters}
        clearFilters={clearFilters}
        hasName
        hasEmail
        hasPhone
        hasRating
      />
      <Divider />
      <TableComponent
        data={users}
        columns={columns}
        isLoading={loadingUser}
        renderItem={renderItem}
        offset={filter?.offset}
        limit={filter?.limit}
        activePage={filter?.activePage}
        handlePaginationChange={handlePaginationChange}
        count={Number(count)}
        totalCount={Number(count)}
        handleColumnSort={handleSort}
        showFirstAndLastNav
        showPreviousAndNextNav
      />
    </>
  )
}
