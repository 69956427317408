import React, { memo, useState, useEffect } from 'react'

import _ from 'lodash'
import t from 'prop-types'
import { groupBy } from 'ramda'
import { Card, Button, Image, List, Search } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import Loading from '~/components/Loader/Subcategories'
import useRestrictedSubCategories from '~/hooks/useRestrictedSubCategories'
import useSubCategories from '~/hooks/useSubCategories'

function CustomerCategories({ customerId }) {
  const initialState = { isLoading: false, results: [] }
  const [values, setValue] = useState('')
  const [searchValues, setSearchValues] = useState(initialState)
  const {
    getRestrictedSubCategories,
    restrictedSubcategories,
    loadingRemoveRestricted,
    loadingRestricted,
    createRestrictedSubCategory,
    removeSubCategoriesByCustomerId,
  } = useRestrictedSubCategories()
  const { loadingSubcategories, getSubCategories } =
    useSubCategories()


  async function onSearchChange(e, { value }) {
    setValue(value)
    setSearchValues({ ...searchValues, isLoading: true })

    const subcategoriesReturned = await getSubCategories({
      limit: 3, title: value
    })

    const source = _.range(0, 3).reduce(item => {
      const transform = subcategoriesReturned.map(i => ({
        id: i.uid,
        title: i.title,
        image: i.avatar,
        price: i.customer_value,
        category: i.category_name,
        description: 'Clique para Adicionar',
      }))

      const grouped = groupBy(i => i.category)(transform)

      item = Object.keys(grouped).map(i => ({
        [i]: {
          name: i,
          results: grouped[i],
        },
      }))

      return Object.assign({}, ...item)
    }, {})

    setTimeout(() => {
      const re = new RegExp(_.escapeRegExp(values), 'i')
      const isMatch = result => re.test(result.title)

      const filteredResults = _.reduce(
        source,
        (item, data, name) => {
          const results = _.filter(data.results, isMatch)

          if (results.length) {
            item[name] = { name, results }
          }

          return item
        },
        {}
      )

      return setSearchValues({ isLoading: false, results: filteredResults })
    }, 300)
  }

  function onResultSelect(e, { result }) {
    createRestrictedSubCategory({ missionsMainId: result.id, customerId })
    return setSearchValues({ ...searchValues, value: result.title })
  }

  useEffect(() => {
    getRestrictedSubCategories(customerId)
  }, [customerId]) //eslint-disable-line

  return (
    <Card centered fluid style={{ marginBottom: 20, overflowY: 'auto' }}>
      <Card.Content>
        <Search
          category
          value={values}
          results={searchValues.results}
          onSearchChange={onSearchChange}
          loading={searchValues.isLoading || loadingSubcategories}
          onResultSelect={_.debounce(onResultSelect, 500, { leading: true })}
        />

        {loadingRestricted && <Loading />}

        {!loadingRestricted && restrictedSubcategories.length === 0 && (
          <EmptyList icon="tag" text="Nenhuma categoria foi adicionada" />
        )}

        <List divided verticalAlign="middle">
          {!loadingRestricted &&
            restrictedSubcategories.map(item => (
              <List.Item key={`a-${item.activity_id}`}>
                <List.Content floated="right">
                  <Button
                    color="red"
                    loading={loadingRemoveRestricted}
                    onClick={() => removeSubCategoriesByCustomerId(item.id)}
                  >
                    Remover
                  </Button>
                </List.Content>
                <Image avatar src={item.avatar} />
                <List.Content>
                  {item.activity_id}. {item.title}
                </List.Content>
              </List.Item>
            ))}
        </List>
      </Card.Content>
    </Card>
  )
}

CustomerCategories.propTypes = {
  customerId: t.string
}

export default memo(CustomerCategories)
