import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'

import t from 'prop-types'
import { Select, Button, Icon, Input, Divider } from 'semantic-ui-react'

import useMissionsMainValues from '~/hooks/useMissionsMainValues'
import message from '~/utils/messages'

import { Container, ContainerWrapper } from './styles'

const ButtonSave = ({ onClick, loading }) => (
  <Button
    icon
    size="large"
    color="green"
    onClick={onClick}
    labelPosition="left"
    style={{ marginLeft: 10 }}
  >
    <Icon loading={loading} name={loading ? 'refresh' : 'check'} />
    Salvar
  </Button>
)

const MissionMainValue = ({
  isPercent,
  onRefresh,
  subcategoryId,
  missionsUsersId,
}) => {
  const [missionsMainValueId, setMissionsMainValueId] = useState(null)
  const [costValue, setCostValue] = useState(null)
  const {
    loadingChangeValue,
    missionsMainValues,
    getMissionsMainValues,
    onChangeMissionsValuesOnRegisters,
  } = useMissionsMainValues()

  function onChange(e, { value }) {
    return setMissionsMainValueId(value)
  }

  function onChangeValue({ floatValue }) {
    return setCostValue(floatValue)
  }

  function onSubmitCostValue() {
    if (!costValue) {
      return message().error('Valor do Input de Vendas não pode estar vazio')
    }

    return onChangeMissionsValuesOnRegisters({
      onRefresh,
      costValue,
      missionsUsersId,
      id: missionsMainValueId,
    })
  }

  useEffect(() => {
    if (subcategoryId !== null) {
      getMissionsMainValues(subcategoryId)
    }
  }, []) //eslint-disable-line

  return (
    <ContainerWrapper>
      <Container>
        <Select
          search
          placeholder="Selecione um novo valor"
          style={{ marginRight: 5, width: 200 }}
          onChange={onChange}
          options={missionsMainValues.map(i => ({
            key: i.uid,
            value: i.uid,
            text: i.description,
          }))}
        />
        {!isPercent && missionsMainValueId && (
          <ButtonSave
            onClick={() =>
              onChangeMissionsValuesOnRegisters({
                onRefresh,
                missionsUsersId,
                id: missionsMainValueId,
              })
            }
            loading={loadingChangeValue}
          />
        )}
      </Container>
      <Divider />
      {isPercent && (
        <Container>
          <NumberFormat
            prefix="R$ "
            decimalScale={2}
            allowLeadingZeros
            fixedDecimalScale
            autoComplete="off"
            customInput={Input}
            decimalSeparator=","
            thousandSeparator="."
            value={costValue}
            style={{ minWidth: 200 }}
            onValueChange={onChangeValue}
            placeholder="R$ 150,90 (Digite '.' para centavos)"
          />
          {missionsMainValueId && (
            <ButtonSave
              onClick={onSubmitCostValue}
              loading={loadingChangeValue}
            />
          )}
        </Container>
      )}
    </ContainerWrapper>
  )
}

MissionMainValue.propTypes = {
  subcategoryId: t.string,
  missionsUsersId: t.string,
  onRefresh: t.func,
  isPercent: t.bool,
}

ButtonSave.propTypes = {
  onClick: t.func,
  loading: t.bool,
}

export default MissionMainValue
