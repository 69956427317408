import React from 'react'

import t from 'prop-types'
import { Button, Modal } from 'semantic-ui-react'

const ResendEmail = ({ open, userAdmin, close, resendEmail }) => {
  return (
    <Modal size="mini" open={open} onClose={() => open}>
      <Modal.Header>Reenviar email para ativação de conta</Modal.Header>
      <Modal.Content>
        Deseja reenviar a ativação de conta para {userAdmin?.name}?
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={close}>
          Não
        </Button>
        <Button positive onClick={resendEmail}>
          Sim
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

ResendEmail.propTypes = {
  resendEmail: t.func,
  close: t.func,
  open: t.bool,
  userAdmin: t.objectOf(t.any),
}

export default ResendEmail
