import { useCallback, useState } from 'react'

import * as Yup from 'yup'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useCategories() {
  const [loadingCategories, setLoadingCategories] = useState(false)
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState({
    name: '',
    status: false,
    description: '',
  })
  const [categoriesOptions, setCategoriesOptions] = useState([])

  const getCategories = useCallback(async params => {
    try {
      setLoadingCategories(true)
      const res = await api.get('categories/admin', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const options = res.data.categories.map(item => ({
        key: item.uid,
        value: item.uid,
        text: `${item.id} - ${item.name}`,
      }))
      setCategoriesOptions(options)
      return setCategories(res.data.categories)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingCategories(false)
    }
  }, [])

  async function onSubmitCategory(categoryData) {
    try {
      setLoadingCategories(true)

      const schema = Yup.object().shape({
        avatar: Yup.object().nullable().required('Imagem é obrigatória.'),
        name: Yup.string().required('Nome é obrigatório.'),
        description: Yup.string().required('Descrição é obrigatória.'),
        has_consultant: Yup.boolean().notRequired(),
      })

      const isValid = schema.isValidSync(categoryData)

      if (!isValid) {
        const validate = schema.validateSync(categoryData)
        return Message().error(validate)
      }

      const data = new FormData()

      data.set('name', categoryData.name)
      data.set('description', categoryData.description)
      data.set('has_consultant', categoryData.has_consultant)
      data.set('available_in', categoryData.available_in)

      if (categoryData.customer_id) {
        data.set('customer_id', categoryData.customer_id)
      } else if (categoryData.customer_id === false) {
        data.set('customer_id', null)
      }

      data.append('file', categoryData.avatar.data)

      const res = await api.post('categories', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getCategories()
      return Message().success('Categoria criada com sucesso!')
    } catch (error) {
      setLoadingCategories(false)
      return Message().error(error.message)
    }
  }

  const getCategoryById = useCallback(async id => {
    try {
      setLoadingCategories(true)
      const res = await api.get(`categories/admin/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingCategories(false)
      return setCategory(res.data)
    } catch (error) {
      setLoadingCategories(false)
      return Message().error(error.message)
    }
  }, [])

  async function onUpdateCategory(categoryData) {
    try {
      setLoadingCategories(true)

      const schema = Yup.object().shape({
        uid: Yup.string().uuid().required(),
        name: Yup.string().required('Nome é obrigatório.'),
        description: Yup.string().required('Descrição é obrigatória.'),
        has_consultant: Yup.boolean().notRequired(),
      })

      const isValid = schema.isValidSync(categoryData)

      if (!isValid) {
        const validate = schema.validateSync(categoryData)
        return Message().error(validate)
      }

      const data = new FormData()

      data.set('uid', categoryData.uid)
      data.set('name', categoryData.name)
      data.set('description', categoryData.description)
      data.set('has_consultant', categoryData.has_consultant)
      data.set('available_in', categoryData.available_in)

      if (categoryData.customer_id) {
        data.set('customer_id', categoryData.customer_id)
      } else if (categoryData.customer_id === false) {
        data.set('customer_id', null)
      }

      if (categoryData.avatar.data) {
        data.append('file', categoryData.avatar.data)
      }

      const res = await api.put('categories', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getCategories()
      return Message().success('Categoria atualizada com sucesso!')
    } catch (error) {
      setLoadingCategories(false)
      return Message().error(error.message)
    }
  }

  async function onRemoveCategory(id) {
    try {
      const res = await api.delete(`categories/admin/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCategories(categories.filter(c => c.uid !== id))

      return Message().success('Item removido com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    }
  }

  return {
    category,
    categories,
    onUpdateCategory,
    onSubmitCategory,
    getCategories,
    getCategoryById,
    onRemoveCategory,
    loadingCategories,
    categoriesOptions,
  }
}
