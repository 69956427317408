import * as React from 'react'
import { Popup as PopupMap, Marker as MarkerGl } from 'react-map-gl'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Icon } from 'semantic-ui-react'

import { feedIcon, feedLabel, iconColor } from '~/utils/types'

export function Marker({ item, index }) {
  return (
    <div key={`pop-${index}`}>
      <PopupMap
        anchor="top"
        tipSize={10}
        offsetTop={30}
        offsetLeft={15}
        closeButton={false}
        latitude={item.lat}
        longitude={item.lng}
      >
        <div style={{ fontSize: 13, textAlign: 'center' }}>
          <h5>{feedLabel[item.action]}</h5>
          <p>{dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}</p>
        </div>
      </PopupMap>
      <MarkerGl
        key={`marker-${index}`}
        offsetTop={0}
        offsetLeft={0}
        latitude={item.lat}
        longitude={item.lng}
      >
        <Icon
          circular
          inverted
          style={{
            marginRight: 10,
            // zIndex: state[`index${item.uid}`],
          }}
          color={iconColor[item.action]}
          name={feedIcon[item.action]}
        />
      </MarkerGl>
    </div>
  )
}

Marker.propTypes = {
  item: t.objectOf({
    lat: t.number,
    lng: t.number,
    action: t.string,
    created_at: t.string,
  }),
  index: t.number,
}

export const MemoizedMarker = React.memo(Marker)
