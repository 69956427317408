import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useDatausers() {
  const [dataUsers, setDatausers] = useState([])
  const [dataMissions, setDataMissions] = useState([])
  const [usersNearby, setUsersNearby] = useState([])
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [loadingMissions, setLoadingMissions] = useState(true)

  const getDataUsers = useCallback(async () => {
    try {
      const res = await api.get('data-users/geo', {
        params: {
          types: ['uf'],
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setDatausers(res.data)
      return setLoadingUsers(false)
    } catch (error) {
      setLoadingUsers(false)
      return Message().error(error.message)
    }
  }, [])

  const getDataMissions = useCallback(async () => {
    try {
      const res = await api.get('missions-data/geo', {
        params: {
          types: ['uf'],
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setDataMissions(res.data)
      return setLoadingMissions(false)
    } catch (error) {
      setLoadingMissions(false)
      return Message().error(error.message)
    }
  }, [])

  const getUsersNearby = async query => {
    try {
      setLoadingUsers(true)
      const res = await api.get('data-users/nearby', {
        params: query,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setUsersNearby([...res.data])

      return setLoadingUsers(false)
    } catch (error) {
      setLoadingUsers(false)
    }
  }

  return {
    dataUsers,
    dataMissions,
    usersNearby,
    setUsersNearby,
    loadingMissions,
    loadingUsers,
    getDataUsers,
    getUsersNearby,
    getDataMissions,
  }
}
