/* eslint-disable no-shadow */
import { useCallback, useState } from 'react'

import FileSaver from 'file-saver'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useUsers(userId) {
  const [users, setUsers] = useState([])
  const [count, setCount] = useState(0)
  const [allUsers, setAllUsers] = useState([])
  const [userDetail, setUserDetail] = useState({})
  const [countUsers, setCountUsers] = useState(null)
  const [loadingUser, setLoadingUser] = useState(true)
  const [loadingExport, setLoadingExport] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [loadingNotify, setLoadingNotify] = useState(false)
  const [loadingCheckCPF, setLoadingCheckCPF] = useState(false)

  const getUserData = useCallback(async () => {
    try {
      const res = await api.get('users/admin/metadata', {
        params: {
          userId,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status === 500) {
        throw new Error(res.data.message)
      }

      return setUserDetail(res.data)
    } catch (error) {
      throw new Error(error.message)
    } finally {
      setLoadingUser(false)
    }
  }, [userId])

  async function sendMessagesToUsers(data) {
    try {
      setLoadingNotify(true)
      const res = await api.post('notifications/hunting', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Mensagem encaminhada com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingNotify(false)
    }
  }

  async function getAllUsers(data) {
    try {
      setLoadingUser(true)
      const params = { ...data }

      const res = await api.get(
        'users/admin',
        {
          params,
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCountUsers(Number(res.data.data[0]?.total_rows) || 0)
      return setAllUsers(res.data.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingUser(false)
    }
  }

  async function getUsers(data) {
    try {
      setLoadingUser(true)
      const params = { ...data }

      const res = await api.get(
        'rate-users',
        { params },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCount(res.data.data[0]?.total_rows || 0)
      return setUsers(res.data.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingUser(false)
    }
  }

  async function updateUsers(data) {
    try {
      setLoadingUpdate(true)
      const res = await api.patch(`users/admin/change/${data.uid}`, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Os dados foram alterados com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingUpdate(false)
    }
  }
  async function onExport(params) {
    try {
      setLoadingExport(true)
      const res = await api.get('users/export', {
        params,
        responseType: 'arraybuffer',
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        const text = Buffer.from(res.data).toString('utf-8')
        const { message } = JSON.parse(text)

        throw new Error(message)
      }

      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      })

      return FileSaver.saveAs(blob, `users_${Date.now()}`)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingExport(false)
    }
  }

  async function checkCPFStatus(userId) {
    try {
      setLoadingCheckCPF(true)

      const res = await api.post(
        'users/check-cpf',
        { userId },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      Message().success('Consulta realizada com sucesso')

      return true
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingCheckCPF(false)
    }
  }

  return {
    userDetail,
    loadingUser,
    loadingNotify,
    getUserData,
    loadingUpdate,
    sendMessagesToUsers,
    getUsers,
    users,
    count,
    allUsers,
    onExport,
    loadingExport,
    countUsers,
    getAllUsers,
    updateUsers,
    checkCPFStatus,
    loadingCheckCPF,
  }
}
