import { useCallback, useState } from 'react'

import FileSaver from 'file-saver'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useSubscribes() {
  const [loadingSubs, setLoading] = useState(true)
  const [subscribes, setSubscribes] = useState([])
  const [dashboard, setDashboard] = useState(null)
  const [loadingDash, setLoadingDash] = useState(true)
  const [subscribesCount, setSubscribesCount] = useState(0)

  const getSubscribes = useCallback(async params => {
    try {
      setLoading(true)

      const res = await api.get('subscriptions/admin/subscribes', {
        params,
        responseType:
          params.xls === 'true' ? 'arraybuffer' : 'application/json',
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      if (params.xls === 'true') {
        Message().success('Exportação realizada com sucesso.')

        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        })

        return FileSaver.saveAs(blob, `subscribes-${Date.now()}`)
      }

      setSubscribesCount(Number(res.data.count))

      return setSubscribes(res.data.subscribes)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  const getDashboardSubscribes = useCallback(async params => {
    try {
      setLoadingDash(true)

      const res = await api.get('subscriptions/admin/dashboard', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setDashboard(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingDash(false)
    }
  }, [])

  return {
    subscribes,
    loadingSubs,
    loadingDash,
    dashboard,
    getSubscribes,
    getDashboardSubscribes,
    subscribesCount,
  }
}
