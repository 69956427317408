import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useImports() {
  const [imports, setImports] = useState([])
  const [loadingImports, setLoadingImports] = useState(false)
  const [loadingImportsCreated, setLoadingCreated] = useState(false)
  const [page, setPage] = useState(0)

  const processSpreadSheet = async (rows, filename, customerId) => {
    try {
      setLoadingCreated(true)
      let lines = []
      let importId = null
      for (let index = 0; index < rows.length; index += 1) {
        lines.push({ ...rows[index], line: index })

        if (lines.length === 1000) {
          const response = await api.post('/import-files', {
            name: filename,
            customerId,
            importId,
            lines,
            totalLines: rows.length,
          })

          importId = response.data.importId
          lines = []
        }
      }

      if (lines.length > 0) {
        await api.post(`/import-files`, {
          name: filename,
          customerId,
          importId,
          lines,
          totalLines: rows.length,
        })
      }
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setPage(0)
      getImportsAfterNewImportFile()
      setLoadingCreated(false)
    }
  }

  const getImportsAfterNewImportFile = async () => {
    try {
      const pageToSeach = page + 1
      setPage(pageToSeach)
      setLoadingImports(true)
      const res = await api.get(`/import-files?page=1&itensPerPage=10`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setImports(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingImports(false)
    }
  }

  const getImports = async () => {
    try {
      const pageToSeach = page + 1
      setPage(pageToSeach)
      setLoadingImports(true)
      const res = await api.get(
        `/import-files?page=${pageToSeach}&itensPerPage=10`,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setImports([...imports, ...res.data])
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingImports(false)
    }
  }

  async function onRemoveImportById(id) {
    try {
      setLoadingImports(true)
      const res = await api.delete(`/import-files/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 204) {
        throw new Error(res.data.message)
      }

      return setImports(imports.filter(i => i.uid !== id))
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingImports(false)
    }
  }

  return {
    imports,
    getImports,
    loadingImportsCreated,
    loadingImports,
    onRemoveImportById,
    processSpreadSheet,
  }
}
