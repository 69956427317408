import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import { List } from 'semantic-ui-react'

import FormsItemsDetail from './FormsItemsDetail'

const SortableMultipleItem = SortableElement(FormsItemsDetail)

const FormsItems = ({
  items,
  state,
  handlerInput,
  removeMultipleItems,
  handleChangeFormMultiple,
}) => (
  <List celled style={{ fontSize: 12 }}>
    {items.map((value, index) => (
      <SortableMultipleItem
        index={index}
        value={value}
        state={state}
        key={`index-${index}`}
        handlerInput={handlerInput}
        removeItem={removeMultipleItems}
        handleChangeFormMultiple={handleChangeFormMultiple}
      />
    ))}
  </List>
)

export default FormsItems
