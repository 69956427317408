import React from 'react'
import ReactDatePicker from 'react-datepicker'

import t from 'prop-types'
import { Card, Form, Input, Button, Dropdown } from 'semantic-ui-react'

const options = [
  { key: '1', text: 'Ativo', value: 'active' },
  { key: '2', text: 'Cancelado', value: 'canceled' },
]

const optionsPlans = [
  { key: '1', text: 'Plano Black Anual', value: 'black_anual' },
  { key: '2', text: 'Plano Black Mensal', value: 'black_month' },
  { key: '3', text: 'Plano Prime Mensal', value: 'prime_month' },
  { key: '4', text: 'Plano Prime Anual', value: 'prime_anual' },
]

const Filter = ({
  filters,
  onChange,
  clearFilters,
  onSubmit,
  onSubmitReport,
}) => (
  <Card fluid>
    <Card.Content>
      <Form>
        <Form.Group widths="equal">
          <Form.Field
            fluid
            control={Input}
            icon="search"
            placeholder="Nome"
            value={filters?.name || ''}
            onChange={(e, { value }) => onChange({ name: value })}
          />
          <Form.Field
            fluid
            control={Input}
            icon="mail"
            placeholder="Email"
            value={filters?.email || ''}
            onChange={(e, { value }) => onChange({ email: value })}
          />
          <Form.Field
            fluid
            control={Input}
            icon="phone"
            placeholder="Telefone"
            value={filters?.phone || ''}
            onChange={(e, { value }) => onChange({ phone: value })}
          />
          <Form.Field
            fluid
            selection
            search
            options={options}
            control={Dropdown}
            placeholder="Avaliação"
            value={filters?.status || ''}
            onChange={(e, { value }) => onChange({ status: value })}
          />
          <Form.Field
            fluid
            selection
            search
            options={optionsPlans}
            control={Dropdown}
            placeholder="Planos"
            value={filters?.unit_label || ''}
            onChange={(e, { value }) => onChange({ unit_label: value })}
          />
          <Form.Field
            selectsStart
            id="startDate"
            control={ReactDatePicker}
            dateFormat="dd/MM/yyyy"
            placeholderText="Data Inicio"
            selected={filters.started_at}
            startDate={filters.started_at}
            endDate={filters.finished_at}
            onChange={value => onChange({ started_at: value })}
          />
          <Form.Field
            control={ReactDatePicker}
            id="endDate"
            selectsEnd
            dateFormat="dd/MM/yyyy"
            placeholderText="Data Fim"
            selected={filters.finished_at}
            startDate={filters.started_at}
            endDate={filters.finished_at}
            onChange={value => onChange({ finished_at: value })}
          />
        </Form.Group>
      </Form>
    </Card.Content>
    <Card.Content>
      <Button content="Filtrar" primary onClick={onSubmit} />
      <Button content="Exportar" color="green" onClick={onSubmitReport} />
      <Button content="Limpar" onClick={clearFilters} />
    </Card.Content>
  </Card>
)

Filter.propTypes = {
  filters: t.objectOf(t.any),
  onChange: t.func,
  onSubmit: t.func,
  clearFilters: t.func,
}

export { Filter }
