import React from 'react'

import { Card, Form, Icon, Input, Button, Divider } from 'semantic-ui-react'

export default function CreateItem({
  show,
  title,
  badges,
  loading,
  submit,
  types,
  submitType,
  description,
  handleInput,
  handleFormType,
}) {
  return (
    <Card
      centered
      fluid
      style={{ marginTop: 10, display: show ? 'block' : 'none' }}
    >
      <Card.Content>
        <Form>
          <Form.Input
            type="text"
            value={title || ''}
            label="Título (Obrigatório)"
            placeholder="Ex: Consultor"
            onChange={(e, { value }) => handleInput(value, 'title')}
          />
          <Form.Input
            type="text"
            value={description || ''}
            label="Descrição (Obrigatório)"
            placeholder="Ex: Formulário relacionado ao badge..."
            onChange={(e, { value }) => handleInput(value, 'description')}
          />
          <Form.Select
            label="Badges"
            placeholder="Badge"
            options={badges}
            onChange={(e, { value }) => handleInput(value, 'badge_id')}
          />
          <Form.Select
            label="Tipo"
            options={types}
            loading={loading}
            placeholder="Tipo"
            onChange={(e, { value }) => handleInput(value, 'form_user_type_id')}
          />
          <Divider />
          <Input
            onChange={(e, { value }) => handleFormType(value)}
            type="text"
          />
          <Button
            positive
            content="Adicionar Tipo"
            style={{ marginLeft: 10 }}
            onClick={() => submitType()}
          />
        </Form>
      </Card.Content>
      <Card.Content extra textAlign="right">
        <Button
          icon
          positive
          loading={loading}
          disabled={loading}
          labelPosition="left"
          onClick={() => submit()}
        >
          <Icon name="check" /> Salvar
        </Button>
      </Card.Content>
    </Card>
  )
}
