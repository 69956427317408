import React from 'react'

import t from 'prop-types'
import { Confirm } from 'semantic-ui-react'

function ConfirmUpdate({ showConfirm, handleCancel, setShowConfirm }) {
  return (
    <Confirm
      open={showConfirm}
      header="Confirmação para Alteração"
      content="Há produtos com essa categoria vinculada. Deseja continuar com a alteração?"
      cancelButton="Cancelar"
      onCancel={handleCancel}
      confirmButton="Sim, desejo alterar"
      onConfirm={() => setShowConfirm(false)}
    />
  )
}

ConfirmUpdate.propTypes = {
  showConfirm: t.bool,
  handleCancel: t.func,
  setShowConfirm: t.func,
}

export default ConfirmUpdate
