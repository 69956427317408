import React, { useState } from 'react'

import { Table, Header, Card } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import useMetrics from '~/hooks/useMetrics'
import useSubCategories from '~/hooks/useSubCategories'

import Filters from './Filters'
import FooterItem from './FooterItem'
import { Item } from './Item'

import 'react-semantic-toasts/styles/react-semantic-alert.css'

function Metrics() {
  const {
    metrics,
    loadingMetrics,
    onSubmitMetrics,
    onSubmitMetricsExport,
    sumTotals,
  } = useMetrics()
  const { getSubCategories, subcategories } = useSubCategories()

  const [analytical, setAnalytical] = useState(false)
  const [filters, setFilters] = useState({
    initial: '',
    final: '',
    group: '',
    range: '',
    period: 'interval',
  })
  const [conditional, setCondicional] = useState({
    customer: [],
    category: [],
    missions_main: [],
  })

  const [types, setTypes] = useState({
    typesGroups: [],
    typesMissions: [],
  })

  async function onSubmit(isXls) {
    const data = {
      groups: {
        types: types.typesGroups,
        range: filters.range,
      },
      types: types.typesMissions,
      initial: new Date(filters.initial).getTime(),
      final: new Date(filters.final).getTime(),
      conditions: [
        ...conditional.customer,
        ...conditional.category,
        ...conditional.missions_main,
      ],
      analytical,
    }

    if (isXls) {
      return onSubmitMetricsExport({ data, query: { xls: isXls } })
    }

    return onSubmitMetrics({ data, query: { xls: false } })
  }

  function onChange(key, value) {
    return setFilters(prevState => ({ ...prevState, [key]: value }))
  }

  function onIncrementTypes(key, value) {
    const index = types[key].findIndex(i => i === value)

    if (index > -1) {
      return setTypes(prevState => ({
        ...prevState,
        [key]: types[key].filter(i => i !== value),
      }))
    }

    return setTypes(prevState => ({
      ...prevState,
      [key]: [...types[key], value],
    }))
  }

  function onIncrementCondicional(key, value) {
    if (key === 'category') {
      getSubCategories({ categoryId: value[0], limit: 1000 })
    }

    const condObject = { type: key, uid: value[0] }

    const index = conditional[key].findIndex(i => i === condObject)

    if (index > -1) {
      return setCondicional(prev => ({
        ...prev,
        [key]: conditional[key].filter(i => i !== condObject),
      }))
    }

    return setCondicional(prev => ({
      ...prev,
      [key]: [...conditional[key], condObject],
    }))
  }

  function onResetFilter() {
    return window.location.reload()
  }

  const Headers = () => (
    <Table.Header>
      <Table.Row>
        <Table.Cell>Cliente</Table.Cell>
        <Table.Cell>Categoria</Table.Cell>
        <Table.Cell>Subcategoria</Table.Cell>
        <Table.Cell>Tipo</Table.Cell>
        <Table.Cell>Período</Table.Cell>
        <Table.Cell>Total</Table.Cell>
        <Table.Cell>MU</Table.Cell>
        <Table.Cell>Valor Inputado (R$)</Table.Cell>
        <Table.Cell>Valor Cliente (R$)</Table.Cell>
        <Table.Cell>Valor Usuário (R$)</Table.Cell>
        <Table.Cell>Total Pontos</Table.Cell>
      </Table.Row>
    </Table.Header>
  )

  const hasProperty = (object, key) =>
    Object.prototype.hasOwnProperty.call(object, key)

  return (
    <>
      <Filters
        filters={filters}
        onChange={onChange}
        onSubmit={onSubmit}
        loading={loadingMetrics}
        subcategories={subcategories}
        onResetFilter={onResetFilter}
        checkAnalytical={setAnalytical}
        onIncrementTypes={onIncrementTypes}
        onIncrementCondicional={onIncrementCondicional}
      />

      {metrics.length > 0 && (
        <div
          style={{
            flex: 1,
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <Header as="h4">Total de {metrics.length} resultados.</Header>
        </div>
      )}

      {metrics.length > 0 && hasProperty(metrics[0], 'items') ? (
        metrics.map((item, index) => (
          <Card key={String(index)} fluid>
            <Card.Content>
              {hasProperty(item, 'cliente') && (
                <Card.Header>{item.cliente}</Card.Header>
              )}
              {hasProperty(item, 'categoria') && (
                <Card.Header>{item.sub_categoria}</Card.Header>
              )}
              {hasProperty(item, 'sub_categoria') && (
                <Card.Header>{item.sub_categoria}</Card.Header>
              )}
              <Table celled selectable>
                <Headers />
                {item.items.map((i, idx) => (
                  <Item item={i} key={String(idx)} />
                ))}
              </Table>
            </Card.Content>
          </Card>
        ))
      ) : (
        <Table celled selectable>
          <Headers />
          <Table.Body>
            {metrics.map((i, idx) => (
              <Item item={i} key={String(idx)} />
            ))}
          </Table.Body>
          {!loadingMetrics && sumTotals ? (
            <FooterItem sumTotals={sumTotals} />
          ) : null}
        </Table>
      )}

      {metrics.length === 0 && (
        <EmptyList icon="chart bar" text="Nenhuma métrica executada..." />
      )}
    </>
  )
}

export default Metrics
