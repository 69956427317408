import React, { useState, useEffect, useCallback } from 'react'

import t from 'prop-types'
import { length } from 'ramda'
import {
  Modal,
  Button,
  Loader,
  Dimmer,
  Message,
  Segment,
  Image,
  List,
  Header,
  Icon,
  Form,
  Input,
} from 'semantic-ui-react'

import Avatar from '~/assets/avatar.png'
import api from '~/services/api'

import { ListItem, SearchForm } from './styles'

export function ModalMissionUsers({
  onSubmit,
  loading,
  isOpenModalUsers,
  onCloseModalUsers,
}) {
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('')
  const [errorsModal, setErrorsModal] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getUsers = useCallback(async () => {
    try {
      setIsLoading(true)
      setErrorsModal([])
      const response = await api.get('users/admin', {
        params: {
          search,
          limit: 10,
          offset: 0,
          order: 'created_at',
        },
      })

      setUsers(response.data.data)
    } catch (error) {
      setErrorsModal(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [search])

  useEffect(() => {
    getUsers()
  }, []) //eslint-disable-line

  return (
    <Modal size="small" open={isOpenModalUsers} onClose={onCloseModalUsers}>
      <Modal.Header>Filtrar</Modal.Header>
      <SearchForm>
        <Form.Field
          fluid
          control={Input}
          icon="search"
          className="icon"
          placeholder="Nome ou e-mail do usuário"
          value={search}
          onChange={(e, { value }) => setSearch(value)}
          disabled={isLoading}
        />
        <Button
          icon="check"
          color="green"
          content="Filtrar"
          labelPosition="left"
          onClick={getUsers}
          loading={isLoading}
          disabled={isLoading}
        />
      </SearchForm>
      <Modal.Header>Selecione um novo usuário</Modal.Header>
      <Modal.Content>
        {length(errorsModal) > 0 && (
          <Message negative header="Alerta!" content={errorsModal} />
        )}

        {isLoading && (
          <Segment>
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>

            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
          </Segment>
        )}

        <List divided verticalAlign="middle">
          {users.length === 0 && !isLoading ? (
            <div style={{ textAlign: 'center' }}>
              <Header icon style={{ marginTop: 20 }}>
                <Icon style={{ color: '#b9b9b9' }} name="user outline" />
                <span style={{ color: '#b9b9b9' }}>
                  Nenhum usuário encontrado...
                </span>
              </Header>
            </div>
          ) : (
            users.map(user => {
              const avatar = user.avatar === null ? Avatar : user.avatar

              return (
                <ListItem key={user.uid}>
                  <div>
                    <Image avatar src={avatar} />
                    <List.Content>
                      <strong>{user.name}</strong>
                      <span style={{ marginLeft: 3 }}>{user.email}</span>
                    </List.Content>
                  </div>

                  <Button
                    className="alignRight"
                    primary
                    loading={loading}
                    onClick={() => onSubmit(user.uid)}
                    content="Selecionar"
                  />
                </ListItem>
              )
            })
          )}
        </List>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={onCloseModalUsers} content="Cancelar" />
      </Modal.Actions>
    </Modal>
  )
}

ModalMissionUsers.propTypes = {
  isOpenModalUsers: t.bool,
  onCloseModalUsers: t.func,
  onSubmit: t.func,
  loading: t.bool,
}
