import queryString from 'query-string'

import {
  DASHBOARD,
  DASHBOARD_REPORT_DATA,
  DASHBOARD_REPORT_CATEGORIES,
} from '~/services/api/endpoints'
import { get } from '~/utils/actionsRequests'

const dashboard = () =>
  get({
    url: `${DASHBOARD}?period=year`,
    typeFetch: 'DASHBOARD',
    typeError: 'DASHBOARD_FAIL',
    typeResponse: 'DASHBOARD_OK',
  })

const reportCategories = () =>
  get({
    url: DASHBOARD_REPORT_CATEGORIES,
    typeFetch: 'DASHBOARD_REPORT_CATEGORIES',
    typeError: 'DASHBOARD_REPORT_CATEGORIES_FAIL',
    typeResponse: 'DASHBOARD_REPORT_CATEGORIES_OK',
  })

const filter = ({
  typeData,
  table,
  period,
  where,
  status,
  columnDate,
  limit,
}) => {
  const query = queryString.stringify({
    typeData,
    table,
    period,
    where,
    status,
    limit,
    columnDate,
  })
  return get({
    url: `${DASHBOARD_REPORT_DATA}?${query}`,
    typeFetch: 'DASHBOARD_REPORT_DATA',
    typeError: 'DASHBOARD_REPORT_DATA_FAIL',
    typeResponse: 'DASHBOARD_REPORT_DATA_OK',
  })
}

export { dashboard, filter, reportCategories }
