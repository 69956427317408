import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'

import dayjs from 'dayjs'
import t from 'prop-types'
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  List,
  Modal,
  Popup,
} from 'semantic-ui-react'

import useGeo from '~/hooks/useGeo'
import { feedLabel } from '~/utils/types'

function UpdateModal({ open, onClose, itemData, onSubmitUpdate }) {
  const [values, setValues] = useState({})
  const [openAddressModal, setOpenAddressModal] = useState(false)

  const {
    onSearchAddress,
    addresses,
    getAddressByCordinates,
    addressByCordinates,
    loadingAddresses,
  } = useGeo()

  const formatTime = value => {
    const fullTime = value.replace(/[^0-9]/g, '')

    if (fullTime.length > 4) {
      return `${fullTime.slice(0, 2)}:${fullTime.slice(2, 4)}`
    }
    if (value.length > 2) {
      return `${fullTime.slice(0, 2)}:${fullTime.slice(2)}`
    }
    return fullTime
  }

  const handleTimeChange = value => {
    const newTime = formatTime(value)
    setValues(prevState => ({
      ...prevState,
      time: newTime,
    }))
  }

  const handleOnChange = (key, value) => {
    if (key === 'date') {
      value = dayjs(value).format('YYYY-MM-DD')
    }

    setValues(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleOnSearchAddress = () => {
    onSearchAddress(values.place)
    setOpenAddressModal(true)
  }

  const handleOnClickAddress = address => {
    setValues(prevState => ({
      ...prevState,
      place: address.formatted_address,
      lat: address.geometry?.location?.lat,
      lng: address.geometry?.location?.lng,
    }))

    setTimeout(() => {
      setOpenAddressModal(false)
    }, 500)
  }

  const handleOnClick = () => {
    onSubmitUpdate({ values, missionsFeedsId: itemData?.uid })
  }

  useEffect(() => {
    const fullDate = dayjs(itemData?.created_at)
    const date = fullDate.format('YYYY-MM-DD')
    const time = fullDate.format('HH:mm')

    setValues(prevState => ({
      ...prevState,
      place: addressByCordinates,
      lat: itemData?.lat,
      lng: itemData?.lng,
      date,
      time,
    }))
  }, [addressByCordinates, itemData])

  return (
    <>
      <Modal
        open={open}
        size="tiny"
        onClose={() => onClose()}
        onMount={() => getAddressByCordinates(itemData?.lat, itemData?.lng)}
      >
        <Modal.Header>Editar Feed - {feedLabel[itemData?.action]}</Modal.Header>
        <Modal.Content>
          <Form loading={loadingAddresses}>
            <Grid>
              <Grid.Row stretched>
                <Grid.Column>
                  <Form.Field
                    id="place"
                    label="Endereço"
                    control={Input}
                    value={values.place}
                    onChange={(e, { value }) => handleOnChange('place', value)}
                    icon={
                      <Popup
                        content="Pesquisar Endereço"
                        trigger={
                          <Icon
                            name="search"
                            inverted
                            circular
                            link
                            onClick={() => handleOnSearchAddress()}
                          />
                        }
                      />
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2} stretched>
                <Grid.Column>
                  <Form.Field
                    id="date"
                    label="Data"
                    control={ReactDatePicker}
                    value={dayjs(values.date).format('DD/MM/YYYY')}
                    onChange={value => handleOnChange('date', value)}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Field
                    id="time"
                    label="Hora"
                    control={Input}
                    value={values.time}
                    onChange={(e, { value }) => handleTimeChange(value)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Cancelar"
            onClick={() => onClose()}
            disabled={loadingAddresses}
          />
          <Button
            content="Confirmar"
            primary
            onClick={() => handleOnClick()}
            disabled={loadingAddresses}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        open={openAddressModal}
        size="small"
        onClose={() => setOpenAddressModal(false)}
      >
        {addresses.length > 0 && (
          <>
            <Modal.Header>Seleção de Endereços</Modal.Header>
            <Modal.Content>
              <List divided verticalAlign="middle">
                {addresses.map(address => (
                  <List.Item>
                    <List.Content floated="left">
                      <Button
                        icon={<Icon name="plus" />}
                        onClick={() => handleOnClickAddress(address)}
                      />
                    </List.Content>
                    <List.Content>{address.formatted_address}</List.Content>
                  </List.Item>
                ))}
              </List>
            </Modal.Content>
          </>
        )}
      </Modal>
    </>
  )
}

UpdateModal.propTypes = {
  open: t.bool,
  onClose: t.func,
  itemData: t.objectOf(t.any),
  onSubmitUpdate: t.func,
}

export default UpdateModal
