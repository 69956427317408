import React from 'react'

import t from 'prop-types'
import { Button, Modal } from 'semantic-ui-react'

const AccountCancel = ({ open, userAdmin, close, submitCancel }) => {
  return (
    <Modal size="mini" open={open} onClose={() => open}>
      <Modal.Header>Cancelar Conta</Modal.Header>
      <Modal.Content>
        Deseja cancelar a conta de {userAdmin?.name}?
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={close}>
          Não
        </Button>
        <Button positive onClick={submitCancel}>
          Sim
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

AccountCancel.propTypes = {
  submitCancel: t.func,
  close: t.func,
  open: t.bool,
  userAdmin: t.objectOf(t.any),
}

export default AccountCancel
