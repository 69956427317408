import React, { useEffect, useState } from 'react'

import TableComponent from '~/components/Table'
import useCustomers from '~/hooks/useCustomers'
import useFinancialCustomers from '~/hooks/useFinancialCustomers'
import Main from '~/pages/Main'

import Filter from './components/Filter'
import Item from './components/Item'

function CustomersCreditRequests() {
  const [filters, setFilters] = useState({
    final: null,
    initial: null,
    customerId: null,
    taxValue: 0,
  })
  const {
    creditItems,
    getCreditRequests,
    loadingCreditItems,
    onUpdateCreditRequest,
    onSubmitCreditRequest,
    loadingUpdateCreditItem,
    countCreditItems,
    createCreditRequestLicensedPayment,
    onCreateCloseRequestPayment,
  } = useFinancialCustomers()
  const { loadingCustomers, customers, getCustomers } = useCustomers()

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 50,
    activePage: 1,
  })

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * pagination.limit
    setPagination(prevState => ({
      ...prevState,
      activePage,
      offset,
      limit: pagination.limit,
    }))
    return getCreditRequests({
      offset,
      limit: pagination.limit,
    })
  }

  function onChangeFilter(key, value) {
    return setFilters(prevState => ({ ...prevState, [key]: value }))
  }

  function onSubmitFilter() {
    return onSubmitCreditRequest({
      final: filters.final,
      initial: filters.initial,
      customer_id: filters.customerId,
    })
  }

  async function onCreateCreditRequestLicensedPayment() {
    return createCreditRequestLicensedPayment({
      customer_id: filters.customerId,
      valuePerUser: filters.valuePerUser,
      totalLicenses: filters.totalLicenses,
      payment_type: 'BOLETO',
    })
  }

  const [modalCloseRequest, setModalCloseRequest] = useState(false)

  async function onSubmitCloseRequest() {
    await onCreateCloseRequestPayment({
      ...filters,
      tax: filters.taxValue,
      paymentType: 'BOLETO',
    })

    setFilters(prevState => ({ ...prevState, taxValue: 0 }))

    setTimeout(() => {
      setModalCloseRequest(false)
    }, 1000)
  }

  useEffect(() => {
    getCustomers({ limit: 1000, offset: 0 })
  }, []) //eslint-disable-line

  useEffect(() => {
    getCreditRequests({ offset: pagination.offset, limit: pagination.limit })
  }, []) //eslint-disable-line

  return (
    <Main>
      <Filter
        filters={filters}
        setModalCloseRequest={setModalCloseRequest}
        modalCloseRequest={modalCloseRequest}
        onCreateCloseRequestPayment={onSubmitCloseRequest}
        onCreateCreditRequestLicensedPayment={
          onCreateCreditRequestLicensedPayment
        }
        onChange={onChangeFilter}
        onSubmit={onSubmitFilter}
        loading={loadingUpdateCreditItem}
        loadingCustomers={loadingCustomers}
        customers={customers.map((item, index) => ({
          key: index,
          customer_payment_type: item.customer_payment_type,
          text: item.name,
          value: item.uid,
        }))}
      />
      <TableComponent
        showFirstAndLastNav
        showPreviousAndNextNav
        showEllipsis
        activePage={pagination.activePage}
        limit={pagination.limit}
        offset={pagination.offset}
        count={countCreditItems}
        totalCount={countCreditItems}
        handlePaginationChange={handlePaginationChange}
        columns={[
          'Cliente',
          'Email',
          'Tipo',
          'Criado Em',
          'Atualizado Em',
          'Valor',
          '',
        ]}
        data={creditItems}
        isLoading={loadingCreditItems}
        renderItem={(item, index) => (
          <Item
            item={item}
            key={`item-${index}`}
            onUpdate={onUpdateCreditRequest}
            loading={loadingUpdateCreditItem}
          />
        )}
      />
    </Main>
  )
}

export default CustomersCreditRequests
