import React from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Table, Popup, Button } from 'semantic-ui-react'

const ButtonPop = ({ icon, onUpdate, loading, description }) => (
  <Popup
    inverted
    content={description}
    trigger={
      <Button
        icon={icon}
        onClick={onUpdate}
        loading={loading}
        disabled={loading}
        positive={icon === 'check'}
      />
    }
  />
)

ButtonPop.propTypes = {
  icon: t.string,
  loading: t.bool,
  onUpdate: t.func,
  description: t.string,
}

const Item = ({ item, loading, onUpdate, onItem, onClickUser }) => (
  <Table.Row
    warning={item.transfer_request_status_id === 1}
    positive={item.transfer_request_status_id === 2}
    negative={item.transfer_request_status_id === 3}
  >
    <Table.Cell style={{ cursor: 'pointer' }} onClick={onClickUser}>
      {item.name}
    </Table.Cell>
    <Table.Cell>{dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}</Table.Cell>
    <Table.Cell>{dayjs(item.updated_at).format('DD/MM/YYYY HH:mm')}</Table.Cell>
    <Table.Cell>R$ {item.amount}</Table.Cell>
    <Table.Cell>
      <ButtonPop
        loading={loading}
        description="Comprovante"
        icon="image"
        onUpdate={() => window.open(item.url_receipt, '_blank')}
      />
      {item.transfer_request_status_id === 1 && (
        <>
          <ButtonPop
            loading={loading}
            description="Confirmar Transferência"
            icon="check"
            onUpdate={() =>
              onUpdate({ transfer_request_status_id: 2, id: item.id })
            }
          />
          <ButtonPop
            icon="remove"
            loading={loading}
            description="Reprovar"
            onUpdate={() => onItem(item)}
          />
        </>
      )}
    </Table.Cell>
  </Table.Row>
)

Item.propTypes = {
  loading: t.bool,
  onUpdate: t.func,
  item: t.objectOf(t.any),
  onItem: t.func,
  onClickUser: t.func,
}

export default Item
