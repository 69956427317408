import React from 'react'

import t from 'prop-types'
import { Form, Modal, Button } from 'semantic-ui-react'

import { registerStatus } from '~/utils/types'

const ReproveList = ({ open, value, onClose, onChange, onClick, status }) => (
  <Modal size="small" onClose={onClose} open={open}>
    <Modal.Header>
      {status === registerStatus.IN_CORRECTION &&
        'Descreva os pontos em que o usuário deve corrigir na missão.'}
      {status === registerStatus.REPROVED &&
        'Descreva o motivo pelo qual você está reprovando a missão.'}
    </Modal.Header>
    <Modal.Content>
      <Form>
        <Form.Field>
          <Form.TextArea
            name="text"
            type="text"
            value={value}
            onChange={onChange}
            placeholder="Ex: Faltou enviar a foto de..."
          />
        </Form.Field>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button content="Cancelar" onClick={onClose} />
      <Button color="blue" onClick={() => onClick(status)} content="Enviar" />
    </Modal.Actions>
  </Modal>
)

ReproveList.propTypes = {
  open: t.bool,
  status: t.bool,
  value: t.string,
  onClose: t.func,
  onChange: t.func,
  onClick: t.func,
}

export default ReproveList
