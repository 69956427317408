import React from 'react'

import t from 'prop-types'
import { Form, Modal, Button, Dropdown } from 'semantic-ui-react'

const AccountUpdate = ({
  close,
  open,
  submitUpdate,
  handleUpdate,

  userAdmin,
  roles,
  isLoading,
}) => (
  <Modal size="small" open={open} onClose={() => open}>
    <Modal.Header>Atualizar Conta</Modal.Header>
    <Modal.Content>
      <Form>
        <Form.Input
          type="text"
          id="name-update"
          value={userAdmin?.name || ''}
          onChange={({ target }) => handleUpdate('name', target.value)}
          placeholder="Nome"
        />

        <Form.Input
          type="text"
          id="email-update"
          placeholder="Email"
          value={userAdmin?.email || ''}
          onChange={({ target }) => handleUpdate('email', target.value)}
          autoComplete="username"
        />

        <Dropdown
          fluid
          selection
          options={roles}
          placeholder="Role"
          value={userAdmin?.role_id || null}
          onChange={(e, { value }) => handleUpdate('role_id', value)}
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button content="Cancelar" onClick={close} />
      <Button
        positive
        icon="checkmark"
        content="Atualizar"
        labelPosition="right"
        loading={isLoading}
        disabled={isLoading}
        onClick={submitUpdate}
      />
    </Modal.Actions>
  </Modal>
)

AccountUpdate.propTypes = {
  close: t.func,
  open: t.bool,
  submitUpdate: t.func,
  handleUpdate: t.func,

  userAdmin: t.objectOf(t.any),
  roles: t.arrayOf(t.any),
  isLoading: t.bool,
}

export default AccountUpdate
