import React from 'react'

import t from 'prop-types'
import { Dropdown, Card, Grid, Divider, Button } from 'semantic-ui-react'

function MainMenu({
  loadingCustomers,
  listNameCustomers,
  setSelectedCustomer,
  selectedCustomer,
  loadingForms,
  forms,
  setSelectedForm,
  selectedForm,
  handleClickButton,
  loadingItems,
}) {
  return (
    <Card fluid>
      <Card.Content>
        <Grid columns={2}>
          <Grid.Column>
            Cliente:
            <Dropdown
              fluid
              selection
              search
              placeholder="Escolha um cliente..."
              loading={loadingCustomers}
              options={listNameCustomers}
              onChange={(e, { value }) => setSelectedCustomer(value)}
              value={selectedCustomer}
            />
          </Grid.Column>

          <Grid.Column>
            Formulário:
            <Dropdown
              fluid
              selection
              search
              placeholder="Escolha um formulário..."
              loading={loadingForms}
              options={forms}
              onChange={(e, { value }) => setSelectedForm(value)}
              value={selectedForm}
              disabled={loadingCustomers}
            />
            <Divider hidden />
            {selectedCustomer && (
              <Button
                disabled={loadingForms}
                loading={loadingItems}
                size="large"
                icon="add"
                content="Criar"
                labelPosition="left"
                color="green"
                onClick={() => handleClickButton('create')}
              />
            )}
            {selectedForm && (
              <>
                <Button
                  disabled={loadingForms}
                  loading={loadingItems}
                  size="large"
                  icon="refresh"
                  content="Atualizar"
                  labelPosition="left"
                  color="orange"
                  onClick={() => handleClickButton('update')}
                />

                <Button
                  disabled={loadingForms}
                  loading={loadingItems}
                  size="large"
                  icon="remove"
                  content="Remover"
                  labelPosition="left"
                  color="red"
                  onClick={() => handleClickButton('remove')}
                />

                <Button
                  disabled={loadingForms}
                  loading={loadingItems}
                  size="large"
                  icon="copy"
                  content="Duplicar"
                  labelPosition="left"
                  color="facebook"
                  onClick={() => handleClickButton('copy')}
                />
              </>
            )}
          </Grid.Column>
        </Grid>
      </Card.Content>
    </Card>
  )
}

MainMenu.propTypes = {
  loadingCustomers: t.bool,
  listNameCustomers: t.arrayOf(t.any),
  setSelectedCustomer: t.func,
  selectedCustomer: t.func,
  loadingForms: t.bool,
  forms: t.arrayOf(t.any),
  setSelectedForm: t.func,
  selectedForm: t.string,
  handleClickButton: t.func,
  loadingItems: t.bool,
}

export default MainMenu
