import React from 'react'

import t from 'prop-types'
import { Button, Card, Form } from 'semantic-ui-react'

function Filter({
  filters,
  onClickSubmit,
  onClickClear,
  onChangeFilter,
  listNameCustomers,
  badgesOptions,
}) {
  const optionsSelect = [
    {
      key: 1,
      value: 'PENDENT',
      text: 'Pendente',
    },
    {
      key: 2,
      value: 'APPROVED',
      text: 'Aprovado',
    },
    {
      key: 3,
      value: 'REPROVED',
      text: 'Reprovado',
    },
  ]

  const optionsItemsPerPage = [15, 25, 50, 100, 200]

  return (
    <Card fluid>
      <Card.Content>
        <Form widths="equal">
          <Form.Group>
            <Form.Input
              value={filters?.username}
              placeholder="Nome do Usuário"
              icon="search"
              onChange={(e, { value }) => onChangeFilter('username', value)}
            />
            <Form.Select
              options={optionsSelect}
              value={filters?.status}
              onChange={(e, { value }) => onChangeFilter('status', value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Select
              search
              placeholder="Cliente"
              value={filters?.customerId}
              options={listNameCustomers}
              onChange={(e, { value }) => onChangeFilter('customerId', value)}
            />
            <Form.Select
              search
              placeholder="Certificado"
              value={filters?.badgeId}
              options={badgesOptions}
              onChange={(e, { value }) => onChangeFilter('badgeId', value)}
            />
            <Form.Select
              placeholder="Items por Página"
              onChange={(e, { value }) => onChangeFilter('itemsPerPage', value)}
              value={filters?.itemsPerPage}
              options={optionsItemsPerPage.map((item, index) => ({
                key: index,
                value: item,
                text: item,
              }))}
            />
          </Form.Group>

          <Button primary content="Filtrar" onClick={onClickSubmit} />
          <Button content="Limpar Filtro" onClick={onClickClear} />
        </Form>
      </Card.Content>
    </Card>
  )
}

Filter.propTypes = {
  onClickSubmit: t.func,
  onClickClear: t.func,
  onChangeFilter: t.func,
  filters: t.objectOf(t.any),
  listNameCustomers: t.arrayOf(t.string),
  badgesOptions: t.arrayOf(t.string),
}

export default Filter
