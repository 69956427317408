import {
  UFS,
  CITIES,
  UFS_MIS,
  CAMPAIGN,
  CITIES_MIS,
  CAMPAIGN_CREATE,
} from '~/services/api/endpoints'
import { get, post } from '~/utils/actionsRequests'

const find = () =>
  get({
    url: CAMPAIGN,
    typeFetch: 'CAMPAIGN',
    typeError: 'CAMPAIGN_FAIL',
    typeResponse: 'CAMPAIGN_OK',
  })

const create = (data) =>
  post({
    data,
    url: CAMPAIGN_CREATE,
    typeFetch: 'CAMPAIGN_CREATE',
    typeError: 'CAMPAIGN_CREATE_FAIL',
    typeResponse: 'CAMPAIGN_CREATE_OK',
  })

const findUfs = () =>
  get({
    url: UFS,
    typeFetch: 'UFS',
    typeError: 'UFS_FAIL',
    typeResponse: 'UFS_OK',
  })

const findCities = (ufIdsSeparatedByPipe) =>
  get({
    url: CITIES(ufIdsSeparatedByPipe),
    typeFetch: 'CITIES',
    typeError: 'CITIES_FAIL',
    typeResponse: 'CITIES_OK',
  })

const findUfsMission = () =>
  get({
    url: UFS_MIS,
    typeFetch: 'UFS_MIS',
    typeError: 'UFS_MIS_FAIL',
    typeResponse: 'UFS_MIS_OK',
  })

const findCitiesMission = (query) =>
  get({
    url: `${CITIES_MIS}?${query}`,
    typeFetch: 'CITIES_MIS',
    typeError: 'CITIES_MIS_FAIL',
    typeResponse: 'CITIES_MIS_OK',
  })

export { find, create, findUfs, findCities, findUfsMission, findCitiesMission }
