import React, { useEffect } from 'react'
import DatePicker from 'react-datepicker'

import t from 'prop-types'
import { Input, Card, Form, Button, Select } from 'semantic-ui-react'

import useCategories from '~/hooks/useCategories'
import useCustomers from '~/hooks/useCustomers'
import useUfsAndCities from '~/hooks/useUfsAndCities'

import '~/assets/css/react-datepicker.css'

const Filter = ({
  limit,
  kind,
  final,
  initial,
  directions,
  customerId,
  categoryId,
  missionsMainId,

  subCategories,
  loadingMissions,

  onChange,
  onExportMissionsOpen,
  onSubmitOrderRequest,
}) => {
  const { getCustomers, loadingCustomers, customers } = useCustomers()
  const { getCategories, categories } = useCategories()

  const { ufsOptions, citiesOptions, onChangeUFs, onChangeCities } =
    useUfsAndCities()

  useEffect(() => {
    getCustomers({ offset: 0, limit: 1000 })
  }, []) //eslint-disable-line

  useEffect(() => {
    getCategories()
  }, []) //eslint-disable-line

  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Buscar..."
              onChange={(e, { value }) => onChange('search', value)}
            />
            <Form.Field
              control={Select}
              placeholder="Limite"
              selection
              value={limit}
              options={[
                { key: 0, value: 10, text: 10 },
                { key: 1, value: 15, text: 15 },
                { key: 2, value: 25, text: 25 },
                { key: 3, value: 50, text: 50 },
                { key: 4, value: 100, text: 100 },
              ]}
              onChange={(e, { value }) => onChange('limit', value)}
            />
            <Form.Field
              control={Select}
              search
              selection
              loading={loadingCustomers}
              placeholder="Clientes"
              options={customers.map(item => ({
                key: item.uid,
                value: item.uid,
                text: item.name,
              }))}
              value={customerId}
              onChange={(e, { value }) => onChange('customer_id', value)}
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Categoria"
              options={categories.map(i => ({
                key: i.uid,
                value: i.uid,
                text: i.name,
              }))}
              value={categoryId}
              onChange={(e, { value }) => onChange('category_id', value)}
            />
            {subCategories.length > 0 && (
              <Form.Field
                control={Select}
                search
                selection
                placeholder="Sub-Categoria"
                options={subCategories.map(sub => ({
                  key: sub.uid,
                  value: sub.uid,
                  text: sub.title,
                }))}
                value={missionsMainId}
                onChange={(e, { value }) => onChange('missions_main_id', value)}
              />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Field
              control={Select}
              search
              selection
              multiple
              options={ufsOptions}
              placeholder="UF"
              onChange={(e, { value }) => {
                onChange('uf', value.map(i => i).join(','))
                onChangeUFs(value)
              }}
            />
            {citiesOptions.length > 0 && (
              <Form.Field
                control={Select}
                search
                selection
                placeholder="Cidades"
                options={citiesOptions}
                onChange={(e, { value }) => {
                  onChange('city', `${value.cityName} - ${value.uf}`)
                  onChangeCities(value)
                }}
              />
            )}
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Ordenar Por"
              options={directions}
              value={kind}
              onChange={(e, { value }) => onChange('kind', value)}
            />
            <Form.Field
              selectsStart
              id="startDate"
              control={DatePicker}
              dateFormat="dd/MM/yyyy"
              placeholderText="Data Inicio"
              selected={initial}
              startDate={initial}
              endDate={final}
              onChange={value => onChange('initial', value)}
            />
            <Form.Field
              control={DatePicker}
              id="endDate"
              selectsEnd
              dateFormat="dd/MM/yyyy"
              placeholderText="Data Fim"
              selected={final}
              startDate={initial}
              endDate={final}
              onChange={value => onChange('final', value)}
            />
          </Form.Group>
        </Form>
      </Card.Content>
      <Card.Content>
        <Button
          icon="check"
          color="green"
          content="Filtrar"
          labelPosition="left"
          loading={loadingMissions}
          disabled={loadingMissions}
          onClick={() => onSubmitOrderRequest()}
        />
        <Button
          color="olive"
          labelPosition="left"
          loading={loadingMissions}
          disabled={loadingMissions}
          icon="file excel outline"
          content="Exportar (.XLSX)"
          onClick={onExportMissionsOpen}
        />
      </Card.Content>
    </Card>
  )
}

Filter.propTypes = {
  onChange: t.func,
  customerId: t.string,
  categoryId: t.string,
  missionsMainId: t.string,
  onExportMissionsOpen: t.func,
  onSubmitOrderRequest: t.func,

  loadingMissions: t.bool,
  subCategories: t.arrayOf(t.any),

  kind: t.string,
  limit: t.number,
  final: t.string,
  initial: t.string,
  directions: t.arrayOf(t.any),
}

export default Filter
