import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import queryString from 'query-string'
import { Divider } from 'semantic-ui-react'

import { ModalMissionsUsers } from '~/components/ModalMissionsUsers'
import TableComponent from '~/components/Table'
import { useAuth } from '~/contexts/auth'
import useUsers from '~/hooks/useUsers'
import useUsersConsultants from '~/hooks/useUsersConsultants'

import { Filter, ModalUsersConsultants, Item, ModalExport } from './components'

const columns = [
  'Nome',
  'Email',
  'Telefone',
  'Code',
  'OS',
  'Saldo',
  'Saldo Pontos',
  'Pagos',
  'Versão',
  'Acesso',
  'Criação',
  'Ações',
]

/* eslint-disable */
const columnsMapForApiSort = {
  Nome: 'name',
  Email: 'email',
  Telefone: 'phone',
  Code: 'code',
  OS: 'platform',
  Saldo: 'balance',
  'Saldo Pontos': 'total_points',
  Pagos: 'paid',
  Versão: 'app_version',
  Acesso: 'last_access',
  Criação: 'created_at',
}

const initialFilters = {
  name: null,
  email: null,
  phone: null,
  code: null,
  platform: null,
  balance: null,
  paid: null,
  app_version: null,
  badges: null,
  cities: null,
  ufs: null,
  last_access: null,
  created_at: null,
  direction: 'desc',
  order: 'created_at',
  limit: 50,
  offset: 0,
  sex: '',
  private_users: 'private',
  public_users: 'public',
  age: 'age',
}

export default function Users() {
  const { user } = useAuth()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [userIdSelected, setUserIdSelected] = useState(null)
  const { onDisableLeader, loadingUsersConsultants } = useUsersConsultants()
  const {
    updateUsers,
    loadingUpdate,
    getAllUsers,
    allUsers,
    loadingUser,
    countUsers,
  } = useUsers()

  const [filters, setFilters] = useState(initialFilters)
  const [leader, setLeader] = useState(null)
  const [modalLeader, setModalLeader] = useState(false)
  const [showModalExport, setShowModalExport] = useState(false)
  const [activePage, setActivePage] = useState(1)

  function onChangeItem(item, key, value) {
    const index = allUsers.findIndex(i => i.uid === item.uid)

    allUsers[index][key] = value
  }

  function onChangeFilters(value) {
    return setFilters(prev => ({ ...prev, ...value }))
  }

  function clearFilters() {
    return setFilters(initialFilters)
  }

  function onUpdateItem(item, key, value) {
    onChangeItem(item, key, value)

    return updateUsers({ uid: item.uid, [key]: value })
  }

  function onRemoveLeader(userId) {
    onChangeItem({ uid: userId }, 'is_leader', false)

    return onDisableLeader(userId)
  }

  function openUserConsultant(id) {
    setLeader(id)

    return setModalLeader(true)
  }

  function onPage(_, params) {
    const { limit } = filters
    const offset = params.activePage * limit - limit

    setFilters(prev => ({ ...prev, offset }))
    setActivePage(params.activePage)

    return getAllUsers({ ...filters, offset })
  }

  async function onHandleSort(order, direction) {
    const newDirection = direction === 'desc' ? 'asc' : 'desc'

    const sortQuery = {
      offset: 0,
      direction: newDirection,
      order: columnsMapForApiSort[order],
    }

    setFilters(prev => ({ ...prev, ...sortQuery }))

    const search = queryString.stringify({ ...filters, ...sortQuery })

    history.replace({ search })

    return getAllUsers({
      ...filters,
      order: sortQuery.order,
      direction: sortQuery.direction,
    })
  }

  function onSubmitFilter() {
    return getAllUsers(filters)
  }

  function onOpenMissions(userId) {
    setUserIdSelected(userId)

    return setOpen(true)
  }

  function onRefresh() {
    for (let propName in filters) filters[propName] ?? delete filters[propName]

    history.push({ search: queryString.stringify(filters) })

    return getAllUsers(filters)
  }

  useEffect(() => {
    const subscribe = onRefresh()

    return () => subscribe
  }, []) //eslint-disable-line

  return (
    <>
      {filters !== null ? (
        <Filter
          filters={filters}
          isLoading={loadingUser}
          roleName={user?.role_name}
          clearFilters={clearFilters}
          onSubmitFilter={onSubmitFilter}
          onChangeFilters={onChangeFilters}
          onClickExport={() => setShowModalExport(true)}
        />
      ) : null}

      <Divider inverted />

      <TableComponent
        data={allUsers}
        columns={columns}
        renderItem={(item, index) => (
          <Item
            item={item}
            index={index}
            key={item.uid}
            roleName={user?.role_name}
            onOpenMissions={onOpenMissions}
            openUserConsultant={openUserConsultant}
            onDisablePrivate={onUpdateItem}
            onDisableLeader={() => onRemoveLeader(item.uid)}
            loading={loadingUpdate || loadingUsersConsultants}
            onBanned={() => onUpdateItem(item, 'banned', !item.banned)}
            onChangeMembers={() =>
              onUpdateItem(item, 'unlimited_members', !item.unlimited_members)
            }
          />
        )}
        showEllipsis
        siblingRange={1}
        boundaryRange={0}
        count={onSubmitFilter}
        showFirstAndLastNav
        limit={filters?.limit}
        showPreviousAndNextNav
        isLoading={loadingUser}
        totalCount={countUsers}
        activePage={activePage}
        offset={filters?.offset}
        handleColumnSort={onHandleSort}
        handlePaginationChange={onPage}
      />
      <ModalUsersConsultants
        userId={leader}
        open={modalLeader}
        onChangeItem={onChangeItem}
        onClose={() => setModalLeader(false)}
      />
      {userIdSelected ? (
        <ModalMissionsUsers
          open={open}
          userId={userIdSelected}
          onClose={() => {
            setUserIdSelected(null)
            setOpen(false)
          }}
        />
      ) : null}
      {user?.role_name === 'Admin' && (
        <ModalExport
          filters={filters}
          initialFilters={initialFilters}
          open={showModalExport}
          close={() => setShowModalExport(false)}
        />
      )}
    </>
  )
}
