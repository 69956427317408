import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

export const Container = styled(Modal)`
  width: 100%;
  max-width: 625px;

  button {
    padding: 1.2rem 0.8rem !important;
    width: 100px;
    margin: 0 !important;
  }
`

export const Pagination = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    align-items: center;

    > button + button {
      margin-left: 16px !important;
    }
  }
`
