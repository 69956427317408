import React from 'react'
import ReactMapGl from 'react-map-gl'

import t from 'prop-types'

import MarkerIcon from '~/components/MapsItems/MarkerIcon'
import { MAPBOX_TOKEN } from '~/config/env'

const Map = ({ viewport, onChange, items }) => (
  <ReactMapGl
    {...viewport}
    width="100%"
    height={507}
    onViewportChange={onChange}
    mapboxApiAccessToken={MAPBOX_TOKEN}
    mapStyle="mapbox://styles/missionbrasil/cjkmphdta3c5r2rpezblnlfth"
  >
    {items.map((item, index) => (
      <MarkerIcon {...item} index={index} />
    ))}
  </ReactMapGl>
)

Map.propTypes = {
  onChange: t.func,
  items: t.arrayOf(t.any),
  viewport: t.objectOf(t.any),
}

export default Map
