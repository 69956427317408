import React from 'react'

import t from 'prop-types'
import { Modal, Button, Icon, List, Image } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'

const AnswerItem = ({ answer }) => {
  if (typeof answer === 'string') {
    return <p>{answer}</p>
  }

  return answer.map(item => {
    if ('option' in item) {
      return (
        <div>
          <Icon name="check" />
          {item.option}
        </div>
      )
    }

    if (['png'].includes(item.path)) {
      return (
        <Image
          size="medium"
          src={item.path}
          key={item.path}
          style={{ marginRight: 5 }}
        />
      )
    }

    return (
      <a href={item.path} target="_blank" rel="noopener noreferrer">
        Abrir
      </a>
    )
  })
}

export function ModalFormsUsersData({ open, close, items }) {
  console.log('ModalFormsUsersData')
  console.log('-------------------')
  console.log(items)
  console.log('-------------------')
  return (
    <Modal size="small" open={open} centered={false}>
      <Modal.Content>
        <h2>Informações Consultor</h2>
        <List>
          {items.length === 0 ? (
            <EmptyList text="Nenhum Dado foi adicinado..." icon="database" />
          ) : null}

          {items.length > 0 &&
            items.map((i, index) => (
              <List.Item key={`i-${String(index)}`}>
                <List.Header>{i.question}</List.Header>
                <AnswerItem answer={i.answer} />
              </List.Item>
            ))}
        </List>
      </Modal.Content>
      <Modal.Content>
        <Button color="grey" onClick={close} content="Cancelar" />
      </Modal.Content>
    </Modal>
  )
}

ModalFormsUsersData.propTypes = {
  open: t.bool,
  close: t.bool,
  items: t.arrayOf(t.any),
}
