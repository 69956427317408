import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import * as t from 'prop-types'
import { Button, Modal, Table } from 'semantic-ui-react'

import Loading from '~/components/Loading'
import TableComponent from '~/components/Table'
import useRegisters from '~/hooks/useRegisters'

function ExportRegistersList({ open, setOpen }) {
  const {
    getExportRegisters,
    exportRegisters,
    totalExportRegisters,
    loadingRegisters,
  } = useRegisters()

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 15,
    activePage: 1,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,
  })

  function formatDate(date) {
    return dayjs(date).format('DD/MM/YYYY HH:mm')
  }

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * pagination.limit
    setPagination(prevState => ({
      ...prevState,
      activePage,
      offset,
      limit: pagination.limit,
    }))
    return getExportRegisters({
      offset,
      limit: pagination.limit,
    })
  }

  useEffect(() => {
    getExportRegisters({
      offset: pagination.offset,
      limit: pagination.limit,
    })
  }, [open]) // eslint-disable-line

  return (
    <Modal open={open}>
      <Modal.Header>Exportação de Registros</Modal.Header>
      <Modal.Content>
        {loadingRegisters ? (
          <Loading />
        ) : (
          <TableComponent
            data={exportRegisters}
            columns={['STATUS', 'CLIENTE', 'URL', 'CRIADO EM', 'ATUALIZADO EM']}
            renderItem={item => (
              <Table.Row key={item.uid}>
                <Table.Cell>{item.status}</Table.Cell>
                <Table.Cell>{item.customer_name || 'Geral'}</Table.Cell>
                <Table.Cell>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {item.url}
                  </a>
                </Table.Cell>
                <Table.Cell>{formatDate(item.created_at)}</Table.Cell>
                <Table.Cell>{formatDate(item.updated_at)}</Table.Cell>
              </Table.Row>
            )}
            count={totalExportRegisters}
            totalCount={totalExportRegisters}
            limit={pagination.limit}
            offset={pagination.offset}
            activePage={pagination.activePage}
            showFirstAndLastNav={pagination.showFirstAndLastNav}
            showPreviousAndNextNav={pagination.showPreviousAndNextNav}
            handlePaginationChange={handlePaginationChange}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Fechar" onClick={() => setOpen(false)} />
      </Modal.Actions>
    </Modal>
  )
}

ExportRegistersList.propTypes = {
  open: t.bool,
  setOpen: t.func,
}

export { ExportRegistersList }
