import React, { useEffect, useState } from 'react'

import t from 'prop-types'
import { Button, Card, Form, Icon } from 'semantic-ui-react'

import useBadges from '~/hooks/useBadges'
import useCategories from '~/hooks/useCategories'
import useCustomers from '~/hooks/useCustomers'
import useSubCategories from '~/hooks/useSubCategories'
import useUfsAndCities from '~/hooks/useUfsAndCities'

import { PERIOD_OPTIONS } from '../helpers/options'

function FormFilter({
  filter,
  onChangeInputValue,
  submitFilters,
  resetFilters,
}) {
  const { customers, getAllCustomers } = useCustomers()
  const { getAllBadges, badgesOptions } = useBadges()
  const { getCategories, categoriesOptions } = useCategories()
  const { getSubCategories, subcategoriesOptions } = useSubCategories()
  const { ufsOptions, citiesOptions, onChangeUFs } = useUfsAndCities()
  const [clientOptions, setClientOptions] = useState([])
  const periodOptions = PERIOD_OPTIONS
  const isCustomPeriod = () => {
    return filter.period === 'custom'
  }

  const canEnableCitySelect = () => {
    return citiesOptions.length > 0
  }

  const canEnableSubcategorySelect = () => {
    return subcategoriesOptions.length > 0
  }

  const prepareCustomerOptions = () => {
    const options = customers.map(item => {
      return {
        key: item.uid,
        value: item.uid,
        text: item.name,
      }
    })

    setClientOptions(options)
  }

  const loadSubcategories = categoryId => {
    onChangeInputValue('categoryMissionsMainId', categoryId)
    getSubCategories({ categoryId })
  }

  useEffect(() => {
    getAllCustomers()
    getAllBadges()
    getCategories()
  }, []) //eslint-disable-line

  useEffect(() => {
    prepareCustomerOptions()
  }, [customers]) //eslint-disable-line

  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Select
              fluid
              search
              label="Cliente"
              value={filter.customerId}
              onChange={(event, data) =>
                onChangeInputValue('customerId', data.value)
              }
              options={clientOptions}
              placeholder="Selecionar um cliente"
            />
            <Form.Select
              fluid
              search
              label="Certificados"
              value={filter.badgeId}
              onChange={(event, data) =>
                onChangeInputValue('badgeId', data.value)
              }
              options={badgesOptions}
              placeholder="Selecionar um certificados"
            />
            <Form.Select
              fluid
              search
              label="Estado"
              options={ufsOptions}
              value={filter.uf}
              onChange={(event, data) => {
                onChangeInputValue('uf', data.value)
                onChangeUFs([data.value])
              }}
              placeholder="Selecionar um estado"
            />
            <Form.Select
              fluid
              search
              label="Cidade"
              disabled={!canEnableCitySelect()}
              value={filter.city}
              onChange={(event, data) => onChangeInputValue('city', data.value)}
              options={citiesOptions}
              placeholder="Selecionar uma cidade"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Select
              fluid
              search
              label="Categoria"
              value={filter.categoryMissionsMainId}
              onChange={(event, data) => {
                loadSubcategories(data.value)
              }}
              options={categoriesOptions}
              placeholder="Selecionar uma categoria"
            />
            <Form.Select
              fluid
              search
              disabled={!canEnableSubcategorySelect()}
              label="Subcategoria"
              value={filter.missionsMainId}
              onChange={(event, data) => {
                onChangeInputValue('missionsMainId', data.value)
              }}
              options={subcategoriesOptions}
              placeholder="Selecionar uma subcategoria"
            />
            <Form.Select
              fluid
              label="Período"
              options={periodOptions}
              value={filter.period}
              onChange={(event, data) => {
                onChangeInputValue('period', data.value)
              }}
              placeholder="Selecionar um período"
            />

            {isCustomPeriod() && (
              <>
                <Form.Input
                  value={filter.initDate}
                  onChange={event => {
                    onChangeInputValue('initDate', event.target.value)
                  }}
                  type="date"
                  label="Data inicial"
                />
                <Form.Input
                  value={filter.finalDate}
                  onChange={event => {
                    onChangeInputValue('finalDate', event.target.value)
                  }}
                  type="date"
                  label="Data final"
                />
              </>
            )}
          </Form.Group>
          <br />
          <Form.Group>
            <Button onClick={() => submitFilters()} color="green">
              <Icon name="check" />
              Filtros
            </Button>
            <Button onClick={() => resetFilters()}>
              <Icon name="undo" />
              Limpar filtros
            </Button>
          </Form.Group>
        </Form>
      </Card.Content>
    </Card>
  )
}

FormFilter.propTypes = {
  filter: t.objectOf(t.any),
  onChangeInputValue: t.func,
  submitFilters: t.func,
  resetFilters: t.func,
}

export default FormFilter
