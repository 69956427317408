const initialState = {
  data: [],
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const ATTACHMENTS = 'ATTACHMENTS'
const ATTACHMENTS_OK = 'ATTACHMENTS_OK'
const ATTACHMENTS_FAIL = 'ATTACHMENTS_FAIL'

export default function attachmentsReducers(state = initialState, action) {
  const { data, message, type } = action

  switch (type) {
    case ATTACHMENTS:
      return { isFetching: true }

    case ATTACHMENTS_OK:
      return { isFetching: false, data, success: true }

    case ATTACHMENTS_FAIL:
      return { isFetching: false, error: true, message, success: false }

    default:
      return state
  }
}
