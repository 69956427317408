import React from 'react'

import t from 'prop-types'
import { Card, Grid, Image } from 'semantic-ui-react'

import ImagePdf from '~/assets/pdf.png'

import { StyleBoxPDF, CardStyle, ImageStyle } from '../../styles'

function Files({ files }) {
  return (
    <Grid relaxed>
      {files.map(file => {
        const name = file.key.split('_')
        return (
          <Card style={CardStyle} link>
            <Grid.Column>
              <Card.Content style={StyleBoxPDF}>
                <Image
                  style={ImageStyle}
                  fluid
                  src={ImagePdf}
                  href={file.file_path}
                  target="_blank"
                />
                <Card.Meta>{name[1]}</Card.Meta>
              </Card.Content>
            </Grid.Column>
          </Card>
        )
      })}
    </Grid>
  )
}

Files.propTypes = {
  files: t.objectOf(t.any),
}

export default Files
