import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Confirm } from 'semantic-ui-react'
import * as Yup from 'yup'

import Breadcrumbs from '~/components/Breadcrumbs'
import TableComponent from '~/components/Table'
import useMissionsMainValues from '~/hooks/useMissionsMainValues'
import useSubCategories from '~/hooks/useSubCategories'
import Main from '~/pages/Main'
import history from '~/services/history'
import message from '~/utils/messages'

import CreateSubCategorieValue from './components/Create'
import { Item } from './components/Item'
import SubCategoriesValuesModal from './components/Modal'

const schema = Yup.object().shape({
  user_value: Yup.number()
    .positive()
    .nullable()
    .required('Preencha o valor do usuário.'),
  customer_value: Yup.number()
    .positive()
    .moreThan(
      Yup.ref('user_value'),
      'Valor do usuário não pode ser maior que o cliente.'
    )
    .nullable()
    .required('Preencha o valor do cliente.'),
  description: Yup.string().nullable().required('Preencha a descrição.'),
})

function SubCategoriesValues() {
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)

  const { categoryId, subcategoryId } = useParams()

  const { getSubCategoryById, subcategory } = useSubCategories()

  const {
    loading,
    onCreateMissionsValues,
    onDeleteMissionsValues,
    onUpdateMissionsValues,
    getMissionsMainValues,
    missionsMainValues,
  } = useMissionsMainValues()

  const [removeId, setRemoveId] = useState(null)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [item, setItem] = useState({
    user_value: null,
    description: null,
    customer_value: null,
    missions_main_id: subcategoryId,
  })

  function onChange(key, value) {
    return setItem(prevState => ({ ...prevState, [key]: value }))
  }

  function removeItem() {
    setOpenConfirm(false)
    return onDeleteMissionsValues(removeId)
  }

  function openRemoveItem(id) {
    setOpenConfirm(true)
    return setRemoveId(id)
  }

  function openDetail(missionMainValue) {
    setOpen(true)
    return setItem(prevState => ({ ...prevState, ...missionMainValue }))
  }

  async function onSubmit({ like }) {
    try {
      const isValid = schema.isValidSync(item)

      if (!isValid) {
        const validate = schema.validateSync(item)

        throw new Error(validate)
      }

      setOpen(false)

      return like === 'create'
        ? onCreateMissionsValues({ ...item, missions_main_id: subcategoryId })
        : onUpdateMissionsValues({ ...item, missions_main_id: subcategoryId })
    } catch (error) {
      return message().error(error.message)
    }
  }

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      link: '/categories',
      name: 'Categorias',
    },
    {
      active: false,
      hasLink: true,
      name: 'Sub-Categorias',
      link: `/categories/${categoryId}/sub-categories`,
    },
    {
      active: true,
      hasLink: false,
      name: subcategory?.has_value_variable
        ? 'Valor Variável'
        : 'Valor Percentual',
    },
  ]

  useEffect(() => {
    getMissionsMainValues(subcategoryId)
  }, []) //eslint-disable-line

  useEffect(() => {
    getSubCategoryById(subcategoryId)
  }, []) //eslint-disable-line

  return (
    <Main>
      <Breadcrumbs items={breadcrumbsList} history={history} />

      <Confirm
        header="Alerta"
        open={openConfirm}
        onConfirm={removeItem}
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onCancel={() => setOpenConfirm(i => !i)}
        content="Tem certeza deseja remover este item?"
      />

      <Button
        size="small"
        style={{ marginTop: 10 }}
        color={show ? 'red' : 'blue'}
        onClick={() => {
          setShow(i => !i)
          return setItem(prevState => ({
            ...prevState,
            user_value: null,
            description: null,
            customer_value: null,
          }))
        }}
        content={show ? 'Fechar' : 'Criar'}
      />

      <CreateSubCategorieValue
        item={item}
        show={show}
        onChange={onChange}
        valueType={subcategory?.has_value_variable}
        create={() => onSubmit({ like: 'create' })}
      />

      <TableComponent
        columns={[
          'ID',
          'Descrição',
          'Valor Usuário',
          'Valor Usuário Prime',
          'Valor Usuário Black',
          'Valor Cliente',
          'Criado',
          'Atualizado',
          'Ações',
        ]}
        isLoading={loading}
        data={missionsMainValues}
        renderItem={(missionMainValue, index) => (
          <Item
            item={missionMainValue}
            index={index}
            key={`i-${index}`}
            openDetail={openDetail}
            openRemoveId={openRemoveItem}
          />
        )}
        emptyText={{
          icon: 'check circle outline',
          text: 'Nenhum valor variável...',
        }}
      />

      <SubCategoriesValuesModal
        item={item}
        openModal={open}
        onChange={onChange}
        valueType={subcategory?.has_value_variable}
        close={() => setOpen(false)}
        update={() => onSubmit({ like: 'update' })}
      />
    </Main>
  )
}
export default SubCategoriesValues
