import React from 'react'

import t from 'prop-types'
import { Button, Grid, Icon, Modal } from 'semantic-ui-react'

import Loading from '~/components/Loading'

function Item({ label, hasValue }) {
  const color = has => (has ? 'green' : 'red')
  const icon = has => (has ? 'check circle' : 'times circle')

  return (
    <>
      <Icon name={icon(hasValue)} color={color(hasValue)} />
      {label}
    </>
  )
}

export function Results({
  open,
  onClose,
  loading,
  results,
  missionsMain,
  onSubmitFinishedMission,
}) {
  const isValid =
    (missionsMain?.has_photo || missionsMain?.has_import_image) ===
      results?.hasPhotos &&
    missionsMain?.has_shared_link === results?.hasSharedLink &&
    missionsMain?.has_pos === results?.hasPointOfSale &&
    missionsMain?.has_import_document === results?.hasFiles &&
    missionsMain?.has_forms === results?.hasFormsData &&
    missionsMain?.has_products === results?.hasInvoice &&
    missionsMain?.has_attachment === results?.hasFeed &&
    missionsMain?.has_signature === results?.hasSignature &&
    missionsMain?.has_rating === results?.hasRating &&
    missionsMain?.has_signature === results?.hasSignature &&
    missionsMain?.check_in_check_out ===
      (results?.hasCheckIn && results?.hasCheckOut)

  return (
    <Modal open={open} onClose={onClose} size="mini">
      <Modal.Header>Validação de Atividades</Modal.Header>
      <Modal.Content>
        {loading ? (
          <Loading customText="Carregando..." />
        ) : (
          <Grid columns={1}>
            <Grid.Column>
              <Grid.Row>
                {missionsMain?.check_in_check_out && (
                  <Item
                    label="Registrou Check-In"
                    hasValue={results?.hasCheckIn}
                  />
                )}
              </Grid.Row>
              <Grid.Row>
                {missionsMain?.check_in_check_out && (
                  <Item
                    label="Registrou Check-Out"
                    hasValue={results?.hasCheckOut}
                  />
                )}
              </Grid.Row>
              <Grid.Row>
                {(missionsMain?.has_photo ||
                  missionsMain?.has_import_image) && (
                  <Item
                    label="Enviou pelo menos uma foto"
                    hasValue={results?.hasPhotos}
                  />
                )}
              </Grid.Row>
              <Grid.Row>
                {missionsMain?.has_signature && (
                  <Item
                    label="Enviou pelo menos uma assinatura"
                    hasValue={results?.hasSignature}
                  />
                )}
              </Grid.Row>
              <Grid.Row>
                {missionsMain?.has_import_document && (
                  <Item
                    label="Importou pelo menos um PDF"
                    hasValue={results?.hasFiles}
                  />
                )}
              </Grid.Row>
              <Grid.Row>
                {missionsMain?.has_pos && (
                  <Item
                    label="Atualizou pelo menos um produto"
                    hasValue={results?.hasPointOfSale}
                  />
                )}
              </Grid.Row>
              <Grid.Row>
                {missionsMain?.has_products && (
                  <Item
                    label="Tem pelo menos uma venda aprovada"
                    hasValue={results?.hasInvoice}
                  />
                )}
              </Grid.Row>
              <Grid.Row>
                {missionsMain?.has_forms && (
                  <Item
                    label="Encaminhou os dados coletados no formulário"
                    hasValue={results?.hasFormsData}
                  />
                )}
              </Grid.Row>
              <Grid.Row>
                {missionsMain?.has_attachment && (
                  <Item
                    label="Abriu o documento em anexo"
                    hasValue={results?.hasFeed}
                  />
                )}
              </Grid.Row>
              <Grid.Row>
                {missionsMain?.has_shared_link && (
                  <Item
                    label="Converteu Compartilhamento"
                    hasValue={results?.hasSharedLink}
                  />
                )}
              </Grid.Row>
            </Grid.Column>
          </Grid>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancelar" onClick={onClose} disabled={loading} />{' '}
        {isValid && (
          <Button
            content="Finalizar Missão"
            primary
            disabled={loading}
            onClick={() => onSubmitFinishedMission(missionsMain)}
          />
        )}
      </Modal.Actions>
    </Modal>
  )
}

Results.propTypes = {
  open: t.bool,
  onClose: t.func,
  loading: t.bool,
  results: t.objectOf(t.bool),
  missionsMain: t.objectOf(t.any),
  onSubmitFinishedMission: t.func,
}

Item.propTypes = {
  label: t.string,
  hasValue: t.bool,
}
