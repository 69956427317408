import React from 'react'

import t from 'prop-types'
import { Image, Button, Card, Input } from 'semantic-ui-react'

import Avatar from '~/assets/avatar.png'

export function Item({
  item,
  loading,
  handleChange,
  percentualValue,
  loadingFormsUsers,
  onSubmitUserConsultant,
  getConsultantFormsUsers,
  onDisableUsersConsultants,
}) {
  const avatar = item.avatar === null ? Avatar : item.avatar

  return (
    <Card key={item.uid}>
      <Card.Content>
        <Image floated="right" size="mini" src={avatar} />
        <Card.Header>{item.user_name}</Card.Header>
        <Card.Meta style={{ color: item.status ? 'green' : 'red' }}>
          {`Consultor ${item.status ? 'Ativo' : 'Inativo'}`}
        </Card.Meta>
        <Card.Description>
          <Input
            type="number"
            icon="percent"
            iconPosition="left"
            placeholder={
              item.percentual ? `Comissão (${item.percentual})` : 'Comissão'
            }
            value={percentualValue[item.uid]}
            onChange={(e, { value }) =>
              item.uid && handleChange(value, item.uid)
            }
          />
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button.Group fluid>
          <Button
            basic
            compact
            color="green"
            loading={loading}
            disabled={loading}
            onClick={onSubmitUserConsultant}
            content={item.status ? 'Atualizar' : 'Adicionar'}
          />
          <Button
            basic
            compact
            color="blue"
            content="Informações Coletadas"
            loading={loadingFormsUsers}
            onClick={getConsultantFormsUsers}
          />
          {item.status ? (
            <Button
              basic
              compact
              color="red"
              content="Remover"
              loading={loading}
              disabled={loading}
              onClick={onDisableUsersConsultants}
            />
          ) : null}
        </Button.Group>
      </Card.Content>
    </Card>
  )
}

Item.propTypes = {
  loading: t.bool,
  handleChange: t.func,
  item: t.objectOf(t.any),
  loadingFormsUsers: t.bool,
  percentualValue: t.string,
  onCreateUserConsultant: t.func,
  getConsultantFormsUsers: t.func,
  onDisableUsersConsultants: t.func,
}
