import React from 'react'

import t from 'prop-types'
import { Card, Grid, Statistic } from 'semantic-ui-react'

function TotalCards({ label, value, isMoney }) {
  function formatMoney(valueMoney) {
    return valueMoney?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  return (
    <Grid.Column>
      <Card fluid>
        <Card.Content textAlign="center">
          <Statistic>
            <Statistic.Value text>
              {isMoney ? `${formatMoney(value)}` : value}
            </Statistic.Value>
            <Statistic.Label>{label}</Statistic.Label>
          </Statistic>
        </Card.Content>
      </Card>
    </Grid.Column>
  )
}

TotalCards.propTypes = {
  label: t.string,
  value: t.number,
  isMoney: t.bool,
}

export default TotalCards
