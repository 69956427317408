import React from 'react'

import t from 'prop-types'
import { Input, Card, Form, Button, Select } from 'semantic-ui-react'

const Filter = ({
  filters,
  groups,
  showGroup,
  showCategory,
  onOpenCategory,
  onOpenGroups,
  isLoading,
  onChangeFilters,
  clearFilters,
}) => (
  <Card fluid>
    <Card.Content>
      <Form>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            icon="search"
            className="icon"
            value={filters?.subCategoryName}
            placeholder="Buscar por nome"
            onChange={(e, { value }) => onChangeFilters('title', value)}
          />
          <Form.Field
            control={Select}
            placeholder="Grupo"
            selection
            value={filters?.missionsMainGroupId}
            options={groups?.map(group => ({
              key: group.id,
              value: group.uid,
              text: group.description,
            }))}
            onChange={(e, { value }) =>
              onChangeFilters('missionsMainGroupId', value)
            }
          />
        </Form.Group>
      </Form>
    </Card.Content>
    <Card.Content>
      <Button positive={!showCategory} onClick={onOpenCategory}>
        Criar Sub-Categoria
      </Button>
      <Button primary={!showGroup} onClick={onOpenGroups}>
        Novo Grupo
      </Button>
      <Button
        icon="undo"
        loading={isLoading}
        labelPosition="left"
        onClick={clearFilters}
        content="Limpar Filtros"
      />
    </Card.Content>
  </Card>
)

Filter.propTypes = {
  isLoading: t.bool,
  showGroup: t.bool,
  showCategory: t.bool,
  clearFilters: t.func,
  onOpenGroups: t.func,
  onOpenCategory: t.func,
  onChangeFilters: t.func,
  groups: t.arrayOf(t.any),
  filters: t.objectOf(t.any),
}

export default Filter
