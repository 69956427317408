import { useState } from 'react'

import * as FileSaver from 'file-saver'
import * as Yup from 'yup'

import api from '~/services/api'
import Message from '~/utils/messages'

const shape = Yup.object().shape({
  conditions: Yup.array().notRequired(),
  final: Yup.number('Selecione data inicial.')
    .typeError('Selecione data inicial')
    .nullable()
    .required(),
  initial: Yup.number('Selecione data final.')
    .typeError('Selecione data inicial')
    .nullable()
    .required(),
  types: Yup.array()
    .min(1, 'Você precisa escolher pelo menos 1 tipo!')
    .required(),
  groups: Yup.object().shape({
    range: Yup.string().notRequired('Selecione um período.'),
    types: Yup.array()
      .min(1, 'Você precisa escolher pelo menos 1 grupo!')
      .required(),
  }),
  analytical: Yup.boolean().notRequired(),
})

export default function useMetrics() {
  const [loadingMetrics, setLoadingMetrics] = useState(false)
  const [metrics, setMetrics] = useState([])
  const [sumTotals, setSumTotals] = useState(null)

  async function onSubmitMetrics({ data, query }) {
    setLoadingMetrics(true)
    try {
      const isValid = shape.isValidSync(data)

      if (!isValid) {
        const validate = shape.validateSync(data)
        throw new Error(validate)
      }

      const res = await api.post('reports/missions-metrics', data, {
        params: query,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const sumTotalValue = res.data
      const result = {
        total: 0,
        total_customer_value: 0,
        total_user_value: 0,
        total_users_point_value: 0,
        total_customer_value_a: 0,
        total_user_value_a: 0,
      }
      const hasPoint = value => value?.total_users_point_value

      sumTotalValue.reduce((a, value) => {
        result.total += Number(value.total) || 0
        result.total_customer_value += hasPoint(value)
          ? 0
          : Number(value.total_customer_value) || 0
        result.total_customer_value_a += hasPoint(value)
          ? 0
          : Number(value.customer_value) || 0
        result.total_user_value_a += hasPoint(value)
          ? 0
          : Number(value.user_value) || 0
        result.total_user_value += hasPoint(value)
          ? 0
          : Number(value.total_user_value) || 0
        result.total_users_point_value +=
          Number(value.total_users_point_value) || 0

        return a
      }, result)

      console.log('RESULT')
      console.log('====================')
      console.log(result)
      console.log('====================')

      setSumTotals(result)
      return setMetrics(sumTotalValue)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingMetrics(false)
    }
  }

  async function onSubmitMetricsExport({ data, query }) {
    try {
      const isValid = shape.isValidSync(data)

      if (!isValid) {
        const validate = shape.validateSync(data)
        throw new Error(validate)
      }

      setLoadingMetrics(true)
      const res = await api.post('reports/missions-metrics', data, {
        params: query,
        responseType: 'arraybuffer',
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      })

      setLoadingMetrics(false)
      return FileSaver.saveAs(blob, `metrics_${Date.now()}`)
    } catch (error) {
      setLoadingMetrics(false)
      return Message().error(error.message)
    }
  }

  return {
    metrics,
    loadingMetrics,
    onSubmitMetrics,
    onSubmitMetricsExport,
    sumTotals,
  }
}
