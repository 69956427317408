import { Link } from 'react-router-dom'

import { Card } from 'semantic-ui-react'
import styled from 'styled-components'

export const CardContainer = styled(Card)`
  min-width: 100%;
`

export const MainContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
`

export const DaysContainer = styled.div`
  border: 1px solid #efefef;
  border-radius: 8px;
  margin-right: 6px;
  padding-bottom: 30px;
  height: 850px;
  max-height: 850px;
  min-width: 320px;
  max-width: 320px;
  overflow-y: hidden;
  overflow-x: hidden;
`

export const StatusLabel = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => (props.isChecked ? '#3ad089' : '#fe5565')};
`

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const UserLinkProfile = styled(Link)`
  color: black;
`

export const ChecksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
`

export const HeaderContainer = styled.div`
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 90%;
  margin: auto;
  padding: 12px 0px;
  border-bottom: 1px solid #f1f2f3;
  margin-bottom: 20px;
  text-align: center;
`

export const FlexContainer = styled.div`
  display: flex;
  margin: 5px 0px;

  .date {
    flex: 1;
    justify-content: center;
  }
`

export const SelectsContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`
export const AddRatingContainer = styled.div`
  width: 100%;
  margin-left: 5px;
`
