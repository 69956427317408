import React from 'react'
import NumberFormat from 'react-number-format'

import t from 'prop-types'
import { Modal, Button, Form } from 'semantic-ui-react'

const SubcategorieValuesModal = ({
  item,
  close,
  update,
  openModal,
  onChange,
  valueType,
}) => (
  <Modal size="small" open={openModal} onClose={close}>
    <Modal.Header content="Atualizar Missão" />
    <Modal.Content>
      <Form>
        <Form.Input
          type="text"
          value={item.description || ''}
          label="Descrição (Obrigatório)"
          placeholder="Ex: Cópia de Processo"
          onChange={(e, { value }) => onChange('description', value)}
        />

        <NumberFormat
          prefix={valueType ? 'R$' : '%'}
          customInput={Form.Input}
          decimalSeparator="."
          label="Valor Cliente"
          fixedDecimalScale={2}
          thousandSeparator
          value={item.customer_value || ''}
          placeholder="5% (Deve ser maior que o usuário)"
          onValueChange={({ value }) => onChange('customer_value', value)}
        />

        <NumberFormat
          prefix={valueType ? 'R$' : '%'}
          decimalSeparator="."
          label="Valor Usuário"
          fixedDecimalScale={2}
          thousandSeparator
          customInput={Form.Input}
          placeholder="3% (Deve ser menor que o cliente)"
          value={item.user_value || ''}
          onValueChange={({ value }) => onChange('user_value', value)}
        />
        <NumberFormat
          prefix={valueType ? 'R$' : '%'}
          decimalSeparator="."
          label="Valor Usuário Prime"
          fixedDecimalScale={2}
          thousandSeparator
          customInput={Form.Input}
          placeholder="3% (Deve ser menor que o cliente)"
          value={item.user_value_prime || ''}
          onValueChange={({ value }) => onChange('user_value_prime', value)}
        />
        <NumberFormat
          prefix={valueType ? 'R$' : '%'}
          decimalSeparator="."
          label="Valor Usuário Black"
          fixedDecimalScale={2}
          thousandSeparator
          customInput={Form.Input}
          placeholder="3% (Deve ser menor que o cliente)"
          value={item.user_value_black || ''}
          onValueChange={({ value }) => onChange('user_value_black', value)}
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={close}>
        Cancelar
      </Button>
      <Button
        positive
        icon="checkmark"
        content="Atualizar"
        labelPosition="right"
        onClick={update}
      />
    </Modal.Actions>
  </Modal>
)

SubcategorieValuesModal.propTypes = {
  close: t.func,
  update: t.func,
  openModal: t.bool,
  onChange: t.func,
  valueType: t.bool,
  item: t.objectOf(t.any),
}

export default SubcategorieValuesModal
