import React from 'react'

import t from 'prop-types'
import { Button, Popup, Form, Grid, Loader } from 'semantic-ui-react'

import { OPTIONS_TYPE_EXPORTATION, TYPE_EXPORTATION } from '../../helpers'

const FormExportImage = ({
  errorsValidation,
  formOptions,
  isLoadedForms,
  changeInputValue,
  enableLoader,
  exportImages,
  filters,
  getForms,
  customerOptions,
}) => {
  const hasSelectedTypePhotos = () => {
    return filters.type === TYPE_EXPORTATION.PHOTOS
  }

  return (
    <Grid columns="6">
      <Grid.Column />
      <Grid.Column />
      <Grid.Column />
      <Grid.Column />
      <Grid.Column />
      <Grid.Column>
        <Popup
          content={
            <Form style={{ width: '250px' }}>
              <Loader active={enableLoader} />
              <Form.Input
                value={filters.initial}
                error={errorsValidation.initial}
                type="date"
                onChange={event =>
                  changeInputValue('initial', event.target.value)
                }
                label="Data inicial"
              />

              <Form.Input
                value={filters.final}
                error={errorsValidation.final}
                type="date"
                onChange={event =>
                  changeInputValue('final', event.target.value)
                }
                label="Data final"
              />

              <Form.Select
                search
                value={filters.customerId}
                error={errorsValidation.customerId}
                options={customerOptions}
                onChange={(event, data) => {
                  changeInputValue('customerId', data.value)
                  getForms(data.value)
                }}
                label="Clientes"
              />

              <Form.Select
                search
                value={filters.type}
                error={errorsValidation.type}
                options={OPTIONS_TYPE_EXPORTATION}
                onChange={(event, data) => {
                  changeInputValue('type', data.value)
                }}
                label="Tipo"
              />

              {!hasSelectedTypePhotos() && (
                <Form.Select
                  search
                  value={filters.formId}
                  error={errorsValidation.formId}
                  options={formOptions}
                  disabled={!isLoadedForms()}
                  onChange={(event, data) =>
                    changeInputValue('formId', data.value)
                  }
                  label="Formulários"
                />
              )}

              <Button
                color="primary"
                disabled={enableLoader}
                onClick={() => exportImages()}
              >
                Exportar
              </Button>
            </Form>
          }
          on="click"
          pinned
          position="bottom right"
          trigger={
            <Button
              style={{ marginLeft: '60px' }}
              content="Exportar"
              icon="inbox"
            />
          }
        />
      </Grid.Column>
    </Grid>
  )
}

FormExportImage.propTypes = {
  errorsValidation: t.objectOf(t.any),
  formOptions: t.arrayOf(t.object),
  isLoadedForms: t.bool,
  changeInputValue: t.func,
  enableLoader: t.bool,
  exportImages: t.func,
  filters: t.objectOf(t.any),
  getForms: t.func,
  customerOptions: t.arrayOf(t.object),
}

export default FormExportImage
