import React from 'react'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import t from 'prop-types'
import { Table, Popup, Button } from 'semantic-ui-react'

const Item = ({ item, handleUpdate, handleRemove }) => {
  const history = useHistory()

  return (
    <Table.Row key={`index-${item.uid}`}>
      <Table.Cell>{item.title}</Table.Cell>
      <Table.Cell>{item.description}</Table.Cell>
      <Table.Cell>
        {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        {item.updated_at &&
          format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Popup
          inverted
          content="Itens"
          trigger={
            <Button
              icon="search"
              onClick={() => {
                history.push(`forms-users-extra/${item.uid}`)
              }}
            />
          }
        />
        <Popup
          content={item.status ? 'Inativar' : 'Ativar'}
          inverted
          trigger={
            <Button
              icon={item.status ? 'remove' : 'check'}
              color={item.status ? 'red' : 'green'}
              onClick={() => handleUpdate(item)}
            />
          }
        />

        <Popup
          content="Remover"
          inverted
          trigger={
            <Button
              icon="trash"
              color="violet"
              onClick={() => handleRemove(item)}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  item: t.objectOf(t.any),
  handleUpdate: t.func,
  handleRemove: t.func,
}

export { Item }
