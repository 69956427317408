import React from 'react'
import DatePicker from 'react-datepicker'

import t from 'prop-types'
import { Button, Card, Form } from 'semantic-ui-react'

function Filter({ filters, onClickSubmit, onClickClear, onChangeFilter }) {
  const optionsSelect = [
    {
      key: 1,
      value: 1,
      text: 'Pendente',
    },
    {
      key: 2,
      value: 2,
      text: 'Aprovado',
    },
  ]

  return (
    <Card fluid>
      <Card.Content>
        <Form widths="equal">
          <Form.Group>
            <Form.Input
              value={filters.search}
              placeholder="Nome (Usuário ou Instituição)"
              icon="search"
              onChange={(e, { value }) => onChangeFilter('search', value)}
            />
            <Form.Select
              options={optionsSelect}
              value={filters.charitiesRequestsDonateStatusId}
              onChange={(e, { value }) =>
                onChangeFilter('charitiesRequestsDonateStatusId', value)
              }
            />

            <Form.Field>
              <div className="ui input">
                <DatePicker
                  id="date"
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Selecionar Data"
                  selected={filters.date}
                  onChange={value => onChangeFilter('date', value)}
                />
              </div>
            </Form.Field>
          </Form.Group>

          <Button primary content="Filtrar" onClick={onClickSubmit} />
          <Button content="Limpar Filtro" onClick={onClickClear} />
        </Form>
      </Card.Content>
    </Card>
  )
}

Filter.propTypes = {
  onClickSubmit: t.func,
  onClickClear: t.func,
  onChangeFilter: t.func,
  filters: t.objectOf(t.any),
}

export default Filter
