import React from 'react'

import t from 'prop-types'
import { Table } from 'semantic-ui-react'

import { formatPrice } from '~/utils/format'

const hasProperty = (object, key) =>
  Object.prototype.hasOwnProperty.call(object, key)

export function Item({ item }) {
  const customerValue =
    Number(item.total_customer_value) || Number(item.customer_value)
  const userValue = Number(item.total_user_value) || Number(item.user_value)
  const currentCustomerValue = item?.total_users_point_value
    ? '####'
    : formatPrice(customerValue)
  const currentUserValue = item?.total_users_point_value
    ? '####'
    : formatPrice(userValue)

  return (
    <Table.Row>
      <Table.Cell>{item?.cliente}</Table.Cell>
      <Table.Cell>
        {hasProperty(item, 'categoria') ? item.categoria : item.name}
      </Table.Cell>
      <Table.Cell>{item?.sub_categoria}</Table.Cell>
      <Table.Cell>{item.tipo}</Table.Cell>
      <Table.Cell>{item.periodo}</Table.Cell>
      <Table.Cell>{item.total}</Table.Cell>
      <Table.Cell>{item?.mu_key}</Table.Cell>
      <Table.Cell>
        {item?.invoice_value && formatPrice(item?.invoice_value)}
      </Table.Cell>
      <Table.Cell>{currentCustomerValue}</Table.Cell>
      <Table.Cell>{currentUserValue}</Table.Cell>
      <Table.Cell>{Number(item.total_users_point_value) || ''}</Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  item: t.objectOf(t.any),
}
