export const columnsMap = {
  Nome: 'name',
  Email: 'email',
  Telefone: 'phone',
  Code: 'code',
  OS: 'platform',
  Saldo: 'balance',
  Pagos: 'paid',
  Versão: 'app_version',
  Acesso: 'last_access',
  Criação: 'created_at',
}
