import React from 'react'

import t from 'prop-types'

import { Container, Title, Label, Total } from './styles'

export function Confirm({ totalCount }) {
  return (
    <Container>
      <Title>Tem certeza deseja editar todas as missões?</Title>
      <br />
      <br />
      <Label>O número de missões alteradas será:</Label>
      <Total>{totalCount}</Total>
    </Container>
  )
}

Confirm.propTypes = {
  totalCount: t.number,
}
