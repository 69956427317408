import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { Table, Label, Dropdown } from 'semantic-ui-react'

import AccountsCreate from '~/components/AccountsCreate'
import AccountsHeader from '~/components/AccountsHeader'
import EmptyList from '~/components/EmptyList'
import AccountCancel from '~/components/Modal/AccountCancel'
import AccountUpdate from '~/components/Modal/AccountUpdate'
import ResendEmail from '~/components/Modal/ResendEmail'
import TableComponent from '~/components/Table'
import { useAuth } from '~/contexts/auth'
import useAccounts from '~/hooks/useAccounts'

import Main from '../Main'

const columns = [
  'ID',
  'Nome',
  'Email',
  'Username',
  'Role',
  'Status',
  'Criado em',
  'Ações',
]

function Accounts() {
  const {
    loadingAccounts,
    accounts,
    getAccounts,
    submitAccount,
    getRoles,
    roles,
    cancelAccount,
    submitUpdate,
    resendEmail,
    accountsCount,
  } = useAccounts()

  const { user } = useAuth()

  const [showForm, setShowForm] = useState(false)
  const [admin, setAdmin] = useState(null)

  const [userAdmin, setUserAdmin] = useState(null)

  const [modalEdit, setModalEdit] = useState(false)
  const [modalResendEmail, setModalResendEmail] = useState(false)
  const [modalCancel, setModalCancel] = useState(false)

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 15,
    activePage: 1,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,
  })

  useEffect(() => {
    getAccounts({ ...pagination })
    getRoles()
  }, []) // eslint-disable-line

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * pagination.limit
    setPagination(prevState => ({
      ...prevState,
      activePage,
      offset,
      limit: pagination.limit,
    }))
    return getAccounts({
      offset,
      limit: pagination.limit,
    })
  }

  function onChange(key, value) {
    return setAdmin({ ...admin, [key]: value })
  }

  function submitedAccount() {
    submitAccount(admin)
    setAdmin(null)
    return setShowForm(false)
  }

  function submitedCancel() {
    cancelAccount(userAdmin)
    return setModalCancel(false)
  }

  function submitedUpdate() {
    submitUpdate(userAdmin)
    return setModalEdit(false)
  }

  function resendedEmail() {
    resendEmail(userAdmin)
    return setModalResendEmail(false)
  }

  function onClickResend(item) {
    setUserAdmin(item)
    return setModalResendEmail(true)
  }

  function onClickRemove(item) {
    setUserAdmin(item)
    return setModalCancel(true)
  }

  function onClickEdit(item) {
    setUserAdmin(item)
    return setModalEdit(true)
  }

  function handleUpdate(key, value) {
    return setUserAdmin(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  function renderItem(item, index) {
    return (
      <Table.Row key={`key-${index}`}>
        <Table.Cell>#{item.id}</Table.Cell>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.email}</Table.Cell>
        <Table.Cell>{item.login}</Table.Cell>
        <Table.Cell>{item.role_name}</Table.Cell>
        <Table.Cell>
          {item.status ? (
            <Label color="green">Ativo</Label>
          ) : (
            <Label color="yellow">Aguardando Ativação</Label>
          )}
        </Table.Cell>
        <Table.Cell>
          {moment(item.created_at).format('DD/MM/YYYY HH:mm')}
        </Table.Cell>
        <Table.Cell>
          <Dropdown icon="ellipsis vertical">
            <Dropdown.Menu direction="left">
              {!item.status && (
                <Dropdown.Item
                  text="Reenviar Convite"
                  icon="refresh"
                  onClick={() => onClickResend(item)}
                />
              )}

              <Dropdown.Item
                text="Editar Usuário"
                icon="edit"
                onClick={() => onClickEdit(item)}
              />

              <Dropdown.Item
                text="Cancelar Usuário"
                icon="remove"
                onClick={() => onClickRemove(item)}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Table.Cell>
      </Table.Row>
    )
  }

  function renderComponent() {
    return (
      <>
        <AccountsHeader
          show={showForm}
          showForm={() => setShowForm(!showForm)}
        />

        <AccountsCreate
          show={showForm}
          roles={roles}
          admin={admin}
          onChange={onChange}
          submitAccount={submitedAccount}
        />
        <AccountUpdate
          open={modalEdit}
          userAdmin={userAdmin}
          submitUpdate={submitedUpdate}
          handleUpdate={handleUpdate}
          roles={roles}
          close={() => setModalEdit(false)}
        />
        <AccountCancel
          open={modalCancel}
          userAdmin={userAdmin}
          submitCancel={submitedCancel}
          close={() => setModalCancel(false)}
        />
        <ResendEmail
          open={modalResendEmail}
          userAdmin={userAdmin}
          resendEmail={resendedEmail}
          close={() => setModalResendEmail(false)}
        />
        <TableComponent
          showFirstAndLastNav={pagination.showFirstAndLastNav}
          showPreviousAndNextNav={pagination.showPreviousAndNextNav}
          count={accountsCount}
          activePage={pagination.activePage}
          limit={pagination.limit}
          offset={pagination.offset}
          totalCount={accountsCount}
          columns={columns}
          data={accounts}
          renderItem={renderItem}
          isLoading={loadingAccounts}
          handlePaginationChange={handlePaginationChange}
          emptyText={{
            icon: 'check circle outline',
            text: 'Nenhuma conta adicionada...',
          }}
        />
      </>
    )
  }

  return (
    <Main>
      {user?.role_name === 'Basic' || user?.role_name === 'Leader' ? (
        <EmptyList icon="lock" text="Acesso restrito" />
      ) : (
        renderComponent()
      )}
    </Main>
  )
}

export default Accounts
