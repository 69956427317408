import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import t from 'prop-types'
import { Card, Image, Button } from 'semantic-ui-react'

export function Item({ onCreate, onRemove, badge, loading }) {
  const has = badge.has_badge

  return (
    <Card>
      <Card.Content>
        <Image floated="right" size="mini" src={badge.avatar} />
        <Card.Header>{badge.title}</Card.Header>
        {badge.exclusive && <Card.Meta>Exclusivo</Card.Meta>}
        <Card.Description>
          {ReactHtmlParser(badge.description)}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button
          basic
          loading={loading}
          disabled={loading}
          color={has ? 'red' : 'green'}
          content={has ? 'Remover' : 'Adicionar'}
          onClick={() =>
            has ? onRemove(badge.users_badges_id) : onCreate(badge.uid)
          }
        />
      </Card.Content>
    </Card>
  )
}

Item.propTypes = {
  loading: t.bool,
  onCreate: t.func,
  onRemove: t.func,
  badge: t.objectOf(t.any),
}
