import { responseData } from '~/utils/reducer'

const MISSIONS = 'MISSIONS'
const MISSIONS_OPEN = 'MISSIONS_OPEN'
const FETCH_USER_FEEDS_COUNT = 'FETCH_USER_FEEDS_COUNT'
const UPDATE_MISSIONS_STATUS = 'UPDATE_MISSIONS_STATUS'
const MISSIONS_USERS_EXPIRING = 'MISSIONS_USERS_EXPIRING'
const MISSIONS_USERS_EXPIRING_REMOVE = 'MISSIONS_USERS_EXPIRING_REMOVE'
const MISSIONS_USERS_EXPIRING_EXTEND = 'MISSIONS_USERS_EXPIRING_EXTEND'

const missions = responseData(MISSIONS, 'array')
const missionsOpen = responseData(MISSIONS_OPEN, 'array')
const feedsCount = responseData(FETCH_USER_FEEDS_COUNT, 'object')
const missionsExpiring = responseData(MISSIONS_USERS_EXPIRING, 'array')
const missionsUpdateStatus = responseData(UPDATE_MISSIONS_STATUS, 'object')
const missionsUsersRemove = responseData(
  MISSIONS_USERS_EXPIRING_REMOVE,
  'array'
)
const missionsUsersExtend = responseData(
  MISSIONS_USERS_EXPIRING_EXTEND,
  'object'
)

export default {
  missions,
  feedsCount,
  missionsOpen,
  missionsExpiring,
  missionsUsersExtend,
  missionsUsersRemove,
  missionsUpdateStatus,
}
