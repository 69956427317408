const initialState = {
  data: [],
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const TRANSACTIONS = 'TRANSACTIONS'
const TRANSACTIONS_OK = 'TRANSACTIONS_OK'
const TRANSACTIONS_FAIL = 'TRANSACTIONS_FAIL'

export default function transactionsReducers(state = initialState, action) {
  const { data, message, type } = action

  switch (type) {
    case TRANSACTIONS:
      return { ...state, isFetching: true }

    case TRANSACTIONS_OK:
      return { isFetching: false, data, success: true, error: false }

    case TRANSACTIONS_FAIL:
      return { isFetching: false, error: true, message, success: false }

    default:
      return state
  }
}
