import React from 'react'
import ContentLoader from 'react-content-loader'

const Form = () => (
  <ContentLoader
    speed={2}
    width={600}
    height={460}
    viewBox="0 0 600 460"
    backgroundColor="#f9f6f6"
    foregroundColor="#ecebeb"
  >
    <rect x="5" y="9" rx="3" ry="3" width="163" height="28" />
    <rect x="5" y="48" rx="3" ry="3" width="182" height="28" />
    <rect x="5" y="88" rx="3" ry="3" width="500" height="28" />
    <rect x="5" y="132" rx="3" ry="3" width="182" height="28" />
    <rect x="5" y="176" rx="3" ry="3" width="500" height="28" />
    <rect x="5" y="220" rx="3" ry="3" width="182" height="28" />
    <rect x="5" y="264" rx="3" ry="3" width="500" height="28" />
    <rect x="5" y="308" rx="3" ry="3" width="182" height="28" />
    <rect x="5" y="354" rx="3" ry="3" width="500" height="28" />
  </ContentLoader>
)

export default Form
