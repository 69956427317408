import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { SemanticToastContainer } from 'react-semantic-toasts'

import PropTypes from 'prop-types'
import { Form } from 'semantic-ui-react'

import api from '~/services/api'
import message from '~/utils/messages'

export default function CreatePromotion({ onCreatePromotionSuccess }) {
  const [fields, setFields] = useState({
    maxUsers: '',
    percentage: '',
    finalTs: null,
    initialTs: null,
  })
  const [isLoading, setIsLoading] = useState(false)

  const handleInputChange = fieldsProps => {
    setFields(f => ({ ...f, ...fieldsProps }))
  }

  const createPromotion = async data => {
    try {
      setIsLoading(true)
      const res = await api.post('config-campaigns-member', data)

      onCreatePromotionSuccess(res.data)
      message().success('Criado com sucesso!')

      return setFields({
        maxUsers: '',
        percentage: '',
        finalTs: null,
        initialTs: null,
      })
    } catch (error) {
      return message().error(
        error.response?.data?.message || 'Houve um erro no servidor'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const submit = () => {
    setIsLoading(true)

    if (parseInt(maxUsers, 0) <= 0) {
      setIsLoading(false)
      message().error('Máximo de usuário deve ser maior que zero!')
      return false
    }

    if (parseInt(percentage, 0) <= 0) {
      setIsLoading(false)
      message().error('Porcentagem deve ser maior que zero!')
      return false
    }

    if (!initialTs) {
      setIsLoading(false)
      message().error('Data inicial não pode ser nula!')
      return false
    }

    if (!finalTs) {
      setIsLoading(false)
      message().error('Data final não pode ser nula!')
      return false
    }

    return createPromotion({
      final_ts: finalTs,
      max_users: maxUsers,
      initial_ts: initialTs,
      percentage,
    })
  }

  const { maxUsers, percentage, finalTs, initialTs } = fields

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Input
          fluid
          type="number"
          id="maxUsers"
          placeholder="Ex: 5"
          label="Máximo de Usuários"
          onChange={(e, { value }) => handleInputChange({ maxUsers: value })}
          value={maxUsers}
        />

        <Form.Input
          fluid
          id="percentage"
          type="number"
          label="Porcentagem"
          placeholder="Ex: 5"
          onChange={(e, { value }) => handleInputChange({ percentage: value })}
          value={percentage}
        />

        <Form.Field fluid>
          <label htmlFor="initialTs">Data Inicio</label>
          <DatePicker
            id="initialTs"
            selectsStart
            dateFormat="dd/MM/yyyy"
            placeholderText="Data Inicio"
            selected={initialTs || ''}
            startDate={initialTs}
            endDate={finalTs}
            onChange={value => handleInputChange({ initialTs: value })}
          />
        </Form.Field>

        <Form.Field fluid>
          <label htmlFor="finalTs">Data Fim</label>
          <DatePicker
            id="finalTs"
            selectsEnd
            dateFormat="dd/MM/yyyy"
            placeholderText="Data Fim"
            selected={finalTs || ''}
            startDate={initialTs}
            endDate={finalTs}
            onChange={value => handleInputChange({ finalTs: value })}
          />
        </Form.Field>
      </Form.Group>

      <Form.Button
        onClick={submit}
        loading={isLoading}
        disabled={isLoading}
        color="green"
      >
        Criar
      </Form.Button>
      <SemanticToastContainer position="top-center" />
    </Form>
  )
}

CreatePromotion.propTypes = {
  onCreatePromotionSuccess: PropTypes.func,
}
