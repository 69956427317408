import React, { useEffect, useState } from 'react'
import { FlyToInterpolator } from 'react-map-gl'

import * as d3 from 'd3'
import t from 'prop-types'
import { Grid } from 'semantic-ui-react'

import useMissionsFeeds from '~/hooks/useMissionsFeeds'
import Main from '~/pages/Main'

import Feeds from './Feeds'
import Map from './Map'

function Activities({ missionsUsersId, userData }) {
  const { feeds, getMissionsFeeds } = useMissionsFeeds()

  const [viewport, setViewport] = useState({
    zoom: 14,
    latitude: userData?.lat || 0,
    longitude: userData?.lng || 0,
    transitionDuration: 5000,
    transitionEasing: d3.easeCubic,
    transitionInterpolator: new FlyToInterpolator(),
  })

  function onClickAction({ lat, lng }) {
    return setViewport(prevState => ({
      ...prevState,
      zoom: 14,
      latitude: lat,
      longitude: lng,
      transitionDuration: 5000,
      transitionEasing: d3.easeCubic,
      transitionInterpolator: new FlyToInterpolator(),
    }))
  }

  function onChangeMap(viewportData) {
    return setViewport(prevState => ({
      ...prevState,
      ...viewportData,
    }))
  }

  useEffect(() => {
    setViewport(prevState => ({
      ...prevState,
      latitude: userData?.lat,
      longitude: userData?.lng,
    }))
  }, [userData])

  useEffect(() => {
    getMissionsFeeds(missionsUsersId)
  }, []) //eslint-disable-line

  return (
    <Main>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={4}>
            <Feeds items={feeds} onClick={onClickAction} />
          </Grid.Column>
          <Grid.Column width={12}>
            <Map items={feeds} viewport={viewport} onChange={onChangeMap} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Main>
  )
}

Activities.propTypes = {
  missionsUsersId: t.string,
  lat: t.number,
  lng: t.number,
  userData: t.objectOf(t.any),
}

export default Activities
