import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import t from 'prop-types'
import { Button, Card, Form, Grid, Header, Image } from 'semantic-ui-react'

import { missionsMainProps } from '../missionsMainProps'

export function Appearance({ getValues, onChange }) {
  const avatar = getValues('avatar') || 'http://placehold.it/200x200'
  const imagePath = getValues('image_path') || 'http://placehold.it/500x300'
  const urlVideo = getValues('url_video')

  const avatarRef = useRef(null)
  const backgroundRef = useRef(null)
  const [imageFiles, setImageFiles] = useState({})
  const [url, setUrlVideo] = useState(urlVideo)

  function onUpload(key, { files }) {
    const file = files[0]
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onloadend = event => {
      setImageFiles(prev => ({ ...prev, [key]: event.target.result }))
      return onChange(null, { name: key, value: event.target.result })
    }
  }

  function onChangeVideo({ target }) {
    onChange(null, { name: 'url_video', value: target.value })
    return setUrlVideo(target.value)
  }

  return (
    <>
      <Grid.Column width={5}>
        <Form.Input
          type="text"
          name="url_video"
          defaultValue={getValues('url_video')}
          onChange={onChangeVideo}
          label="URL Video"
          placeholder="Ex: URL Youtube"
        />
        <ReactPlayer url={url || urlVideo} height={250} width={365} />
        <Card raised>
          <Card.Content>
            <Card.Header>
              <Header>Background</Header>
            </Card.Header>
            <Image
              size="small"
              name="image_path"
              style={{ width: '100%' }}
              verticalAlign="middle"
              src={imageFiles?.image_path || imagePath}
            />
            <input
              type="file"
              id="image_path"
              accept="image/*"
              style={{ display: 'none' }}
              ref={backgroundRef}
              onChange={({ target }) => onUpload('image_path', target)}
            />
            <Button
              type="button"
              fluid
              icon="upload"
              content="Background"
              onClick={() => backgroundRef.current.click()}
            />
          </Card.Content>
        </Card>
        <Card raised>
          <Card.Content>
            <Card.Header>
              <Header>Logo</Header>
            </Card.Header>
            <Image
              size="small"
              name="avatar"
              style={{ width: '100%' }}
              verticalAlign="middle"
              src={imageFiles?.avatar || avatar}
            />
            <input
              type="file"
              id="avatar"
              accept="image/*"
              style={{ display: 'none' }}
              ref={avatarRef}
              onChange={({ target }) => onUpload('avatar', target)}
            />
            <Button
              type="button"
              fluid
              icon="upload"
              content="Icone"
              onClick={() => avatarRef.current.click()}
            />
          </Card.Content>
        </Card>
      </Grid.Column>
    </>
  )
}

Appearance.propTypes = {
  getValues: missionsMainProps.values,
  onChange: t.func,
}
