import React from 'react'

import { format } from 'date-fns'
import t from 'prop-types'
import { Icon, Dropdown, Button } from 'semantic-ui-react'

import history from '~/services/history'

import {
  Avatar,
  Title,
  TitleLink,
  Grid,
  Subtitle,
  TitleValue,
  ContainerCol,
  StatusLabel,
  ContainerDataCol,
  StatusDescription,
  StatusCPF,
} from './styles'

const colors = {
  0: '#FFE767',
  1: '#BFF6DC',
}

const statusDescription = {
  0: 'Aguardando Comprovante',
  1: 'Aprovado',
}

const PAYMENT_REQUEST_PENDENT_STATUS = 0

function isRegular(status) {
  if (status === null) {
    return <StatusCPF>Consulta Pendente</StatusCPF>
  }

  return status ? (
    <StatusCPF color="green" bold>
      CPF Regular
    </StatusCPF>
  ) : (
    <StatusCPF color="red" bold>
      CPF Irregular
    </StatusCPF>
  )
}

const Item = ({
  item,
  uploadAttachments,
  onClickUpload,
  confirmPayment,
  handleOpenModalWidrawalsReport,
  openRevertPaymentRequestModal,
  handleCheckCPFStatus,
}) => (
  <Grid>
    <ContainerCol position>
      <Avatar src={item.avatar} />
      <ContainerDataCol>
        <TitleLink onClick={() => history.push(`/user/${item.user_id}`)}>
          {item.userName}
        </TitleLink>
        <Subtitle>
          {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
        </Subtitle>
      </ContainerDataCol>
    </ContainerCol>
    <ContainerCol position>
      {item.pix_value !== null ? (
        <ContainerDataCol>
          <Title>PIX ({item.pix_type.toUpperCase()})</Title>
          <Subtitle>{item.pix_value}</Subtitle>
        </ContainerDataCol>
      ) : (
        <ContainerDataCol>
          <Title>{item.bankName}</Title>
          <Subtitle>{item.name_account_holder}</Subtitle>
          <Subtitle>CPF/CNPJ {item.cnpj_cpf}</Subtitle>
          <Subtitle>
            Conta {item.type_account === 'C' ? 'Corrente' : 'Poupança'}
          </Subtitle>
          <Subtitle>
            AG {item.ag} / CC {item.cc}
          </Subtitle>
        </ContainerDataCol>
      )}
    </ContainerCol>
    <ContainerCol position>{isRegular(item.is_regular)}</ContainerCol>
    <ContainerCol position>
      <TitleValue>R${item.value}</TitleValue>
    </ContainerCol>

    <ContainerCol position>
      <StatusLabel
        color={
          item.attachment !== null ? colors[item.payment_status] : '#15C3D6'
        }
      />
      <StatusDescription>
        {item.attachment !== null && item.payment_status === 0 ? (
          <Button color="green" onClick={() => confirmPayment(item.uid)}>
            <Icon name="check" /> Confirmar Pagamento
          </Button>
        ) : (
          <span>{statusDescription[item.payment_status]}</span>
        )}
      </StatusDescription>

      <Dropdown
        icon="ellipsis vertical"
        style={{ marginLeft: 10, color: '#b2b3d1' }}
      >
        <Dropdown.Menu className="left">
          <input
            type="file"
            id={`uploadAttachment-${item.uid}`}
            accept="application/pdf"
            style={{ display: 'none' }}
            onChange={e => uploadAttachments(e, item.uid)}
          />
          <Dropdown.Item
            text="Upload do Comprovante"
            icon="upload"
            onClick={() => onClickUpload(item.uid)}
          />
          {item.attachment !== null && (
            <Dropdown.Item
              text="Download"
              icon="download"
              onClick={() => window.open(item.attachment, '_blank')}
            />
          )}
          {item.url_invoice && (
            <Dropdown.Item
              text="Download NF"
              icon="barcode"
              onClick={() => window.open(item.url_invoice, '_blank')}
            />
          )}
          {item.attachment !== null && item.payment_status === 0 && (
            <Dropdown.Item
              text="Confirmar Pagamento"
              icon="check"
              onClick={() => confirmPayment(item.uid)}
            />
          )}
          <Dropdown.Item
            text="Extrato"
            icon="list"
            onClick={() => handleOpenModalWidrawalsReport(item)}
          />
          {item.payment_status === PAYMENT_REQUEST_PENDENT_STATUS && (
            <Dropdown.Item
              text="Reverter pedido de pagamento"
              icon="cancel"
              onClick={() => openRevertPaymentRequestModal(item)}
            />
          )}
          <Dropdown.Item
            text="Verificar Situação CPF"
            icon="search"
            onClick={() => handleCheckCPFStatus(item)}
          />
        </Dropdown.Menu>
      </Dropdown>
    </ContainerCol>
  </Grid>
)

Item.propTypes = {
  item: t.objectOf(t.any),
  uploadAttachments: t.func,
  onClickUpload: t.func,
  confirmPayment: t.func,
  handleOpenModalWidrawalsReport: t.func,
  openRevertPaymentRequestModal: t.func,
  handleCheckCPFStatus: t.func,
}

export default Item
// {
//   "name_account_holder": "Ed2",
//   "value": "142.00",
//   "payment_status": 0,
//   "created_at": "2021-03-29T15:09:17.750Z",
//   "updatedAt": null,
//   "uid": "bb5c7d60-90a0-11eb-8293-43f871024935",
//   "user_id": "1d110140-f391-11e6-a3d3-bd8e3617b3f7",
//   "ag": "1234",
//   "avatar": "https://devmissionbr.s3.amazonaws.com/users/1d110140-f391-11e6-a3d3-bd8e3617b3f7/1535253963532avatar.png",
//   "cc": "132",
//   "type_account": "C",
//   "cnpj_cpf": "04816437967",
//   "bankName": "Banco BNP Paribas Brasil S.A.",
//   "id": 1174,
//   "attachment": null,
//   "pix_value": null,
//   "pix_type": null,
//   "url_invoice": "https://devmissionbr.s3.amazonaws.com/file",
//   "userName": "Eduardo Parucker",
//   "total_rows": "1"
// }
