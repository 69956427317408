import {
  IMPORT_FILE_ID,
  IMPORT_XLS_CREATE,
  IMPORT_FILE_UPDATE,
  IMPORT_FILE_CREATE,
  IMPORT_FILE_MISSIONS_OPEN,
} from '~/services/api/endpoints'
import { get, post, put, postFormData } from '~/utils/actionsRequests'

const exportOpenMissions = query =>
  get({
    url: `${IMPORT_FILE_MISSIONS_OPEN}${query}`,
    typeFetch: 'IMPORT_FILE_MISSIONS_OPEN',
    typeError: 'IMPORT_FILE_MISSIONS_OPEN_FAIL',
    typeResponse: 'IMPORT_FILE_MISSIONS_OPEN_OK',
  })

const createMissions = id =>
  post({
    url: IMPORT_FILE_CREATE(id),
    typeFetch: 'IMPORT_FILE_CREATE',
    typeError: 'IMPORT_FILE_CREATE_FAIL',
    typeResponse: 'IMPORT_FILE_CREATE_OK',
  })

const findImportsFiles = ({ id, limit, offset }) =>
  get({
    url: `${IMPORT_FILE_ID(id)}?limit=${limit}&offset=${offset}`,
    typeFetch: 'IMPORT_FILE_ID',
    typeError: 'IMPORT_FILE_ID_FAIL',
    typeResponse: 'IMPORT_FILE_ID_OK',
  })

const create = (id, data) =>
  postFormData({
    data,
    url: IMPORT_XLS_CREATE(id),
    typeFetch: 'IMPORT_CREATE',
    typeError: 'IMPORT_CREATE_FAIL',
    typeResponse: 'IMPORT_CREATE_OK',
  })

const update = ({ fileId, id, data }) =>
  put({
    data,
    url: IMPORT_FILE_UPDATE(fileId, id),
    typeFetch: 'IMPORT_FILE_UPDATE',
    typeError: 'IMPORT_FILE_UPDATE_FAIL',
    typeResponse: 'IMPORT_FILE_UPDATE_OK',
  })

export { create, update, createMissions, findImportsFiles, exportOpenMissions }
