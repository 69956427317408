import React from 'react'

import t from 'prop-types'
import { Button } from 'semantic-ui-react'

import { registerStatus } from '~/utils/types'

import { Container } from './styles'

const AvaliationButton = ({ loading, onClick, status }) => {
  const canBackAnalysis =
    status === registerStatus.PENDING || status === registerStatus.IN_CORRECTION
  const canSetToCustomer =
    status === registerStatus.IN_ANALYSIS ||
    status === registerStatus.IN_CORRECTION
  const canReprove = status === registerStatus.IN_ANALYSIS
  const canBackMissionReprovedToAnalyses = registerStatus.REPROVED === status
  const canRevertApprovedMission = registerStatus.APPROVED === status
  return (
    <Container>
      {canBackAnalysis && (
        <Button
          loading={loading}
          onClick={() => onClick(registerStatus.IN_ANALYSIS)}
        >
          VOLTAR PARA ANALISE
        </Button>
      )}
      {canSetToCustomer && (
        <Button
          loading={loading}
          color="yellow"
          onClick={() => onClick(registerStatus.PENDING)}
        >
          PASSAR PARA O CLIENTE
        </Button>
      )}
      {canReprove && (
        <>
          <Button
            loading={loading}
            color="blue"
            onClick={() => onClick(registerStatus.IN_CORRECTION)}
          >
            CORRIGIR
          </Button>
          <Button
            loading={loading}
            color="red"
            onClick={() => onClick(registerStatus.REPROVED)}
          >
            REPROVAÇÃO ANTECIPADA
          </Button>
        </>
      )}
      {canBackMissionReprovedToAnalyses && (
        <Button
          loading={loading}
          color="orange"
          onClick={() => onClick(registerStatus.IN_ANALYSIS)}
        >
          VOLTAR PARA ANÁLISE
        </Button>
      )}

      {canRevertApprovedMission && (
        <Button
          loading={loading}
          color="red"
          onClick={() => onClick(registerStatus.PENDING)}
        >
          REVERTER MISSÃO APROVADA
        </Button>
      )}
    </Container>
  )
}

AvaliationButton.propTypes = {
  onClick: t.func,
  loading: t.bool,
  status: t.number,
}

export default AvaliationButton
