import React from 'react'

import PropTypes from 'prop-types'
import { Button, List, Modal, Image, Icon } from 'semantic-ui-react'

import IDImage from '~/assets/id.png'
import PDFImage from '~/assets/pdf.png'
import SelfieImage from '~/assets/selfie.png'
import useMultiplier from '~/hooks/useMultiplier'

export default function ShowDocumentsModal({
  openDocumentsModal,
  setOpenDocumentsModal,
  registerSelectedToShowDocument,
}) {
  const DOCUMENT_SOURCE = 'loan_credit_profile_approval'
  const DOCUMENT_SOURCE_FIELD = {
    SIGNED_CONTRACT_URL: 'signed_contract_url',
    SELFIE_URL: 'selfie_url',
    DOCUMENT_FRONT_URL: 'document_front_url',
    DOCUMENT_BACK_URL: 'document_back_url',
  }

  const { getSignedUrlToDocument } = useMultiplier()

  const openDocument = async sourceField => {
    const url = await getSignedUrlToDocument(
      registerSelectedToShowDocument.credit_profile_id,
      DOCUMENT_SOURCE,
      sourceField
    )

    return window.open(url, '_blank')
  }

  const checkCanShowElement = {
    [DOCUMENT_SOURCE_FIELD.SIGNED_CONTRACT_URL]:
      registerSelectedToShowDocument?.has_signed_contract_url,
    [DOCUMENT_SOURCE_FIELD.SELFIE_URL]:
      registerSelectedToShowDocument?.has_selfie_url,
    [DOCUMENT_SOURCE_FIELD.DOCUMENT_BACK_URL]:
      registerSelectedToShowDocument?.has_document_back_url,
    [DOCUMENT_SOURCE_FIELD.DOCUMENT_FRONT_URL]:
      registerSelectedToShowDocument?.has_document_front_url,
  }

  const returnDocument = sourceField =>
    checkCanShowElement[sourceField] ? (
      <Button onClick={() => openDocument(sourceField)}>Ver Documento</Button>
    ) : null

  const items = [
    {
      label: 'Contrato Assinado',
      image: PDFImage,
      link: returnDocument(DOCUMENT_SOURCE_FIELD.SIGNED_CONTRACT_URL),
      isCheck: checkCanShowElement[DOCUMENT_SOURCE_FIELD.SIGNED_CONTRACT_URL],
    },
    {
      label: 'Selfie',
      image: SelfieImage,
      link: returnDocument(DOCUMENT_SOURCE_FIELD.SELFIE_URL),
      isCheck: checkCanShowElement[DOCUMENT_SOURCE_FIELD.SELFIE_URL],
    },
    {
      label: 'Documento (Frente)',
      image: IDImage,
      link: returnDocument(DOCUMENT_SOURCE_FIELD.DOCUMENT_FRONT_URL),
      isCheck: checkCanShowElement[DOCUMENT_SOURCE_FIELD.DOCUMENT_FRONT_URL],
    },
    {
      label: 'Documento (Verso)',
      image: IDImage,
      link: returnDocument(DOCUMENT_SOURCE_FIELD.DOCUMENT_BACK_URL),
      isCheck: checkCanShowElement[DOCUMENT_SOURCE_FIELD.DOCUMENT_BACK_URL],
    },
  ]

  return (
    <Modal
      size="tiny"
      open={openDocumentsModal}
      onClose={() => setOpenDocumentsModal(false)}
    >
      <Modal.Header>Documentos Usuário Multiplicador</Modal.Header>
      <Modal.Content>
        <List divided verticalAlign="middle">
          {items.map(item => (
            <List.Item>
              <List.Content floated="right">{item.link}</List.Content>
              <Image src={item.image} style={{ width: 30 }} />
              <List.Content>
                {item.label}
                <Icon
                  style={{ marginLeft: 10 }}
                  color={item.isCheck ? 'green' : 'red'}
                  name={item.isCheck ? 'check circle' : 'remove circle'}
                />
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpenDocumentsModal(false)}>Fechar</Button>
      </Modal.Actions>
    </Modal>
  )
}

ShowDocumentsModal.propTypes = {
  openDocumentsModal: PropTypes.bool,
  setOpenDocumentsModal: PropTypes.func,
  registerSelectedToShowDocument: PropTypes.objectOf(PropTypes.any),
}
