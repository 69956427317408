import React from 'react'

import t from 'prop-types'
import { Grid, Card, Statistic } from 'semantic-ui-react'

const RegisterHeaderData = ({ status, data, handleChange }) => (
  <Grid columns={5} relaxed>
    <Grid.Row>
      <Grid.Column>
        <Card
          fluid
          style={{ border: status === 4 && '3px solid #2185d0' }}
          as="a"
          onClick={() => handleChange(4, 'status')}
        >
          <Card.Content textAlign="center">
            <Statistic size="large" color="blue">
              <Statistic.Label>Em Correção</Statistic.Label>
              <Statistic.Value>
                {data.missions_rejected_by_analysis}
              </Statistic.Value>
            </Statistic>
          </Card.Content>
        </Card>
      </Grid.Column>
      <Grid.Column>
        <Card
          fluid
          style={{ border: status === 3 && '3px solid #f2711c' }}
          as="a"
          onClick={() => handleChange(3, 'status')}
        >
          <Card.Content textAlign="center">
            <Statistic size="large" color="orange">
              <Statistic.Label>Validando Dados</Statistic.Label>
              <Statistic.Value>{data.registers_analyze_count}</Statistic.Value>
            </Statistic>
          </Card.Content>
        </Card>
      </Grid.Column>
      <Grid.Column>
        <Card
          fluid
          style={{ border: status === 0 && '3px solid #fbbd08' }}
          as="a"
          onClick={() => handleChange(0, 'status')}
        >
          <Card.Content textAlign="center">
            <Statistic size="large" color="yellow">
              <Statistic.Label>Aguardando</Statistic.Label>
              <Statistic.Value>{data.registers_pendent_count}</Statistic.Value>
            </Statistic>
          </Card.Content>
        </Card>
      </Grid.Column>
      <Grid.Column>
        <Card
          fluid
          style={{ border: status === 1 && '3px solid #21ba45' }}
          as="a"
          onClick={() => handleChange(1, 'status')}
        >
          <Card.Content textAlign="center">
            <Statistic size="large" color="green">
              <Statistic.Label>Aprovados</Statistic.Label>
              <Statistic.Value>{data.registers_approved_count}</Statistic.Value>
            </Statistic>
          </Card.Content>
        </Card>
      </Grid.Column>
      <Grid.Column>
        <Card
          fluid
          style={{ border: status === 2 && '3px solid #db2828' }}
          as="a"
          onClick={() => handleChange(2, 'status')}
        >
          <Card.Content textAlign="center">
            <Statistic size="large" color="red">
              <Statistic.Label>Reprovados</Statistic.Label>
              <Statistic.Value>{data.registers_reproved_count}</Statistic.Value>
            </Statistic>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

RegisterHeaderData.propTypes = {
  data: t.objectOf(t.any),
  handleChange: t.func,
  status: t.number,
}

export default RegisterHeaderData
