import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'

import moment from 'moment'
import t from 'prop-types'
import { Modal, Form, Button, Message, Progress } from 'semantic-ui-react'
import io from 'socket.io-client'

import { API_BASE_URL } from '~/config/env'
import useMissions from '~/hooks/useMissions'
import message from '~/utils/messages'

import '~/assets/css/react-datepicker.css'

const socket = io(API_BASE_URL, {
  transports: ['websocket'],
})

export function Replace({ filters, open, onClose }) {
  const today = new Date()
  const [isConnected, setIsConnected] = useState(socket.connected)
  const [process, setProcess] = useState(null)
  const {
    totalReplace,
    loadingReplace,
    getReplaceMissions,
    onSubmitReplaceMissions,
  } = useMissions()
  const [expiredAt, setExpiredAt] = useState(today.setDate(today.getDate() + 7))

  useEffect(() => {
    if (filters) {
      getReplaceMissions(filters)
    }
  }, [filters]) //eslint-disable-line

  useEffect(() => {
    socket.on('connect', () => setIsConnected(true))

    socket.on('disconnect', () => setIsConnected(false))

    socket.on('missions:processing', ({ current }) => {
      setProcess(prevState => Number(prevState) + Number(current))
    })

    if (((Number(process) / Number(totalReplace)) * 100).toFixed(0) >= 100) {
      setProcess(null)
      message().success(
        'As missões foram enviadas com sucesso, dentro de alguns instantes todas estarão disponíveis'
      )
      onClose()
    }

    return () => {
      socket.off('connect')
      socket.off('disconnect')
      socket.off('missions:processing')
    }
  }, [totalReplace, process]) //eslint-disable-line

  return (
    <Modal size="small" open={open} onClose={onClose}>
      <Modal.Header content="Recompor Missões" />
      <Modal.Content scrolling>
        <Message info fluid>
          <Message.Header>Recompor Missões</Message.Header>
          <p>
            Total de Missões <b>{totalReplace}</b> Finalizadas Encontradas para
            Recomposição. Preencha a <b>Data de Expiração</b> e clique em{' '}
            <b>Enviar</b> para executar este processo. (
            {isConnected ? 'Conectado' : 'Desconectado'})
          </p>
        </Message>
        {process > 0 && isConnected && (
          <Progress
            percent={((Number(process) / Number(totalReplace)) * 100).toFixed(
              2
            )}
            indicating
            progress
          />
        )}
        <Form>
          <div className="field">
            <label>Data de Expiração</label>
            <div>
              <DatePicker
                id="expired_at"
                minDate={moment()}
                dateFormat="dd/MM/yyyy"
                onChange={value => setExpiredAt(value)}
                value={moment(expiredAt).format('DD/MM/YYYY')}
              />
            </div>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          Cancelar
        </Button>
        <Button
          positive
          icon="checkmark"
          content="Enviar"
          labelPosition="right"
          loading={loadingReplace}
          onClick={() => onSubmitReplaceMissions({ expiredAt, ...filters })}
          disabled={
            (Number(totalReplace) > 0 && expiredAt.length > 0) ||
            loadingReplace ||
            (process > 0 && isConnected)
          }
        />
      </Modal.Actions>
    </Modal>
  )
}

Replace.propTypes = {
  filters: t.objectOf(t.any),
  onClose: t.func,
  open: t.bool,
}
