import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import t from 'prop-types'
import { Table } from 'semantic-ui-react'

import translate from '~/assets/i18n/ptBr'
import { columnsMap } from '~/services/columns-map'

const HeaderColumns = ({ name, onSort }) => {
  const location = useLocation()
  const [filters, setFilters] = useState({
    col: null,
    order: 'ascending',
  })

  function handleSort(selectedColumn) {
    const order = filters.order === 'ascending' ? 'descending' : 'ascending'

    if (typeof onSort === 'function') {
      return onSort(
        selectedColumn,
        filters.order === 'ascending' ? 'desc' : 'asc'
      )
    }

    return setFilters(prev => ({
      ...prev,
      order,
      col: selectedColumn,
    }))
  }

  useEffect(() => {
    const { direction, order } = Object.fromEntries(
      new URLSearchParams(location.search)
    )

    if (direction && order) {
      setFilters(prev => ({
        ...prev,
        order: direction === 'desc' ? 'ascending' : 'descending',
        col: columnsMap[order] || null,
      }))
    }
  }, []) //eslint-disable-line

  return (
    <Table.HeaderCell
      onClick={() => handleSort(name)}
      content={translate[name] || name}
      sorted={filters.col === name ? filters.order : null}
    />
  )
}

HeaderColumns.propTypes = {
  name: t.string,
  onSort: t.oneOfType([t.func, t.any]),
}

export { HeaderColumns }
