import React, { useState } from 'react'
import DatePicker from 'react-datepicker'

import t from 'prop-types'
import {
  Icon,
  Input,
  Card,
  Form,
  Button,
  Select,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'
import { useAuth } from '~/contexts/auth'
import { ExportRegistersList } from '~/pages/Missions/Registers/ExportRegistersList'

const RegisterFilter = ({
  limit,
  final,
  initial,
  customerId,
  categoryId,
  missionsMainId,

  ufsList,
  citiesList,
  customers,
  categories,
  subCategories,
  handlerChange,

  submitFilterRegister,
  resetFilter,
  submitExport,
}) => {
  const { user } = useAuth()

  const [openExportList, setOpenExportList] = useState(false)

  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Buscar..."
              onChange={(e, { value }) => handlerChange(value, 'search')}
            />
            <Form.Field
              control={Select}
              placeholder="Limite"
              selection
              value={limit}
              options={[
                { key: 0, value: 10, text: 10 },
                { key: 1, value: 15, text: 15 },
                { key: 2, value: 25, text: 25 },
                { key: 3, value: 50, text: 50 },
                { key: 4, value: 100, text: 100 },
              ]}
              onChange={(e, { value }) => handlerChange(value, 'limit')}
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Clientes"
              options={customers}
              value={customerId}
              onChange={(e, { value }) => handlerChange(value, 'customerId')}
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Categoria"
              options={categories.map((i, idx) => ({
                key: idx,
                value: i.uid,
                text: i.name,
              }))}
              value={categoryId}
              onChange={(e, { value }) => handlerChange(value, 'categoryId')}
            />
            {subCategories !== undefined && subCategories.length > 0 && (
              <Form.Field
                control={Select}
                search
                selection
                placeholder="Sub-Categoria"
                options={subCategories.map((i, idx) => ({
                  key: idx,
                  value: i.uid,
                  text: i.title,
                }))}
                value={missionsMainId}
                onChange={(e, { value }) =>
                  handlerChange(value, 'missionsMainId')
                }
              />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Field
              control={Select}
              search
              selection
              multiple
              options={ufsList}
              placeholder="UF"
              onChange={(e, { value }) => handlerChange(value, 'uf')}
            />
            {citiesList !== undefined && citiesList.length > 0 && (
              <Form.Field
                control={Select}
                search
                selection
                placeholder="Cidades"
                options={citiesList}
                onChange={(e, { value }) => handlerChange(value, 'city')}
              />
            )}
            <Form.Field
              control={DatePicker}
              id="startDate"
              selectsStart
              dateFormat="dd/MM/yyyy"
              placeholderText="Data Inicio"
              selected={initial}
              startDate={initial}
              endDate={final}
              onChange={value => handlerChange(value, 'initial')}
            />
            <Form.Field
              control={DatePicker}
              id="endDate"
              selectsEnd
              dateFormat="dd/MM/yyyy"
              placeholderText="Data Fim"
              selected={final}
              startDate={initial}
              endDate={final}
              onChange={value => handlerChange(value, 'final')}
            />
          </Form.Group>
        </Form>
      </Card.Content>
      <Card.Content>
        <Button
          icon="check"
          color="green"
          content="Filtrar"
          labelPosition="left"
          onClick={submitFilterRegister}
        />

        <Button
          icon
          color="orange"
          labelPosition="left"
          onClick={() => resetFilter()}
        >
          <Icon name="undo" />
          Resetar Filtro
        </Button>

        {user?.role_name === 'Admin' && (
          <>
            <Dropdown
              text="Exportar"
              icon="upload"
              button
              labeled
              className="icon"
              floating
            >
              <DropdownMenu>
                <DropdownItem
                  icon="upload"
                  text="Exportar Registros"
                  onClick={submitExport}
                />
                <DropdownItem
                  icon="list"
                  text="Ver Registros Exportados"
                  onClick={() => setOpenExportList(true)}
                />
              </DropdownMenu>
            </Dropdown>
            <ExportRegistersList
              open={openExportList}
              setOpen={() => setOpenExportList(false)}
            />
          </>
        )}
      </Card.Content>
    </Card>
  )
}

RegisterFilter.propTypes = {
  limit: t.number,
  final: t.string,
  initial: t.string,
  customerId: t.string,
  categoryId: t.string,
  missionsMainId: t.string,

  ufsList: t.arrayOf(t.any),
  citiesList: t.arrayOf(t.any),
  customers: t.arrayOf(t.any),
  categories: t.arrayOf(t.any),
  subCategories: t.arrayOf(t.any),
  handlerChange: t.func,

  submitFilterRegister: t.func,
  resetFilter: t.func,
  submitExport: t.func,
}

export default RegisterFilter
