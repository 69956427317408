import React, { useState } from 'react'

import { Button, Form, Card } from 'semantic-ui-react'

import logo from '~/assets/logo-white.png'
import { useAuth } from '~/contexts/auth'

export default function Login() {
  const { signIn, loadingSign } = useAuth()

  const [values, setValues] = useState({})

  function onChange(key, value) {
    return setValues({
      ...values,
      [key]: value,
    })
  }

  return (
    <header className="App-header">
      <div style={{ textAlign: 'center' }}>
        <img src={logo} className="App-logo" alt="logo" />
        <h1 style={{ color: 'white' }}>Login</h1>
      </div>
      <Card centered>
        <Card.Content>
          <Form warning size="large" onSubmit={() => signIn(values)}>
            <Form.Input
              type="text"
              label="Email"
              placeholder="Email"
              onChange={e => onChange('email', e.target.value)}
              required
            />
            <Form.Input
              type="password"
              label="Senha"
              placeholder="Digite sua senha"
              onChange={e => onChange('password', e.target.value)}
              required
            />

            <Button
              color="orange"
              content="Entrar"
              loading={loadingSign}
              disabled={loadingSign}
            />
          </Form>
        </Card.Content>
      </Card>
    </header>
  )
}
