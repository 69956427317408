import React from 'react'
import HtmlParse from 'react-html-parser'

import t from 'prop-types'
import { Modal, Button } from 'semantic-ui-react'

const MissionDescription = ({ content, onClose, open }) => (
  <Modal size="small" onClose={onClose} open={open}>
    <Modal.Header>Instruções da Missão</Modal.Header>
    <Modal.Content>{HtmlParse(content)}</Modal.Content>
    <Modal.Actions>
      <Button content="Cancelar" onClick={onClose} />
    </Modal.Actions>
  </Modal>
)

MissionDescription.propTypes = {
  content: t.string,
  onClose: t.func,
  open: t.bool,
}

export default MissionDescription
