import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useFormsItemsParents() {
  const [loadingParents, setLoadingParents] = useState(false)
  const [itemsParents, setItemsParents] = useState([])

  async function submitParents(params) {
    try {
      setLoadingParents(true)

      const res = await api.post('forms-items-parents', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      getParents(params.parentId)

      return Message().success('Registrado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingParents(false)
    }
  }

  async function removeParents(params) {
    try {
      setLoadingParents(true)

      const res = await api.delete('forms-items-parents', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getParents(params.parentId)

      return Message().success('Removido com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingParents(false)
    }
  }

  async function getParents(fiqmUid) {
    try {
      setLoadingParents(true)

      const res = await api.get('forms-items-parents', {
        params: { fiqmUid },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setItemsParents(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingParents(false)
    }
  }

  return {
    submitParents,
    loadingParents,
    getParents,
    itemsParents,
    removeParents,
  }
}
