import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'

import t from 'prop-types'
import { Card, List, Container } from 'semantic-ui-react'

import ContainerImages from '~/pages/Missions/Progress/components/ContainerImages'

function Answers({ formsData }) {
  const [items, setItems] = useState([])
  const [current, setCurrent] = useState(null)
  const [open, setOpen] = useState(false)

  function openBox(i, paths) {
    setItems(JSON.parse(paths).map(image => image.path))
    setCurrent(i)
    return setTimeout(() => setOpen(true), 100)
  }

  return (
    <Container fluid>
      {open && (
        <Lightbox
          onCloseRequest={() => setOpen(false)}
          mainSrc={items[current]}
          nextSrc={items[(current + 1) % items.length]}
          onMovePrevRequest={() => setCurrent(current - 1)}
          onMoveNextRequest={() => setCurrent(current + 1)}
          prevSrc={items[(current + items.length - 1) % items.length]}
        />
      )}
      {formsData.map((item, index) => (
        <Card fluid>
          <Card.Content>
            <List>
              <List.Item key={String(index)}>
                <List.Header as="h3" content={`Seção ${item.section}`} />
                <List>
                  {item.items.map((data, dataIdx) => (
                    <List.Item key={String(dataIdx)}>
                      {data.answer.trim() && (
                        <>
                          <List.Header
                            content={`${data.order}. ${data.question}`}
                          />
                          R:{' '}
                          {data.type === 16 ? (
                            <ContainerImages
                              item={data}
                              answer={data.answer}
                              onClick={openBox}
                            />
                          ) : (
                            data.answer
                          )}
                        </>
                      )}
                    </List.Item>
                  ))}
                </List>
              </List.Item>
            </List>
          </Card.Content>
        </Card>
      ))}
    </Container>
  )
}

Answers.propTypes = {
  formsData: t.arrayOf(t.any),
}

export default Answers
