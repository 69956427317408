import React from 'react'

import t from 'prop-types'
import { length as ramdaLength } from 'ramda'
import {
  Card,
  Form,
  Grid,
  Radio,
  Button,
  Input,
  Message,
} from 'semantic-ui-react'

const CreateFormsItems = ({
  value,
  errors,
  addItem,
  question,
  isLoading,
  multiples,
  submitItem,
  refQuestion,
  questionItem,
  handlerInputCreate,
  handleChangeForm,
}) => {
  return (
    <Card fluid>
      <Card.Content>
        {ramdaLength(errors) > 0 && (
          <Message warning list={errors} header="Alerta!" />
        )}
        <Form>
          <Form.Field
            type="text"
            id="question"
            control={Input}
            value={question}
            label="Pergunta"
            ref={refQuestion}
            placeholder="Sua pergunta"
            onChange={handlerInputCreate}
          />
          <Form.Field
            control={Input}
            type="text"
            id="questionItem"
            onKeyDown={addItem}
            onChange={handlerInputCreate}
            value={questionItem || ''}
            placeholder="Itens (Pressione ENTER para Adicionar)"
          />
          {multiples.map((item, index) => (
            <Grid columns={2} key={`key-${index}`}>
              <Grid.Column width={13}>
                <Form.Field
                  type="text"
                  control={Input}
                  id="questionItem"
                  value={item.answer}
                  key={`key-${index}`}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Form.Field>
                  <Radio
                    value={index}
                    label="Correto"
                    name="isCorrect"
                    checked={value === index}
                    onChange={handleChangeForm}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
          ))}
        </Form>
      </Card.Content>
      <Card.Content extra>
        <Button
          basic
          color="blue"
          loading={isLoading}
          disabled={isLoading}
          onClick={submitItem}
          content="Adicionar Pergunta"
        />
      </Card.Content>
    </Card>
  )
}

CreateFormsItems.propTypes = {
  value: t.string,
  errors: t.string,
  question: t.string,
  multiples: t.arrayOf(t.any),
  refQuestion: t.oneOfType([
    t.func,
    t.shape({ current: t.instanceOf(Element) }),
  ]),
  questionItem: t.string,
  isLoading: t.bool,
  addItem: t.func,
  submitItem: t.func,
  handleChangeForm: t.func,
  handlerInputCreate: t.func,
}

export default CreateFormsItems
