import React, { useState, useRef, useEffect } from 'react'

import t from 'prop-types'
import {
  List,
  Menu,
  Icon,
  Image,
  Card,
  Button,
  Progress,
} from 'semantic-ui-react'

import ImagePdf from '~/assets/pdf.png'
import EmptyList from '~/components/EmptyList'
import useFiles from '~/hooks/useFiles'

import ModalPDFPreview from './Preview'
import { StyledCard } from './styles'

const PDFList = ({ id }) => {
  const uploadRef = useRef('uploadRef')
  const [isPDFPreviewModalOpen, setIsPDFPreviewModalOpen] = useState(false)
  const [selectedPDF, setSelectedPDF] = useState(null)
  const {
    files,
    progress,
    getFiles,
    onRemoveFile,
    onSubmitFile,
    loadingFiles,
  } = useFiles()

  function onUploadFiles(e) {
    const { files: data } = e.target

    return onSubmitFile({ id, data: Array.from(data) })
  }

  function handleOpenPreview(file) {
    setSelectedPDF(file)
    return setIsPDFPreviewModalOpen(true)
  }

  function handleClosePreview() {
    setSelectedPDF(null)
    return setIsPDFPreviewModalOpen(false)
  }

  useEffect(() => {
    getFiles(id)
  }, []) //eslint-disable-line

  return (
    <div>
      <Menu>
        <Menu.Menu position="left">
          <Menu.Item>
            <input
              multiple
              type="file"
              ref={uploadRef}
              id="uploadAttachment"
              accept="application/pdf"
              onChange={onUploadFiles}
              style={{ display: 'none' }}
            />
            <Button
              basic
              icon="plus"
              content="Adicionar"
              labelPosition="left"
              loading={loadingFiles}
              onClick={() => uploadRef.current.click()}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      {progress > 0 && (
        <Progress percent={progress} progress active success>
          {progress === 100
            ? 'Arquivo(s) enviado(s) com sucesso!'
            : 'Enviando...'}
        </Progress>
      )}
      <List divided verticalAlign="middle">
        {files.length === 0 && (
          <EmptyList text="Nenhum Arquivo" icon="file pdf outline" />
        )}

        {files.map((item, index) => {
          return (
            <StyledCard key={String(`item-${index}`)}>
              <Image
                as="a"
                size="medium"
                src={ImagePdf}
                href={item.file_path}
                target="_blank"
              />

              <Card.Content>
                <Card.Header>{item.key}</Card.Header>
              </Card.Content>
              <Card.Content extra>
                <Button icon onClick={() => handleOpenPreview(item.file_path)}>
                  <Icon name="eye" />
                </Button>
                <Button
                  icon
                  style={{ marginLeft: 10 }}
                  onClick={() => window.open(item.file_path, '_blank')}
                >
                  <Icon name="download" />
                </Button>
                <Button
                  icon
                  color="red"
                  loading={loadingFiles}
                  disabled={loadingFiles}
                  style={{ marginLeft: 10 }}
                  onClick={() => onRemoveFile({ id, fileId: item.uid })}
                >
                  <Icon name="trash" />
                </Button>
              </Card.Content>
            </StyledCard>
          )
})}
      </List>
      <ModalPDFPreview
        isOpen={isPDFPreviewModalOpen}
        close={handleClosePreview}
        file={selectedPDF}
      />
    </div>
  )
}

PDFList.propTypes = {
  id: t.string,
}

export default PDFList
