import React, { useEffect } from 'react'

import t from 'prop-types'
import { Card, List, Button } from 'semantic-ui-react'

import useInvoice from '~/hooks/useInvoices'
import Message from '~/utils/messages'

const Products = ({ id, url }) => {
  const { loadingInvoices, invoice, onGenerateInvoice, getInvoice } =
    useInvoice()

  function onDownloadInvoice() {
    if (!url) {
      return Message().error('Orçamento não foi gerado')
    }

    return window.open(url, '_blank')
  }

  useEffect(() => {
    getInvoice(id)
  }, []) //eslint-disable-line

  return (
    <Card fluid>
      {!loadingInvoices && invoice.length > 0 && (
        <>
          <Card.Content>
            <Card.Header>Informações</Card.Header>
            <Card.Description>
              {invoice[0].invoice_products.corporate_name}
            </Card.Description>
            <Card.Description>
              {invoice[0].invoice_products.corporate_cnpj}
            </Card.Description>
            <Card.Description>
              {invoice[0].invoice_products.customer_email}
            </Card.Description>
            <Card.Description>
              {invoice[0].invoice_products.phone_1}
            </Card.Description>
            <Card.Description>
              {invoice[0].invoice_products.phone_2}
            </Card.Description>
          </Card.Content>
          <Card.Content>
            {invoice.length > 0 &&
              invoice[0].invoice_products.invoice.map(item => (
                <>
                  <Card.Header>{item.category}</Card.Header>
                  <List>
                    {item.products.map(prod => {
                      const installmentValue =
                        Number.parseFloat(prod.value) /
                        Number.parseFloat(
                          prod.installments ? prod.installments : 1
                        )

                      return (
                        <List.Item>
                          <List.Header>
                            {`${prod.title}\n${prod.description}`}
                          </List.Header>
                          Qtd. ({prod.quantity})
                          <List.Content floated="right">
                            R$ {Number.parseFloat(installmentValue).toFixed(2)}
                          </List.Content>
                        </List.Item>
                      )
                    })}
                  </List>
                </>
              ))}
          </Card.Content>
          <Card.Content>
            <List>
              <List.Item>
                <b>Total</b>
                <List.Content floated="right">
                  R${' '}
                  {invoice.length > 0 &&
                    Number.parseFloat(
                      invoice[0].invoice_products.invoice[0].products
                        .map(
                          product =>
                            Number(product.value) * Number(product.quantity)
                        )
                        .reduce((total, current) => total + current)
                    ).toFixed(2)}
                </List.Content>
              </List.Item>
            </List>
          </Card.Content>
          <Card.Content>
            <Button positive onClick={onDownloadInvoice}>
              Baixar PDF
            </Button>
            <Button
              primary
              loading={loadingInvoices}
              onClick={() => onGenerateInvoice(id)}
            >
              Gerar Novo
            </Button>
          </Card.Content>
        </>
      )}
    </Card>
  )
}

Products.propTypes = {
  id: t.string,
  url: t.string,
}

export default Products
