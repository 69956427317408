import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as Yup from 'yup'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useAccounts() {
  const [loadingAccounts, setLoadingAccounts] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [accountsCount, setAccountsCount] = useState(0)
  const [roles, setRoles] = useState([])
  const history = useHistory()

  const getAccounts = useCallback(async params => {
    try {
      setLoadingAccounts(true)
      const token = localStorage.getItem('@MissionAdmin:token')

      const res = await api.get('admin/fetch', {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setAccountsCount(res.data.count)

      setLoadingAccounts(false)
      return setAccounts(res.data.data)
    } catch (error) {
      setLoadingAccounts(false)
      return Message().error(error.message)
    }
  }, [])

  const getRoles = useCallback(async () => {
    try {
      const res = await api.get('roles', {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const rolesAdmin = res.data.map(({ uid, name }, idx) => ({
        key: `key-${idx}`,
        text: name,
        value: uid,
        role_id: uid,
      }))

      return setRoles(rolesAdmin)
    } catch (error) {
      return Message().error(error.message)
    }
  }, [])

  const submitAccount = async accountData => {
    try {
      setLoadingAccounts(true)

      const schema = Yup.object().shape({
        email: Yup.string().email().required('E-mail é obrigatório.'),
        role_id: Yup.string().required('Regra é obrigatória.').uuid(),
      })

      const isValid = schema.isValidSync(accountData)

      if (!isValid) {
        const validate = schema.validateSync(accountData)
        return Message().error(validate)
      }

      const res = await api.post('sessions/admin/create', accountData, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      setLoadingAccounts(false)
      return Message().success('Admin criado com sucesso!')
    } catch (error) {
      setLoadingAccounts(false)
      return Message().error(error.message)
    }
  }

  const submitActivation = async activationData => {
    try {
      setLoadingAccounts(true)

      const { password, confirmPassword, token } = activationData
      const passwordEqual = password === confirmPassword

      if (!passwordEqual) {
        setLoadingAccounts(false)
        return Message().error(
          'As senhas estão diferentes. Por favor, verifique.'
        )
      }

      const schema = Yup.object().shape({
        userId: Yup.string().uuid().required('User ID obrigatório'),
        name: Yup.string().required('Nome obrigatório.'),
        password: Yup.string().required('Senha obrigatória.'),
      })

      const isValid = schema.isValidSync(activationData)

      if (!isValid) {
        const validate = schema.validateSync(activationData)
        return Message().error(validate)
      }

      const res = await api.patch('sessions/admin/activation', activationData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      history.push('/')
      setLoadingAccounts(false)
      return Message().success('Admin alterado com sucesso!')
    } catch (error) {
      setLoadingAccounts(false)
      return Message().error(error.message)
    }
  }

  const submitUpdate = async updateData => {
    try {
      setLoadingAccounts(true)

      const schema = Yup.object().shape({
        email: Yup.string().email().required('E-mail é obrigatório.'),
        role_id: Yup.string().required('Regra é obrigatória.').uuid(),
      })

      const isValid = schema.isValidSync(updateData)

      if (!isValid) {
        const validate = schema.validateSync(updateData)
        return Message().error(validate)
      }

      const { uid, name, email, role_id } = updateData

      const res = await api.patch(
        'sessions/admin/update',
        { uid, name, email, role_id },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }
      const index = accounts.findIndex(i => i.uid === updateData.uid)
      accounts[index] = updateData
      setAccounts(accounts)

      setLoadingAccounts(false)
      return Message().success('Admin alterado com sucesso!')
    } catch (error) {
      setLoadingAccounts(false)
      return Message().error(error.message)
    }
  }

  const cancelAccount = async params => {
    try {
      setLoadingAccounts(true)
      const { uid } = params

      const res = await api.patch(
        'sessions/admin/cancel',
        { userId: uid },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      const changedStatus = {
        ...params,
        status: JSON.parse(res.data.admin.status),
      }

      const index = accounts.findIndex(i => i.uid === res.data.admin.uid)
      accounts[index] = changedStatus
      setAccounts(accounts)

      setLoadingAccounts(false)
      return Message().success('Admin cancelado')
    } catch (error) {
      setLoadingAccounts(false)
      return Message().error(error.message)
    }
  }

  const resendEmail = async data => {
    try {
      setLoadingAccounts(true)

      const schema = Yup.object().shape({
        email: Yup.string().email().required('E-mail é obrigatório.'),
        role_id: Yup.string().uuid().required('Regra é obrigatória.'),
      })

      const isValid = schema.isValidSync(data)

      if (!isValid) {
        const validate = schema.validateSync(data)
        return Message().error(validate)
      }

      const res = await api.patch('sessions/admin/resend-email', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      setLoadingAccounts(false)
      return Message().success('Email reenviado com sucesso')
    } catch (error) {
      setLoadingAccounts(false)
      return Message().error(error.message)
    }
  }

  return {
    accountsCount,
    accounts,
    getAccounts,
    loadingAccounts,
    submitAccount,
    roles,
    getRoles,
    submitActivation,
    cancelAccount,
    submitUpdate,
    resendEmail,
  }
}
