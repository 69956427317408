export const month = [
  { key: 1, value: '01', text: 'Janeiro' },
  { key: 2, value: '02', text: 'Fevereiro' },
  { key: 3, value: '03', text: 'Março' },
  { key: 4, value: '04', text: 'Abril' },
  { key: 5, value: '05', text: 'Maio' },
  { key: 6, value: '06', text: 'Junho' },
  { key: 7, value: '07', text: 'Julho' },
  { key: 8, value: '08', text: 'Agosto' },
  { key: 9, value: '09', text: 'Setembro' },
  { key: 10, value: '10', text: 'Outubro' },
  { key: 11, value: '11', text: 'Novembro' },
  { key: 12, value: '12', text: 'Dezembro' },
]

export const year = [
  { key: 1, value: '2017', text: '2017' },
  { key: 2, value: '2018', text: '2018' },
  { key: 3, value: '2019', text: '2019' },
  { key: 4, value: '2020', text: '2020' },
  { key: 5, value: '2021', text: '2021' },
  { key: 6, value: '2022', text: '2022' },
]

export const statusMissions = [
  { key: 0, value: 0, text: 'Aguardando Avaliação' },
  { key: 1, value: 1, text: 'Aprovado' },
  { key: 2, value: 2, text: 'Reprovado' },
]

export const reprovedList = [
  'Não se deslocou',
  'Não imprimiu',
  'Foto Incorreta',
  'Foto ilegível',
  'Incompleto',
]

export const feedIcon = {
  quit: 'sign out',
  start: 'play circle',
  finish: 'check circle',
  photos: 'photo',
  'open-web': 'linkify',
  'form-sended': 'file text',
  check_in: 'map marker alternate',
  check_out: 'map marker alternate',
}

export const feedLabel = {
  quit: 'Desistiu da missão',
  start: 'Iniciou a missão',
  finish: 'Finalizou a missão',
  photos: 'Enviou Foto',
  'open-web': 'Anexo Aberto',
  'form-sended': 'Enviou Formulário',
  check_in: 'Registro de Entrada',
  check_out: 'Registro de Saída',
}

export const iconColor = {
  quit: 'red',
  start: 'blue',
  finish: 'green',
  photos: 'teal',
  'open-web': 'teal',
  'form-sended': 'yellow',
  check_in: 'green',
  check_out: 'red',
}

export const statusPaymentColor = {
  0: 'yellow',
  1: 'green',
  2: 'red',
}

export const statusPaymentLabel = {
  0: 'Aguardando Avaliação',
  1: 'Aprovado',
  2: 'Cancelado',
}

export const statusRegisterLabel = {
  0: 'Aguardando Avaliação',
  1: 'Aprovado',
  2: 'Reprovado',
  3: 'Validando Dados',
  4: 'Em Correção',
}

export const registerStatus = {
  PENDING: 0,
  APPROVED: 1,
  REPROVED: 2,
  IN_ANALYSIS: 3,
  IN_CORRECTION: 4,
}

export const statusRegisterColor = {
  0: '#fbbd08',
  1: '#21ba45',
  2: '#db2828',
  3: '#f2711c',
  4: '#2185d0',
}

export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})

export const campaignsColumns = [
  'ID',
  'Campanha',
  'Mensagem',
  'Inicio',
  'Fim',
  'Horários',
  'Períodos',
  'Criado Em',
  'Atualizado Em',
  'Ações',
]

export const recurrency = [
  { value: 'daily', key: 0, text: 'Diário' },
  { value: 'weekly', key: 1, text: 'Semanal' },
  { value: 'monthly', key: 2, text: 'Mensal' },
]

export const weeks = [
  { value: 0, key: 0, text: 'Domingo' },
  { value: 1, key: 1, text: 'Segunda-Feira' },
  { value: 2, key: 2, text: 'Terça-Feira' },
  { value: 3, key: 3, text: 'Quarta-Feira' },
  { value: 4, key: 4, text: 'Quinta-Feira' },
  { value: 5, key: 5, text: 'Sexta-Feira' },
  { value: 6, key: 6, text: 'Sábado' },
]

export const OPTIONS_TYPE_INPUTS = {
  SELECT: [
    { key: '=', value: '=', text: 'Igual' },
    { key: '>=', value: '>=', text: 'Maior é igual' },
    { key: '>', value: '>', text: 'Maior' },
    { key: '<=', value: '<=', text: 'Menor é igual' },
    { key: '<', value: '<', text: 'Menor' },
  ],
  INPUT: [],
}

export const TYPE_INPUTS_OPTIONS = [
  { key: 'text', value: 'INPUT', text: 'Texto' },
  { key: 'input_number', value: 'INPUT_NUMBER', text: 'Númerico' },
  { key: 'select', value: 'SELECT', text: 'Opções de comparação' },
]
export const creditProfileStatus = {
  APPROVED: 'APPROVED',
  REPROVED: 'REPROVED',
  PENDING: 'PENDING',
}

export const mappedCreditProfileStatus = {
  APPROVED: 'Aprovado',
  REPROVED: 'Reprovado',
  PENDING: 'Aguardando Aprovação',
}

export const mappedCreditProfileStatusColor = {
  APPROVED: 'green',
  REPROVED: 'red',
  PENDING: 'yellow',
}

export const mappedLoanRequestStatus = {
  APPROVED: 'Aprovado',
  REPROVED: 'Reprovado',
  PENDING: 'Aguardando Aprovação',
}

export const mappedStatusInstallment = {
  PENDING: 'Aberto',
  PAID: 'Pagamento Aprovado',
  CANCELED: 'Cancelado',
}

export const mappedStatusInstallmentColor = {
  PENDING: 'yellow',
  PAID: 'green',
  CANCELED: 'red',
}

export const loanApprovalItemStatus = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  CANCELED: 'CANCELED',
}

export const loanRequestStatus = {
  PENDING: 'PENDING',
}

const primeSettings = {
  icon: 'diamond',
  bg: 'red',
}

const blackSettings = {
  icon: 'chess king',
  bg: 'black',
}

export const planColor = {
  prime: primeSettings,
  prime_month: primeSettings,
  prime_anual: primeSettings,
  black: blackSettings,
  black_month: blackSettings,
  black_anual: blackSettings,
}

export const missionProgress = [
  { key: 0, text: 'Aguardando', value: 0 },
  { key: 1, text: 'Iniciado', value: 1 },
  { key: 2, text: 'Concluído', value: 2 },
  { key: 3, text: 'Rejeitado', value: 3 },
  { key: 4, text: 'Expirado', value: 4 },
  { key: 5, text: 'Excluído', value: 5 },
  { key: 6, text: 'Desistência', value: 6 },
  { key: 7, text: 'Missão Incompleta', value: 7 },
  { key: 8, text: 'Aguardando Aprovação de Orçamento', value: 8 },
  { key: 9, text: 'Agendamento de missão', value: 9 },
  { key: 10, text: 'Agendamento expirado', value: 10 },
]

export const QUESTIONS_TYPES = {
  SHORT_ANSWER: 1,
  LONG_ANSWER: 2,
  MULTIPLE: 3,
  SELECT_LIST: 4,
  DATE: 5,
  HOUR: 6,
  NUMBER: 7,
  PRICE: 8,
  CPF: 9,
  CNPJ: 10,
  ZIP_CODE: 11,
  PHONE: 12,
  ADDRESS: 13,
  TEXT: 14,
  EMAIL: 15,
  CAMERA: 16,
  RATING: 17,
}
