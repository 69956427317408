import { Icon, Tab } from 'semantic-ui-react'
import styled from 'styled-components'

export const LargeText = styled.p`
  font-size: ${props => (props.size !== null ? props.size : '1.2em')};
  margin: 10px;
  font-weight: bold;
`
export const MarginTop10 = styled.span`
  margin-top: 10px;
`

export const AlertLow30Percentage = styled(Icon)`
  position: relative;
  top: -3px;
  right: -105px;
  z-index: 100;
`

export const ContainerDiv = styled.div`
  margin: 10px 0px 0px 0px;
  padding-bottom: 10px;
`

export const TabReponsive = styled(Tab)`
  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`
