import React from 'react'

import t from 'prop-types'
import { Grid } from 'semantic-ui-react'

import ContainerLoading from '~/components/Loading/ContainerLoading'

import { ContainerDiv, LargeText } from '../styles'
import ThermometerCard from './ThermometerCard'

function FunnelInactiveUser({ data: funnelUserInactive, isLoading }) {
  const isEmpty = () => {
    return funnelUserInactive.length === 0 && isLoading === false
  }

  return (
    <ContainerDiv>
      <ContainerLoading
        isLoading={isLoading}
        customText="Carregando informações"
      >
        <>
          {isEmpty() && (
            <LargeText
              size="2em"
              className="center aligned"
              style={{ marginBottom: '20px' }}
            >
              Não há nenhum registro.
            </LargeText>
          )}
          <Grid columns={4} stackable>
            {funnelUserInactive.map(item => {
              return (
                <ThermometerCard percentage={item.percentage} label={item.city} />
              )
            })}
          </Grid>
        </>
      </ContainerLoading>
    </ContainerDiv>
  )
}

FunnelInactiveUser.propTypes = {
  data: t.arrayOf(t.objectOf(t.any)),
  isLoading: t.bool,
}

export default FunnelInactiveUser
