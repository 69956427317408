import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useMissionsMainGroups() {
  const [loadingGroups, setLoading] = useState(false)
  const [groups, setGroups] = useState([])

  const getMissionsMainsGroups = useCallback(async () => {
    try {
      setLoading(true)

      const res = await api.get('missions-main-group', {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setGroups(res.data.missionsMainGroups)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  async function onCreateMissionsMainGroups(data) {
    try {
      setLoading(true)

      const res = await api.post('missions-main-group', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return Message().success('Grupo criado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    groups,
    loadingGroups,
    getMissionsMainsGroups,
    onCreateMissionsMainGroups,
  }
}
