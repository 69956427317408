import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

// export const USERS_LEADER_FETCH = `user/consultants/fetch`
// export const USERS_LEADER_CREATE = `user/consultants/insert`
// export const USERS_LEADER_UPDATE = id => `user/consultants/${id}/update`
// export const USERS_LEADER_REMOVE = (id, badgeId) =>
//   `user/consultants/${id}/remove/${badgeId}`

export default function useUsersConsultants() {
  const [leaders, setLeaders] = useState([])
  const [consultants, setConsultants] = useState([])
  const [loadingUsersConsultants, setLoading] = useState(false)

  const getUsersConsultants = useCallback(async params => {
    try {
      setLoading(true)
      const res = await api.get('users-consultants', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setConsultants(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  const getUsersConsultantsLeaders = useCallback(async params => {
    try {
      setLoading(true)
      const res = await api.get('users-consultants/leaders', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setLeaders(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  async function onSubmitUsersConsultants(data) {
    try {
      setLoading(true)
      const res = await api.post('users-consultants', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Líder criado com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function onUpdateUsersConsultants(data) {
    try {
      setLoading(true)
      const res = await api.put('users-consultants', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Líder criado com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function onDisableLeader(userId) {
    try {
      setLoading(true)
      const res = await api.delete(`users-consultants/leader/${userId}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Líder removido com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function onDisableUserConsultor(userId) {
    try {
      setLoading(true)
      const res = await api.delete(`users-consultants/${userId}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('O consultor foi desabilitado')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    loadingUsersConsultants,
    onSubmitUsersConsultants,
    onDisableLeader,
    onDisableUserConsultor,
    getUsersConsultants,
    getUsersConsultantsLeaders,
    onUpdateUsersConsultants,
    consultants,
    leaders,
  }
}
