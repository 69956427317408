import { responseData } from '~/utils/reducer'

const DASHBOARD = 'DASHBOARD'
const DASHBOARD_REPORT_DATA = 'DASHBOARD_REPORT_DATA'
const DASHBOARD_REPORT_CATEGORIES = 'DASHBOARD_REPORT_CATEGORIES'
const HEATMAP = 'HEATMAP'

const dashboard = responseData(DASHBOARD, 'object')
const dashboardFilter = responseData(DASHBOARD_REPORT_DATA, 'object')
const dashboardCategories = responseData(DASHBOARD_REPORT_CATEGORIES, 'array')
const fetchHeatmap = responseData(HEATMAP, 'array')

export default {
  dashboard,
  dashboardFilter,
  dashboardCategories,
  fetchHeatmap,
}
