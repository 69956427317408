import React, { useState } from 'react'

import { Form, Button, Card } from 'semantic-ui-react'
import * as yup from 'yup'

import useChallenge from '~/hooks/useChallenge'
import Message from '~/utils/messages'

import FormDynamicParams from '../components/FormDynamicParams'

function Create() {
  const schemaValidation = yup.object().shape({
    name: yup.string().required('Nome é obrigatório.'),
    params: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().required('Texto da questão é obrigatório'),
          type: yup.string().required('Tipo do parâmetro é obrigatório'),
          name: yup.string().required('Nome do parâmetro é obrigatório'),
        })
      )
      .required('Parâmetros são obrigatórios'),
  })

  const {
    createTypeChallenge,
    setTypeChallengeParams,
    addTypeChallengeParams,
    typeChallengeParams,
    prepareDataToPersist,
  } = useChallenge()
  const [name, setName] = useState('')

  const submit = async () => {
    const isValid = schemaValidation.isValidSync({
      name,
      params: typeChallengeParams,
    }, { abortEarly: false })

    try {
      if (!isValid) {
        schemaValidation.validateSync({
          name,
          params: typeChallengeParams,
        }, { abortEarly: false })
      }

      const data = prepareDataToPersist({
        name,
        params: typeChallengeParams,
      })

      await createTypeChallenge(data)
      setTypeChallengeParams([
        {
          label: '',
          type: '',
          name: '',
        },
      ])
      setName('')
    } catch (error) {
      Message().errors(error.errors)
    }
  }

  return (
    <>
      <Card fluid>
        <Card.Content>
          <Card.Description>
            <Form>
              <Form.Field>
                <label>Nome:</label>
                <input
                  value={name}
                  onChange={event => setName(event.target.value)}
                  placeholder="Nome"
                />
              </Form.Field>
              <Form.Field>
                <label>Parâmetros:</label>
                <FormDynamicParams
                  setParams={setTypeChallengeParams}
                  params={typeChallengeParams}
                />
                <Button onClick={addTypeChallengeParams}>
                  Adicionar novo parâmetro
                </Button>
              </Form.Field>
              <Button primary type="submit" onClick={submit}>
                Salvar
              </Button>
            </Form>
          </Card.Description>
        </Card.Content>
      </Card>
    </>
  )
}

export default Create
