import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Menu, Image, Dropdown, Icon } from 'semantic-ui-react'

import ImageAvatar from '~/assets/logo-dark.png'
import { APP_VERSION } from '~/config/env'
import { useAuth } from '~/contexts/auth'

import items from './routesAuthenticated'
import { StyledDropdown, Container } from './styles'

export default function SideBar() {
  const location = useLocation()
  const { user, signOut } = useAuth()

  useEffect(() => {}, [location])

  function renderItems(item, key) {
    const { path, icon, text, restrictions, hasSubMenu, subMenu } = item
    const hasAccess = restrictions.find(value => {
      return value === user?.role_name
    })
    if (!hasAccess && !hasSubMenu) {
      return (
        <Menu.Item
          to={path}
          as={Link}
          name={path}
          icon={icon}
          content={<span>{text}</span>}
          key={`menu-side-bar-${key}`}
          active={location?.pathname === path}
        />
      )
    }

    if (!hasAccess && hasSubMenu) {
      return (
        <StyledDropdown
          className={
            subMenu.some(i => location?.pathname === i.path) ? 'active' : ''
          }
          icon={icon}
          pointing="left"
          item
          text={
            <Container>
              <span>{text}</span> <Icon name="angle right" />
            </Container>
          }
          key={`menu-side-bar-${key}`}
        >
          <Dropdown.Menu scrolling>
            {subMenu.map(subMenuItem => {
              const hasAnotherRestriction =
                subMenuItem.uncommonRestrictions.find(
                  value => value === user?.role_name
                )
              if (!hasAnotherRestriction) {
                return (
                  <Dropdown.Item
                    key={`submenu-${subMenuItem.text}`}
                    to={subMenuItem.path}
                    name={subMenuItem.path}
                    as={Link}
                    text={`${subMenuItem.text}`}
                  />
                )
              }

              return false
            })}
          </Dropdown.Menu>
        </StyledDropdown>
      )
    }

    return false
  }

  return (
    <Menu
      compact
      inverted
      vertical
      className="side-bar enable-scroll-horizontally"
    >
      <Menu.Item>
        <div>
          <Image avatar size="mini" src={ImageAvatar} verticalAlign="middle" />
          <span className="header-side-bar">
            Plataforma Admin ({APP_VERSION})
          </span>
        </div>
      </Menu.Item>
      {items.map(renderItems)}
      <Menu.Item
        name="Sair"
        content={<span>Sair</span>}
        icon="sign out"
        onClick={signOut}
      />
    </Menu>
  )
}
