import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom'

import queryString from 'query-string'
import { isNil } from 'ramda'

import TableComponent from '~/components/Table'
import UsersNearby from '~/components/UsersNearby'
import useDatausers from '~/hooks/useData'
import useExportFiles from '~/hooks/useExportFiles'
import useMissions from '~/hooks/useMissions'
import useMissionsUsers from '~/hooks/useMissionsUsers'
import useSubCategories from '~/hooks/useSubCategories'

import CurrentParticipants from './components/CurrentParticipants'
import MissionsFilter from './components/Filter'
import Item from './components/Item'

const directions = [
  { key: 0, text: 'Aberta', value: 'opened' },
  { key: 1, text: 'Iniciadas', value: 'started' },
  { key: 2, text: 'Finalizadas', value: 'finished' },
  { key: 3, text: 'Inativas', value: 'inactive' },
]

const columns = [
  'ID',
  'Missão',
  'Cidade',
  'UF',
  'Categoria',
  'Sub Categoria',
  'Participantes',
  'Participação Atual',
  'Cliente',
  'Expira Em',
  '',
]

const orderByLabel = {
  opened: 'de Missões Abertas',
  started: 'de Missões Iniciadas',
  finished: 'de Missões Finalizadas',
}

const filtersColumn = {
  Cliente: 'customername',
  Categoria: 'category',
  Missão: 'name',
  Cidade: 'place',
  'Expira Em': 'm.expired_at',
  UF: 'uf',
  'Sub Categoria': 'sub_category',
  Participantes: 'participants',
}

function MissionsOpen() {
  const history = useHistory()
  const location = useLocation()

  const { getCurrentParticipants, currentParticipants } = useMissionsUsers()
  const { getSubCategories, subcategories } = useSubCategories()
  const { onExportMissionsOpen, loadingExport } = useExportFiles()
  const { getUsersNearby, usersNearby, setUsersNearby, loadingUsers } =
    useDatausers()
  const { getMissionsOpen, loadingMissions, count, joinedMissions } =
    useMissions()

  const [showCurrentParticipants, setShowCurrentParticipants] = useState(false)
  const [limit, setLimit] = useState(50)
  const [open, setOpen] = useState(false)
  const [mission, setMission] = useState({})
  const [filters, setFilters] = useState({
    limit: 15,
    offset: 0,
    final: null,
    initial: null,
    kind: 'opened',
    customer_id: '',
    direction: 'asc',
    missions_main_id: '',
    type: 'm.expired_at',
  })

  const [activePageTable, setActivePageTable] = useState(1)

  function handleColumnSort(order, direction) {
    return onSubmitOrderRequest({ type: filtersColumn[order], direction })
  }

  function onOpenModalUsersNearby(item) {
    setMission(item)
    getUsersNearby({
      lat: item.lat,
      lng: item.lng,
      offset: 0,
      limit,
      customerId: item.customer_id,
      isPrivate: item.is_private,
    })
    return setOpen(true)
  }

  function onFilterUsers({ item, orderBy }) {
    return getUsersNearby({
      lat: item.lat,
      lng: item.lng,
      limit,
      orderBy,
      customerId: item.customer_id,
      isPrivate: item.is_private,
    })
  }

  function onSubmitMore({ item, orderBy }) {
    getUsersNearby({
      isPrivate: item.is_private,
      customerId: item.customer_id,
      lat: item.lat,
      lng: item.lng,
      orderBy,
      offset: 0,
      limit: limit + limit,
    })

    return setLimit(limit + limit)
  }

  function onChange(key, value) {
    if (key === 'category_id') {
      getSubCategories({ categoryId: value })
    }

    if (key === 'initial' || key === 'final') {
      setFilters(prevState => ({ ...prevState, [key]: value }))
      return handleQuery(key, new Date(value).getTime())
    }

    setFilters(prevState => ({ ...prevState, [key]: value }))
    return handleQuery(key, value)
  }

  function handleQuery(target, value) {
    const queryParse = queryString.parse(location.search)
    const query = { ...queryParse, [target]: value }
    const search = queryString.stringify(query)

    return history.replace({ search, pahtname: location.pathname })
  }

  function handlePaginationChange(e, { activePage }) {
    const newOffset = (activePage - 1) * filters.limit
    const queries = queryString.parse(location.search)
    const search = {
      ...filters,
      ...queries,
      offset: newOffset,
      limit: filters.limit,
    }

    setActivePageTable(activePage)

    setFilters(prevState => ({ ...prevState, activePage, offset: newOffset }))

    handleQuery('offset', newOffset)

    return getMissionsOpen(search)
  }

  function onParseDate(queryFilters) {
    if (!isNil(queryFilters.initial) && !isNil(queryFilters.final)) {
      queryFilters = {
        ...queryFilters,
        initial: Number(queryFilters.initial),
        final: Number(queryFilters.final),
      }
    }

    return queryFilters
  }

  function onSubmitOrderRequest(query = null) {
    let newQuery = { ...filters, limit: Number(filters.limit) }

    newQuery = onParseDate(newQuery)

    if (location.search) {
      let locationSearch = queryString.parse(location.search)

      locationSearch = onParseDate(locationSearch)

      newQuery = { ...newQuery, ...locationSearch }
    }

    if (query) {
      newQuery = { ...newQuery, ...query }
    }

    if (filters.activePage !== '1') {
      newQuery.offset = 0
    }

    setFilters(prevState => ({ ...prevState, ...newQuery }))

    history.replace({ search: queryString.stringify(newQuery) })

    return getMissionsOpen(newQuery)
  }

  function onClickCurrentParticipants(item) {
    const querySearch = queryString.parse(location.search)

    const progressValues = {
      opened: [0, 3, 4, 5, 6],
      started: [1],
      finished: [2],
    }

    setShowCurrentParticipants(true)

    return getCurrentParticipants({
      missionId: item.uid,
      progress: progressValues[querySearch.kind],
    })
  }

  useEffect(() => {
    onSubmitOrderRequest()
  }, []) //eslint-disable-line

  return (
    <>
      <MissionsFilter
        {...filters}
        limit={Number(filters.limit)}
        onChange={onChange}
        directions={directions}
        subCategories={subcategories}
        onSubmitOrderRequest={onSubmitOrderRequest}
        loadingMissions={loadingMissions || loadingExport}
        onExportMissionsOpen={() => onExportMissionsOpen(filters)}
      />
      <TableComponent
        showEllipsis
        siblingRange={1}
        boundaryRange={1}
        totalCount={Number(count)}
        showFirstAndLastNav
        limit={Number(filters.limit)}
        offset={filters.offset}
        showPreviousAndNextNav
        count={joinedMissions.length}
        activePage={activePageTable}
        data={joinedMissions}
        columns={columns}
        renderItem={item => (
          <Item
            item={item}
            key={item.uid}
            onOpenModalUsersNearby={onOpenModalUsersNearby}
            onClickCurrentParticipants={onClickCurrentParticipants}
          />
        )}
        isLoading={loadingMissions}
        handleColumnSort={handleColumnSort}
        hasText={orderByLabel[filters.kind]}
        handlePaginationChange={handlePaginationChange}
        emptyText={{
          icon: 'world',
          text: 'Nenhuma Missão foi encontrada...',
        }}
      />
      <UsersNearby
        open={open}
        mission={mission}
        loading={loadingUsers}
        usersNearby={usersNearby}
        onSubmitMore={onSubmitMore}
        onFilterUsers={onFilterUsers}
        close={() => {
          setUsersNearby([])
          setLimit(50)
          setOpen(false)
        }}
      />
      <CurrentParticipants
        data={currentParticipants}
        open={showCurrentParticipants}
        onClose={() => setShowCurrentParticipants(false)}
      />
    </>
  )
}

export default withRouter(MissionsOpen)
