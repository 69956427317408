import React from 'react'
import { useHistory } from 'react-router-dom'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Dropdown, Label, Menu, Table } from 'semantic-ui-react'

import {
  mappedLoanRequestStatus,
  creditProfileStatus,
  loanRequestStatus,
  mappedCreditProfileStatus,
  mappedCreditProfileStatusColor,
} from '~/utils/types'

const Item = ({
  item,
  index,
  uploadContract,
  approveCreditProfile,
  reproveCreditProfile,
  selectLoanRequestToApprove,
  selectLoanRequestToShowDocuments,
}) => {
  const history = useHistory()

  const isEnableApproveOption =
    item.credit_profile_status === creditProfileStatus.PENDING
  const isEnableReproveOption =
    item.credit_profile_status !== creditProfileStatus.APPROVED &&
    item.credit_profile_status !== creditProfileStatus.REPROVED
  const isEnableUploadSignedContractOption =
    item.credit_profile_status === creditProfileStatus.APPROVED &&
    item.has_signed_contract_url === false
  const isEnableSeeInstallmentsOption =
    item.credit_profile_status === creditProfileStatus.APPROVED &&
    item.has_signed_contract_url === true
  const isEnableApproveLoanOption =
    isEnableSeeInstallmentsOption && item.status === loanRequestStatus.PENDING

  return (
    <Table.Row>
      <Table.Cell>{item.plan_name}</Table.Cell>
      <Table.Cell>{item.username}</Table.Cell>
      <Table.Cell>
        {dayjs(item.created_at).format('DD/MM/YYYY HH:mm:ss')}
      </Table.Cell>
      <Table.Cell>
        <Label size="big" color={mappedCreditProfileStatusColor[item.status]}>
          {mappedLoanRequestStatus[item.status]}
        </Label>
      </Table.Cell>

      <Table.Cell>
        <Label
          size="big"
          color={mappedCreditProfileStatusColor[item.credit_profile_status]}
        >
          {mappedCreditProfileStatus[item.credit_profile_status]}
        </Label>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Menu compact>
          <Dropdown simple item text="Opções">
            <Dropdown.Menu className="left">
              {isEnableApproveOption && (
                <Dropdown.Item
                  text="Aprovar perfil de crédito do usuário"
                  icon="check"
                  onClick={() =>
                    approveCreditProfile(item.credit_profile_id, index)
                  }
                />
              )}
              {isEnableReproveOption && (
                <Dropdown.Item
                  text="Reprovar perfil de crédito do usuário"
                  icon="close"
                  onClick={() =>
                    reproveCreditProfile(item.credit_profile_id, index)
                  }
                />
              )}
              {isEnableUploadSignedContractOption && (
                <Dropdown.Item
                  icon="upload"
                  text="Carregar Contrato Assinado"
                  onClick={() => uploadContract(item.credit_profile_id, index)}
                />
              )}
              <Dropdown.Item
                text="Ver os documentos do usuário"
                icon="file"
                onClick={() =>
                  selectLoanRequestToShowDocuments(
                    item.credit_profile_id,
                    index
                  )
                }
              />
              {isEnableSeeInstallmentsOption && (
                <Dropdown.Item
                  text="Ver as parcelas"
                  icon="list"
                  onClick={() =>
                    history.push(
                      `/financial/multipliers/requests/${item.id}/installments`
                    )
                  }
                />
              )}
              {isEnableApproveLoanOption && (
                <Dropdown.Item
                  text="Aprovar multiplicador"
                  icon="check"
                  onClick={() => selectLoanRequestToApprove(item, index)}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  item: t.objectOf(t.any),
  index: t.number,
  uploadContract: t.func,
  approveCreditProfile: t.func,
  reproveCreditProfile: t.func,
  selectLoanRequestToApprove: t.func,
  selectLoanRequestToShowDocuments: t.func,
}

export { Item }
