import React from 'react'

import t from 'prop-types'
import { Button, Form, Header, Modal } from 'semantic-ui-react'

function CreateForm({ showModal, type, dataForms, onClose, onChange, submit }) {
  const typeHeader = type === 'create' ? 'Criar' : 'Atualizar'

  return (
    <Modal open={showModal} onClose={onClose}>
      <Modal.Header>
        <Header size="large">{typeHeader} Formulário</Header>
      </Modal.Header>

      <Modal.Content>
        <Form size="large">
          <Form.Input
            label="Nome do Formulário"
            type="text"
            id="name"
            value={dataForms?.name || ''}
            onChange={(e, { value }) => onChange('name', value)}
            placeholder="Nome do Fomulário"
          />

          <Form.Input
            label="Link"
            type="text"
            id="link"
            value={dataForms?.link || ''}
            onChange={(e, { value }) => onChange('link', value)}
            placeholder="Link (opcional)"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          size="large"
          color="black"
          content="Cancelar"
          onClick={onClose}
        />
        {type === 'create' ? (
          <Button size="large" positive content="Criar" onClick={submit} />
        ) : (
          <Button
            size="large"
            color="orange"
            content="Atualizar"
            onClick={submit}
          />
        )}
      </Modal.Actions>
    </Modal>
  )
}

CreateForm.propTypes = {
  type: t.string,
  showModal: t.bool,
  onClose: t.func,
  onChange: t.func,
  submit: t.func,
  dataForms: t.objectOf(t.any),
}

export default CreateForm
