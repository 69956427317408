import {
  MASTER_REGISTERS,
  REGISTERS_FEEDS,
  REGISTERS_UPDATE,
  REGISTERS_DETAIL,
  REGISTERS_FILTER,
} from '~/services/api/endpoints'
import { get, put } from '~/utils/actionsRequests'

const findById = id =>
  get({
    url: `${REGISTERS_DETAIL}${id}`,
    typeFetch: 'REGISTERS',
    typeError: 'REGISTERS_FAIL',
    typeResponse: 'REGISTERS_OK',
  })

const getAll = queryString =>
  get({
    url: `${MASTER_REGISTERS}?${queryString}`,
    typeFetch: 'REGISTERS_ALL',
    typeError: 'REGISTERS_ALL_FAIL',
    typeResponse: 'REGISTERS_ALL_OK',
  })

const filter = ({ customerId, period, year }) =>
  get({
    url: `${REGISTERS_FILTER}${customerId}?period=${period}&year=${year}`,
    typeFetch: 'REGISTERS_FILTER',
    typeError: 'REGISTERS_FILTER_FAIL',
    typeResponse: 'REGISTERS_FILTER_OK',
  })

const updateStatus = data =>
  put({
    data,
    url: REGISTERS_UPDATE,
    typeFetch: 'UPDATE_REGISTER_STATUS',
    typeError: 'UPDATE_REGISTER_STATUS_FAIL',
    typeResponse: 'UPDATE_REGISTER_STATUS_OK',
  })

const findFeeds = id =>
  get({
    url: `${REGISTERS_FEEDS}${id}`,
    typeFetch: 'REGISTERS_FEEDS',
    typeError: 'REGISTERS_FEEDS_FAIL',
    typeResponse: 'REGISTERS_FEEDS_OK',
  })

export { filter, getAll, findById, findFeeds, updateStatus }
