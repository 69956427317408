import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useTransferRequests() {
  const [loadingTransfer, setLoading] = useState(true)
  const [transferRequests, setTransferRequests] = useState([])
  const [transferCount, setTransferRequestsCount] = useState(0)

  const getTransferRequests = useCallback(async params => {
    try {
      setLoading(true)
      const res = await api.get('transfer-requests/admin', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      console.log(res.data)

      if (res.data.length > 0) {
        setTransferRequestsCount(res.data[0].total_rows)
      }

      return setTransferRequests(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  async function onUpdateTransferRequest(params) {
    try {
      setLoading(true)
      const res = await api.put(`transfer-requests/admin`, params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Transferência atualizada com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    transferCount,
    transferRequests,
    loadingTransfer,
    getTransferRequests,
    onUpdateTransferRequest,
  }
}
