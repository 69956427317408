import React, { useState } from 'react'

import { Card, Icon, Form, Button } from 'semantic-ui-react'

import useMissionsMainGroups from '~/hooks/useMissionsMainGroups'

const CreateMissionsMainGroups = () => {
  const [description, setDescription] = useState(null)
  const { onCreateMissionsMainGroups, loadingGroups } = useMissionsMainGroups()

  return (
    <Card centered fluid style={{ marginTop: 10 }}>
      <Card.Content>
        <Form>
          <Form.Input
            id="groupName"
            type="text"
            value={description || ''}
            placeholder="Digite o nome do grupo"
            onChange={(e, { value }) => setDescription(value)}
          />
          <Button
            icon
            positive
            labelPosition="left"
            loading={loadingGroups}
            disabled={loadingGroups}
            onClick={() => onCreateMissionsMainGroups({ description })}
          >
            <Icon name="check" /> Salvar
          </Button>
        </Form>
      </Card.Content>
    </Card>
  )
}

export default CreateMissionsMainGroups
