import queryString from 'query-string'

export function objectToQueryString(paramsObj) {
  try {
    const parsedParams = {}

    Object.entries(paramsObj).forEach(([key, value]) => {
      if (Object.prototype.toString.call(value) === '[object Date]') {
        parsedParams[key] = new Date(value).getTime()
      }
    })

    const validParams = { ...paramsObj, ...parsedParams }

    const query = queryString.stringify(validParams, {
      skipNull: true,
      arrayFormat: 'bracket',
    })

    return query
  } catch (error) {
    return null
  }
}

export function queryStringToObject(stringParams) {
  return (
    queryString.parse(stringParams, {
      arrayFormat: 'bracket',
    }) || {}
  )
}

export function isJSON(stringValue) {
  return /^\s*(\{)/.test(stringValue)
}
