export default {
  customers: 'Cliente',
  categories: 'Categoria',
  sunday: 'Domingo',
  monday: 'Segunda-Feira',
  tuesday: 'Terça-Feira',
  wednesday: 'Quarta-Feira',
  thursday: 'Quinta-Feira',
  friday: 'Sexta-Feira',
  saturday: 'Sábado',
  daily: 'Diário',
  monthly: 'Mensal',
  month: 'Mensal',
  weekly: 'Semanal',

  columns: {
    'Criado Em': 'm.created_at',
    'Expira Em': 'm.expired_at',
  },

  weekValue: {
    0: 'Domingo',
    1: 'Segunda-Feira',
    2: 'Terça-Feira',
    3: 'Quarta-Feira',
    4: 'Quinta-Feira',
    5: 'Sexta-Feira',
    6: 'Sábado',
  },
}
