import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

function useUsersPointsTransactions() {
  const [loading, setLoading] = useState(false)
  const [pointsTransactions, setPointsTransactions] = useState([])
  const [totalPointsTransactions, setTotalPointsTransactions] = useState(0)

  async function getPointsTransactions(params) {
    try {
      setLoading(true)

      const res = await api.get('users-points-transactions/admin', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setTotalPointsTransactions(res.data.count)
      return setPointsTransactions(res.data.transactions)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    getPointsTransactions,
    loading,
    pointsTransactions,
    totalPointsTransactions,
  }
}

export default useUsersPointsTransactions
