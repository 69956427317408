import React from 'react'

import t from 'prop-types'
import { Button, Modal } from 'semantic-ui-react'

function RemoveCategory({ showModal, onClose, categoryData, submitRemove }) {
  return (
    <Modal open={showModal} onClose={onClose} size="small">
      <Modal.Header>
        <h1>Remover Categoria</h1>
      </Modal.Header>
      <Modal.Content>
        <h2>Você deseja excluir a categoria abaixo?</h2>
        <p>
          <strong>Nome:</strong> {categoryData?.title}
        </p>
        <p>
          <strong>Descrição:</strong> {categoryData?.description}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button size="big" color="black" onClick={onClose} content="Cancelar" />
        <Button
          size="big"
          color="red"
          onClick={submitRemove}
          content="Remover Categoria"
        />
      </Modal.Actions>
    </Modal>
  )
}

RemoveCategory.propTypes = {
  showModal: t.bool,
  onClose: t.func,
  categoryData: t.objectOf(t.any),
  submitRemove: t.func,
}

export default RemoveCategory
