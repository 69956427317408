import React, { useState } from 'react'

import t from 'prop-types'
import { Form, Modal, Button } from 'semantic-ui-react'

const CategoryGrouping = ({
  close,
  openModal,
  loading,
  groups,
  onSubmitGroupId,
}) => {
  const [groupId, setGroupId] = useState(null)

  return (
    <Modal size="small" open={openModal} onClose={close}>
      <Modal.Header>Vincular subcategoria</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Select
            options={
              groups.length
                ? groups.map(group => ({
                    key: group.id,
                    value: group.uid,
                    text: group.description,
                  }))
                : []
            }
            label="Grupos"
            loading={loading}
            placeholder="Selecione um grupo"
            onChange={(e, { value }) => setGroupId(value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancelar" onClick={close} />
        <Button
          positive
          icon="checkmark"
          loading={loading}
          content="Vincular"
          labelPosition="right"
          disabled={groupId === null}
          onClick={() => onSubmitGroupId(groupId)}
        />
      </Modal.Actions>
    </Modal>
  )
}

CategoryGrouping.propTypes = {
  close: t.func,
  openModal: t.bool,
  onSubmitGroupId: t.func,
  loading: t.bool,
  groups: t.arrayOf(t.any),
}

export default CategoryGrouping
