import React from 'react'

import t from 'prop-types'
import { Button, List, Form, Modal } from 'semantic-ui-react'

const ModalAddress = ({
  open,
  item,
  close,
  address,
  addresses,
  changeAddress,
  handleAddress,
  loadingAddresses,
}) => (
  <Modal size="tiny" open={open} onClose={close}>
    <Modal.Header>Alterar Endereço</Modal.Header>
    <Modal.Content>
      <Form.Input
        fluid
        type="text"
        value={address}
        loading={loadingAddresses}
        placeholder="Ex: Florianópolis"
        onChange={(e, { value }) => handleAddress(value)}
      />
      {!loadingAddresses && addresses.length > 0 && (
        <List selection verticalAlign="middle">
          {addresses.map((i, index) => {
            const { lat, lng } = i.geometry.location
            const data = {
              location: { lat, lng },
              formattedAddress: i.formatted_address,
            }
            return (
              <List.Item
                key={String(index)}
                onClick={() => changeAddress({ data, item })}
              >
                <List.Icon style={{ color: 'red' }} name="marker" />
                <List.Content>
                  <List.Header as="a">{i.formatted_address}</List.Header>
                </List.Content>
              </List.Item>
            )
          })}
        </List>
      )}
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={close}>
        Cancelar
      </Button>
      <Button
        positive
        content="OK"
        onClick={close}
        icon="checkmark"
        labelPosition="right"
      />
    </Modal.Actions>
  </Modal>
)

ModalAddress.propTypes = {
  open: t.bool,
  item: t.objectOf(t.any),
  close: t.func,
  address: t.string,
  addresses: t.arrayOf(t.any),
  changeAddress: t.func,
  handleAddress: t.func,
  loadingAddresses: t.bool,
}

export default ModalAddress
