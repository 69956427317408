import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useSkillsTags() {
  const [userSkills, setUserSkills] = useState([])

  async function getUsersSkills(params) {
    try {
      const res = await api.get('skills-tags/admin', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setUserSkills(res.data)
    } catch (error) {
      return Message().error(error.message)
    }
  }

  return {
    getUsersSkills,
    userSkills,
  }
}
