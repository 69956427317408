import React from 'react'

import { format } from 'date-fns'
import t from 'prop-types'
import { Table, Button, Popup } from 'semantic-ui-react'

const Item = ({ item, index, openDetail, openRemoveId }) => (
  <Table.Row key={`index-${index}`}>
    <Table.Cell>{item.id}</Table.Cell>
    <Table.Cell>{item.description}</Table.Cell>
    <Table.Cell>{item.user_value}</Table.Cell>
    <Table.Cell>{item.user_value_prime}</Table.Cell>
    <Table.Cell>{item.user_value_black}</Table.Cell>
    <Table.Cell>{item.customer_value}</Table.Cell>
    <Table.Cell>
      {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
    </Table.Cell>
    <Table.Cell>
      {format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
    </Table.Cell>
    <Table.Cell>
      <Button icon="search" onClick={() => openDetail(item)} />
      <Popup
        inverted
        content="Remover"
        trigger={
          <Button
            icon="trash"
            negative
            onClick={() => openRemoveId(item.uid)}
          />
        }
      />
    </Table.Cell>
  </Table.Row>
)

Item.propTypes = {
  item: t.objectOf(t.any),
  index: t.number,
  openDetail: t.func,
  openRemoveId: t.func,
}

export { Item }
