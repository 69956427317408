import { Card, Form } from 'semantic-ui-react'
import styled from 'styled-components'

export const ButtonsWrapper = styled(Card.Content)`
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;

    > button + button {
      margin-top: 10px !important;
    }
  }
`

export const InputField = styled(Form.Field)`
  input {
    width: 100% !important;
  }
`
