import React, { useState } from 'react'

import { Button, Dropdown, Table } from 'semantic-ui-react'

import useMultiplier from '~/hooks/useMultiplier'

import FormPlan from './components/Form/index'

export default function Plans() {
  const [enableForm, setEnableForm] = useState(false)
  const { plans, savePlan, enablePlan, disablePlan } = useMultiplier()

  const toggleEnableOrDisableForm = () => {
    setEnableForm(!enableForm)
  }

  const renderPlans = () => {
    return plans.map((item, index) => {
      const isActive = item.status === true ? 'Sim' : 'Não'
      return (
        <Table.Row>
          <Table.Cell>{item.description}</Table.Cell>
          <Table.Cell>{item.fees}%</Table.Cell>
          <Table.Cell singleLine>{item.multipler}</Table.Cell>
          <Table.Cell>{item.installments}</Table.Cell>
          <Table.Cell>{isActive}</Table.Cell>
          <Table.Cell>
            <Dropdown
              icon="ellipsis vertical"
              style={{ marginLeft: 10, color: '#b2b3d1' }}
            >
              <Dropdown.Menu className="left">
                {item.status === true && (
                  <Dropdown.Item
                    text="Desabilitar plano"
                    icon="close"
                    onClick={() => disablePlan(item.id, index)}
                  />
                )}
                {item.status === false && (
                  <Dropdown.Item
                    text="Habilitar plano"
                    icon="check"
                    onClick={() => enablePlan(item.id, index)}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  return (
    <>
      <h1 className="ui header">Planos de multiplicador</h1>
      <Button
        color={enableForm ? 'red' : 'primary'}
        onClick={() => toggleEnableOrDisableForm()}
      >
        {enableForm ? 'Fechar' : 'Criar'}
      </Button>
      {enableForm && (
        <FormPlan
          savePlans={savePlan}
          toggleEnableOrDisableForm={toggleEnableOrDisableForm}
        />
      )}

      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Descrição</Table.HeaderCell>
            <Table.HeaderCell>Juros</Table.HeaderCell>
            <Table.HeaderCell>Valor do Multiplicador</Table.HeaderCell>
            <Table.HeaderCell>Parcelas</Table.HeaderCell>
            <Table.HeaderCell>Está ativo?</Table.HeaderCell>
            <Table.HeaderCell>Ações</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{renderPlans()}</Table.Body>
      </Table>
    </>
  )
}
