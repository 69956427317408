import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Table } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useUsersPointsTransactions from '~/hooks/useUsersPointsTransactions'

export function PointsTransactions({ userId }) {
  const {
    getPointsTransactions,
    loading,
    pointsTransactions,
    totalPointsTransactions,
  } = useUsersPointsTransactions()

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 15,
    activePage: 1,
  })

  const columns = ['Descrição', 'Valor', 'Status', 'Criado em']
  const renderItem = item => (
    <Table.Row
      key={item.created_at}
      positive={item.event_type === 'CREDIT'}
      negative={item.event_type === 'DEBIT'}
    >
      <Table.Cell
        style={{
          fontWeight: 'bold',
        }}
      >
        {item.description}
      </Table.Cell>
      <Table.Cell>{item.value}</Table.Cell>
      <Table.Cell>{item.status}</Table.Cell>
      <Table.Cell>
        {dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
    </Table.Row>
  )

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * pagination.limit

    setPagination(prevState => ({
      ...prevState,
      activePage,
      offset,
    }))

    return getPointsTransactions({
      userId,
      offset,
      limit: pagination.limit,
    })
  }

  useEffect(() => {
    getPointsTransactions({ userId, ...pagination })
  }, []) // eslint-disable-line

  return (
    <TableComponent
      isLoading={loading}
      showEllipsis
      showFirstAndLastNav
      showPreviousAndNextNav
      columns={columns}
      data={pointsTransactions}
      renderItem={item => {
        console.log(item)
        return renderItem(item)
      }}
      count={Number(totalPointsTransactions)}
      totalCount={Number(totalPointsTransactions)}
      activePage={pagination.activePage}
      limit={pagination.limit}
      offset={pagination.offset}
      handlePaginationChange={handlePaginationChange}
      emptyText={{
        icon: 'list',
        text: 'Nenhuma Transação de Pontos foi Encontrada',
      }}
    />
  )
}

PointsTransactions.propTypes = {
  userId: t.string,
}
