import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Button, Card, Grid, Loader } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import EmptyList from '~/components/EmptyList'
import useUsersBadges from '~/hooks/useUsersBadges'

import Main from '../../Main'
import { Item } from './Item'

export default function UserBadges(props) {
  const userId = useParams().id
  const history = useHistory()
  const [limit, setLimit] = useState(10)
  const [loadMore, setLoadMore] = useState(false)

  const {
    usersBadges,
    getUsersBadges,
    loadingUsersBadges,
    onCreateUsersBadges,
    onRemoveUsersBadges,
  } = useUsersBadges()

  function onCreate(id) {
    return onCreateUsersBadges({
      userId,
      score: 100,
      badgeId: id,
      active: true,
    })
  }

  function onRemove(id) {
    return onRemoveUsersBadges({ id, userId })
  }

  async function onLoadingMore() {
    setLoadMore(true)
    setLimit(prev => prev + 10)

    await getUsersBadges({ userId, limit: limit + 10 })

    return setLoadMore(false)
  }

  useEffect(() => {
    getUsersBadges({ userId })
  }, []) //eslint-disable-line

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      name: 'Usuários',
      link: '/users',
    },
    {
      active: false,
      hasLink: false,
      name: 'Nome',
      link: `/user/${userId}`,
    },
    {
      active: true,
      hasLink: false,
      name: 'Badges',
    },
  ]

  return (
    <Main {...props}>
      <Breadcrumbs items={breadcrumbsList} history={history} />

      {loadingUsersBadges && !loadMore ? (
        <Loader active inline="centered" />
      ) : null}

      {!loadingUsersBadges && usersBadges.length === 0 ? (
        <EmptyList icon="shield" text="Nenhum Badge..." />
      ) : null}

      <Card.Group>
        {usersBadges.length > 0
          ? usersBadges.map((i, k) => (
              <Item
                badge={i}
                key={String(k)}
                onCreate={onCreate}
                onRemove={onRemove}
                loading={loadingUsersBadges}
              />
            ))
          : null}
      </Card.Group>

      <Grid centered columns={5}>
        <Grid.Column>
          <Button
            content="Carregar mais..."
            onClick={onLoadingMore}
            loading={loadMore && loadingUsersBadges}
            disabled={loadMore && loadingUsersBadges}
          />
        </Grid.Column>
      </Grid>
    </Main>
  )
}
