import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { Modal, Button, Form } from 'semantic-ui-react'

import ContainerLoading from '~/components/Loading/ContainerLoading'

export default function ReproveCreditProfileModal({
  reproveLoanCreditProfile,
  openReproveCreditProfile,
  setOpenReproveCreditProfile,
  setCreditProfileSelected,
  creditProfileSelected,
}) {
  const [motivationReproved, setMotivationReproved] = useState('')
  const [disabledConfirmButtom, setDisabledConfirmButtom] = useState(false)

  const confirmCreditProfileReproved = async () => {
    setDisabledConfirmButtom(true)
    await reproveLoanCreditProfile(
      creditProfileSelected.id,
      creditProfileSelected.index,
      motivationReproved
    )
    setMotivationReproved('')
    setDisabledConfirmButtom(false)
    setOpenReproveCreditProfile(false)
  }

  const closeModelReproveCreditProfile = () => {
    setOpenReproveCreditProfile(false)
    setCreditProfileSelected({})
  }

  return (
    <Modal
      size="tiny"
      onClose={() => setOpenReproveCreditProfile(false)}
      onOpen={() => setOpenReproveCreditProfile(true)}
      open={openReproveCreditProfile}
    >
      <Modal.Header>
        Você deseja reprovar o perfil de crédito do usuário?
      </Modal.Header>
      <Modal.Content>
        <ContainerLoading
          isLoading={disabledConfirmButtom}
          customText="Processando..."
        >
          <Modal.Description>
            <Form>
              <Form.Field>
                <label>Motivo da reprovação:</label>
                <textarea
                  value={motivationReproved}
                  onChange={event => setMotivationReproved(event.target.value)}
                />
              </Form.Field>
            </Form>
          </Modal.Description>
        </ContainerLoading>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => closeModelReproveCreditProfile()}>
          Cancelar
        </Button>
        <Button
          primary
          disabled={disabledConfirmButtom}
          onClick={() => confirmCreditProfileReproved()}
        >
          Confirmar
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

ReproveCreditProfileModal.propTypes = {
  reproveLoanCreditProfile: PropTypes.func,
  openReproveCreditProfile: PropTypes.func,
  setOpenReproveCreditProfile: PropTypes.func,
  setCreditProfileSelected: PropTypes.func,
  creditProfileSelected: PropTypes.func,
}
