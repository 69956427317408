import React from 'react'

import t from 'prop-types'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { Card, Icon } from 'semantic-ui-react'

import ContainerLoading from '~/components/Loading/ContainerLoading/index'

import { ContainerDiv, LargeText, MarginTop10 } from '../styles'

function LinearGraphic({
  title,
  data,
  columnY,
  columnX,
  customTooltip,
  reference,
  isLoading,
}) {
  const hasRegisters = () => {
    return data.length > 0 && isLoading === false
  }

  return (
    <Card fluid>
      <Card.Content>
        <h2 className="center aligned">{title}</h2>
        <ContainerDiv>
          <ContainerLoading
            isLoading={isLoading}
            customText="Carregando as informações..."
          >
            <>
              {!hasRegisters() && (
                <LargeText className="center aligned">
                  <Icon name="line graph" />
                  &nbsp;
                  <MarginTop10>Não há informações disponível.</MarginTop10>
                </LargeText>
              )}
              {hasRegisters() && (
                <ResponsiveContainer width="95%" height={200}>
                  <LineChart
                    height={200}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 40,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={columnX} angle={-45} textAnchor="end" />
                    <YAxis dataKey={columnY} />
                    {customTooltip && <Tooltip content={customTooltip} />}
                    {!customTooltip && <Tooltip />}
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey={reference}
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </>
          </ContainerLoading>
        </ContainerDiv>
      </Card.Content>
    </Card>
  )
}

LinearGraphic.propTypes = {
  title: t.string,
  data: t.arrayOf(t.objectOf(t.any)),
  columnY: t.string,
  columnX: t.string,
  customTooltip: t.func,
  reference: t.string,
  isLoading: t.bool,
}

export default LinearGraphic
