import React, { useEffect, useState } from 'react'

import { Card, Container, Grid, Statistic } from 'semantic-ui-react'

import Loading from '~/components/Loading'
import { ModalMissionsUsers } from '~/components/ModalMissionsUsers'
import TableComponent from '~/components/Table'
import useSubscribes from '~/hooks/useSubscribes'

import { Filter } from './Filter'
import { TableRow } from './TableRow'

const columns = [
  '',
  'Nome',
  'E-mail',
  'Telefone',
  'Status',
  'Plano',
  'Pagos',
  'Missões Iniciadas',
  'Criado Em',
  'Cancelado Em',
  '',
]

const defaultFilters = {
  offset: 0,
  limit: 100,
  name: '',
  status: 'active',
  xls: 'false',
  started_at: null,
  finished_at: null,
  activePage: 1,
}

export default function Subscribes() {
  const [open, setOpen] = useState(false)
  const [userIdSelected, setUserIdSelected] = useState(null)
  const [filters, setFilters] = useState(defaultFilters)
  const {
    subscribes,
    subscribesCount,
    getSubscribes,
    getDashboardSubscribes,
    dashboard,
    loadingDash,
    loadingSubs,
  } = useSubscribes()

  function onSubmitFilter() {
    return getSubscribes(filters)
  }

  function onSubmitReport() {
    return getSubscribes({ ...filters, xls: 'true' })
  }

  function onClearFilter() {
    setFilters(defaultFilters)

    return getSubscribes(defaultFilters)
  }

  function onOpenMissions(userId) {
    setUserIdSelected(userId)

    return setOpen(true)
  }

  function onSort(value) {
    console.log(value)
  }

  function onPageChange(page) {
    console.log(page)
  }

  function onChangeFilters(item) {
    return setFilters(prev => ({ ...prev, ...item }))
  }

  useEffect(() => {
    Promise.all([getSubscribes(defaultFilters), getDashboardSubscribes()])
  }, []) //eslint-disable-line

  const cards = [
    {
      title: 'Assinaturas Ativas',
      value: dashboard?.activeSubs,
    },
    {
      title: 'Cancelamentos Agendados',
      value: dashboard?.scheduledCancelSubs,
    },
    {
      title: 'Churn',
      value: dashboard?.cancelSubs,
    },
  ]

  return (
    <Container fluid>
      <Grid columns={3}>
        <Grid.Row>
          {!loadingSubs &&
            cards.map(card => (
              <Grid.Column key={card.title}>
                <Card fluid>
                  <Card.Content textAlign="center">
                    {loadingDash ? (
                      <Loading />
                    ) : (
                      <Statistic>
                        <Statistic.Label>{card.title}</Statistic.Label>
                        <Statistic.Value>{card.value}</Statistic.Value>
                      </Statistic>
                    )}
                  </Card.Content>
                </Card>
              </Grid.Column>
            ))}
        </Grid.Row>
      </Grid>

      <Filter
        filters={filters}
        onChange={onChangeFilters}
        onSubmit={onSubmitFilter}
        clearFilters={onClearFilter}
        onSubmitReport={onSubmitReport}
      />

      <TableComponent
        showEllipsis
        data={subscribes}
        siblingRange={1}
        boundaryRange={0}
        columns={columns}
        showFirstAndLastNav
        showPreviousAndNextNav
        limit={filters?.limit}
        count={subscribesCount}
        isLoading={loadingSubs}
        offset={filters?.offset}
        totalCount={subscribesCount}
        handleColumnSort={onSort}
        activePage={filters.activePage}
        handlePaginationChange={onPageChange}
        renderItem={item => (
          <TableRow
            key={item.name}
            item={item}
            onOpenMissions={onOpenMissions}
          />
        )}
        emptyText="Nenhuma Assinatura até o momento..."
      />

      {userIdSelected ? (
        <ModalMissionsUsers
          open={open}
          userId={userIdSelected}
          onClose={() => {
            setUserIdSelected(null)
            setOpen(false)
          }}
        />
      ) : null}
    </Container>
  )
}
