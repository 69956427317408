/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react'

import t from 'prop-types'
import { Button, Input, Modal, List, Image, Label } from 'semantic-ui-react'

import useMissionsManagement from '~/hooks/useMissionsManagement'

import EmptyList from '../EmptyList'

export function ModalMissionsUsers({ userId, onClose, open }) {
  const importButton = useRef()
  const [selected, setSelected] = useState([])
  const initialFilter = { userId, name: '', limit: 10 }
  const [filters, setFilters] = useState(initialFilter)
  const {
    onCreateMissionsUsers,
    missions,
    getMissions,
    loadingMissions,
    onImportMissions,
  } = useMissionsManagement()

  function onChange({ target }) {
    return setFilters(prev => ({ ...prev, name: target.value }))
  }

  function onSelected(item) {
    const find = selected.find(i => i === item)

    if (find) {
      return setSelected(selected.filter(i => i !== find))
    }

    return setSelected(prev => [...prev, item])
  }
  function onCreate() {
    onClose()

    return onCreateMissionsUsers({
      userId,
      missionsIds: selected,
    })
  }

  function onSubmitFilter() {
    return getMissions(filters)
  }

  function onClear() {
    setFilters(initialFilter)

    return getMissions(initialFilter)
  }

  useEffect(() => {
    getMissions(filters)
  }, []) //eslint-disable-line

  return (
    <Modal onClose={onClose} open={open}>
      <Modal.Content scrolling>
        <Modal.Description>
          <>
            <Input
              fluid
              type="text"
              id="name"
              autoCapitalize="none"
              autoCorrect="off"
              onChange={onChange}
              placeholder="Digite o nome da missão"
            />
            <div style={{ marginTop: 10 }}>
              <Button
                color="blue"
                onClick={onSubmitFilter}
                loading={loadingMissions}
                disabled={loadingMissions}
              >
                Filtrar
              </Button>
              <Button
                color="orange"
                loading={loadingMissions}
                disabled={loadingMissions}
                onClick={() => importButton.current.click()}
              >
                Importar
              </Button>
              <Button onClick={onClear}>Limpar</Button>
              <input
                type="file"
                ref={importButton}
                id="upload"
                onChange={e =>
                  onImportMissions({ userId, file: e.target.files[0] })
                }
                style={{ display: 'none' }}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </div>
          </>
          {!loadingMissions && missions.length > 0 ? (
            <List relaxed>
              {missions.map(item => (
                <List.Item key={item.uid}>
                  <List.Content floated="right">
                    <Button
                      active={!selected.find(i => i === item)}
                      onClick={() => onSelected(item.uid)}
                    >
                      {selected.find(i => i === item.uid)
                        ? 'Selecionado'
                        : 'Selecionar'}
                    </Button>
                  </List.Content>
                  <Image avatar src={item.avatar} />
                  <List.Content>
                    <List.Header as="a">
                      <Label color={item.status ? 'green' : 'red'} horizontal>
                        {item.status ? 'Ativa' : 'Inativa'}
                      </Label>
                      {item.name}{' '}
                      {item.distance ? `(${item.distance}km)` : null}
                    </List.Header>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          ) : (
            !loadingMissions && (
              <EmptyList icon="rocket" text="Nenhuma missão foi encontrada" />
            )
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {selected.length > 0 ? (
          <Button primary onClick={onCreate}>
            Adicionar ({selected.length})
          </Button>
        ) : null}

        <Button onClick={() => onClose()}>Cancelar</Button>
      </Modal.Actions>
    </Modal>
  )
}

ModalMissionsUsers.propTypes = {
  onClose: t.func,
  open: t.bool,
  userId: t.string.isRequired,
}
