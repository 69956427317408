export const COLUMNS = [
  'Usuário',
  'Situação da entrega',
  'Valor em dinheiro',
  'Valor em pontos',
  'Total de produtos',
  'Data do pedido',
  'Ações',
]

export const DELIVERY_STATUS = {
  DELIVERED: 'DELIVERED',
  PENDENT: 'PENDENT',
  SENDED: 'SENDED',
  PROBLEM_TO_SEND: 'PROBLEM_TO_SEND',
}

export const DELIVERY_STATUS_TO_DISPLAY = {
  DELIVERED: 'Entregue',
  PENDENT: 'Pendente',
  SENDED: 'Enviado',
  PROBLEM_TO_SEND: 'Problema no envio',
}

export const INITIAL_PAGE = 1
