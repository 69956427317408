import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import dayjs from 'dayjs'
import { Table, Dropdown, Button } from 'semantic-ui-react'

import useChallenge from '~/hooks/useChallenge'

import DeleteModal from './components/DeleteModal'

function TypeChallenge() {
  const history = useHistory()
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [itemSelectedToDelete, setItemSelectedToDelete] = useState({})
  const { typeChallenges, getTypeChallenges, removeTypeChalllenge } =
    useChallenge()

  useEffect(() => {
    getTypeChallenges()
  }, []) //eslint-disable-line

  const openDeleteModal = item => {
    setIsOpenDeleteModal(true)
    setItemSelectedToDelete(item)
  }

  const closeModal = () => {
    setIsOpenDeleteModal(false)
    setItemSelectedToDelete(null)
  }

  const confirmDeleteItemSelected = async () => {
    await removeTypeChalllenge(itemSelectedToDelete.id)
    setItemSelectedToDelete(null)
    closeModal()
  }

  return (
    <>
      <DeleteModal
        isOpenDeleteModal={isOpenDeleteModal}
        closeModal={closeModal}
        confirmDeleteItemSelected={confirmDeleteItemSelected}
      />

      <Link to="/type-challenges/new">
        <Button primary>Novo</Button>
      </Link>

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Nome</Table.HeaderCell>
            <Table.HeaderCell>Criado em</Table.HeaderCell>
            <Table.HeaderCell>Atualizado em</Table.HeaderCell>
            <Table.HeaderCell>Ações</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {typeChallenges.map((item, index) => {
            return (
              <Table.Row key={`item-${item.id}`}>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>
                  {dayjs(item.created_at).format('DD/MM/YYYY HH:mm:ss')}
                </Table.Cell>
                <Table.Cell>
                  {dayjs(item.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                </Table.Cell>
                <Table.Cell>
                  <Dropdown icon="ellipsis vertical">
                    <Dropdown.Menu>
                      <Dropdown.Menu scrolling>
                        <Dropdown.Item
                          onClick={() =>
                            history.push(`/type-challenges/${item.id}/edit`)
                          }
                          icon="pencil"
                          key="editar"
                          text="Editar"
                          value="editar"
                        />
                        <Dropdown.Item
                          onClick={() => openDeleteModal(item, index)}
                          icon="trash"
                          key="deletar"
                          text="Deletar"
                          value="deletar"
                        />
                      </Dropdown.Menu>
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
}

export default TypeChallenge
