import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Menu } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import useCustomers from '~/hooks/useCustomers'
import Main from '~/pages/Main'

import Details from '../components/FormInfo'
import Finance from './components/Finance'
import Subcategories from './components/Subcategories'

export default function CustomerDetail(props) {
  const history = useHistory()
  const { customerId } = useParams()
  const { getCustomers, customers, loadingCustomers, onUpdateCustomer } =
    useCustomers()

  const [customer, setCustomer] = useState({})
  const [activePage, setActivePage] = useState(0)

  const handleTabs = page => {
    setActivePage(page)
  }

  useEffect(() => {
    getCustomers({ customerId })
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!loadingCustomers && customers.length > 0) {
      const { uid, cnpj, name, phone, email, responsible, is_private } =
        customers[0]
      setCustomer({
        uid,
        cnpj,
        name,
        phone,
        email,
        responsible,
        is_private,
        settings: true,
      })
    }
  }, [loadingCustomers, customers]) //eslint-disable-line

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      link: '/customers',
      name: 'Clientes',
    },
    {
      active: true,
      hasLink: false,
      link: `/customers/${customerId}`,
      name: customer?.name,
    },
  ]

  return (
    <Main {...props}>
      <Breadcrumbs items={breadcrumbsList} history={history} />
      {!loadingCustomers && customer !== null && (
        <>
          <Menu secondary pointing color="blue">
            <Menu.Item
              content="Detalhes"
              name="details"
              active={activePage === 0}
              onClick={() => handleTabs(0)}
            />
            <Menu.Item
              content="Dados Financeiros"
              name="payment"
              active={activePage === 1}
              onClick={() => handleTabs(1)}
            />
            <Menu.Item
              content="Sub-categorias"
              name="subcategories"
              active={activePage === 2}
              onClick={() => handleTabs(2)}
            />
          </Menu>

          {activePage === 0 && (
            <Details
              customer={customer}
              loading={loadingCustomers}
              onClick={() => onUpdateCustomer(customer)}
              onChange={(key, value) =>
                setCustomer(prevState => ({ ...prevState, [key]: value }))
              }
            />
          )}

          {activePage === 1 && <Finance customerId={customerId} />}

          {activePage === 2 && <Subcategories customerId={customerId} />}
        </>
      )}
    </Main>
  )
}
