import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useRegisters() {
  const [registers, setRegisters] = useState([])
  const [loadingRegisters, setLoadingRegisters] = useState(false)

  const [register, setRegister] = useState(null)
  const [loadingRegister, setLoadingRegister] = useState(true)
  const [exportRegisters, setExportRegisters] = useState([])
  const [totalExportRegisters, setTotalExportRegisters] = useState(0)

  const getRegisterById = useCallback(async id => {
    try {
      setLoadingRegister(true)

      const res = await api.get(`registers/admin/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setRegister(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingRegister(false)
    }
  }, [])

  const getRegisters = useCallback(async query => {
    try {
      setLoadingRegisters(true)
      const res = await api.get('registers/admin', {
        params: query,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setRegisters(res.data.data)
      return setLoadingRegisters(false)
    } catch (error) {
      setLoadingRegisters(false)
      return Message().error(error.message)
    }
  }, [])

  const getRegistersByUserId = useCallback(async query => {
    try {
      setLoadingRegisters(true)
      const res = await api.get(`registers/admin/users`, {
        params: query,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setRegisters(res.data.registers)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingRegisters(false)
    }
  }, [])

  async function revertApprovedMission(registerId) {
    try {
      setLoadingRegister(true)

      await api.put(`/registers/admin/revert/${registerId}`)

      return getRegisterById(registerId)
    } catch (error) {
      return Message().error(error.response.data.message)
    } finally {
      setLoadingRegister(false)
    }
  }

  async function onUpdateRegister(data) {
    try {
      setLoadingRegister(true)

      const res = await api.put('/registers/admin', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return getRegisterById(data.uid)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingRegister(false)
    }
  }

  async function createRegister(params) {
    try {
      const res = await api.post('/registers/admin', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return Message().success('Missão finalizada com sucesso')
    } catch (error) {
      return Message().error(error.message)
    }
  }

  async function getExportRegisters(params) {
    try {
      setLoadingRegisters(true)
      const res = await api.get(`registers/admin/export-list`, {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setTotalExportRegisters(Number(res.data.count))
      return setExportRegisters(res.data.registers)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingRegisters(false)
    }
  }

  return {
    register,
    registers,
    getRegisters,
    loadingRegister,
    getRegisterById,
    onUpdateRegister,
    revertApprovedMission,
    loadingRegisters,
    getRegistersByUserId,
    createRegister,
    getExportRegisters,
    exportRegisters,
    totalExportRegisters,
  }
}
