import React, { useState } from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Button, Icon, List, Modal } from 'semantic-ui-react'

import { feedLabel, iconColor, feedIcon } from '~/utils/types'

function RemoveModal({ open, onClose, feedData, onSubmitRemove }) {
  const [openConfirm, setOpenConfirm] = useState(false)

  const handleConfirmRemove = () => {
    setOpenConfirm(false)
    return onSubmitRemove(feedData)
  }

  return (
    <>
      <Modal open={open} onClose={onClose} size="mini">
        {console.log({ feedData })}
        <Modal.Header>
          Você tem certeza que quer remover o Feed abaixo?
        </Modal.Header>
        <Modal.Content>
          <List>
            <List.Content>
              <strong>Ação:</strong>{' '}
              <Icon
                color={iconColor[feedData?.action]}
                name={feedIcon[feedData?.action]}
              />
              {feedLabel[feedData?.action]}
            </List.Content>
            <List.Content>
              <strong>Nome:</strong> {feedData?.name}
            </List.Content>
            <List.Content>
              <strong>Data:</strong>{' '}
              {dayjs(feedData?.created_at).format('DD/MM/YYYY HH:mm')}
            </List.Content>
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button primary onClick={() => setOpenConfirm(true)}>
            Confirmar
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal size="tiny" open={openConfirm}>
        <Modal.Header>Confirmação para remoção de Feed</Modal.Header>
        <Modal.Content>
          <p>Você tem certeza que deseja remover esse feed?</p>
          <p> Essa ação não poderá ser revertida!</p>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancelar" onClick={() => setOpenConfirm(false)} />
          <Button
            content="Confirmar Remoção"
            onClick={handleConfirmRemove}
            color="red"
          />
        </Modal.Actions>
      </Modal>
    </>
  )
}

RemoveModal.propTypes = {
  open: t.bool,
  onClose: t.func,
  feedData: t.objectOf(t.any),
  onSubmitRemove: t.func,
}

export default RemoveModal
