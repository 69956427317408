import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { omit } from 'ramda'
import { Button } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import TableComponent from '~/components/Table'
import useBadges from '~/hooks/useBadges'
import useFormsUsers from '~/hooks/useFormsUsers'
import {
  fetch,
  update,
  create,
  createType,
  fetchType,
} from '~/store/modules/formsUsers/actions'
import message from '~/utils/messages'

import Main from '../../Main'
import CreateItem from './components/Create'
import { Item } from './components/Item'

function FormsUsersExtra(props) {
  const {
    fetch,
    create,
    update,
    fetchType,
    createType,
    formsUsers,
    formsUsersType,
    formsUsersCreate,
    formsUsersUpdate,
    formsUsersCreateType,
  } = props
  const [types, setTypes] = useState([])
  const [items, setItems] = useState([])
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formType, setFormType] = useState(null)
  const [item, setItem] = useState({
    title: null,
    badge_id: null,
    description: null,
    form_user_type_id: 1,
  })

  const { getBadges, badges } = useBadges()

  const { onRemoveFormsUsers } = useFormsUsers()

  useEffect(() => {
    fetch()
  }, [fetch])

  useEffect(() => {
    fetchType()
  }, [fetchType])

  useEffect(() => {
    getBadges({ limit: 1000, offset: 0 })
  }, []) //eslint-disable-line

  useEffect(() => {
    if (formsUsersType.success) {
      setTypes(
        formsUsersType.data.map(i => ({
          key: i.id,
          value: i.id,
          text: i.description,
        }))
      )
      formsUsersType.success = false
    }

    if (formsUsers.success) {
      setLoading(false)
      setItems(formsUsers.data)
      formsUsers.success = false
    }

    if (formsUsersCreate.success) {
      setLoading(false)
      fetch()
      message().success('Item criado com sucesso!')
      formsUsersCreate.success = false
    } else if (formsUsersCreate.error) {
      setLoading(false)
      message().error(formsUsersCreate.message)
      formsUsersCreate.error = false
    }

    if (formsUsersUpdate.success) {
      setLoading(false)
      message().success('Item atualizado com sucesso!')
      formsUsersUpdate.success = false
    } else if (formsUsersUpdate.error) {
      setLoading(false)
      message().error(formsUsersUpdate.message)
      formsUsersUpdate.error = false
    }

    if (formsUsersCreateType.success) {
      setLoading(false)
      fetchType()
      message().success('Item criado com sucesso!')
      formsUsersCreateType.success = false
    } else if (formsUsersCreateType.error) {
      setLoading(false)
      message().error(formsUsersCreateType.message)
      formsUsersCreateType.error = false
    }
  }, [
    items,
    fetch,
    fetchType,
    formsUsers,
    formsUsersUpdate,
    formsUsersType,
    formsUsersCreate,
    formsUsersCreateType,
  ])

  function submit() {
    if (!item.title) {
      message().error('Título não pode estar vazio!')
      return false
    }

    if (!item.badge_id) {
      message().error('Badge não pode estar vazio!')
      return false
    }

    if (!item.description) {
      message().error('Descrição não pode estar vazio!')
      return false
    }

    setLoading(true)
    return create(item)
  }

  function submitType() {
    setLoading(true)
    createType({ description: formType })
  }

  function handleInput(value, key) {
    setItem({ ...item, [key]: value })
  }

  function handleFormType(value) {
    setFormType(value)
  }

  function handleUpdate(item) {
    const omitParams = ['customer_name', 'badge_title']
    const index = items.findIndex(i => i === item)
    items[index].status = !item.status

    setItems(items)

    const data = omit(omitParams)(items[index])
    update(data, items[index].uid)
  }

  async function handleRemove(itemForRemove) {
    const removed = await onRemoveFormsUsers(itemForRemove.uid)

    if (removed) {
      removeItem(itemForRemove)
    }
  }

  function removeItem(item) {
    const newItems = items.filter(i => i !== item)
    setItems(newItems)
  }

  const breadcrumbsList = [
    {
      active: true,
      hasLink: false,
      name: 'Formulários',
    },
  ]

  return (
    <Main {...props}>
      <Breadcrumbs items={breadcrumbsList} />

      <Button
        compact
        content="Criar Formulário"
        onClick={() => setShow(e => !e)}
      />

      <CreateItem
        {...item}
        show={show}
        types={types}
        badges={badges.map(i => ({
          key: i.uid,
          value: i.uid,
          text: i.title,
        }))}
        submit={submit}
        loading={loading}
        submitType={submitType}
        handleInput={handleInput}
        handleFormType={handleFormType}
      />

      <TableComponent
        data={items}
        renderItem={(item, index) => (
          <Item
            item={item}
            key={`i-${index}`}
            removeItem={removeItem}
            handleUpdate={handleUpdate}
            handleRemove={handleRemove}
          />
        )}
        isLoading={loading}
        columns={['TITULO', 'DESCRICAO', 'CRIADO EM', 'ATUALIZADO EM', '']}
      />
    </Main>
  )
}

const mapStateToProps = state => ({
  formsUsers: state.formsUsers,
  formsUsersType: state.formsUsersType,
  formsUsersCreate: state.formsUsersCreate,
  formsUsersUpdate: state.formsUsersUpdate,
  formsUsersCreateType: state.formsUsersCreateType,
})

const mapDispatch = {
  fetch,
  create,
  update,
  fetchType,
  createType,
}

export default connect(mapStateToProps, mapDispatch)(FormsUsersExtra)
