import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'

import TableComponent from '~/components/Table'
import usePaymentRequest from '~/hooks/usePaymentRequest'
import { statusPaymentLabel } from '~/utils/feedsActions'
import { formatPrice } from '~/utils/format'

const columns = ['Status', 'Data', 'Valor', 'Obs']

const emptyText = {
  icon: 'check circle outline',
  text: 'Nenhuma transação até o momento...',
}

export function RecentPayments({ userId }) {
  const {
    getPaymentRequestsByUserId,
    loadingPayments,
    paymentCount,
    usersPayments,
  } = usePaymentRequest()
  const [activePage, setActivePage] = useState(1)
  const [filters, setFilters] = useState({ page: 1, itemsPerPage: 15 })

  function handlePaginationChange(_, params) {
    setFilters(prev => ({ ...prev, page: params.activePage }))
    setActivePage(params.activePage)

    return getPaymentRequestsByUserId({
      userId,
      ...filters,
      page: params.activePage,
    })
  }

  useEffect(() => {
    getPaymentRequestsByUserId({ userId, ...filters })
  }, []) //eslint-disable-line

  const renderItem = item => (
    <Table.Row
      key={uuidv4()}
      warning={item.payment_status === 0}
      positive={item.payment_status === 1}
      negative={item.payment_status === 2}
    >
      <Table.Cell
        style={{
          fontWeight: 'bold',
        }}
      >
        {statusPaymentLabel(item.payment_status)}
      </Table.Cell>
      <Table.Cell>
        {dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>{formatPrice(item.value)}</Table.Cell>
      <Table.Cell>{item.obs || '-'}</Table.Cell>
    </Table.Row>
  )

  return (
    <TableComponent
      showEllipsis
      siblingRange={1}
      boundaryRange={0}
      columns={columns}
      showFirstAndLastNav
      count={Number(paymentCount)}
      data={usersPayments}
      emptyText={emptyText}
      showPreviousAndNextNav
      renderItem={renderItem}
      activePage={activePage}
      offset={filters.page - 1}
      limit={filters.itemsPerPage}
      isLoading={loadingPayments}
      handlePaginationChange={handlePaginationChange}
      totalCount={usersPayments.length > 0 ? Number(paymentCount) : 0}
    />
  )
}

RecentPayments.propTypes = {
  userId: PropTypes.string,
}
