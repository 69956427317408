import React from 'react'

import { format } from 'date-fns'
import t from 'prop-types'
import { Table, Popup, Button } from 'semantic-ui-react'

const Item = ({ item, openRemoveId, history }) => (
  <Table.Row>
    <Table.HeaderCell>
      <img alt="avatar" src={item.avatar} style={{ width: 50, height: 30 }} />
    </Table.HeaderCell>
    <Table.Cell>{item.id}</Table.Cell>
    <Table.Cell>{item.name}</Table.Cell>
    <Table.Cell>{item.has_consultant ? 'Sim' : 'Não'}</Table.Cell>
    <Table.Cell>
      {item.available_in === 'public' ? 'Público' : 'Privado'}
    </Table.Cell>
    <Table.Cell>
      {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
    </Table.Cell>
    <Table.Cell>
      {format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
    </Table.Cell>
    <Table.Cell>
      <Popup
        inverted
        trigger={
          <Button
            icon="search"
            onClick={() =>
              history.push(`categories/${item.uid}/sub-categories`, { item })
            }
          />
        }
        content="Ver sub-categorias"
      />
      <Popup
        inverted
        trigger={
          <Button
            icon="pencil"
            className="orange"
            inverted
            onClick={() => history.push(`categories/${item.uid}`, { item })}
          />
        }
        content="Editar"
      />
      <Popup
        inverted
        trigger={
          <Button
            icon="trash"
            negative
            onClick={() => openRemoveId(item.uid)}
          />
        }
        content="Remover"
      />
    </Table.Cell>
  </Table.Row>
)

Item.propTypes = {
  item: t.objectOf(t.any),
  openRemoveId: t.func,
  history: t.objectOf(t.any),
}

export default Item
