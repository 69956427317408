import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'

import {
  Popup,
  Form,
  Button,
  Card,
  Icon,
  Select,
  Grid,
  Image,
} from 'semantic-ui-react'
import * as yup from 'yup'

import useChallenge from '~/hooks/useChallenge'
import Message from '~/utils/messages'

const TYPE_CURRENT_TYPES = [
  { key: '1', value: 'POINTS', text: 'Ponto' },
  { key: '2', value: 'MONEY', text: 'Dinheiro' },
]

const TYPE_RECURRENCES = [
  { key: '1', value: true, text: 'Sim' },
  { key: '2', value: false, text: 'Não' },
]

function Create() {
  const [challenge, setChallenge] = useState({})
  const [isProcessing, setIsProcessing] = useState(false)
  const [selectedTypeChallenge, setSelectedTypeChallenge] = useState(null)
  const [paramsSelectedTypeChallenge, setParamsSelectedTypeChallenge] =
    useState([])
  const { createChallenge, typeChallenges, getTypeChallenges } = useChallenge()

  const renderTypeChallengeOptions = () => {
    return typeChallenges.map((item, index) => {
      return {
        key: index,
        value: item.id,
        text: item.name,
      }
    })
  }

  function selectTypeChallenge(value) {
    handleInputValue('type_challenge_id', value)

    return setSelectedTypeChallenge(value)
  }

  const renderParams = () => {
    if (!challenge.params) {
      challenge.params = {}
    }

    const renderInputByType = {
      INPUT: name => {
        return (
          <input
            value={challenge.params[name]}
            onChange={event => handleParamsInputValue(name, event.target.value)}
          />
        )
      },
      INPUT_NUMBER: name => {
        return (
          <input
            type="number"
            value={challenge.params[name]}
            onChange={event => handleParamsInputValue(name, event.target.value)}
          />
        )
      },
      SELECT: (name, options) => {
        return (
          <Select
            value={challenge.params[name]}
            onChange={(event, data) => handleParamsInputValue(name, data.value)}
            options={options}
          />
        )
      },
    }

    return (paramsSelectedTypeChallenge || []).map(item => {
      const options = item.options || []
      return (
        <Form.Field>
          <label>{item.label}:</label>
          {renderInputByType[item.type](item.name, options)}
        </Form.Field>
      )
    })
  }

  const isEmptyParamsSelectedTypeChallenge = () => {
    return paramsSelectedTypeChallenge.length === 0
  }

  const isEmptySelectedTypeChallenge = () => {
    return selectedTypeChallenge === null
  }

  function handleInputValue(key, value) {
    return setChallenge(prev => ({ ...prev, [key]: value }))
  }

  function handleParamsInputValue(key, value) {
    return setChallenge(prev => ({
      ...prev,
      params: { ...prev.params, [key]: value },
    }))
  }

  const handleFile = acceptedFiles => {
    const file = acceptedFiles[0]
    const isTypeValid = file.type === 'image/jpeg' || file.type === 'image/png'

    if (!isTypeValid) {
      return Message().error(
        'O tipo do arquivo é inválido. Deve fazer o upload de PNG ou JPEG.'
      )
    }

    return handleInputValue('avatar', file)
  }

  const submit = async event => {
    event.preventDefault()

    const paramsRequired = {}

    paramsSelectedTypeChallenge.forEach(item => {
      paramsRequired[item.name] = yup
        .string()
        .required(`Precisa preencher o campo: ${item.label}`)
    })

    const schemaValidation = yup.object().shape({
      title: yup.string().required('Título é obrigatório.'),
      description: yup.string().required('Descrição é obrigatório.'),
      avatar: yup.mixed().required('Imagem de fundo é obrigatório.'),
      currency_type: yup.string().required('Tipo da prêmiação é obrigatório.'),
      value: yup.string().required('Valor da prêmiação é obrigatório.'),
      recurrence: yup
        .bool()
        .required(
          'Você precisa selecionar algum altenativa no campo "Poder fazer o desafio mais 1 vez"'
        ),
      type_challenge_id: yup
        .string()
        .required('Você precisa selecionar um tipo de desafio.'),
      params: yup.object(paramsRequired),
    })

    const isValid = schemaValidation.isValidSync(challenge, {
      abortEarly: false,
    })
    try {
      setIsProcessing(true)
      if (!isValid) {
        schemaValidation.validateSync(challenge, { abortEarly: false })
      }
      const formData = new FormData()
      formData.append('title', challenge.title)
      formData.append('description', challenge.description)
      formData.append('currency_type', challenge.currency_type)
      formData.append('value', challenge.value)
      formData.append('params', JSON.stringify(challenge.params || {}))
      formData.append('type_challenge_id', challenge.type_challenge_id)

      if (challenge.start_at) {
        formData.append('start_at', challenge.start_at)
      }

      if (challenge.end_at) {
        formData.append('end_at', challenge.end_at)
      }

      formData.append('recurrence', challenge.recurrence)
      formData.append('avatar', challenge.avatar)

      await createChallenge(formData)
      const register = {
        title: '',
        description: '',
        currency_type: '',
        value: '',
        params: {},
        type_challenge_id: '',
        recurrence: '',
        avatar: '',
      }

      if (challenge.start_at) {
        register.start_at = ''
      }

      if (challenge.end_at) {
        register.end_at = ''
      }

      setChallenge({ ...register })
      setSelectedTypeChallenge(null)
    } catch (error) {
      Message().errors(error.errors)
    } finally {
      setIsProcessing(false)
    }
  }

  useEffect(() => {
    if (!isEmptySelectedTypeChallenge()) {
      const item = typeChallenges.find(
        challengeItem => challengeItem.id === selectedTypeChallenge
      )

      setParamsSelectedTypeChallenge(item?.params)
    }
  }, [selectedTypeChallenge]) //eslint-disable-line

  useEffect(() => {
    getTypeChallenges(['name', 'params'])
  }, []) //eslint-disable-line

  return (
    <Card fluid>
      <Card.Content>
        <Card.Description>
          <Form>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column width={3}>
                  <Form.Field>
                    <label>Avatar</label>
                    <Dropzone onDrop={handleFile}>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          style={{
                            height: 180,
                            width: '100%',
                            backgroundColor: '#f1f2f3',
                            borderRadius: 30,
                          }}
                        >
                          <div
                            {...getRootProps()}
                            style={{
                              display: 'flex',
                              height: 180,
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'column',
                              cursor: 'pointer',
                            }}
                          >
                            <input {...getInputProps()} />
                            {challenge.avatar ? (
                              <Image
                                src={URL.createObjectURL(challenge.avatar)}
                                style={{ height: 180 }}
                              />
                            ) : (
                              <>
                                <Icon name="cloud upload" size="big" />
                                <p>Adicionar Imagem</p>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={13}>
                  <Form.Field>
                    <label>Título:</label>
                    <input
                      value={challenge.title}
                      onChange={event =>
                        handleInputValue('title', event.target.value)
                      }
                      placeholder="Título"
                    />
                  </Form.Field>

                  <Form.Field>
                    <label>Descrição:</label>
                    <textarea
                      value={challenge.description}
                      onChange={event =>
                        handleInputValue('description', event.target.value)
                      }
                      placeholder="Descrição"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid columns={3}>
              <Grid.Column>
                <Form.Field>
                  <label>Tipo da prêmiação:</label>
                  <Select
                    value={challenge.currency_type}
                    onChange={(_, data) => {
                      handleInputValue('currency_type', data.value)
                    }}
                    options={TYPE_CURRENT_TYPES}
                    placeholder="Selecionar tipo da prêmiação"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Valor da prêmiação:</label>
                  <input
                    value={challenge.value}
                    onChange={event => {
                      handleInputValue('value', event.target.value)
                    }}
                    placeholder="Valor da prêmiação"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>
                    <Popup
                      trigger={
                        <Button style={{ borderRadius: '35px' }} icon="info" />
                      }
                    >
                      <Popup.Content>
                        <p>
                          Se colocar <strong>SIM</strong> significa que a pessoa
                          pode fazer 1 vez por dia.
                        </p>
                        <p>
                          Se o valor for <strong>NÃO</strong> a pessoa poderia
                          fazer a missão uma única vez
                        </p>
                      </Popup.Content>
                    </Popup>
                    &nbsp;Poder fazer o desafio mais 1 vez?
                  </label>
                  <Select
                    value={challenge.recurrence}
                    onChange={(_, data) =>
                      handleInputValue('recurrence', data.value)
                    }
                    options={TYPE_RECURRENCES}
                    placeholder="Valor da prêmiação"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>

            <Form.Field>
              <label>Tipo de desafio:</label>
              <Select
                onChange={(_, data) => selectTypeChallenge(data.value)}
                options={renderTypeChallengeOptions()}
                placeholder="Tipo de desafio"
              />
            </Form.Field>

            <Grid columns={2}>
              <Grid.Column>
                <Form.Field>
                  <label>Data de inicia:</label>
                  <input
                    value={challenge.start_at}
                    onChange={event =>
                      handleInputValue('start_at', event.target.value)
                    }
                    type="datetime-local"
                    placeholder="Data de inicia"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Data de final:</label>
                  <input
                    value={challenge.end_at}
                    onChange={event =>
                      handleInputValue('end_at', event.target.value)
                    }
                    type="datetime-local"
                    placeholder="Data de final"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
            <br />

            {!isEmptySelectedTypeChallenge() &&
              !isEmptyParamsSelectedTypeChallenge() && (
                <Form.Field>
                  <label>Parâmetros customizados:</label>
                  {renderParams()}
                </Form.Field>
              )}

            <Button primary onClick={submit}>
              {isProcessing ? 'Salvando...' : 'Salvar'}
            </Button>
          </Form>
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default Create
