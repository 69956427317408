import React, { useEffect, useState } from 'react'

import t from 'prop-types'
import { Modal, Button, Form } from 'semantic-ui-react'

import useBadges from '~/hooks/useBadges'
import useUsersConsultants from '~/hooks/useUsersConsultants'

export function ModalUsersConsultants({ open, onClose, userId, onChangeItem }) {
  const { getBadges, badges, loadingBadges } = useBadges()
  const { onSubmitUsersConsultants, loadingUsersConsultants } =
    useUsersConsultants()

  const [userConsultant, setUserConsultant] = useState({
    max_users: 0,
    badge_id: null,
  })

  function onChange(key, value) {
    return setUserConsultant(prev => ({ ...prev, [key]: value }))
  }

  async function onSubmit() {
    await onSubmitUsersConsultants({
      status: true,
      is_leader: true,
      user_id: userId,
      ...userConsultant,
    })

    await onChangeItem({ uid: userId }, 'is_leader', true)

    return onClose()
  }

  useEffect(() => {
    getBadges({ limit: 1000, active: true })
  }, []) //eslint-disable-line

  return (
    <Modal size="small" open={open} centered={false}>
      <Modal.Content>
        <h2>Selecione o limite de pessoas do consultor líder</h2>
        <Form>
          <Form.Input
            type="number"
            placeholder="Ex: 10"
            label="Limite de Consultores"
            value={userConsultant.leaderLimit}
            onChange={({ target }) => onChange('max_users', target.value)}
          />
          <Form.Dropdown
            fluid
            search
            selection
            loading={loadingBadges}
            disabled={loadingBadges}
            options={badges.map((i, key) => ({
              key,
              value: i.uid,
              text: i.title,
            }))}
            placeholder="Escolha o badge..."
            onChange={(_, target) => onChange('badge_id', target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Content>
        <Button
          color="grey"
          onClick={onClose}
          content="Cancelar"
          loading={loadingUsersConsultants}
          disabled={loadingUsersConsultants}
        />
        <Button
          color="green"
          content="Ativar"
          onClick={onSubmit}
          loading={loadingUsersConsultants}
          disabled={loadingUsersConsultants}
        />
      </Modal.Content>
    </Modal>
  )
}

ModalUsersConsultants.propTypes = {
  open: t.bool,
  onClose: t.func,
  userId: t.string,
  onChangeItem: t.func,
}
