import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import Accounts from '~/pages/Accounts'
import Activation from '~/pages/Activation'
import Badges from '~/pages/Badges'
import BadgeDetail from '~/pages/Badges/Detail'
import BadgeForm from '~/pages/Badges/EAD'
import FormsUsersExtra from '~/pages/Badges/FormsUsersExtra'
import FormsUsersExtraDetail from '~/pages/Badges/FormsUsersExtra/Detail'
import BadgeRequests from '~/pages/Badges/Requests'
import Categories from '~/pages/Categories'
import CategoriesDetail from '~/pages/Categories/Detail'
import SubCategories from '~/pages/Categories/Subcategories'
import CustomStatus from '~/pages/Categories/Subcategories/CustomStatus'
import SubCategoriesDetail from '~/pages/Categories/Subcategories/Detail'
import LeadersBySubcategory from '~/pages/Categories/Subcategories/LeadersBySubcategory'
import SubCategoriesValues from '~/pages/Categories/Subcategories/SubCategoriesValues'
import CreateChallenge from '~/pages/Challenges/Challenges/Create'
import ListChallenge from '~/pages/Challenges/Challenges/List'
import CreateTypeChallenge from '~/pages/Challenges/TypeChallenges/Create'
import ListTypeChallenge from '~/pages/Challenges/TypeChallenges/List'
import UpdateTypeChallenge from '~/pages/Challenges/TypeChallenges/Update'
import Customers from '~/pages/Customers'
import CustomersDetail from '~/pages/Customers/Detail'
import Dashboard from '~/pages/Dashboard'
import Exportation from '~/pages/Exportation'
import CharitiesInstitutions from '~/pages/Financial/CharitiesInstitutions/Institutions'
import CharitiesInstitutionsRequests from '~/pages/Financial/CharitiesInstitutions/Requests'
import MultiplierInstallments from '~/pages/Financial/Multipliers/Installments'
import MultiplierPlans from '~/pages/Financial/Multipliers/Plans'
import MultiplierLoanRequest from '~/pages/Financial/Multipliers/Requests'
import PaymentCustomers from '~/pages/Financial/PaymentCustomers'
import PaymentsUsers from '~/pages/Financial/PaymentsUsers'
import TransferRequests from '~/pages/Financial/TransferRequests'
import ImportsFormsCustomers from '~/pages/Imports/FormsCustomers'
import ImportFilesLinkManager from '~/pages/Imports/LinkFiles'
import Imports from '~/pages/Imports/Missions'
import ImportsFiles from '~/pages/Imports/Missions/Details'
import ImportsProductsCustomers from '~/pages/Imports/ProductsCustomers'
import ImportsProductsPDV from '~/pages/Imports/ProductsPDV'
import Login from '~/pages/Login'
import Campaign from '~/pages/Marketing/Campaign'
import Promotions from '~/pages/Marketing/Promotions'
import MissionsExpiring from '~/pages/Missions/Expiring'
import MissionsLanched from '~/pages/Missions/Launched'
import Metrics from '~/pages/Missions/Metrics'
import MissionsOpened from '~/pages/Missions/Open'
import MissionsProgress from '~/pages/Missions/Progress'
import Registers from '~/pages/Missions/Registers'
import RegistersDetail from '~/pages/Missions/Registers/Detail'
import NotFound from '~/pages/NotFound'
import RatingCustomers from '~/pages/Rating/Customers'
import RatingUsers from '~/pages/Rating/Users'
import StoreCategories from '~/pages/Store/Categories'
import StoreProducts from '~/pages/Store/Products'
import Subscribes from '~/pages/Subscribes'
import Users from '~/pages/Users'
import UserBadges from '~/pages/Users/Badges'
import Consultants from '~/pages/Users/Consultants'
import UsersDetail from '~/pages/Users/Detail'
import Webhook from '~/pages/Webhook'
import Workforce from '~/pages/Workforce'

import Route from './Route'
import Orders from '~/pages/Store/Orders'
import DashboardMetrics from '~/pages/DashboardMetrics'

const Routes = () => (
  <Switch>
    <Route exact name="login" path="/" component={Login} />
    <Route exact name="activation" path="/activation" component={Activation} />
    <Route
      isPrivate
      exact
      name="multipliers"
      path="/financial/multipliers/plans"
      component={MultiplierPlans}
    />

    <Route
      isPrivate
      exact
      name="charities-institutions"
      path="/financial/charities-institutions/charities"
      component={CharitiesInstitutions}
    />

    <Route
      isPrivate
      exact
      name="charities-institutions"
      path="/financial/charities-institutions/requests"
      component={CharitiesInstitutionsRequests}
    />

    <Route isPrivate exact name="webhook" path="/webhook" component={Webhook} />

    <Route
      isPrivate
      exact
      name="exportation"
      path="/exportation"
      component={Exportation}
    />
     <Route
      isPrivate
      exact
      path="/dashboard-metrics"
      component={DashboardMetrics}
    />

    <Route
      isPrivate
      exact
      name="multipliers"
      path="/financial/multipliers/requests"
      component={MultiplierLoanRequest}
    />
    <Route
      isPrivate
      exact
      name="multipliers"
      path="/financial/multipliers/requests/:id/installments"
      component={MultiplierInstallments}
    />
    <Route
      isPrivate
      exact
      name="dashboard"
      path="/dashboard"
      component={Dashboard}
    />
    <Route
      isPrivate
      exact
      name="type-challenges"
      path="/type-challenges"
      component={ListTypeChallenge}
    />
    <Route
      isPrivate
      exact
      name="type-challenges"
      path="/type-challenges/:id/edit"
      component={UpdateTypeChallenge}
    />
    <Route
      isPrivate
      exact
      name="challenges"
      path="/challenges/new"
      component={CreateChallenge}
    />
    <Route
      isPrivate
      exact
      name="challenges"
      path="/challenges"
      component={ListChallenge}
    />
    <Route
      isPrivate
      exact
      name="type-challenges"
      path="/type-challenges/new"
      component={CreateTypeChallenge}
    />
    <Route
      isPrivate
      exact
      name="missions"
      path="/missions/launched"
      component={MissionsLanched}
    />
    <Route
      isPrivate
      exact
      name="missions"
      path="/missions/opened"
      component={MissionsOpened}
    />
    <Route
      isPrivate
      exact
      name="missions"
      path="/missions/expiring"
      component={MissionsExpiring}
    />
    <Route
      isPrivate
      exact
      name="details"
      path="/missions/progress/:missionsUsersId"
      component={MissionsProgress}
    />
    <Route
      isPrivate
      exact
      name="metrics"
      path="/missions/metrics"
      component={Metrics}
    />
    <Route
      isPrivate
      exact
      name="registers"
      path="/registers"
      component={Registers}
    />
    <Route
      isPrivate
      exact
      name="import-missions"
      path="/imports/missions"
      component={Imports}
    />
    <Route
      isPrivate
      exact
      name="import-missions"
      path="/imports/missions/:importFileId"
      component={ImportsFiles}
    />
    <Route
      isPrivate
      exact
      name="import-products"
      path="/imports/products-pdv"
      component={ImportsProductsPDV}
    />
    <Route
      isPrivate
      exact
      name="imports-products-customers"
      path="/imports/products-customers"
      component={ImportsProductsCustomers}
    />
    <Route
      isPrivate
      exact
      name="imports-forms-customers"
      path="/imports/forms-customers"
      component={ImportsFormsCustomers}
    />
    <Route
      isPrivate
      exact
      name="import-links"
      path="/imports/link-files"
      component={ImportFilesLinkManager}
    />
    <Route isPrivate exact name="badges" path="/badges" component={Badges} />
    <Route
      isPrivate
      exact
      name="badges"
      component={BadgeDetail}
      path="/badges/detail/:id"
    />
    <Route
      isPrivate
      exact
      name="badges"
      component={BadgeForm}
      path="/badges/detail/:id/ead"
    />
    <Route
      isPrivate
      exact
      name="badges"
      component={BadgeRequests}
      path="/badges/requests"
    />
    <Route
      isPrivate
      exact
      name="formsUsersExtra"
      path="/badges/forms-users-extra"
      component={FormsUsersExtra}
    />
    <Route
      isPrivate
      exact
      name="formsUsersExtra"
      path="/badges/forms-users-extra/:id"
      component={FormsUsersExtraDetail}
    />

    <Route
      isPrivate
      exact
      name="accounts"
      path="/accounts"
      component={Accounts}
    />
    <Route
      isPrivate
      exact
      name="categories"
      path="/categories"
      component={Categories}
    />
    <Route
      isPrivate
      exact
      name="categories"
      component={CategoriesDetail}
      path="/categories/:categoryId"
    />
    <Route
      isPrivate
      exact
      name="categories"
      component={SubCategories}
      path="/categories/:categoryId/sub-categories"
    />
    <Route
      isPrivate
      exact
      name="categories"
      component={SubCategoriesDetail}
      path="/categories/:categoryId/sub-categories/:subcategoryId"
    />
    <Route
      isPrivate
      exact
      name="categories"
      path="/categories/:categoryId/sub-categories/:subcategoryId/custom-status"
      component={CustomStatus}
    />
    <Route
      isPrivate
      exact
      name="categories"
      component={SubCategoriesValues}
      path="/categories/:categoryId/sub-categories-values/:subcategoryId"
    />
    <Route
      isPrivate
      exact
      name="leadersBySubcategories"
      component={LeadersBySubcategory}
      path="/leaders/:subcategoryId"
    />
    <Route
      isPrivate
      exact
      name="requests"
      component={PaymentCustomers}
      path="/financial/payments-customers"
    />
    <Route
      isPrivate
      exact
      name="payments"
      path="/financial/payments-users"
      component={PaymentsUsers}
    />
    <Route
      isPrivate
      exact
      name="transfer-requests"
      path="/financial/transfer-requests"
      component={TransferRequests}
    />
    <Route
      isPrivate
      exact
      name="customers"
      component={Customers}
      path="/customers"
    />
    <Route
      isPrivate
      exact
      name="customers"
      path="/customers/:customerId"
      component={CustomersDetail}
    />
    <Route
      isPrivate
      exact
      name="subscribes"
      path="/subscribes"
      component={Subscribes}
    />
    <Route isPrivate exact name="users" path="/users" component={Users} />
    <Route
      isPrivate
      exact
      name="users"
      path="/user/:id"
      component={UsersDetail}
    />
    <Route
      isPrivate
      exact
      name="users"
      path="/user/:id/badges"
      component={UserBadges}
    />
    <Route
      isPrivate
      exact
      name="users"
      path="/user/:id/consultants"
      component={Consultants}
    />
    <Route
      isPrivate
      exact
      name="registers"
      path="/register/:id"
      component={RegistersDetail}
    />
    <Route
      isPrivate
      exact
      name="workforce"
      path="/workforce"
      component={Workforce}
    />
    <Route
      isPrivate
      exact
      name="campaign"
      path="/marketing/campaign"
      component={Campaign}
    />
    <Route
      isPrivate
      exact
      name="promotions"
      path="/marketing/promotions"
      component={Promotions}
    />
    <Route
      isPrivate
      exact
      name="rating-users"
      path="/rating/users"
      component={RatingUsers}
    />
    <Route
      isPrivate
      exact
      name="store-categories"
      path="/store/categories"
      component={StoreCategories}
    />
    <Route
      isPrivate
      exact
      name="store-products"
      path="/store/products"
      component={StoreProducts}
    />
    <Route
      isPrivate
      exact
      name="rating-customers"
      path="/rating/customers"
      component={RatingCustomers}
    />
    <Route
      isPrivate
      exact 
      name="orders"
      path="/store/orders"
      component={Orders}
    />
    <Route exact path="/404" component={NotFound} />
    <Redirect to="/404" />
  </Switch>
)

export default Routes
