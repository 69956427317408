import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import t from 'prop-types'
import * as Yup from 'yup'

import api from '~/services/api'
import Message from '~/utils/messages'

const AuthContext = createContext({})

function AuthProvider({ children }) {
  const history = useHistory()
  const location = useLocation()
  const isActivation = location.pathname === '/activation'

  const [loadingAdmin, setLoadingAdmin] = useState(true)
  const [loadingSign, setLoadingSign] = useState(false)
  const [data, setData] = useState({ user: null })

  const signOut = useCallback(() => {
    localStorage.removeItem('@MissionAdmin:token')

    setData({ user: null })

    return history.push('/')
  }, []) //eslint-disable-line

  const getAdminMetadata = useCallback(async () => {
    try {
      const response = await api.get('admin/profile', {
        validateStatus() {
          return true
        },
      })

      if (response.status !== 200) {
        throw new Error(response.data.message)
      }

      return setData(prevState => ({ ...prevState, user: response.data }))
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingAdmin(false)
    }
  }, []) // eslint-disable-line

  const signIn = useCallback(async ({ email, password }) => {
    try {
      const params = { email, password }
      setLoadingSign(true)

      const schema = Yup.object().shape({
        email: Yup.string().email().required('Email não pode estar vazio!'),
        password: Yup.string()
          .min(6)
          .required('Senha deve conter no mínimo 6 caracteres!'),
      })

      const isValid = schema.isValidSync(params)

      if (!isValid) {
        const validate = schema.validateSync(params)
        throw new Error(validate)
      }

      const response = await api.post('sessions/admin/auth', params, {
        validateStatus() {
          return true
        },
      })

      if (response.status !== 201) {
        throw new Error(response.data.message)
      }

      api.defaults.headers.Authorization = `Bearer ${response.data.token}`

      localStorage.setItem('@MissionAdmin:token', response.data.token)

      await getAdminMetadata()

      setLoadingSign(false)

      return history.push('/dashboard')
    } catch (error) {
      setLoadingSign(false)
      return Message().error(error.message)
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!isActivation) {
      getAdminMetadata()
    } else {
      setLoadingAdmin(false)
    }
  }, []) // eslint-disable-line

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        loadingAdmin,
        loadingSign,
        getAdminMetadata,
        user: data?.user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: t.node.isRequired,
}

function useAuth() {
  const context = useContext(AuthContext)

  return context
}

export { AuthProvider, useAuth }
