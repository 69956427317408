import React from 'react'

import t from 'prop-types'
import { isNil } from 'ramda'
import { Header, Icon } from 'semantic-ui-react'

const EmptyList = ({ text, icon, marginTop }) => (
  <Header
    icon
    style={{
      width: '100%',
      marginTop: isNil(marginTop) ? 100 : marginTop,
      justifyContent: 'center',
    }}
  >
    <Icon style={{ color: '#b9b9b9' }} name={icon} />
    <span style={{ color: '#b9b9b9' }}>{text}</span>
  </Header>
)

EmptyList.propTypes = {
  text: t.string,
  icon: t.string,
  marginTop: t.number,
}

export default EmptyList
