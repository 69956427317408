import React, { useEffect, useState } from 'react'

import { Button, Card, Header } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useCharitiesIntitutions from '~/hooks/useCharitiesIntitutions'

import CreateOrEdit from './components/CreateOrEdit'
import Item from './components/Item'

function CharitiesInstitutions() {
  const [selectedItem, setSelectedItem] = useState({})
  const [createOrEditModal, setCreateOrEditModal] = useState({
    open: false,
    type: '',
  })

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
    activePage: 1,
  })

  const {
    charitiesInstitutions,
    getCharitiesInstitutions,
    loading,
    createCharitiesInstitutions,
    changeRemove,
    count,
    updateCharitiesInstitutions,
  } = useCharitiesIntitutions()

  function handleOnClick(type, item) {
    if (type === 'create') {
      setCreateOrEditModal({
        open: true,
        type: 'create',
        textHeader: 'Nova',
      })

      setSelectedItem({})
    }

    if (type === 'update') {
      setCreateOrEditModal(prevState => ({
        ...prevState,
        open: true,
        type: 'update',
        textHeader: 'Editar',
      }))

      setSelectedItem(item)
    }
  }

  function handleOnClickConfirm(type, values) {
    if (type === 'create') {
      createCharitiesInstitutions(values)
    }

    if (type === 'update') {
      updateCharitiesInstitutions(values)
    }

    setTimeout(() => {
      setCreateOrEditModal(prevState => ({
        ...prevState,
        open: false,
      }))
    }, 500)
  }

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * pagination.limit
    setPagination(prevState => ({
      ...prevState,
      activePage,
      offset,
      limit: pagination.limit,
    }))
    return getCharitiesInstitutions({
      offset,
      limit: pagination.limit,
    })
  }

  useEffect(() => {
    getCharitiesInstitutions(pagination)
  }, []) // eslint-disable-line

  return (
    <>
      <Header>Instituições de Caridade</Header>
      <Card fluid>
        <Card.Content>
          <Button
            icon="plus"
            labelPosition="left"
            primary
            content="Criar"
            onClick={() => handleOnClick('create')}
          />
        </Card.Content>
      </Card>

      <TableComponent
        columns={['', 'Nome', 'Telefone', 'Email', 'Dados da Conta', 'Ações']}
        isLoading={loading}
        data={charitiesInstitutions}
        renderItem={item => (
          <Item
            item={item}
            onClickEdit={handleOnClick}
            onClickRemove={changeRemove}
          />
        )}
        emptyText={{
          icon: 'box',
          text: 'Nenhuma Instituição de Caridade foi Encontrada',
        }}
        count={count}
        totalCount={count}
        showFirstAndLastNav
        showPreviousAndNextNav
        activePage={pagination.activePage}
        limit={pagination.limit}
        offset={pagination.offset}
        handlePaginationChange={handlePaginationChange}
      />

      <CreateOrEdit
        createOrEditModal={createOrEditModal}
        onClose={() =>
          setCreateOrEditModal(prevState => ({ ...prevState, open: false }))
        }
        data={selectedItem}
        loading={loading}
        onClickConfirm={handleOnClickConfirm}
      />
    </>
  )
}

export default CharitiesInstitutions
