import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { length } from 'ramda'
import { Table, Loader, Button, Confirm, Divider } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import { useAuth } from '~/contexts/auth'
import useCategories from '~/hooks/useCategories'
import Main from '~/pages/Main'

import CreateCategories from './components/Create'
import Item from './components/Item'

import '~/assets/css/react-draft-wysiwyg.css'

function Categories() {
  const history = useHistory()
  const filter = { offset: 0, limit: 50 }
  const [isRemoveCategoryModalOpen, setIsRemoveCategoryModalOpen] =
    useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [category, setCategory] = useState({
    name: '',
    description: '',
    has_consultant: false,
    avatar: null,
    available_in: 'public',
  })
  const [item, setItem] = useState(null)

  const { user } = useAuth()

  const {
    getCategories,
    categories,
    loadingCategories,
    onRemoveCategory,
    onSubmitCategory,
  } = useCategories()

  const uploadRef = useRef()

  function onChange(id, value) {
    return setCategory(prevState => ({ ...prevState, [id]: value }))
  }

  function uploadImage(e) {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = event => {
      setCategory(prevState => ({
        ...prevState,
        avatar: {
          data: file,
          src: event.target.result,
        },
      }))
    }
  }

  function openRemoveId(removeId) {
    return setTimeout(() => {
      setItem(removeId)
      setIsRemoveCategoryModalOpen(true)
    }, 200)
  }

  useEffect(() => {
    getCategories(filter)
  }, []) //eslint-disable-line

  const renderComponent = () => (
    <>
      <Confirm
        header="Alerta"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        open={isRemoveCategoryModalOpen}
        onConfirm={() => {
          setIsRemoveCategoryModalOpen(false)
          onRemoveCategory(item)
        }}
        onCancel={() => setIsRemoveCategoryModalOpen(false)}
        content="Tem certeza deseja remover este item?"
      />

      <Button
        size="small"
        color={openCreate ? 'red' : 'blue'}
        onClick={() => setOpenCreate(prevState => !prevState)}
      >
        {openCreate ? 'Fechar' : 'Criar'}
      </Button>

      {loadingCategories && (
        <div>
          <Loader active inline="centered" />
        </div>
      )}

      {categories.length === 0 && (
        <EmptyList icon="check circle outline" text="Nenhuma Categoria" />
      )}

      {!loadingCategories && categories.length > 0 && (
        <div className="container">
          <CreateCategories
            category={category}
            show={openCreate}
            myRef={uploadRef}
            onChange={onChange}
            uploadImage={uploadImage}
            create={() => onSubmitCategory(category)}
            onClick={() => uploadRef.current.click()}
          />

          <Divider inverted />

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell className="th-has-consultant">
                  Tem consultor líder
                </Table.HeaderCell>
                <Table.HeaderCell>Exibição</Table.HeaderCell>
                <Table.HeaderCell>Criado Em</Table.HeaderCell>
                <Table.HeaderCell>Atualizado Em</Table.HeaderCell>
                <Table.HeaderCell>Ações</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {length(categories) > 0 &&
                categories.map((itemCategory, index) => (
                  <Item
                    item={itemCategory}
                    history={history}
                    key={String(`k-${index}`)}
                    openRemoveId={openRemoveId}
                  />
                ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </>
  )

  return (
    <Main>
      {user?.role_name === 'Basic' ? (
        <EmptyList icon="lock" text="Acesso restrito" />
      ) : (
        renderComponent()
      )}
    </Main>
  )
}

export default Categories
