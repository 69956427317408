import React, { useState, useEffect } from 'react'
import ReactMapGl, { FlyToInterpolator, Marker as MarkerGl } from 'react-map-gl'
import { AutoSizer } from 'react-virtualized'

import * as d3 from 'd3'
import t from 'prop-types'
import { Grid, Image, Loader } from 'semantic-ui-react'

import ImageAvatar from '~/assets/avatar.png'
import EmptyList from '~/components/EmptyList'
import useMissionsFeeds from '~/hooks/useMissionsFeeds'
import message from '~/utils/messages'

import { MemoizedMarker } from './Marker'
import { MissionsFeeds } from './MissionsFeeds'

const token =
  'pk.eyJ1IjoibWlzc2lvbmJyYXNpbCIsImEiOiJjajBqczEwZXMwMTgxMnFyNmZnZnR6ZGc4In0.SwxP9SQ88PFmT602u0Wtxg'
const positionDefault = {
  lat: 0,
  lng: 0,
}

export function RecentActivities({ userDetailData, userId }) {
  console.log('render')
  const { getMissionsFeedsByUserId, usersFeeds, loadingFeeds } =
    useMissionsFeeds()

  const { lat, lng, avatar } = userDetailData

  const [viewport, setViewport] = useState({
    zoom: 14,
    height: 600,
    width: window.innerWidth,
    latitude: positionDefault.lat,
    longitude: positionDefault.lng,
  })
  const [textCopy, setTextCopy] = useState('')
  const [limitFeeds, setLimitFeeds] = useState(15)

  function copy(item) {
    return setTextCopy(item)
  }

  function onCopied() {
    return message().success('Identificador copiado!')
  }

  function loadMoreFeeds() {
    setLimitFeeds(prev => prev + 10)

    return getMissionsFeedsByUserId({ userId, limit: limitFeeds + 10 })
  }

  function goToPoint(params) {
    return setViewport(prev => ({
      ...prev,
      zoom: 14,
      latitude: params.lat,
      longitude: params.lng,
      transitionDuration: 5000,
      transitionEasing: d3.easeCubic,
      transitionInterpolator: new FlyToInterpolator(),
    }))
  }

  useEffect(() => {
    if (usersFeeds.length > 0) {
      goToPoint(usersFeeds[0])
    }
  }, [usersFeeds])

  useEffect(() => {
    getMissionsFeedsByUserId({
      userId,
      limit: limitFeeds,
    })
  }, []) //eslint-disable-line

  return (
    <Grid>
      <Grid.Column width={11}>
        {loadingFeeds ? <Loader active inline="centered" /> : null}

        {!loadingFeeds && usersFeeds.length > 0 ? (
          <AutoSizer>
            {({ width, height }) => (
              <ReactMapGl
                {...viewport}
                mapboxApiAccessToken={token}
                onViewportChange={v => setViewport(v)}
                mapStyle="mapbox://styles/missionbrasil/cjkmphdta3c5r2rpezblnlfth"
                width={width}
                height={height}
              >
                <MarkerGl
                  offsetTop={0}
                  offsetLeft={0}
                  latitude={lat}
                  longitude={lng}
                >
                  <Image avatar size="mini" src={avatar || ImageAvatar} />
                </MarkerGl>
                {usersFeeds.map((item, index) => (
                  <MemoizedMarker item={item} index={index} />
                ))}
              </ReactMapGl>
            )}
          </AutoSizer>
        ) : (
          !loadingFeeds && <EmptyList text="Nenhuma Atividade até o momento" />
        )}
      </Grid.Column>
      {!loadingFeeds && usersFeeds.length > 0 ? (
        <Grid.Column width={5}>
          <MissionsFeeds
            feeds={usersFeeds}
            copy={copy}
            textCopy={textCopy}
            loadMoreFeeds={loadMoreFeeds}
            onCopied={onCopied}
            goToPoint={goToPoint}
            isLoading={loadingFeeds}
          />
        </Grid.Column>
      ) : null}
    </Grid>
  )
}

RecentActivities.propTypes = {
  userDetailData: t.objectOf(t.any),
  userId: t.string,
}

export const MemoizedRecentActivities = React.memo(RecentActivities)
