import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: #f1f2f3;
  margin-left: -8px;
  margin-right: -8px;
  padding: 10px;
  white-space: nowrap;
  &:after {
    content: '';
    clear: both;
    display: table;
  }
`

export const Item = styled.div`
  float: left;
  padding-left: 8px;
  padding-right: 8px;
  width: calc(25% - 16px);
  .content {
    padding: 8px 12px;
    background-color: #ddd;
    height: 150px;
    background-color: blue;
  }
`

export const DragItem = styled.div`
  width: 100%;
  height: 50px;
  padding: 10px;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: row;
  background-color: white;
  justify-content: flex-start;
`

export const ContainerButton = styled.div`
  width: 100%;
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
`

export const TitleItem = styled.div`
  font-size: 14px;
  font-weight: bold;
`

export const IconItem = styled(Icon)`
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex !important;
`
export const HeaderContainer = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding: 10px;
  margin-bottom: 20px;
`

export const BottomContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px;
  margin-top: 20px;
`
