import { useState } from 'react'

import api from '~/services/api'

export default function useDashboardMetrics() {
  const [filter, setFilter] = useState({
    customerId: null,
    period: 'months',
    uf: null,
    city: null,
    badgeId: null,
    missionsMainId: null,
    categoryMissionsMainId: null,
    initDate: null,
    finalDate: null,
  })
  const [usersNoCompleteRegister, setUsersNoCompleteRegister] = useState([])
  const [accountsCancelled, setAccountsCancelled] = useState([])
  const [usersActive, setUsersActive] = useState([])
  const [usersCompletedRegister, setUsersCompletedRegister] = useState([])
  const [usersRegistered, setUsersRegistered] = useState([])
  const [missionsActive, setMissionsActive] = useState([])
  const [missionsUsersStartedFinished, setMissionsUsersStartedFinished] =
    useState([])
  const [usersByVersion, setUsersByVersion] = useState([])
  const [converstionFunnels, setConverstionFunnels] = useState([])
  const [funnelUserInactive, setFunnelUserInactive] = useState([])
  const [funnelUsersPotential, setFunnelUsersPotential] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onChangeInputValue = (key, value) => {
    setFilter({ ...filter, [key]: value })
  }

  const resetFilters = () => {
    setFilter({
      customerId: null,
      period: 'months',
      uf: null,
      city: null,
      badgeId: null,
      missionsMainId: null,
      categoryMissionsMainId: null,
      initDate: null,
      finalDate: null,
    })
  }

  const submitFilters = () => {
    const data = filter
    data.city = data?.city?.cityName
    setFilter(data)
    getMetrics()
  }

  const getMetrics = async () => {
    setIsLoading(true)
    const [
      userMetricsResponse,
      missionsMetricsResponse,
      converstionFunnelsResponse,
      funnelUserInactiveResponse,
      funnelUsersPotentialResponse,
    ] = await Promise.all([
      api.get('/dashboard/users-metrics', {
        params: filter,
      }),
      api.get('/dashboard/missions-metrics', {
        params: filter,
      }),
      api.get('/dashboard/converstion-funnels-metrics', {
        params: filter,
      }),
      api.get('/dashboard/funnel-users-inactive-metrics', {
        params: filter,
      }),
      api.get('/dashboard/funnel-potential-users-metrics', {
        params: filter,
      }),
    ])
    setUsersNoCompleteRegister(userMetricsResponse.data.usersNoCompleteRegister)
    setAccountsCancelled(userMetricsResponse.data.accountsCancelled)
    setUsersActive(userMetricsResponse.data.usersActive)
    setUsersCompletedRegister(userMetricsResponse.data.usersCompletedRegister)
    setUsersRegistered(userMetricsResponse.data.usersRegistered)
    setUsersByVersion(userMetricsResponse.data.usersByVersion)
    setConverstionFunnels(converstionFunnelsResponse.data)
    setMissionsActive(missionsMetricsResponse.data.missionsActive)
    setMissionsUsersStartedFinished(
      missionsMetricsResponse.data.missionsUsersStartedFinished
    )
    setFunnelUserInactive(funnelUserInactiveResponse.data)
    setFunnelUsersPotential(funnelUsersPotentialResponse.data)
    setIsLoading(false)
  }

  return {
    filter,
    onChangeInputValue,
    resetFilters,
    submitFilters,
    getMetrics,
    usersNoCompleteRegister,
    accountsCancelled,
    usersActive,
    usersCompletedRegister,
    usersRegistered,
    missionsActive,
    missionsUsersStartedFinished,
    usersByVersion,
    converstionFunnels,
    funnelUserInactive,
    funnelUsersPotential,
    isLoading,
  }
}
