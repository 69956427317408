import React, { useState } from 'react'

import t from 'prop-types'
import { Table } from 'semantic-ui-react'

import history from '~/services/history'

const Item = ({ item }) => {
  const [style, setStyle] = useState({
    cursor: 'pointer',
  })

  const onMouseEnter = () => {
    setStyle(prevState => ({
      ...prevState,
      backgroundColor: '#EFEFEF',
    }))
  }

  const onMouseLeave = () => {
    setStyle(prevState => ({
      ...prevState,
      backgroundColor: '#fff',
    }))
  }

  return (
    <Table.Row
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => history.push(`user/${item.uid}`)}
      key={item.uid}
      positive={item.status && !item.banned}
      negative={!item.status && !item.banned}
      warning={(item.status || !item.status) && item.banned}
    >
      <Table.Cell style={style}>{item.name}</Table.Cell>
      <Table.Cell style={style}>{item.email}</Table.Cell>
      <Table.Cell style={style}>{item.phone}</Table.Cell>
      <Table.Cell style={style}>
        {parseFloat(item.rating).toFixed(1)}
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  item: t.objectOf(t.any),
  banned: t.bool,
}

export default Item
