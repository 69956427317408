/* eslint-disable no-const-assign */
/* eslint-disable no-undef */
import React, { useState, useEffect, useCallback } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDropzone } from 'react-dropzone'
import { useHistory, useLocation } from 'react-router-dom'

import { format } from 'date-fns'
import queryString from 'query-string'
import { Card, Icon, Button, Confirm, Table, Popup } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useLinkFiles from '~/hooks/useLinkFiles'
import Filter from '~/pages/Imports/LinkFiles/component/Filter'
import Upload from '~/pages/Imports/LinkFiles/component/Upload'
import Main from '~/pages/Main'
import Message from '~/utils/messages'

const columns = ['Nome', 'Link', 'Tamanho', 'Data', 'Ações']

function ImportFilesLinkManager(props) {
  const history = useHistory()
  const location = useLocation()

  const [name, setName] = useState('')
  const [files, setFiles] = useState([])
  const [open, setOpen] = useState(false)
  const [importId, setImportId] = useState(null)
  const [activePageTable, setActivePageTable] = useState(1)
  const [filters, setFilters] = useState({ limit: 20, offset: 0 })

  function handleQuery(target, value) {
    const queryParse = queryString.parse(location.search)
    const query = { ...queryParse, [target]: value }
    const search = queryString.stringify(query)

    return history.replace({ search, pahtname: location.pathname })
  }

  function handlePaginationChange(e, { activePage }) {
    const newOffset = (activePage - 1) * filters.limit
    const queries = queryString.parse(location.search)
    const search = {
      ...filters,
      ...queries,
      offset: Number(newOffset),
      limit: filters.limit,
    }

    setActivePageTable(activePage)

    setFilters(prevState => ({ ...prevState, activePage, offset: newOffset }))

    handleQuery('offset', newOffset)

    return getArchives(search)
  }

  const {
    archives,
    getArchives,
    onRemoveArchive,
    onSubmitFiles,
    loadingArchives,
    count,
  } = useLinkFiles()

  function removeFile(id) {
    const newFiles = files.filter((i, index) => index !== id)
    return setFiles(newFiles)
  }

  const onDrop = useCallback(accFiles => {
    const mappedAcc = accFiles.map(file => ({ file }))
    setFiles(curr => [...curr, ...mappedAcc])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  function confirmRemove(id) {
    setImportId(id)
    setOpen(true)
  }

  function removeFileById(id) {
    setOpen(false)
    onRemoveArchive(id)
  }

  function onChange(value) {
    return setName(value)
  }

  function onSubmitFilter() {
    return getArchives({ fileName: name.trim() })
  }

  function clearFilters() {
    setName('')
    return getArchives({ fileName: '' })
  }

  async function onCreate() {
    await onSubmitFiles(files)
    return setFiles([])
  }

  useEffect(() => {
    getArchives(filters)
  }, []) //eslint-disable-line

  function renderItem(item, index) {
    return (
      <Table.Row key={`key-${index}`}>
        <Table.Cell>{item.filename}</Table.Cell>
        <Table.Cell>
          <a rel="noopener noreferrer" target="_blank" href={item.url_raw}>
            {item.url_raw}
          </a>
        </Table.Cell>
        <Table.Cell>{(item.size / (1024 * 1024)).toFixed(2)} MB</Table.Cell>
        <Table.Cell>
          {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
        </Table.Cell>
        <Table.Cell>
          <Popup
            content="Copiar Link"
            trigger={
              <CopyToClipboard
                text={item.url_raw}
                onCopy={() => Message().success('Link Copiado')}
              >
                <Button icon primary>
                  <Icon name="copy" />
                </Button>
              </CopyToClipboard>
            }
          />
          <Popup
            content="Remover"
            trigger={
              <Button icon negative onClick={() => confirmRemove(item.uid)}>
                <Icon name="remove" />
              </Button>
            }
          />
        </Table.Cell>
      </Table.Row>
    )
  }

  return (
    <Main {...props}>
      <Card fluid>
        <Card.Content>
          <Upload
            files={files}
            removeFile={removeFile}
            isDragActive={isDragActive}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            multiple
          />
        </Card.Content>
        <Card.Content extra>
          {files.length > 0 && (
            <Button
              positive
              onClick={onCreate}
              loading={loadingArchives}
              disabled={loadingArchives}
            >
              Enviar Arquivos
            </Button>
          )}
        </Card.Content>
      </Card>

      <Filter
        fileName={name}
        onChange={onChange}
        isLoading={loadingArchives}
        clearFilters={clearFilters}
        onSubmitFilter={onSubmitFilter}
      />

      <TableComponent
        data={archives}
        isLoading={loadingArchives}
        renderItem={renderItem}
        columns={columns}
        count={count}
        totalCount={count}
        activePage={activePageTable}
        offset={filters.offset}
        handlePaginationChange={handlePaginationChange}
        emptyText={{
          icon: 'world',
          text: 'Nenhum Arquivo foi encontrada...',
        }}
        siblingRange={1}
        boundaryRange={0}
        limit={filters.limit}
        showEllipsis
        showFirstAndLastNav
        showPreviousAndNextNav
      />
      <Confirm
        open={open}
        header="Alerta"
        confirmButton="Sim"
        cancelButton="Cancelar"
        onCancel={() => setOpen(false)}
        onConfirm={() => removeFileById(importId)}
        content="Tem certeza que deseja remover este arquivo?"
      />
    </Main>
  )
}

export default ImportFilesLinkManager
