import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import t from 'prop-types'
import { Button, Table } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'

function TableFormsItems({
  fullDataForm,
  selectedForm,
  items,
  handleClickButton,
  loadingItems,
  changeOrderFormsItems,
}) {
  const grid = 2

  const getItemStyle = (isDragging, draggableStyle) => ({
    width: '100%',
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    border: '1px solid #F0F0F0',
    borderRadius: '4px',
    background: isDragging ? '#E0E1E2' : '#fff',
    ...draggableStyle,
  })

  const getListStyle = isDraggingOver => ({
    width: '100%',
    padding: grid,
    background: isDraggingOver ? 'lightblue' : '#fff',
  })

  const onDragEnd = result => {
    const { source, destination } = result

    if (!destination) {
      return null
    }

    const newItems = Array.from(items)
    const [removed] = newItems.splice(source.index, 1)
    newItems.splice(destination.index, 0, removed)

    return changeOrderFormsItems(newItems)
  }

  const TableCell = ({ children, flex, justifyContent }) => (
    <Table.Cell
      style={{
        flex,
        justifyContent,
        display: ' flex',
        alignItems: 'center',
      }}
    >
      {children}
    </Table.Cell>
  )

  TableCell.propTypes = {
    children: t.string,
    flex: t.number,
    justifyContent: t.string,
  }

  return (
    <Table selectable>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell colSpan="3">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {`${fullDataForm?.name || 'Itens do Formulário'}`.toUpperCase()}

              <Button
                content="Corrigir ordenação"
                onClick={() => handleClickButton('order-items')}
                disabled={loadingItems}
              />
            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {selectedForm ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(providedDroppable, snapshotDroppable) => (
                <div
                  ref={providedDroppable.innerRef}
                  {...providedDroppable.droppableProps}
                  style={getListStyle(snapshotDroppable.isDraggingOver)}
                >
                  {items.map((item, index) => (
                    <Draggable
                      index={index}
                      key={item.uid}
                      draggableId={item.uid}
                    >
                      {(providedDraggable, snapshotDraggable) => (
                        <div
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          style={getItemStyle(
                            snapshotDraggable.isDragging,
                            providedDraggable.draggableProps.style
                          )}
                        >
                          <Table.Row key={item.uid} style={{ display: 'flex' }}>
                            <TableCell>{item.order}</TableCell>
                            <TableCell flex={5}>{item.question}</TableCell>
                            <TableCell flex={1}>{item.question_type}</TableCell>
                            <TableCell flex={1}>{item.section}</TableCell>
                            <TableCell justifyContent="right">
                              <Button
                                color="linkedin"
                                icon="pencil"
                                content="Editar"
                                onClick={() =>
                                  handleClickButton('update-item', item)
                                }
                              />
                            </TableCell>
                          </Table.Row>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {providedDroppable.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Table.Row>
            <Table.Cell>
              <EmptyList text="Nenhum item para ser exibido" icon="box" />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

TableFormsItems.propTypes = {
  fullDataForm: t.arrayOf(t.any),
  selectedForm: t.string,
  items: t.arrayOf(t.any),
  handleClickButton: t.func,
  loadingItems: t.bool,
  changeOrderFormsItems: t.func,
}

export default TableFormsItems
