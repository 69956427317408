import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Table } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useUsersTransactions from '~/hooks/useUsersTransactions'

export function Statement({ userId }) {
  const {
    loading,
    getUsersTransactions,
    usersTransactions,
    totalUsersTransactions,
  } = useUsersTransactions()

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 15,
    activePage: 1,
  })

  const columns = ['Evento', 'Missão', 'MU Key', 'Valor', 'Criado em']
  const renderItem = item => (
    <Table.Row
      key={item.created_at}
      positive={Number(item.mission_value) >= 0}
      negative={Number(item.mission_value) < 0}
    >
      <Table.Cell
        style={{
          fontWeight: 'bold',
        }}
      >
        {item.event}
      </Table.Cell>
      <Table.Cell>{item.mission_name}</Table.Cell>
      <Table.Cell>{item.mission_key}</Table.Cell>
      <Table.Cell>{item.mission_value}</Table.Cell>
      <Table.Cell>
        {dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
    </Table.Row>
  )

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * pagination.limit

    setPagination(prevState => ({
      ...prevState,
      activePage,
      offset,
    }))

    return getUsersTransactions({
      userId,
      offset,
      limit: pagination.limit,
    })
  }

  useEffect(() => {
    getUsersTransactions({ userId, ...pagination })
  }, []) // eslint-disable-line

  return (
    <TableComponent
      isLoading={loading}
      showEllipsis
      showFirstAndLastNav
      showPreviousAndNextNav
      columns={columns}
      data={usersTransactions}
      renderItem={renderItem}
      count={Number(totalUsersTransactions)}
      totalCount={Number(totalUsersTransactions)}
      activePage={pagination.activePage}
      limit={pagination.limit}
      offset={pagination.offset}
      handlePaginationChange={handlePaginationChange}
      emptyText={{
        icon: 'list',
        text: 'Nenhuma Transação foi Encontrada',
      }}
    />
  )
}

Statement.propTypes = {
  userId: t.string,
}
