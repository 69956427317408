import React, { useEffect } from 'react'
import DatePicker from 'react-datepicker'

import t from 'prop-types'
import {
  Select,
  Button,
  Card,
  Form,
  Input,
  Icon,
  Dropdown,
} from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'
import useBadges from '~/hooks/useBadges'
import useCustomers from '~/hooks/useCustomers'
import useUfsAndCities from '~/hooks/useUfsAndCities'

export function Filter({
  roleName,
  filters,
  isLoading,
  onSubmitFilter,
  clearFilters,
  onClickExport,
  onChangeFilters,
}) {
  const { getBadges, badges, loadingBadges } = useBadges()
  const { ufs, ufsOptions, citiesOptions, onChangeUFs } = useUfsAndCities()
  const { getCustomers, listNameCustomers } = useCustomers()

  useEffect(() => {
    getCustomers({ limit: 20000 })
  }, []) // eslint-disable-line

  useEffect(() => {
    getBadges({ limit: 1000, order: 'b.title', direction: 'asc' })
  }, []) //eslint-disable-line

  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Nome"
              value={filters.name || ''}
              onChange={(e, { value }) => onChangeFilters({ name: value })}
            />

            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Email"
              value={filters.email || ''}
              onChange={(e, { value }) => onChangeFilters({ email: value })}
            />

            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Telefone"
              value={filters.phone || ''}
              onChange={(e, { value }) => onChangeFilters({ phone: value })}
            />

            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Code"
              value={filters.code || ''}
              onChange={(_, { value }) => onChangeFilters({ code: value })}
            />

            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="OS"
              value={filters.platform || ''}
              onChange={(e, { value }) => onChangeFilters({ platform: value })}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Saldo"
              value={filters.balance || ''}
              onChange={(e, { value }) => onChangeFilters({ balance: value })}
            />

            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Pagos"
              value={filters.paid || ''}
              onChange={(e, { value }) => onChangeFilters({ paid: value })}
            />

            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Versão"
              value={filters.app_version || ''}
              onChange={(e, { value }) =>
                onChangeFilters({ app_version: value })
              }
            />

            <Form.Field fluid>
              <DatePicker
                id="last_access"
                selectsStart
                dateFormat="dd/MM/yyyy"
                placeholderText="Último Acesso"
                selected={filters.last_access || ''}
                onChange={value => onChangeFilters({ last_access: value })}
              />
            </Form.Field>

            <Form.Field fluid>
              <DatePicker
                id="created_at"
                selectsStart
                dateFormat="dd/MM/yyyy"
                placeholderText="Criado em"
                selected={filters.created_at || ''}
                onChange={value => onChangeFilters({ created_at: value })}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field fluid disabled={!badges.length}>
              <Dropdown
                search
                multiple
                selection
                loading={loadingBadges}
                options={badges.map((i, key) => ({
                  key,
                  value: i.uid,
                  text: i.title,
                }))}
                placeholder="Badges"
                value={filters.badges || []}
                onChange={(e, { value }) => onChangeFilters({ badges: value })}
              />
            </Form.Field>

            <Form.Field fluid disabled={!ufsOptions.length}>
              <Dropdown
                search
                selection
                multiple
                options={ufsOptions}
                placeholder="UFs"
                value={ufs || []}
                onChange={(e, { value }) => {
                  onChangeUFs(value)
                  onChangeFilters({ ufs: value })
                }}
              />
            </Form.Field>

            <Form.Field fluid disabled={!citiesOptions.length || !ufs?.length}>
              <Dropdown
                search
                selection
                multiple
                options={citiesOptions}
                placeholder="Cidades"
                onChange={(e, { value }) => {
                  const prev = filters.cities || []
                  const values = value
                    .map(i => i.cityName)
                    .filter(v => v !== undefined)

                  onChangeFilters({
                    cities: [...prev, ...values],
                  })
                }}
              />
            </Form.Field>

            <Form.Field
              fluid
              control={Select}
              placeholder="Resultados por página"
              selection
              value={filters.limit || ''}
              options={[
                { key: 1, value: 15, text: 15 },
                { key: 2, value: 50, text: 50 },
                { key: 3, value: 100, text: 100 },
              ]}
              onChange={(e, { value }) => onChangeFilters({ limit: value })}
            />
            <Form.Field
              fluid
              control={Select}
              placeholder="Cliente"
              selection
              search
              value={filters.customer_id || ''}
              options={listNameCustomers}
              onChange={(e, { value }) =>
                onChangeFilters({ customer_id: value })
              }
            />
          </Form.Group>
          <Form.Group widths="5">
            <Form.Field
              fluid
              control={Select}
              placeholder="Tipo de Usuário"
              selection
              search
              value={filters.userType || ''}
              options={[
                { key: 1, value: 'all', text: 'Todos' },
                { key: 2, value: 'public', text: 'Público' },
                { key: 1, value: 'private', text: 'Privado' },
              ]}
              onChange={(e, { value }) => onChangeFilters({ userType: value })}
            />
          </Form.Group>
        </Form>
      </Card.Content>

      <Card.Content>
        <Button
          icon="filter"
          content="Filtrar"
          color="blue"
          labelPosition="left"
          onClick={onSubmitFilter}
          loading={isLoading}
        />
        <Button
          icon="undo"
          color="grey"
          content="Limpar Filtros"
          labelPosition="left"
          onClick={clearFilters}
          loading={isLoading}
        />

        {['Admin'].includes(roleName) ? (
          <Button
            icon
            color="orange"
            labelPosition="left"
            onClick={onClickExport}
            loading={isLoading}
            disabled={isLoading}
          >
            <Icon name="download" />
            Gerar Relatório
          </Button>
        ) : null}
      </Card.Content>
    </Card>
  )
}

Filter.propTypes = {
  roleName: t.string,
  filters: t.objectOf(t.any),
  isLoading: t.bool,
  clearFilters: t.func,
  onClickExport: t.func,
  onSubmitFilter: t.func,
  onChangeFilters: t.func,
}
