import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Form, Button, Card } from 'semantic-ui-react'

import useChallenge from '~/hooks/useChallenge'

import FormDynamicParams from '../components/FormDynamicParams'

function Update() {
  const history = useHistory()
  const urlParams = useParams()
  const [typeChallengeToEdit, setTypeChallengeToEdit] = useState({})
  const {
    getTypeChallengeById,
    updateTypeChallenge,
    addTypeChallengeParams,
    typeChallengeParams,
    setTypeChallengeParams,
    prepareDataToPersist,
  } = useChallenge()

  const loadData = async () => {
    const typeChallenge = await getTypeChallengeById(urlParams.id)
    setTypeChallengeParams(typeChallenge.params)
    setTypeChallengeToEdit(typeChallenge)
  }

  const submit = async () => {
    const data = prepareDataToPersist({
      ...typeChallengeToEdit,
      params: typeChallengeParams,
    })

    await updateTypeChallenge(typeChallengeToEdit.id, data)
    history.push('/type-challenges')
  }

  useEffect(() => {
    if (urlParams.id) {
      loadData()
    }
  }, []) //eslint-disable-line

  return (
    <>
      <Card fluid>
        <Card.Content>
          <Card.Description>
            <Form>
              <Form.Field>
                <label>Nome:</label>
                <input
                  value={typeChallengeToEdit.name}
                  onChange={event =>
                    setTypeChallengeToEdit({
                      ...typeChallengeToEdit,
                      name: event.target.value,
                    })
                  }
                  placeholder="Nome"
                />
              </Form.Field>
              <Form.Field>
                <label>Parâmetros:</label>
                <FormDynamicParams
                  setParams={setTypeChallengeParams}
                  params={typeChallengeParams}
                />
                <Button onClick={addTypeChallengeParams}>
                  Adicionar novo parâmetro
                </Button>
              </Form.Field>
              <Button primary type="submit" onClick={submit}>
                Salvar
              </Button>
            </Form>
          </Card.Description>
        </Card.Content>
      </Card>
    </>
  )
}

export default Update
