import React, { memo } from 'react'

import t from 'prop-types'
import {
  Modal,
  Item,
  Label,
  Icon,
  Segment,
  Dimmer,
  Loader,
  Image,
} from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'

function RatesModal({ close, rates, openModal, customerName, isLoadingRates }) {
  return (
    <Modal size="small" open={openModal} onClose={close}>
      <Modal.Header content={`${rates.length} Avaliações - ${customerName}`} />
      <Modal.Content scrolling>
        {isLoadingRates && (
          <Segment>
            <Dimmer active>
              <Loader size="medium">Loading</Loader>
            </Dimmer>
          </Segment>
        )}

        {!isLoadingRates &&
          rates.map(item => (
            <Item.Group divided>
              <Item>
                <Image
                  avatar
                  rounded
                  wrapped
                  bordered
                  size="tiny"
                  src={item.user_avatar}
                />
                <Item.Content verticalAlign="middle">
                  <Item.Header as="a">{item.mission_name}</Item.Header>
                  <Item.Description>{item.message}</Item.Description>
                  <Item.Description>
                    <Label basic size="medium">
                      <Icon name="star" color="yellow" />
                      <Label.Detail>{item.rate}</Label.Detail>
                    </Label>
                    <Label
                      basic
                      icon="user"
                      content={item.user_name}
                      size="medium"
                    />
                  </Item.Description>
                </Item.Content>
              </Item>
            </Item.Group>
          ))}

        {rates.length === 0 && !isLoadingRates && (
          <EmptyList text="Nenhuma avaliação disponível" icon="star" />
        )}
      </Modal.Content>
    </Modal>
  )
}

RatesModal.propTypes = {
  close: t.func,
  rates: t.arrayOf(t.any),
  openModal: t.bool,
  customerName: t.string,
  isLoadingRates: t.bool,
}

export default memo(RatesModal)
