import React from 'react'

import t from 'prop-types'
import { Button, Card } from 'semantic-ui-react'

function Options({ disabled, onClick }) {
  return (
    <Card fluid>
      <Card.Content>
        <Button
          content="Aprovar"
          color="green"
          icon="check"
          labelPosition="left"
          onClick={() => onClick('approve')}
          disabled={disabled}
        />
        <Button
          content="Reprovar"
          color="red"
          icon="close"
          labelPosition="left"
          onClick={() => onClick('reprove')}
          disabled={disabled}
        />
        <Button
          content="Exportar"
          color="orange"
          icon="download"
          labelPosition="left"
          onClick={() => onClick('export')}
          disabled={disabled}
        />
      </Card.Content>
    </Card>
  )
}

Options.propTypes = {
  disabled: t.bool,
  onClick: t.func,
}

export default Options
