import React from 'react'

import t from 'prop-types'

import { Row, Content, ContentValue, Title, Subtitle } from './styles'

const Item = ({ title, subtitle, value, onClick }) => (
  <Row onClick={onClick}>
    <Content>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Content>
    <ContentValue>
      <Title>{value}</Title>
    </ContentValue>
  </Row>
)

Item.propTypes = {
  title: t.string,
  subtitle: t.string,
  value: t.string,
  onClick: t.func,
}

export { Item }
