import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Confirm } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import CreateMissionsMainGroups from '~/components/CreateMissionsMainGroups'
import BadgesList from '~/components/Modal/BadgesList'
import CategoryGrouping from '~/components/Modal/CategoryGrouping'
import TableComponent from '~/components/Table'
import useMissionsMainGroups from '~/hooks/useMissionsMainGroups'
import useSubCategories from '~/hooks/useSubCategories'
import { FormSubcategory } from '~/pages/Categories/Subcategories/components/Form'
import Item from '~/pages/Categories/Subcategories/components/Item'

import Filter from './components/Filter'

export default function SubCategories() {
  const history = useHistory()
  const { categoryId } = useParams()
  const filterDefault = {
    limit: 10,
    offset: 0,
    title: '',
    categoryId,
    missionsMainGroupId: null,
  }
  const {
    subcategory,
    subcategories,
    getSubCategories,
    onSubmitSubcategory,
    onUpdateSubcategory,
    loadingSubcategories,
    subcategoriesCount,
    customFields,
    onAddCustomField,
    onRemoveCustomField,
    onChangeCustomFieldInput,
    onAddDeliveryPlaceCustomField,
    deliveryPlacesCustomFields,
    onRemoveDeliveryPlaceCustomField,
    onChangeDeliveryPlaceCustomFieldInput,
  } = useSubCategories()
  const { loadingGroups, getMissionsMainsGroups, groups } =
    useMissionsMainGroups()

  const [show, setShow] = useState(false)
  const [missionMain, setMissionMain] = useState(null)
  const [openBadge, setOpenBadge] = useState(false)
  const [openGroup, setOpenGroup] = useState(false)
  const [openRemove, setOpenRemove] = useState(false)
  const [openGroupCard, setOpenGroupCard] = useState(false)
  const [filters, setFilters] = useState(filterDefault)
  const [pagination, setPagination] = useState({
    activePage: 1,
    siblingRange: 1,
    boundaryRange: 0,
    showEllipsis: true,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,
  })

  // function onChange(id, value) {
  //   if (isBoolVariable.includes(id)) {
  //     return setMissionMain(prev => ({
  //       ...prev,
  //       user_value: 0,
  //       customer_value: 0,
  //       mission_commission_percent: null,
  //       [id]: value,
  //     }))
  //   }

  //   return setMissionMain(prev => ({ ...prev, [id]: value }))
  // }

  // function onChangeInvoiceField(field, value) {
  //   return setMissionMain(prev => ({
  //     ...prev,
  //     [field]: { ...prev.invoice_items, ...value },
  //   }))
  // }

  async function onSubmit(data) {
    return onSubmitSubcategory({
      ...data,
      category_missions_main_id: categoryId,
    })
  }

  function onChangeFilters(id, value) {
    setFilters(prev => ({ ...prev, [id]: value }))

    return getSubCategories({ ...filters, [id]: value })
  }

  function onClearFilters() {
    setFilters(filterDefault)

    return getSubCategories(filterDefault)
  }

  async function onUpdate(params) {
    const subcategoryUpdated = { ...missionMain, ...params }

    delete subcategoryUpdated.total_rows
    delete subcategoryUpdated.category_name

    return onUpdateSubcategory(subcategoryUpdated)
  }

  function onOpenBadge(item) {
    setOpenBadge(true)

    return setMissionMain(item)
  }

  function onOpenGroup(item) {
    setOpenGroup(true)

    return setMissionMain(item)
  }

  function onOpenRemove(item) {
    setOpenRemove(true)

    return setMissionMain(item)
  }

  function handleOnChangeBadge(badgeId) {
    setMissionMain(prevState => ({
      ...prevState,
      badge_id: badgeId,
    }))
  }

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * filters.limit

    setPagination(prev => ({ ...prev, activePage }))
    setFilters(prev => ({ ...prev, offset }))

    return getSubCategories({ ...filters, offset })
  }

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      link: '/categories',
      name: 'Categorias',
    },
    {
      active: true,
      hasLink: false,
      name: 'Sub-categorias',
    },
  ]

  useEffect(() => {
    getMissionsMainsGroups()
  }, []) //eslint-disable-line

  useEffect(() => {
    getSubCategories(filters)
  }, []) //eslint-disable-line

  return (
    <>
      <Breadcrumbs items={breadcrumbsList} history={history} />

      <Filter
        groups={groups}
        filters={filters}
        showCategory={show}
        showGroup={openGroupCard}
        clearFilters={onClearFilters}
        isLoading={loadingSubcategories}
        onChangeFilters={onChangeFilters}
        onOpenCategory={() => setShow(prev => !prev)}
        onOpenGroups={() => setOpenGroupCard(prev => !prev)}
      />

      {openGroupCard ? <CreateMissionsMainGroups /> : null}

      <FormSubcategory
        subcategory={subcategory}
        show={show}
        onSubmit={onSubmit}
        customFields={customFields}
        loading={loadingSubcategories}
        onAddCustomField={onAddCustomField}
        onRemoveCustomField={onRemoveCustomField}
        onChangeCustomFieldInput={onChangeCustomFieldInput}
        onAddDeliveryPlaceCustomField={onAddDeliveryPlaceCustomField}
        deliveryPlacesCustomFields={deliveryPlacesCustomFields}
        onRemoveDeliveryPlaceCustomField={onRemoveDeliveryPlaceCustomField}
        onChangeDeliveryPlaceCustomFieldInput={
          onChangeDeliveryPlaceCustomFieldInput
        }
      />

      <TableComponent
        data={subcategories}
        count={subcategoriesCount}
        limit={filters.limit}
        offset={filters.offset}
        totalCount={subcategoriesCount}
        activePage={pagination.activePage}
        handlePaginationChange={handlePaginationChange}
        showFirstAndLastNav={pagination.showFirstAndLastNav}
        showPreviousAndNextNav={pagination.showPreviousAndNextNav}
        renderItem={(item, index) => (
          <Item
            item={item}
            index={index}
            key={item.uid}
            history={history}
            categoryId={categoryId}
            onRemove={() => onOpenRemove(item)}
            onOpenBadge={() => onOpenBadge(item)}
            onOpenGroup={() => onOpenGroup(item)}
            changeStatus={() => onUpdate({ ...item, status: !item.status })}
          />
        )}
        emptyText={{
          icon: 'check circle outline',
          text: 'Nenhuma Categorias...',
        }}
        columns={[
          '',
          'Título',
          'Aprovação',
          'Expiração',
          'Participantes',
          'Valor Cliente',
          'Criado Em',
          'Atualizado Em',
          '',
        ]}
      />

      <Confirm
        header="Alerta"
        open={openRemove}
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onCancel={() => setOpenRemove(false)}
        onConfirm={() => {
          setOpenRemove(false)
          return onUpdate({ deleted: true })
        }}
        content="Tem certeza deseja remover este item?"
      />

      <BadgesList
        openModal={openBadge}
        onChangeBadge={handleOnChangeBadge}
        badgeId={missionMain?.badge_id}
        close={() => setOpenBadge(false)}
        onRemoveBadge={() => {
          setOpenBadge(false)
          return onUpdate({ badge_id: null })
        }}
        onSaveBadge={() => {
          setOpenBadge(false)
          return onUpdate({ badge_id: missionMain?.badge_id })
        }}
      />

      <CategoryGrouping
        groups={groups}
        openModal={openGroup}
        loading={loadingGroups}
        close={() => setOpenGroup(false)}
        onSubmitGroupId={groupId => {
          setOpenGroup(false)
          return onUpdate({ missions_main_group_id: groupId })
        }}
      />
    </>
  )
}
