import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Tab } from 'semantic-ui-react'

import useFiles from '~/hooks/useFiles'
import useFormsData from '~/hooks/useFormsData'
import useMissionsUsers from '~/hooks/useMissionsUsers'
import usePhotos from '~/hooks/usePhotos'
import Main from '~/pages/Main'

import Activities from './components/Activities'
import Answers from './components/Answers'
import Files from './components/Files'
import Photos from './components/Photos'
import ProgressHeader from './components/ProgressHeader'

function MissionsProgress() {
  const history = useHistory()

  const { missionsUsersId } = useParams()
  const { getProgress, missionsUsersData } = useMissionsUsers()
  const { getFormsDataByMissionsUsersId, formsData } = useFormsData()
  const { getPhotos, photos } = usePhotos()
  const { getFiles, files } = useFiles()

  const [tabs, setTabs] = useState([])

  const panes = [
    {
      menuItem: 'Atividades',
      render: () => (
        <Tab.Pane attached={false}>
          <Activities
            missionsUsersId={missionsUsersId}
            userData={missionsUsersData}
          />
        </Tab.Pane>
      ),
    },
  ]

  function handlePanes() {
    if (formsData.length) {
      panes.push({
        menuItem: 'Respostas',
        render: () => (
          <Tab.Pane attached={false}>
            <Answers formsData={formsData} />
          </Tab.Pane>
        ),
      })
    }

    if (photos.length) {
      panes.push({
        menuItem: 'Fotos',
        render: () => (
          <Tab.Pane attached={false}>
            <Photos photos={photos} />
          </Tab.Pane>
        ),
      })
    }

    if (files.length) {
      panes.push({
        menuItem: 'Arquivos',
        render: () => (
          <Tab.Pane attached={false}>
            <Files files={files} />
          </Tab.Pane>
        ),
      })
    }

    return setTabs(panes)
  }

  useEffect(() => {
    getProgress({ missionsUsersId })
  }, []) // eslint-disable-line

  useEffect(() => {
    getFormsDataByMissionsUsersId(missionsUsersId)
  }, []) // eslint-disable-line

  useEffect(() => {
    getPhotos({ id: missionsUsersId })
  }, []) // eslint-disable-line

  useEffect(() => {
    getFiles(missionsUsersId)
  }, []) // eslint-disable-line

  useEffect(() => {
    handlePanes()
  }, [formsData, photos, files]) // eslint-disable-line

  return (
    <Main>
      <ProgressHeader
        missionsUsersData={missionsUsersData}
        onClick={() => history.goBack()}
      />
      <Tab menu={{ secondary: true, pointing: true }} panes={tabs} />
    </Main>
  )
}

export default MissionsProgress
