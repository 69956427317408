import t from 'prop-types'

export const missionsMainProps = {
  values: t.objectOf({
    title: t.string,
    time_to_appreciate: t.string,
    expiring_time: t.string,
    fix_time: t.string,
    customer_value: t.number,
    user_value: t.number,
    user_value_prime: t.number,
    user_value_black: t.number,
    mission_commission_percent: t.number,
    customer_value_bonus: t.number,
    user_value_bonus: t.number,
    participants: t.number,
    user_point_value: t.string,
    points_label: t.string,
    avatar: t.string,
    image_path: t.string,
    has_bonus: t.bool,

    no_location: t.string,
    can_restart: t.string,
    can_multiple: t.string,
    show_only_if_badge: t.string,
    invoice_items: t.string,

    has_points: t.bool,
    has_points_value: t.bool,
    has_value_prime: t.bool,
    has_value_black: t.bool,
    has_pos: t.bool,
    has_forms: t.bool,
    has_photo: t.bool,
    has_scanner: t.bool,
    has_webview: t.bool,
    has_invoice: t.bool,
    has_products: t.bool,
    has_commission: t.bool,
    has_attachment: t.bool,
    has_import_image: t.bool,
    has_custom_status: t.bool,
    has_auto_approval: t.bool,
    has_value_variable: t.bool,
    has_import_document: t.bool,
    has_value_percentage: t.bool,
    has_enable_scheduling: t.bool,
    has_check_availability: t.bool,
    has_signature: t.bool,
    has_rating: t.bool,
    has_recurring_payment: t.bool,

    check_in_check_out: t.bool,
    checkin_has_sms_confirm: t.bool,
    checkin_has_code_confirm: t.bool,
    check_distance: t.string,

    description: t.string,
    webview_link: t.string,
    should_inactivate_when_reproved: t.string,
    is_expiring_weekend: t.bool,

    has_shared_link: t.bool,
    shared_links_settings: t.string,
    shared_links_api_integration: t.string,
  }),
}
