import React, { useCallback, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import PropTypes from 'prop-types'
import {
  Input,
  Card,
  Form,
  Button,
  Select,
  Dropdown,
  Icon,
} from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'
import useUfsAndCities from '~/hooks/useUfsAndCities'

import { InputField, ButtonsWrapper } from './styles'

export default function Filter({
  final,
  initial,
  categoryId,
  customerId,
  missions_main_id,
  userName,

  isLoading,
  customers,
  categories,
  subCategories,
  handlerChange,
  submit,
  clearFilters,
  handleExport,
  submitRemoveUsers,
  search,
}) {
  const {
    cities,
    ufs,
    citiesOptions,
    ufsOptions,
    onChangeCities,
    onChangeUFs,
  } = useUfsAndCities()
  const [progress, setProgress] = useState([1, 7])

  useEffect(() => {
    handlerChange(cities, 'cities')
  }, [cities]) //eslint-disable-line

  useEffect(() => {
    handlerChange(ufs, 'ufs')
  }, [ufs]) //eslint-disable-line

  useEffect(() => {
    handlerChange(progress, 'progress')
  }, [progress]) //eslint-disable-line

  const handleClearFilters = useCallback(() => {
    onChangeUFs([])
    setProgress([1, 7])
    clearFilters()
  }, [onChangeUFs, clearFilters])

  const handleChangeProgress = useCallback(selectedProgress => {
    setProgress(selectedProgress)
  }, [])

  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              value={search}
              control={Input}
              placeholder="MB ou MU"
              onChange={(e, { value }) => handlerChange(value, 'search')}
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Clientes"
              options={customers}
              value={customerId}
              onChange={(e, { value }) => handlerChange(value, 'customerId')}
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Categoria"
              options={categories.map(category => ({
                key: category.uid,
                value: category.uid,
                text: category.name,
              }))}
              value={categoryId}
              onChange={(e, { value }) => handlerChange(value, 'categoryId')}
            />
            {subCategories.length > 0 && (
              <Form.Field
                control={Select}
                search
                selection
                placeholder="Sub-Categoria"
                options={subCategories}
                value={missions_main_id}
                onChange={(e, { value }) =>
                  handlerChange(value, 'missions_main_id')
                }
              />
            )}
            <Form.Field
              selectsStart
              control={DatePicker}
              dateFormat="dd/MM/yyyy"
              placeholderText="Data Inicio"
              selected={initial}
              startDate={initial}
              endDate={final}
              onChange={value => handlerChange(value, 'initial')}
            />
            <Form.Field
              control={DatePicker}
              id="endDate"
              selectsEnd
              dateFormat="dd/MM/yyyy"
              placeholderText="Data Fim"
              selected={final}
              startDate={initial}
              endDate={final}
              onChange={value => handlerChange(value, 'final')}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <InputField
              control={Input}
              icon="search"
              className="icon"
              value={userName}
              placeholder="Nome de usuário"
              onChange={(e, { value }) => handlerChange(value, 'userName')}
            />
            <Form.Field fluid disabled={!ufsOptions.length}>
              <Dropdown
                search
                selection
                multiple
                options={ufsOptions}
                placeholder="UFs"
                value={ufs || []}
                onChange={(e, { value }) => onChangeUFs(value)}
              />
            </Form.Field>
            <Form.Field fluid disabled={!citiesOptions.length || !ufs?.length}>
              <Dropdown
                search
                selection
                multiple
                options={citiesOptions}
                placeholder="Cidades"
                value={cities || []}
                onChange={(e, { value }) => onChangeCities(value)}
              />
            </Form.Field>
            <Form.Field fluid>
              <Dropdown
                search
                selection
                multiple
                options={[
                  { key: 1, value: 1, text: 'Iniciado' },
                  { key: 2, value: 2, text: 'Concluído' },
                  { key: 3, value: 3, text: 'Rejeitado' },
                  { key: 4, value: 4, text: 'Expirado' },
                  { key: 5, value: 5, text: 'Excluido' },
                  { key: 6, value: 6, text: 'Desistência' },
                  { key: 7, value: 7, text: 'Missão Incompleta' },
                  {
                    key: 8,
                    value: 8,
                    text: 'Aguardando Aprovação de Orçamento',
                  },
                ]}
                placeholder="Progress"
                value={progress || []}
                onChange={(e, { value }) => handleChangeProgress(value)}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Card.Content>
      <ButtonsWrapper>
        <Button
          icon="check"
          color="green"
          content="Filtrar"
          labelPosition="left"
          onClick={submit}
        />

        <Button
          icon="undo"
          color="grey"
          content="Limpar Filtros"
          labelPosition="left"
          onClick={() => handleClearFilters()}
          loading={isLoading}
        />
        <Button
          icon
          color="orange"
          labelPosition="left"
          onClick={handleExport}
          loading={isLoading}
          disabled={isLoading}
        >
          <Icon name="download" />
          Gerar Relatório
        </Button>
        <Button
          content="Remover Usuários"
          color="red"
          icon="times"
          labelPosition="left"
          onClick={submitRemoveUsers}
        />
      </ButtonsWrapper>
    </Card>
  )
}

Filter.propTypes = {
  final: PropTypes.number,
  initial: PropTypes.number,
  categoryId: PropTypes.string,
  customerId: PropTypes.string,
  missions_main_id: PropTypes.string,
  isLoading: PropTypes.bool,
  customers: PropTypes.arrayOf(PropTypes.any),
  categories: PropTypes.arrayOf(PropTypes.any),
  subCategories: PropTypes.arrayOf(PropTypes.any),
  handlerChange: PropTypes.func,
  submit: PropTypes.func,
  clearFilters: PropTypes.func,
  handleExport: PropTypes.func,
  userName: PropTypes.string,
  submitRemoveUsers: PropTypes.func,
  search: PropTypes.string,
}
