import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import history from '~/services/history'

import attachments from './attachments/reducer'
import badges from './badges/reducer'
import badgesForms from './badgesForms/reducer'
import budget from './budget/reducer'
import budgetRequest from './budgetRequest/reducer'
import campaign from './campaign/reducer'
import categories from './categories/reducer'
import customStatus from './customStatus/reducer'
import dashboard from './dashboard/reducer'
import formHasMission from './formHasMission/reducer'
import formsData from './formsData/reducer'
import formsItems from './formsItems/reducer'
import formsItemsMultiples from './formsItemsMultiples/reducer'
import formsMissions from './formsMissions/reducer'
import formsUsers from './formsUsers/reducer'
import formsUsersData from './formsUsersData/reducer'
import formsUsersItems from './formsUsersItems/reducer'
import integrations from './integrations/reducer'
import invoices from './invoices/reducer'
import { logs } from './logs/reducer'
import metrics from './metrics/reducer'
import missions from './missions/reducer'
import missionsParticipants from './missionsParticipants/reducer'
import missionsUpdate from './missionsUpdate/reducer'
import registers from './registers/reducer'
import removeAttachment from './removeAttachment/reducer'
import transactions from './transactions/reducer'
import updateOrder from './updateOrder/reducer'
import uploadAttachments from './uploadAttachments/reducer'
import user from './user/reducer'
import users from './users/reducer'
import usersConsultants from './usersConsultants/reducer'

export default combineReducers({
  router: connectRouter(history),

  ...users,
  ...badges,
  ...budget,
  ...metrics,
  ...invoices,
  ...campaign,
  ...missions,
  ...registers,
  ...dashboard,
  ...formsData,
  ...categories,
  ...formsItems,
  ...formsUsers,
  ...badgesForms,
  ...integrations,
  ...customStatus,
  ...formsMissions,
  ...formsUsersData,
  ...formsUsersItems,
  ...usersConsultants,
  ...formsItemsMultiples,

  // \/\/ REFATORAR \/\/

  logs,
  user,

  updateOrder,
  transactions,
  budgetRequest,

  formHasMission,
  missionsUpdate,
  missionsParticipants,

  attachments,
  removeAttachment,
  uploadAttachments,
})
