import React from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Button, Icon, Table } from 'semantic-ui-react'

import { formatPrice } from '~/utils/format'
import { statusRegisterLabel } from '~/utils/types'

export function Item({ register, onRegisterDetail, onReceipt, loading }) {
  const currencyValue =
    register.user_value_variable !== null
      ? register.user_value_variable
      : register.mission_value

  const currencyWithPoints = register.user_point_value
    ? '####'
    : formatPrice(currencyValue)

  return (
    <Table.Row key={register.uid}>
      <Table.Cell>{register.mu_key}</Table.Cell>
      <Table.Cell>{register.customer_name}</Table.Cell>
      <Table.Cell>{register.identifier}</Table.Cell>
      <Table.Cell
        positive={register.status === 1}
        negative={register.status === 2}
      >
        {statusRegisterLabel[register.status]}
      </Table.Cell>
      <Table.Cell>{register.userName}</Table.Cell>
      <Table.Cell>{register.missions_main_title}</Table.Cell>
      <Table.Cell>{register.missionName}</Table.Cell>
      <Table.Cell>{currencyWithPoints}</Table.Cell>
      <Table.Cell>{register.user_point_value}</Table.Cell>
      <Table.Cell>
        {dayjs(new Date(register.created_at)).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>
        {register.updated_at &&
          dayjs(new Date(register.updated_at)).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Button
          icon
          primary
          size="small"
          labelPosition="left"
          onClick={() => onRegisterDetail(register.uid)}
        >
          <Icon name="write" />
          Avaliar
        </Button>
        {register.status === 1 && (
          <Button
            icon
            negative
            size="small"
            labelPosition="left"
            loading={loading}
            disabled={loading}
            onClick={() => onReceipt(register.missions_users_id)}
          >
            <Icon name="file pdf outline" />
            Recibo
          </Button>
        )}
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  register: {
    uid: t.string,
    mu_key: t.string,
    customer_name: t.string,
    identifier: t.string,
    status: t.number,
    userName: t.string,
    missions_main_title: t.string,
    user_value_variable: t.string,
    mission_value: t.string,
    missionName: t.string,
    user_point_value: t.string,
    created_at: t.string,
    updated_at: t.string,
    missions_users_id: t.string,
  },
  onRegisterDetail: t.func,
  onReceipt: t.func,
  loading: t.bool,
}
