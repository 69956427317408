export default [
  {
    icon: 'dashboard',
    text: 'Dashboard',
    path: '/dashboard',
    restrictions: [],
  },
  {
    icon: 'signal',
    text: 'Métricas',
    path: '/dashboard-metrics',
    restrictions: [],
  },
  {
    restrictions: [],
    text: 'Missões',
    icon: 'rocket',
    hasSubMenu: true,
    subMenu: [
      {
        text: 'Lançadas',
        path: '/missions/launched',
        uncommonRestrictions: [],
      },
      {
        text: 'Expirando',
        path: '/missions/expiring',
        uncommonRestrictions: [],
      },
      {
        text: 'Finalizadas',
        path: '/registers',
        uncommonRestrictions: [],
      },
      {
        text: 'Métricas',
        path: '/missions/metrics',
        uncommonRestrictions: [],
      },
    ],
  },
  {
    restrictions: [],
    text: 'Workforce Manager',
    icon: 'bolt',
    path: '/workforce',
  },
  {
    icon: 'clipboard',
    text: 'Categorias',
    path: '/categories',
    restrictions: ['Basic'],
  },
  {
    icon: 'building',
    text: 'Clientes',
    path: '/customers',
    restrictions: ['Basic'],
  },
  {
    icon: 'diamond',
    text: 'Assinantes',
    path: '/subscribes',
    restrictions: [],
  },
  {
    icon: 'users',
    text: 'Usuários',
    path: '/users',
    restrictions: [],
  },
  {
    restrictions: ['Leader', 'Basic'],
    icon: 'dollar',
    text: 'Financeiro',
    hasSubMenu: true,
    subMenu: [
      {
        text: 'Pagamentos Clientes',
        path: '/financial/payments-customers',
        uncommonRestrictions: [],
      },
      {
        text: 'Pagamentos Usuários',
        path: '/financial/payments-users',
        uncommonRestrictions: [],
      },
      {
        text: 'Transferências Usuários',
        path: '/financial/transfer-requests',
        uncommonRestrictions: [],
      },
      {
        text: 'Mutiplicadores - Planos',
        path: '/financial/multipliers/plans',
        uncommonRestrictions: [],
      },
      {
        text: 'Mutiplicadores - Solicitações',
        path: '/financial/multipliers/requests',
        uncommonRestrictions: [],
      },
      {
        text: 'Doações - Instituições de Caridade',
        path: '/financial/charities-institutions/charities',
        uncommonRestrictions: [],
      },
      {
        text: 'Doações - Solicitações',
        path: '/financial/charities-institutions/requests',
        uncommonRestrictions: [],
      },
    ],
  },
  {
    restrictions: ['Leader', 'Basic'],
    text: 'Webhook',
    icon: 'linkify',
    path: '/webhook',
    hasSubMenu: false,
  },
  {
    restrictions: ['Leader', 'Basic'],
    text: 'Certificados',
    icon: 'wpforms',
    hasSubMenu: true,
    subMenu: [
      {
        text: 'Lista',
        path: '/badges',
        uncommonRestrictions: [],
      },
      {
        text: 'Formulários',
        path: '/badges/forms-users-extra',
        uncommonRestrictions: [],
      },
      {
        text: 'Solicitações de Certificados',
        path: '/badges/requests',
        uncommonRestrictions: [],
      },
    ],
  },
  {
    icon: 'user circle',
    text: 'Contas',
    path: '/accounts',
    restrictions: ['Leader', 'Basic'],
  },
  {
    icon: 'file',
    text: 'Exportação',
    path: '/exportation',
    restrictions: [],
  },
  {
    restrictions: ['Basic'],
    icon: 'upload',
    text: 'Importação',
    hasSubMenu: true,
    subMenu: [
      {
        text: 'Missões',
        path: '/imports/missions',
        uncommonRestrictions: [],
      },
      {
        text: 'Arquivos',
        path: '/imports/link-files',
        uncommonRestrictions: [],
      },
      {
        text: 'Produtos PDV',
        path: '/imports/products-pdv',
        uncommonRestrictions: ['Leader'],
      },
      {
        text: 'Produtos Clientes',
        path: '/imports/products-customers',
        uncommonRestrictions: [],
      },
      {
        text: 'Formulários Clientes',
        path: '/imports/forms-customers',
        uncommonRestrictions: [],
      },
    ],
  },

  {
    icon: 'list',
    text: 'Desafios',
    restrictions: [],
    hasSubMenu: true,
    subMenu: [
      {
        text: 'Desafios',
        path: '/challenges',
        uncommonRestrictions: [],
      },
      {
        text: 'Tipos de desafios',
        path: '/type-challenges',
        uncommonRestrictions: [],
      },
    ],
  },
  {
    restrictions: ['Leader', 'Basic'],
    icon: 'bell',
    text: 'Marketing',
    hasSubMenu: true,
    subMenu: [
      {
        text: 'Campanhas',
        path: '/marketing/campaign',
        uncommonRestrictions: [],
      },
      {
        text: 'Promoções',
        path: '/marketing/promotions',
        uncommonRestrictions: [],
      },
    ],
  },
  {
    icon: 'star',
    text: 'Avaliações',
    hasSubMenu: true,
    restrictions: [],
    subMenu: [
      {
        text: 'Usuários',
        path: '/rating/users',
        uncommonRestrictions: [],
      },
      {
        text: 'Clientes',
        path: '/rating/customers',
        uncommonRestrictions: [],
      },
    ],
  },
  {
    icon: 'shopping cart',
    text: 'Loja',
    hasSubMenu: true,
    restrictions: [],
    subMenu: [
      {
        text: 'Categorias',
        path: '/store/categories',
        uncommonRestrictions: [],
      },
      {
        text: 'Produtos',
        path: '/store/products',
        uncommonRestrictions: [],
      },
      {
        text: 'Pedidos',
        path: '/store/orders',
        uncommonRestrictions: [],
      },
    ],
  },
]
