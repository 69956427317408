import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useConsultantsMissionsMain() {
  const [consultants, setConsultants] = useState([])
  const [loadingConsultants, setLoading] = useState(false)

  const getConsultantsMissionsMain = useCallback(async params => {
    try {
      setLoading(true)
      const res = await api.get('consultants-missions-main', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setConsultants(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  async function onSubmitConsultantsMissionsMain(data) {
    try {
      setLoading(true)
      const res = await api.post('consultants-missions-main', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return Message().success('Líder adicionado com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function onRemoveConsultantsMissionsMain(id) {
    try {
      setLoading(true)
      const res = await api.delete(`consultants-missions-main/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 204) {
        throw new Error(res.data.message)
      }

      return Message().success('Líder removido com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    consultants,
    loadingConsultants,
    getConsultantsMissionsMain,
    onSubmitConsultantsMissionsMain,
    onRemoveConsultantsMissionsMain,
  }
}
