import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

function useUsersTransactions() {
  const [loading, setLoading] = useState(false)
  const [usersTransactions, setUsersTransactions] = useState([])
  const [totalUsersTransactions, setTotalUsersTransactions] = useState(0)
  const [usersTransactionsReport, setUsersTransactionsReport] = useState([])
  const [inconsistency, setInconsistency] = useState(false)

  async function getUsersTransactions(params) {
    try {
      setLoading(true)

      const res = await api.get('users-transactions/admin', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setTotalUsersTransactions(res.data.count)
      return setUsersTransactions(res.data.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function getUsersTransactionsReport(userId) {
    try {
      setLoading(true)

      const res = await api.get('users-transactions/admin/report', {
        params: { userId },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setInconsistency(res.data.areThereInconsistency)
      return setUsersTransactionsReport(res.data.transactionsReport)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    getUsersTransactions,
    loading,
    usersTransactions,
    totalUsersTransactions,
    getUsersTransactionsReport,
    usersTransactionsReport,
    inconsistency,
  }
}

export default useUsersTransactions
