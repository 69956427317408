import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import {
  Form,
  Card,
  Table as SemanticUiTable,
  Dropdown,
  Message,
} from 'semantic-ui-react'

import Table from '~/components/Table'
import useCustomers from '~/hooks/useCustomers'
import useForms from '~/hooks/useForms'
import useReports from '~/hooks/useReports'

import FormExportImage from './components/FormExportImage'
import ZipImageModal from './components/ZipImagesModal'
import { TYPE_EXPORTATION } from './helpers'

export default () => {
  const DATA_FORMAT = 'DD/MM/YYYY HH:mm'
  const COLUMNS = [
    'ID',
    'Formulário',
    'Período',
    'Criando em',
    'Atualizado em',
    'Situação',
    'Ações',
  ]
  const [customerSelected, setCustomerSelected] = useState(null)
  const { customers, getAllCustomers } = useCustomers()
  const { forms, getForms } = useForms([])
  const [customerOptions, setCustomerOptions] = useState([])
  const [formOptions, setFormOptions] = useState([])
  const [isOpenZipLinkModal, setIsOpenZipLinkModal] = useState(false)

  const {
    exportPhotos,
    exportImages,
    errorsValidation,
    enableLoader,
    setFilters,
    filters,
    getReports,
    setPagination,
    paginate,
    setOffset,
    limit,
    offset,
    reports,
    generateXlsUrl,
    generateZipSignedUrls,
    reportSelected,
  } = useReports()

  const changeInputValue = (key, value) => {
    setFilters({ ...filters, [key]: value })
  }

  const loadFormOptions = () => {
    const options = []
    for (let index = 0; index < forms.length; index += 1) {
      const item = forms[index]
      options.push({
        text: item.name,
        value: item.uid,
      })
    }

    setFormOptions(options)
  }

  const loadCustomerOptions = () => {
    const options = []
    for (let index = 0; index < customers.length; index += 1) {
      const item = customers[index]
      options.push({
        text: item.name,
        value: item.uid,
      })
    }

    setCustomerOptions(options)
  }

  const isLoadedForms = () => {
    return filters.type != null && formOptions.length > 0
  }

  const hasZip = data => {
    return data.url != null && data.is_zip
  }

  const hasExcel = data => {
    return data.url != null && !data.is_zip
  }

  const renderItem = data => {
    return (
      <SemanticUiTable.Row>
        <SemanticUiTable.Cell>{data.uid}</SemanticUiTable.Cell>
        <SemanticUiTable.Cell>{data.form_name}</SemanticUiTable.Cell>
        <SemanticUiTable.Cell>
          {dayjs(data.initialized_at).format(DATA_FORMAT)} -
          {dayjs(data.finalized_at).format(DATA_FORMAT)}
        </SemanticUiTable.Cell>
        <SemanticUiTable.Cell>
          {dayjs(data.created_at).format(DATA_FORMAT)}
        </SemanticUiTable.Cell>
        <SemanticUiTable.Cell>
          {dayjs(data.updated_at).format(DATA_FORMAT)}
        </SemanticUiTable.Cell>
        <SemanticUiTable.Cell>{data.status}</SemanticUiTable.Cell>
        <SemanticUiTable.Cell>
          <Dropdown pointing="right" icon="ellipsis vertical">
            <Dropdown.Menu>
              {hasZip(data) && (
                <Dropdown.Item
                  icon="images"
                  onClick={() => openZipLinkModal(data)}
                  text="Baixar imagens ou fotos"
                />
              )}
              {hasExcel(data) && (
                <Dropdown.Item
                  icon="file excel outline"
                  onClick={() => generateXlsUrl(data)}
                  text="Baixar excel"
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </SemanticUiTable.Cell>
      </SemanticUiTable.Row>
    )
  }

  const changePage = page => {
    setPagination(() => ({
      ...paginate,
      page,
    }))
    setOffset(page - 1)
  }

  const getOffset = () => {
    const result = offset - 1
    if (result < 0) {
      return 0
    }

    return offset * limit
  }

  const exportImageAndUpdateReportList = async () => {
    if (filters.type === TYPE_EXPORTATION.PHOTOS) {
      await exportPhotos()
    } else {
      await exportImages()
    }

    if (customerSelected) {
      getReports(paginate.activePage, customerSelected)
    }
  }

  const openZipLinkModal = data => {
    generateZipSignedUrls(data)
    setIsOpenZipLinkModal(true)
  }

  useEffect(() => {
    getAllCustomers()
  }, []) //eslint-disable-line

  useEffect(() => {
    loadCustomerOptions()
  }, [customers]) //eslint-disable-line

  useEffect(() => {
    loadFormOptions()
  }, [forms]) //eslint-disable-line

  return (
    <>
      <ZipImageModal
        isOpenZipLinkModal={isOpenZipLinkModal}
        setIsOpenZipLinkModal={setIsOpenZipLinkModal}
        reportSelected={reportSelected}
      />

      <Message
        icon="info circle"
        header="Atenção!"
        content="Os arquivos da exportação de imagens(Formulário) e imagens(Fotos) ficaram disponível por 5 dias, após 5 dias eles são deletados.
          Caso precise baixar uma exportação de imagens, mas os arquivos da exportação não estão disponivel mais você precisa fazer a exportação novamente."
      />

      <FormExportImage
        errorsValidation={errorsValidation}
        formOptions={formOptions}
        isLoadedForms={isLoadedForms}
        changeInputValue={changeInputValue}
        enableLoader={enableLoader}
        exportImages={exportImageAndUpdateReportList}
        filters={filters}
        getForms={getForms}
        customerOptions={customerOptions}
      />
      <Card style={{ width: '100%' }}>
        <Card.Content>
          <Form>
            <Form.Select
              search
              value={customerSelected}
              options={customerOptions}
              onChange={(event, data) => {
                setCustomerSelected(data.value)
                getReports(paginate.activePage, data.value)
              }}
              label="Selecionar um cliente"
            />
          </Form>

          {reports.length > 0 && (
            <Table
              handlePaginationChange={(_, pagination) => {
                changePage(pagination.activePage)
                getReports(pagination.activePage, customerSelected)
              }}
              emptyText="Não foi encontrado registros"
              showFirstAndLastNav
              showPreviousAndNextNav
              isLoading={false}
              columns={COLUMNS}
              data={reports}
              totalCount={paginate.total}
              count={paginate.total}
              limit={limit}
              offset={getOffset()}
              activePage={offset}
              renderItem={renderItem}
            />
          )}

          {reports.length === 0 && (
            <>
              <br />
              <p style={{ textAlign: 'center' }}>
                <strong>
                  Não foi encontrado nenhum registro ou você ainda não
                  selecionou um cliente.
                </strong>
              </p>
            </>
          )}
        </Card.Content>
      </Card>
    </>
  )
}
