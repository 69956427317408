import { cond, equals, always } from 'ramda'

export const STATUS_COLOR = cond([
  [equals('CHECKED'), always('green')],
  [equals('SUCCESS'), always('blue')],
  [equals('MULTIPLES'), always('yellow')],
  [equals('NOT_FOUND'), always('red')],
  [equals('ERROR'), always('orange')],
  [equals('IMPORTED'), always('grey')],
])

export const STATUS_LABEL = cond([
  [equals('CHECKED'), always('Conferido')],
  [equals('SUCCESS'), always('Encontrado')],
  [equals('MULTIPLES'), always('Multiplos')],
  [equals('NOT_FOUND'), always('Não encontrado')],
  [equals('ERROR'), always('Erro ao requisitar endereço')],
  [equals('IMPORTED'), always('Importado')],
])
