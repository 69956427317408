import { useCallback, useState } from 'react'

import api from '~/services/api'
import { CUSTOMERS_RESTRICTED_SUBCATEGORIES } from '~/services/api/endpoints'
import Message from '~/utils/messages'

export default function useRestrictedSubCategories() {
  const [restrictedSubcategories, setRestrictedSubcategories] = useState([])
  const [loadingRestricted, setLoadingRestricted] = useState(false)
  const [loadingRemoveRestricted, setLoadingRemoveRestricted] = useState(false)

  const getRestrictedSubCategories = useCallback(async customerId => {
    try {
      setLoadingRestricted(true)

      const res = await api.get(CUSTOMERS_RESTRICTED_SUBCATEGORIES, {
        params: { customerId },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingRestricted(false)
      return setRestrictedSubcategories(res.data)
    } catch (error) {
      setLoadingRestricted(false)
      return Message().error(error.message)
    }
  }, [])

  const removeSubCategoriesByCustomerId = useCallback(
    async id => {
      try {
        setLoadingRemoveRestricted(true)

        const res = await api.delete(CUSTOMERS_RESTRICTED_SUBCATEGORIES, {
          params: { customersRestrictedId: id },
        })

        if (res.status !== 200) {
          throw new Error(res.data.message)
        }

        setLoadingRemoveRestricted(false)
        Message().success('Subcategoria removida com sucesso!')
        return setRestrictedSubcategories(
          restrictedSubcategories.filter(i => i.id !== res.data.id)
        )
      } catch (error) {
        setLoadingRemoveRestricted(false)
        return Message().error(error.message)
      }
    },
    [restrictedSubcategories]
  )

  async function createRestrictedSubCategory(data) {
    try {
      setLoadingRestricted(true)

      const res = await api.post(CUSTOMERS_RESTRICTED_SUBCATEGORIES, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingRestricted(false)
      getRestrictedSubCategories(data.customerId)
      return Message().success('Subcategoria criada com sucesso!')
    } catch (error) {
      setLoadingRestricted(false)
      return Message().error(error.message)
    }
  }

  return {
    loadingRestricted,
    loadingRemoveRestricted,
    restrictedSubcategories,
    createRestrictedSubCategory,
    getRestrictedSubCategories,
    removeSubCategoriesByCustomerId,
  }
}
