import { BADGET_GET, BADGET_FETCH } from '~/services/api/endpoints'
import { get } from '~/utils/actionsRequests'

const findAll = () =>
  get({
    url: BADGET_FETCH,
    typeFetch: 'BADGET_FETCH',
    typeError: 'BADGET_FETCH_FAIL',
    typeResponse: 'BADGET_FETCH_OK',
  })

const findById = id =>
  get({
    url: BADGET_GET(id),
    typeFetch: 'BADGET_GET',
    typeError: 'BADGET_GET_FAIL',
    typeResponse: 'BADGET_GET_OK',
  })

export { findAll, findById }
