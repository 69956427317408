import React from 'react'

import t from 'prop-types'
import { Table, Button, Image } from 'semantic-ui-react'

const Item = ({ item, myRef, loading, onClick, uploadImage }) => (
  <Table.Row key={String(item.uid)}>
    <Table.Cell singleLine style={{ maxWidth: 80 }}>
      {item.image_path && <Image src={item.image_path} size="tiny" />}
    </Table.Cell>
    <Table.Cell>{item.father_id}</Table.Cell>
    <Table.Cell>{item.children_father_id}</Table.Cell>
    <Table.Cell>{item.description}</Table.Cell>
    <Table.Cell>
      <input
        type="file"
        ref={myRef}
        id={String(item.uid)}
        accept="image/*"
        style={{ display: 'none' }}
        onChange={e => uploadImage(e)}
      />
      <Button
        icon="upload"
        color="orange"
        loading={loading}
        onClick={onClick}
        labelPosition="right"
        content="Upload Imagem"
      />
    </Table.Cell>
  </Table.Row>
)

Item.propTypes = {
  myRef: t.objectOf(t.any),
  onClick: t.func,
  loading: t.bool,
  uploadImage: t.func,
  item: t.objectOf(t.any),
}

export default Item
