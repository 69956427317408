import React from 'react'

import t from 'prop-types'
import { Input, Card, Form, Button } from 'semantic-ui-react'

const Filter = ({
  fileName,
  isLoading,
  onChange,
  clearFilters,
  onSubmitFilter,
}) => (
  <Card fluid>
    <Card.Content>
      <Form>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            icon="search"
            className="icon"
            value={fileName}
            placeholder="Buscar por nome"
            onChange={(e, { value }) => onChange(value)}
          />
        </Form.Group>
      </Form>
    </Card.Content>
    <Card.Content>
      <Button
        icon="check"
        color="green"
        content="Filtrar"
        labelPosition="left"
        loading={isLoading}
        disabled={isLoading}
        onClick={onSubmitFilter}
      />
      <Button
        icon="undo"
        color="grey"
        content="Limpar Filtros"
        labelPosition="left"
        disabled={isLoading}
        onClick={clearFilters}
        loading={isLoading}
      />
    </Card.Content>
  </Card>
)

Filter.propTypes = {
  fileName: t.string,
  isLoading: t.bool,
  onChange: t.func,
  clearFilters: t.func,
  onSubmitFilter: t.func,
}

export default Filter
