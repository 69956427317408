import React, { useEffect } from 'react'

import { Card, Grid, Tab } from 'semantic-ui-react'

import useDashboardMetrics from '~/hooks/useDashboardMetrics'

import Loader from '../../components/Loader/Metrics/index'
import CardStatistic from '../Dashboard/components/CardStatistic'
import BarGraphic from './components/BarGraphic'
import FormFilter from './components/FormFilter'
import FunnelInactiveUser from './components/FunnelInactiveUser'
import FunnelUsersPotential from './components/FunnelUsersPotential'
import GraphicTooltip from './components/GraphicTooltip'
import GraphicTooltipMissionStartedFinished from './components/GraphicTooltipMissionStartedFinished'
import LinearGraphic from './components/LinearGraphic'
import { TabReponsive } from './styles'

function DashboardMetrics() {
  const {
    filter,
    onChangeInputValue,
    resetFilters,
    submitFilters,
    getMetrics,
    usersNoCompleteRegister,
    accountsCancelled,
    usersActive,
    usersCompletedRegister,
    usersRegistered,
    missionsActive,
    missionsUsersStartedFinished,
    usersByVersion,
    converstionFunnels,
    funnelUserInactive,
    funnelUsersPotential,
    isLoading,
  } = useDashboardMetrics()

  const panes = [
    {
      menuItem: 'Métricas sobre usuários',
      render: () => renderUsersMetrics(),
    },
    {
      menuItem: 'Métricas sobre missões',
      render: () => renderMissionsMetrics(),
    },
    {
      menuItem: 'Funis de conversão',
      render: () => renderConverstionFunnel(),
    },
    {
      menuItem: 'Funil de usuários inativos',
      render: () => renderFunnelInactiveUser(),
    },
    {
      menuItem: 'Funil de usuários em potêncial por estado',
      render: () => renderFunnelUsersPotential(),
    },
  ]

  useEffect(() => {
    getMetrics()
  }, []) //eslint-disable-line

  const renderUsersMetrics = () => {
    return (
      <Tab.Pane>
        <Card fluid>
          <Card.Content>
            <LinearGraphic
              isLoading={isLoading}
              title="Cadastros incompletos"
              data={usersNoCompleteRegister}
              columnX="period"
              columnY="total"
              customTooltip={GraphicTooltip}
              reference="total"
            />

            <LinearGraphic
              isLoading={isLoading}
              title="Contas canceladas"
              data={accountsCancelled}
              columnX="period"
              columnY="total"
              customTooltip={GraphicTooltip}
              reference="total"
            />

            <LinearGraphic
              isLoading={isLoading}
              title="Usuários ativos"
              data={usersActive}
              columnX="period"
              columnY="total"
              customTooltip={GraphicTooltip}
              reference="total"
            />

            <LinearGraphic
              isLoading={isLoading}
              title="Usuários que completaram o cadastro"
              data={usersCompletedRegister}
              columnX="period"
              columnY="total"
              customTooltip={GraphicTooltip}
              reference="total"
            />

            <LinearGraphic
              isLoading={isLoading}
              title="Usuários cadastrados"
              data={usersRegistered}
              columnX="period"
              columnY="total"
              customTooltip={GraphicTooltip}
              reference="total"
            />

            <BarGraphic
              isLoading={isLoading}
              title="Usuários por versões do app"
              data={usersByVersion}
              columnY="percentage"
              columnX="version"
              referenceColumn="percentage"
              rangeMinMax={[0, 100]}
            />
          </Card.Content>
        </Card>
      </Tab.Pane>
    )
  }

  const renderMissionsMetrics = () => {
    return (
      <Tab.Pane>
        <Card fluid>
          <Card.Content>
            <LinearGraphic
              isLoading={isLoading}
              title="Missões ativas"
              data={missionsActive}
              columnX="period"
              columnY="total"
              customTooltip={GraphicTooltip}
              reference="total"
            />

            <LinearGraphic
              isLoading={isLoading}
              title="Missões iniciadas/finalizadas"
              data={missionsUsersStartedFinished}
              columnX="period"
              columnY="total"
              customTooltip={GraphicTooltipMissionStartedFinished}
              reference="total"
            />
          </Card.Content>
        </Card>
      </Tab.Pane>
    )
  }

  const renderConverstionFunnel = () => {
    return (
      <Tab.Pane>
        <Card fluid>
          <Card.Content>
            <Grid columns={3} stackable>
              <Grid.Column>
                {isLoading === true && <Loader />}
                {!isLoading && (
                  <CardStatistic
                    borderColor="blue"
                    label="Cadastros incompletos"
                    value={converstionFunnels.percentageNoCompleteRegister}
                  />
                )}
              </Grid.Column>
              <Grid.Column>
                {isLoading && <Loader />}
                {!isLoading && (
                  <CardStatistic
                    borderColor="blue"
                    label="Missões iniciadas"
                    value={converstionFunnels.percentageMissionUserStarted}
                  />
                )}
              </Grid.Column>
              <Grid.Column>
                {isLoading && <Loader />}
                {!isLoading && (
                  <CardStatistic
                    borderColor="blue"
                    label="Desistência de missões"
                    value={converstionFunnels.percentageMissionUserQuitted}
                  />
                )}
              </Grid.Column>
              <Grid.Column>
                {isLoading && <Loader />}
                {!isLoading && (
                  <CardStatistic
                    borderColor="blue"
                    label="Missões finalizadas"
                    value={converstionFunnels.percentageMissionUserFinished}
                  />
                )}
              </Grid.Column>
              <Grid.Column>
                {isLoading && <Loader />}
                {!isLoading && (
                  <CardStatistic
                    borderColor="blue"
                    label="Missões finalizadas e aprovadas"
                    value={
                      converstionFunnels.percentageMissionUserFinishedAndApproved
                    }
                  />
                )}
              </Grid.Column>
              <Grid.Column>
                {isLoading && <Loader />}
                {!isLoading && (
                  <CardStatistic
                    borderColor="blue"
                    label="Missões finalizadas e reprovadas"
                    value={
                      converstionFunnels.percentageMissionUserFinishedAndReproved
                    }
                  />
                )}
              </Grid.Column>
            </Grid>
          </Card.Content>
        </Card>
      </Tab.Pane>
    )
  }

  const renderFunnelUsersPotential = () => {
    return (
      <Tab.Pane>
        <Card fluid>
          <Card.Content>
            <FunnelUsersPotential
              isLoading={isLoading}
              data={funnelUsersPotential}
            />
          </Card.Content>
        </Card>
      </Tab.Pane>
    )
  }

  const renderFunnelInactiveUser = () => {
    return (
      <Tab.Pane>
        <Card fluid>
          <Card.Content>
            <FunnelInactiveUser
              isLoading={isLoading}
              data={funnelUserInactive}
            />
          </Card.Content>
        </Card>
      </Tab.Pane>
    )
  }

  return (
    <>
      <FormFilter
        filter={filter}
        onChangeInputValue={onChangeInputValue}
        submitFilters={submitFilters}
        resetFilters={resetFilters}
      />
      <TabReponsive
        menu={{ pointing: true, className: 'wrapper' }}
        panes={panes}
      />
    </>
  )
}

export default DashboardMetrics
