import React from 'react'

import t from 'prop-types'
import { Dropdown, Table, Button, Icon } from 'semantic-ui-react'

import Avatar from '~/assets/avatar.png'
import messages from '~/utils/messages'

export function Item({ item, index, loading, onDisableLeader }) {
  const profileImage = item.avatar === null ? Avatar : item.avatar

  function sendEmail(email) {
    return window.open(`mailto:${email}?subject=Mission Brasil`)
  }

  function copyText(text) {
    messages().success(`${text} copiado com sucesso!`)
    return navigator.clipboard.writeText(text)
  }

  function sendWhatsapp(phone) {
    return window.open(`https://api.whatsapp.com/send?phone=${phone}`)
  }

  return (
    <Table.Row key={`index-${index}`}>
      <Table.Cell width={80} verticalAlign="center" textAlign="center">
        <img
          alt="avatar"
          src={profileImage}
          style={{ width: 50, height: 50, borderRadius: 25 }}
        />
      </Table.Cell>
      <Table.Cell>{item.name}</Table.Cell>
      <Table.Cell>
        <Dropdown text={item.email}>
          <Dropdown.Menu>
            <Dropdown.Item
              icon="send"
              text="Enviar E-mail"
              onClick={() => sendEmail(item.email)}
            />
            <Dropdown.Item
              icon="copy"
              text="Copiar"
              onClick={() => copyText(item.email)}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
      <Table.Cell>
        <Dropdown text={item.phone}>
          <Dropdown.Menu>
            <Dropdown.Item
              icon="whatsapp"
              text="Enviar Mensagem"
              onClick={() => sendWhatsapp(item.phone)}
            />
            <Dropdown.Item
              icon="copy"
              text="Copiar"
              onClick={() => copyText(item.phone)}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
      <Table.Cell collapsing singleLine>
        <Button
          icon
          negative
          loading={loading}
          disabled={loading}
          labelPosition="left"
          onClick={onDisableLeader}
        >
          <Icon name="trash" />
          Remover
        </Button>
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  item: t.objectOf(t.any),
  index: t.number,
  loading: t.bool,
  onDisableLeader: t.func,
}
