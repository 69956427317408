import { baseURL } from '.'

export const GOOGLE_GEOCODING = 'https://maps.google.com/maps/api/geocode/json'

// DASHBOARD
export const DASHBOARD = 'dashboard'
export const DASHBOARD_REPORT_DATA = 'dashboard/user-report'
export const DASHBOARD_REPORT_CATEGORIES = 'master/daily-report-totalizers'

// BADGET
export const BADGET_CREATE = `badges`
export const BADGET_FETCH = `badges`
export const BADGET_GET = id => `badges/${id}`
export const BADGET_UPDATE = id => `badges/${id}`
export const BADGET_REMOVE = id => `badges/${id}/delete`

// USER BADGE
export const BADGES = `badges`
export const USER_BADGE = id => `users/badges/${id}/fetch`
export const USER_BADGE_CREATE = `users/badges/create`
export const USER_BADGE_UPDATE = id => `users/badges/${id}/update`
export const USER_BADGE_REMOVE = id => `users/badges/${id}/delete`

// BADGET FORM
export const BADGET_FORM_FETCH = 'badges-questions'
export const BADGET_FORM_CREATE = 'badges-questions'
export const BADGET_FORM_UPDATE = id => `badges-questions/${id}`
export const BADGET_FORM_REMOVE_QUESTION = id => `badges-questions/${id}`
export const BADGET_FORM_CHANGE_ORDER_QUESTIONS =
  'badges-questions/change-order'

export const BADGET_FORM_GET = 'badges-answers'
export const BADGET_FORM_CREATE_ANSWER = 'badges-answers'
export const BADGET_FORM_UPDATE_ANSWER = id => `badges-answers/${id}`
export const BADGET_FORM_REMOVE_ANSWER = id => `badges-answers/${id}`
export const BADGET_FORM_CHANGE_ORDER_ANSWER = 'badges-answers/change-order'

// BADGET FORMS USERS
export const FORMS_USERS_FETCH_TYPE = `forms-users-type`
export const FORMS_USERS_FETCH = `forms-users`
export const FORMS_USERS_CREATE = `forms-users`
export const FORMS_USERS_CREATE_TYPE = `forms-users-type`
export const FORMS_USERS_UPDATE = `forms-users`
export const FORMS_USERS_DELETE = id => `users/forms-users/${id}/remove` // migrated for useFormsUsers hook

// BADGET FORMS USERS ITEMS
export const FORMS_USERS_ITEMS_FETCH = `forms-users-items`
export const FORMS_USERS_ITEMS_CREATE = `forms-users-items`
export const FORMS_USERS_ITEMS_UPDATE = 'forms-users-items'
export const FORMS_USERS_ITEMS_DELETE = 'forms-users-items'
export const FORMS_USERS_ITEMS_REORDER = `forms-users-items/reorder`

// INVOICE
export const INVOICE_FETCH = id => `invoices/${id}`

// FORMS USERS DATA
export const FORMS_USERS_DATA = `forms-users-data`

// LEADER
export const USERS_LEADER_FETCH = `user/consultants/fetch`
export const USERS_LEADER_CREATE = `user/consultants/insert`
export const USERS_LEADER_UPDATE = id => `user/consultants/${id}/update`
export const USERS_LEADER_REMOVE = (id, badgeId) =>
  `user/consultants/${id}/remove/${badgeId}`

export const BADGES_MISSIONS_MAIN_UPDATE_NEW_VERSION = (
  missionsMainId,
  badgeId
) => `missions-main/${missionsMainId}/badges/${badgeId}`

// BADGE MISSIONS MAIN
export const BADGES_MISSIONS_MAIN_UPDATE = (missionsMainId, badgeId) =>
  `missions-main/${missionsMainId}/badges/${badgeId}/add`

// USERS
export const USERS = 'user'
export const USERS_GEO = 'data-users/geo'
export const MISSIONS_ACTIVE_GEO = 'missions-data/geo'
export const USERS_REPORT = `${baseURL}master/users/report/generate`
export const USERS_BANNED = 'master/user-banned'
export const USER_DATA_REGISTERS = 'registers/admin/'
export const USER_UPDATE_BY_ADMIN = userId => `user/admin/change/${userId}`
export const DELIVERY_MESSAGES = 'users/delivery-messages'
export const SEARCH_LEADERS = 'user/consultants/leaders/fetch'
export const USER_DATA_PAYMENTS = '/payment-request/users/'

export const USER_NEARBY_BY_MISSION = 'master/users-nearby-by-missions'

// REGISTERS
export const MASTER_REGISTERS = 'registers/admin'
export const REGISTERS_UPDATE = 'customers/registers/update'
export const POINT_OF_SALE = id => `point-of-sale/sent/${id}`

// MISSIONS MAIN VALUES
export const MISSIONS_MAIN_VALUE = 'missions-main-values'
export const MISSIONS_MAIN_VALUE_CREATE = `missions-main-value/create`
export const MISSIONS_MAIN_VALUE_UPDATE = `missions-main-value/`
export const MISSIONS_MAIN_VALUE_REMOVE = `missions-main-value/`
export const MISSIONS_MAIN_VALUE_FETCH = `missions-main-value/`

// MISSIONS MAIN
export const MISSIONS_MAIN = `master/missions-main/`
export const MISSIONS_MAIN_ALL = `master/missions-main-customers`
export const MISSIONS_MAIN_CREATE_NEW_VERSION = 'missions-main'
export const MISSIONS_MAIN_CREATE = `master/missions-main-create`
export const MISSIONS_MAIN_BY_CATEGORY = `master/missions-main-by-category/`
export const MISSIONS_MAIN_SUBCATEGORIES = 'missions-main'
export const MISSIONS_MAIN_UPDATE_STATUS = `master/missions-main-update-status`
export const MISSIONS_MAIN_UPDATE_NEW_VERSION = `missions-main/`
export const MISSIONS_MAIN_UPDATE = `master/missions-main/update`

export const MISSIONS_MAIN_DELETE = `master/missions-main-delete/`

// MISSIONS MAIN GROUP
export const MISSIONS_MAIN_GROUP_FIND = `missions-main-group`
export const MISSIONS_MAIN_GROUP_CREATE = `missions-main-group/create`
export const MISSIONS_MAIN_GROUP_REMOVE = id => `missions-main-group/${id}`

// PAYMENTS
export const PAYMENTS = `payment-request/users`
export const PAYMENTS_USER_REPORT = `payment-request/report-xls`
export const PAYMENTS_REPORT = `${baseURL}master/payments-requests/report`

export const PAYMENTS_USERS = `payment-request/withdraw`
export const PAYMENTS_UPDATE = `master/payment-request/update`
export const PAYMENTS_DISCLAIMER = `master/payment-disclaimer/create`
export const PAYMENTS_EXTRACT = `payment-request/extract`

// LOGS
export const LOGS = `master/logs`

// CREDIT REQUEST
export const CREDIT_REQUESTS = `master/credit-requests/all`
export const UPDATE_CREDIT_REQUESTS = `master/credit-requests/update`

// FILES
export const MISSIONS_FILES_GET = id => `missions/${id}/files/fetch`
export const MISSIONS_FILES_POST = id => `missions/${id}/files/upload`
export const MISSIONS_FILES_DEL = id => `missions/${id}/file/delete`

export const CUSTOMERS_RESTRICT = `customers/`
export const CUSTOMERS_PAYMENT_CONFIG_UPDATE = `customers/payment/config/`
export const CUSTOMERS_PROCESS_PAYMENT = `credit-request/month-end-closing`

export const CUSTOMERS_ID_RESTRICTED = customerId =>
  `restricted-subcategories/${customerId}`
export const CUSTOMERS_RESTRICTED_SUBCATEGORIES = 'restricted-subcategories'

// PROMOTIONS

export const CONFIG_MEMBER_UPDATE = id => `config-campaigns-member/${id}`

export const CONFIG_MEMBER_GET = id => `user/get-member/${id}/config/get`
export const CONFIG_MEMBER_REMOVE = id => `user/get-member/${id}/config/remove`

// CONFIG CUSTOMER PAYMENT

export const CONFIG_PAYMENT_UPDATE = id =>
  `customers/payment/config/${id}/update`

// ACCOUNTS
export const ACCOUNTS = `logins/fetch`
export const ACCOUNTS_AUTH = `logins/login`

export const CUSTOMER_LOGIN = `customers/login`

export const ACCOUNTS_CREATE = `logins/create`
export const ACCOUNTS_UPDATE = `logins/update`
export const ACCOUNTS_REMOVE = id => `logins/${id}/remove`

// ROLES

export const ACCOUNTS_ROLES = `roles/fetch`
export const ACCOUNTS_ROLES_CREATE = `roles/create`
export const ACCOUNTS_ROLES_UPDATE = `roles/update`
export const ACCOUNTS_ROLES_REMOVE = id => `roles/${id}/remove`

export const UFS = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
export const CITIES = ufIdsSeparatedByPipe =>
  `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufIdsSeparatedByPipe}/municipios`

export const UFS_MIS = 'geocode/admin/search'
export const CITIES_MIS = 'geocode/admin/search'

// NOTIFICATIONS

export const CAMPAIGN = 'campaigns'
export const CAMPAIGN_REMOVE = 'campaigns/remove/'
export const CAMPAIGN_CREATE = 'campaigns/create'
export const CAMPAIGN_UPDATE = 'campaigns/update'

// BUDGET
export const AVERAGE_CATEGORIES = 'customers/missions-main/'
export const REPORT_TRANSACTIONS = 'customers/'

// MISSIONS
export const MISSIONS = 'missions/launched'
export const MISSIONS_SEARCH = 'missions-search'

export const MISSIONS_CREATE = 'customers/missions-create'
export const MISSIONS_UPDATE = 'customers/missions/update/'
export const MISSIONS_UPDATE_STATUS = 'customers/missions-update-status'
export const MISSIONS_OPEN = 'master/missions-open'
export const MISSION_FEEDS_BY_USER = 'missions-feeds-count-by-user'

export const PHOTOS = 'photos'
export const PHOTOS_COUNT = 'photos/admin/count/'
export const PHOTOS_ORDER = 'photos/reorder'
export const PHOTOS_ROTATE = 'photos/rotate'
export const PHOTO_SINGLE = 'photos'

export const MISSIONS_FEEDS = `customers/missions-feeds/`
export const MISSIONS_PLACES = `missions-places`
export const MISSIONS_PARTICIPANTS = `missions/participants/`

export const ATTACHMENTS = `attachments/admin`
export const ATTACHMENTS_UPLOAD = `attachments/admin`
export const ATTACHMENTS_REMOVE = `attachments/admin`

export const MISSIONS_USERS_UPDATE_MISSIONS_MAIN_VALUE = (
  missionUserId,
  missionMainValueIdSelected
) =>
  `/missions-users/${missionUserId}/missions-main-values/${missionMainValueIdSelected}`
export const MISSIONS_USERS_REDIRECT = `missions-users/redirect`
export const MISSIONS_USERS_EXPIRING_REPORT = `${baseURL}missions-users/expiring/export`
export const MISSIONS_USERS_EXPIRING = `missions-users/expiring`
export const MISSIONS_USERS_EXPIRING_REMOVE = id =>
  `missions-users/expiring/${id}`
export const MISSIONS_USERS_EXPIRING_EXTEND = id =>
  `missions-users/expiring/${id}`

// REGISTERS
export const REGISTERS_FEEDS = `registers/missions-feeds/`
export const REGISTERS_DETAIL = `registers/admin/`
export const REGISTERS_SEARCH = `customers/registers-search/`
export const REGISTERS_FILTER = `customers/register-data-period/`
export const REGISTERS_REPORT = `${baseURL}registers/report/photos/`
export const REGISTERS_ZIP = `${baseURL}customers/extract-files/`

// CUSTOM STATUS
export const CUSTOM_STATUS_FETCH = `custom-status`
export const CUSTOM_STATUS_UPDATE = `custom-status`
export const CUSTOM_STATUS_CREATE = `custom-status`
export const CUSTOM_STATUS_REMOVE = id => `custom-status/${id}`
export const CUSTOM_STATUS_REORDER = `custom-status/reorder`

// FORMS ITEMS
export const FORMS_ITEMS_LIST = `customers/forms-items/`
export const FORMS_ITEMS_CREATE = `customers/forms-items/create`
export const FORMS_ITEMS_REMOVE = `customers/forms-items-remove/`
export const FORMS_ITEMS_UPDATE = `customers/forms-items/update`
export const FORMS_ITEMS_UPDATE_ORDER = `customers/forms-items/change-order`

export const FORMS_HAS_MISSIONS = `customers/has-forms-missions/`
export const FORMS_MISSIONS_CREATE = `customers/forms-missions/create`
export const FORMS_MISSIONS_UPDATE = `customers/forms-missions/update`

// FORMS ITEMS QUESTIONS MULTIPLES
export const FORMS_ITEMS_MULTIPLES_CREATE = `customers/forms-items-questions-multiples/create`
export const FORMS_ITEMS_MULTIPLES_REMOVE = `customers/forms-items-questions-multiples-remove/`
export const FORMS_ITEMS_MULTIPLES_UPDATE_ORDER = `customers/forms-items-questions-multiples/change-order`

export const FORMS_DATA = `customers/forms-data-by-mission/`
export const FORMS_DATA_STATISTICS = `customers/forms-data-statistics/`
export const FORMS_DATA_EXPORT = `${baseURL}customers/forms-data-export/`
export const APP_VERSIONS_ALL = `customers/versions/all`
export const PHOTO_UPDATE_ORDER = `photos/reorder`
export const PHOTO_DOWNLOAD_ZIP = `${baseURL}customers/extract-files/`

// INTEGRATION
export const IMPORT_PRODUCTS_PDV = `${baseURL}import-products`
export const GET_CATEGORIES_PRODUCTS = customerId =>
  `products-customers/admin/${customerId}`
export const CREATE_CATEGORY_PRODUCTS = 'products-customers/admin/create'
export const GET_PRODUCTS_ITEMS = categoryId =>
  `products-items/admin/${categoryId}`
export const UPDATE_CATEGORY_PRODUCTS = 'products-customers/admin/update'
export const REMOVE_CATEGORY_PRODUCTS = categoryId =>
  `products-customers/admin/${categoryId}/remove`
export const CREATE_PRODUCT_ITEM = 'products-items/admin/create'
export const UPDATE_PRODUCT_ITEM = 'products-items/admin/update'
export const REMOVE_PRODUCT_ITEM = 'products-items/admin/remove'
export const UPDATE_ALL_STATUS = 'products-items/admin/update-all-status'
export const IMPORT_PRODUCTS_ITEMS = 'products-items/admin/imports'

// GROUPS
export const CUSTOMERS_GROUPS = 'customers-groups'

// INTEGRATION
export const IMPORT_XLS = `${baseURL}integration/xls/import`
export const IMPORT_FILE_ID = id =>
  `${baseURL}integration/importFile/${id}/fetch`
export const IMPORT_XLS_CREATE = id =>
  `${baseURL}integration/customer/${id}/import`
export const IMPORT_FILE_CREATE = id =>
  `${baseURL}integration/importFile/${id}/create-missions`
export const IMPORT_FILE_UPDATE = (fileId, id) =>
  `${baseURL}integration/importFile/${fileId}/item/${id}/update`
export const IMPORT_FILE_MISSIONS_OPEN = `${baseURL}master/missions-open/excel`

// OTHERS
export const XLS_FILE_S3 =
  'https://s3-us-west-2.amazonaws.com/assetsmissionbr/IMPORT/Exemplo_Importacao.xlsx'

// MEMBER GET MEMBER
export const MGM_FIND_HOST = guestId =>
  `user/get-owner-code-applied-guests/${guestId}`
export const MGM_FIND_GUESTS = hostId =>
  `user/get-guests-applied-code-host/${hostId}`
