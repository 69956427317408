/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useRef } from 'react'

import { head } from 'ramda'
import { Header, Confirm } from 'semantic-ui-react'

import useCustomers from '~/hooks/useCustomers'
import useForms from '~/hooks/useForms'
import useFormsItems from '~/hooks/useFormsItems'
import useFormsItemsParents from '~/hooks/useFormsItemsParents'
import Message from '~/utils/messages'

import AddExample from './components/AddExample'
import CreateForm from './components/CreateForm'
import CreateFormsItems from './components/CreateFormsItems'
import MainMenu from './components/MainMenu'
import OperatorWarning from './components/OperatorWarning'
import Optional from './components/Optional'
import SubMenu from './components/SubMenu'
import TableFormsItems from './components/TableFormsItems'
import UpdateScore from './components/UpdateScore'

function ImportsFormsCustomers() {
  const uploadRef = useRef()

  const [uidMultipleSelected, setUidMultipleSelected] = useState('')

  const { getCustomers, listNameCustomers, loadingCustomers } = useCustomers()
  const {
    getForms,
    loadingForms,
    forms,
    createForm,
    updateForms,
    removeForms,
    duplicateForms,
  } = useForms()

  const {
    getItems,
    items,
    loadingFormsItems,
    createItems,
    getItemsMultiples,
    setItemsMultiples,
    itemsMultiples,
    updateFormsItems,
    createMultiple,
    removeMultiple,
    reorderMultiple,
    submitUploadImage,
    importFormsItems,
    removeFormsItems,
    orderFormsItems,
    submitExample,
    submitOperatorWarning,
    updateQuestionsMultiples,
    exportFormsItemsTemplate,
    changeOrderFormsItems,
    getQuestionsTypes,
    questionsTypes,
  } = useFormsItems()

  const {
    loadingParents,
    submitParents,
    getParents,
    itemsParents,
    removeParents,
  } = useFormsItemsParents()

  const [selectedCustomer, setSelectedCustomer] = useState('')
  const [selectedForm, setSelectedForm] = useState('')
  const [modalForms, setModalForms] = useState({
    open: false,
    type: '',
  })

  const [modalFormsItems, setModalFormsItems] = useState({
    open: false,
    type: '',
  })

  const [dataCreateForm, setDataCreateForm] = useState({})
  const [dataFormsItems, setDataFormsItems] = useState({})
  const [modalConfirmRemove, setModalConfirmRemove] = useState(false)
  const [modalConfirmDuplicate, setModalConfirmDuplicate] = useState(false)
  const [modalConfirmRemoveFormItem, setModalConfirmRemoveFormItem] =
    useState(false)
  const [modalOptionals, setModalOptionals] = useState(false)
  const [fullDataForm, setFullDataForm] = useState({})
  const [answers, setAnswers] = useState({
    items: [],
  })

  const [itemSelected, setItemSelected] = useState({
    formItemId: '',
    parents: [],
  })

  const [selectedMultiple, setSelectedMultiple] = useState('')

  const [modalExample, setModalExample] = useState(false)
  const [modalOperatorWarning, setModalOperatorWarning] = useState(false)

  const [scoreMultiple, setScoreMultiple] = useState({})
  const [showConfirmScore, setShowConfirmScore] = useState(false)
  const [scoreType, setScoreType] = useState('')

  useEffect(() => {
    getCustomers({ limit: 10000 })

    if (selectedCustomer) {
      getForms(selectedCustomer)
      setFullDataForm({})
      setSelectedForm('')
    }
  }, [selectedCustomer]) // eslint-disable-line

  useEffect(() => {
    if (selectedForm) {
      setFullDataForm(forms.find(form => form.uid === selectedForm))

      getItems(selectedForm)
    }
  }, [selectedForm]) // eslint-disable-line

  function handleClickButton(type, item) {
    if (type === 'addExample') {
      setModalExample(true)
      setDataFormsItems(prevState => ({
        ...prevState,
        ...item,
      }))
    }

    if (type === 'order-items') {
      orderFormsItems({ formItems: items, formUid: selectedForm })
    }
    if (type === 'importFormsItems') {
      importFormsItems({ item, selectedForm })
    }

    if (type === 'update-item') {
      getQuestionsTypes()
      setModalFormsItems({ open: true, type: 'update' })
      setAnswers({
        items: [],
      })

      getItemsMultiples(item.uid)

      setDataFormsItems(prevState => ({
        ...prevState,
        ...item,
      }))
    }

    if (type === 'create-item') {
      getQuestionsTypes()
      setModalFormsItems({
        open: true,
        type: 'create',
      })

      setDataFormsItems({ formId: selectedForm })
      setAnswers({ items: [] })
    }

    if (type === 'create') {
      setDataCreateForm({})
      setModalForms({
        open: true,
        type: 'create',
      })

      setDataCreateForm(prevState => ({
        ...prevState,
        clientId: selectedCustomer,
      }))
    }

    if (type === 'update') {
      setModalForms({
        open: true,
        type: 'update',
      })

      setDataCreateForm(fullDataForm)
    }

    if (type === 'remove') {
      setModalConfirmRemove(true)
    }

    if (type === 'copy') {
      setModalConfirmDuplicate(true)
    }
  }

  function onChangeCreateForm(key, value) {
    setDataCreateForm(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  function addAnswers() {
    setAnswers(prevState => ({
      items: [
        ...prevState.items,
        {
          title: dataFormsItems.items,
          score: dataFormsItems.score,
          score_rating: dataFormsItems.score_rating,
        },
      ],
    }))
  }

  function removeAnswer(answer) {
    const newList = answers.items.filter(item => item !== answer)

    setAnswers(prevState => ({
      ...prevState,
      items: newList,
    }))
  }

  function onChangeFormsItems(key, value) {
    setDataFormsItems(prevState => ({
      ...prevState,
      [key]: value,
    }))

    if (key === 'multiple') {
      setAnswers(prevState => ({
        items: [...prevState.items, value],
      }))
    }

    if (key === 'title' || key === 'description') {
      setDataFormsItems(prevState => ({
        ...prevState,
        example: {
          ...prevState.example,
          [key]: value,
        },
      }))
    }
  }

  function submitCreateForm() {
    if (modalForms.type === 'create') {
      createForm(dataCreateForm)
    }

    if (modalForms.type === 'update') {
      updateForms(dataCreateForm)
    }

    return setTimeout(() => {
      setItemsMultiples([])
      setModalForms({
        open: false,
      })
    }, 500)
  }

  function submitFormsItems() {
    if (modalFormsItems.type === 'create') {
      createItems({ dataFormsItems, answers })
    }

    if (modalFormsItems.type === 'update') {
      updateFormsItems({
        dataFormsItems,
        answers,
        multi: itemsMultiples,
        selectedForm,
      })
    }

    return setTimeout(() => {
      setModalFormsItems({ open: false })
      setItemsMultiples([])
      setDataFormsItems({})
    }, 500)
  }

  function addAnswersUpdate() {
    if (!dataFormsItems.items) {
      return Message().info('Você precisa digitar uma resposta')
    }

    return createMultiple(dataFormsItems)
  }

  function submitRemove() {
    removeForms(fullDataForm)

    return setTimeout(() => {
      setModalConfirmRemove(false)
    }, 500)
  }

  function submitDuplicate() {
    duplicateForms(fullDataForm)

    return setTimeout(() => {
      setModalConfirmDuplicate(false)
    }, 500)
  }

  function submitRemoveItemsMultiples(item) {
    removeMultiple(item)
  }

  function onClickOptionals(dataQuestionMultiple) {
    setSelectedMultiple(dataQuestionMultiple.uid)
    getParents(dataQuestionMultiple.uid)

    setModalOptionals(true)
  }

  function onClickAddOrRemoveOptional(type) {
    const oldParents = itemsParents.map(parent => parent.forms_items_id)

    if (type === 'add') {
      submitParents({
        parentId: selectedMultiple,
        newParents: itemSelected.parents.map(item => item.uid),
        oldParents,
      })
    }

    if (type === 'remove') {
      removeParents({
        parentId: selectedMultiple,
        deleteParents: itemSelected.parents.map(item => item.forms_items_id),
        oldParents,
      })
    }

    setItemSelected({
      formItemId: '',
      parents: [],
    })
  }

  function onChangeParents(item, checked) {
    if (checked) {
      return setItemSelected(prevState => ({
        parents: [...prevState.parents, item],
      }))
    }

    const removeParent = itemSelected.parents.filter(
      parent => parent.uid !== item.uid
    )

    return setItemSelected({
      parents: removeParent,
    })
  }

  function onClickMoveAnswers(item, fromIndex, direction) {
    const list = itemsMultiples.map(i => i.id)
    let index

    if (direction === 'down') {
      index = list.findIndex(i => i === item.id) + 1
    }

    if (direction === 'up') {
      index = list.findIndex(i => i === item.id) - 1
    }

    list.splice(fromIndex, 1)
    list.splice(index, 0, item.id)

    reorderMultiple({ list, formsItemsId: item.forms_items_id })
  }

  function onClickUpload(item) {
    setUidMultipleSelected(item.uid)
    uploadRef.current.click()
  }

  function uploadImage(e) {
    const file = head(e.target.files)
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onloadend = event =>
      submitUploadImage({
        fiqmId: uidMultipleSelected,
        image: event.target.result,
      })
  }

  function onChangeImport(e) {
    handleClickButton('importFormsItems', e.target.files[0])
  }

  function submitRemoveFormsItems() {
    removeFormsItems({
      formItemUid: dataFormsItems.uid,
      formUid: selectedForm,
    })

    setTimeout(() => {
      setModalConfirmRemoveFormItem(false)
      setModalFormsItems({ open: false })
      setItemsMultiples([])
    }, 500)
  }

  function closeOptional() {
    setModalOptionals(false)
    setItemSelected({
      formItemId: '',
      parents: [],
    })
  }

  function uploadImageExample(e) {
    const file = head(e.target.files)
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onloadend = event => {
      setDataFormsItems(prevState => ({
        ...prevState,
        example: {
          ...prevState.example,
          image: event.target.result,
        },
      }))
    }
  }

  function onChangeOperatorWarining(key, value) {
    setDataFormsItems(prevState => ({
      ...prevState,
      operator_warning: {
        ...prevState.operator_warning,
        [key]: value,
      },
    }))
  }

  function onClickSubmitOperatorWarning() {
    submitOperatorWarning({
      dataFormsItems,
      selectedForm,
    })
  }

  function onChangeMultiples(key, value) {
    setScoreMultiple(prevState => ({
      ...prevState,
      key,
      value,
    }))
  }

  function onClickUpdateScore(key, item) {
    setScoreType(key)
    setScoreMultiple(item)
    setShowConfirmScore(true)
  }

  function onChangeScore(key, value) {
    setScoreMultiple(prevState => ({
      ...prevState,
      [key]: Number(value),
    }))
  }

  function submitUpdateScore() {
    updateQuestionsMultiples(scoreMultiple)

    setTimeout(() => {
      setShowConfirmScore(false)
    }, 1000)
  }

  function handleClickExportFormsItemsTemplate() {
    exportFormsItemsTemplate(selectedForm)
  }

  function handleOnClickRemoveImage(values) {
    updateQuestionsMultiples({
      uid: values.uid,
      url_image: null,
      forms_items_id: values.forms_items_id,
    })
  }

  return (
    <>
      <UpdateScore
        showModal={showConfirmScore}
        onClose={() => setShowConfirmScore(false)}
        dataMultiple={scoreMultiple}
        onChange={onChangeScore}
        submit={submitUpdateScore}
        scoreType={scoreType}
      />

      <Header>Formulários da Plataforma do Cliente</Header>

      <MainMenu
        forms={forms}
        handleClickButton={handleClickButton}
        listNameCustomers={listNameCustomers}
        loadingCustomers={loadingCustomers}
        loadingItems={loadingFormsItems}
        loadingForms={loadingForms}
        selectedCustomer={selectedCustomer}
        selectedForm={selectedForm}
        setSelectedCustomer={setSelectedCustomer}
        setSelectedForm={setSelectedForm}
      />

      <SubMenu
        handleClickButton={handleClickButton}
        loadingFormsItems={loadingFormsItems}
        onChangeImport={onChangeImport}
        selectedForm={selectedForm}
        handleClickExportFormsItemsTemplate={
          handleClickExportFormsItemsTemplate
        }
      />

      <TableFormsItems
        loadingItems={loadingFormsItems}
        fullDataForm={fullDataForm}
        handleClickButton={handleClickButton}
        items={items}
        selectedForm={selectedForm}
        changeOrderFormsItems={changeOrderFormsItems}
      />

      <Optional
        open={modalOptionals}
        onClose={closeOptional}
        allFormsItems={items}
        dataFormsItems={dataFormsItems}
        selectedMultiple={selectedMultiple}
        onChange={onChangeParents}
        itemsParents={itemsParents}
        loading={loadingParents}
        onClickAddOrRemoveOptional={onClickAddOrRemoveOptional}
      />
      <Confirm
        open={modalConfirmRemove}
        size="mini"
        header="Remoção de Formulário"
        content="Tem certeza que deseja remover este formulário?"
        confirmButton="Remover"
        cancelButton="Cancelar"
        onCancel={() => setModalConfirmRemove(false)}
        onConfirm={submitRemove}
      />

      <Confirm
        open={modalConfirmDuplicate}
        size="mini"
        header="Duplicar Formulário"
        content="Tem certeza que deseja duplicar este formulário?"
        confirmButton="Duplicar"
        cancelButton="Cancelar"
        onCancel={() => setModalConfirmDuplicate(false)}
        onConfirm={submitDuplicate}
      />

      <Confirm
        open={modalConfirmRemoveFormItem}
        size="mini"
        header="Remover Form Item"
        content="Tem certeza que deseja remover este item de formulário?"
        confirmButton="Remover"
        cancelButton="Cancelar"
        onCancel={() => setModalConfirmRemoveFormItem(false)}
        onConfirm={submitRemoveFormsItems}
      />

      <CreateForm
        showModal={modalForms.open}
        type={modalForms.type}
        dataForms={dataCreateForm}
        onClose={() => {
          setItemsMultiples([])
          setModalForms({ open: false })
        }}
        onChange={onChangeCreateForm}
        submit={submitCreateForm}
      />

      <CreateFormsItems
        showModal={modalFormsItems.open}
        type={modalFormsItems.type}
        dataFormsItems={dataFormsItems}
        onClose={() => {
          setItemsMultiples([])
          setModalFormsItems({ open: false })
        }}
        onChange={onChangeFormsItems}
        submit={submitFormsItems}
        addAnswers={addAnswers}
        answers={answers.items}
        removeAnswer={removeAnswer}
        itemsMultiples={itemsMultiples}
        submitRemoveItemsMultiples={submitRemoveItemsMultiples}
        onClickOptionals={onClickOptionals}
        onClickMoveAnswers={onClickMoveAnswers}
        onClickUpload={onClickUpload}
        addAnswersUpdate={addAnswersUpdate}
        myRef={uploadRef}
        uploadImage={uploadImage}
        loadingFormsItems={loadingFormsItems}
        remove={() => setModalConfirmRemoveFormItem(true)}
        addExample={() => handleClickButton('addExample', dataFormsItems)}
        operatorWarning={() => setModalOperatorWarning(true)}
        onChangeScore={onChangeScore}
        onClickUpdateScore={onClickUpdateScore}
        onChangeMultiples={onChangeMultiples}
        onClickRemoveImage={handleOnClickRemoveImage}
        questionsTypes={questionsTypes}
      />

      <AddExample
        dataForm={dataFormsItems}
        openExample={modalExample}
        onClose={() => setModalExample(false)}
        uploadImage={uploadImageExample}
        onChange={onChangeFormsItems}
        submitExample={() => submitExample({ dataFormsItems, selectedForm })}
        loadingExample={loadingFormsItems}
      />

      <OperatorWarning
        showOperatorWarning={modalOperatorWarning}
        onClose={() => {
          setModalOperatorWarning(false)
        }}
        onChange={onChangeOperatorWarining}
        submitOperatorWarning={onClickSubmitOperatorWarning}
        operatorWarningData={dataFormsItems}
        loadingFormsItems={loadingFormsItems}
      />
    </>
  )
}

export default ImportsFormsCustomers
