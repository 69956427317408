import React, { useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import t from 'prop-types'
import { Modal, Button, Form } from 'semantic-ui-react'

export default function UserInfoEditModal({
  userProfile,
  open,
  close,
  submit,
  isLoading,
}) {
  const newPass = useRef('newPass')

  const form = useForm()

  useEffect(() => {
    form.setValue('name', userProfile.name)
    form.setValue('phone', userProfile.phone)
    form.setValue('email', userProfile.email)
  }, [userProfile]) // eslint-disable-line

  function handlerInput(e, { name, value }) {
    return form.setValue(name, value)
  }

  return (
    <Modal size="small" open={open} onClose={close}>
      <Modal.Header content="Info" />

      <Modal.Content>
        <Form>
          <Form.Input
            type="text"
            id="name"
            name="name"
            label="Nome"
            defaultValue={form.getValues('name')}
            onChange={handlerInput}
          />
          <Form.Input
            type="text"
            id="email"
            name="email"
            onChange={handlerInput}
            defaultValue={form.getValues('email')}
            label="Email"
            placeholder="Ex: ola@email.com"
          />

          <Form.Input
            type="text"
            id="phone"
            name="phone"
            onChange={handlerInput}
            defaultValue={form.getValues('phone')}
            label="Celular"
            placeholder="Ex: +55 + Número"
          />

          <Form.Input
            id="password"
            name="password"
            ref={newPass}
            type="password"
            onChange={handlerInput}
            defaultValue={form.getValues('password')}
            label="Senha"
            placeholder="Ex: Senha"
          />

          <Form.Input
            id="passwordConfirm"
            name="passwordConfirm"
            type="password"
            onChange={handlerInput}
            defaultValue={form.getValues('passwordConfirm')}
            label="Confirmar Senha"
            placeholder="Ex: Confirmar Senha"
          />
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button negative onClick={close}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={form.handleSubmit(submit)}
          icon="checkmark"
          loading={isLoading}
          disabled={isLoading}
          labelPosition="right"
          content="Atualizar"
        />
      </Modal.Actions>
    </Modal>
  )
}

UserInfoEditModal.propTypes = {
  userProfile: t.objectOf(t.any),
  open: t.bool,
  close: t.func,
  submit: t.func,
  isLoading: t.bool,
}
