import React from 'react'

import t from 'prop-types'
import { Menu, Button } from 'semantic-ui-react'

const AccountsHeader = ({ showForm, show }) => (
  <Menu>
    <Menu.Item>
      <Button color={show ? 'grey' : 'blue'} onClick={showForm}>
        {show ? 'Fechar' : 'Criar'}
      </Button>
    </Menu.Item>
  </Menu>
)

AccountsHeader.propTypes = {
  show: t.bool,
  showForm: t.func,
}

export default AccountsHeader
