import { responseData } from '~/utils/reducer'

const CUSTOM_STATUS_UPDATE = 'CUSTOM_STATUS_UPDATE'
const CUSTOM_STATUS_CREATE = 'CUSTOM_STATUS_CREATE'
const CUSTOM_STATUS_REMOVE = 'CUSTOM_STATUS_REMOVE'
const CUSTOM_STATUS_REORDER = 'CUSTOM_STATUS_REORDER'

const customUpdated = responseData(CUSTOM_STATUS_UPDATE, 'object')
const customCreated = responseData(CUSTOM_STATUS_CREATE, 'object')
const customRemoved = responseData(CUSTOM_STATUS_REMOVE, 'object')
const customReorded = responseData(CUSTOM_STATUS_REORDER, 'array')

export default {
  customReorded,
  customRemoved,
  customCreated,
  customUpdated,
}
