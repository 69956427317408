import React, { useRef } from 'react'

import t from 'prop-types'
import { Button, Image, Form, Modal } from 'semantic-ui-react'

function AddExample({
  submitExample,
  onClose,
  openExample,
  dataForm,
  onChange,
  uploadImage,
  loadingExample,
}) {
  const uploadRef = useRef()

  return (
    <>
      <Modal open={openExample} onClose={onClose} size="tiny">
        <Modal.Header>Descreva um Exemplo</Modal.Header>
        <Modal.Content>
          <Image
            verticalAlign="middle"
            size="tiny"
            src={dataForm.example?.image}
          />

          <input
            type="file"
            id="uploadImage"
            accept="image/*"
            style={{ display: 'none' }}
            ref={uploadRef}
            onChange={uploadImage}
          />
          <Button
            icon="upload"
            content="Upload"
            labelPosition="right"
            onClick={() => uploadRef.current.click()}
            disabled={loadingExample}
          />

          <Form>
            <Form.Input
              id="title"
              type="text"
              value={dataForm.example?.title}
              onChange={(e, { value }) => onChange('title', value)}
              label="Título"
            />
            <Form.TextArea
              id="description"
              type="text"
              value={dataForm.example?.description}
              onChange={(e, { value }) => onChange('description', value)}
              label="Descrição"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            size="large"
            color="green"
            content="Salvar"
            onClick={submitExample}
            loading={loadingExample}
          />
          <Button
            size="large"
            color="black"
            content="Sair"
            onClick={onClose}
            loading={loadingExample}
          />
        </Modal.Actions>
      </Modal>
    </>
  )
}

AddExample.propTypes = {
  submitExample: t.func,
  onClose: t.func,
  openExample: t.bool,
  dataForm: t.objectOf(t.any),
  onChange: t.func,
  uploadImage: t.func,
  loadingExample: t.bool,
}

export default AddExample
