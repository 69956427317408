import React, { useEffect, useState, useRef } from 'react'

import { Table, Image, Label, Icon, Card, Button } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useStore from '~/hooks/useStore'

import ConfirmUpdate from './components/ConfirmUpdate'
import CreateOrEdit from './components/CreateOrEdit'

function StoreProducts() {
  const uploadRef = useRef()
  const {
    getStoreProducts,
    storeProducts,
    totalCountProducts,
    loading,
    getStoreProductsCategories,
    storeProductsCategories,
    createStoreProducts,
    updateStoreProducts,
    getBuyers,
    countBuyer,
  } = useStore()

  const [pagination, setPagination] = useState({
    limit: 15,
    offset: 0,
    activePage: 1,
  })
  const [showConfirm, setShowConfirm] = useState(false)

  const columns = [
    'Imagem',
    'Produto',
    'Valor',
    'Moeda',
    'Categoria',
    'Limite de Vendas',
    'Status',
    'Ações',
  ]

  function renderItem(item, index) {
    return (
      <Table.Row key={String(index)}>
        <Table.Cell>
          <Image
            src={item.image_url}
            alt={`Imagem do ${item.name}`}
            size="mini"
            verticalAlign="middle"
          />
        </Table.Cell>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.value}</Table.Cell>
        <Table.Cell>
          {item.currency_type === 'money' ? 'Dinheiro' : 'Pontos'}
        </Table.Cell>
        <Table.Cell>{item.category_name}</Table.Cell>
        <Table.Cell>{item.stock_limit}</Table.Cell>
        <Table.Cell>
          {item.status ? (
            <Label color="green">Ativo</Label>
          ) : (
            <Label color="red">Inativo</Label>
          )}
        </Table.Cell>
        <Table.Cell>
          <Icon
            disabled={loading}
            link={!loading}
            color="blue"
            size="large"
            name="edit"
            onClick={() => handleClick({ type: 'update', item })}
          />
        </Table.Cell>
      </Table.Row>
    )
  }

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * pagination.limit
    setPagination(prevState => ({
      ...prevState,
      activePage,
      offset,
      limit: pagination.limit,
    }))

    return getStoreProducts({
      offset,
      limit: pagination.limit,
    })
  }

  const [modalVisible, setModalVisible] = useState(false)
  const [productData, setProductData] = useState({})

  function handleClick(params) {
    setModalVisible(true)
    setProductData({
      type: params.type,
    })

    if (params.type === 'new') {
      setProductData(prevState => ({
        ...prevState,
        header: 'Novo Produto',
      }))
    }

    if (params.type === 'update') {
      getBuyers(params.item.uid)

      setProductData(prevState => ({
        ...prevState,
        ...params.item,
        stockLimit: params.item.stock_limit,
        currencyType: params.item.currency_type,
        storeProductsCategoriesId: params.item.store_products_categories_id,
        startedAt: new Date(params.item.started_at),
        expiredAt: new Date(params.item.expired_at),
        header: 'Atualizar Produto',
        hasDelivery: params.item.has_delivery
      }))
    }
  }

  function onChangeCreateOrEdit(key, value) {
    setProductData(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  async function onCreate() {
    await createStoreProducts(productData, pagination)

    setProductData({})
    setModalVisible(false)
  }

  async function onUpdate() {
    updateStoreProducts(productData, pagination)

    setProductData({})
    setModalVisible(false)
  }

  function uploadImage(e) {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = event => {
      setProductData(prevState => ({
        ...prevState,
        image_url: event.target.result,
        file,
      }))
    }
  }

  function handleCancel() {
    setShowConfirm(false)
    setModalVisible(false)
  }

  useEffect(() => {
    getStoreProducts(pagination)
  }, []) // eslint-disable-line

  useEffect(() => {
    getStoreProductsCategories(pagination)
  }, []) // eslint-disable-line

  useEffect(() => {
    if (countBuyer > 0) {
      setShowConfirm(true)
    }
  }, [countBuyer])

  return (
    <>
      <h1>Loja Mission - Produtos</h1>

      <Card fluid>
        <Card.Content>
          <Button
            primary
            size="large"
            onClick={() => handleClick({ type: 'new' })}
            loading={loading}
            disabled={loading}
          >
            Novo Produto
          </Button>
        </Card.Content>
      </Card>

      <TableComponent
        isLoading={loading}
        columns={columns}
        data={storeProducts}
        renderItem={renderItem}
        emptyText={{
          icon: 'eye slash outline',
          text: 'A lista de produtos está vazia',
        }}
        count={totalCountProducts}
        totalCount={totalCountProducts}
        activePage={pagination.activePage}
        limit={pagination.limit}
        offset={pagination.offset}
        showEllipsis
        showFirstAndLastNav
        showPreviousAndNextNav
        handlePaginationChange={handlePaginationChange}
      />

      <CreateOrEdit
        open={modalVisible}
        productData={productData}
        onClose={() => setModalVisible(false)}
        onChange={onChangeCreateOrEdit}
        onCreate={onCreate}
        myRef={uploadRef}
        onClickUpload={() => uploadRef.current.click()}
        uploadImage={uploadImage}
        storeProductsCategories={storeProductsCategories}
        isLoading={loading}
        onUpdate={onUpdate}
      />

      <ConfirmUpdate
        countBuyer={countBuyer || 0}
        handleCancel={handleCancel}
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
      />
    </>
  )
}

export default StoreProducts
